/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import type { EuiBasicTableColumn, EuiTableSelectionType } from '@elastic/eui';
import { EuiButton, EuiButtonEmpty, EuiInMemoryTable, EuiLoadingContent, EuiProgress, EuiToolTip } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import { mdiContentCopy, mdiDelete } from '@mdi/js';
import { ComponentBreadcrumb, ComponentSearchField, ComponentToast, DialogComponent } from 'components';
import type { APIApps, APIAppsApplication } from 'modules/operational/entities/app.entity';
import { AppsList } from 'modules/operational/entities/app.entity';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { IconInformation } from 'icons';
import type { DashboardFilterTypes, DistributionCounter } from 'modules/operational/components/apps/DashboardFilter/AppsDashboardFilter.contracts';
import { AppsDashboardFilter } from 'modules/operational/components/apps';
import * as S from './PageApps.style';
import type { DistributionParams } from './PageApps.contracts';
import { AppHelper } from './PageApps.helper';
import { OperationalBreadcrumbs } from '../OperationalBreadcrumbs';

export function PageAppsList() {
  const [dashboardFilterSelected, setDashboardFilterSelected] = useState<DashboardFilterTypes>('');
  const [searchFilter, setSearchFilter] = useState('');
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [selectedApps, setSelectedApps] = useState<AppsList[]>([]);
  const [appsTable, setAppsTable] = useState<AppsList[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [appsPerDistribution, setAppsPerDistribution] = useState<DistributionCounter>();
  const [toastType, setToastType] = useState<'error' | 'success' | undefined>();

  // TODO: wating for edit modal decisions
  // const [updateType, setUpdateType] = useState<UpdateTypes>('instant');
  // const [profilesConection, setProfilesConection] = useState(false);
  // const [sortField, setSortField] = useState('firstName');
  // const [delegateActions, setDelegateActions] = useState<DelegateActions>({
  //   updateSettings: false,
  //   constraintManagement: false,
  //   uninstallBlock: false,
  //   permissionsManagement: false,
  //   statusManagement: false,
  //   enableSystemApps: false,
  // });

  const { t } = useTranslation('translation', { keyPrefix: 'apps' });

  const itemsPerPage = 25;
  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const tableRef = useRef<EuiInMemoryTable<AppsList>>(null);

  const columns: EuiBasicTableColumn<AppsList>[] = [
    {
      field: 'application',
      name: t('table.header.application'),
      render: (application: AppsList['application']) => renderApplicationColumn(application),
    },
    {
      field: 'numberOfGroups',
      name: t('table.groups'),
      render: (numberOfGroups: number) => renderGroupColumn(numberOfGroups),
      style: { justifyContent: 'center' },
    },
    {
      field: 'size',
      name: t('table.compatibility'),
    },
    {
      field: 'installations',
      name: t('table.header.evolution'),
      width: '270px',
      align: 'center',
      render: (installations: AppsList['installations']) => renderPercentageBars(installations),
    },
    {
      field: 'actions',
      name: '',
      width: '130px',
      align: 'right',
      render: () => renderActions(),
    },
  ];

  const getApps = async (page: number, distributionParams?: DistributionParams, searchByname?: string) => {
    setIsLoading(true);
    let result: APIApps;
    try {
      setErrorMessage('');
      if (distributionParams) {
        if (!distributionParams?.kind) {
          result = await serviceLoki.getApps(page + 1, 'os', distributionParams);
        } else {
          result = await serviceLoki.getApps(page + 1, 'distribution', distributionParams);
        }
      } else if (searchByname) {
        result = await serviceLoki.getApps(page + 1, 'name', undefined, searchByname);
      } else {
        result = await serviceLoki.getApps(page + 1);
      }
      if (result.apps.length === 0) {
        setErrorMessage(t('devices_not_found'));
        setPageCount(1);
      } else {
        setPageCount(Math.ceil(result.total / itemsPerPage));
        const appsLocal = result.apps.map((item: APIAppsApplication) => {
          const application: AppsList['application'] = {
            icon: AppHelper.findDistributionIcon(item),
            name: item.name,
            identifier: item.identifier,
          };
          const installations: AppsList['installations'] = {
            total: item.device_count_in_groups || 0,
            updated: item.updated_device_count || 0,
            installed: item.installed_device_count || 0,
            isPlayStore: item.current_version.enterprise_app,
          };

          return new AppsList(application, String(item.id), item.group_count, installations);
        });
        setAppsTable(appsLocal);
      }
    } catch {
      setErrorMessage(t('get_devices_error'));
      setPageCount(1);
    } finally {
      setIsLoading(false);
    }
  };

  const getDistributionCounter = async () => {
    try {
      const result = await serviceLoki.getNumberOfAppsPerDistribution();
      const distribution = AppHelper.findDistributionCounter(result);
      setAppsPerDistribution(distribution);
    } catch {
      setToastType('error');
    }
  };

  // RENDER COLUMNS
  const renderApplicationColumn = (application: AppsList['application']) => {
    return (
      <S.ColumnApplication className="link">
        {application.icon}
        <div>
          <S.AppName title={application.name}>{application.name}</S.AppName>
          <S.AppIdentifier title={application.identifier}>{application.identifier}</S.AppIdentifier>
        </div>
      </S.ColumnApplication>
    );
  };

  const renderGroupColumn = (numberOfGroups: number) => {
    return (
      <S.ColumnGroup>
        <p>
          {`${[null, 0].includes(numberOfGroups) ? t('table.no_groups') : numberOfGroups} ${
            numberOfGroups > 1 ? t('table.groups').toUpperCase() : t('table.groups').slice(0, -1).toUpperCase()
          }`}
        </p>
      </S.ColumnGroup>
    );
  };

  const renderPercentageBars = (installations: AppsList['installations']): ReactElement => {
    const percentageUpdated = Math.round((installations.updated / installations.total) * 100);
    const percentageInstalled = Math.round((installations.installed / installations.total) * 100);

    return (
      <S.ColumnEvolution>
        <S.Count countApps={String(installations.total).length}>{installations.total}</S.Count>
        <S.WrapperBars>
          <S.Bars>
            {!installations.isPlayStore ? <EuiProgress size="s" color="#00BFB3" value={percentageInstalled} max={100} /> : <></>}
            <EuiProgress size="s" color="#30A8FF" value={percentageUpdated} max={100} />
          </S.Bars>
          <S.IndicatorBar />
        </S.WrapperBars>
        <S.Informations className="informations">
          {installations.isPlayStore ? (
            <EuiToolTip content={t('table.bars.tooltip')}>
              <S.WrapperTooltip>
                <S.Unavailable>{t('table.bars.unavailable')}</S.Unavailable>
                <IconInformation width="12px" height="12px" fill="#30A8FF" />
              </S.WrapperTooltip>
            </EuiToolTip>
          ) : (
            <S.Information color="#30A8FF">
              <b>{installations.updated}</b> {`(${Number.isNaN(percentageUpdated) ? '-' : percentageUpdated}%) ${t('table.bars.updated')}`}
            </S.Information>
          )}
          <S.Information color="#00BFB3">
            <b>{installations.installed}</b> {`(${Number.isNaN(percentageInstalled) ? '-' : percentageInstalled}%) ${t('table.bars.installed')}`}
          </S.Information>
        </S.Informations>
      </S.ColumnEvolution>
    );
  };

  const renderActions = () => {
    return (
      <S.ColumnActions>
        <S.IconsBg>
          <Icon path={mdiContentCopy} size={0.8} />
        </S.IconsBg>
        <S.IconsBg>
          <Icon path={mdiDelete} size={0.8} />
        </S.IconsBg>
      </S.ColumnActions>
    );
  };

  function changeDashboardFilter(filter: DashboardFilterTypes) {
    setDashboardFilterSelected(filter);
    setActivePage(0);
    setSearchFilter('');
  }

  // TODO: waiting edit modal decisions
  // function changeDelegateAction(key: DelegateActionsKeys) {
  //   delegateActions[key] = !delegateActions[key];
  //   setDelegateActions((prev) => {
  //     return { ...prev, delegateActions };
  //   });
  // }

  const appSelection: EuiTableSelectionType<AppsList> = {
    onSelectionChange: (selection) => setSelectedApps(selection),
  };

  const clearSelection = () => {
    if (tableRef.current) {
      tableRef.current.setSelection([]);
    }
  };

  const onPageChange = (page: number) => {
    setActivePage(page);
  };

  useEffect(() => {
    getDistributionCounter();
  }, []);

  useEffect(() => {
    if (dashboardFilterSelected) {
      getApps(activePage, AppHelper.findDistributionParams(dashboardFilterSelected));
    } else if (searchFilter) {
      getApps(activePage, undefined, searchFilter);
    } else {
      getApps(activePage);
    }
  }, [activePage, dashboardFilterSelected]);

  const footer = (
    <S.ModalFooter>
      <EuiButtonEmpty onClick={() => setCreateModalOpen(false)}>{t('dialogFooter.cancel')}</EuiButtonEmpty>
      <EuiButton fill>{t('dialogFooter.continue')}</EuiButton>
    </S.ModalFooter>
  );

  // const message = <EuiEmptyPrompt title={<>Vazio</>} titleSize="xs" body={t('apps.no_results')} />;

  return (
    <S.Container groupsLocale={t('table.groups')}>
      <S.Header>
        <ComponentBreadcrumb noPadding items={OperationalBreadcrumbs.PULSUS_OPERATIONAL_APPS.breadcrumbs} />
        <S.HeaderActions>
          {/* TODO: wating for edit modal decisions */}
          {/* <S.OpenModalButton iconType="plusInCircle" fill onClick={() => setCreateModalOpen(true)}>
            {t('apps.add_new_app')}
          </S.OpenModalButton> */}
        </S.HeaderActions>
      </S.Header>
      <S.FiltersBox>
        <S.Left>
          <S.Title>{t('title')}</S.Title>
          <S.Description>{t('subtitle')}</S.Description>
          <ComponentSearchField
            value={searchFilter}
            placeholder={t('input_search_placeholder')}
            onChange={(e) => setSearchFilter(e.target.value)}
            onSearch={(search) => getApps(0, undefined, search)}
          />
        </S.Left>
        <S.Right>
          <AppsDashboardFilter
            appsPerDistribution={appsPerDistribution}
            filterSelected={dashboardFilterSelected}
            onChangeFilter={(e: DashboardFilterTypes) => changeDashboardFilter(e)}
          />
        </S.Right>
      </S.FiltersBox>
      <DialogComponent footer={footer} maxHeight="auto" open={createModalOpen} onClose={() => setCreateModalOpen(false)}>
        {/* TODO: wating for edit modal decisions */}
        {/* <AddAppModal
          updateType={updateType}
          changeUpdateType={(e: UpdateTypes) => setUpdateType(e)}
          profilesConection={profilesConection}
          changeProfilesConection={(e: boolean) => setProfilesConection(e)}
          delegateActions={delegateActions}
          changeDelegateActions={(e: DelegateActionsKeys) => changeDelegateAction(e)}
        /> */}
      </DialogComponent>
      <S.TableBox>
        {selectedApps.length > 0 && (
          <S.Selected>
            <p>
              {selectedApps.length} {t('apps_selected.selected')}
            </p>
            <EuiButtonEmpty className="link" onClick={clearSelection}>
              {t('apps_selected.undo')}
            </EuiButtonEmpty>
          </S.Selected>
        )}
        <EuiInMemoryTable
          ref={tableRef}
          error={errorMessage}
          itemId="appId"
          columns={columns}
          message={<EuiLoadingContent lines={10} />}
          items={isLoading ? [] : appsTable}
          isSelectable
          selection={appSelection}
          loading={isLoading}
        />
        <S.Pagination aria-label="Pagination Navigation" pageCount={pageCount} activePage={activePage} onPageClick={(page) => onPageChange(page)} />
      </S.TableBox>
      <ComponentToast dismissToast={() => setToastType(undefined)} toastType={toastType} />
    </S.Container>
  );
}
