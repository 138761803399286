import React, { useEffect, useMemo, useState } from 'react';
import { EuiTab } from '@elastic/eui';
import { TabDeviceInformations } from 'pages/device-details/tab-device-informations';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useQuery } from 'hooks/use-query';
import * as style from './page-device-details.style';
import type { DeviceDetailsTabsProps } from './page-device-details.contracts';
import { TabDeviceApps } from './tab-device-apps';

export function DeviceDetailsTabs({
  cpuInfo,
  groupName,
  groupId,
  serialNumber,
  agentSpecificVersion,
  platform,
  deviceId,
  battery_level,
  gps_on,
  total_memory,
  available_memory,
  status,
  applicationData,
  pendingAppsLength,
  installAppsLength,
}: DeviceDetailsTabsProps) {
  const [selected_tab_id, set_selected_tab_id] = useState('informations');

  const { t } = useTranslation();
  const query = useQuery();
  const language = query.get('language');
  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  const tabs_content = [
    {
      id: 'informations',
      name: t('pages.device_details_tabs.tabs_info'),
      content: (
        <TabDeviceInformations
          cpuInfo={cpuInfo}
          groupName={groupName}
          groupId={groupId}
          serialNumber={serialNumber}
          agentSpecificVersion={agentSpecificVersion}
          platform={platform}
          deviceId={deviceId}
          battery_level={battery_level}
          gps_on={gps_on}
          total_memory={total_memory}
          available_memory={available_memory}
          applicationLength={applicationData.length}
          pendingAppsLength={pendingAppsLength}
          installAppsLength={installAppsLength}
        />
      ),
      disabled: false,
    },
    {
      id: 'app',
      name: t('pages.device_details_tabs.tabs_apps'),
      content: <TabDeviceApps status={status} applicationData={applicationData} />,
      disabled: false,
    },
    {
      id: 'data',
      disabled: true,
      name: t('pages.device_details_tabs.tabs_data'),
      content: <>{t('pages.device_details_tabs.tabs_data')}</>,
    },
    {
      id: 'notification',
      disabled: true,
      name: t('pages.device_details_tabs.tabs_notification'),
      content: <>{t('pages.device_details_tabs.tabs_notification')}</>,
    },
    {
      id: 'calls',
      disabled: true,
      name: t('pages.device_details_tabs.tabs_calls'),
      content: <>{t('pages.device_details_tabs.tabs_calls')}</>,
    },
    {
      id: 'debug',
      disabled: true,
      name: t('pages.device_details_tabs.tabs_debug'),
      content: <>{t('pages.device_details_tabs.tabs_debug')}</>,
    },
  ];

  const selectedTabsContent = useMemo(() => {
    return tabs_content.find((obj) => obj.id === selected_tab_id)?.content;
  }, [selected_tab_id, tabs_content]);

  const onSelectedTabChanged = (id: string) => {
    set_selected_tab_id(id);
  };

  return (
    <>
      <style.TabsContainer>
        <style.TabsSelector>
          {tabs_content.map((tab) => (
            <EuiTab key={tab.id} onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id === selected_tab_id} disabled={tab.disabled}>
              {tab.name}
            </EuiTab>
          ))}
        </style.TabsSelector>
      </style.TabsContainer>

      {selectedTabsContent}
    </>
  );
}
