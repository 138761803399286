import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';

export const Container = styled.div``;

export const Button = styled(EuiButton)`
  & {
    background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
    color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
    border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
  }

  &:hover {
    text-decoration: none !important;
  }
`;
