import type { ReactElement } from 'react';
import * as S from './GeneralConfigComponent.styles';
import { mdiWifiStrength4, mdiBluetooth, mdiCameraOutline, mdiVolumeHigh, mdiMicrophoneOff, mdiAccountMinus } from '@mdi/js';
import { ComponentRubyGroupButton, ComponentRubyRadioButtons, ComponentRubySwitchButton } from 'modules/core/components';
import { AdvancedFlag } from 'components';
import { Flags } from './data';
import type { SelectedIdTypes } from 'modules/core/components/ComponentRubyGroupButton/ComponentRubyGroupButton.contracts';
import type { GeneralConfigComponentProps } from './GeneralConfigComponent.contracts';
import { EuiToolTip } from '@elastic/eui';
import { useTypedTranslation } from 'modules/core/hooks';

export const GeneralConfigComponent = ({ handleChangeForm, generalData }: GeneralConfigComponentProps): ReactElement => {
  const options = [
    { id: 'EXTERNAL_STORAGE_ENUM_DISALLOW', text: 'Não desativar' },
    { id: 'EXTERNAL_STORAGE_ENUM_READ_ONLY', text: 'Compartilhamento de energia via USB' },
    { id: 'EXTERNAL_STORAGE_ENUM_READ_WRITE', text: 'Acesso a dispositivos USB' },
  ];

  const handleChange = (key: keyof typeof generalData, value: unknown) => {
    handleChangeForm({ ...generalData, [key]: value });
  };

  const { t } = useTypedTranslation<'general'>('general');

  return (
    <S.Body>
      <S.Row>
        <S.Side>
          <S.Settings>
            <S.Title>WI-FI</S.Title>
            <AdvancedFlag flags={Flags.wifi} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <ComponentRubyGroupButton
            icon={mdiWifiStrength4}
            selectedId={generalData?.networkMode}
            onChange={(e: SelectedIdTypes) => handleChange('networkMode', e)}
          />
        </S.Side>
      </S.Row>
      <S.Row>
        <S.Side>
          <S.Settings>
            <S.Title>BLUETOOTH</S.Title>
            <AdvancedFlag flags={Flags.bluetooth} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <ComponentRubySwitchButton
            leftText="Não"
            rightText="Sim"
            icon={mdiBluetooth}
            value={generalData?.allowBluetooth}
            onChange={(e) => handleChange('allowBluetooth', e)}
          />
        </S.Side>
      </S.Row>
      <S.Row>
        <S.Side>
          <S.Settings>
            <S.WrapperTitleTootip>
              <S.Title>BLOQUEAR USO DE CÂMERA</S.Title>
              <EuiToolTip display="inlineBlock" position="top" content={t('blockCamTootip')}>
                <S.HelpIcon />
              </EuiToolTip>
            </S.WrapperTitleTootip>
            <AdvancedFlag flags={Flags.camera} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <ComponentRubySwitchButton
            leftText="Não"
            rightText="Sim"
            icon={mdiCameraOutline}
            value={generalData?.videoCaptureAllowed}
            onChange={(e) => handleChange('videoCaptureAllowed', e)}
          />
        </S.Side>
      </S.Row>
      <S.Row>
        <S.Side>
          <S.Settings>
            <S.Title>BLOQUEAR SAÍDA DO SOM</S.Title>
            <AdvancedFlag flags={Flags.soundOutput} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <ComponentRubySwitchButton
            leftText="Não"
            rightText="Sim"
            icon={mdiVolumeHigh}
            value={generalData?.audioOutputAllowed}
            onChange={(e) => handleChange('audioOutputAllowed', e)}
          />
        </S.Side>
      </S.Row>
      <S.Row>
        <S.Side>
          <S.Settings>
            <S.Title>BLOQUEAR USO DO MICROFONE</S.Title>
            <AdvancedFlag flags={Flags.microphone} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <ComponentRubySwitchButton
            leftText="Não"
            rightText="Sim"
            icon={mdiMicrophoneOff}
            value={generalData?.audioCaptureAllowed}
            onChange={(e) => handleChange('audioCaptureAllowed', e)}
          />
        </S.Side>
      </S.Row>
      <S.Row>
        <S.Side>
          <S.Settings>
            <S.WrapperTitleTootip>
              <S.Title>{t('dataEmpty')}</S.Title>
              <EuiToolTip display="inlineBlock" position="top" content={t('dataEmptyTootip')}>
                <S.HelpIcon />
              </EuiToolTip>
            </S.WrapperTitleTootip>

            <AdvancedFlag flags={Flags.microphone} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <ComponentRubySwitchButton
            leftText="Não"
            rightText="Sim"
            icon={mdiAccountMinus}
            value={generalData?.ephemeralUsersEnabled}
            onChange={(e) => handleChange('ephemeralUsersEnabled', e)}
          />
        </S.Side>
      </S.Row>
      <S.Row>
        <S.Side style={{ marginBottom: '32px' }}>
          <S.Settings>
            <S.Title>{t('usbConfig')}</S.Title>
            <AdvancedFlag flags={Flags.microphone} />
          </S.Settings>
        </S.Side>
        <S.Side>
          <S.Settings>
            <ComponentRubyRadioButtons allowEmpty selected={generalData.usbConfig} onClick={(e) => handleChange('usbConfig', e)} options={options} />
          </S.Settings>
        </S.Side>
      </S.Row>
    </S.Body>
  );
};
