/* eslint-disable @typescript-eslint/no-unused-vars */
import MessageIcon from '@mui/icons-material/Message';
import EditIcon from '@mui/icons-material/Edit';
import VibrationIcon from '@mui/icons-material/Vibration';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import LockIcon from '@mui/icons-material/Lock';
import ReregisteIcon from '@mui/icons-material/HowToReg';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import BackupIcon from '@mui/icons-material/Backup';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import { useQuery } from 'hooks/use-query';
import { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { EuiToolTip } from '@elastic/eui';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import HealingIcon from '@mui/icons-material/Healing';
import SecurityUpdateIcon from '@mui/icons-material/SecurityUpdate';
import { ComponentResourceChecker } from 'modules/core/components';
import { ConstantResource } from 'modules/core/utilities';
import { useTypedTranslation } from 'modules/core/hooks';
import * as style from './ActionIconsComponent.style';
import type { MenuDevicesIconsProps } from './ActionIconsComponent.contracts';
import type { BulkActions, FeatureFlagsProps } from 'modules/operational/pages/devices/PageDevices.contracts';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import image_pulsus_backup_contacts from 'assets/images/image-pulsus-backup-contacts.svg';

export function ActionIconsComponent({
  hasDevicesSelected,
  onClickAction,
  hasIosSelected,
  isQuarantineMode,
  hasChromeSelected,
  hasAllDevicesSelected,
  hasOTAUpdate,
  hasLauncherNullSelected,
  hasMacOsSelected,
}: MenuDevicesIconsProps) {
  const { t } = useTypedTranslation<'devices.actions_icons'>('devices.actions_icons');
  const query = useQuery();
  const language = query.get('language');
  const [featureFlags, setFeatureFlags] = useState<FeatureFlagsProps>();
  const [bulkGetPhoneNumberEnabled, setBulkGetPhoneNumberEnabled] = useState(false);
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }

    checkBundles();
    checkFeatureFlag();
  }, []);

  const checkFeatureFlag = () => {
    servicePulsusWeb.getFlags().then((data: FeatureFlagsProps) => setFeatureFlags(data));
  };

  const checkBundles = () => {
    servicePulsusWeb.getResources().then(({ data }: { data: string[] }) => {
      setBulkGetPhoneNumberEnabled(data.includes(ConstantResource.DEVICES.BULK_GET_PHONE_NUMBER));
    });
  };

  const handleAction = (action: Exclude<BulkActions, ''>) => {
    let actionAttributes = {
      isActive: hasDevicesSelected,
      toolTip: <style.TooltipContent>{t(action)}</style.TooltipContent>,
    };

    if (hasLauncherNullSelected) {
      if (['maintenance_mode'].includes(action)) {
        actionAttributes = {
          isActive: false,
          toolTip: <style.TooltipContent>{t('no_launcher')}</style.TooltipContent>,
        };
      }
    }

    if (hasChromeSelected && !hasAllDevicesSelected) {
      actionAttributes = {
        isActive: false,
        toolTip: <style.TooltipContent>{t('chrome_disabled')}</style.TooltipContent>,
      };
    }

    if (hasIosSelected && !hasAllDevicesSelected) {
      if (['get_phone_number', 'renewal', 'find_phone', 'maintenance_mode', 'shutdown'].includes(action)) {
        actionAttributes = {
          isActive: false,
          toolTip: <style.TooltipContent>{t('ios_disabled')}</style.TooltipContent>,
        };
      }
    }

    if (hasMacOsSelected && !hasAllDevicesSelected) {
      if (['get_phone_number', 'renewal', 'find_phone', 'maintenance_mode', 'shutdown', 'send_message'].includes(action)) {
        actionAttributes = {
          isActive: false,
          toolTip: <style.TooltipContent>{t('macos_disabled')}</style.TooltipContent>,
        };
      }
    }

    if (hasAllDevicesSelected) {
      if (['disable_quarantine', 'quarantine_mode', 'update_group', 'maintenance_mode', 'lock_device', 'remove', 'shutdown'].includes(action)) {
        actionAttributes = {
          isActive: false,
          toolTip: <style.TooltipContent>{t('all_selected')}</style.TooltipContent>,
        };
      }
    }

    return actionAttributes;
  };

  return (
    <style.Container className="options-container">
      <style.Section>
        {hasOTAUpdate && (
          <EuiToolTip className="tooltip" position="top" content={<span style={{ fontSize: '11px', padding: '0px' }}>{t('OTAUpdate')}</span>}>
            <style.BgCircle isActive>
              <SecurityUpdateIcon onClick={() => onClickAction('OTAUpdate')} />
            </style.BgCircle>
          </EuiToolTip>
        )}
        {isQuarantineMode && (
          <ComponentResourceChecker identifier={ConstantResource.DEVICES.QUARANTINE_MODE}>
            <EuiToolTip className="tooltip" position="top" content={handleAction('disable_quarantine').toolTip}>
              <style.BgCircle isActive={handleAction('disable_quarantine').isActive}>
                <InsertEmoticonIcon onClick={() => onClickAction('disable_quarantine')} />
              </style.BgCircle>
            </EuiToolTip>

            <EuiToolTip position="top" content={handleAction('quarantine_mode').toolTip}>
              <style.BgCircle isActive={handleAction('quarantine_mode').isActive}>
                <HealingIcon onClick={() => onClickAction('quarantine_mode')} />
              </style.BgCircle>
            </EuiToolTip>
          </ComponentResourceChecker>
        )}
        <style.Divider />

        {featureFlags?.PEM5655_backup_contacts && (
          <EuiToolTip position="top" content={handleAction('backup_contacts').toolTip}>
            <style.BgCircle isActive={handleAction('backup_contacts').isActive}>
              <style.ImagePulsusBackupContacts src={image_pulsus_backup_contacts} onClick={() => onClickAction('backup_contacts')} />
            </style.BgCircle>
          </EuiToolTip>
        )}

        {featureFlags?.EVOLU162_phone_number_capture && bulkGetPhoneNumberEnabled && (
          <EuiToolTip position="top" content={handleAction('get_phone_number').toolTip}>
            <style.BgCircle isActive={handleAction('get_phone_number').isActive}>
              <InfoOutlinedIcon onClick={() => onClickAction('get_phone_number')} />
            </style.BgCircle>
          </EuiToolTip>
        )}

        <EuiToolTip position="top" content={handleAction('sync').toolTip}>
          <style.BgCircle isActive={handleAction('sync').isActive}>
            <CachedIcon onClick={() => onClickAction('sync')} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={handleAction('send_message').toolTip}>
          <style.BgCircle isActive={handleAction('send_message').isActive}>
            <MessageIcon onClick={() => onClickAction('send_message')} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={handleAction('renewal').toolTip}>
          <style.BgCircle isActive={handleAction('renewal').isActive}>
            <ReregisteIcon onClick={() => onClickAction('renewal')} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={handleAction('update_group').toolTip}>
          <style.BgCircle isActive={handleAction('update_group').isActive}>
            <EditIcon onClick={() => onClickAction('update_group')} />
          </style.BgCircle>
        </EuiToolTip>

        <style.Divider />

        <EuiToolTip position="top" content={handleAction('find_phone').toolTip}>
          <style.BgCircle isActive={handleAction('find_phone').isActive}>
            <VibrationIcon onClick={() => onClickAction('find_phone')} />
          </style.BgCircle>
        </EuiToolTip>

        {/* this action will enter when we have the endpoint for it - TODO: verify the actions rules for it */}
        {/* <EuiToolTip position="top" content={handleAction('shutdown').toolTip}>
          <style.BgCircle isActive={handleAction('shutdown').isActive}>
            <PowerSettingsNewIcon onClick={() => onClickAction('shutdown')} />
          </style.BgCircle>
        </EuiToolTip> */}

        <EuiToolTip position="top" content={handleAction('maintenance_mode').toolTip}>
          <style.BgCircle isActive={handleAction('maintenance_mode').isActive}>
            <BuildIcon onClick={() => onClickAction('maintenance_mode')} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={handleAction('lock_device').toolTip}>
          <style.BgCircle isActive={handleAction('lock_device').isActive}>
            <LockIcon onClick={() => onClickAction('lock_device')} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={handleAction('remove').toolTip}>
          <style.BgCircle isActive={handleAction('remove').isActive}>
            <DeleteIcon onClick={() => onClickAction('remove')} style={{ color: '#f44336' }} />
          </style.BgCircle>
        </EuiToolTip>
      </style.Section>
    </style.Container>
  );
}
