/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
import { EuiButtonEmpty, EuiFieldText, EuiFormRow, EuiSelect, EuiToolTip, EuiSwitch } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import entrustLogo from 'assets/images/entrust-logo.png';
import btnPlus from 'assets/images/btn-plus.png';
import exclamationIcon from 'assets/images/exclamationIcon.png';
import axios from 'axios';
import { ServicePulsusIduna } from 'services/ServicePulsusIduna';
import { useTranslation } from 'react-i18next';
import { ServicePulsusFreyr } from 'services/ServicePulsusFreyr';
import { HelperAuthentication } from 'helpers';
import * as style from './certificate-identity-modal.style';
import type { CertificateIdentityModalProps } from '../page-certificate.contracts';
import { AppModal } from '../certificate-identity-app-modal/certificate-identity-app-modal';
import type { AppProps } from '../certificate-identity-app-modal/certificate-identity-app-modal.contracts';

export function CertificateIdentityModal({ onClose, openFinishedModal }: CertificateIdentityModalProps) {
  const { t } = useTranslation();
  const [name, setName] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [email, setEmail] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [company, setCompany] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [cn, setCn] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [state, setState] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [country, setCountry] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [city, setCity] = useState({ value: '', error: t('pages.certificate.error_message'), hasError: false });
  const [isActiveAuthorizeSwitch, setIsActiveAuthorizeSwitch] = useState(false);
  const [countriesList, setCountriesList] = useState<any[] | never>([]);
  const [firstEntry, setFirstEntry] = useState(false);
  const FreyrService = useMemo(() => new ServicePulsusFreyr(), []);

  const [is_open_type_modal, set_is_open_type_modal] = useState(false);
  const [selectionGroups, setSelectedGroups] = useState<AppProps[]>([]);

  const [searchValue, setSearchValue] = useState<string>('');
  const [groups, setGroups] = useState<AppProps[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [radioSelected, setRadioSelected] = useState('E-mail');

  const handleOnClose = () => {
    onClose();
  };

  const cancelForm = () => {
    onClose();
    setSelectedGroups([]);
  };

  const handleFilterApps = () => {
    setLoading(true);
    FreyrService.fetchAllAppsByQuery(HelperAuthentication.decodedGroupPolicyId(), searchValue)
      .then((items: AppProps[]) => {
        setGroups(items);
        setLoading(false);
      })
      .catch((e: string | undefined) => {
        throw new Error(e);
      });
  };

  useEffect(() => {
    handleFilterApps();
  }, [searchValue, isActiveAuthorizeSwitch]);

  useEffect(() => {
    axios
      .get('https://restcountries.com/v3.1/all')
      .then((response) => {
        const countries: [{ text: string; value: string }] | any = response.data.map((item: { cca2: string; name: { common: string } }) => {
          return {
            value: item?.cca2,
            text: item?.name?.common,
          };
        });

        setCountry({ ...country, value: countries[33]?.value });

        countries.sort((a: { text: string }, b: { text: string }) => {
          if (a.text < b.text) return -1;
          if (a.text > b.text) return 1;
          return 0;
        });

        setCountriesList(countries);
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.log(error);
      });
  }, []);

  useEffect(() => {
    if (countriesList?.length >= 34 && !firstEntry) {
      setCountry({ ...country, value: countriesList[33].value });
      setFirstEntry(true);
    }
  }, [countriesList]);

  const handleValidationForm = () => {
    if (name.value === '') {
      setName({ ...name, hasError: true });
    } else {
      setName({ ...name, hasError: false });
    }
    if (email.value === '') {
      setEmail({ ...email, hasError: true });
    } else {
      setEmail({ ...email, hasError: false });
    }

    if (cn.value === '') {
      setCn({ ...cn, hasError: true });
    } else {
      setCn({ ...cn, hasError: false });
    }

    if (city.value === '') {
      setCity({ ...city, hasError: true });
    } else {
      setCity({ ...city, hasError: false });
    }
    if (state.value === '') {
      setState({ ...state, hasError: true });
    } else {
      setState({ ...state, hasError: false });
    }

    if (company.value === '') {
      setCompany({ ...company, hasError: true });
    } else {
      setCompany({ ...company, hasError: false });
    }
  };

  const handleChangeCN = () => {
    if (radioSelected === radiosCN[1].id) {
      return radiosCN[1].var;
    }
    return radiosCN[0].var;
  };

  const submitForm = () => {
    handleValidationForm();
    if (name.value !== '' && company.value !== '' && email.value !== '' && state.value !== '' && city.value !== '') {
      const selectionIds = selectionGroups.map((group) => {
        return group.id;
      });
      const body = {
        alias: name.value,
        common_name: handleChangeCN(),
        policy_id: HelperAuthentication.decodedGroupPolicyId(),
        email: email.value,
        country: country.value,
        state: state.value,
        location: city.value,
        organization: company.value,
        applications: selectionIds || [],
      };
      ServicePulsusIduna.createTemplate(HelperAuthentication.decodingTenantId(), body).then(() => {
        handleOnClose();
        openFinishedModal();
      });
    }
  };

  const handleOpenModal = () => {
    set_is_open_type_modal(true);
  };
  const handleCloseConfigModal = () => {
    set_is_open_type_modal(false);
  };
  const handleLabelPhrase = (n: number) => {
    if (n === 1) {
      return `${n} ${t('pages.certificate.label_app_active_single')}`;
    }
    if (n > 1) {
      return `${n} ${t('pages.certificate.label_app_active')}`;
    }
    return `${t('pages.certificate.label_app_disable')}`;
  };

  const handleChangeSwitchApps = (switchState: boolean) => {
    setIsActiveAuthorizeSwitch(!switchState);
    if (isActiveAuthorizeSwitch) {
      setSelectedGroups([]);
      setGroups([]);
    }
  };

  const radiosCN = [
    {
      id: 'E-mail',
      label: 'E-mail',
      var: '$email_address$',
    },
    {
      id: t('pages.certificate.cn_exchange'),
      label: t('pages.certificate.cn_exchange'),
      var: '$user_name$',
    },
  ];

  return (
    <style.Modal onClose={handleOnClose} maxWidth="880px">
      <style.Content>
        <style.Title>{t('pages.certificate.title')}</style.Title>
        <style.TopContainer>
          <style.TopLeft>
            <EuiFormRow label="Alias*:" isInvalid={name.hasError} error={name.error}>
              <EuiFieldText
                placeholder={t('pages.certificate.input_name_label')}
                onChange={(e) => setName({ ...name, value: e.target.value })}
                name="name"
                isInvalid={name.hasError}
              />
            </EuiFormRow>
            <EuiFormRow label={`${t('pages.certificate.input_email_label')}:`} isInvalid={email.hasError} error={email.error}>
              <EuiFieldText
                placeholder={t('pages.certificate.input_email_placeholder')}
                onChange={(e) => setEmail({ ...email, value: e.target.value })}
                name="email"
                isInvalid={email.hasError}
              />
            </EuiFormRow>

            <EuiFormRow label={`${t('pages.certificate.input_company_label')}:`} isInvalid={company.hasError} error={company.error}>
              <EuiFieldText
                placeholder={t('pages.certificate.input_company_placeholder')}
                onChange={(e) => setCompany({ ...company, value: e.target.value })}
                name="company"
                isInvalid={company.hasError}
                className="inputLabel"
              />
            </EuiFormRow>
          </style.TopLeft>
          <style.TopRight>
            <style.CertifierCard>
              <div className="title">
                <p>{t('pages.certificate.entrust_card_title')}</p>
              </div>
              <img className="entrust-logo" src={entrustLogo} alt="Entrust" />
              <p>
                {t('pages.certificate.entrust_card_text')} <b>Entrust</b>
              </p>
            </style.CertifierCard>
            <style.Container>
              <>
                <style.Label htmlFor="common name">Common name (CN):</style.Label>
                <EuiToolTip position="top" content={t('pages.certificate.content_tooltip')}>
                  <style.ExclamationIcon src={exclamationIcon} />
                </EuiToolTip>
                <style.RadiosCN options={radiosCN} onChange={(id) => setRadioSelected(id)} idSelected={radioSelected} name="radio group CN" />
              </>
            </style.Container>
          </style.TopRight>
        </style.TopContainer>
        <style.BottomContainer>
          <EuiFormRow label={`${t('pages.certificate.input_country_label')}:`} isInvalid={country.hasError} error={country.error}>
            <EuiSelect
              id="selectDocExample"
              options={countriesList}
              defaultChecked
              value={country.value}
              onChange={(e) => setCountry({ ...country, value: e.target.value })}
              aria-label="list of countries"
            />
          </EuiFormRow>

          <EuiFormRow label={`${t('pages.certificate.input_state_label')}:`} isInvalid={state.hasError} error={state.error}>
            <EuiFieldText
              placeholder={t('pages.certificate.input_state_placeholder')}
              onChange={(e) => setState({ ...state, value: e.target.value })}
              name="state"
              isInvalid={state.hasError}
            />
          </EuiFormRow>

          <EuiFormRow label={`${t('pages.certificate.input_city_label')}:`} isInvalid={city.hasError} error={city.error}>
            <EuiFieldText
              placeholder={t('pages.certificate.input_city_placeholder')}
              onChange={(e) => setCity({ ...city, value: e.target.value })}
              name="city"
              isInvalid={city.hasError}
            />
          </EuiFormRow>
        </style.BottomContainer>
        <style.AuthorizeSwitch>
          <EuiSwitch
            label={t('pages.certificate.switch_authorization')}
            checked={isActiveAuthorizeSwitch}
            onChange={() => handleChangeSwitchApps(isActiveAuthorizeSwitch)}
          />
        </style.AuthorizeSwitch>
        <style.SubmitContainer>
          <style.WrapBottonContent>
            {isActiveAuthorizeSwitch && (
              <style.LinkAddApp onClick={() => handleOpenModal()}>
                <style.BtnPlus src={btnPlus} alt="plus icon" />
                <style.AddAppText>{handleLabelPhrase(selectionGroups.length)}</style.AddAppText>
              </style.LinkAddApp>
            )}
          </style.WrapBottonContent>
          <div>
            <EuiButtonEmpty onClick={cancelForm}>{t('pages.certificate.cancel_label')}</EuiButtonEmpty>
            <style.Button onClick={submitForm}>{t('pages.certificate.create_button')}</style.Button>
          </div>
        </style.SubmitContainer>
        {is_open_type_modal && (
          <AppModal
            setGroups={setGroups}
            groups={groups}
            setSearchValue={setSearchValue}
            onClose={handleCloseConfigModal}
            certificateName={name.value || ''}
            setSelectedGroups={setSelectedGroups}
            selectionGroups={selectionGroups}
            loading={loading}
          />
        )}
      </style.Content>
    </style.Modal>
  );
}
