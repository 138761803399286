import React from 'react';
import type { SecuritySelectBoxProps } from './page-security-settings.contracts';
import * as style from './security-select-box.style';

export const SelectBox = (props: SecuritySelectBoxProps) => {
  const { options, label, additionalText, required, fullWidth, disabled, fieldDescription, warningText, value, onChange } = props;
  return (
    <style.SelectContainer>
      <style.TitleGroup>
        {label && <style.SelectLabel>{label}</style.SelectLabel>}
        {required && <style.RequiredIcon>*</style.RequiredIcon>}
        {additionalText && <style.InfoButton>{additionalText}</style.InfoButton>}
      </style.TitleGroup>
      <style.FieldGroup active={!!fieldDescription}>
        <div>
          <style.SelectField fullWidth={fullWidth} value={value} options={options} disabled={disabled} onChange={(e) => onChange(e.target.value)} />
        </div>
        {fieldDescription && <style.SelectFieldDescription>{fieldDescription}</style.SelectFieldDescription>}
      </style.FieldGroup>
      {warningText && (
        <style.WarningGroup>
          <style.WarningIcon type="iInCircle" />
          <style.WarningMessage>{warningText}</style.WarningMessage>
        </style.WarningGroup>
      )}
    </style.SelectContainer>
  );
};
