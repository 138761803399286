import styled from 'styled-components';
import { ConstantColors } from 'utilities';

const Container = styled.div`
  position: relative;
  background: ${ConstantColors.BRAZIL};
  padding-bottom: 30px;
  padding: 25px 50px 0px 50px;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  color: #1a1c21;
`;
const Title = styled.h3``;
const AlertGroup = styled.div`
  margin-top: 20px;
`;

const Item = styled.div`
  display: flex;
`;

export { Container, Header, Title, AlertGroup, Item };
