import styled, { css } from 'styled-components';
import WarningPasswordImg from 'assets/images/maintenanceImg.png';

export const EditModalContent = styled.section`
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
`;

export const MessageModalContent = styled.section`
  ${({ theme }) => css`
    .euiFormLabel-isFocused {
      color: ${theme.main};
    }
    .euiTextArea {
      height: 80px;
      &:focus {
        background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%);
      }
    }
  `}
`;

export const ModalsFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 20px;

    .euiButton {
      background-color: ${theme.main} !important;
      border: none;

      &:hover {
        filter: brightness(0.9);
        background-color: ${theme.main};
        border: none;
      }

      &:disabled {
        background-color: #afafaf !important;
      }
    }

    .euiButton__text {
      color: #fff;
    }
  `}
`;

export const WarningPasswordContent = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 326px;
  width: 100%;
`;

export const WarningPasswordLeft = styled.div`
  height: 326px;
  width: 390px;
  background-image: url(${WarningPasswordImg});
  background-repeat: no-repeat;
  background-size: 280px;
`;

export const WarningPasswordRight = styled.div`
  height: 326px;
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const WarningPasswordFirst = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 28px;
  margin-top: 18px;
  color: #ff4c0c;
`;

export const WarningPasswordSecond = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  margin-top: 18px;
`;

export const WarningPasswordLabel = styled.label`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
  margin-top: 55px;
`;

export const Container = styled.div``;

export const SoundSignalContent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const SoundSignalText = styled.p`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  letter-spacing: -1px;
  color: #ff4c0c;
`;

export const SoundSignalImg = styled.img`
  width: 268px;
  height: 272px;
  margin: 26px auto 10px auto;
`;

export const BlockModalContent = styled.section`
  padding: 0 10px 20px 24px;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .euiSwitch {
    margin-bottom: 17px;
  }
`;

export const ReregisterImg = styled.img`
  width: 268px;
  height: 272px;
  margin: 0 auto 20px auto;
`;

export const ReregisterIcons = styled.img`
  width: 63px;
  height: 74px;
  position: absolute;
  top: 110px;
  left: 150px;
`;
