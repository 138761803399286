import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import { ComponentPulsusInsightsContent } from 'components';
import React, { useState, useEffect } from 'react';
import { ConstantRoutes, ConstantLanguages } from 'utilities';
import { HelperAuthentication } from 'helpers';
import { ResourceContextProvider } from 'modules/core/contexts';
import * as style from './embedded-pulsus-insights.style';

export function EmbeddedPulsusInsights() {
  const urlPulsusSignals = `${ConstantRoutes.EMBEDDED_PULSUS_SIGNALS.path}?content_only=true`;
  const urlPulsusBatteries = ConstantRoutes.EMBEDDED_PULSUS_BATTERIES.path;

  const [displaySignals, setDisplaySignals] = useState(true);
  const [displayBatteries, setDisplayBatteries] = useState(true);

  const query = useQuery();

  const language = query.get('language') || ConstantLanguages.PT_BR;

  useEffect(() => {
    i18next.changeLanguage(language);
  }, []);

  const sendPostMessage = (payload = {}) => {
    window.parent.postMessage(JSON.stringify(payload), process.env.REACT_APP_RUBY_LINK!);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const displayPulsusInsigthsFlags = (event: any) => {
    if (event && event?.data && typeof event?.data === 'string') {
      const data = JSON.parse(event?.data);
      if (data.token) {
        const jwtToken = data?.token;
        localStorage.setItem(HelperAuthentication.key(), jwtToken);
      }

      if (data.JOTUNHEIM_199_insight_signal === false) {
        setDisplaySignals(false);
      } else {
        setDisplaySignals(true);
      }

      if (data.JOTUNHEIM_199_insight_battery === false) {
        setDisplayBatteries(false);
      } else {
        setDisplayBatteries(true);
      }
    }
  };

  useEffect(() => {
    sendPostMessage({
      from: 'njord',
      event: 'ON_READY',
    });

    window.addEventListener('message', displayPulsusInsigthsFlags);

    return () => {
      window.removeEventListener('message', displayPulsusInsigthsFlags);
    };
  }, []);

  return (
    <style.Container>
      <ResourceContextProvider>
        <ComponentPulsusInsightsContent
          displaySignals={displaySignals}
          displayBatteries={displayBatteries}
          urlPulsusSignals={urlPulsusSignals}
          urlPulsusBatteries={urlPulsusBatteries}
        />
      </ResourceContextProvider>
    </style.Container>
  );
}
