import { Theme } from 'entities/theme';

export const DashboardWhite = {
  background: '#F2F2F2',
  paper: '#FFFFFF',
  bar: '#949494',
  font: '#4A4A4A',
} as const;

export const ConstantThemes = {
  PULSUS: new Theme('PULSUS', '#F15A22', '#FFFFFF', DashboardWhite),
  OCEAN: new Theme('OCEAN', '#004691', '#FFFFFF', DashboardWhite),
  DEEP_OCEAN: new Theme('DEEP_OCEAN', '#001A45', '#FFFFFF', DashboardWhite),
  SPACE: new Theme('SPACE', '#525A69', '#FFFFFF', DashboardWhite),
  ROYAL_PURPLE: new Theme('ROYAL_PURPLE', '#609', '#FFFFFF', DashboardWhite),
  MATERIAL_BLUE: new Theme('MATERIAL_BLUE', '#0D47A1', '#FFFFFF', DashboardWhite),
  MATERIAL_BROWN: new Theme('MATERIAL_BROWN', '#3E2723', '#FFFFFF', DashboardWhite),
  MATERIAL_GRAY: new Theme('MATERIAL_GRAY', '#212121', '#FFFFFF', DashboardWhite),
  MATERIAL_GREEN: new Theme('MATERIAL_GREEN', '#1B5E20', '#FFFFFF', DashboardWhite),
  MATERIAL_RED: new Theme('MATERIAL_RED', '#B71C1C', '#FFFFFF', DashboardWhite),
} as const;

export const DashboardDark = {
  background: '#2F2F2F',
  paper: '#232323',
  bar: '#FF4C0C',
  font: '#FFFFFF',
} as const;
