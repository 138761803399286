import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const LoaderContainer = styled.div<{ overflow?: boolean; height?: string | '100%' }>`
  z-index: 99999;
  border-radius: 8px;
  overflow: hidden;

  @keyframes AnimateBG {
    0% {
      background-position: 50% 50%;
    }
    100% {
      background-position: -100% 50%;
    }
  }

  &.PulsusLoaderAnimation {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: 300% 300%;
    animation: AnimateBG 3s ease infinite;
    background-image: linear-gradient(-90deg, #f8f9fa 0%, #e9ecef 50%, #f8f9fa 100%);
  }

  ${({ overflow, height }) => {
    if (overflow) {
      return css`
        padding-top: 0 !important;
        position: absolute;
        height: ${overflow ? '100%' : height};
        width: 100%;
      `;
    }
    return css`
      display: flex;
      justify-content: center;
      align-items: center;
      height: ${overflow ? '100%' : height};
      width: 100%;
    `;
  }}
`;

export const LoaderContent = styled.div<{ isLoading: boolean }>`
  display: ${({ isLoading }) => (isLoading ? 'none' : 'visible')};
`;
