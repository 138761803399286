import styled, { css } from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { ConstantColors } from 'utilities';
import type { SettingsStatusSectionProps } from 'pages/settings/connectivity/page-connectivity.contracts';
import { EuiConfirmModal, EuiModalBody, EuiModalFooter } from '@elastic/eui';

export const Container = styled.main`
  display: flex;
  gap: 30px;
  width: 100%;
  background: ${ConstantColors.BRAZIL};
  padding: 25px 50px;
  flex-wrap: wrap;

  .box_section {
    display: flex;
    flex-direction: column;
    gap: 30px;
    flex-wrap: wrap;
  }

  body {
    .button_add_new_network {
      width: 242px;
      height: 37.89px;
      border-radius: 5px;
      background-color: ${({ theme }) => theme.main} !important;
      color: ${({ theme }) => theme.contrast} !important;
      border-color: ${({ theme }) => theme.main} !important;
    }
  }
`;
export const SettingsStatusSection = styled.section<SettingsStatusSectionProps>`
  width: 100%;
  height: 90px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .icon_status_container {
    display: flex;

    .left_section_status {
      width: 60px;
    }

    .left_section_status button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: ${ConstantColors.ITALY};
      width: 50px;
    }

    .right_section_status {
      width: 100%;
      margin-left: 20px;
    }

    .right_section_status h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 10px;
      color: ${ConstantColors.GERMANY};
    }

    .right_section_status p {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: ${ConstantColors.ITALY};
    }
  }

  .options_container {
    justify-content: start;
    gap: 3px;
    display: flex;

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      align-items: center;
      text-align: center;
      width: 56px;
      height: 18px;
      background: ${ConstantColors.ENGLAND};
      color: ${ConstantColors.ITALY};
      border-radius: 14px;
    }
    .turn_on_button {
      ${({ is_on }) => css`
        ${is_on &&
        css`
          background: ${ConstantColors.CHILE};
          color: ${ConstantColors.AUSTRIA};
        `}
      `}
    }

    .turn_off_button {
      ${({ is_off }) => css`
        ${is_off &&
        css`
          background: ${ConstantColors.CHILE};
          color: ${ConstantColors.AUSTRIA};
        `}
      `}
    }

    .neutral_button {
      ${({ is_neutral }) => css`
        ${is_neutral &&
        css`
          background: ${ConstantColors.CHILE};
          color: ${ConstantColors.AUSTRIA};
        `}
      `}
    }

    .active {
      background: ${ConstantColors.CHILE};
      color: ${ConstantColors.AUSTRIA};
    }
  }

  .options_container.airplane {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.airplane {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.bluetooth {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.bluetooth {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.mobile_data {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.mobile_data {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.tethering {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.tethering {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.wifi {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.wifi {
      transition: 0.3s;
      opacity: 0.95;
    }
  }
`;

export const WifiNetworksModal = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  .euiModalHeader__title {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;

    h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 27px;
      line-height: 35px;
      text-align: center;
      color: #ff4c0c;
      padding-bottom: 15px;
    }
    p {
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      text-align: center;
      color: #707070;
    }
    b {
      margin-left: 3px;
    }
  }
`;

export const Body = styled(EuiModalBody)`
  align-items: center;
  overflow-y: auto;
  height: 250px;
  display: flex;
  justify-content: center;

  .euiModalBody__overflow {
    .euiBasicTable {
      overflow-y: auto;
      height: 250px;
      width: 500px;
    }

    tbody {
      .euiTableCellContent__text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #67758d !important;
      }
    }
  }

  .add_wifi_form {
    width: 500px;

    .euiFormLabel {
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 18px;
      color: #1a1c21;
    }

    .euiFlexGroup--gutterLarge {
      margin: 0;
    }

    .euiFormControlLayout--group {
      width: auto;
    }
  }
`;

export const Description = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  color: ${ConstantColors.ITALY};
`;

export const Footer = styled(EuiModalFooter)`
  display: flex;
  justify-content: end;
  align-items: center;
  height: 55px;
  margin-top: 10px;

  .button_add_new_network {
    width: 242px;
    height: 37.89px;
    border-radius: 5px;
    background-color: ${({ theme }) => theme.main} !important;
    color: ${({ theme }) => theme.contrast} !important;
    border-color: ${({ theme }) => theme.main} !important;
  }

  .button_to_networks_list {
    width: 297px;
    height: 15px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: #30a8ff;
  }
`;

export const Poupup = styled(EuiConfirmModal)`
  .euiModalHeader__title {
    font-style: normal;
    font-weight: 700;
    font-size: 23px;
    line-height: 32px;
    width: 350px;
  }

  .euiModalBody__overflow {
    width: 390px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
`;

export const TableWifiNetworks = styled.table`
  tr {
    height: 40px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: auto 0;
    width: 100%;
  }

  tr.table_header {
    th.table_titles {
      font-style: normal;
      font-weight: 700;
      font-size: 11px;
      line-height: 118%;
      color: ${ConstantColors.ITALY};
      padding: 0 1px;
    }

    th.table_titles.name {
      width: 200px;
    }

    th.table_titles.security {
      width: 80px;
    }
    th.table_titles.status {
      width: 220px;
    }
    th.table_titles.delete {
      width: 50px;
    }
  }

  tr.table_body {
    border-bottom: 1px solid #d3dae6;
    td.network_name.column {
      display: flex;
      width: 200px;
      padding: 0 1px;
      align-items: center;
      justify-content: start;
    }

    h6.network_name {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      color: #67758d;
    }

    td.security.column {
      width: 100px;
      padding: 0 1px;
    }

    h6.security {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      display: flex;
      align-items: end;
      color: #67758d;
      justify-content: start;
    }

    td.status.column {
      display: flex;
      width: 200px;
      padding: 0 1px;
      justify-content: start;
    }
  }

  td.status.column {
    .network_status {
      .euiSwitch__label {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        color: #343741;
      }

      .euiSwitch__thumb {
        border-color: #fff !important;
      }
    }
  }
`;
