import * as S from './DevicesPerUserTabComponent.style';

import { DeviceTableComponent } from 'modules/core/components/DeviceTable/DeviceTableComponent';
import { useParams } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { ServicePulsusLoki } from 'services';
import { useTypedTranslation } from 'modules/core/hooks';
import type { Device } from 'modules/core/entities/Device/Device.entity';

export const DevicerPerUserTabComponent = () => {
  const [loading, setLoading] = useState(true);
  const [devices, setDevices] = useState<Device[]>([]);
  const [error, setError] = useState<string>(null!);

  const { t } = useTypedTranslation<'devices'>('devices');

  const userId = useParams().id;

  const service = useMemo(() => new ServicePulsusLoki(), []);

  const getUserDevices = async () => {
    try {
      const result = await service.getDevicesByUserId(userId!);
      setDevices(result);
    } catch (err) {
      setError(t('device_error_empty'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getUserDevices();
  }, []);

  return (
    <S.Container>
      <S.TableBox>
        <DeviceTableComponent error={error} embedded omitColumns={['name']} items={devices} loading={loading} />
      </S.TableBox>
    </S.Container>
  );
};
