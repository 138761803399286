export class Theme {
  name: string;

  main: string;

  contrast: string;

  dashboardTheme: {
    background: string;
    paper: string;
    bar: string;
    font: string;
  };

  constructor(name: string, main: string, contrast: string, dashboardTheme: DashboardTheme) {
    this.name = name;
    this.main = main;
    this.contrast = contrast;
    this.dashboardTheme = dashboardTheme;
  }

  theme() {
    return Object.freeze({
      name: this.name,
      main: this.main,
      contrast: this.contrast,
      dashboardTheme: this.dashboardTheme,
    });
  }
}

type DashboardTheme = {
  background: string;
  paper: string;
  bar: string;
  font: string;
};
