/* eslint-disable react/jsx-no-useless-fragment */
import { RedirectReason } from '../../../../constants';
import { useQuery } from 'hooks/use-query';
import { useEffect, type ReactElement } from 'react';
import { useNavigate, useSearchParams, useLocation } from 'react-router-dom';

export const RouteGuardComponent = ({ children }): ReactElement => {
  const navigate = useNavigate();
  const location = useLocation();

  const query = useQuery();

  const [_, setSearchParams] = useSearchParams();

  const decodeJwt = (jwt: string) => {
    const base64Url = jwt.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

    const decoded = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map((c) => {
          return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
        })
        .join('')
    );

    return JSON.parse(decoded);
  };

  useEffect(() => {
    if (!isAuth()) {
      navigate('/login', { replace: true, state: { reason: RedirectReason.EXPIRED_SESSION, location: location.pathname } });
    }
  }, []);

  const isAuth = () => {
    const urlToken = query.get('token');

    if (urlToken) {
      localStorage.setItem('@jwt_token', urlToken);
      setSearchParams({});

      return true;
    }

    const token = localStorage.getItem('@jwt_token');
    if (!token) return false;

    const decoded: { exp: number } = decodeJwt(token);

    return Date.now() <= decoded?.exp * 1000;
  };

  return <>{isAuth() ? children : <></>}</>;
};
