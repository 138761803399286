import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { ManagementDefaultRoutes } from './ManagementRoutes';

export const ManagementRoutes: RouteObject[] = [
  {
    path: '/management',
    element: <Outlet />,
    children: [...ManagementDefaultRoutes],
  },
];
