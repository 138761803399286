import { EmbeddedFullyCardSettings, EnterpriseSettings } from 'embedded';
import { DeviceSecurity } from 'embedded/embedded-device-security/embedded-security-settings';
import { EmbeddedPulsusBatteries } from 'embedded/embedded-pulsus-batteries/embedded-pulsus-batteries';
import { EmbeddedPulsusInsights } from 'embedded/embedded-pulsus-insights/embedded-pulsus-insights';
import { EmbeddedPulsusSignals } from 'embedded/embedded-pulsus-signals/embedded-pulsus-signals';
import { EmbeddedChromeRoutes } from 'modules/chromeOS/routes/EmbeddedChromeRoutes';
import { NotFound } from 'modules/core/components/ComponentNotFound/ComponentNotFound';
import { PageContainer } from 'modules/core/pages';
import { ChromeRoutes } from 'modules/chromeOS/routes';
import { OperationalRoutes } from 'modules/operational/routes';
import {
  PageLogin,
  PageForgotPassword,
  PageSignup,
  PagePulsusInsights,
  PageSSO,
  PagePulsusInsightsBatteries,
  PageCertificate,
  PageAndroidEnterprise,
  ModalEnrollSelector,
  ModalAEEnroll,
  Onboarding,
  PagePulsusInsightsSignals,
  PageDeviceDetails,
  PageSettings,
  PageConnectivity,
  PageSecuritySettings,
  PageSettingsIframe,
} from 'pages';
import { PageHome } from 'pages/home/page-home';
import { Suspense, type ReactElement } from 'react';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';
import { ConstantRoutes } from 'utilities';
import { EmbeddedOperationalRoutes } from 'modules/operational/routes/EmbeddedOperationalRoutes';
import { PageDevices } from 'modules/operational/pages';
import { EmbeddedManagementRoutes } from 'modules/management/routes/EmbeddedManagementRoutes';
import { ManagementRoutes } from 'modules/management/routes';
import { EnvironmentVerificationComponent } from 'components/EnvironmentVerification/EnvironmentVerificationComponent';
import { UserProvider } from '../contexts/user';
import { LoadingPage } from 'pages/loading/LoadingPage';
import { LoadingFallbackComponent } from '../components/LoadingFallback/LoadingFallbackComponent';
import { RouteGuardComponent } from '../components/RouteGuard/RouteGuardComponent';

// TODO Colocar dentro dos módulos de acordo
export const EmbeddedRoutes: RouteObject[] = [
  {
    path: 'android-enterprise/settings-management',
    element: <EnterpriseSettings />,
  },
  {
    path: 'password-policy',
    element: <DeviceSecurity />,
  },
  {
    path: 'fully-managed-settings',
    element: <EmbeddedFullyCardSettings />,
  },
  {
    path: 'pulsus-insights',
    element: <EmbeddedPulsusInsights />,
  },
  {
    path: 'pulsus-insights/batteries',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <EmbeddedPulsusBatteries />
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
];
// TODO Colocar dentro dos módulos de acordo
const DefaultRoutes: RouteObject[] = [
  {
    path: ConstantRoutes.AE_CERTIFICATE.path,
    element: <PageCertificate />,
  },
  {
    path: ConstantRoutes.MODAL_ENROLL.path,
    element: <ModalEnrollSelector />,
  },
  {
    path: ConstantRoutes.LOGIN.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageLogin />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: '/',
    element: (
      <EnvironmentVerificationComponent>
        <PageHome />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.FORGOT_PASSWORD.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageForgotPassword />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.SIGNUP.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageSignup />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.SSO.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageSSO />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.PULSUS_INSIGHTS.path,
    element: (
      <EnvironmentVerificationComponent>
        <PagePulsusInsights />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.PULSUS_INSIGHTS_BATTERIES.path,
    element: (
      <EnvironmentVerificationComponent>
        <PagePulsusInsightsBatteries />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.PULSUS_INSIGHTS_SIGNAL.path,
    element: (
      <EnvironmentVerificationComponent>
        <PagePulsusInsightsSignals />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.DEVICE_DETAILS.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageDeviceDetails />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.ANDROID_ENTERPRISE.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageAndroidEnterprise />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.MODAL_ENROLL_OPTIONS.path,
    element: <ModalAEEnroll />,
  },
  {
    path: ConstantRoutes.ONBOARDING.path,
    element: <Onboarding />,
  },
  // TODO: delete this device route when Rails updates the embedded route
  {
    path: ConstantRoutes.DEVICES.path,
    element: (
      <UserProvider>
        <PageDevices embedded />
      </UserProvider>
    ),
  },
  {
    path: ConstantRoutes.PULSUS_SECURITY_SETTINGS.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageSecuritySettings />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.IFRAME_AE_ANDROID_SETTINGS.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageSettingsIframe />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.AE_ANDROID_SETTINGS.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageSettings />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: ConstantRoutes.AE_CONNECTIVITY.path,
    element: (
      <EnvironmentVerificationComponent>
        <PageConnectivity />
      </EnvironmentVerificationComponent>
    ),
  },
  {
    path: 'embedded/pulsus-insights/signals',
    element: <EmbeddedPulsusSignals />,
  },
  {
    path: 'embedded/loading',
    element: <LoadingPage />,
  },
];

const RootRoutes: RouteObject[] = [
  ...DefaultRoutes,
  {
    path: '/embedded',
    element: <PageContainer embedded />,
    children: [...EmbeddedRoutes, ...EmbeddedChromeRoutes, ...EmbeddedOperationalRoutes, ...EmbeddedManagementRoutes],
  },
  {
    path: '/',
    element: (
      <EnvironmentVerificationComponent>
        <PageContainer />
      </EnvironmentVerificationComponent>
    ),
    children: [...ManagementRoutes, ...ChromeRoutes, ...OperationalRoutes],
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export const RootRoutesComponent = (): ReactElement => useRoutes(RootRoutes)!;
