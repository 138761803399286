import { useEffect, useState, type ReactElement } from 'react';
import * as S from './KioskModeComponent.styles';
import { useTypedTranslation } from 'modules/core/hooks';
import { ComponentRubyRadioButtons } from 'modules/core/components';

import { isValidUrl, truncateText } from 'helpers';

import type { KioskModeComponentProps } from './KioskModeComponent.contracts';

export const KioskModeComponent = ({ kioskApp, setKioskApp }: KioskModeComponentProps): ReactElement => {
  const { t } = useTypedTranslation<'kioskMode'>('kioskMode');

  const [appInput, setAppInput] = useState('');
  const [customUrlValue, setCustomUrlValue] = useState('');
  const [error, setError] = useState(false);
  const [typeKiosk, setTypeKiosk] = useState(true);
  const [isCustomUrl, setIsCustomUrl] = useState(false);

  useEffect(() => {
    setAppInput('');
    setCustomUrlValue('');
    setError(false);
    setIsCustomUrl(false);
  }, [typeKiosk]);

  const showTooltipMessage = () => {
    if (kioskApp.app) {
      return t('btnAddTooltip');
    }
  };

  const optionsType = [
    { id: 'URL', text: t('byUrl') },
    { id: 'CODE', text: t('byCode') },
  ];

  const optionsCustom = [{ id: 'CHROME', text: t('byChrome') }];

  const handleRemoveApp = () => {
    setKioskApp({ app: '', type: 'web', customUrl: '' });
    setAppInput('');
    setCustomUrlValue('');
  };

  const handleAddApp = () => {
    const type = typeKiosk ? 'web' : 'chrome';

    setKioskApp({ app: appInput, type, customUrl: customUrlValue });

    if (!isValidUrl(appInput) && typeKiosk) {
      setError(true);
    } else {
      setError(false);
    }

    setAppInput('');
    setCustomUrlValue('');
  };

  const handleChangeInput = (value: string) => {
    setAppInput(value);

    if (!typeKiosk) return;

    if (!isValidUrl(appInput)) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const handleValidationCode = (): boolean => {
    return !typeKiosk && appInput.length < 32 && appInput.length !== 0;
  };

  const handlePlusBtnValidation = (): boolean => {
    return !!kioskApp.app || appInput === '' || error;
  };

  return (
    <S.Container>
      <S.FlexRow>
        <ComponentRubyRadioButtons
          row
          selected={typeKiosk ? optionsType[0].id : optionsType[1].id}
          onClick={() => setTypeKiosk(!typeKiosk)}
          options={optionsType}
          disabled={!!kioskApp.app}
        />
      </S.FlexRow>
      <S.FlexRow>
        <S.Title>{typeKiosk ? t('title') : t('phraseChrome')}</S.Title>
      </S.FlexRow>
      {!typeKiosk && (
        <S.FlexRow>
          <ComponentRubyRadioButtons row selected={optionsCustom[0].id} onClick={() => {}} options={optionsCustom} disabled={!!kioskApp.app} />
        </S.FlexRow>
      )}
      <S.FlexRow row>
        {typeKiosk ? (
          <S.InputApp
            id="component-error"
            aria-describedby="component-error-text"
            type="url"
            placeholder="URL"
            value={appInput}
            onChange={(e) => handleChangeInput(e.target.value)}
            disabled={!!kioskApp.app}
            endAdornment={
              <S.ContainerInputAdornment position="end" title={showTooltipMessage()}>
                <S.AddAppIcon disable={handlePlusBtnValidation()} onClick={handleAddApp} />
              </S.ContainerInputAdornment>
            }
          />
        ) : (
          <S.InputApp
            id="component-error"
            aria-describedby="component-error-text"
            placeholder={t('inputCode')}
            value={appInput}
            onChange={(event) => {
              const value = event.target.value;
              setAppInput(value.toLowerCase().replace(/[0-9\s]/g, ''));
            }}
            disabled={!!kioskApp.app}
            inputProps={{ maxLength: 32, minLength: 32 }}
            endAdornment={
              !isCustomUrl && (
                <S.ContainerInputAdornment position="end" title={showTooltipMessage()}>
                  <S.AddAppIcon disable={appInput.length !== 32} onClick={handleAddApp} />
                </S.ContainerInputAdornment>
              )
            }
          />
        )}
      </S.FlexRow>
      {error && <S.InputError>{t('error')}</S.InputError>}
      {handleValidationCode() && <S.InputError>{t('errorMessageCode')}</S.InputError>}

      <S.FlexRow>
        <S.GridContainer>
          <S.Headers>
            {(kioskApp.type === 'web' || kioskApp.customUrl) && <div className="topTable"> {t('name')} </div>}
            {kioskApp.type === 'chrome' && <div className="topTable">{t('inputCode')}</div>}
            <div className="topTable">{t('policyInstall')}</div>
            <div className="topTable"></div>
          </S.Headers>

          {!!kioskApp.app && (
            <S.Items>
              <div>
                <p> {kioskApp.customUrl || kioskApp.app}</p>
              </div>

              {(isCustomUrl || kioskApp.customUrl) && (
                <div>
                  <p title={kioskApp.app}>{truncateText(kioskApp.app, 13)}</p>
                </div>
              )}

              <div>
                <p>{t('deviceStatus')}</p>
              </div>

              <S.AppStatusArea>
                <S.DeleteAppIcon onClick={handleRemoveApp} />
              </S.AppStatusArea>
            </S.Items>
          )}
        </S.GridContainer>
        {!Boolean(kioskApp.app) && <S.EmptyApp> {t('noSelected')} </S.EmptyApp>}
      </S.FlexRow>

      <S.FlexRow>
        {typeKiosk && (
          <S.InfoOs>
            <S.InfoIconMessage />
            <p>{t('versionInfo')}</p>
          </S.InfoOs>
        )}
      </S.FlexRow>
    </S.Container>
  );
};
