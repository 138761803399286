import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { StyleProps } from './DevicesPerUserTabComponent.contracts';

export const Container = styled.main`
  ${({ theme }) => css`
    background-color: ${ConstantColors.BRAZIL};
    position: relative;

    a {
      &:hover {
        transition: 0.3s;
        transform: translateY(-2px);
      }
    }

    .link {
      color: ${ConstantColors.CHILE};
      font-weight: 500;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        text-decoration: underline;
        transition: 0.3s;
        transform: translateY(-2px);
      }
    }

    .theme-color {
      color: ${theme.main};
    }

    .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
      border-color: ${theme.main} !important;
      background-color: ${theme.main} !important;

      @keyframes focusRingAnimate {
        0% {
          box-shadow: 0 0 0 6px ${theme.main}00;
        }
        100% {
          box-shadow: 0 0 0 3px ${theme.main}60;
        }
      }
    }
  `}
`;

export const TableBox = styled.div`
  position: relative;
  background-color: #ffffff;

  .euiTableHeaderCell {
    .euiTableCellContent__text {
      font-weight: 500;
      white-space: break-spaces;
    }
  }

  .euiTableCellContent {
    min-height: 40px;

    &__text:not(.battery-text) {
      font-size: 12px;
      color: #67758d;
      width: 100%;
    }
  }

  .euiLoadingContent__singleLine {
    height: 40px;

    &:last-child {
      width: 100%;
    }
  }
`;

export const IdentifierColumn = styled.div`
  display: flex;

  .edit-icon-button {
    opacity: 0.2;
    padding: 5px;

    &:hover {
      opacity: 1;
    }
  }
`;

export const IdentifierLink = styled.a`
  display: flex;
  align-items: center;

  .id {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 5px;
    padding: 3px 7px;
    border: 0.6px solid ${ConstantColors.PORTUGAL};
    border-radius: 8px;
    background-color: ${ConstantColors.AUSTRIA};
    font-weight: 700 !important;
    height: 16px;
    min-width: 60px;

    font-size: 11px;
    text-decoration: underline;
  }
`;

export const DeviceIcon = styled.div`
  position: relative;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-warning {
    position: absolute;
    top: -1px;
    right: -5px;
  }
`;

export const StatusColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const ManagementColumn = styled.div<StyleProps>`
  ${({ color }) => css`
    width: 120px;
    height: 18px;
    color: #ffffff;
    background-color: ${color || 'unset'};
    border-radius: 3px;

    p {
      font-size: 10px;
      font-weight: 500;
      text-align: center;
    }
  `}
`;

export const InfoColumn = styled.div`
  div {
    line-height: 12px;
    color: #67758d;
    font-size: 10px;
    font-weight: 500;
  }
`;
