import styled from 'styled-components';
import { EuiModal, EuiButton, EuiCallOut } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Modal = styled(EuiModal)`
  .euiModal__flex {
    max-height: fit-content !important;
    height: 520px !important;
    width: 770px !important;
  }
  position: relative;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 50px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: ${({ theme }) => theme.main};
`;

export const Subtitle = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  margin-bottom: 18px;
`;

export const SearchGroupsContainer = styled.div`
  height: 80px;
  width: 90%;
  display: flex;
  alignitems: center;
  position: relative;

  .euiSelectable {
    width: 500px;
    overflow-y: auto;
    position: absolute;
    top: 0;
    left: 8%;
  }

  .euiSelectableList__list {
    -webkit-mask-image: none !important;
    z-index: 9 !important;

    ::-webkit-scrollbar-track {
      background: ${ConstantColors.AUSTRIA} !important;
    }
  }

  .euiSelectableListItem {
    background-color: ${ConstantColors.AUSTRIA};
  }

  :hover {
    .list {
      display: initial;
    }
  }

  .list {
    display: none;
    overflow-y: auto;
  }
`;

export const OptionContainer = styled.div`
  display: flex;
  align-items: left;

  .euiBadge.euiBadge--iconLeft {
    margin-right: 0;
    margin-left: auto;
  }

  .euiText {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .EuiHighlight {
    max-width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const ChartFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 0.45fr 2fr;
  grid-template-rows: 1fr 1fr;
  justify-items: center;
  row-gap: 30px;
  width: 90%;
  padding-bottom: 30px;
  align-items: center;

  .type_container {
    grid-column: 1/2;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
  }

  .days_container {
    grid-column: 3/4;
    grid-row: 1/2;
    display: flex;
    flex-direction: column;
  }

  .range_container {
    grid-column: 1/4;
    grid-row: 2/3;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
`;

export const Footer = styled.footer`
  width: 90%;
  display: flex;
  justify-content: end;
  padding: 0 50px;

  .euiButton.euiButton-isDisabled.euiButton--fill {
    color: #88888b !important;
    background-color: #c2c3c6 !important;
    border-color: #c2c3c6 !important;
  }
`;

export const Error = styled(EuiCallOut)`
  width: 260px !important;
  position: absolute;
  left: 80px;
  bottom: 20px;

  .euiCallOutHeader__title {
    font-size: 13px !important;
    line-height: 1rem !important;
  }

  .euiTextColor.euiTextColor--default {
    p {
      font-size: 11px !important;
    }
  }

  .euiCallOut {
    padding: 2px 10px;
  }
`;

export const Button = styled(EuiButton)`
  min-height: 38px;
  background-color: ${({ theme }) => theme.main} !important;
  border-color: ${({ theme }) => theme.main} !important;
  color: #fff;
  width: 200px;
  height: 40px;
  font-size: 14px;
  margin-left: auto;
  margin-right: 0;
`;

export const TableContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  height: 80px;
  margin-top: 10px;
  margin-bottom: 20px;
  overflow-y: auto;

  .euiBasicTable {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100% !important;
  }
`;
