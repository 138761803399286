import styled from 'styled-components';

export const GroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 12px 0;
  align-items: center;
`;

export const AddGroupButton = styled.button`
  all: unset;
  cursor: pointer;
  color: #30a8ff;
  font-size: 14px;
  font-weight: 500;

  &:hover {
    border-bottom: 1px solid #30a8ff;
  }
`;

export const GroupTitle = styled.p`
  color: #1a1c21;
  font-size: 15px;
  font-weight: 700;
`;

export const GroupList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-height: 416px;
  overflow: auto;
`;
