/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import type { Tag } from 'modules/operational/entities';
import SellIcon from '@mui/icons-material/Sell';
import { EuiFieldSearch } from '@elastic/eui';
import { Skeleton } from '@mui/material';
import { useTypedTranslation } from 'modules/core/hooks';
import type { TagListComponentProps } from './TagListComponent.contracts';
import * as S from './TagListComponent.styles';

export const TagListComponent = ({ setTags, tags, loading }: TagListComponentProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');

  const { t } = useTypedTranslation<'devices.add_group_modal.tag_list'>('devices.add_group_modal.tag_list');

  const checkIfCanRenderTag = (tagToCheck: Tag) => {
    return tagToCheck.groups.some((group) => group.selected === false);
  };

  const addGroupsByTag = (tagId: string) => {
    const updatedGroups = tags.map((tag) => {
      if (tag.id === tagId) {
        return {
          ...tag,
          groups: tag.groups.map((group) => ({ ...group, selected: true })),
        } as Tag;
      }
      return { ...tag } as Tag;
    });

    setTags([...updatedGroups]);
  };

  const renderTags = (tag: Tag) => {
    if (checkIfCanRenderTag(tag) && tag.id !== 'noTag') {
      return (
        <S.TagItem key={tag.id}>
          <SellIcon style={{ color: '#0077CC', width: 14, height: 14 }} />
          <S.WrapperButton>
            <S.TagNameButton onClick={() => addGroupsByTag(tag.id)}>{tag.name} </S.TagNameButton>
            <S.GroupCount onClick={() => addGroupsByTag(tag.id)}>
              ({tag.groups.length} {t('group')}
              {tag.groups.length > 1 ? 's' : ''})
            </S.GroupCount>
          </S.WrapperButton>
        </S.TagItem>
      );
    }

    return <></>;
  };

  const renderTagsOrFilteredTags = () => {
    if (inputValue.length > 0) {
      return filteredTags.map((tag) => renderTags(tag));
    }

    return tags.map((tag) => renderTags(tag));
  };

  const filteredTags = useMemo(() => tags.filter((tag) => tag.name.includes(inputValue)), [inputValue, tags]);

  return (
    <>
      <S.GroupTitle>{t('title')}</S.GroupTitle>
      <EuiFieldSearch
        placeholder={t('inputPlaceholder')}
        isLoading={loading}
        disabled={loading}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <S.TagList>
        {loading ? (
          <>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
          </>
        ) : (
          renderTagsOrFilteredTags()
        )}
      </S.TagList>
    </>
  );
};
