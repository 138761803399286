import * as S from './ComponentEmptyButton.style';
import type { ComponentEmptyButtonProps } from './ComponentEmptyButton.contracts';

export function ComponentEmptyButton({ label, onClick, type }: ComponentEmptyButtonProps) {
  return (
    <S.Container>
      <S.CustomEmptyButton colorType={type} onClick={() => onClick()}>
        {label}
      </S.CustomEmptyButton>
    </S.Container>
  );
}
