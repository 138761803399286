import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import MyLocationOutlinedIcon from '@mui/icons-material/MyLocationOutlined';
import SettingsInputAntennaOutlinedIcon from '@mui/icons-material/SettingsInputAntennaOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';

import { useState } from 'react';

import { MenuTab } from '../menu-tab/menu-tab';
import * as style from './settings-menu.style';
import { MenuTag } from '../menu-tag/menu-tag';
import type { SettingsTopMenuProps } from '../page-settings.contracts';

export function SettingsTopMenu({ onChange, activeTab }: SettingsTopMenuProps) {
  const [selected_tag, set_selected_tag] = useState(1);

  return (
    <style.Container>
      <style.Title>Configuração</style.Title>
      <style.Text>
        Configure aqui todas as customizações que precisa fazer no seu grupo de devices, as mudanças feitas aplicaram para todos os devices que se
        encontram asociados ao grupo.
      </style.Text>
      <style.NavContainer>
        <style.TabsContainer>
          <MenuTab icon={<ArticleOutlinedIcon />} text="Geral" isSelected={activeTab === 'Geral'} onClick={(value) => onChange(value)} />
          <MenuTab icon={<LockOutlinedIcon />} text="Segurança" isSelected={activeTab === 'Segurança'} onClick={(value) => onChange(value)} />
          <MenuTab icon={<MyLocationOutlinedIcon />} text="Navegação" isSelected={activeTab === 'Navegação'} onClick={(value) => onChange(value)} />
          <MenuTab
            icon={<SettingsInputAntennaOutlinedIcon />}
            text="Comunicação"
            isSelected={activeTab === 'Comunicação'}
            onClick={(value) => onChange(value)}
          />
          <MenuTab
            icon={<LocationOnOutlinedIcon />}
            text="Localização"
            isSelected={activeTab === 'Localização'}
            onClick={(value) => onChange(value)}
          />
          <MenuTab
            icon={<CheckCircleOutlineOutlinedIcon />}
            text="Certificados"
            isSelected={activeTab === 'Certificados'}
            onClick={(value) => onChange(value)}
          />
          <MenuTab icon={<DisplaySettingsOutlinedIcon />} text="Sistema" isSelected={activeTab === 'Sistema'} onClick={(value) => onChange(value)} />
          <style.SelectAll onClick={() => onChange('Geral')}>Selecionar todos</style.SelectAll>
        </style.TabsContainer>
        <style.TagsContainer>
          <MenuTag text="todas as configurações" isSelected={selected_tag === 1} onClick={() => set_selected_tag(1)} />
          <MenuTag text="sistema" isSelected={selected_tag === 2} onClick={() => set_selected_tag(2)} />
          <MenuTag text="conectividade" isSelected={selected_tag === 3} onClick={() => set_selected_tag(3)} />
          <MenuTag text="redes sem fio" isSelected={selected_tag === 4} onClick={() => set_selected_tag(4)} />
        </style.TagsContainer>
      </style.NavContainer>
    </style.Container>
  );
}
