import { Route } from 'entities/route';
import { Breadcrumb } from 'entities/breadcrumb';

export const ConstantRoutes = {
  HOME: new Route('/', null, [new Breadcrumb('components.breadcrumb.home', '/')]),
  LOGIN: new Route('/login', null),
  NOTIFICATIONS: new Route('/notifications', null),
  FORGOT_PASSWORD: new Route('/forgot-password', null),
  SIGNUP: new Route('/signup', null),
  SSO: new Route('/sso', null),
  PULSUS_INSIGHTS: new Route('/pulsus-insights', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.management', '/'),
    new Breadcrumb('components.breadcrumb.pulsus_insights', ''),
  ]),
  PULSUS_INSIGHTS_BATTERIES: new Route('/pulsus-insights/batteries', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.management', '/'),
    new Breadcrumb('components.breadcrumb.pulsus_insights', '/pulsus-insights'),
    new Breadcrumb('components.breadcrumb.pulsus_insights_batteries', ''),
  ]),
  PULSUS_INSIGHTS_SIGNAL: new Route('/pulsus-insights/signals', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.management', '/'),
    new Breadcrumb('components.breadcrumb.pulsus_insights', '/pulsus-insights'),
    new Breadcrumb('components.breadcrumb.pulsus_insights_signal', ''),
  ]),

  ANDROID_ENTERPRISE: new Route('/android-enterprise', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.management', '/'),
    new Breadcrumb('components.breadcrumb.android_enterprise', '/android-enterprise'),
  ]),

  MODAL_ENROLL: new Route('/enroll', null),
  MODAL_ENROLL_OPTIONS: new Route('/enroll/options', null),
  AE_CERTIFICATE: new Route('/certificates', null),
  EMBEDDED_AE_SETTINGS_MANAGEMENT: new Route('/embedded/android-enterprise/settings-management', null),
  EMBEDDED_DEVICE_SECURITY: new Route('/embedded/password-policy', null),
  ONBOARDING: new Route('/onboarding', null),
  DEVICES: new Route('/device-management', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.pulsus_chrome_os_devices', ''),
  ]),
  DEVICE_DETAILS: new Route('/device-management/:id', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.pulsus_chrome_os_devices', '/device-management'),
    new Breadcrumb('components.breadcrumb.device_details', ''),
  ]),
  EMBEDDED_FULLY_MANAGED_CARD_SETTINGS: new Route('/embedded/fully-managed-settings', null),
  EMBEDDED_PULSUS_INSIGHTS: new Route('/embedded/pulsus-insights', null),
  EMBEDDED_PULSUS_BATTERIES: new Route('/embedded/pulsus-insights/batteries', null),
  EMBEDDED_PULSUS_SIGNALS: new Route('/embedded/pulsus-insights/signals', null),
  NOT_FOUND: new Route('*', null),
  PULSUS_SECURITY_SETTINGS: new Route('/pulsus-security', null),
  AE_ANDROID_SETTINGS: new Route('/android-settings', null, [
    new Breadcrumb('components.breadcrumb.home', '/'),
    new Breadcrumb('components.breadcrumb.settings', '/'),
    new Breadcrumb('components.breadcrumb.settings_A34', ''),
  ]),
  IFRAME_AE_ANDROID_SETTINGS: new Route('/android-settings-iframe', null),
  AE_CONNECTIVITY: new Route('/connectivity', null),
};

export default ConstantRoutes;
