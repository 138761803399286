import styled from 'styled-components';

export const Container = styled.div`
  min-height: 300px;
  margin-top: 50px;
`;

export const TabColumns = styled.div``;

export const Body = styled.div``;
