import type { ReactElement } from 'react';
import * as S from './LoadingPage.styles';
import { LoadingFallbackComponent } from 'modules/core/components/LoadingFallback/LoadingFallbackComponent';
export const LoadingPage = (): ReactElement => {
  return (
    <S.Wrapper>
      <LoadingFallbackComponent />
    </S.Wrapper>
  );
};
