import styled from 'styled-components';

export const Container = styled.div`
  padding: 10px 0;
  display: flex;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
  background: #e0e5ee;
  border-radius: 9px;
  padding: 0px 12px;
  gap: 5px;

  p {
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: #343741;
  }
`;
