export function IconWarning({ width = 10, height = 10, fill = '#FFD600' }) {
  return (
    <svg width={width} height={height} className="icon-warning" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        strokeWidth="10"
        d="M.98 9.248h7.37a.979.979 0 0 0 .848-1.468L5.512 1.412a.978.978 0 0 0-1.694 0L.133 7.78A.979.979 0 0 0 .98 9.248Zm3.685-3.426a.49.49 0 0 1-.49-.49v-.979a.49.49 0 0 1 .979 0v.98c0 .269-.22.489-.489.489Zm.49 1.958h-.98V6.8h.98v.98Z"
        fill={fill}
      />
    </svg>
  );
}
