type IconProps = {
  width?: number | string;
  height?: number | string;
};
export function ColoredChromeOSIcon({ width, height }: IconProps) {
  return (
    <svg width={width || '22'} height={height || '22'} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4445_308726)">
        <path
          d="M22.3897 5.99947C21.3365 4.17538 19.8216 2.66066 17.9974 1.60754C16.1733 0.554421 14.104 0 11.9977 0C9.89138 0 7.82214 0.554421 5.99798 1.60754C4.17381 2.66066 2.65897 4.17538 1.60571 5.99947L2.32571 18.9595L22.3897 5.99947Z"
          fill="url(#paint0_linear_4445_308726)"
        />
        <path
          d="M6.80382 15L1.60782 6C0.55461 7.82421 0.000135449 9.89351 0.000122071 11.9999C0.000108692 14.1063 0.554557 16.1756 1.60774 17.9999C2.66093 19.8241 4.17574 21.3389 5.99992 22.3922C7.82411 23.4454 9.8934 23.9999 11.9998 24L23.7598 12L6.80382 15Z"
          fill="url(#paint1_linear_4445_308726)"
        />
        <path
          d="M17.1961 15L12.0001 24C14.1063 23.9995 16.1753 23.4447 17.9992 22.3913C19.823 21.3379 21.3375 19.823 22.3904 17.9988C23.4433 16.1747 23.9975 14.1055 23.9974 11.9993C23.9973 9.89311 23.4428 7.82403 22.3897 6H12.0001L17.1961 15Z"
          fill="url(#paint2_linear_4445_308726)"
        />
        <path
          d="M12.0001 18C12.7881 18 13.5683 17.8448 14.2962 17.5433C15.0242 17.2417 15.6856 16.7998 16.2428 16.2426C16.7999 15.6855 17.2419 15.0241 17.5434 14.2961C17.8449 13.5681 18.0001 12.7879 18.0001 12C18.0001 11.2121 17.8449 10.4319 17.5434 9.7039C17.2419 8.97595 16.7999 8.31451 16.2428 7.75736C15.6856 7.20021 15.0242 6.75825 14.2962 6.45672C13.5683 6.15519 12.7881 6 12.0001 6C10.4088 6 8.8827 6.63214 7.75748 7.75736C6.63226 8.88258 6.00012 10.4087 6.00012 12C6.00012 13.5913 6.63226 15.1174 7.75748 16.2426C8.8827 17.3679 10.4088 18 12.0001 18Z"
          fill="white"
        />
        <path
          d="M12.0002 16.752C12.6243 16.752 13.2422 16.6291 13.8187 16.3903C14.3953 16.1515 14.9191 15.8015 15.3604 15.3602C15.8017 14.919 16.1517 14.3951 16.3905 13.8186C16.6293 13.242 16.7522 12.6241 16.7522 12C16.7522 11.376 16.6293 10.7581 16.3905 10.1815C16.1517 9.605 15.8017 9.08114 15.3604 8.63988C14.9191 8.19861 14.3953 7.84858 13.8187 7.60977C13.2422 7.37096 12.6243 7.24805 12.0002 7.24805C10.7399 7.24805 9.53123 7.7487 8.64006 8.63988C7.74889 9.53105 7.24823 10.7397 7.24823 12C7.24823 13.2604 7.74889 14.469 8.64006 15.3602C9.53123 16.2514 10.7399 16.752 12.0002 16.752Z"
          fill="#1A73E8"
        />
      </g>
      <defs>
        <linearGradient id="paint0_linear_4445_308726" x1="1.60811" y1="7.49947" x2="22.3897" y2="7.49947" gradientUnits="userSpaceOnUse">
          <stop stopColor="#D93025" />
          <stop offset="1" stopColor="#EA4335" />
        </linearGradient>
        <linearGradient id="paint1_linear_4445_308726" x1="13.2982" y1="23.2488" x2="2.90622" y2="5.2488" gradientUnits="userSpaceOnUse">
          <stop stopColor="#1E8E3E" />
          <stop offset="1" stopColor="#34A853" />
        </linearGradient>
        <linearGradient id="paint2_linear_4445_308726" x1="10.2481" y1="24" x2="20.6401" y2="6.0312" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FCC934" />
          <stop offset="1" stopColor="#FBBC04" />
        </linearGradient>
        <clipPath id="clip0_4445_308726">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
