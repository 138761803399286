import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import React, { useEffect } from 'react';
import { ComponentPulsusInsightsBatteriesContent } from 'components';
import { ConstantRoutes, ConstantLanguages } from 'utilities';
import * as style from './embedded-pulsus-batteries.style';

export function EmbeddedPulsusBatteries() {
  const query = useQuery();

  const language = query.get('language') || ConstantLanguages.PT_BR;

  useEffect(() => {
    i18next.changeLanguage(language);
  }, []);

  return (
    <style.Container>
      <ComponentPulsusInsightsBatteriesContent route={ConstantRoutes.EMBEDDED_PULSUS_BATTERIES.path} />
    </style.Container>
  );
}
