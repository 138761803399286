import { HelperDevice } from 'modules/operational/pages/devices/PageDevices.helper';
import moment from 'moment';

export class Device {
  id: string;
  status: string;
  name: string;
  management: Management;
  soVersion: string;
  lastSync: string;

  deviceId: {
    icon: React.ReactNode;
    id: string;
    os: string | null;
  };

  information: {
    identifier: string;
    model: string;
  };

  group: {
    name: string;
    id: string;
    launcherId: number | undefined | null;
  };

  battery: {
    level: string;
    temperature: number;
    isIOS: boolean;
  };

  constructor(data: Device.Server) {
    this.id = String(data.id);
    this.deviceId = {
      icon: HelperDevice.findIcon(data as any),
      id: String(data.id),
      os: data.platform ? data.platform.toLocaleLowerCase() : 'android',
    };
    this.status = this.getStatusTypeByIndex(data.status);
    this.name = data.user?.full_name || '-';
    this.management = data.management_type;
    this.information = {
      identifier: data.identifier,
      model: data.model,
    };
    this.soVersion = data.os_version || '-';
    this.group = {
      name: data.group?.name || '-',
      id: data.group?.id ? String(data.group?.id) : '-',
      launcherId: data.group?.launcher_id,
    };
    this.lastSync = data.last_contact_at ? moment(data.last_contact_at).fromNow(true) : '-';
    this.battery = {
      level: data.last_heartbeat?.battery_level.toFixed(0) || '',
      temperature: data.last_heartbeat?.battery_temperature ? Math.round(data.last_heartbeat?.battery_temperature) : 0,
      isIOS: data.platform?.toLowerCase() === 'ios',
    };
  }

  getStatusTypeByIndex(status: number) {
    const availableStatus = {
      0: 'created',
      1: 'active',
      2: 'blocked',
      3: 'removed',
      4: 'wiped',
      5: 'unenrolled',
      6: 'lost',
      7: 'tempBlock',
      8: 'safetynetBlocked',
      9: 'safetynetWiped',
    };

    return availableStatus[status];
  }
}

export namespace Device {
  export type Server = {
    id: number;
    status: number;
    management_mode: string | null;
    management_type: Management;
    device_owner: boolean | null;
    group_id?: number;
    os_version: string | null;
    model: string;
    manufacturer: string;
    serial_number: string;
    identifier: string;
    platform: string | null;
    ownership: string;
    last_heartbeat: LastHeartbeat | null;
    group: Group | null;
    user?: User | null;
    ios_info?: iOSInfo | null;
    last_contact_at: string | null;
  };
}

interface LastHeartbeat {
  id: number;
  recorded_at: string;
  battery_level: number;
  battery_temperature: number;
}

interface Group {
  id: number;
  name: string;
  launcher_id?: number | null;
}

interface iOSInfo {
  id: number;
  supervised: boolean;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
}

export enum StatusCode {
  created = 0,
  active = 1,
  blocked = 2,
  removed = 3,
  wiped = 4,
  unenrolled = 5,
  lost = 6,
  tempBlock = 7,
  safetynetBlocked = 8,
  safetynetWiped = 9,
}

type Management = 'ChromeOS' | 'FM' | 'FM_WP' | 'WP' | 'DA' | 'SU' | 'MSU' | 'BA' | 'NU' | 'ChromeFlex' | 'UN';
