import { SaveBar } from 'components/save-bar/component-save-bar';
import { PageConnectivity, PageSecuritySettings, GeneralCardSettings } from 'pages';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ServicePulsusNjord } from 'services/ServicePulsusNjord';
import type { StoreProps } from 'stores/stores.contracts';
import { ComponentApplicationContainer } from 'modules/core/components';
import { CertificatesCardsSettings } from './certificates/certificates-cards-settings';
import { PageLocalization } from './localization/localization';
import { SettingsTopMenu } from './top-menu/settings-menu';
import { PageOperationalSystem } from './operational-system/page-operational-system';
import * as style from './page-settings.style';
import { CardDataPrtotection } from './data-protection/card-data-protection';

export function PageSettingsIframe() {
  const [settings_changed, set_settings_changed] = useState(false);
  const [selected_tab, set_selected_tab] = useState('Geral');
  const [active, policy] = useSelector((store: StoreProps) => [store?.android_settings?.active, store?.android_settings?.policy]);
  const dispatch = useDispatch();

  async function getSettings() {
    const request = await ServicePulsusNjord.getGeneralSettings();
    dispatch({ type: 'SET_ALL_SETTINGS', payload: request });
  }

  useEffect(() => {
    getSettings();
  }, []);

  useEffect(() => {
    set_settings_changed(active);
  }, [active]);

  return (
    <ComponentApplicationContainer>
      {settings_changed && (
        <SaveBar
          animation={settings_changed}
          onCancel={() => dispatch({ type: 'DISCARD_ANDROID_SETTINGS' })}
          onConfirm={() => {
            ServicePulsusNjord.setGeneralSettings(policy, dispatch);
          }}
        />
      )}
      <SettingsTopMenu activeTab={selected_tab} onChange={(e) => set_selected_tab(e)} />
      <style.Container animation={selected_tab === 'Geral'}>
        <style.MenuItem
          className={selected_tab === 'Navegação' ? 'individualAnimation' : ''}
          hidden={!(selected_tab === 'Navegação' || selected_tab === 'Geral')}
        >
          <PageConnectivity />
        </style.MenuItem>

        <style.MenuItemGroups
          hidden={!(selected_tab === 'Sistema' || selected_tab === 'Localização' || selected_tab === 'Geral')}
          className="MenuItemGroups"
        >
          <style.MenuItem
            className={selected_tab === 'Sistema' ? 'individualAnimation' : ''}
            hidden={!(selected_tab === 'Sistema' || selected_tab === 'Geral')}
            style={{ minWidth: '483px', width: '483px', maxWidth: '483px', backgroundColor: '#F9F9F9' }}
          >
            <PageOperationalSystem />
          </style.MenuItem>

          <style.MenuItem
            className={selected_tab === 'Localização' ? 'individualAnimation' : ''}
            hidden={!(selected_tab === 'Localização' || selected_tab === 'Geral')}
            style={{ minWidth: '483px', width: '483px', maxWidth: '483px', backgroundColor: '#F9F9F9' }}
          >
            <PageLocalization />
            <CardDataPrtotection />
          </style.MenuItem>
        </style.MenuItemGroups>

        <style.MenuItem
          className={selected_tab === 'Segurança' ? 'individualAnimation' : ''}
          hidden={!(selected_tab === 'Segurança' || selected_tab === 'Geral')}
        >
          <PageSecuritySettings />
        </style.MenuItem>

        <style.MenuItem
          className={selected_tab === 'Certificados' ? 'individualAnimation' : ''}
          hidden={!(selected_tab === 'Certificados' || selected_tab === 'Geral')}
        >
          <CertificatesCardsSettings />
        </style.MenuItem>
        <style.MenuItem
          className={selected_tab === 'Geral' ? 'individualAnimation' : ''}
          hidden={selected_tab !== 'Geral'}
          style={{ width: '483px', backgroundColor: '#F9F9F9 !important', padding: '25px 50px 25px 50px' }}
        >
          <GeneralCardSettings />
        </style.MenuItem>
      </style.Container>
    </ComponentApplicationContainer>
  );
}
