export class DataUsageDashboard {
  public date: string;
  public mobile: number;
  public wifi: number;
  public mobilePercentage: number;
  public wifiPercentage: number;
  public total: number;

  constructor(data: DataUsageDashboard.Server) {
    this.date = data.reference_date;
    this.mobile = data.total_mobile;
    this.wifi = data.total_wifi;
    this.mobilePercentage = data.total_mobile_percentage;
    this.wifiPercentage = data.total_wifi_percentage;
    this.total = data.total;
  }
}

export namespace DataUsageDashboard {
  export type Server = {
    reference_date: string;
    total_mobile: number;
    total_wifi: number;
    total_wifi_percentage: number;
    total_mobile_percentage: number;
    total: number;
  };
}
