import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ComponentToastProps, Toast } from './component-toast.contracts';
import { StyledToast } from './component-toast.style';

const defaultProps: Partial<ComponentToastProps> = {
  side: 'left',
  toastLifeTimeMs: 6000,
};

type Props = ComponentToastProps & typeof defaultProps;

export function ComponentToast({ dismissToast, toastLifeTimeMs, toastType, toasts, side, ...props }: Props): React.ReactElement {
  const { t } = useTranslation();

  const [usedToasts, setUsedToasts] = useState<Toast[]>();

  const toastError: Toast[] = [
    {
      title: t('components.toast.error.title'),
      color: 'danger',
      iconType: 'faceSad',
      id: '1',
      text: t('components.toast.error.text'),
    },
  ];

  const toastSuccess: Toast[] = [
    {
      title: t('components.toast.success.title'),
      color: 'success',
      iconType: 'faceHappy',
      id: '1',
      text: t('components.toast.success.text'),
    },
  ];
  useEffect(() => {
    if (toastType === 'success') {
      setUsedToasts(toastSuccess);
    } else if (toastType === 'error') {
      setUsedToasts(toastError);
    } else {
      setUsedToasts([]);
    }
  }, [toastType]);

  return <StyledToast dismissToast={dismissToast} side={side} toastLifeTimeMs={toastLifeTimeMs} toasts={toasts || usedToasts} {...props} />;
}

ComponentToast.defaultProps = defaultProps;
