import { FullyManagedWP } from 'pages/modal-ae-enroll/fully-managed-wp/fully-managed-wp';
import { FullyManaged } from 'pages/modal-ae-enroll/fully-managed/fully-managed';
import { WorkProfile } from 'pages/modal-ae-enroll/work-profile/work-profile';
import { useState } from 'react';
import { AndroidEnrollSelector } from './android-enroll-selector';
import * as style from './android-information-topic.style';

export function AndroidEnrollInformation() {
  const [type, setType] = useState('none');

  const data = {
    jsonString: 'string',
    QRcode: 'string',
  };

  const render = {
    fully: <FullyManaged data={data} />,
    fully_work_profile: <FullyManagedWP data={data} />,
    work_profile: <WorkProfile data={data} />,
    none: <AndroidEnrollSelector onSelect={(e) => setType(e)} />,
  };

  return <style.Container>{render[type]}</style.Container>;
}
