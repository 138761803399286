import type { Axios } from 'axios';
import axios from 'axios';
import { HelperAuthentication } from 'helpers';

export class BaseServiceInstance {
  protected _instance: Axios;

  protected constructor(baseURL: string) {
    this._instance = axios.create({
      baseURL,
      headers: {
        Authorization: HelperAuthentication.bearer(),
      },
    });
  }
}
