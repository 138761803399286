export class Tag {
  public id: string;
  public groups: Tag.Group[];
  public name: string;

  constructor(data: Tag.Data) {
    this.id = data.id;
    this.groups = this.adaptGroupsForSelection(data.groups);
    this.name = data.name;
  }

  adaptGroupsForSelection(groups: Tag.Group[]) {
    const adapteeGroups = groups.map((group) => ({ ...group, selected: false }));

    return adapteeGroups;
  }
}

export namespace Tag {
  export interface Data {
    id: string;
    groups: Tag.Group[];
    name: string;
  }
  export interface Server {
    id: number;
    tags: string[];
    name: string;
  }

  export interface Group {
    name: string;
    id: number;
    selected?: boolean;
  }
}
