import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiInMemoryTable } from '@elastic/eui';
import { IconButton } from '@mui/material';
import { useState } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import { IconAndroid } from 'icons';
import { useTranslation } from 'react-i18next';
import * as style from './page-device-details.style';
import type { ApplicationTabProps } from './page-device-details.contracts';

export function TabDeviceApps({ status, applicationData }: ApplicationTabProps) {
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation();

  const columns: EuiBasicTableColumn<unknown>[] = [
    {
      field: 'icon',
      name: '',
      render: () => <IconAndroid width="11px" height="20px" fill="#9BDF83" />,
      width: '40px',
    },
    {
      field: 'app',
      name: t('pages.page_device_apps.device_apps'),
      width: '185px',
    },
    {
      field: 'identifier',
      name: t('pages.page_device_apps.details_apps_identifier'),
      width: '215px',
    },
    {
      field: 'size',
      name: t('pages.page_device_apps.details_apps_size'),
      width: '145px',
    },
    {
      field: 'version',
      name: t('pages.page_device_apps.details_apps_version'),
      width: '145px',
    },
    {
      field: 'status',
      name: t('pages.page_device_apps.details_apps_status'),
      width: '200px',
      render: () => {
        if (status && status === 0) {
          return <style.StatusTag isInstall>{t('pages.page_device_apps.details_apps_installed')}</style.StatusTag>;
        }
        return <style.StatusTag>{t('pages.page_device_apps.details_apps_pending_installation')}</style.StatusTag>;
      },
    },
    {
      field: 'delete',
      name: t('pages.page_device_apps.details_apps_uninstall'),
      width: '90px',
      align: 'center',
      render: () => (
        <IconButton style={{ padding: 5 }}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const pagination = {
    pageIndex,
    pageSize: 4,
    hidePerPageOptions: true,
    totalItemCount: applicationData?.length,
  };

  function onTableChange(index: number) {
    setPageIndex(index);
  }

  return (
    <style.TabsContainer>
      <EuiInMemoryTable
        columns={columns}
        items={applicationData}
        pagination={pagination}
        onChange={(e) => {
          const { page } = e;
          onTableChange(page.index);
        }}
      />
    </style.TabsContainer>
  );
}
