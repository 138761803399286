import styled, { createGlobalStyle, css } from 'styled-components';
import { EuiButtonEmpty, EuiButtonGroup, EuiCard, EuiIcon, EuiTabs, EuiTab } from '@elastic/eui';
import type { CardItemProps, ElementGroupProps, ElementProps } from './embedded-security-settings.contracts';

const GlobalStyle = createGlobalStyle`
  html {
    background: #FFFFFF;
  }

  body {
    background: #FFFFFF;
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const SecurityCard = styled(EuiCard)`
  display: flex;
  align-items: center;
  padding: 10px 0 !important;
  border: none !important;
  overflow: hidden;
  background: #d5d5d5;
  box-shadow: none !important;

  .euiCard__children {
    display: flex;
    flex-direction: column;
    margin: 0;
  }

  .euiCard__children div.small {
    width: 50%;
    min-width: 405px;
  }
`;

const SecurityTitle = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 40px;
`;

const SecurityToggle = styled.div`
  margin-top: 15px;
  .euiSwitch__button[aria-checked='true'] .euiSwitch__body {
    background-color: ${({ theme }) => theme.main} !important;
  }

  .workProfile {
    .euiSwitch__button[aria-checked='true'] .euiSwitch__body {
      background-color: #1483ff !important;
    }
  }
`;

const WifiDescription = styled.p`
  margin-top: 15px;
  font-size: 13px;
  line-height: 15.73px;
  font-weight: 500;
  color: #67758d;
  border-radius: 6px;
`;

const AdvancedSettingsButton = styled(EuiButtonEmpty)`
  margin-top: 15px;
  background: #69707d1a;
  color: #343741;
`;

const CardItem = styled.div`
  padding: 25px;
  ${(props: CardItemProps) => {
    if (props.hidden) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}
`;

const WifiWarningAlert = styled.div`
  font-size: 16px;
  border-radius: 10px;
  background: #fff9e8;
  margin-top: 15px;
`;

const WarningTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
`;

const WarningIcon = styled(EuiIcon)`
  color: #8a6a0a;
`;

const WarningMessage = styled.p`
  color: #8a6a0a;
  font-weight: 500;
  font-size: 11px;
  line-height: 24px;
`;

const WarningSubMessage = styled.p`
  color: #343741;
  font-size: 13px;
  font-weight: 400;
  line-height: 21px;
`;

const FormGroup = styled.div`
  margin-top: 20px;
`;

const AlertMessage = styled.p`
  padding-top: 10px;
  font-size: 12px;
`;

const ElementsGroup = styled.div`
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  ${(props: ElementGroupProps) => {
    if (props.customGap) {
      return css`
        gap: ${props.customGap};
      `;
    }
    return css``;
  }}
`;

const Element = styled.div`
  .euiRangeTick {
    color: ${({ theme }) => theme.main};
  }
  .workProfile {
    .euiRangeTick {
      color: #1483ff;
    }
  }

  ${(props: ElementProps) => {
    if (props.hidden) {
      return css`
        display: none;
      `;
    }
    if (props.minWidth) {
      return css`
        min-width: ${props.minWidth};
      `;
    }
    return css``;
  }}
`;

const ElementContentText = styled.p`
  font-weight: 700;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.08px;

  .workProfile {
    color: #1483ff;
  }
`;

const ContentTextCustomColor = styled.span`
  color: ${({ theme }) => theme.main};
`;

const GroupButtonBox = styled.div`
  margin-top: 15px;

  .euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: ${({ theme }) => theme.main} !important;
    border: 1px solid ${({ theme }) => theme.main} !important;
    box-shadow: inset -1px 0px 0px rgba(19, 34, 149, 0.1);
  }

  .workProfile {
    .euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
      background-color: #1483ff !important;
      border: 1px solid #1483ff !important;
      box-shadow: inset -1px 0px 0px rgba(19, 34, 149, 0.1);
    }
  }
`;

const GroupButton = styled(EuiButtonGroup)`
  .euiButtonGroupButton {
    padding: 20px 10px;
    border: 1px solid #ededed;
    box-shadow: inset -1px 0px 0px rgba(19, 34, 149, 0.1);
  }

  .euiButton__text {
    font-weight: 400!;
    font-size: 14px;
    line-height: 21px;
  }
`;

const Tabs = styled(EuiTabs)`
  margin: 30px 0;

  .euiTab.euiTab-isSelected,
  .euiTab.euiTab-isSelected .euiTab__content {
    color: ${({ theme }) => theme.main} !important;
  }
  .euiTab.euiTab-isSelected {
    box-shadow: inset 0 -2px 0 ${({ theme }) => theme.main};
  }

  .workProfile.euiTab.euiTab-isSelected,
  .workProfile.euiTab.euiTab-isSelected .euiTab__content {
    color: #1483ff !important;
  }

  .workProfile.euiTab.euiTab-isSelected {
    box-shadow: inset 0 -2px 0 #1483ff;
  }

  ${(props) => {
    if (props.hidden) {
      return css`
        display: none;
      `;
    }
    return css``;
  }};
`;

const Tab = styled(EuiTab)`
  .euiTab__content {
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
  }
`;

const TabContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #1483ff;
`;

export {
  GlobalStyle,
  Container,
  SecurityCard,
  SecurityTitle,
  SecurityToggle,
  WifiDescription,
  AdvancedSettingsButton,
  CardItem,
  WifiWarningAlert,
  WarningTitle,
  WarningIcon,
  WarningMessage,
  WarningSubMessage,
  FormGroup,
  AlertMessage,
  ElementsGroup,
  Element,
  ElementContentText,
  ContentTextCustomColor,
  GroupButtonBox,
  GroupButton,
  Tabs,
  Tab,
  TabContent,
};
