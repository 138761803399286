import { useRef } from 'react';

export function useDebounce(fn, delay) {
  const timout_ref = useRef(null);

  function debounced(...args) {
    window.clearTimeout(timout_ref.current);

    timout_ref.current = window.setTimeout(() => {
      fn(...args);
    }, delay);
  }

  return debounced;
}
