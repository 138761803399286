import styled from 'styled-components';

export const Main = styled.button`
  display: flex;
  align-items: center;
  width: 50px;
  height: 28px;
  border-radius: 20px;
  padding: 5px;
  background-color: #6e6e6e;
`;

export const IconContainer = styled.div`
  transition: all 0.5s ease-in-out;
  width: fit-content;
  transform: rotate(360deg);
  transition: all 0.5s ease-in-out;

  svg {
    fill: #ffa500;
  }

  .moon-icon {
    position: absolute;
    opacity: 1;
    transform: scaleX(-1);
  }

  &.dark {
    transition: all 0.5s ease-in-out;
    transform: translateX(18px) rotate(180deg);

    svg {
      fill: #ffffff;
    }
  }

  .hidden {
    transition: all 0.5s ease-in-out;
    opacity: 0;
  }
`;
