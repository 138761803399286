import styled from 'styled-components';

import { EuiModal } from '@elastic/eui';

export const Modal = styled(EuiModal)`
  margin-bottom: 11vh;
  .euiModal__flex {
    max-height: fit-content;
    min-height: 480px;
    min-width: 360px;
  }
`;

export const Video = styled.video`
  width: 240px;
  height: 400px;
`;
export const Container = styled.div`
  margin: 5px;
`;
export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: inherit;
  background-color: #f15a22;
  height: 400px;
  width: 250px;

  p {
    margin: 1rem;
  }
`;
export const Device = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  width: auto;
  padding: 50px 15px 0 15px;
  padding: 50px 15px;
  border-radius: 20px;

  padding: 50px 15px 50px 15px;

  background: #1e1e1e;
  border-radius: 20px;

  display: inline-block;
  position: relative;
  box-sizing: content-box !important;

  @media (min-height: 800px) {
    width: auto;
    height: auto;
    padding: 40px 12px;
    border-radius: 16px;
  }

  :before {
    border-radius: 600px / 50px;
    /* background: inherit; */
    content: '';
    top: 0;
    position: absolute;
    height: 103.1%;
    width: calc(100% - 26px);
    top: 50%;
    left: 50%;
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
  }
`;
export const LauncherPreview = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 30px;
`;

export const Phrase = styled.p`
  font-size: 1rem;
  color: #fff;
`;
export const Spinner = styled.div`
  color: #fff;
  font-size: 11px;
  text-indent: -99999em;
  margin: 30px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  border-radius: 50%;

  :after {
    border-radius: 50%;
    width: 5.2em;
    height: 10.2em;
    background: #f15a22;
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    -webkit-transform-origin: 0.1em 5.1em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
    position: absolute;
    content: '';
  }

  :before {
    border-radius: 50%;
    position: absolute;
    content: '';
  }

  @-webkit-keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }

  @keyframes load2 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;
export const ActionsWrapper = styled.div`
  min-width: 100%;
  min-height: 50px;
  z-index: 9999;
  .actions-container {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background: transparent;
    border-radius: 4px;
    border: 1px solid transparent;

    .action-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 50px;
      background: transparent;
      outline: none !important;
      border: none;
      filter: brightness(0.6);
    }

    .action-button:hover {
      filter: brightness(1);
    }

    .icon-back {
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 20px 10px 0;
      border-color: transparent #ffffff transparent transparent;
      border-radius: 4px;
    }

    .icon-home {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border-radius: 50%;
    }

    .icon-tasks {
      width: 20px;
      height: 20px;
      background: #ffffff;
      border-radius: 4px;
    }
  }
`;
export const Screen = styled.div`
  height: auto;
  width: auto;
`;
export const DeviceLabel = styled.span``;
export const DeviceLabelAlert = styled.div`
  margin: 20px;
  max-width: 350px;
  line-height: 1.2;
`;
