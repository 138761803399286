import { HelperTheme } from 'helpers';
import { IconArrowLink } from 'icons/arrow-link/icon-arrow-link';
import type { QuicklinksCardProps } from '../page-home.contracts';
import * as style from './quicklinks-card.style';

export function QuicklinksCard({ icon, title, text, link, history }: QuicklinksCardProps) {
  const theme_color = HelperTheme.getTheme().main;

  return (
    <style.Wrapper data-testid="page__home-quicklinks-cards" onClick={() => history(link)}>
      {icon}
      <style.Texts>
        <h3 className="theme-color">{title}</h3>
        <p>{text}</p>
      </style.Texts>
      <IconArrowLink color={theme_color} />
    </style.Wrapper>
  );
}
