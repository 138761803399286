import * as S from './DeviceTableComponent.styles';
import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiHealth, EuiInMemoryTable, EuiLoadingContent } from '@elastic/eui';
import { IconBattery2, IconGroup2 } from 'icons';
import { useTypedTranslation } from 'modules/core/hooks';
import type { DevicesList } from 'modules/operational/entities/devices';
import { TextEllipsisComponent } from '../TextEllipsis/TextEllipsisComponent';
import { useNavigate } from 'react-router-dom';
import { mdiCellphoneNfc, mdiMapMarker, mdiPencil } from '@mdi/js';
import Icon from '@mdi/react';
import type { DeviceTableComponentProps } from './DeviceTableComponent.contracts';
import type { DevicesStatus } from 'modules/operational/pages/devices/PageDevices.contracts';
import { HelperDevice } from 'modules/operational/pages/devices/PageDevices.helper';
import { ComponentResourceChecker } from '../ComponentResourceChecker/ComponentResourceChecker';
import { ConstantResource } from 'modules/core/utilities';
import { RemoteViewModalComponent } from 'modules/operational/components/devices';
import { HelperAuthentication } from 'helpers';
import type { ComponentToastProps } from 'components/toast/component-toast.contracts';
import { forwardRef, useState } from 'react';
import { FeatureFlagCheckerComponent } from '../FeatureFlagChecker/FeatureFlagCheckerComponent';
import { useTheme } from 'styled-components';
import type { Device } from 'modules/core/entities/Device/Device.entity';

export const DeviceTableComponent = forwardRef<EuiInMemoryTable<Device>, DeviceTableComponentProps>(
  ({ embedded, hasPermission, loading, omitColumns, getDeviceDetails, ...props }, ref) => {
    const [toastType, setToastType] = useState<ComponentToastProps['toastType']>();
    const [showRemoteViewModal, setShowRemoteViewModal] = useState(false);

    const { t } = useTypedTranslation<'devices'>('devices');

    const theme = useTheme();
    const history = useNavigate();

    const renderIdentifierColumn = (deviceId: Device['deviceId']) => {
      const { icon, id, os } = deviceId;

      return (
        <S.IdentifierColumn>
          <S.IdentifierLink
            href={embedded ? `${process.env.REACT_APP_PULSUS_WEB_HOST}/devices/${id}` : `/operational/device-management/${os}/${id}`}
            target="_top"
            className="link"
          >
            <S.DeviceIcon>{icon}</S.DeviceIcon>
            <div className="id">
              <span>{id}</span>
            </div>
          </S.IdentifierLink>
          {hasPermission && (
            <button
              className="edit-icon-button link"
              title={t('table.edit_devices')}
              type="button"
              onClick={() => getDeviceDetails && getDeviceDetails(id)}
            >
              <Icon path={mdiPencil} size={0.8} color="#67758d" />
            </button>
          )}
        </S.IdentifierColumn>
      );
    };

    const renderStatusColumn = (status: DevicesStatus) => {
      const { color, text } = HelperDevice.findStatusAttributes(status);

      return (
        <S.StatusColumn>
          <EuiHealth style={{ color }} />
          <span className="euiTableCellContent__text">{text}</span>
        </S.StatusColumn>
      );
    };

    const renderNameColumn = (name: string) => {
      return (
        <TextEllipsisComponent className="euiTableCellContent__text" title={name} rows={2}>
          {name}
        </TextEllipsisComponent>
      );
    };

    const renderManagementColumn = (management: Device['management']) => {
      if (management !== 'UN') {
        const { color, text } = HelperDevice.findManagementAttributes(management);

        return (
          <S.ManagementColumn className={management === 'DA' ? 'isDAManagement' : ''} title={text} color={color}>
            <p>{text.toUpperCase()}</p>
          </S.ManagementColumn>
        );
      }
      return <p>-</p>;
    };

    const renderOSColumn = (osVersion: string) => {
      return (
        <TextEllipsisComponent className="euiTableCellContent__text" title={osVersion}>
          {osVersion}
        </TextEllipsisComponent>
      );
    };

    const renderInformationColumn = (information: Device['information']) => {
      const { identifier, model } = information;

      return (
        <S.InfoColumn>
          <TextEllipsisComponent title={identifier}>{identifier}</TextEllipsisComponent>
          <TextEllipsisComponent title={model}>{model}</TextEllipsisComponent>
        </S.InfoColumn>
      );
    };

    const renderGroupColumn = (group: Device['group']) => {
      const { name, id } = group;

      return (
        <S.GroupColumn
          className="link"
          onClick={() => {
            if (embedded) {
              window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_HOST}/groups/${id}`;
            } else {
              history(`/operational/device-management/group/${id}`);
            }
          }}
        >
          <IconGroup2 />
          <TextEllipsisComponent title={name} rows={2}>
            {group.name}
          </TextEllipsisComponent>
        </S.GroupColumn>
      );
    };

    const renderBatteryColumn = (battery: Device['battery']) => {
      const tempColor = HelperDevice.findBatteryTempColor(battery);

      return (
        <S.BatteryColumn>
          {battery.level ? (
            <div>
              <div className="battery-level">
                <p className="euiTableCellContent__text battery-text">{battery.level}%</p>
                <IconBattery2 width="17" height="19" color={theme.palette.FINLAND} />
              </div>
              <FeatureFlagCheckerComponent featureFlag="EVOLU291_battery_temperature">
                {battery.temperature ? (
                  <div className="battery-temp">
                    <EuiHealth color={tempColor} />
                    <p className="euiTableCellContent__text">{battery.temperature}°</p>
                  </div>
                ) : (
                  <p className="battery-undefined euiTableCellContent__text">-</p>
                )}
              </FeatureFlagCheckerComponent>
            </div>
          ) : (
            <p className="battery-undefined euiTableCellContent__text">-</p>
          )}
        </S.BatteryColumn>
      );
    };

    //TODO retirar declaração do devicesList daqui, adicionar em outro lugar
    const defaultColumns: EuiBasicTableColumn<Device>[] = [
      {
        id: 'deviceId',
        field: 'deviceId',
        name: t('table.header.identifier'),
        align: 'center',
        render: (deviceId: Device['deviceId']) => renderIdentifierColumn(deviceId),
      },
      {
        id: 'status',
        field: 'status',
        name: 'Status',
        align: 'center',
        render: (status: DevicesStatus) => renderStatusColumn(status),
      },
      {
        id: 'name',
        field: 'name',
        name: t('table.header.name'),
        align: 'center',
        render: (name: string) => renderNameColumn(name),
      },
      {
        id: 'management',
        field: 'management',
        name: t('table.header.management'),
        render: (management: Device['management']) => renderManagementColumn(management),
        align: 'center',
      },
      {
        id: 'information',
        field: 'information',
        name: t('table.header.information'),
        align: 'center',
        render: (information: Device['information']) => renderInformationColumn(information),
      },
      {
        id: 'information',
        field: 'soVersion',
        name: t('table.header.os'),
        align: 'center',
        render: (osVersion: string) => renderOSColumn(osVersion),
      },
      {
        id: 'group',
        field: 'group',
        name: t('table.header.group'),
        align: 'center',
        render: (group: Device['group']) => renderGroupColumn(group),
      },
      {
        id: 'lastSync',
        field: 'lastSync',
        name: t('table.header.last_sync'),
        align: 'center',
      },
      {
        id: 'battery',
        field: 'battery',
        name: t('table.header.battery'),
        align: 'center',
        render: (battery: Device['battery']) => renderBatteryColumn(battery),
      },
      {
        id: 'actions',
        field: 'deviceId',
        name: t('table.header.actions'),
        align: 'center',
        render: (deviceId: Device['deviceId']) => renderActionsColumn(deviceId),
      },
    ];

    const mountTableColumns = () => {
      if (omitColumns) {
        return defaultColumns.filter((column: any) => !omitColumns.includes(column?.id));
      }

      return defaultColumns;
    };

    const sendPostMessageLocation = (deviceId: string) => {
      const postMessage = {
        target: 'user-list',
        event: 'ON_CLICK',
        args: {
          type: 'locations',
          device_id: deviceId,
        },
      };

      window.parent.postMessage(JSON.stringify(postMessage), process.env.REACT_APP_RUBY_LINK!);
    };

    const remoteViewPostMessage = (deviceId: string) => {
      const postMessage = {
        target: 'user-list',
        event: 'ON_CLICK',
        args: {
          type: 'remote_control',
          device_id: deviceId,
          room: `pulsus${HelperAuthentication.decodedSchemaName()} ${deviceId}`,
        },
      };

      window.parent.postMessage(JSON.stringify(postMessage), process.env.REACT_APP_RUBY_LINK!);
    };

    const handleCheckRemoteBtn = async (deviceId: string) => {
      if (deviceId) {
        remoteViewPostMessage(deviceId);
      } else {
        setToastType('error');
      }
    };

    const renderActionsColumn = (deviceId: DevicesList['deviceId']) => {
      const isMacOS = deviceId.os?.toLowerCase() === 'macos';
      const isIos = deviceId.os?.toLowerCase() === 'ios';

      let titleToShow = '';
      if (isMacOS) {
        titleToShow = t('actions_icons.macos_disabled');
      } else if (isIos) {
        titleToShow = t('actions_icons.ios_disabled');
      } else {
        titleToShow = t('table.show_location');
      }

      // TODO ao subir pra prod, verificar se vai subir a acesso remoto ou não
      return (
        <S.ActionsColumn>
          <ComponentResourceChecker identifier={ConstantResource.DEVICES.REMOTE_VIEW}>
            <FeatureFlagCheckerComponent featureFlag="remote_view">
              <div title={titleToShow}>
                <S.IconButton
                  disabled={isMacOS || isIos}
                  title={t('table.open_remote')}
                  type="button"
                  onClick={() => handleCheckRemoteBtn!(deviceId.id)}
                >
                  <Icon path={mdiCellphoneNfc} size={0.7} color={theme.palette.JORDAN} />
                </S.IconButton>
              </div>
            </FeatureFlagCheckerComponent>
          </ComponentResourceChecker>
          <S.IconButton type="button" onClick={() => sendPostMessageLocation(deviceId.id)}>
            <Icon path={mdiMapMarker} size={0.7} color={theme.palette.JORDAN} />
          </S.IconButton>
          {showRemoteViewModal && <RemoteViewModalComponent onClose={() => setShowRemoteViewModal(false)} deviceId={String(deviceId.id)} />}
        </S.ActionsColumn>
      );
    };

    const checkIfHasError = () => {
      if (loading) {
        return '';
      }

      if (props.items.length <= 0 && !props.error) {
        return t('devices_empty');
      }

      return props.error;
    };

    return (
      <EuiInMemoryTable
        {...props}
        ref={ref}
        pagination={props.pagination!}
        columns={mountTableColumns()}
        message={<EuiLoadingContent lines={10} />}
        loading={loading}
        error={checkIfHasError()}
      />
    );
  }
);
