import { useQuery } from 'hooks/use-query';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useGeofenceContext } from 'modules/management/contexts/GeofenceContext';
import { GeofenceConfigurationPage } from 'modules/management/pages/Locations/GeofenceConfiguration/GeofenceConfigurationPage';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { ConstantLanguages } from 'utilities';
import { HelperAuthentication } from 'helpers';
import { useTranslation } from 'react-i18next';

export const EmbeddedGeofenceConfiguration = (): ReactElement => {
  const [toast, setToast] = useState<Toast[]>([]);
  const { t } = useTranslation('translation', { keyPrefix: 'geofence.table_fences.errors' });

  const { fields, geofences, formatChangedFences } = useGeofenceContext();

  const query = useQuery();

  const token = query.get('token');
  const language = query.get('language') || ConstantLanguages.PT_BR;

  const sendPostMessage = (payload = {}) => {
    window.parent.postMessage(JSON.stringify(payload), process.env.REACT_APP_RUBY_LINK!);
  };

  if (token) {
    HelperAuthentication.login(token);
  }

  useEffect(() => {
    i18next.changeLanguage(language);
  }, []);

  const setEvent = (event: MessageEvent<string>) => {
    try {
      const data = JSON.parse(event.data);
      if (data?.canSave) {
        fetchData();
      }
    } catch (err) {
      setToast([
        {
          color: 'danger',
          id: '1',
          iconType: 'faceSad',
          title: t('baseTitle'),
          text: t('loadingDataError'),
        },
      ]);
    }
  };

  useEffect(() => {
    window.addEventListener('message', setEvent);

    return () => {
      window.removeEventListener('message', setEvent);
    };
  }, [fields, geofences]);

  const formatTriggerBeforeSend = (): number => {
    if (fields.both) return 2;

    if (fields.fenceOut) return 1;

    return 0;
  };

  const getChangedAreas = () => {
    const fenceAsObject = formatChangedFences();
    const formatted = Object.keys(fenceAsObject).map((key) => ({ id: key, status: fenceAsObject[key] }));

    return {
      removed: formatted.filter((active) => active.status === false).map((area) => ({ id: area.id })),
      added: formatted.filter((active) => active.status === true).map((area) => ({ id: area.id })),
    };
  };

  const fetchData = (): void => {
    const { added, removed } = getChangedAreas();
    const adminEmails = Object.values(fields.adminEmails)
      .map((email) => email.label)
      .join(', ');

    const emailAlert = fields.emailAlert;

    const bodyRequest = {
      group_policy_body: {
        trigger: formatTriggerBeforeSend(),
        block_device: fields.blockDevice,
        email_alert: emailAlert,
        email_alert_language: fields.emailAlertLanguage,
        admin_emails: adminEmails,
        sound_alert: fields.soundAlert,
        sound_alert_action: !fields.soundAlert ? 'ONE' : fields.soundAlertAction,
      },
      add_area_id: added,
      remove_area_id: removed,
    };

    if (emailAlert && emailAlert === true) {
      if (adminEmails) {
        sendPostMessage({ body: bodyRequest, from: 'geofence' });
      } else {
        setToast([
          {
            color: 'danger',
            id: '1',
            iconType: 'faceSad',
            title: t('baseTitle'),
            text: t('needAnEmail'),
          },
        ]);
      }
    } else {
      sendPostMessage({ body: bodyRequest, from: 'geofence' });
    }
  };

  return (
    <>
      <GeofenceConfigurationPage />
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
};
