import styled, { css } from 'styled-components';
import type { StyleProps } from './DeviceTableComponent.contracts';

export const BatteryColumn = styled.div`
  p {
    min-width: 25px;
    color: #67758d;
    font-size: 12px;
    white-space: nowrap;
  }

  div {
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  .battery-temp {
    margin-left: 15px;

    p {
      margin-left: -3px;
    }
  }

  .battery-undefined {
    margin-left: 30px;
  }
`;

export const InfoColumn = styled.div`
  div {
    line-height: 12px;
    color: #67758d;
    font-size: 10px;
    font-weight: 500;
  }
`;

export const GroupColumn = styled.button`
  display: flex;
  align-items: center;

  svg {
    min-width: 15px;
  }

  div {
    font-size: 12px;
    text-decoration: underline;
    margin-left: 8px;
    line-height: 15px;
    font-weight: 500;
    text-align: left;

    max-width: 100px;
    overflow: hidden;
    text-overflow: ellipsis;
    border: none;
    max-height: 45px;
  }
`;

export const IdentifierColumn = styled.div`
  display: flex;

  .edit-icon-button {
    opacity: 0.2;
    padding: 5px;

    &:hover {
      opacity: 1;
    }
  }
`;

export const IdentifierLink = styled.a`
  display: flex;
  align-items: center;

  .id {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    padding: 3px 7px;
    border: 0.6px solid ${({ theme }) => theme.palette.PORTUGAL};
    border-radius: 8px;
    background-color: ${({ theme }) => theme.palette.AUSTRIA};
    font-weight: 700 !important;
    height: 16px;
    min-width: 60px;
    font-size: 11px;
    text-decoration: underline;
  }
`;

export const DeviceIcon = styled.div`
  position: relative;
  min-width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;

  .icon-warning {
    position: absolute;
    top: -1px;
    right: -5px;
  }
`;

export const StatusColumn = styled.div`
  display: flex;
  align-items: center;
`;

export const ManagementColumn = styled.div<StyleProps>`
  ${({ color }) => css`
    width: 120px;
    height: 18px;
    color: #ffffff;
    background-color: ${color || 'unset'};
    border-radius: 3px;

    p {
      font-size: 10px;
      font-weight: 500;
      text-align: center;
    }
  `}
`;

export const IconButton = styled.button<{ disabled?: boolean }>`
  ${({ disabled }) => css`
    opacity: ${disabled ? 0.4 : 1};
    pointer-events: ${disabled ? 'none' : 'unset'};
    cursor: ${disabled ? 'unset' : 'pointer'};
  `}
`;

export const ActionsColumn = styled.div`
  display: flex;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin: 0 4px;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.palette.IRAQ};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: ${({ theme }) => theme.palette.BRUNEI};
      transition: 0.3s;
    }
  }
`;
