import { ComponentPasswordField } from 'components';
import { BannerUnlinkDeviceAlert } from 'icons';
import { useTranslation } from 'react-i18next';
import type { PopupRemoveDeviceProps } from './popup-device.contracts';
import * as style from './popup-unlink-device.style';

export function UnlinkDevicePopup(props: PopupRemoveDeviceProps) {
  const { password, onChange } = props;
  const { t } = useTranslation();
  return (
    <style.Container>
      <style.Left>
        <BannerUnlinkDeviceAlert />
      </style.Left>
      <style.Right>
        <style.Header>
          <style.Title>{t('pages.device_management.popups.unlink_device_title')}</style.Title>
          <style.Description>{t('pages.device_management.popups.unlink_device_description')}</style.Description>
        </style.Header>
        <style.Body>
          <ComponentPasswordField
            label={t('pages.device_management.popups.unlink_device_password_input_label')}
            value={password}
            onChange={(e) => onChange(e)}
          />
        </style.Body>
      </style.Right>
    </style.Container>
  );
}
