export class LocationDetails {
  public location?: {
    recordedAt: string;
    provider?: ProviderOptions;
    accuracy?: number;
    deviceId?: number;
  };

  public user?: {
    id?: number;
    firstName?: string;
    lastName?: string;
    email?: string;
  };

  public telephonyInfo?: {
    phoneNumber1?: string;
    phoneNumber2?: string;
    imei1?: string;
    imei2?: string;
  };

  public device?: {
    id?: number;
    identifier?: string;
    model?: string;
    platform?: string;
    osVersion?: string;
  };

  constructor(data: LocationDetails.Server) {
    this.location = {
      recordedAt: data.location?.recorded_at!,
      provider: data.location?.provider,
      accuracy: data.location?.accuracy,
      deviceId: data.location?.device_id,
    };
    this.user = {
      id: data.user?.id,
      firstName: data.user?.first_name,
      lastName: data.user?.last_name,
      email: data.user?.email,
    };
    this.telephonyInfo = {
      phoneNumber1: data.telephony_info?.phone_number_1,
      phoneNumber2: data.telephony_info?.phone_number_2,
      imei1: data.telephony_info?.imei_1,
      imei2: data.telephony_info?.imei_2,
    };
    this.device = {
      id: data.device?.id,
      identifier: data.device?.identifier,
      model: data.device?.model,
      platform: data.device?.platform,
      osVersion: data.device?.os_version,
    };
  }
}

export namespace LocationDetails {
  export type Server = {
    location?: {
      recorded_at: string;
      provider?: ProviderOptions;
      accuracy?: number;
      device_id?: number;
    };
    user?: {
      id?: number;
      first_name?: string;
      last_name?: string;
      email?: string;
    };
    telephony_info?: {
      phone_number_1?: string;
      phone_number_2?: string;
      imei_2?: string;
      imei_1?: string;
    };
    device?: {
      id?: number;
      identifier?: string;
      model?: string;
      platform?: string;
      os_version?: string;
    };
  };
}

export type ProviderOptions = 'fused' | 'gps' | 'network' | 'unknown' | 'title';
