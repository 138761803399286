import type { ReactElement } from 'react';
import { EuiButtonEmpty } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import * as S from './ButtonModalFormComponent.styles';
import type { ButtonModalFormComponentProps } from './ButtonModalFormComponent.contracts';

export const ButtonModalFormComponent = ({ onClose, onClick, disabled, loading }: ButtonModalFormComponentProps): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'geofence.modal_edit.buttons_modal' });

  return (
    <S.WrapperButtons>
      <EuiButtonEmpty onClick={() => onClose(null)} style={{ fontSize: 14 }}>
        {t('cancel')}
      </EuiButtonEmpty>
      <S.StyledButton isLoading={loading} disabled={disabled} type="button" onClick={() => onClick()}>
        {t('save')}
      </S.StyledButton>
    </S.WrapperButtons>
  );
};
