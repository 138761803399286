/* eslint-disable react/self-closing-comp */
import { IconArrowLink } from 'icons/arrow-link/icon-arrow-link';
import * as style from './component-devices-info.style';

type DevicesInfoProps = {
  icon: React.ReactElement;
  number: number;
  text: string;
};

export function DevicesInfo({ icon, number, text }: DevicesInfoProps) {
  return (
    <style.Wrapper>
      <style.Info>
        {icon}
        <div>
          <span className="theme-color">{number}</span>
          <p>{text}</p>
        </div>
      </style.Info>
      <IconArrowLink />
    </style.Wrapper>
  );
}
