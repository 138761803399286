import { ComponentApplicationContainer } from 'modules/core/components';
import { Outlet } from 'react-router-dom';

export function PageContainer({ embedded }: { embedded?: boolean }) {
  return (
    <ComponentApplicationContainer embedded={embedded} test_id="page__home">
      <Outlet />
    </ComponentApplicationContainer>
  );
}
