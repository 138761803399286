/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentBreadcrumb } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ConstantColors, ConstantRoutes } from 'utilities';
import { useQuery } from 'hooks/use-query';
import { EuiAvatar } from '@elastic/eui';
import { ComponentMenuDevicesIcons } from 'components/menu-devices-icons/component-menu-devices-icons';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { DeviceDetailsTabs } from 'pages/device-details/device-details-tabs';
import { ServicePulsusFreyr } from 'services/ServicePulsusFreyr';
import { ComponentApplicationContainer } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import * as style from './page-device-details.style';
import type { Application, ParamsProps } from './page-device-details.contracts';

export function PageDeviceDetails() {
  const query = useQuery();
  const params = useParams() as unknown as ParamsProps;
  const content_only = query.get('content_only');
  const [completeData, setCompleteData] = useState<any>();
  const [applicationData, setApplicationData] = useState<Application[]>([]);
  const { t } = useTranslation();
  const language = query.get('language');

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const serviceFreyr = useMemo(() => new ServicePulsusFreyr(), []);

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  const filterInstallAppsList = () => {
    const installApps = applicationData?.filter((item) => item?.status === 0);
    return installApps.length;
  };

  const filterPendingAppsList = () => {
    const pendingApps = applicationData.length - filterInstallAppsList();

    return pendingApps;
  };

  useEffect(() => {
    serviceLoki
      .getDeviceDetailsById(params.id)
      .then((items) => {
        setCompleteData(items);
      })
      .catch((e) => {
        throw new Error(e);
      });
  }, [params.id]);

  useEffect(() => {
    serviceFreyr
      .fetchAllApplication()
      .then((items) => {
        setApplicationData(items);
      })
      .catch((e) => {
        throw new Error(e);
      });
  }, []);

  return (
    <ComponentApplicationContainer test_id="page__devices">
      {!(content_only === 'true') && <ComponentBreadcrumb items={ConstantRoutes.DEVICE_DETAILS.breadcrumbs} />}
      <style.Container>
        <style.TopContent>
          <style.DeviceInfoSection>
            <div>
              <style.DeviceInfoId>{`ID-${params.id}`}</style.DeviceInfoId>
              <style.DeviceInfoText style={{ marginTop: '25px' }}>
                <span className="label">IMEI</span>
                <span className="text">{completeData?.identifier || '---'}</span>
              </style.DeviceInfoText>
              <style.DeviceInfoText>
                <span className="label">{t('pages.page_device_details.details_model')}</span>
                <span className="text">{completeData?.model}</span>
              </style.DeviceInfoText>
            </div>
            <div>
              <style.DeviceInfoText style={{ marginTop: '6px' }}>
                <span className="label">{t('pages.page_device_details.details_manufacturer')}</span>
                <span className="text">{completeData?.model}</span>
              </style.DeviceInfoText>
              <style.DeviceInfoText>
                <span className="label">{t('pages.page_device_details.details_so')}</span>
                <span className="text" style={{ display: 'flex', gap: '3px' }}>
                  {completeData?.platform === 'Android' && <AndroidIcon style={{ color: '#3DDB85', width: '19px' }} />}

                  {completeData?.platform === 'iOS' && <AppleIcon style={{ color: ConstantColors.ITALY }} />}
                  {completeData?.platform}
                </span>
              </style.DeviceInfoText>
            </div>
          </style.DeviceInfoSection>
          <style.UserInfoSection>
            <style.UserInfoTitle>{t('pages.page_device_details.details_text')}</style.UserInfoTitle>
            <style.UserInfoContent>
              <style.UserInfoAvatarNameEmail>
                <EuiAvatar size="l" name={completeData?.user?.first_name || ''} color="#343741" />
                <p>
                  <span className="name">{`${completeData?.user?.first_name} ${completeData?.user?.last_name}`}</span>
                  <span className="email">jhony@google.com ***</span>
                </p>
              </style.UserInfoAvatarNameEmail>
              <style.DeviceInfoText className="cpf" style={{ marginTop: '0', minWidth: '180px', width: '50%' }}>
                <span className="label">CPF:</span>
                <span className="text">872.549.560-72 ***</span>
              </style.DeviceInfoText>
            </style.UserInfoContent>
          </style.UserInfoSection>
        </style.TopContent>
        <style.MenuIconsContainer>
          <ComponentMenuDevicesIcons />
        </style.MenuIconsContainer>
        <DeviceDetailsTabs
          cpuInfo={completeData?.cpu_info}
          groupName={completeData?.group?.name}
          groupId={completeData?.group?.id}
          serialNumber={completeData?.serial_number}
          agentSpecificVersion={completeData?.agent_specific_version}
          platform={completeData?.platform}
          deviceId={params.id}
          battery_level={completeData?.last_heartbeat?.battery_level}
          gps_on={completeData?.last_heartbeat?.gps_on}
          total_memory={completeData?.last_heartbeat?.total_memory}
          available_memory={completeData?.last_heartbeat?.available_memory}
          status={completeData?.status}
          applicationData={applicationData}
          pendingAppsLength={filterPendingAppsList()}
          installAppsLength={filterInstallAppsList()}
        />
      </style.Container>
    </ComponentApplicationContainer>
  );
}
