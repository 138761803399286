import { EuiButtonEmpty } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const Dashboard = styled.div`
  background: #f9f9f9;
  border-radius: 10px;
  padding: 20px 25px;
`;

export const DashboardTitle = styled.p`
  padding: 0 20px;
  font-weight: 500;
  font-size: 14px;
  line-height: 37px;
  color: #1a1c21;
`;

export const Lines = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  &.divider {
    border-bottom: 1px solid #dfdfdf;
  }
`;

export const CategoryIcon = styled.div`
  button {
    margin: 12px 10px;
    transition: 0.15s;
    transform: translateY(0px);
  }

  button:hover {
    background-color: transparent;
    transition: 0.3s;
    transform: translateY(-2px);
  }

  ${({ isActive }: { isActive?: boolean }) => {
    if (isActive) {
      return css`
        button,
        button:hover {
          background-color: #e2e2e2;
        }
      `;
    }
    return '';
  }};
`;

export const ItemsGroup = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 135px 190px auto;

  :nth-of-type(2) {
    margin-left: -20px;
  }
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  cursor: pointer;
  transition: 0.15s;
  transform: translateY(0px);

  :hover {
    transition: 0.3s;
    transform: translateY(-2px);

    p {
      text-decoration: underline;
    }
  }

  ${({ isActive }: { isActive?: boolean }) => {
    if (isActive) {
      return css`
        padding: 5px;
        border-radius: 7px;
        background-color: #e2e2e2;
      `;
    }
    return '';
  }};
`;

export const ItemIcon = styled.div``;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ItemTitle = styled.p`
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #5a606b;
`;

export const ItemDescription = styled.p`
  font-weight: 300;
  font-size: 11px;
  line-height: 15px;
  color: #5a606b;
`;

export const InfoButton = styled(EuiButtonEmpty)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.08px;
  color: #30a8ff;
`;
