import React from 'react';
import PropTypes from 'prop-types';

export function IconArticles({ width, height, color, test_id }) {
  return (
    <svg data-testid={test_id} id="icon-articles" width={width} height={height} fill={color} viewBox="0 0 16 14" xmlns="http://www.w3.org/2000/svg">
      <title>Articles Icon</title>
      <path d="M0 10H16V8H0V10ZM0 14H16V12H0V14ZM0 6H16V4H0V6ZM0 0V2H16V0H0Z" />
    </svg>
  );
}

IconArticles.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  test_id: PropTypes.string,
};

IconArticles.defaultProps = {
  width: '18px',
  height: '24px',
  color: '#FFFFFF',
  test_id: 'icons__icon-articles',
};
