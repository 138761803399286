import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import { DialogComponent } from 'components';
import type { ConfirmDeleteModalComponentProps } from './ConfirmDeleteModalComponent.contracts';
import { useTypedTranslation } from 'modules/core/hooks';
import * as S from './ConfirmDeleteModalComponent.styles';
import { useMemo, useState } from 'react';
import { ServicePulsusWebDAV } from 'services/ServicePulsusWebDAV/ServicePulsusWebDAV';
import { ComponentToast, type Toast } from 'modules/core/components';

const ConfirmDeleteModalComponent = ({ contactListId, close }: ConfirmDeleteModalComponentProps) => {
  const [toast, setToast] = useState<Toast[]>([]);

  const { t } = useTypedTranslation<'contacts.confirm_delete_modal'>('contacts.confirm_delete_modal');

  const serviceWebDAV = useMemo(() => new ServicePulsusWebDAV(), []);

  const confirmDeletion = async () => {
    try {
      await serviceWebDAV.deleteList(contactListId);
      close();
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request_error.title'),
          text: t('request_error.text'),
          id: '1',
        },
      ]);
    }
  };

  const renderFooter = () => {
    return (
      <S.ModalFooter>
        <EuiButtonEmpty onClick={() => close()}>{t('cancel')}</EuiButtonEmpty>
        <EuiButton onClick={() => confirmDeletion()} fill>
          {t('confirm')}
        </EuiButton>
      </S.ModalFooter>
    );
  };

  return (
    <DialogComponent footer={renderFooter()} width="448px" onClose={close} open={!!contactListId}>
      <S.Content>
        <S.Title>{t('title')}</S.Title>
        <S.Text>{t('text')}</S.Text>
      </S.Content>
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </DialogComponent>
  );
};

export default ConfirmDeleteModalComponent;
