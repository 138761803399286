import { EuiTextColor } from '@elastic/eui';
import { Tab } from '@mui/material';
import { ComponentSteps } from 'components/steps/component-steps';
import { BannerChromeZeroTouch, BannerEnrollDeviceDemo, BannerChromeIntegrationModal } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ChromeEnrollInformationProps, TabPanelProps } from '../enroll-popups.contracts';
import * as style from './chromeOS-information-topic.style';

export function ChromeEnrollInformation(props: ChromeEnrollInformationProps) {
  const { onCancel, onReturn } = props;
  const { t } = useTranslation();
  const [topic, setTopic] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);

  const chromeSteps = [
    {
      title: t('pages.pulsus_chrome_os_integration.step_one_title'),
      children: (
        <>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.step_one_prefix')}</EuiTextColor>
          <EuiTextColor color="secondary">
            <a href="https://support.google.com/chrome/a/topic/7613713" target="_blank" rel="noreferrer">
              {` ${t('pages.pulsus_chrome_os_integration.step_one_sufix')}`}
            </a>
          </EuiTextColor>
        </>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.step_two_title'),
      children: (
        <>
          <EuiTextColor>{`${t('pages.pulsus_chrome_os_integration.step_two_prefix')}`}</EuiTextColor>
          <EuiTextColor color="secondary">
            <a href="https://support.google.com/chrome/a/answer/7532316?hl=en#zippy=" target="_blank" rel="noreferrer">
              {t('pages.pulsus_chrome_os_integration.step_two_sufix')}
            </a>
          </EuiTextColor>
        </>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.step_three_title'),
      children: (
        <>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.step_three_prefix')}</EuiTextColor>
          <EuiTextColor color="secondary">
            <a href="https://support.google.com/chrome/a/answer/1360642?hl=en" target="_blank" rel="noreferrer">
              {` ${t('pages.pulsus_chrome_os_integration.step_three_sufix')}`}
            </a>
          </EuiTextColor>
        </>
      ),
    },
  ];
  const EnrollSteps = [
    {
      title: t('pages.pulsus_chrome_os_integration.enroll_title_start'),
      children: <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_content_start')}</EuiTextColor>,
    },
    {
      title: t('pages.pulsus_chrome_os_integration.enroll_title_gsuit_activation'),
      children: <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_content_gsuit_activation')}</EuiTextColor>,
    },
    {
      title: t('pages.pulsus_chrome_os_integration.enroll_title_end'),
      children: <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_content_end')}</EuiTextColor>,
    },
  ];
  const ZeroTouchSteps = [
    {
      title: t('pages.pulsus_chrome_os_integration.enroll_zero_touch_title_1'),
      children: (
        <>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_prefix_content_1')}</EuiTextColor>
          <a
            className="link"
            href="https://support.google.com/chrome/a/answer/10130175?hl=en&ref_topic=9028498&sjid=7407237768023970751-SA#identify_compatible&zippy=%2Cidentify-compatible-chromeos-devices"
            target="_blank"
            rel="noreferrer"
          >
            {t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_1')}
          </a>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_sufix_content_1')}</EuiTextColor>
        </>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.enroll_zero_touch_title_2'),
      children: (
        <>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_2_prefix_1')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_2_prefix_2')}</EuiTextColor>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_2_prefix_3')}</EuiTextColor>
          <a
            className="link"
            href="https://support.google.com/chrome/a/answer/10130175?hl=en&ref_topic=9028498&sjid=7407237768023970751-SA#identify_compatible&zippy=%2Cidentify-compatible-chromeos-devices"
            target="_blank"
            rel="noreferrer"
          >
            {t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_2_prefix_4')}
          </a>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_2_prefix_5')}</EuiTextColor>
          <EuiTextColor className="bold" color="#343741">
            {t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_2')}
          </EuiTextColor>
        </>
      ),
    },
    {
      title: t('pages.pulsus_chrome_os_integration.enroll_zero_touch_title_3'),
      children: (
        <>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_3_prefix')}</EuiTextColor>
          <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_3')}</EuiTextColor>
          <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_zero_touch_content_3_sufix')}</EuiTextColor>
        </>
      ),
    },
  ];

  function TabPanel(tabProps: TabPanelProps) {
    const { children, value, index } = tabProps;

    return (
      <div hidden={value !== index} id={`Tab_id_${index}`}>
        {value === index && <>{children}</>}
      </div>
    );
  }

  function onClickReturn() {
    if (onReturn) {
      onReturn();
    } else {
      setTopic(0);
    }
  }

  return (
    <>
      {topic === 0 && (
        <>
          <style.Left>
            <style.Title>{t('pages.pulsus_chrome_os_integration.enroll_modal_title')}</style.Title>
            <BannerChromeIntegrationModal />
          </style.Left>
          <style.Right>
            <ComponentSteps id="chromeOS-integration-tutorial" steps={chromeSteps} />
            <style.Actions>
              <style.CancelButton onClick={() => onCancel()}>{t('pages.pulsus_chrome_os_integration.enroll_modal_cancel_button')}</style.CancelButton>
              <style.ContinueButton fill onClick={() => setTopic(1)}>
                {t('pages.pulsus_chrome_os_integration.enroll_modal_continue_button')}
              </style.ContinueButton>
            </style.Actions>
          </style.Right>
        </>
      )}
      {topic === 1 && (
        <style.EnrollContainer>
          <style.ModalTitle>{t('pages.pulsus_chrome_os_integration.enroll_second_modal_title')}</style.ModalTitle>
          <style.Content>
            <style.EnrollLeft>
              <style.CustomTabs value={tabIndex} onChange={(e, index) => setTabIndex(index)}>
                <Tab label="Control + Alt + E" />
                <Tab label="Zero Touch" />
              </style.CustomTabs>
              <TabPanel index={0} value={tabIndex}>
                <ComponentSteps id="chromeOS-enroll-tutorial" steps={EnrollSteps} />
              </TabPanel>
              <TabPanel index={1} value={tabIndex}>
                <div style={{ overflow: 'scroll', maxHeight: 380 }}>
                  <style.ZeroTouchWarningBox>
                    <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.enroll_warning_zero_touch_1')}</EuiTextColor>
                    <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_warning_zero_touch_2')}</EuiTextColor>
                    <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.enroll_warning_zero_touch_3')}</EuiTextColor>
                    <EuiTextColor>{t('pages.pulsus_chrome_os_integration.enroll_warning_zero_touch_4')}</EuiTextColor>
                    <EuiTextColor className="bold">{t('pages.pulsus_chrome_os_integration.enroll_warning_zero_touch_5')}</EuiTextColor>
                  </style.ZeroTouchWarningBox>
                  <ComponentSteps id="chromeOS-enroll-tutorial" steps={ZeroTouchSteps} />
                </div>
              </TabPanel>
            </style.EnrollLeft>
            <style.EnrollRight>
              <TabPanel index={0} value={tabIndex}>
                <style.BannerBackground>
                  <style.Banner>
                    <BannerEnrollDeviceDemo height={375} width={350} className="bannerSVG" />
                    <p>{t('pages.pulsus_chrome_os_integration.enroll_second_modal_email')}</p>
                  </style.Banner>
                </style.BannerBackground>
                <style.Breadcrumb>
                  <style.LinkButton onClick={() => onClickReturn()}>ChromeOS</style.LinkButton>
                  <EuiTextColor>/</EuiTextColor>
                  <EuiTextColor>Control + Alt + E</EuiTextColor>
                </style.Breadcrumb>
              </TabPanel>
              <TabPanel index={1} value={tabIndex}>
                <style.Banner>
                  <BannerChromeZeroTouch className="bannerSVG" />
                </style.Banner>
                <style.Breadcrumb>
                  <style.LinkButton onClick={() => onClickReturn()}>ChromeOS</style.LinkButton>
                  <EuiTextColor>/</EuiTextColor>
                  <EuiTextColor>Zero Touch</EuiTextColor>
                </style.Breadcrumb>
              </TabPanel>
            </style.EnrollRight>
          </style.Content>
        </style.EnrollContainer>
      )}
    </>
  );
}
