type IconProps = {
  size?: number | string;
  isSquare?: boolean;
};

export function AppleFilterIcon({ size, isSquare }: IconProps) {
  return (
    <svg style={{ zoom: size }} width="30" height="31" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.353516" width="25" height="25" rx={isSquare ? '5' : '15'} fill="#454545" />
      <path
        d="M17.5165 15.3127C17.5321 16.826 18.9839 17.3296 19 17.336C18.9877 17.3715 18.768 18.0537 18.2351 18.7583C17.7744 19.3675 17.2963 19.9744 16.5431 19.987C15.803 19.9993 15.565 19.5899 14.7188 19.5899C13.8729 19.5899 13.6085 19.9744 12.908 19.9993C12.1809 20.0242 11.6273 19.3406 11.1628 18.7337C10.2136 17.4921 9.48821 15.2254 10.4622 13.6953C10.9461 12.9355 11.8108 12.4543 12.7493 12.442C13.4632 12.4296 14.1371 12.8765 14.5735 12.8765C15.0097 12.8765 15.8286 12.3391 16.6894 12.418C17.0498 12.4316 18.0615 12.5497 18.711 13.41C18.6587 13.4393 17.504 14.0475 17.5165 15.3127V15.3127ZM16.1256 11.5966C16.5116 11.1739 16.7714 10.5855 16.7005 10C16.1441 10.0202 15.4714 10.3354 15.0723 10.7579C14.7146 11.132 14.4014 11.7308 14.4859 12.3047C15.1061 12.3481 15.7396 12.0196 16.1256 11.5967"
        fill="#A0A0A0"
        transform="translate(-2, -2)"
      />
      <defs>
        <clipPath id="clip0_0_1">
          <rect y="0.353516" width="29.0301" height="30" rx="14.515" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
