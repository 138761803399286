import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const WrapperContent = styled.form`
  padding: 36px 48px;
  width: 448px;

  .information {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 48px;

    h1 {
      font-size: 26px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      text-align: center;
      color: ${ConstantColors.COMOROS};
    }

    p {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #474747;
    }
  }

  label {
    color: ${ConstantColors.ITALY};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const StyledButton = styled(EuiButton)`
  background: #ee2f51 !important;
  border-color: #ee2f51 !important;
  color: #fff;
  width: 192px;
  &:disabled {
    color: #fff !important;
  }
`;

export const StyledButtonEmpty = styled(EuiButtonEmpty)`
  color: ${ConstantColors.CHILE};
`;
