import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import type { StoreProps } from 'stores/stores.contracts';
import * as style from './page-operational-system.style';
import { OperationalSystemCardsGroup } from './operational-system-cards-group';

export const PageOperationalSystem: React.FC = () => {
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const dispatch = useDispatch();
  const [initialPolicy, setInitialPolicy] = useState({});
  const [systemUpdate, setSystemUpdate] = useState({});
  const [autoDateAndTimeZone, setAutoDateAndTimeZone] = useState('AUTO_DATE_AND_TIME_ZONE_USER_CHOICE');
  const [allowFirmwareOtaUpgrade, setAllowFirmwareOtaUpgrade] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChange(value: any, type: string) {
    switch (type) {
      case 'systemUpdateType':
        setSystemUpdate((prevstate) => {
          return { ...prevstate, type: value };
        });
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { systemUpdate: { ...systemUpdate, type: value } } });
        break;
      case 'systemUpdateStart':
        setSystemUpdate((prevstate) => {
          return { ...prevstate, startMinutes: value };
        });
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { systemUpdate: { ...systemUpdate, startMinutes: value } } });
        break;
      case 'systemUpdateEnd':
        setSystemUpdate((prevstate) => {
          return { ...prevstate, endMinutes: value };
        });
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { systemUpdate: { ...systemUpdate, endMinutes: value } } });
        break;
      case 'autoDateAndTimeZone':
        setAutoDateAndTimeZone(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { autoDateAndTimeZone: value } });
        break;
      case 'allowFirmwareOtaUpgrade':
        setAllowFirmwareOtaUpgrade(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { allowFirmwareOtaUpgrade: value } });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initialPolicy)) {
      setSystemUpdate(policy.systemUpdate);
      setAutoDateAndTimeZone(policy.autoDateAndTimeZone);
      setInitialPolicy(policy);
    }
  }, [policy]);

  return (
    <style.Container>
      <style.Header>
        <style.Title>Sistema Operacional</style.Title>
      </style.Header>
      <OperationalSystemCardsGroup
        systemUpdate={systemUpdate}
        autoDateAndTimeZone={autoDateAndTimeZone}
        allowFirmwareOtaUpgrade={allowFirmwareOtaUpgrade}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onChange={(value: any, type: string) => handleChange(value, type)}
      />
    </style.Container>
  );
};
