import { ComponentBreadcrumb, ComponentToast } from 'components';
import { SimManagement } from 'modules/management/components';
import type { FormEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import type { Paginate, SelectProps, StatusCount } from './PageSimManagement.contracts';
import { ManagementBreadcrumbs } from '../ManagementBreadcrumbs';
import type { Toast } from 'modules/core/components';

export function PageSimManagement() {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'sim_management' });

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<Toast[]>([]);

  const [modal, setModal] = useState<boolean>(false);

  const [paginate, setPaginate] = useState<Paginate>({ page: 0, items: [], totalItems: 0 });
  const [countStatus, setCountStatus] = useState<StatusCount>({
    total: 0,
    managed: 0,
    pending: 0,
  });
  const [searchValue, setSearchValue] = useState('');
  const [searchSelect, setSearchSelect] = useState('id');
  const [selectedStatus, setSelectedStatus] = useState('NEED_TO_LOCK');
  const [customFieldLabels, setCustomFieldLabels] = useState<SelectProps[]>([]);

  useEffect(() => {
    fetchData(0);
    getSimByStatus();
    getCustomFieldLabel();
  }, []);

  const handleSelectedStatus = (status: string) => {
    setSelectedStatus(status);
  };

  const handleFilterBySelect = (option: string) => {
    setSearchSelect(option);
  };

  const handleInputValue = (value: string) => {
    setSearchValue(value);
  };

  const getCustomFieldLabel = async () => {
    try {
      const result = await serviceLoki.getCustomFields();
      setCustomFieldLabels(result);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    }
  };

  const getSimByStatus = async () => {
    try {
      const result = await serviceLoki.getNumberOfSims();
      setCountStatus(result);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (pageToFetch: number, select?: string, value?: string, status?: string) => {
    try {
      if (status && status.length > 0) {
        await handleSelectedStatus(status);
      }

      setLoading(true);
      const hasCustomFieldSelected = customFieldLabels.some((item) => select === item.value || searchSelect === item.value);

      const selectedCustomField = customFieldLabels.filter((item) => item.value === searchSelect);

      if (hasCustomFieldSelected) {
        const result = await serviceLoki.getSims(
          pageToFetch + 1,
          i18n.language,
          selectedStatus || status,
          'custom_field_info',
          value,
          selectedCustomField[0].value
        );

        setPaginate({
          page: pageToFetch,
          items: result.sims,
          totalItems: result.total,
        });
      } else {
        const result = await serviceLoki.getSims(pageToFetch + 1, i18n.language, selectedStatus || status, select, value);

        setPaginate({
          page: pageToFetch,
          items: result.sims,
          totalItems: result.total,
        });
      }
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSIM = async (): Promise<void> => {
    await fetchData(paginate.page);
  };

  const handleChangePage = async (currentPage: number): Promise<void> => {
    try {
      await fetchData(currentPage);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
    }
  };

  const fetchCsv = async (e: FormEvent<HTMLFormElement>, file: FileList | null): Promise<void> => {
    e.preventDefault();

    try {
      setCsvLoading(true);

      const csv = file?.item(0) as File;
      await serviceLoki.uploadFile(csv);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
    } finally {
      await fetchData(paginate.page);
      setModal(false);
      setCsvLoading(false);
    }
  };

  return (
    <>
      <ComponentBreadcrumb items={ManagementBreadcrumbs.PULSUS_MANAGEMENT_SIM.breadcrumbs} />
      <SimManagement
        fetchCsv={fetchCsv}
        handleChangePage={handleChangePage}
        handleDeleteSIM={handleDeleteSIM}
        searchSims={(select: string, value: string, status?: string) => fetchData(0, select, value, status)}
        setModal={setModal}
        modal={modal}
        csvLoading={csvLoading}
        loading={loading}
        message={message}
        setMessage={setMessage}
        setToast={setToast}
        page={paginate.page}
        sims={paginate.items}
        totalItems={paginate.totalItems}
        countStatus={countStatus}
        selectedStatus={selectedStatus}
        handleSelectedStatus={handleSelectedStatus}
        searchSelect={searchSelect}
        handleFilterBySelect={handleFilterBySelect}
        searchValue={searchValue}
        handleInputValue={handleInputValue}
        customFieldLabels={customFieldLabels}
      />
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
}
