import { ComponentApplicationHeader } from 'components';
import Lottie from 'lottie-react';
import animation_404 from 'assets/animations/animation-404.json';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as style from './ComponentNotFound.style';

export function NotFound() {
  const { t } = useTranslation();
  const history = useNavigate();

  const handleBackHome = () => {
    history('/');
  };

  return (
    <style.Container data-testid="page__not-found">
      <ComponentApplicationHeader />
      <style.AnimationContainer>
        <style.Animation>
          <Lottie
            data-testid="not-found-animation"
            animationData={animation_404}
            style={{
              width: '100%',
              height: '100%',
              pointerEvents: 'none',
              margin: '0',
            }}
          />
        </style.Animation>
        <style.Title data-testid="not-found-title">{t('pages.not_found.text.title')}</style.Title>
        <style.Description data-testid="not-found-description">{t('pages.not_found.text.description')}</style.Description>
        <style.Button data-testid="not-found-button" onClick={handleBackHome} fill>
          {t('pages.not_found.button.back_home')}
        </style.Button>
      </style.AnimationContainer>
    </style.Container>
  );
}
