import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';

type ContainerProps = {
  isSelected: boolean;
};

export const Container = styled.main<ContainerProps>`
  ${({ isSelected }) => css`
    width: fit-content;
    padding: 1px 10px;
    background-color: ${isSelected ? ConstantColors.JAMAICA : ConstantColors.AUSTRIA};
    border: 1px solid ${ConstantColors.PORTUGAL};
    border-radius: 12px;

    cursor: pointer;

    p {
      font-size: 12px;
      font-weight: 700;
      transition: 0.1s;
      text-transform: uppercase;
    }

    &:hover {
      p {
        transition: 0.3s;
        transform: translateY(-1px);
        text-decoration: underline;
      }
    }
  `}
`;
