import React from 'react';

type IconBluetoothInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconBluetoothInCicle({ width, height, elipse_fill, icon_fill }: IconBluetoothInCicleProps) {
  return (
    <svg width={width || 44} height={height || 45} viewBox="0 0 44 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="21.6783" cy="22.5" rx="21.6783" ry="22.5" fill={elipse_fill || '#30A8FF'} />
      <path
        d="M27.6615 16.71L22.16 11H21.1965V18.59L16.7742 14L15.4156 15.41L20.8015 21L15.4156 26.59L16.7742 28L21.1965 23.41V31H22.16L27.6615 25.29L23.5185 21L27.6615 16.71ZM23.1235 14.83L24.9348 16.71L23.1235 18.59V14.83ZM24.9348 25.29L23.1235 27.17V23.41L24.9348 25.29Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
