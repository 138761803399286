import type { Geofence } from 'modules/management/entities';
import { createContext, useContext } from 'react';
import type { GeofenceFieldsProps, TriggerValuesAdaptProps, ChangedFences } from './contracts';

type GeofenceContextProps = {
  fields: GeofenceFieldsProps;
  geofences: Geofence[];
  setFields: (fields: GeofenceFieldsProps) => void;
  loading: boolean;
  handleChangeSwitch: (switchValue: boolean, id: number) => void;
  getConfigAndFences: (index: number) => Promise<void>;
  pagination: { activePage: number; totalPages: number };
  serverTrigger: TriggerValuesAdaptProps | null;
  formatChangedFences: () => ChangedFences;
};

export const GeofenceContext = createContext<GeofenceContextProps>({} as GeofenceContextProps);

export const useGeofenceContext = (): GeofenceContextProps => useContext(GeofenceContext);
