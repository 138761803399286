type ErrorDetail = {
  loc: Array<string>;
  msg: string;
  type: string;
};

export type ErrorBodyMessage = {
  detail: Array<ErrorDetail>;
};

export const GetValidationError = (body: ErrorBodyMessage, validationItem: string) => {
  const errorBody = body.detail?.[0];

  if (errorBody) {
    return errorBody.loc.includes(validationItem);
  }
};
