import React from 'react';
import PropTypes from 'prop-types';

export function IconSupport({ width, height, color, test_id }) {
  return (
    <svg
      data-testid={test_id}
      id="icon-support"
      width={width}
      height={height}
      fill={color}
      viewBox="0 0 18 22"
      data-name="support"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Support Icon</title>
      <path d="M9 0C4.03 0 0 4.03 0 9V16C0 17.66 1.34 19 3 19H6V11H2V9C2 5.13 5.13 2 9 2C12.87 2 16 5.13 16 9V11H12V19H16V20H9V22H15C16.66 22 18 20.66 18 19V9C18 4.03 13.97 0 9 0Z" />
    </svg>
  );
}

IconSupport.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
  test_id: PropTypes.string,
};

IconSupport.defaultProps = {
  width: '18px',
  height: '22px',
  color: '#FFFFFF',
  test_id: 'icons__icon-support',
};
