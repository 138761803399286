import { EuiRange } from '@elastic/eui';
import styled from 'styled-components';

const SliderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SliderBox = styled.div`
  display: flex;
  align-items: center;
  margin-top: 15px;
  background: red;
  height: 4px;
  border-radius: 4px;
  width: 200px;
`;

const Slider = styled(EuiRange)`
  margin-top: 5px;

  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-top: -5.8x;
    background: #505050;
    border: 2px solid #ffffff;
    box-shadow:
      0px 1px 5px rgba(0, 0, 0, 0.1),
      0px 3.6px 13px rgba(0, 0, 0, 0.07),
      0px 8.4px 23px rgba(0, 0, 0, 0.06),
      0px 23px 35px rgba(0, 0, 0, 0.05);
  }
`;

export { SliderContainer, Slider, SliderBox };
