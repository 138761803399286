import styled, { css } from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { EuiButton } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  background-color: ${ConstantColors.BRAZIL};
  padding-left: 9px;
  position: relative;
  min-height: 80vh;
}

  .euiPanel {
    box-shadow: none;
    border-radius: 9px !important;
  }
  @keyframes slideInFromRight {
    0% {
      transform: translateX(500px);
    }
    50% {
      transform: translateX(-40px);
    }
    75% {
      transform: translateX(20px);
    }
    100% {
      transform: translateX(0);
    }
  }

  div.individualAnimation {
    animation: 0.5s ease-out 0s 1 slideInFromRight;
    transform: translate(0, 0) !important;
    transition: all 3s !important;
  }

  ${(props: { animation: boolean }) => {
    if (props?.animation) {
      return css`
        animation: 0.5s ease-out 0s 1 slideInFromRight;
        transform: translate(0, 0) !important;
        transition: all 3s !important;
      `;
    }
    return css``;
  }}
`;

export const SaveBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  bottom: 0;
  z-index: 100;

  width: 100%;
  height: 58px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  box-shadow: 0px 3.16359px 3.16359px rgba(0, 0, 0, 0.25);

  .link-style {
    margin: 0 5px 0 20px;
    color: ${ConstantColors.CHILE};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SaveButton = styled(EuiButton)`
  color: ${ConstantColors.AUSTRIA};
  background-color: ${ConstantColors.ARGENTINA};
  margin-left: 15px;
  margin-right: 280px;

  &:hover {
    background-color: ${ConstantColors.ARGENTINA} !important;
  }

  &:focus {
    background-color: ${ConstantColors.ARGENTINA} !important;
  }
`;

export const MenuItemGroups = styled.div`
  ${(props) => {
    if (props.hidden) {
      return css`
        display: none;
      `;
    }
    return css`
      display: flex;
      max-width: 1100px;
      gap: 30px;
      background: #f9f9f9;
      padding: 25px 50px;
    `;
  }}
`;
export const MenuItem = styled.div`
  min-width: 1100px;
  width: 100%;
  max-width: 1100px;
  background-color: ${ConstantColors.BRAZIL};

  ${(props) => {
    if (props.hidden) {
      return css`
        display: none;
      `;
    }
    return css``;
  }}
`;
