import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.div`
  position: relative;
  background: ${ConstantColors.BRAZIL};
  padding: 25px 50px 0px 50px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: #1a1c21;
`;
export const Title = styled.h3``;
