import { EuiButtonEmpty, EuiNotificationBadge } from '@elastic/eui';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import type { TextProps } from './RegisterComponent.contracts';

export const Body = styled.div`
  .euiPagination {
    justify-content: flex-end;
  }

  .euiTableCellContent__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 118.02%;
    color: #67758d;
  }

  .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
    background-color: ${({ theme }) => theme.main};
    border-color: ${({ theme }) => theme.main};
  }

  tbody {
    tr {
      #editIcon {
        opacity: 0;
      }
      :hover {
        #editIcon {
          opacity: 1;
          cursor: pointer;
          transition: 0.5s;
        }
      }
    }
  }
`;

export const WrapperTable = styled.div`
  margin-top: 32px;
`;

export const BodyHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const FencesSelectionNumbers = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 118.02%;
  color: #67758d;
  margin-right: 15px;
  width: 200px;
`;

export const ChangeMultipleFencesButton = styled(EuiButtonEmpty)`
  font-weight: 700;
  font-size: 12px;
  line-height: 35px;
  color: #30a8ff;
  text-decoration-line: underline;
`;

export const FencesFlag = styled.p`
  width: 90px;
  max-height: 21px;
  border-radius: 3px;
  color: black;
  background-color: #e0e5ee;
  padding: 5px 2px;
  font-weight: 500;
  font-size: 10px;
  display: flex;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
`;

export const CoordinatesColumn = styled.div`
  font-style: italic;
  font-weight: 700;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;

  p {
    font-size: 10px !important;
  }
  span {
    font-weight: normal;
  }
`;

export const ActionsColumn = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  align-items: center;
  display: flex;
  gap: 7px;

  #copyIcon,
  #deleteIcon {
    background: #e0e5ee90;
    color: #000;
  }

  svg {
    color: #ffff;
    border-radius: 50%;
    padding: 4px;
  }

  #insideIcon {
    transform: rotate(180deg);
  }
`;

export const SquareNumberActions = styled.div`
  height: 20px;
  min-width: 20px;
  background-color: #e0e5ee;
  border-radius: 3px;
  align-items: center;
  padding: 2px 6px;
`;

export const Text = styled.p<TextProps>`
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${({ highlight }) => (highlight ? '#30a8ff' : '#5B5B5B')};
`;

export const ActionsNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const ActionsName = styled.div<TextProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  text-decoration-line: underline;
  color: ${({ highlight }) => (highlight ? '#30a8ff' : '#5B5B5B')};
  margin-right: 10px;
  cursor: pointer;
`;

export const ActionsNameRouter = styled(Link)<TextProps>`
  font-style: normal;
  font-weight: 700;
  font-size: 13px;
  line-height: 21px;
  text-decoration-line: underline;
  color: ${({ highlight }) => (highlight ? '#30a8ff' : '#5B5B5B')};
  margin-right: 10px;
`;

export const BlockDeviceText = styled.p`
  font-size: 12px;
  font-weight: 400;
`;

export const Badge = styled(EuiNotificationBadge)`
  width: 170px;
  height: 21px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
