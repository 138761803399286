import { EuiButton, EuiCallOut, EuiFilePicker, EuiModal } from '@elastic/eui';
import styled from 'styled-components';

const MODAL_SIZE = '682px';
const PADDING_FROM_TOP = '130px';

export const Modal = styled(EuiModal)`
  margin-bottom: 20vh;
  .euiModal__flex {
    max-height: none !important;
  }

  margin-top: calc(-100% + (${MODAL_SIZE} + ${PADDING_FROM_TOP}));
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 880px;
  padding: 20px 150px;
`;

export const FilePicker = styled(EuiFilePicker)`
  .euiFilePicker__prompt {
    height: 185px !important;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${({ theme }) => theme.main};
  margin: 25px 0 40px;
`;

export const BodyModal = styled.main`
  display: flex;
  width: 100%;
  .euiStep__title {
    font-style: normal;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 32px !important;
    color: #1a1c21;
  }

  .euiStep__circle {
    font-style: normal;
    font-weight: 600;

    line-height: 21px;
    background-color: ${({ theme }) => theme.main};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .euiCallOut--danger {
    position: absolute;
    right: 40px;
    bottom: 190px;
    left: 570px;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  color: #343741;
  margin-bottom: 75px;
`;

export const Error = styled(EuiCallOut)`
  width: 190px !important;
  position: absolute;
  right: 350px;
  bottom: 20px;

  .euiCallOutHeader__title {
    font-size: 13px !important;
    line-height: 1rem !important;
  }

  .euiTextColor.euiTextColor--default {
    p {
      font-size: 11px !important;
    }
  }

  .euiCallOut {
    padding: 2px 10px;
  }
`;

export const FooterModal = styled.footer`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 40px;
`;

export const ButtonLink = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  border: none;
  box-shadow: none !important;
  color: #30a8ff;
  min-width: fit-content;

  &:hover {
    background: none !important;
  }
`;

export const Button = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;
