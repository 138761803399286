import { useState, useEffect } from 'react';
import { ComponentBreadcrumb, ComponentPulsusInsightsContent } from 'components';
import { ConstantRoutes } from 'utilities';
import { ComponentApplicationContainer } from 'modules/core/components';
import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';

export function PagePulsusInsights() {
  const urlPulsusSignals = ConstantRoutes.PULSUS_INSIGHTS_SIGNAL.path;
  const urlPulsusBatteries = ConstantRoutes.PULSUS_INSIGHTS_BATTERIES.path;

  const [displaySignals] = useState(false);
  const [displayBatteries] = useState(false);

  const query = useQuery();
  const language = query.get('language');

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <ComponentApplicationContainer>
      <ComponentBreadcrumb items={ConstantRoutes.PULSUS_INSIGHTS.breadcrumbs} />
      <ComponentPulsusInsightsContent
        displaySignals={displaySignals}
        displayBatteries={displayBatteries}
        urlPulsusSignals={urlPulsusSignals}
        urlPulsusBatteries={urlPulsusBatteries}
      />
    </ComponentApplicationContainer>
  );
}
