import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const RegisterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  min-height: 100vh;
  background: ${`linear-gradient(${ConstantColors.ARGENTINA} 50%, ${ConstantColors.BRAZIL} 50%);`};

  @media (max-height: 700px) {
    justify-content: center;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  width: 26vh;
  height: 5.5vh;
  min-width: 190px;
  min-height: 40px;
  max-width: 260px;
  max-height: 55px;
  margin-top: auto;
  margin-bottom: 7vh;

  @media (max-height: 700px) {
    display: none;
  }

  @media (min-height: 700px) and (max-height: 800px) {
    margin-bottom: 3.5vh;
  }
`;

export const Copyright = styled.p`
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: 500;
  font-size: 15.1636px;
  line-height: 18px;
  margin-top: auto;
  margin-bottom: 5vh;
  color: ${ConstantColors.GREECE};

  @media (max-height: 700px) {
    display: none;
  }

  @media (min-height: 700px) and (max-height: 800px) {
    margin-bottom: 2vh;
  }
`;
