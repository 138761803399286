import type { SearchBarContextProps, SearchBarProviderProps } from './SearchBarProvider.contracts';

import { createContext, useContext, useState } from 'react';

export const SearchBarContext = createContext<SearchBarContextProps>({} as SearchBarContextProps);
export const useSearchBarContext = (): SearchBarContextProps => useContext(SearchBarContext);

export const SearchBarProvider = ({ children }: SearchBarProviderProps) => {
  const [searchType, setSearchType] = useState('');
  const [searchValue, setSearchValue] = useState('');

  return <SearchBarContext.Provider value={{ searchType, setSearchType, searchValue, setSearchValue }}>{children}</SearchBarContext.Provider>;
};
