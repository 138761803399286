import type { IconCellComponentProps } from './IconCellComponent.contracts';
import Icon from '@mdi/react';
import * as S from './IconCellComponent.styles';
import { IconComponent } from 'modules/core/components/Icons/IconComponent';

export const IconCellComponent = ({ color, value, text, icon, downloadedIcon, onClick }: IconCellComponentProps) => {
  return (
    <S.Container role="button" onClick={onClick}>
      <div>
        {icon && <Icon path={icon} color={color || '#818181'} size={1} />}
        {downloadedIcon && <IconComponent color={color || '#818181'} icon={downloadedIcon} />}
        <S.IconCellValue hasColor={!!color} hasIcon={!!icon || !!downloadedIcon} color={color}>
          {value}
        </S.IconCellValue>
      </div>
      <S.IconCellText>{text}</S.IconCellText>
    </S.Container>
  );
};
