import { EuiFlyout, EuiFlyoutHeader } from '@elastic/eui';
import styled from 'styled-components';

export const Flyout = styled(EuiFlyout)`
  overflow: auto;
  min-width: 500px;
`;

export const FlyoutHeader = styled(EuiFlyoutHeader)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  padding-top: 0;
  margin-top: -40px;
`;

export const HeaderLine = styled.div`
  height: 70px;
  background: ${({ theme }) => theme.main};
  border-radius: 6px 6px 0px 0px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  .status-button {
    margin-right: 15px;
    margin-left: auto;
    margin-bottom: 5px;
    margin-top: auto;

    background: #efefef;
    border-radius: 50%;
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;

    :hover {
      transition: 0.4s;
      transform: translateY(-2px);
      cursor: pointer;
    }

    button {
      display: flex;
      align-items: center;
      justify-content: center;

      .icon-user-inactive {
        width: 21px;
      }

      .icon-user-active {
        width: 18px;
        color: #5f5f5f;
      }
    }
  }
`;

export const UserIconContainer = styled.div`
  background: #d9d9d9;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin-right: 0;
  margin-left: auto;
`;

export const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  gap: 10px;

  h2 {
    font-size: 20px;
    font-weight: 700;
    color: #5a5a5a;
    line-height: 24px;
  }

  span {
    font-size: 14px;
    font-weight: 400;
    color: #343741;
  }
`;

export const UserContainer = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #626262;
  margin-top: -10px;
`;

export const ModalBody = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`;

export const UserInformationsContainer = styled.div`
  display: flex;
  gap: 20px;
  align-items: start;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 40px;
  gap: 80px;
  background: #f9f9f9;
  min-height: 150px;
  border-radius: 7px;
  margin-top: 20px;
`;

export const UserInformation = styled.span`
  h6 {
    font-size: 12px;
    font-weight: 700;
    line-height: 18px;
    color: #1a1c21;
  }

  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    color: #343741;
  }
`;
