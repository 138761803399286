import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 520px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  gap: 30px;
`;

export const CardHeader = styled.div``;

export const CardTitle = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 40px;
  color: #1a1c21;
`;

export const CardDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #67758d;
`;
