import type { EuiBasicTableColumn, EuiTableSelectionType } from '@elastic/eui';
import type { ChangeEvent } from 'react';
import { useState, type ReactElement, useMemo, useRef, useEffect } from 'react';
import { EuiInMemoryTable } from '@elastic/eui';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';

import * as S from './MapsGroupListComponent.styles';
import { useMapsContext } from 'modules/operational/contexts/maps';
import { useTypedTranslation } from 'modules/core/hooks';
import type { GroupList } from 'modules/operational/entities/GroupList/GroupList.entity';
import type { Toast } from 'modules/core/components';
import { ComponentToast, TextEllipsisComponent } from 'modules/core/components';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';
import { IntersectionObserverComponent } from 'modules/core/components/IntersectionObserver/IntersectionObserverComponent';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';

export const MapsGroupListComponent = (): ReactElement => {
  const [groups, setGroups] = useState<GroupList[]>([]);
  const [selectedGroups, setSelectedGroups] = useState<GroupList[]>([]);
  const [isLoadingSearchSelect, setIsLoadingSearchSelect] = useState(false);
  const [toast, setToast] = useState<Toast[]>([]);
  const [loading, setLoading] = useState(true);
  const [groupsTotal, setGroupsTotal] = useState(0);
  const [devicesTotal, setDevicesTotal] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isTyping, setIsTyping] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams({ page: '1' });

  const search = searchParams.get('search_name');
  const groupService = useMemo(() => new ServicePulsusLoki(), []);
  const itemsPerPage = 100;
  const tableRef = useRef(null);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { setFilter } = useMapsContext();

  const { t } = useTypedTranslation<'maps.groupsTag'>('maps.groupsTag');
  const isSelectedListEmpty = useMemo(() => Object.keys(selectedGroups)?.length <= 0, [selectedGroups]);

  const columns: Array<EuiBasicTableColumn<GroupList>> = useMemo(
    () => [
      {
        field: 'basicInfo',
        truncateText: true,
        name: <S.Row>{t('all_groups')}</S.Row>,
        render: (basicInfo: GroupList['basicInfo']) => (
          <S.RowValue>
            <TextEllipsisComponent title={basicInfo.name}>{basicInfo.name}</TextEllipsisComponent>
          </S.RowValue>
        ),
      },
      {
        field: 'numberOfDevices',
        name: <S.Row>{devicesTotal}</S.Row>,
        align: 'center',
        render: (numberOfDevices: number) => (
          <S.RowValue>
            <TextEllipsisComponent title={String(numberOfDevices)} id="devices-quantity">
              {numberOfDevices}
            </TextEllipsisComponent>
          </S.RowValue>
        ),
        width: '150px',
      },
    ],
    [groups]
  );

  const selection: EuiTableSelectionType<GroupList> = {
    onSelectionChange: (select) => {
      setSelectedGroups(select);
    },
  };

  const MemoizedTable = useMemo(
    () => (
      <EuiInMemoryTable
        selection={selection}
        items={groups}
        loading={loading}
        itemId="id"
        isSelectable
        ref={tableRef}
        columns={columns}
        message={t('message')}
      />
    ),
    [loading, groups, selection, groupsTotal]
  );

  useEffect(() => {
    getGroups(1);
  }, []);

  useEffect(() => {
    if (search !== null && !isTyping) {
      handleSearchGroup(search);
    }
  }, [isTyping]);

  const getGroups = async (pageNumber: number, clear?: boolean, emptyList?: boolean) => {
    const searchAux = clear ? '' : search;
    try {
      const response = await groupService.getGroupsList(pageNumber, searchAux!, itemsPerPage);
      setGroupsTotal(response.total);
      setDevicesTotal(response.totalDevices);
      setGroups(response.groups);

      if (emptyList) {
        setGroups(response.groups);
      } else {
        setGroups([...groups, ...response.groups]);
      }

      setTotalItems(Math.ceil(response.total / itemsPerPage));
    } catch (error) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('error.text'),
          title: t('error.title'),
        },
      ]);
    } finally {
      setIsLoadingSearchSelect(false);
      setLoading(false);
    }
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTyping(true);
    setSearchParams({ page: '1', search_name: e.target.value });

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      typingTimeoutRef.current = null;
    }, 500);
  };

  const handleSearchGroup = (searchStr: string) => {
    if (searchStr === '') {
      getGroups(1, true, true);
      setSearchParams({ page: '1' });
    } else {
      getGroups(1, false, true);
      setSearchParams({ page: '1', search_name: searchStr });
    }
    setCurrentPage(1);
    setIsLoadingSearchSelect(true);
  };

  const observerAction = async () => {
    if (totalItems !== 1 && currentPage <= totalItems) {
      await getGroups(currentPage + 1);
      setCurrentPage(currentPage + 1);
    }
  };

  const setGroupsToFilter = (): void => {
    setFilter({
      groupIds: selectedGroups.map((group) => group.id),
      deviceIds: undefined,
    });
  };

  return (
    <S.Container>
      <S.SearchInput
        fullWidth
        placeholder={t('placeholder')}
        value={search || ''}
        onChange={(e) => onChangeSearch(e)}
        isLoading={isLoadingSearchSelect}
        aria-label={t('placeholder')}
      />
      <S.GroupListSection>
        {MemoizedTable}
        <ConditionalSlotComponent renderIf={currentPage < totalItems}>
          <IntersectionObserverComponent state={currentPage} action={async () => observerAction()}>
            <CircularProgress size={24} style={{ color: 'black', display: 'flex', alignSelf: 'center', justifyContent: 'center' }} />
          </IntersectionObserverComponent>
        </ConditionalSlotComponent>
        <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
      </S.GroupListSection>
      <S.LocationButton disabled={isSelectedListEmpty} onClick={() => setGroupsToFilter()}>
        {t('locateButton')}
      </S.LocationButton>
    </S.Container>
  );
};
