type IconArrowLinkProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconArrowLink({ width = 8, height = 12, color = '#DE5B25', test_id = 'icons__arrow-link' }: IconArrowLinkProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10.59 4.58 6 0 1.41 1.41 0l6 6-6 6L0 10.59Z" fill={color} />
    </svg>
  );
}
