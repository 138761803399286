type DevicesProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconDevices({ width = 24, height = 16, color = '#000', test_id = 'icons__devices' }: DevicesProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 2h18V0H4C2.9 0 2 .9 2 2v11H0v3h14v-3H4V2Zm19 2h-6c-.55 0-1 .45-1 1v10c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V5c0-.55-.45-1-1-1Zm-1 9h-4V6h4v7Z"
        fill={color}
      />
    </svg>
  );
}
