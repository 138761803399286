import styled, { css } from 'styled-components';
import type { SelectableProps } from './component-groups-select.contracts';

export const OptionContainer = styled.div`
  display: flex;
  align-items: left;

  .euiBadge.euiBadge--iconLeft {
    margin-right: 0;
    margin-left: auto;
  }

  .euiText {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
  }

  .EuiHighlight {
    max-width: 340px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;

export const Selectable = styled.section<SelectableProps>`
  ${({ disabled }) => css`
    opacity: ${disabled ? 0.4 : 1};
    pointer-events: ${disabled ? 'none' : 'unset'};
    cursor: pointer;

    .euiSelectableList__list {
      -webkit-mask-image: none !important;
      z-index: 9 !important;
    }
  `}
`;
