import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiEmptyPrompt, EuiInMemoryTable } from '@elastic/eui';
import { ComponentTextField } from 'components';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { IconButton } from '@mui/material';
import { mdiDeleteOutline } from '@mdi/js';
import Icon from '@mdi/react';
import { useSelector, useDispatch } from 'react-redux';
import type { PolicyBody, StoreProps, UrlModes } from 'stores/stores.contracts';
import { useTranslation } from 'react-i18next';
import type { UrlsManagementProps, TableItemsProps } from './urls-management-card.contracts';
import * as componentStyle from './urls-management-card.style';
import * as cardStyle from '../network-cards.style';

const style = { ...cardStyle, ...componentStyle };

export function UrlManagementCard({ emmitToast }: UrlsManagementProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store: PolicyBody | undefined = useSelector((stores: StoreProps) => stores?.chromeOS?.configuration?.policy);
  const [urlInput, setUrlInput] = useState('');
  const [listMode, setListMode] = useState<UrlModes>('Allowlist');
  const [list, setList] = useState<TableItemsProps[]>([]);

  function emmitListChange(items: TableItemsProps[], mode: UrlModes) {
    if (store) {
      const aux: PolicyBody = JSON.parse(JSON.stringify(store));
      aux.user_policies.urlBlocking.mode = mode;
      aux.user_policies.urlBlocking.urls = items;
      dispatch({ type: 'CHROMEOS_CHANGE_CONFIGURATION_POLICY', payload: aux });
    }
  }

  function addItem() {
    if (urlInput && _.filter(list, (o) => o.url.toLocaleLowerCase() === urlInput.toLocaleLowerCase()).length === 0) {
      let index = 0;
      const aux: TableItemsProps[] = JSON.parse(JSON.stringify(list));
      aux.forEach((item) => {
        index = Number(item.id) > index ? Number(item.id) : index;
      });
      aux.push({ id: index + 1, url: urlInput });
      setUrlInput('');
      emmitListChange(aux, listMode);
    } else if (!urlInput) {
      emmitToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('components.component_url_management_card.error_title'),
          text: t('components.component_url_management_card.empty_input_field_message'),
        },
      ]);
    } else {
      emmitToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('components.component_url_management_card.error_title'),
          text: t('components.component_url_management_card.duplicated_input_field_message'),
        },
      ]);
    }
  }

  function deleteItem(item: TableItemsProps) {
    const aux = _.filter(list, (o) => o.id !== item.id);
    emmitListChange(aux, listMode);
  }

  function keyPress(event: KeyboardEvent) {
    const isFocused = document.getElementById('urlTextField') === document.activeElement;
    if (event.key === 'Enter' && isFocused) {
      addItem();
    }
  }

  const cols: EuiBasicTableColumn<TableItemsProps>[] = [
    {
      field: 'url',
      name: 'URL',
      width: '155px',
    },
    {
      field: 'permission',
      name: t('components.component_url_management_card.table_collumn_permission'),
      width: '80px',
      align: 'center',
      render: () => {
        if (listMode === 'Blocklist') {
          return <div className="blockFlagCol">BLOCK</div>;
        }
        return <div className="allowFlagCol">ALLOW</div>;
      },
    },
    {
      field: 'delete',
      name: t('components.component_url_management_card.table_collumn_delete'),
      width: '40px',
      align: 'center',
      render: (name: string, item: TableItemsProps) => {
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <IconButton title={t('components.component_url_management_card.table_collumn_delete_tooltip')} onClick={() => deleteItem(item)}>
              <Icon path={mdiDeleteOutline} size="20px" color="#64748F" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const [pagination] = useState({
    initialPageSize: 10,
    hidePerPageOptions: true,
  });

  const message = (
    <EuiEmptyPrompt
      title={<h3>{t('components.component_url_management_card.empty_input_field_message')}</h3>}
      titleSize="xs"
      body={t('components.component_url_management_card.empty_table')}
    />
  );

  function populateFields() {
    if (store) {
      setList([...store?.user_policies.urlBlocking.urls]);
      setListMode(store?.user_policies.urlBlocking.mode);
    }
  }

  useEffect(() => {
    populateFields();
  }, [store]);

  useEffect(() => {
    window.addEventListener('keypress', keyPress);
    return () => window.removeEventListener('keypress', keyPress);
  }, [urlInput]);

  const cardTitle = t('components.component_url_management_card.card_title');
  const blockRadio = t('components.component_url_management_card.block_radio');
  const allowRadio = t('components.component_url_management_card.allow_radio');
  let tableTitle = '';
  if (listMode === 'Allowlist') {
    tableTitle = t('components.component_url_management_card.allow_table_title');
  } else {
    tableTitle = t('components.component_url_management_card.block_table_title');
  }
  tableTitle += ` ${t('components.component_url_management_card.table_title')}`;

  return (
    <style.Card>
      <style.CardHeader>
        <style.CardTitle>{cardTitle}</style.CardTitle>
        <style.CardDescription>{t('components.component_url_management_card.card_description')}</style.CardDescription>
      </style.CardHeader>
      <style.UrlInputBox>
        <ComponentTextField
          id="urlTextField"
          className="input"
          compressed
          label={t('components.component_url_management_card.url_input_field_label')}
          value={urlInput}
          onChange={(e) => setUrlInput(e)}
        />
        <style.AddButton onClick={() => addItem()}>{t('components.component_url_management_card.add_button')}</style.AddButton>
      </style.UrlInputBox>
      <style.ListModeBox>
        <style.Option>
          <style.Radio
            label="Block List"
            id="chromeBlockUrls"
            checked={listMode === 'Blocklist'}
            onChange={() => {
              emmitListChange(list, 'Blocklist');
              setListMode('Blocklist');
            }}
          />
          <style.CustomText>{blockRadio}</style.CustomText>
        </style.Option>
        <style.Option>
          <style.Radio
            label="Allow List"
            id="chromeAllowUrls"
            checked={listMode === 'Allowlist'}
            onChange={() => {
              emmitListChange(list, 'Allowlist');
              setListMode('Allowlist');
            }}
          />
          <style.CustomText>{allowRadio}</style.CustomText>
        </style.Option>
      </style.ListModeBox>
      <style.Table>
        <style.TableTitle>{tableTitle}</style.TableTitle>
        <EuiInMemoryTable
          itemId="id"
          columns={cols}
          pagination={pagination}
          message={message}
          items={list.filter((o) => o.url.toLocaleLowerCase() !== 'chrome://*')}
        />
      </style.Table>
    </style.Card>
  );
}
