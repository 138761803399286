import styled from 'styled-components';
import { EuiButtonEmpty } from '@elastic/eui';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Button = styled(EuiButtonEmpty)`
  width: 180px;
  height: 30px;
  margin: 6px 0;
  border-radius: 19.2105px;
  border: ${({ theme }) => `1px solid ${theme.contrast}`};
  color: ${({ theme }) => theme.contrast};
`;

export const Label = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 12.5286px;
  line-height: 17px;
  margin-right: 5.5px;
`;
