import React, { useState } from 'react';
import { EuiFilePicker, EuiFlexItem, EuiSteps, EuiText } from '@elastic/eui';

import { ComponentPopupError } from 'components/popup-error/component-popup-error';
import { useTranslation } from 'react-i18next';

import * as style from './certificate-config-modal.style';
import type { CertificateConfigModalProps } from '../page-certificate.contracts';

export function CertificateConfigModal({
  onClose,
  handleCreatedCertificate,
  setFile,
  file,
  hasError,
  setHasError,
  messageError,
}: CertificateConfigModalProps) {
  const [is_pem_format, set_is_pem_format] = useState(true);
  const { t } = useTranslation();

  const handleOnClose = () => {
    setFile({});
    onClose();
  };

  const onChange = (value) => {
    setFile(value.length > 0 ? value : {});
    set_is_pem_format(value.length > 0 && value[0].name.substr(-4).includes('.pem'));

    if (value.length === 0) setHasError(false);
  };

  const renderFiles = () => {
    if (file.length > 0) {
      return (
        <small>
          <strong>{is_pem_format && file[0].name}</strong>
        </small>
      );
    }
    return <p />;
  };

  const steps_content = [
    {
      title: t('pages.certificate_add_ca.title_one'),
      children: (
        <EuiText>
          <style.Text>{t('pages.certificate_add_ca.subtitle_one')}</style.Text>
        </EuiText>
      ),
    },
    {
      title: t('pages.certificate_add_ca.title_two'),
      children: (
        <EuiText>
          <style.Text>{t('pages.certificate_add_ca.subtitle_two')}</style.Text>
          <style.SecondText>{t('pages.certificate_add_ca.title_file')}</style.SecondText>
          <EuiFlexItem>
            <EuiText>
              <EuiFilePicker
                initialPromptText={t('pages.certificate_add_ca.phrase_file')}
                onChange={onChange}
                aria-label={t('pages.certificate_add_ca.phrase_file')}
                fullWidth
                compressed
                display="default"
                accept=".pem"
                required
              />
              {renderFiles()}
            </EuiText>
          </EuiFlexItem>
        </EuiText>
      ),
    },
    {
      title: t('pages.certificate_add_ca.title_three'),
      children: (
        <EuiText>
          <style.Text>{t('pages.certificate_add_ca.subtitle_three')}</style.Text>
        </EuiText>
      ),
    },
  ];
  return (
    <style.Modal onClose={handleOnClose} maxWidth="880px">
      <style.Content>
        <style.Title>{t('pages.certificate_add_ca.title')}</style.Title>

        <style.BodyModal>
          <EuiSteps steps={steps_content} style={{ maxWidth: '510px' }} />
          {(hasError || (!is_pem_format && file.length > 0)) && (
            <ComponentPopupError
              title="Certificado inválido"
              description={hasError ? messageError : 'O certificado enviado é inválido, por favor faça o upload de um certificado diferente'}
            />
          )}
        </style.BodyModal>

        <style.FooterModal>
          <style.Button
            fill
            onClick={() => {
              handleCreatedCertificate();
            }}
            disabled={hasError || file.length === 0 || file.length === undefined || !is_pem_format}
          >
            {t('pages.certificate_add_ca.ca_button')}
          </style.Button>
        </style.FooterModal>
      </style.Content>
    </style.Modal>
  );
}
