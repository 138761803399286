import styled from 'styled-components';
import Icon from '@mdi/react';
import { Switch } from '@mui/material';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CustomIcon = styled(Icon)`
  height: 24px;
`;

export const SwitchText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
`;

export const CustomSwitch = styled(Switch)`
  width: 62px;
  height: 34px;

  .MuiSwitch-switchBase,
  .MuiSwitch-switchBase:hover {
    background-color: transparent !important;
  }

  .MuiSwitch-thumb {
    width: 20px;
    height: 20px;
    background-color: #535353;
  }

  .MuiSwitch-track {
    opacity: 1;
    background-color: #99a0ab;
  }
  .Mui-checked + .MuiSwitch-track {
    background-color: ${({ theme }) => theme.main} !important;
  }

  .Mui-checked .MuiSwitch-thumb {
    background-color: ${({ theme }) => theme.main} !important;
  }
`;
