import { EuiFieldPassword } from '@elastic/eui';
import type { PasswordFieldProps } from './component-password-field.contracts';
import * as style from './component-password-field.style';

export function ComponentPasswordField(props: PasswordFieldProps) {
  const { value, onChange, label, disabled, placeholder } = props;
  return (
    <style.FieldBox data-testid="component-passwordfield-box">
      {label && <style.FieldLabel id="component-passwordfield-label">{label}</style.FieldLabel>}
      <EuiFieldPassword
        id="component-passwordfield-field"
        type="dual"
        autoFocus
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </style.FieldBox>
  );
}
