import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  min-width: 493px;
  gap: 30px;
  padding-bottom: 25px;

  .item {
    width: 48%;
  }
`;

export const CardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
`;
export const Footer = styled.footer`
  padding-top: 30px;
  display: flex;
  gap: 20px;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 15px;
  color: ${ConstantColors.CHILE};
`;
