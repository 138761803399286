export class User {
  public id: number;
  public email: string;
  public firstName: string;
  public identifier: string;
  public fullName: string;
  public lastName: string;
  public exchangeUserName: string;
  public updatedAt: string;
  public createdAt: string;

  constructor(data: User.Server) {
    this.id = data.id;
    this.email = data.email;
    this.firstName = data.first_name;
    this.identifier = data.identifier;
    this.fullName = data.full_name;
    this.lastName = data.last_name;
    this.exchangeUserName = data.exchange_user_name;
    this.updatedAt = data.updated_at;
    this.createdAt = data.created_at;
  }
}

export namespace User {
  export type Server = {
    id: number;
    email: string;
    first_name: string;
    identifier: string;
    full_name: string;
    last_name: string;
    exchange_user_name: string;
    updated_at: string;
    created_at: string;
  };
}
