import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import type { EuiDualRangeProps } from '@elastic/eui';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { ConstantColors, ConstantLanguages, Mixpanel } from 'utilities';
import { ServicePulsusBalder } from 'services/ServicePulsusBalder';
import { useQuery } from '../../hooks/use-query';
import { useDebounce } from '../../hooks/use-debounce';
import animation_logo_pulsus_grey from '../../assets/animations/animation-logo-pulsus-grey.json';
import { TIME } from '../../pages/pulsus-insights-batteries/page-pulsus-insights-batteries.constants';
import * as style from './component-pulsus-insights-batteries.style';
import type { ChartItemProps, DataItemProps } from './component-pulsus-insights-batteries.contracts';
import { ChartFilterType } from '../../pages/pulsus-insights-batteries/chart-filter-type';
import { ChartFilterDays } from '../../pages/pulsus-insights-batteries/chart-filter-days';
import { ChartFilterRangeTime } from '../../pages/pulsus-insights-batteries/chart-filter-range-time';
import { ComponentsBatteriesFilterModal } from '../../pages/pulsus-insights-batteries/components-batteries-filter-modal';
import { useUserContext } from 'modules/core/contexts/user';

export function ComponentPulsusInsightsBatteriesContent({ route }: any) {
  const query = useQuery();
  const { t } = useTranslation();

  const [is_loading, set_is_loading] = useState(false);
  const [error_message, set_error_message] = useState<string | null>(null);
  const [is_modal_open, set_is_modal_open] = useState(false);
  const [query_string, set_query_string] = useState<string | null>(null);
  const [group_ids, set_group_ids] = useState<number[]>([]);
  const [chart_type, set_chart_type] = useState('groups');
  const [chart_days, set_chart_days] = useState('seven_days');
  const [chart_data, set_chart_data] = useState([]);
  const [virtual_range, set_virtual_range] = useState([8, 18]);
  const [range, set_range] = useState<[number, number]>([8, 18]);
  const [range_time, set_range_time] = useState([8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18]);
  const [groups, set_groups] = useState([]);
  const [loading, set_loading] = useState(true);
  const [message, set_message] = useState('');
  const [error, set_error] = useState('');
  const [selection, set_selection] = useState([]);

  ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

  const language = query.get('language') || ConstantLanguages.PT_BR;
  const { administrator, tenant } = useUserContext();

  useEffect(() => {
    i18next.changeLanguage(language);
    Mixpanel.track('pulsus-insights-acessou-pulsus-bateria', tenant, administrator);
  }, []);

  const handleOpenModal = () => {
    set_is_modal_open(true);
  };

  const handleCloseModal = () => {
    set_is_modal_open(false);
  };

  const handleChartTypeChange = (option: string) => {
    set_chart_type(option);
  };

  const handleChartDaysChange = (option: string) => {
    set_chart_days(option);
  };

  const handleRangeChange: EuiDualRangeProps['onChange'] = (value) => {
    set_range(value as [number, number]);
    debounceRange(value);
  };

  const debounceRange = useDebounce((value: number[]) => {
    set_virtual_range(value);
    set_range_time(
      TIME.filter((time, index) => {
        return index >= value[0] && index <= value[1];
      })
    );
  }, 2200);

  const normalizeTime = (time: number) => {
    if (time < 10) return `0${time}:00`;
    return `${time}:00`;
  };

  const findBatteryLevel = (chart_line: ChartItemProps, time: number) => {
    const found = chart_line.data.find((data_item: DataItemProps) => {
      return Number(data_item.x) === time;
    });
    return found ? found.y : null;
  };

  const batteryLevelBackground = (level: number | null) => {
    if (level) {
      if (level > 50) return ConstantColors.AFGHANISTAN;
      if (level <= 50 && level > 30) return ConstantColors.ANGOLA;
      if (level <= 30) return ConstantColors.ANDORRA;
    }

    return ConstantColors.CUBA;
  };

  const getBatteries = () => {
    ServicePulsusBalder.fetchAllBatteriesByGroupIds(group_ids, chart_type, virtual_range[0], virtual_range[1], chart_days)
      .then((items) => {
        if (items && items.length) {
          set_chart_data([]);
          set_chart_data(items);
        } else {
          set_chart_data([]);
          set_error_message('Não há dados para serem exibidos.');
        }
        set_is_loading(false);
      })
      .catch(() => {
        set_chart_data([]);
        set_error_message('Erro ao carregar os dados.');
        set_is_loading(false);
      });
  };

  useEffect(() => {
    const params = query.get('groups');

    if (params !== query_string) set_query_string(params);
  }, [query, query_string]);

  useEffect(() => {
    if (query_string) {
      set_group_ids(query_string.split(',').map((id) => Number(id)));
    }
  }, [query_string]);

  useEffect(() => {
    set_error_message('');

    if (group_ids && group_ids.length && !is_modal_open) {
      set_is_loading(true);
      getBatteries();
    }
  }, [group_ids, virtual_range, chart_type, chart_days]);

  useEffect(() => {
    set_loading(true);
    set_error('');
    set_groups([]);

    ServicePulsusBalder.fetchAllGroups()
      .then((items) => {
        set_loading(false);
        set_message('');
        set_error('');
        set_groups(items);
      })
      .catch(() => {
        set_groups([]);
        set_loading(false);
        set_message('');
        set_error(t('pages.pulsus_insights_batteries.modal.error_groups'));
      });
  }, [t]);

  const getOrCreateLegendList = (
    chart: {
      options: { plugins: { legend: { labels: { generateLabels: (arg0: any) => any } } } };
      config: { type: any };
      toggleDataVisibility: (arg0: any) => void;
      setDatasetVisibility: (arg0: any, arg1: boolean) => void;
      isDatasetVisible: (arg0: any) => any;
      update: () => void;
    },
    id: string
  ) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      legendContainer?.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: 'htmlLegend',
    afterUpdate(
      chart: {
        options: { plugins: { legend: { labels: { generateLabels: (arg0: any) => any } } } };
        config: { type: any };
        toggleDataVisibility: (arg0: any) => void;
        setDatasetVisibility: (arg0: any, arg1: boolean) => void;
        isDatasetVisible: (arg0: any) => any;
        update: () => void;
      },
      args: any,
      options: { containerID: any }
    ) {
      const ul = getOrCreateLegendList(chart, options.containerID);
      while (ul.firstChild) {
        ul.firstChild.remove();
      }
      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach(
        (item: {
          index: any;
          datasetIndex: any;
          fillStyle: string;
          strokeStyle: string;
          lineWidth: any;
          fontColor: string;
          hidden: any;
          text: string;
        }) => {
          const li = document.createElement('li');

          li.onclick = () => {
            chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
            chart.update();
          };

          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.style.borderColor = item.strokeStyle;
          boxSpan.style.borderWidth = `${item.lineWidth}px`;

          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

          const textWithoutAsterisks = item.text[item.text.length - 1] === '*' ? item.text.slice(0, item.text.length - 1) : item.text;
          const text = document.createTextNode(textWithoutAsterisks);
          textContainer.appendChild(text);

          if (item.text[item.text.length - 1] === '*') {
            const alertSpan = document.createElement('span');
            alertSpan.style.width = '0';
            alertSpan.style.height = '0';
            alertSpan.style.borderLeft = '6px solid transparent';
            alertSpan.style.borderRight = '6px solid transparent';
            alertSpan.style.borderBottom = '9px solid #FFD600';
            alertSpan.style.position = 'relative';
            alertSpan.style.borderRadius = '2px';
            alertSpan.style.marginLeft = '2px';

            const alertText = document.createElement('p');
            alertText.textContent = '!';
            alertText.style.color = 'white';
            alertText.style.position = 'absolute';
            alertText.style.fontSize = '4px';
            alertText.style.marginTop = '-3px';
            alertSpan.appendChild(alertText);

            boxSpan.appendChild(alertSpan);

            boxSpan.addEventListener('mouseenter', () => {
              boxSpan.title = `${t('pages.pulsus_insights_batteries.tooltip')}`;
            });
          }

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        }
      );
    },
  };

  return (
    <>
      <style.FilterGrayscale is_loading={is_loading}>
        <style.Container>
          <style.NavigationHeaderContainer>
            <style.TitleContainer>
              <h1>{t('pages.pulsus_insights_batteries.page_title')}</h1>
              <p>{t('pages.pulsus_insights_batteries.page_description')}</p>
            </style.TitleContainer>
            <style.FilterGroup fullWidth>
              <style.ChartFilterSection style={{ width: '180px' }}>
                <style.ChartLabel>{t('pages.pulsus_insights_batteries.sampling_button_label')}</style.ChartLabel>
                <style.EditFilterButton fill onClick={handleOpenModal}>
                  {`(${group_ids.length}) `}
                  {t('pages.pulsus_insights_batteries.sampling_button_text')}
                </style.EditFilterButton>
                <style.Error>{error_message}</style.Error>
              </style.ChartFilterSection>
              <style.ChartFilterSection style={{ width: '150px' }}>
                <ChartFilterType chartType={chart_type} handleChartTypeChange={handleChartTypeChange} disabled={!group_ids.length} />
              </style.ChartFilterSection>
              <style.ChartFilterSection style={{ width: '160px' }}>
                <ChartFilterDays chartDays={chart_days} handleChartDaysChange={handleChartDaysChange} disabled={!group_ids.length} />
              </style.ChartFilterSection>
              <style.EuiDualRangeContainer>
                <ChartFilterRangeTime range={range} handleRangeChange={handleRangeChange} disabled={!group_ids.length} />
                {is_modal_open && (
                  <ComponentsBatteriesFilterModal
                    route={route}
                    onClose={handleCloseModal}
                    selected_group_ids={group_ids}
                    getFilterSelect={getBatteries}
                    chartType={chart_type}
                    handleChartTypeChange={handleChartTypeChange}
                    chartDays={chart_days}
                    handleChartDaysChange={handleChartDaysChange}
                    range={range}
                    handleRangeChange={handleRangeChange}
                    loading={loading}
                    message={message}
                    error={error}
                    groups={groups}
                    set_groups={set_groups}
                    selection={selection}
                    set_selection={set_selection}
                  />
                )}
              </style.EuiDualRangeContainer>
            </style.FilterGroup>
          </style.NavigationHeaderContainer>
          <style.ChartContainer className="chart_container">
            <Line
              height={390}
              width={780}
              options={{
                maintainAspectRatio: false,
                responsive: false,
                scales: {
                  x: {
                    beginAtZero: false,
                    title: {
                      display: true,
                      text: `${t('pages.pulsus_insights_batteries.chart_labels_time')}`,
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: `${t('pages.pulsus_insights_batteries.chart_labels_battery_level')}`,
                    },
                    ticks: {
                      callback(value: any) {
                        return `${value}%`;
                      },
                    },
                  },
                },
                layout: {
                  autoPadding: true,
                },
                plugins: {
                  legend: {
                    display: false,
                    position: 'right',
                  },
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  htmlLegend: {
                    containerID: 'htmlLegend',
                  },
                },
              }}
              data={{
                labels: range_time,
                datasets: chart_data,
              }}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              plugins={[htmlLegendPlugin]}
            />
            <style.LegendContainer id="htmlLegend" />
          </style.ChartContainer>
          <style.BatteriesTable data-testid="batteries_table">
            <style.GroupLabelsList>
              <style.TitleList>
                {chart_type === 'users' ? (
                  <>
                    <style.RangeHoursLabel style={{ width: '40%' }}>{t('pages.pulsus_insights_batteries.list_label_id')}</style.RangeHoursLabel>
                    <style.RangeHoursLabel>{t('pages.pulsus_insights_batteries.list_label_name')}</style.RangeHoursLabel>
                  </>
                ) : (
                  <style.RangeHoursLabel>{t('pages.pulsus_insights_batteries.list_label_name')}</style.RangeHoursLabel>
                )}
              </style.TitleList>
              {chart_data?.map((item: ChartItemProps) => {
                const nameWithoutAsterisks = item.name?.replace(/\*/g, '');
                const labelWithoutAsterisks = item.label?.replace(/\*/g, '');

                return (
                  <style.GroupLabelListItem key={item.label + item.id}>
                    <style.GroupsLabelDot style={{ backgroundColor: `${item.backgroundColor}` }} />
                    {chart_type === 'users' ? (
                      <>
                        <style.GroupsLabelId style={{ width: '40%' }}>
                          <abbr title={labelWithoutAsterisks}>{`${labelWithoutAsterisks}`}</abbr>
                        </style.GroupsLabelId>

                        <style.GroupsLabelId style={{ fontWeight: 'normal' }}>
                          {item.name === 'not_found' || item.name === ' ' || (item.name && item.name.length <= 0)
                            ? `${t('pages.pulsus_insights_batteries.list_not_found_name')}`
                            : nameWithoutAsterisks}
                        </style.GroupsLabelId>
                      </>
                    ) : (
                      <style.GroupsLabelId>
                        <abbr title={labelWithoutAsterisks}>{labelWithoutAsterisks}</abbr>
                      </style.GroupsLabelId>
                    )}
                  </style.GroupLabelListItem>
                );
              })}
            </style.GroupLabelsList>
            <style.RangeHoursContainer>
              <style.LevelSectionContainer>
                {chart_data &&
                  chart_data.length > 0 &&
                  range_time.map((time) => {
                    return (
                      <div key={time}>
                        <style.TimeLabel>{normalizeTime(time)}</style.TimeLabel>
                        {chart_data.map((item: ChartItemProps) => {
                          const level = findBatteryLevel(item, time);
                          return (
                            <style.BatteryLevelContainer key={item.label + item.id}>
                              <style.BatteryLevel background={batteryLevelBackground(level)}>{level ? `${level}%` : '--'}</style.BatteryLevel>
                            </style.BatteryLevelContainer>
                          );
                        })}
                      </div>
                    );
                  })}
              </style.LevelSectionContainer>
            </style.RangeHoursContainer>
          </style.BatteriesTable>
        </style.Container>
      </style.FilterGrayscale>
      {is_loading && (
        <style.PlaceHolder>
          <Lottie
            data-testid="animation_logo_pulsus"
            animationData={animation_logo_pulsus_grey}
            style={{
              width: '500px',
              height: '500px',
              pointerEvents: 'none',
              margin: '0',
            }}
          />
        </style.PlaceHolder>
      )}
    </>
  );
}
