type MobileDeviceProps = {
  width?: number | string;
  heigth?: number | string;
  color?: string;
};

export function IconMobileDevice({ width = 23, heigth = 22, color = '#1483FF' }: MobileDeviceProps) {
  return (
    <svg width={width} height={heigth} viewBox="-4 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.08431 0.547382L1.9231 0.541016C1.24537 0.541016 0.697021 1.11402 0.697021 1.81436V13.2744C0.697021 13.9747 1.24537 14.5478 1.9231 14.5478H8.08431C8.76204 14.5478 9.31655 13.9747 9.31655 13.2744V1.81436C9.31655 1.11402 8.76204 0.547382 8.08431 0.547382ZM8.08431 12.0011H1.9231V3.08769H8.08431V12.0011Z"
        fill={color}
      />
    </svg>
  );
}
