import React from 'react';

type IconTheteringInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconTheteringInCicle({ width, height, elipse_fill, icon_fill }: IconTheteringInCicleProps) {
  return (
    <svg width={width || 44} height={height || 45} viewBox="0 0 44 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.24" cy="22.5" rx="21.6783" ry="22.5" fill={elipse_fill || '#30A8FF'} />
      <path
        d="M21.7583 21C20.6984 21 19.8313 21.9 19.8313 23C19.8313 24.1 20.6984 25 21.7583 25C22.8181 25 23.6852 24.1 23.6852 23C23.6852 21.9 22.8181 21 21.7583 21ZM27.5391 23C27.5391 19.69 24.9474 17 21.7583 17C18.5691 17 15.9774 19.69 15.9774 23C15.9774 25.22 17.1432 27.15 18.8678 28.19L19.8313 26.45C18.6848 25.75 17.9043 24.48 17.9043 23C17.9043 20.79 19.629 19 21.7583 19C23.8875 19 25.6122 20.79 25.6122 23C25.6122 24.48 24.8318 25.75 23.6852 26.45L24.6487 28.19C26.3733 27.15 27.5391 25.22 27.5391 23ZM21.7583 13C16.4399 13 12.1235 17.48 12.1235 23C12.1235 26.7 14.0601 29.92 16.9312 31.65L17.8947 29.92C15.6016 28.53 14.0504 25.96 14.0504 23C14.0504 18.58 17.4997 15 21.7583 15C26.0168 15 29.4661 18.58 29.4661 23C29.4661 25.96 27.9149 28.53 25.6122 29.92L26.5756 31.65C29.4564 29.92 31.393 26.7 31.393 23C31.393 17.48 27.0767 13 21.7583 13Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
