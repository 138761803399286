import { EuiRadio, EuiTextColor } from '@elastic/eui';
import { ComponentPasswordField } from 'components';
import { BannerFormatDeviceAlert, BannerFormatDeviceInstruction } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { PopupDeviceProps } from './popup-device.contracts';
import * as style from './popup-format-device.style';

export function FormatDevicePopup(props: PopupDeviceProps) {
  const { onClose, password, onChange, formatDevice } = props;
  const { t } = useTranslation();

  const [step, setStep] = useState(0);
  const [option, setOption] = useState('REMOTE_POWERWASH');

  function closeModal() {
    onClose();
    onChange('');
    setStep(0);
    setOption('REMOTE_POWERWASH');
  }

  function onChangePassword(value: string) {
    onChange(value);
  }

  return (
    <style.Container>
      {step === 0 && (
        <>
          <style.Left>
            <BannerFormatDeviceAlert />
          </style.Left>
          <style.Right>
            <style.Header>
              <style.Title>{t('pages.device_management.popups.format_device_title')}</style.Title>
              <style.Description>{t('pages.device_management.popups.format_device_description')}</style.Description>
            </style.Header>
            <style.Body>
              <style.RadioBox>
                <EuiRadio
                  id="format_power_wash"
                  checked={option === 'REMOTE_POWERWASH'}
                  label="Remote PowerWash"
                  onChange={() => setOption('REMOTE_POWERWASH')}
                />
                <style.RadioDescription>
                  <EuiTextColor>{t('pages.device_management.popups.format_device_radio')}</EuiTextColor>
                  <EuiTextColor color="secondary">{t('pages.device_management.popups.format_device_radio_bold')}</EuiTextColor>
                </style.RadioDescription>
              </style.RadioBox>
              <style.RadioBox>
                <EuiRadio id="format_wipe_user" checked={option === 'WIPE_USERS'} label="Wipe Users" onChange={() => setOption('WIPE_USERS')} />
                <style.RadioDescription>
                  <EuiTextColor>{t('pages.device_management.popups.format_device_radio_remove_data')}</EuiTextColor>
                  <EuiTextColor color="secondary">{t('pages.device_management.popups.format_device_radio_remove_data_bold')}</EuiTextColor>
                </style.RadioDescription>
              </style.RadioBox>
            </style.Body>
            <style.Footer>
              <style.LinkButton onClick={() => closeModal()}>{t('pages.device_management.popups.format_device_cancel_button')}</style.LinkButton>
              <style.Button onClick={() => setStep(step + 1)}>{t('pages.device_management.popups.format_device_confirm_button')}</style.Button>
            </style.Footer>
          </style.Right>
        </>
      )}
      {step === 1 && (
        <>
          <style.Left>
            <BannerFormatDeviceInstruction />
          </style.Left>
          <style.SecondRight>
            <style.UpContent>
              <style.Header>
                <style.SecondTitle>{t('pages.device_management.popups.format_device_second_title')}</style.SecondTitle>
                <style.Description>{t('pages.device_management.popups.format_device_second_description')}</style.Description>
              </style.Header>
              <style.Body>
                <ComponentPasswordField
                  label={t('pages.device_management.popups.format_device_password_input_label')}
                  value={password}
                  onChange={(e) => onChangePassword(e)}
                />
              </style.Body>
            </style.UpContent>
            <style.DownContent>
              <style.SecondFooter>
                <style.LinkButton onClick={() => closeModal()}>{t('pages.device_management.popups.format_device_cancel_button')}</style.LinkButton>
                <style.Button
                  onClick={() => {
                    formatDevice(option);
                    closeModal();
                  }}
                >
                  {t('pages.device_management.popups.format_device_format_button')}
                </style.Button>
              </style.SecondFooter>
            </style.DownContent>
          </style.SecondRight>
        </>
      )}
    </style.Container>
  );
}
