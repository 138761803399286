type IconProps = {
  size?: number | string;
  isSquare?: boolean;
};

export function AndroidStoreIcon({ size, isSquare }: IconProps) {
  return (
    <svg style={{ zoom: size || 1 }} width="30" height="31" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect y="0.511719" width="25" height="25" rx={isSquare ? '5' : '15'} fill="#3DDB85" />
      <path
        d="M17.7615 16.2297C17.486 16.2297 17.2619 15.9975 17.2619 15.7122C17.2619 15.427 17.486 15.1949 17.7615 15.1949C18.0371 15.1949 18.2612 15.427 18.2612 15.7122C18.2612 15.9975 18.0371 16.2297 17.7615 16.2297ZM12.2385 16.2297C11.9629 16.2297 11.7388 15.9975 11.7388 15.7122C11.7388 15.427 11.9629 15.1949 12.2385 15.1949C12.514 15.1949 12.7381 15.427 12.7381 15.7122C12.7381 15.9975 12.514 16.2297 12.2385 16.2297ZM17.9407 13.1134L18.9394 11.3226C18.9967 11.2197 18.9626 11.0882 18.8633 11.0288C18.7641 10.9695 18.6369 11.0048 18.5795 11.1075L17.5684 12.9209C16.7951 12.5556 15.9267 12.3521 15 12.3521C14.0733 12.3521 13.2049 12.5556 12.4316 12.9209L11.4205 11.1075C11.3631 11.0048 11.2359 10.9695 11.1367 11.0288C11.0374 11.0882 11.0032 11.2197 11.0606 11.3226L12.0593 13.1134C10.3444 14.079 9.17158 15.8765 9 18H21C20.8283 15.8765 19.6554 14.079 17.9407 13.1134Z"
        fill="white"
        transform="translate(-2, -2)"
      />
      <defs>
        <clipPath id="clip0_0_1">
          <rect y="0.511719" width="29.0301" height="30" rx="14.515" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
