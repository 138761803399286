/* eslint-disable react/jsx-wrap-multilines */
import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import type { WifiConfigComponentProps } from './WifiConfigComponent.contracts';
import * as S from './WifiConfigComponent.styles';
import { Button, CircularProgress, FormControl, IconButton, Input, InputAdornment, InputLabel, TextField } from '@mui/material';
import { ComponentRubySwitchButton, TextEllipsisComponent } from 'modules/core/components';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useTypedTranslation } from 'modules/core/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuItem from '@mui/material/MenuItem';

export const WifiConfigComponent = ({ handleChangeForm, wifiData }: WifiConfigComponentProps): ReactElement => {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [security, setSecutiry] = useState('None');
  const [password, setPassword] = useState('');
  const [ssid, setSsid] = useState<string>(null!);

  const [searchWifi, setSearchWifi] = useState('');

  const [aditionalConfigs, setAditionalConfigs] = useState({ hideNetwork: false, automaticConnection: false });

  const { t } = useTypedTranslation<'policies'>('policies');

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const createWifiConnection = async () => {
    try {
      const wifiItem = {
        name: ssid!,
        ssid: ssid!,
        security,
        passphrase: password,
        hidden: aditionalConfigs.hideNetwork,
        autoConnect: aditionalConfigs.automaticConnection,
        active: true,
        deviceWide: true,
      };

      handleChangeForm([...wifiData, wifiItem as any]);

      setSecutiry('');
      setPassword('');
      setSsid(undefined!);
      setSearchWifi('');
      setAditionalConfigs({ hideNetwork: false, automaticConnection: false });
    } catch (err) {
      window.parent.postMessage(JSON.stringify({ event: 'error', payload: t('errorCreate') }), process.env.REACT_APP_PULSUS_WEB_HOST!);
    } finally {
      setLoading(false);
    }
  };

  const handleSwitchWifi = (value: boolean, index: number) => {
    const newWifiList = [...wifiData];
    newWifiList[index].active = value;

    handleChangeForm(newWifiList);
  };

  const handleChangeFilteredList = (value: boolean, id: string) => {
    const newWifiList = [...wifiData];
    const index = wifiData.findIndex((wifi) => wifi.ssid === id);

    newWifiList[index].active = value;

    handleChangeForm([...newWifiList]);
  };

  const handleDelete = (ssidWifi: string) => {
    const arr = [...wifiData];

    handleChangeForm(arr.filter((wifiItem) => wifiItem.ssid !== ssidWifi));
  };

  const searchedList = useMemo(() => wifiData.filter((item) => item.name.toLowerCase().includes(searchWifi?.toLowerCase())), [searchWifi, wifiData]);
  const isInvalid = useMemo(() => ssid?.length === 0, [ssid]);

  const handleValidationBtn = () => {
    return !ssid || loading || (security !== 'None' && password === '');
  };

  return (
    <S.Container>
      <S.Inputs>
        <S.Title>Adicionar rede wi-fi</S.Title>
        <S.Input>
          <TextField
            error={isInvalid}
            helperText={isInvalid ? 'Campo obrigatório' : ''}
            label={
              <S.labelSsid>
                SSID<span> *</span>
              </S.labelSsid>
            }
            variant="standard"
            value={ssid === undefined ? '' : ssid}
            onChange={(e) => setSsid(e.target.value)}
          />
        </S.Input>
        <S.Input>
          <TextField
            select
            label={<S.labelSsid>Tipo de segurança</S.labelSsid>}
            variant="standard"
            onChange={(e) => {
              setSecutiry(e.target.value);
              setPassword('');
            }}
          >
            <MenuItem value="None">Nenhum</MenuItem>
            <MenuItem value="WEP-PSK">WEP-PSK</MenuItem>
            <MenuItem value="WPA-PSK">WPA-PSK</MenuItem>
          </TextField>
        </S.Input>

        <S.Input>
          <FormControl variant="standard">
            <InputLabel htmlFor="pass">
              <S.labelSsid>Senha</S.labelSsid>
            </InputLabel>
            <Input
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              id="pass"
              disabled={security === 'None'}
              type={showPassword ? 'text' : 'password'}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPassword ? <S.VisibilityStOff /> : <S.VisibilitySt />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </S.Input>
      </S.Inputs>
      <S.Switchers>
        <S.Input>
          <ComponentRubySwitchButton
            onChange={(value) => setAditionalConfigs({ ...aditionalConfigs, hideNetwork: value })}
            value={aditionalConfigs.hideNetwork}
            rightText="Rede oculta"
          />
          <ComponentRubySwitchButton
            onChange={(value) => setAditionalConfigs({ ...aditionalConfigs, automaticConnection: value })}
            value={aditionalConfigs.automaticConnection}
            rightText="Conexão automática"
          />
        </S.Input>
        <Button size="small" style={{ height: 40 }} disabled={handleValidationBtn()} onClick={createWifiConnection}>
          {loading ? <CircularProgress size={18} /> : <S.AddTitle>Adicionar</S.AddTitle>}
        </Button>
      </S.Switchers>

      <FormControl variant="standard" className="searchWifi">
        <S.LabelStyled htmlFor="search">Lista de redes Wi-fi disponíveis</S.LabelStyled>
        <Input
          value={searchWifi}
          onChange={(e) => setSearchWifi(e.target.value)}
          id="search"
          className="searchWifi"
          endAdornment={
            <InputAdornment position="end">
              <S.SearchIconSt />
            </InputAdornment>
          }
        />
      </FormControl>
      <S.WifiList>
        {searchedList.length > 0 ? (
          <>
            {searchedList.map((wifiItem, ind) => (
              <S.WifiItem key={wifiItem.ssid}>
                <TextEllipsisComponent>
                  <S.WifiItemName>{wifiItem.name}</S.WifiItemName>
                </TextEllipsisComponent>
                <S.WifitItemOption>
                  <ComponentRubySwitchButton onChange={(value) => handleSwitchWifi(value, ind)} value={wifiItem.active} />
                  <IconButton>
                    <DeleteIcon style={{ color: '#e42222' }} onClick={() => handleDelete(wifiItem.ssid)} />
                  </IconButton>
                </S.WifitItemOption>
              </S.WifiItem>
            ))}
          </>
        ) : (
          <>
            {wifiData.map((wifiItem, i) => (
              <S.WifiItem key={i}>
                <TextEllipsisComponent>
                  <span>{wifiItem.name}</span>
                </TextEllipsisComponent>
                <S.WifitItemOption>
                  <ComponentRubySwitchButton onChange={(value) => handleChangeFilteredList(value, wifiItem.ssid)} value={wifiItem.active} />
                  <IconButton>
                    <MoreVertIcon />
                  </IconButton>
                </S.WifitItemOption>
              </S.WifiItem>
            ))}
          </>
        )}
        {wifiData.length === 0 && searchedList.length === 0 && <S.MessageListEmpty>Sem redes encontradas</S.MessageListEmpty>}
      </S.WifiList>
    </S.Container>
  );
};
