import styled from 'styled-components';
import { ConstantColors } from 'utilities';

type StylesProps = {
  noPadding?: boolean;
  first?: boolean;
  last?: boolean;
};

export const List = styled.ul<StylesProps>`
  display: flex;
  flex-direction: row;
  padding: ${({ noPadding }) => (noPadding ? '' : '25px 0 50px 50px')};
`;

export const Item = styled.li<StylesProps>`
  display: flex;
  width: fit-content;
  height: 24px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-left: ${({ first }) => (first ? '0' : '-4px')};
  cursor: ${({ last }) => (last ? 'default' : 'pointer')};
  pointer-events: ${({ last }) => (last ? 'none' : 'auto')};
`;

export const ItemLabel = styled.div<StylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  background: ${({ last }) => (last ? ConstantColors.MALTA : ConstantColors.CHINA)};
  border-top-left-radius: ${({ first }) => (first ? '6px' : '0')};
  border-bottom-left-radius: ${({ first }) => (first ? '6px' : '0')};
  border-top-right-radius: ${({ last }) => (last ? '6px' : '0')};
  border-bottom-right-radius: ${({ last }) => (last ? '6px' : '0')};

  a {
    color: ${({ last }) => (last ? ConstantColors.INDIA : ConstantColors.FRANCE)};
  }
`;

export const ItemArrowLeft = styled.div<StylesProps>`
  display: ${({ first }) => (first ? 'none' : 'flex')};
  width: 0;
  height: 0;
  border-top: 12px solid ${({ last }) => (last ? ConstantColors.MALTA : ConstantColors.CHINA)};
  border-bottom: 12px solid ${({ last }) => (last ? ConstantColors.MALTA : ConstantColors.CHINA)};
  border-left: 10px solid transparent;
`;

export const ItemArrowRight = styled.div<StylesProps>`
  display: ${({ last }) => (last ? 'none' : 'flex')};
  width: 0;
  height: 0;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 10px solid ${ConstantColors.CHINA};
`;
