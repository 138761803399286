import { createGlobalStyle } from 'styled-components';
import { ConstantColors } from 'utilities';

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    background: ${ConstantColors.BRAZIL};
    width: 100%;
  }

  #root {
    width: 100%;
    height: 100%;
  }

  h1 {
    font-size: 34px;
    font-weight: 700;
    line-height: 40px;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
  }

  h3 {
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
  }

  h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }

  p {
    font-size: 14px;
    line-height: 17px;
  }

  a {
    font-style: normal;
    font-weight: bold;
    color: ${ConstantColors.CHILE};
  }

  a:hover {
    text-decoration: underline;
  }


  ::-webkit-scrollbar {
    width: 16px;
    height: 16px;
    padding: 0;
  };

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background-color: rgba(105, 112, 125, 0.5);
    background-clip: content-box;
    border-radius: 16px;
    border: 6px solid transparent;
  }


  .euiSwitch__label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px; 
    color: ${ConstantColors.ITALY};
  }

  .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
    background-color:  ${ConstantColors.ENGLAND}!important;
    opacity: 0.8;
  }

  .euiSwitch__body {
    background-color: ${({ theme }) => theme.main} !important;
  }

  .euiFormLabel-isFocused {
    color: #08090A !important;
  }
  
  .euiFieldText:focus {
    background-image: linear-gradient(to top, ${({ theme }) => theme.main}, ${({ theme }) => theme.main} 2px, transparent 2px, transparent 100%);
  }
`;
