import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as style from './component-breadcrumb.style';
import type { ComponentBreadcrumbProps } from './component-breadcrumb.contracts';

export function ComponentBreadcrumb({ items, noPadding }: ComponentBreadcrumbProps) {
  const { t } = useTranslation();

  return (
    <style.List noPadding={noPadding} data-testid="component__breadcrumb">
      {items.map((item, index) => {
        const is_first = index === 0;
        const is_last = items.length === index + 1;

        return (
          <style.Item data-testid="component__breadcrumb-item" key={`${item.href}-${item.translation}`} first={is_first} last={is_last}>
            <style.ItemArrowLeft first={is_first} last={is_last} />
            <style.ItemLabel first={is_first} last={is_last}>
              <Link to={item.href} data-testid="component__breadcrumb-link">
                {t(item.translation)}
              </Link>
            </style.ItemLabel>
            <style.ItemArrowRight last={is_last} />
          </style.Item>
        );
      })}
    </style.List>
  );
}
