import { EuiIconTip, EuiSelect } from '@elastic/eui';
import type { SelectFieldProps } from './component-select-field.contracts';
import * as style from './component-select-field.style';

export function ComponentSelectField(props: SelectFieldProps) {
  const { className, value, onChange, label, disabled, placeholder, compressed, tootip, options } = props;
  return (
    <style.FieldBox data-testid="component-selectfield-box">
      <style.HeaderContent>
        {label && <style.FieldLabel id="component-selectfield-label">{label}</style.FieldLabel>}
        {tootip && <EuiIconTip content={tootip} position="top" type="iInCircle" />}
      </style.HeaderContent>
      <EuiSelect
        id="component-selectfield-field"
        className={className}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        compressed={compressed}
        options={options}
      />
    </style.FieldBox>
  );
}
