import { EuiButton } from '@elastic/eui';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { ConfigProps } from './page-android-enterprise.contracts';

export const Content = styled.main`
  background-color: ${ConstantColors.BULGARIA};
  padding-bottom: 70px;

  .theme-color {
    color: ${({ theme }) => theme.main};
  }
`;

export const TopContainer = styled.section`
  padding: 0 50px 1px;
  background-color: ${ConstantColors.AUSTRIA};
  display: flex;
  align-items: flex-start;

  img {
    width: 45%;
    height: auto;
  }
`;

export const LeftContainer = styled.section`
  max-width: 55%;
`;

export const About = styled.div`
  h1 {
    margin: 10px 0;
  }

  p {
    color: ${ConstantColors.ITALY};
  }
`;

export const AccInfo = styled.div`
  margin: 40px 0 40px 20px;
  padding: 20px 15px;
  background-color: #f5f7fa;
  max-width: 350px;
  border-radius: 13px;

  p {
    color: ${ConstantColors.ITALY};
    margin: 5px 0;
  }

  h2 {
    margin-bottom: 10px;
  }

  .britgher-color {
    color: ${ConstantColors.LAOS};
  }

  .atach-texts {
    margin: 15px 0;
  }
`;

export const ButtonDetachAcc = styled(EuiButton)`
  margin-top: 15px;
  background-color: ${ConstantColors.CHILE} !important;
  font-size: 15px;
  font-weight: 500;
`;

export const ZeroTouch = styled.section`
  padding: 30px;
  max-width: 550px;
  margin-bottom: 50px;

  display: flex;
  justify-content: space-between;

  background-color: ${ConstantColors.AUSTRIA};
  box-shadow:
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 13px;

  .config {
    margin-top: 20px;
    font-weight: 700;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      transition: 0.3s;
      transform: translateY(-2px);
      text-decoration: underline;
    }
  }

  div {
    max-width: 280px;
  }

  h2 {
    margin: 20px 0;
  }

  p {
    color: ${ConstantColors.ITALY};
  }

  img {
    width: 170px;
    height: 170px;
  }
`;

export const SaveConfig = styled.section`
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin: 35px 0 35px 50px;
`;

export const SaveButton = styled(EuiButton)``;

export const ConfigContainer = styled.section<ConfigProps>`
  ${({ isEditable }) => css`
    opacity: ${isEditable ? 1 : 0.4};
  `}

  max-width: 97%;
`;

export const ModalFooter = styled.div`
  display: flex;
  padding: 0px 60px 10px 60px;
  gap: 20px;

  button {
    border-radius: 5px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    :focus {
      background-color: transparent;
    }
  }

  button.confirm {
    padding: 7px;
    width: 202px;
    height: 38px;
    background-color: ${({ theme }) => theme.main};
    color: ${({ theme }) => theme.contrast};
    box-shadow: none;
    border: none;
    :hover,
    :focus {
      background-color: ${({ theme }) => theme.main} !important;
      box-shadow: none !important;
    }

    :disabled {
      background-color: lightgray !important;
      box-shadow: none !important;
    }
  }
`;
