import { mdiCameraEnhanceOutline, mdiMicrophone, mdiUsb, mdiVolumeHigh } from '@mdi/js';
import { ManagementCard } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { PolicyBody, StoreProps } from 'stores/stores.contracts';
import { useTranslation } from 'react-i18next';
import * as componentStyle from './peripherals-management-card.style';
import * as cardStyle from '../hardware-cards.style';

const style = { ...cardStyle, ...componentStyle };

export function PeripheralsManagementCard() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store: PolicyBody | undefined = useSelector((stores: StoreProps) => stores?.chromeOS?.configuration?.policy);

  const [policy, setPolicy] = useState<PolicyBody | undefined>(store);

  function emmitChange(key: string, value: boolean | string) {
    if (store) {
      const aux = JSON.parse(JSON.stringify(policy));
      aux.user_policies[key] = value;
      dispatch({ type: 'CHROMEOS_CHANGE_CONFIGURATION_POLICY', payload: aux });
    }
  }

  useEffect(() => {
    if (store) {
      setPolicy(store);
    }
  }, [store]);

  return (
    <style.Card>
      <style.SettingsBox>
        <ManagementCard
          title={t('components.component_peripherals_management_card.camera_card_title')}
          description={t('components.component_peripherals_management_card.camera_card_description')}
          active={policy?.user_policies?.videoCaptureAllowed}
          icon={mdiCameraEnhanceOutline}
          onClick={() => {
            emmitChange('videoCaptureAllowed', !policy?.user_policies?.videoCaptureAllowed);
          }}
        />
        <ManagementCard
          title={t('components.component_peripherals_management_card.audio_capture_card_title')}
          description={t('components.component_peripherals_management_card.audio_capture_card_description')}
          active={policy?.user_policies?.audioCaptureAllowed}
          icon={mdiMicrophone}
          onClick={() => {
            emmitChange('audioCaptureAllowed', !policy?.user_policies?.audioCaptureAllowed);
          }}
        />
        <ManagementCard
          title={t('components.component_peripherals_management_card.external_storage_card_title')}
          description={t('components.component_peripherals_management_card.external_storage_card_description')}
          active={policy?.user_policies?.externalStorageDevices === 'EXTERNAL_STORAGE_ENUM_READ_WRITE' || false}
          icon={mdiUsb}
          onClick={() => {
            emmitChange(
              'externalStorageDevices',
              policy?.user_policies?.externalStorageDevices === 'EXTERNAL_STORAGE_ENUM_READ_WRITE'
                ? 'EXTERNAL_STORAGE_ENUM_DISALLOW'
                : 'EXTERNAL_STORAGE_ENUM_READ_WRITE'
            );
          }}
        />
        <ManagementCard
          title={t('components.component_peripherals_management_card.audio_output_card_title')}
          description={t('components.component_peripherals_management_card.audio_output_card_description')}
          active={policy?.user_policies?.audioOutputAllowed}
          icon={mdiVolumeHigh}
          onClick={() => {
            emmitChange('audioOutputAllowed', !policy?.user_policies?.audioOutputAllowed);
          }}
        />
      </style.SettingsBox>
    </style.Card>
  );
}
