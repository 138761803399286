import React from 'react';
import PropTypes from 'prop-types';
import * as style from './nav-item-link.style';

export function NavItemLink({ children, test_id, to }) {
  return (
    <style.StyledLink data-testid={test_id} to={to}>
      {children}
    </style.StyledLink>
  );
}

NavItemLink.propTypes = {
  children: PropTypes.node,
  test_id: PropTypes.string,
  to: PropTypes.string,
};

NavItemLink.defaultProps = {
  children: '',
  test_id: 'component__application-main_nav-item-link',
  to: '',
};
