export function IconFenceInFenceOut({ color }: { color?: string }) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3265 5.21537L10.5438 5.99628L11.976 7.43073H6.3306V8.53841H11.976L10.5438 9.96732L11.3265 10.7538L14.102 7.98457L11.3265 5.21537ZM4.1102 4.10768H8.551V3H4.1102C3.49959 3 3 3.49846 3 4.10768V11.8615C3 12.4707 3.49959 12.9691 4.1102 12.9691H8.551V11.8615H4.1102V4.10768Z"
        fill={color || 'white'}
      />
      <path
        d="M16.8289 12.5594L16.8289 13.6228L12.4426 13.6228L12.4426 22.2764L16.8288 22.2764L16.8288 23.3398L12.4426 23.3398C12.1569 23.3398 11.904 23.2323 11.6838 23.0173C11.4636 22.8023 11.3535 22.5553 11.3535 22.2764L11.3535 13.6228C11.3535 13.3439 11.4636 13.0969 11.6838 12.8819C11.904 12.6669 12.1569 12.5594 12.4426 12.5594L16.8289 12.5594ZM18.2611 15.1088L19.0667 15.8809L17.4853 17.4251L22.6473 17.4251L22.6473 18.4886L17.5151 18.4886L19.0966 20.0329L18.2909 20.7904L15.3668 17.935L18.2611 15.1088Z"
        fill={color || 'white'}
      />
    </svg>
  );
}
