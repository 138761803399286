import { Autocomplete as MaterialAutocomplete, TextField } from '@mui/material';
import { HelperTheme } from 'helpers';
import type { AutocompleteOptionProps, AutocompleteProps } from './component-autocomplete.contracts';
import * as style from './component-autocomplete.style';

export function Autocomplete(props: AutocompleteProps) {
  const { label, options, value, disabled, onChange } = props;

  const theme = HelperTheme.getTheme();
  return (
    <style.ComponentBox>
      <MaterialAutocomplete
        disabled={disabled}
        options={options}
        value={value}
        onChange={(event, option) => {
          onChange(option);
        }}
        getOptionLabel={(option: AutocompleteOptionProps) => option.dropdownDisplay}
        renderInput={(params) => (
          <TextField
            sx={{
              '& .MuiInputLabel-root.Mui-focused': { color: theme.main },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: theme.main,
                },
              },
            }}
            {...params}
            label={value?.id ? 'Selecionado' : label}
          />
        )}
      />
    </style.ComponentBox>
  );
}
