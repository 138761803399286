import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 16px;
  text-align: center;

  p {
    font-size: 14px;
    line-height: 18.57px;
  }

  span {
    font-weight: bold;
  }

  h1 {
    color: ${ConstantColors.COMOROS};
    font-size: 20px;
  }
`;

export const CheckboxGroups = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
`;

export const ButtonLink = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  border: none;
  box-shadow: none !important;
  color: #30a8ff;
  min-width: fit-content;

  &:hover {
    background: none !important;
  }
`;

export const Button = styled(EuiButton)`
  min-width: 112px;
  min-height: 39px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;
