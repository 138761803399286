import { AndroidStoreIcon, AppStoreIcon, AppleFilterIcon, AppleStoreIcon } from 'icons';
import type { APIAppsApplication, APIDistributionCounter } from 'modules/operational/entities/app.entity';
import type { DashboardFilterTypes, DistributionCounter } from 'modules/operational/components/apps/DashboardFilter/AppsDashboardFilter.contracts';
import type { DistributionParams } from './PageApps.contracts';

export class AppHelper {
  static findDistributionIcon(application: APIAppsApplication): React.ReactNode {
    let icon: React.ReactNode;
    if (application.platform.toLowerCase() === 'android') {
      if (application.current_version.enterprise_app === true) {
        icon = <AppStoreIcon size={0.8} isSquare />;
      } else {
        icon = <AndroidStoreIcon size={0.8} isSquare />;
      }
    } else if (application.platform.toLowerCase() === 'ios') {
      if (application.kind === 4) {
        icon = <AppleStoreIcon sizePx="23px" />;
      } else {
        icon = <AppleFilterIcon size={0.8} isSquare />;
      }
    }

    return icon;
  }

  static findDistributionParams(distribution: DashboardFilterTypes): DistributionParams {
    const distributionParams = {
      android: {
        platform: 'Android',
      },
      ios: {
        platform: 'iOS',
      },
      androidPlayStore: {
        'enterprise-app': true,
        kind: 2,
        platform: 'Android',
      },
      androidAPK: {
        'enterprise-app': false,
        kind: 2,
        platform: 'Android',
      },
      iosAppleStore: {
        'enterprise-app': false,
        kind: 4,
        platform: 'iOS',
      },
      iosAppleStorePackage: {
        'enterprise-app': false,
        kind: 2,
        platform: 'iOS',
      },
    };

    return distributionParams[distribution];
  }

  static findDistributionCounter(distributionAttributes: APIDistributionCounter[]): DistributionCounter {
    const distribution: DistributionCounter = {
      androidStore: 0,
      androidPackage: 0,
      iOSStore: 0,
      iOSPackage: 0,
      chromeStore: 0,
      chromePackage: 0,
    };

    distributionAttributes.forEach((item) => {
      if (item.platform.toLowerCase() === 'android') {
        if (item.enterprise_app === true) {
          distribution.androidStore = item.dist_count;
        } else {
          distribution.androidPackage = item.dist_count;
        }
      } else if (item.platform.toLowerCase() === 'ios') {
        if (item.kind === 4) {
          distribution.iOSStore = item.dist_count;
        } else {
          distribution.iOSPackage = item.dist_count;
        }
      }
    });

    return distribution;
  }
}
