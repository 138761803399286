import type { Settings } from 'react-slick';
import Slider from 'react-slick';
import * as style from './component-generic-slider.style';

export type SliderSettings = Settings;

type SliderProps = {
  children: React.ReactNode;
  settings: SliderSettings;
};

export function ComponentSlider({ children, settings }: SliderProps) {
  return (
    <style.Wrapper>
      <Slider {...settings}>{children}</Slider>
    </style.Wrapper>
  );
}
