import { WifiList } from '../WifiList/WifiList.entity';

export type NetworkMode = 'blocked' | 'neutral' | 'active';

export type UsbConfigOptions = 'EXTERNAL_STORAGE_ENUM_READ_WRITE' | 'EXTERNAL_STORAGE_ENUM_READ_ONLY' | 'EXTERNAL_STORAGE_ENUM_DISALLOW';

export class ChromePolicies {
  public allowBluetooth: boolean;
  public videoCaptureAllowed: boolean;
  public audioOutputAllowed: boolean;
  public audioCaptureAllowed: boolean;
  public networkMode: NetworkMode;
  public wifiList: WifiList[];
  public usbConfig: UsbConfigOptions;
  public ephemeralUsersEnabled: boolean;
  public urlBlocking: { mode: string; urls: Array<unknown> };
  public deviceDisabledMessage: string;
  public urlAllowedList: string[];

  constructor(data: ChromePolicies.Server) {
    this.allowBluetooth = data.device_policies.allowBluetooth;
    this.videoCaptureAllowed = data.user_policies.videoCaptureAllowed;
    this.audioCaptureAllowed = data.user_policies.audioCaptureAllowed;
    this.audioOutputAllowed = data.user_policies.audioOutputAllowed;
    this.networkMode = data.networks.mode as NetworkMode;
    this.wifiList = data.networks.networks.map((wifi) => new WifiList(wifi));
    this.usbConfig = data.user_policies.externalStorageDevices;
    this.ephemeralUsersEnabled = data.device_policies.ephemeralUsersEnabled;
    this.urlBlocking = data.user_policies.urlBlocking;
    this.deviceDisabledMessage = data.device_policies.deviceDisabledMessage;
    this.urlAllowedList = data.user_policies.urlAllowedList;
  }
}

export namespace ChromePolicies {
  export type Server = {
    device_policies: {
      allowBluetooth: boolean;
      ephemeralUsersEnabled: boolean;
      deviceDisabledMessage: string;
    };

    user_policies: {
      videoCaptureAllowed: boolean;
      audioOutputAllowed: boolean;
      audioCaptureAllowed: boolean;
      externalStorageDevices: UsbConfigOptions;
      urlBlocking: {
        mode: string;
        urls: Array<unknown>;
      };
      urlAllowedList: [];
    };
    networks: {
      mode: NetworkMode;
      networks: WifiList.Server[];
    };
  };
}
