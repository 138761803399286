import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';

type ContainerProps = {
  isSelected: boolean;
};

export const Container = styled.main<ContainerProps>`
  ${({ isSelected }) => css`
    width: 86px;
    height: 86px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-color: ${isSelected ? ConstantColors.JAMAICA : ConstantColors.AUSTRIA};
    border: 1px solid ${isSelected ? ConstantColors.EMIRATES : ConstantColors.INDONESIA};
    border-radius: 6px;

    cursor: pointer;

    p {
      margin-top: 10px;
      font-size: 11px;
      font-weight: ${isSelected ? 700 : 600};
      transition: 0.1s;
    }

    svg {
      transition: 0.3s;
    }

    &:hover {
      p {
        transition: 0.3s;
        transform: translateY(-2px);
        text-decoration: underline;
      }

      svg {
        transition: 0.3s;
        transform: translateY(-2px);
      }
    }
  `}
`;
