import React from 'react';
import PropTypes from 'prop-types';
import * as style from './nav-item-title-primary.style';

export function NavItemTitlePrimary({ children }) {
  return <style.TitlePrimary>{children}</style.TitlePrimary>;
}

NavItemTitlePrimary.propTypes = {
  children: PropTypes.node,
};

NavItemTitlePrimary.defaultProps = {
  children: '',
};
