import React from 'react';
import PropTypes from 'prop-types';
import { ConstantColors } from 'utilities';
import { IconPulsusLogo } from 'icons';
import * as style from './component-login-container.style';

export function ComponentLoginContainer({ children, test_id }) {
  return (
    <style.RegisterContainer data-testid={test_id}>
      <style.LogoContainer>
        <IconPulsusLogo width="260px" height="55px" color={ConstantColors.AUSTRIA} />
      </style.LogoContainer>
      {children}
      <style.Copyright>Copyright © 2021 MDM Solutions</style.Copyright>
    </style.RegisterContainer>
  );
}

ComponentLoginContainer.propTypes = {
  children: PropTypes.node,
  test_id: PropTypes.string,
};

ComponentLoginContainer.defaultProps = {
  children: '',
  test_id: 'component__login-container',
};
