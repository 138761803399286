import * as S from './ComponentRubySwitchButton.style';
import type { ComponentRubySwitchButtonProps } from './ComponentRubySwitchButton.contracts';

export function ComponentRubySwitchButton({ value, onChange, icon, leftText, rightText }: ComponentRubySwitchButtonProps) {
  const colors = {
    off: '#888b92',
    on: '#32a852',
  };

  return (
    <S.Container>
      {icon && <S.CustomIcon path={icon} color={value ? colors.on : colors.off} />}
      <>
        {leftText && <S.SwitchText>{leftText}</S.SwitchText>}
        <S.CustomSwitch checked={value} onChange={(e) => onChange(e.target.checked)} />
        {rightText && <S.SwitchText>{rightText}</S.SwitchText>}
      </>
    </S.Container>
  );
}
