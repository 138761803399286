import { EuiButtonEmpty } from '@elastic/eui';
import styled from 'styled-components';

export const TagNameButton = styled(EuiButtonEmpty)`
  color: #0077cc;
  height: 32px;

  & span {
    padding: 0 !important;
  }
`;

export const TagItem = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  max-width: 340px;
`;

export const GroupCount = styled.button`
  all: unset;
  color: #afafaf;
  font-size: 12px;
  white-space: nowrap;
`;

export const GroupTitle = styled.p`
  color: #1a1c21;
  font-size: 15px;
  font-weight: 700;
`;

export const TagList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 416px;
  overflow: auto;
`;

export const WrapperButton = styled.div`
  max-width: 75%;
  display: flex;
  flex-direction: row;
  gap: 4px;
  align-items: center;
`;
