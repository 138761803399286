import * as S from './ComponentButton.styles';
import type { ButtonComponentProps } from './ComponentButton.contracts';

export function ComponentButton({ label, onClick, isDisabled, loading }: ButtonComponentProps) {
  return (
    <S.Container>
      <S.Button fill onClick={(e: unknown) => onClick(e)} isLoading={loading} disabled={isDisabled}>
        {label}
      </S.Button>
    </S.Container>
  );
}
