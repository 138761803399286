type GpsProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconGps({ width = 22, height = 22, color = '#6E6E6E', test_id = 'icons__gps' }: GpsProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 7c-2.21 0-4 1.79-4 4s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4Zm8.94 3A8.994 8.994 0 0 0 12 2.06V0h-2v2.06A8.994 8.994 0 0 0 2.06 10H0v2h2.06A8.994 8.994 0 0 0 10 19.94V22h2v-2.06A8.994 8.994 0 0 0 19.94 12H22v-2h-2.06ZM11 18c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7Z"
        fill={color}
      />
    </svg>
  );
}
