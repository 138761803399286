import styled from 'styled-components';

export const Wrapper = styled.button`
  width: 353px;
  height: 118px;
  display: flex;
  justify-content: space-between;

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  &:hover {
    transition: 0.3s;
    transform: translateY(-2px);
    p {
      text-decoration: underline;
    }
  }
`;

export const Texts = styled.div`
  text-align: left;
  width: 250px;

  h3 {
    margin-bottom: 10px;
  }
`;
