import { EuiButtonEmpty } from '@elastic/eui';
import styled from 'styled-components';

export const Content = styled.main`
  margin: 30px 0 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const DownloadButton = styled(EuiButtonEmpty)`
  padding: 70px 0 20px;
  color: #30a8ff;
  font-weight: 500;
`;
