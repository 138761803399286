import { mdiCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { ConstantColors } from 'utilities';
import * as style from './component-dashboard-card.style';
import type { DashboardCardProps } from './component-dashboard-card.contracts';

export function ComponentDashboardCard(props: DashboardCardProps) {
  const { icon, title, info, description, statusColor, className, background = ConstantColors.AUSTRIA } = props;

  return (
    <style.Container className={className}>
      <style.Card background={background}>
        <style.CardHeader>
          <style.CardIcon>{icon}</style.CardIcon>
          <style.CardTitle>{title}</style.CardTitle>
        </style.CardHeader>
        <style.CardBody>
          <style.CardInfo>{info}</style.CardInfo>
        </style.CardBody>
        <style.CardFooter>
          <style.CardDescription>{description}</style.CardDescription>
          <style.CardStatus>
            <Icon path={mdiCircle} title="status" size="8px" color={statusColor} />
          </style.CardStatus>
        </style.CardFooter>
      </style.Card>
    </style.Container>
  );
}
