import { ComponentModal } from 'components/modal/component-modal';
import { useState } from 'react';
import { ComponentApplicationContainer } from 'modules/core/components';
import { BoxMobileData } from './box-mobile-data';
import { BoxWifiBluetooth } from './box-wifi-bluetooth';
import { ModalAddNewWifiContent } from './modal-add-new-wifi-content';
import { ModalWifiNetworksListContent } from './modal-wifi-networks-list-content';
import * as style from './page-connectivity.style';

export function PageConnectivity() {
  const [is_open_wifi_modal, set_is_open_wifi_modal] = useState(false);
  const [is_opened_networks_list, set_is_opened_networks_list] = useState(false);
  const [is_opened_add_new_wifi, set_is_opened_add_new_wifi] = useState(false);

  const [networks_list, set_networks_list] = useState([
    {
      network: 'AAHSH 12301 - Level 1 - teste',
      security: 'WPA/WPA2',
      status: 'Rede habilitada',
    },
    { network: 'DSH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede habilitada' },
    { network: 'GSH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede habilitada' },
    { network: 'CSH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede habilitada' },
    { network: 'FSH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede desabilitada' },
    { network: 'BSH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede habilitada' },
    { network: 'ESH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede desabilitada' },
    { network: 'FSH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede habilitada' },
    { network: 'ASH 12301 - Level 1', security: 'WPA/WPA2', status: 'Rede habilitada' },
  ]);

  const open_wifi_modal = () => {
    set_is_open_wifi_modal(true);
    set_is_opened_networks_list(true);
    set_is_opened_add_new_wifi(false);
  };

  const close_wifi_modal = () => {
    set_is_open_wifi_modal(false);
    set_is_opened_networks_list(false);
    set_is_opened_add_new_wifi(false);
  };

  const to_new_network_modal = () => {
    set_is_open_wifi_modal(true);
    set_is_opened_networks_list(false);
    set_is_opened_add_new_wifi(true);
  };

  const to_networks_list_modal = () => {
    set_is_open_wifi_modal(true);
    set_is_opened_networks_list(true);
    set_is_opened_add_new_wifi(false);
  };

  return (
    <ComponentApplicationContainer>
      <style.Container>
        <h3 style={{ width: '100%' }}> Conectividade</h3>
        <div style={{ display: 'flex', gap: '30px', flexWrap: 'wrap' }}>
          <BoxWifiBluetooth open_wifi_modal={open_wifi_modal} />
          <BoxMobileData />
        </div>
      </style.Container>
      {is_open_wifi_modal && (
        <ComponentModal width="678px" onClose={() => close_wifi_modal()}>
          {is_opened_networks_list && (
            <ModalWifiNetworksListContent
              to_new_network_modal={to_new_network_modal}
              networksList={networks_list}
              setNetworksList={set_networks_list}
            />
          )}
          {is_opened_add_new_wifi && <ModalAddNewWifiContent to_networks_list_modal={to_networks_list_modal} close_wifi_modal={close_wifi_modal} />}
        </ComponentModal>
      )}
    </ComponentApplicationContainer>
  );
}
