export class WifiList {
  public ssid: string;
  public name: string;
  public hiddenSsid: boolean;
  public automaticallyConnect: boolean;
  public security: string;
  public active: boolean;
  public deviceWide: boolean;
  public passphrase?: string;

  constructor(data: WifiList.Server) {
    this.active = data.active;
    this.ssid = data.ssid;
    this.name = data.name;
    this.hiddenSsid = data.hiddenSsid;
    this.automaticallyConnect = data.automaticallyConnect;
    this.security = data.security;
    this.deviceWide = data.device_wide;
    this.passphrase = data.passphrase;
  }
}

export namespace WifiList {
  export type Server = {
    ssid: string;
    name: string;
    hiddenSsid: boolean;
    passphrase?: string;
    automaticallyConnect: boolean;
    security: string;
    active: boolean;
    device_wide: boolean;
  };
}
