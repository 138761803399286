import { EuiFieldText, EuiIconTip } from '@elastic/eui';
import type { TextFieldProps } from './component-text-field.contracts';
import * as style from './component-text-field.style';

export function ComponentTextField(props: TextFieldProps) {
  const { className, value, onChange, label, disabled, placeholder, type, compressed, tootip, id, fullWidth, test_id } = props;
  return (
    <style.FieldBox data-testid={test_id || 'component-textfield-box'}>
      <style.HeaderContent>
        {label && <style.FieldLabel id="component-textfield-label">{label}</style.FieldLabel>}
        {tootip && <EuiIconTip content={tootip} position="top" type="iInCircle" />}
      </style.HeaderContent>
      <EuiFieldText
        id={id || 'component-textfield-field'}
        className={className}
        placeholder={placeholder}
        type={type}
        disabled={disabled}
        value={value}
        onChange={(e) => {
          if (onChange) {
            onChange(e.target.value);
          }
        }}
        compressed={compressed}
        fullWidth={fullWidth}
      />
    </style.FieldBox>
  );
}
