import type { ThemeToggleComponentProps } from './ThemeToggleComponent.contracts';
import * as S from './ThemeToggleComponent.styles';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Brightness4Icon from '@mui/icons-material/Brightness4';

export const ThemeToggleComponent = ({ onClick, selected }: ThemeToggleComponentProps) => {
  return (
    <S.Main onClick={() => onClick(selected === 'light' ? 'dark' : 'light')}>
      <S.IconContainer className={selected}>
        <Brightness7Icon className={`sun-icon ${selected === 'dark' && 'hidden'}`} />
        <Brightness4Icon className={`moon-icon ${selected === 'light' && 'hidden'}`} />
      </S.IconContainer>
    </S.Main>
  );
};
