import styled from 'styled-components';

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const FieldLabel = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;
