import { htmlIdGenerator } from '@elastic/eui';
import * as S from './ComponentRubyGroupButton.style';
import type { ComponentRubyGroupButtonProps, SelectedIdTypes } from './ComponentRubyGroupButton.contracts';
import { useState } from 'react';

export function ComponentRubyGroupButton({ selectedId, onChange, icon }: ComponentRubyGroupButtonProps) {
  const idPrefix = htmlIdGenerator()();

  const toggleButtons = [
    {
      id: `${idPrefix}_blocked`,
      value: 'blocked',
      label: 'Desligado',
    },
    {
      id: `${idPrefix}_neutral`,
      value: 'neutral',
      label: 'Neutro',
    },
    {
      id: `${idPrefix}_active`,
      value: 'active',
      label: 'Ligado',
    },
  ];
  const [colors] = useState({
    blocked: '#d8d9db',
    neutral: '#969799',
    active: '#32a852',
  });

  return (
    <S.Container>
      <S.CustomIcon path={icon} color={colors[selectedId]} />
      <S.ButtonGroup
        legend=""
        options={toggleButtons}
        idSelected={`${idPrefix}_${selectedId}`}
        buttonSize="s"
        onChange={(e, value: SelectedIdTypes) => onChange(value)}
      />
    </S.Container>
  );
}
