import axios from 'axios';
import { ChartLine } from 'entities/chart-line';
import { ConstantServicesEndpoints } from 'utilities';
import { HelperAuthentication } from 'helpers';
import { Group } from 'entities/group';
import { MapOption } from 'entities/map-option';
import { isNull as lodashValueIsNull } from 'lodash';
import type { AxiosInstance } from 'axios';

export class ServicePulsusBalder {
  static instance: AxiosInstance;

  static getInstance() {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: process.env.REACT_APP_URL_PULSUS_BALDER_API,
        headers: {
          Authorization: HelperAuthentication.bearer(),
          'X-Schema': HelperAuthentication.decodedSchemaName(),
        },
      });
    }

    return this.instance;
  }

  static async fetchAllBatteriesByGroupIds(group_ids, type, time_start, time_end, period_in_days) {
    const { data } = await this.getInstance().get(ConstantServicesEndpoints.PULSUS_BALDER.BATTERIES, {
      params: {
        group_id: group_ids.toString(),
        type,
        interval: `${time_start},${time_end}`,
        period: `${period_in_days}`,
        async_query: true,
        cassandra_spark: 'cassandra',
      },
    });

    const response = await this.getInstance().get(`${ConstantServicesEndpoints.PULSUS_BALDER.PERIOD}?group_id=${group_ids.toString()}`);

    const { period_available } = response.data;

    return (
      data.batteries &&
      data.batteries.map((item, index) => {
        if (item.metadata) {
          const { device_id } = item.metadata;

          return new ChartLine(item.id, item.data, item.color, device_id, period_available[index], period_in_days, type);
        }
        return new ChartLine(item.id, item.data, item.color, item.id, period_available[index], period_in_days, type);
      })
    );
  }

  static async fetchAllGroups() {
    const { data } = await this.getInstance().get(ConstantServicesEndpoints.PULSUS_BALDER.GROUPS);

    return data.groups.map((item) => new Group(item.name, lodashValueIsNull, item.id, lodashValueIsNull, item.total_devices));
  }

  static async getMapOptions(language) {
    const { data } = await axios
      .create({
        baseURL: process.env.REACT_APP_URL_PULSUS_BALDER_API,
      })
      .get(`${ConstantServicesEndpoints.PULSUS_BALDER.MONTHS}?total_months=20`);

    return data.map_history.map((item) => {
      return new MapOption(item.year, item.month, item.operator_networks, language);
    });
  }
}
