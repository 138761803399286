import { useEffect, useState } from 'react';
import { ConstantColors } from 'utilities';
import { HelperStorage } from 'helpers';
import { Banner } from './banner/banner';
import { ModalConfigLater } from './modals/modal-configure-later';
import * as style from './page-onboarding.style';
import { StepAndroid } from './steps/step-android';
import { StepEnroll } from './steps/step-enroll';
import { StepIos } from './steps/step-ios';
import { useQuery } from '../../hooks/use-query';

export function Onboarding() {
  const [step, setStep] = useState('0');
  const [bannerColor, setBannerColor] = useState('');
  const [isModalConfigureVisible, setIsModalConfigureVisible] = useState(false);
  const [withBackButton, setWithBackButton] = useState(true);
  const query = useQuery();

  useEffect(() => {
    HelperStorage.save('tenantId', query.get('tenantId'));
    HelperStorage.save('adminId', query.get('adminId'));
  }, []);

  useEffect(() => {
    if (HelperStorage.fetch('android_configurate')) {
      setStep('ios1');
      setWithBackButton(false);
      HelperStorage.delete('android_configurate');
    } else if (query.get('has_enterprise_info') === 'true') {
      setStep('enroll');
    }
  }, []);

  useEffect(() => {
    switch (step) {
      case '0':
        setBannerColor(ConstantColors.COMOROS);
        break;
      case 'android1':
        setBannerColor(ConstantColors.DJBOUTI);
        break;
      case 'android2':
        setBannerColor(ConstantColors.DJBOUTI);
        break;
      case 'ios1':
        setBannerColor('#323232');
        break;
      case 'ios2':
        setBannerColor('#323232');
        break;
      case 'enroll':
        setBannerColor(ConstantColors.COMOROS);
        break;
      default:
        break;
    }
  }, [step]);

  const openModalConfigure = () => {
    setIsModalConfigureVisible(true);
  };

  const closeModalConfigure = () => {
    setIsModalConfigureVisible(false);
  };

  return (
    <>
      {isModalConfigureVisible && <ModalConfigLater setStep={setStep} closeModal={closeModalConfigure} />}

      <style.Container>
        <Banner color={bannerColor} step={step} handleClick={setStep} withBackButton={withBackButton} />
        {step === 'android2' && <StepAndroid openModalConfig={openModalConfigure} />}
        {step === 'ios2' && <StepIos setStep={setStep} />}
        {step === 'enroll' && <StepEnroll />}
      </style.Container>
    </>
  );
}
