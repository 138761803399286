import _ from 'lodash';
import type { ChromeOSStoreProps, ActionProps, PolicyBody } from './stores.contracts';

const INITIAL_STATE: ChromeOSStoreProps = {
  configuration: {},
};

function setPolicy(state: ChromeOSStoreProps, data: PolicyBody) {
  return { ...state, configuration: { hasChanges: false, policy: data, initial_policy: data } };
}

function changePolicy(state: ChromeOSStoreProps, data: PolicyBody) {
  const { configuration } = state;
  let hasChanges = true;
  if (Object.keys(_.pickBy(configuration.initial_policy, _.identity)).length <= 0 || _.isEqual(configuration.initial_policy, data)) {
    hasChanges = false;
  }
  // const a = _.fromPairs(_.differenceWith(_.toPairs(configuration.initial_policy), _.toPairs(data), _.isEqual));
  return { ...state, configuration: { ...configuration, hasChanges, policy: data } };
}

function savePolicy(state: ChromeOSStoreProps, data: PolicyBody) {
  const { configuration } = state;
  return { ...state, configuration: { ...configuration, hasChanges: false, initial_policy: data, policy: data } };
}

function restorePolicy(state: ChromeOSStoreProps) {
  const { configuration } = state;
  return { ...state, configuration: { ...configuration, hasChanges: false, policy: configuration.initial_policy } };
}

const chromeOSReducer = (state = INITIAL_STATE, action: ActionProps) => {
  switch (action.type) {
    case 'CHROMEOS_SET_CONFIGURATION_POLICY':
      return setPolicy(state, action.payload);
    case 'CHROMEOS_CHANGE_CONFIGURATION_POLICY':
      return changePolicy(state, action.payload);
    case 'CHROMEOS_SAVE_CONFIGURATION_POLICY':
      return savePolicy(state, action.payload);
    case 'CHROMEOS_RESTORE_CONFIGURATION_POLICY':
      return restorePolicy(state);
    default:
      return state;
  }
};

export default chromeOSReducer;
