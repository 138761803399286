import { EuiButtonEmpty, EuiTabs } from '@elastic/eui';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { StatusTagProps } from './page-device-details.contracts';

export const Container = styled.main`
  margin-left: 50px;
  position: relative;
  margin-bottom: 100px;

  a {
    &:hover {
      transition: 0.3s;
      transform: translateY(-2px);
    }
  }
`;

export const TopContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  max-width: 95%;
`;

export const DeviceInfoSection = styled.section`
  display: flex;

  div {
    min-width: 180px;
    min-height: 150px;
  }
`;

export const DeviceInfoId = styled.h4`
  font-weight: 700;
  font-size: 24px;
  line-height: 40px;
  color: #1a1c21;
`;

export const DeviceInfoText = styled.small`
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  span.label {
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    color: #1a1c21;
  }

  span.text {
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #343741;
  }

  span.euiCallOutHeader__title {
    font-size: 14px;
  }

  .euiTextColor.euiTextColor--default {
    p {
      font-weight: 400;
      font-size: 13px;
      line-height: 21px;
    }
  }
`;
export const GroupNameButton = styled(EuiButtonEmpty)`
  margin: 0 auto 0 0;

  .euiButtonContent.euiButtonEmpty__content {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    letter-spacing: -0.08px;
    color: #30a8ff;
  }
`;

export const UserInfoSection = styled.section`
  background: #f9f9f9;
  border-radius: 11px;
  width: 472px;
  padding: 15px 30px;
  height: 140px;
`;

export const UserInfoTitle = styled.h6`
  font-weight: 500;
  font-size: 12px;
  line-height: 40px;
  color: #1a1c21;
`;

export const UserInfoContent = styled.div`
  display: flex;
  padding: 10px 0;
`;

export const UserInfoAvatarNameEmail = styled.div`
  display: flex;
  gap: 10px;
  min-width: 180px;
  width: 50%;

  p {
    display: flex;
    flex-direction: column;

    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      color: #5a606b;
    }

    .email {
      font-weight: 300;
      font-size: 12px;
      line-height: 25px;
      color: #5a606b;
    }
  }
`;

export const TabsContainer = styled.div`
  width: 100%;
  margin-top: 30px;
  /* display: flex; */
  gap: 80px;
`;

export const TabsSelector = styled(EuiTabs)`
  button.euiTab {
    background-color: transparent !important;

    span.euiTab__content {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }

  button.euiTab.euiTab-isSelected {
    color: ${({ theme }) => theme.main} !important;
    box-shadow: inset 0 -2px 0 ${({ theme }) => theme.main};

    span.euiTab__content {
      color: ${({ theme }) => theme.main} !important;
    }
  }

  span.euiTab__content {
    color: ${ConstantColors.COLOMBIA} !important;
  }
`;

export const MenuIconsContainer = styled.aside`
  margin-top: 50px;
`;

export const StatusTag = styled.span<StatusTagProps>`
  border-radius: 3px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;

  display: flex;
  align-items: center;
  text-align: center;

  color: ${ConstantColors.AUSTRIA};
  padding: 1px 5px;
  background: #ff3b5e;
  ${({ isInstall }) => css`
    ${isInstall &&
    css`
      background: #2fb18c;
    `}
  `}
`;
