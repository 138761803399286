/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { EuiIconTip, EuiSwitch } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { SelectBox, CustomSlider, AdvancedFlag } from 'components';
import { mdiAndroid } from '@mdi/js';
import * as style from './embedded-security-settings-tab.style';
import { PopulateAndroidSettingsOptions } from './embedded-security-settings-options';
import type { EmbeddedSecuritySettingsTabProps, getInformationPhraseProps } from './embedded-security-settings-tab.contracts';
import { Tabs } from './embedded-security-settings-tab.contracts';
import { PasswordQuality, PasswordRequired } from './embedded-security-settings.contracts';

export const DeviceSecurityTab = ({ tab, visible, initialValues, onChange }: EmbeddedSecuritySettingsTabProps) => {
  const [active, setActive] = useState(false);
  const [passwordQualityActive, setPasswordQualityActive] = useState(false);
  const [forceEncryption, setForceEncryption] = useState(false);
  const [hidePasswordMinimumLength, setHidePasswordMinimumLength] = useState(false);
  const [blockDeviceActive, setBlockDeviceActive] = useState(false);
  const [passwordRequiredActive, setPasswordRequiredActive] = useState(false);
  const [passwordQuality, setPasswordQuality] = useState(PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED);
  const [passwordMinimumLength, setPasswordMinimumLength] = useState(4);
  const [passwordExpirationTimeout, setPasswordExpirationTimeout] = useState(0);
  const [passwordHistoryLength, setPasswordHistoryLength] = useState(0);
  const [maxInactivity, setMaxInactivity] = useState(0);
  const [maximumFailedPasswordsForWipe, setMaximumFailedPasswordsForWipe] = useState(0);
  const [passwordRequired, setPasswordRequired] = useState(PasswordRequired.USE_DEFAULT_DEVICE_TIMEOUT);

  const { t } = useTranslation();
  const options = PopulateAndroidSettingsOptions();

  const handleForceEncryption = () => {
    setForceEncryption(!forceEncryption);
  };

  const handleToggleActive = () => {
    setActive(!active);
  };

  const handleTogglePasswordQuality = () => {
    setPasswordQualityActive(!passwordQualityActive);
  };

  const handleToggleBlockDeviceActive = () => {
    setBlockDeviceActive(!blockDeviceActive);
  };

  const handleTogglePasswordRequiredActive = () => {
    setPasswordRequiredActive(!passwordRequiredActive);
  };

  const handlePasswordQualityChange = (value: PasswordQuality) => {
    setPasswordQuality(value);
  };

  const handlePasswordMinimumLengthChange = (value: number) => {
    if (value >= 4) {
      setPasswordMinimumLength(value);
    }
  };

  const handlePasswordExpirationTimeoutChange = (value: number) => {
    setPasswordExpirationTimeout(value);
  };

  const handlePasswordHistoryLengthChange = (value: number) => {
    setPasswordHistoryLength(value);
  };

  const handleMaxInactivityChange = (value: number) => {
    setMaxInactivity(value);
  };

  const handleMaximumFailedPasswordsForWipeChange = (value: number) => {
    setMaximumFailedPasswordsForWipe(value);
  };

  const handlePasswordRequiredChange = (value: string) => {
    setPasswordRequired(PasswordRequired[value.toUpperCase() as keyof typeof PasswordRequired]);
  };

  const getClassNameForActiveTab = () => {
    switch (tab) {
      case Tabs.DEVICE:
        return '';
      case Tabs.WORK_PROFILE:
        return 'workProfile';
      default:
        return '';
    }
  };

  const getPasswordQuality = () => {
    switch (tab) {
      case Tabs.DEVICE:
        return options.passwordQuality;
      case Tabs.WORK_PROFILE:
        return options.passwordQualityWorkProfile;
      default:
        return [];
    }
  };

  const getInformationPhrase = (): getInformationPhraseProps => {
    switch (tab) {
      case Tabs.DEVICE:
        return {
          prefix: t('pages.pulsus_android_settings.labels.password_max_failed_for_wipe_prefix'),
          sufix: t('pages.pulsus_android_settings.labels.password_max_failed_for_wipe_sufix'),
        };
      case Tabs.WORK_PROFILE:
        return {
          prefix: t('pages.pulsus_android_settings.labels.password_max_failed_for_wipe_prefix_work_profile'),
          sufix: t('pages.pulsus_android_settings.labels.password_max_failed_for_wipe_sufix_work_profile'),
        };
      default:
        return {};
    }
  };

  const getImportantPhrase = () => {
    switch (tab) {
      case Tabs.DEVICE:
        return t('pages.pulsus_android_settings.text.password_max_failed_for_wipe_description');
      case Tabs.WORK_PROFILE:
        return t('pages.pulsus_android_settings.text.password_max_failed_for_wipe_description_work_profile');
      default:
        return '';
    }
  };

  const getLabelForActiveTab = () => {
    switch (tab) {
      case Tabs.DEVICE:
        return t('pages.pulsus_android_settings.labels.password_device_policy_active');
      case Tabs.WORK_PROFILE:
        return t('pages.pulsus_android_settings.labels.password_profile_policy_active');
      default:
        return t('pages.pulsus_android_settings.labels.password_device_policy_active');
    }
  };

  const isPasswordQualityOptionsDisabled = () => {
    return (
      !passwordQualityActive || passwordQuality === PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED || passwordQuality === PasswordQuality.SOMETHING
    );
  };

  useEffect(() => {
    if (
      passwordQuality === PasswordQuality.COMPLEXITY_LOW ||
      passwordQuality === PasswordQuality.COMPLEXITY_MEDIUM ||
      passwordQuality === PasswordQuality.COMPLEXITY_HIGH
    ) {
      setHidePasswordMinimumLength(true);
    } else {
      setHidePasswordMinimumLength(false);
    }
  }, [passwordQuality]);

  useEffect(() => {
    onChange({
      active,
      passwordQualityActive,
      blockDeviceActive,
      passwordRequiredActive,
      passwordQuality,
      passwordMinimumLength,
      passwordExpirationTimeout,
      passwordHistoryLength,
      maxInactivity,
      maximumFailedPasswordsForWipe,
      passwordRequired,
      forceEncryption,
    });
  }, [
    active,
    passwordQualityActive,
    blockDeviceActive,
    passwordRequiredActive,
    passwordQuality,
    passwordMinimumLength,
    passwordExpirationTimeout,
    passwordHistoryLength,
    maxInactivity,
    maximumFailedPasswordsForWipe,
    passwordRequired,
    forceEncryption,
  ]);

  useEffect(() => {
    setActive(initialValues.active);
    setPasswordQualityActive(initialValues.passwordQualityActive);
    setBlockDeviceActive(initialValues.blockDeviceActive);
    setPasswordRequiredActive(initialValues.passwordRequiredActive);
    setPasswordQuality(initialValues.passwordQuality);
    setPasswordMinimumLength(initialValues.passwordMinimumLength);
    setPasswordExpirationTimeout(initialValues.passwordExpirationTimeout);
    setPasswordHistoryLength(initialValues.passwordHistoryLength);
    setMaxInactivity(initialValues.maxInactivity);
    setMaximumFailedPasswordsForWipe(initialValues.maximumFailedPasswordsForWipe);
    setPasswordRequired(initialValues.passwordRequired);
    setForceEncryption(initialValues.forceEncryption);
  }, [initialValues]);

  const renderTooltip = () => {
    return (
      <style.Tooltip>
        <p>{t('pages.pulsus_android_settings.text.password_tooltip_header')}</p>
        <p>
          <b>{t('pages.pulsus_android_settings.labels.pattern_or_simple_password')}:</b>{' '}
          {t('pages.pulsus_android_settings.text.password_tooltip_something')}
        </p>
        <p>
          <b>{t('pages.pulsus_android_settings.labels.numeric')}:</b> {t('pages.pulsus_android_settings.text.password_tooltip_numeric')}
        </p>
        <p>
          <b>{t('pages.pulsus_android_settings.labels.alphabetic')}:</b> {t('pages.pulsus_android_settings.text.password_tooltip_alphabetic')}
        </p>
        <p>
          <b>{t('pages.pulsus_android_settings.labels.biometric_weak')}:</b> {t('pages.pulsus_android_settings.text.password_tooltip_biometric_weak')}
        </p>
        <p>
          <b>{t('pages.pulsus_android_settings.labels.alphanumeric')}:</b> {t('pages.pulsus_android_settings.text.password_tooltip_alphanumeric')}
        </p>
      </style.Tooltip>
    );
  };

  return visible ? (
    <>
      <style.CardItem style={{ paddingTop: 0 }}>
        <style.SecurityToggle>
          <EuiSwitch
            data-testid="embedded-security-active"
            className={getClassNameForActiveTab()}
            label={getLabelForActiveTab()}
            checked={active}
            onChange={handleToggleActive}
          />
          <EuiIconTip type="iInCircle" content={renderTooltip()} position="right" />
        </style.SecurityToggle>
        <style.WifiDescription data-testid="embedded-security-main-description">
          {t('pages.pulsus_android_settings.text.password_main_description')}
        </style.WifiDescription>
        {tab === Tabs.DEVICE ? (
          <AdvancedFlag
            flags={[
              { managementType: 'DO', icon: mdiAndroid, version: '5.0', OS: 'Android' },
              { managementType: 'FM + WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
              { managementType: 'WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
            ]}
          />
        ) : (
          <AdvancedFlag
            flags={[
              { managementType: 'FM + WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
              { managementType: 'WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
            ]}
          />
        )}
      </style.CardItem>
      <style.CardItem data-testid="embedded-security-card-content" hidden={!active}>
        <style.SecurityToggle>
          <EuiSwitch
            data-testid="embedded-security-password-quality-ative"
            className={getClassNameForActiveTab()}
            label={t('pages.pulsus_android_settings.labels.password_quality_switch')}
            checked={passwordQualityActive}
            onChange={handleTogglePasswordQuality}
          />
        </style.SecurityToggle>
        <style.ElementsGroup>
          <style.Element style={{ width: 325 }}>
            <SelectBox
              id="embedded-security-password-type"
              options={getPasswordQuality()}
              value={passwordQuality}
              onChange={handlePasswordQualityChange}
              label=""
              disabled={!passwordQualityActive}
            />
          </style.Element>
          <style.Element style={{ width: 200 }} hidden={hidePasswordMinimumLength}>
            <CustomSlider
              id="embedded-security-password-min-size"
              min={4}
              max={16}
              disabled={isPasswordQualityOptionsDisabled()}
              value={passwordMinimumLength}
              onChange={handlePasswordMinimumLengthChange}
            >
              <style.ElementContentText>
                {t('pages.pulsus_android_settings.labels.password_min_length_prefix')}
                <style.ContentTextCustomColor className={getClassNameForActiveTab()}>{` ${passwordMinimumLength} `}</style.ContentTextCustomColor>
                {t('pages.pulsus_android_settings.labels.password_min_length_sufix')}
              </style.ElementContentText>
            </CustomSlider>
          </style.Element>
          <style.Element style={{ width: 250 }}>
            <CustomSlider
              id="embedded-security-password-change-period"
              min={0}
              max={365}
              step={1}
              disabled={isPasswordQualityOptionsDisabled()}
              value={passwordExpirationTimeout}
              onChange={handlePasswordExpirationTimeoutChange}
            >
              <style.ElementContentText>
                {t('pages.pulsus_android_settings.labels.password_change_period_prefix')}
                <style.ContentTextCustomColor className={getClassNameForActiveTab()}>{` ${passwordExpirationTimeout} `}</style.ContentTextCustomColor>
                {t('pages.pulsus_android_settings.labels.password_change_period_sufix')}
              </style.ElementContentText>
            </CustomSlider>
          </style.Element>
          <style.Element style={{ width: 355 }}>
            <CustomSlider
              id="embedded-security-password-new-registration"
              min={0}
              max={50}
              disabled={isPasswordQualityOptionsDisabled()}
              value={passwordHistoryLength}
              onChange={handlePasswordHistoryLengthChange}
            >
              <style.ElementContentText>
                {t('pages.pulsus_android_settings.labels.password_history_length_prefix')}
                <style.ContentTextCustomColor className={getClassNameForActiveTab()}>{` ${passwordHistoryLength} `}</style.ContentTextCustomColor>
                {t('pages.pulsus_android_settings.labels.password_history_length_sufix')}
              </style.ElementContentText>
            </CustomSlider>
          </style.Element>
        </style.ElementsGroup>
      </style.CardItem>
      <style.CardItem hidden={!active}>
        <style.ElementsGroup customGap="0 73px">
          <style.Element hidden={tab === Tabs.WORK_PROFILE} style={{ width: 275 }}>
            <style.SecurityToggle>
              <EuiSwitch
                data-testid="embedded-security-block-device"
                className={getClassNameForActiveTab()}
                label={t('pages.pulsus_android_settings.labels.device_block')}
                checked={blockDeviceActive}
                onChange={handleToggleBlockDeviceActive}
              />
            </style.SecurityToggle>
            <div style={{ marginTop: 15 }}>
              <CustomSlider
                id="embedded-security-inactivity-time"
                min={0}
                max={5}
                disabled={!blockDeviceActive}
                value={maxInactivity}
                onChange={handleMaxInactivityChange}
              >
                <style.ElementContentText>
                  {t('pages.pulsus_android_settings.labels.device_max_inactivity_time_prefix')}
                  <style.ContentTextCustomColor className={getClassNameForActiveTab()}>{` ${maxInactivity} `}</style.ContentTextCustomColor>
                  {t('pages.pulsus_android_settings.labels.device_max_inactivity_time_sufix')}
                </style.ElementContentText>
              </CustomSlider>
            </div>
          </style.Element>
          <style.Element style={{ width: 620 }}>
            <div style={{ marginTop: 15 }}>
              <CustomSlider
                id="embedded-security-erase-data-after"
                className={getClassNameForActiveTab()}
                min={0}
                max={10}
                ticks={options.eraseDataOptions}
                fullWidth
                value={maximumFailedPasswordsForWipe}
                onChange={handleMaximumFailedPasswordsForWipeChange}
              >
                <style.ElementContentText>
                  {getInformationPhrase().prefix}
                  <style.ContentTextCustomColor className={getClassNameForActiveTab()}>
                    {` ${maximumFailedPasswordsForWipe} `}
                  </style.ContentTextCustomColor>
                  {getInformationPhrase().sufix}
                </style.ElementContentText>
              </CustomSlider>
              <style.WifiWarningAlert>
                <style.WarningTitle>
                  <style.WarningIcon type="help" />
                  <style.WarningMessage>{getImportantPhrase()}</style.WarningMessage>
                </style.WarningTitle>
              </style.WifiWarningAlert>
            </div>
          </style.Element>
        </style.ElementsGroup>
      </style.CardItem>
      <style.CardItem hidden={!active}>
        {tab === Tabs.DEVICE && (
          <style.ElementsGroup customGap="0 23px">
            <style.Element>
              <style.SecurityToggle>
                <EuiSwitch
                  label={t('pages.pulsus_android_settings.labels.force_encryption_text')}
                  checked={forceEncryption}
                  onChange={handleForceEncryption}
                />
              </style.SecurityToggle>
            </style.Element>
          </style.ElementsGroup>
        )}
        <style.Element>
          <style.SecurityToggle>
            <EuiSwitch
              data-testid="embedded-security-request-strong-password"
              className={getClassNameForActiveTab()}
              label={t('pages.pulsus_android_settings.labels.request_strong_password')}
              checked={passwordRequiredActive}
              onChange={handleTogglePasswordRequiredActive}
            />
          </style.SecurityToggle>
          {tab === Tabs.DEVICE ? (
            <AdvancedFlag
              flags={[
                { managementType: 'DO', icon: mdiAndroid, version: '5.0', OS: 'Android' },
                { managementType: 'FM + WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
                { managementType: 'WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
              ]}
            />
          ) : (
            <AdvancedFlag
              flags={[
                { managementType: 'FM + WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
                { managementType: 'WP', icon: mdiAndroid, version: '5.0', OS: 'Android' },
              ]}
            />
          )}
          <style.GroupButtonBox>
            <style.GroupButton
              data-testid="embedded-security-request-password-time"
              legend=""
              className={getClassNameForActiveTab()}
              isDisabled={!passwordRequiredActive}
              options={tab === Tabs.WORK_PROFILE ? options.passwordRequiredWorkProfile : options.passwordRequired}
              idSelected={passwordRequired}
              onChange={handlePasswordRequiredChange}
            />
          </style.GroupButtonBox>
          {passwordRequiredActive && tab === Tabs.DEVICE && passwordRequired === PasswordRequired.USE_DEFAULT_DEVICE_TIMEOUT && (
            <style.WifiWarningAlert style={{ maxWidth: '700px' }}>
              <style.WarningTitle>
                <style.WarningIcon type="help" />
                <style.WarningMessage>{t('pages.pulsus_android_settings.text.password_default_require_strong_password')}</style.WarningMessage>
              </style.WarningTitle>
            </style.WifiWarningAlert>
          )}
        </style.Element>
      </style.CardItem>
    </>
  ) : null;
};
