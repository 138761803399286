import { ModalEnrollSelector } from 'pages';
import { IframeGoogleZeroTouch } from 'pages/modal-ae-enroll/iframe-google-zero-touch/iframe-google-zero-touch';
import { useState } from 'react';
import type { EnrollModalManagerProps, EnrollType } from './embedded-enroll-type-selector.contracts';

export function EnrollModalManager(props: EnrollModalManagerProps) {
  const { groupSelected } = props;
  const [enrollType, setEnrollType] = useState<EnrollType>({
    type: '',
    parent: 'LC01w75bj5',
    policy_name: groupSelected?.id || '',
    schema: 'tenant_felipe_762',
  });
  const [url] = useState('');
  return (
    <div>
      {enrollType && !enrollType.type && (
        <div style={{ height: 500, overflow: 'hidden' }}>
          <ModalEnrollSelector
            managedMode
            onSelect={(type: string) => {
              setEnrollType({ ...enrollType, type });
            }}
          />
        </div>
      )}

      {enrollType && enrollType.type && <IframeGoogleZeroTouch url={url} />}
    </div>
  );
}
