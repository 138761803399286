import { EuiButtonEmpty, EuiIcon } from '@elastic/eui';
import { useTheme } from 'styled-components';
import type { ButtonProps } from '../component-application-header.contracts';

export function ButtonAdmin({ onClick }: ButtonProps) {
  const theme = useTheme();

  return (
    <EuiButtonEmpty data-testid="button-admin" onClick={onClick}>
      <EuiIcon data-testid="button-admin-icon" type="boxesVertical" color={theme.contrast} />
    </EuiButtonEmpty>
  );
}
