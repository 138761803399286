export class DataUsageTableGroups {
  public totalItems: number;
  public groups: GroupsList[];

  constructor(data: DataUsageTableGroups.Server) {
    this.totalItems = data.total_items;
    this.groups = data.page_items.map((item) => ({
      name: item.group_name,
      id: String(item.group_id),
      totalValue: item.total_average,
      wifiValue: item.wifi_average,
      networkValue: item.mobile_average,
      wifiPercentage: item.wifi_percentage,
      networkPercentage: item.mobile_percentage,
    }));
  }
}

export namespace DataUsageTableGroups {
  export type Server = {
    total_items: number;
    page_items: {
      group_name: string;
      group_id: number;
      total_average: number;
      mobile_average: number;
      mobile_percentage: number;
      wifi_average: number;
      wifi_percentage: number;
    }[];
  };
}

export type GroupsList = {
  name: string;
  id: string;
  totalValue: number;
  wifiValue: number;
  networkValue: number;
  wifiPercentage: number;
  networkPercentage: number;
};
