import styled, { css } from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { ConstantColors } from 'utilities';
import type { SettingsStatusSectionProps } from 'pages/settings/connectivity/page-connectivity.contracts';
import { EuiCard } from '@elastic/eui';

export const Card = styled(EuiCard)`
  display: flex;
  border-radius: 9px;
  padding: 25px !important;
  border: none !important;
  width: 100%;
  background: ${ConstantColors.AUSTRIA};

  .euiTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
`;

export const Container = styled.main`
  display: flex;
  gap: 30px;
  width: 100%;
  background: ${ConstantColors.BRAZIL};
  flex-wrap: wrap;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  min-width: 483px;
`;

export const ContainerBigBox = styled.div`
  display: flex;
  min-height: 400px;
  border-radius: 9px;
  background: ${ConstantColors.AUSTRIA};
  padding: 0 30px;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  max-width: 483px;
`;
export const SettingsStatusSection = styled.section<SettingsStatusSectionProps>`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .icon_status_container {
    display: flex;

    .left_section_status {
      width: 50px;
    }

    .left_section_status button {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;
      color: ${ConstantColors.ITALY};
      width: 50px;
    }

    .right_section_status {
      width: 50%;
      margin-left: 20px;
    }

    .right_section_status h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 40px;
      color: ${ConstantColors.GERMANY};
    }

    .right_section_status p {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: ${ConstantColors.ITALY};
    }
  }

  .options_container {
    justify-content: start;
    gap: 3px;
    display: flex;

    button {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      align-items: center;
      text-align: center;
      width: 56px;
      height: 18px;
      background: ${ConstantColors.ENGLAND};
      color: ${ConstantColors.ITALY};
      border-radius: 14px;
    }

    .turn_on_button {
      ${({ is_on }) => css`
        ${is_on &&
        css`
          background: ${ConstantColors.CHILE};
          color: ${ConstantColors.AUSTRIA};
        `}
      `}
    }

    .turn_off_button {
      ${({ is_off }) => css`
        ${is_off &&
        css`
          background: ${ConstantColors.CHILE};
          color: ${ConstantColors.AUSTRIA};
        `}
      `}
    }

    .neutral_button {
      ${({ is_neutral }) => css`
        ${is_neutral &&
        css`
          background: ${ConstantColors.CHILE};
          color: ${ConstantColors.AUSTRIA};
        `}
      `}
    }
  }

  .options_container.airplane {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.airplane {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.bluetooth {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.bluetooth {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.mobile_data {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.mobile_data {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.tethering {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.tethering {
      transition: 0.3s;
      opacity: 0.95;
    }
  }

  .options_container.wifi {
    opacity: 0.03;
    display: flex;
  }

  :hover {
    .options_container.wifi {
      transition: 0.3s;
      opacity: 0.95;
    }
  }
`;
