import { EuiPagination } from '@elastic/eui';
import styled from 'styled-components';

export const Content = styled.main`
  position: relative;
  .euiTableHeaderCell {
    .euiTableCellContent__text {
      font-size: 11px;
      color: #67758d;
      font-weight: 500;
    }
  }

  .euiTableCellContent__text {
    font-weight: 700;
    font-size: 12px;
    width: 100%;
  }

  .euiLoadingContent__singleLine {
    height: 56px;
    margin-bottom: 10px;
    &:last-child {
      width: 100%;
    }
  }

  .link {
    span {
      font-size: 12px;
    }
  }
`;

export const NameColumn = styled.div`
  display: flex;
  align-items: center;

  .edit-icon-button {
    opacity: 0.3;
    padding: 5px;

    svg {
      width: 17px !important;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

export const StatusColumn = styled.div`
  display: flex;
  align-items: center;

  .euiHealth {
    margin-top: 2px;
  }
`;

export const Status = styled.span<{ isProcessing: boolean }>`
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  background-color: ${({ isProcessing }) => (isProcessing ? '#FFA100' : '#3DDB85')};

  div {
    font-weight: 500 !important;
  }
`;

export const PaginationContainer = styled.section`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;

  span {
    font-size: 12px;
  }
`;

export const Pagination = styled(EuiPagination)`
  background-color: ${({ theme }) => theme.palette};
`;
