import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 40px 55px;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;
export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
`;
export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343741;

  .euiTextColor--secondary {
    color: #343741;
    font-weight: 700;
  }
`;

export const Body = styled.div`
  .euiTable,
  .euiTableRow:hover {
    background: #f9f9f9;
  }
`;

export const StatusFlag = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  background: #3ddb85;
  border-radius: 3px;
  padding: 1px 8px;
`;
