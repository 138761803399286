import type { ReactNode } from 'react';

export type SecurityToggleProps = {
  active: boolean;
  onClick: () => void;
};

export type PermissionToggleProps = {
  onClick: () => void;
};

export type Flag = {
  id: number;
  label: string;
};

export type QuickSettingsCardProps = {
  title: string;
  label: string;
  active: boolean;
  onChange: () => void;
  flags?: Flag[];
  children?: string | ReactNode;
  noSwitch?: boolean;
  isContentOnly?: boolean;
  bottomContent?: ReactNode;
  noBottomPadding?: boolean;
};

export type ElementGroupProps = {
  customGap?: string;
};

export type ElementProps = {
  width?: string;
  minWidth?: string;
  hidden?: boolean;
};

export type CardItemProps = {
  hidden?: boolean;
};

export type PasswordPolicyContentProps = {
  passwordQuality: boolean;
  passwordType: string | number | null;
  minSize: number;
  changePeriod: number;
  passwordNewRegistration: number;
  blockDevice: boolean;
  inactivityTime: number;
  eraseDataAfter: number;
  strongPassword: boolean;
  requestPasswordTime: string;
};

export type PasswordRequirementProps = {
  active: boolean;
  passwordQualityActive: boolean;
  blockDeviceActive: boolean;
  passwordRequiredActive: boolean;
  passwordQuality: PasswordQuality;
  passwordMinimumLength: number;
  passwordExpirationTimeout: number;
  passwordHistoryLength: number;
  maxInactivity: number;
  maximumFailedPasswordsForWipe: number;
  passwordRequired: PasswordRequired;
  forceEncryption: boolean;
};

export type PasswordPoliciesProps = {
  device: PasswordRequirementProps;
  workProfile: PasswordRequirementProps;
};

export enum Tabs {
  WORK_PROFILE = 'WORK_PROFILE',
  DEVICE = 'DEVICE',
}

export enum Events {
  ON_READY = 'ON_READY',
  ON_CHANGE = 'ON_CHANGE',
}

export enum Origins {
  NJORD = 'njord',
  RAILS = 'rails',
}

export enum PasswordQuality {
  PASSWORD_QUALITY_UNSPECIFIED = 'password_quality_unspecified',
  SOMETHING = 'something',
  COMPLEXITY_LOW = 'complexity_low',
  COMPLEXITY_MEDIUM = 'complexity_medium',
  COMPLEXITY_HIGH = 'complexity_high',
  NUMERIC = 'numeric',
  ALPHABETIC = 'alphabetic',
  ALPHANUMERIC = 'alphanumeric',
  BIOMETRIC_WEAK = 'biometric_weak',
  NUMERIC_COMPLEX = 'numeric_complex',
}

export enum PasswordRequired {
  REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED = 'require_password_unlock_unspecified',
  USE_DEFAULT_DEVICE_TIMEOUT = 'use_default_device_timeout',
  REQUIRE_EVERY_DAY = 'require_every_day',
}

export const defaultValues: PasswordRequirementProps = {
  active: false,
  passwordQualityActive: false,
  blockDeviceActive: false,
  passwordRequiredActive: false,
  passwordQuality: PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED,
  passwordMinimumLength: 4,
  passwordExpirationTimeout: 0,
  passwordHistoryLength: 0,
  maxInactivity: 0,
  maximumFailedPasswordsForWipe: 0,
  passwordRequired: PasswordRequired.REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED,
  forceEncryption: false,
};
