import { EuiButtonEmpty } from '@elastic/eui';
import styled, { css } from 'styled-components';
import type { ColorTypes } from './ComponentEmptyButton.contracts';

export const Container = styled.div``;

export const CustomEmptyButton = styled(EuiButtonEmpty)<{ colorType?: ColorTypes }>`
  font-size: 16px;
  font-weight: 500;
  line-height: 26px;
  ${({ theme, colorType }) => {
    if (colorType) {
      return css`
        color: ${colorType === 'primary' ? '#30A8FF' : '#8F8F8F'};
      `;
    }
    return css`
      color: ${theme.main};
    `;
  }};
`;
