import { LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const Main = styled.main`
  height: 100%;
  width: 100%;

  .gm-style-iw-d {
    overflow: auto !important;
  }
`;

export const Map = styled.section`
  height: 100%;
  width: 100%;
`;

export const StyledLinearProgress = styled(LinearProgress)`
  height: 4px;
`;
