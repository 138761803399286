import { ColoredChromeOSIcon } from 'icons';
import type { APIDeviceManagement, APIDeviceStatus, APIDevicesList, DevicesList } from 'modules/operational/entities/devices/devicesList.entity';
import { APIManagementCode } from 'modules/operational/entities/devices/devicesList.entity';
import { t } from 'i18next';
import { IconWarning } from 'icons/warning/icon-warning';
import type { Management, DevicesCountPerManagement, DevicesCountPerStatus, ManagementAttributes, DevicesStatus } from './PageDevices.contracts';
import { StatusCode } from './PageDevices.contracts';
import { DeviceIcon, AndroidIconContainer, PngIcon } from './PageDevices.style';
import androidIcon from 'assets/images/android_new.png';
import iOSIcon from 'assets/images/ios_new.png';
import macOSIcon from 'assets/images/mac_os.png';
import newDeviceIcon from 'assets/images/device_new.png';
import windowsIcon from 'assets/images/windows.png';

export class HelperDevice {
  static findIcon(item: APIDevicesList) {
    let icon: React.ReactNode;

    switch (item.platform?.toLowerCase()) {
      case 'android':
        icon = (
          <AndroidIconContainer>
            <img src={androidIcon} alt="Android" title="Android" />
          </AndroidIconContainer>
        );
        break;
      case 'ios':
        icon = <PngIcon src={iOSIcon} alt="iOS" title="iOS" />;
        break;
      case 'chromeos':
        icon = <ColoredChromeOSIcon />;
        break;
      case 'macos':
        icon = <PngIcon src={macOSIcon} alt="MacOS" title="MacOS" />;
        break;
      case 'windows':
        icon = <PngIcon src={windowsIcon} alt="windows" title="windows" />;
        break;
      default:
        icon = <PngIcon src={newDeviceIcon} alt="New Device" title="New Device" />;
        break;
    }

    if (item.status === 0) {
      icon = <PngIcon src={newDeviceIcon} alt="New Device" title="New Device" />;
    }

    if (item.management_type === 'DA') {
      icon = (
        <DeviceIcon>
          <IconWarning />
          <AndroidIconContainer>
            <img src={androidIcon} alt="Android" title="Android" />
          </AndroidIconContainer>
        </DeviceIcon>
      );
    }

    return icon;
  }

  static findManagementCount(apiManagementCounter: APIDeviceManagement[]) {
    const devicesPerManagement: DevicesCountPerManagement = {
      FM: 0,
      FM_WP: 0,
      WP: 0,
      DA: 0,
      SU: 0,
      BA: 0,
      NU: 0,
      MSU: 0,
      WS: 0,
    };

    apiManagementCounter.forEach((item) => {
      const { management, count } = item;
      if (management in APIManagementCode) {
        devicesPerManagement[APIManagementCode[management]] = count;
      }
    });

    return devicesPerManagement;
  }

  static findStatusCounter(apiStatusCounter: APIDeviceStatus[]) {
    const devicesPerStatus: DevicesCountPerStatus = {
      created: 0,
      active: 0,
      blocked: 0,
      removed: 0,
      wiped: 0,
      unenrolled: 0,
      lost: 0,
      tempBlock: 0,
      safetynetBlocked: 0,
      safetynetWiped: 0,
    };

    apiStatusCounter.forEach((item) => {
      const { status, count } = item;
      if (status in StatusCode) {
        devicesPerStatus[StatusCode[status]] = count;
      }
    });

    return devicesPerStatus;
  }

  static findStatusAttributes(status: DevicesStatus | number) {
    const statusDevice = {
      0: 'created',
      1: 'active',
      2: 'blocked',
      3: 'removed',
      4: 'wiped',
      5: 'unenrolled',
      6: 'lost',
      7: 'tempBlock',
      8: 'safetynetBlocked',
      9: 'safetynetWiped',
    };

    const statusString = typeof status === 'number' ? statusDevice[status] : status;

    const statusAttributes = {
      created: {
        color: '#30A8FF',
        text: t('devices.status.new'),
      },
      active: {
        color: '#3DDB85',
        text: t('devices.status.active'),
      },
      blocked: {
        color: '#E8505B',
        text: t('devices.status.blocked'),
      },
      removed: {
        color: '#455A64',
        text: t('devices.status.removed'),
      },
      wiped: {
        color: '#FF0012',
        text: t('devices.status.erased'),
      },
      unenrolled: {
        color: '#FFB93B',
        text: t('devices.status.uninstalled'),
      },
      lost: {
        color: '#681785',
        text: t('devices.status.lost'),
      },
      tempBlock: {
        color: '#D43EC5',
        text: t('devices.status.quarantine'),
      },
      safetynetBlocked: {
        color: '#DE5B25',
        text: t('devices.status.block_playIntegrity'),
      },
      safetynetWiped: {
        color: '#939393',
        text: t('devices.status.wiped_playIntegrity'),
      },
    };

    return statusAttributes[statusString];
  }

  static findManagementAttributes(management: Management): ManagementAttributes {
    const defaultStatusFilter = {
      and: [{ status: { ne: 2 } }, { status: { ne: 3 } }, { status: { ne: 8 } }, { status: { ne: 9 } }],
    };

    const managementAttributes = {
      FM: {
        color: '#30A8FF',
        text: 'Fully Managed',
        initials: 'FM',
        filter: {
          and: [{ platform: 'Android' }, { device_owner: true }, defaultStatusFilter],
        },
      },
      FM_WP: {
        color: '#0077CC',
        text: 'FM + Work Profile',
        initials: 'FWP',
        filter: {
          and: [{ platform: 'Android' }, { and: [{ management_mode: 'PROFILE_OWNER' }, { ownership: 'COMPANY_OWNED' }] }, defaultStatusFilter],
        },
      },
      WP: {
        color: '#62CE9B',
        text: 'Work Profile',
        initials: 'WP',
        filter: {
          and: [{ platform: 'Android' }, { and: [{ management_mode: 'PROFILE_OWNER' }, { ownership: 'PERSONALLY_OWNED' }] }, defaultStatusFilter],
        },
      },
      DA: {
        color: '#FF3B5E',
        text: 'D. Admin',
        initials: 'DA',
        filter: { and: [{ platform: 'Android' }, { device_owner: false }, defaultStatusFilter] },
      },
      SU: {
        color: '#4935C1',
        text: t('devices.management_filter.supervised'),
        initials: 'SU',
        filter: {
          and: [{ platform: 'iOS' }, { ios_info: { supervised: true } }, defaultStatusFilter],
        },
      },
      MSU: {
        color: '#4935C1',
        text: t('devices.management_filter.supervised'),
        initials: 'SU',
        filter: {
          and: [{ 'tolower(platform)': 'macos' }, { ios_info: { supervised: true } }, defaultStatusFilter],
        },
      },
      BA: {
        color: '#EB996E',
        text: t('devices.management_filter.basic'),
        initials: 'BA',
        filter: { and: [{ platform: 'iOS' }, { ios_info: { supervised: false } }, defaultStatusFilter] },
      },
      NU: {
        color: '#17A05D',
        text: t('devices.management_filter.cloud'),
        initials: 'NU',
        filter: { and: [{ platform: 'ChromeOS' }, defaultStatusFilter] },
      },
      WS: {
        color: '#2C93E5',
        text: 'Windows',
        initials: 'WS',
        filter: { and: [{ platform: 'Windows' }, defaultStatusFilter] },
      },
    };

    return managementAttributes[management];
  }

  static findBatteryTempColor(battery: DevicesList['battery']) {
    const threshold1 = battery.isIOS ? 36 : 40;
    const threshold2 = battery.isIOS ? 41 : 45;

    if (battery.temperature < threshold1) {
      return '#3DDB85';
    }

    if (battery.temperature < threshold2) {
      return '#FFB93B';
    }

    return '#FF3B5E';
  }
}
