import { EuiButton, EuiCheckbox, EuiDatePicker, EuiDatePickerRange, EuiFieldText, EuiSelect, EuiToolTip, htmlIdGenerator } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { IconAndroid, IconChromeOS, IconIos } from 'icons';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { useTypedTranslation } from 'modules/core/hooks';
import { StatusCode } from 'modules/operational/pages/devices/PageDevices.contracts';
import type { ReportData } from 'modules/operational/entities/devices/reportData.entity';
import buildQuery from 'odata-query';
import * as S from './AdvancedSearchComponent.styles';
import { DataAdvancedSearch } from './data';
import { SelectGroupModalComponent } from './SelectGroupModal/SelectGroupModalComponent';
import type {
  AdvancedSearchProps,
  FilterSwitches,
  ManagementChecks,
  SelectProps,
  StatusChecks,
  StorageUnit,
  VersionsList,
} from './AdvancedSearchComponent.contracts';
import { Mixpanel } from 'utilities';
import { useUserContext } from 'modules/core/contexts/user';
import type { MixPanelReport } from 'modules/operational/entities/devices/mixpanelReport.entity';
import { IconWindows } from 'icons/windows/icon-windows';
import { FeatureFlagCheckerComponent } from 'modules/core/components/FeatureFlagChecker/FeatureFlagCheckerComponent';

export function AdvancedSearchComponent({ sendReportData, filterDevices, customFields, isOpen }: AdvancedSearchProps) {
  const { t } = useTypedTranslation<'devices'>('devices');

  const [filterSwitches, setFilterSwitches] = useState<FilterSwitches>({} as FilterSwitches);
  const [isAndroid, setIsAndroid] = useState(false);
  const [isIos, setIsIos] = useState(false);
  const [isWS, setIsWS] = useState(false);
  const [isChrome, setIsChrome] = useState(false);
  const [identifierValue, setIdentifierValue] = useState('');
  const [startDateEnrollment, setStartDateEnrollment] = useState<moment.Moment | null>(moment().endOf('day').subtract(15, 'd'));
  const [endDateEnrollment, setEndDateEnrollment] = useState<moment.Moment | null>(moment().endOf('day'));
  const [startDateLastSync, setStartDateLastSync] = useState<moment.Moment | null>(moment().endOf('day').subtract(15, 'd'));
  const [endDateLastSync, setEndDateLastSync] = useState<moment.Moment | null>(moment().endOf('day'));
  const [startDateActivated, setStartDateActivated] = useState<moment.Moment | null>(moment().subtract(15, 'd'));
  const [endDateActivated, setEndDateActivated] = useState<moment.Moment | null>(moment());
  const [statusChecks, setStatusChecks] = useState<StatusChecks>({} as StatusChecks);
  const [androidManagementChecks, setAndroidManagementChecks] = useState<ManagementChecks['android']>({
    DA: false,
    FM: false,
    FMWP: false,
    WP: false,
  });
  const [IosManagementChecks, setIosManagementChecks] = useState<ManagementChecks['ios']>({ basic: false, supervised: false, macSupervised: false });
  const [chromeManagementChecks, setChromeManagementChecks] = useState<ManagementChecks['chrome']>({ chromeOS: false, flex: false });
  const [chipCheck, setChipCheck] = useState({ hasChip: false, doesntHaveChip: false });
  const [storageValue, setStorageValue] = useState('');
  const [storageUnit, setStorageUnit] = useState<StorageUnit>({ GB: false, MB: false });
  const [batteryValue, setBatteryValue] = useState<[number, number]>([20, 40]);
  const [hasDateErrorEnrollment, setHasDateErrorEnrollment] = useState(false);
  const [hasDateErrorLastSync, setHasDateErrorLastSync] = useState(false);
  const [hasDateErrorActivated, setHasDateErrorActivated] = useState(false);
  const options = DataAdvancedSearch();
  const [androidVersionOption, setAndroidVersionOption] = useState(t('advanced_search.so_version'));
  const [iOSVersionOption, setIosVersionOption] = useState(t('advanced_search.so_version'));
  const [chromeVersionOption, setChromeVersionOption] = useState(t('advanced_search.so_version'));
  const [versionsList, setVersionsList] = useState<VersionsList>({} as VersionsList);
  const [identifierOptions, setIdentifierOptions] = useState<SelectProps[]>([
    { value: '', text: t('advanced_search.select_type') },
    { value: 'iccid', text: 'ICCID' },
    { value: 'mac_address', text: 'MAC Address' },
    { value: 'imei', text: 'IMEI' },
    { value: 'serial_number', text: t('advanced_search.select_serial_number') },
  ]);
  const [identifierOption, setIdentifierOption] = useState(identifierOptions[0].value);
  const [dateOption, setDateOption] = useState(options.dateOptions[0].value);
  const [orderOptions, setOrderOptions] = useState(options.orderOptions[0].value);
  const [hasIOS, setHasIOS] = useState(false);
  const [hasWS, setHasWS] = useState(false);
  const [hasAndroid, setHasAndroid] = useState(false);
  const [hasChrome, setHasChrome] = useState(false);
  const [hasBeenOpened, setHasBeenOpened] = useState(false);
  const [selectedGroups, setSelectedGroups] = useState<Array<{ id: number; name: string }>>([]);
  const [openGroupModal, setOpenGroupModal] = useState(false);

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const { administrator, tenant } = useUserContext();

  const {
    checkedGroup,
    checkedDevicesId,
    checkedDate,
    checkedStatus,
    checkedOrder,
    checkedLastSync,
    checkedActivated,
    checkedOS,
    checkedChip,
    checkedBattery,
    checkedStorage,
  } = filterSwitches;

  const sortVersionsArray = (array: string[]) => {
    return array
      .map((a) =>
        a
          .split('.')
          .map((n) => +n + 100000)
          .join('.')
      )
      .sort()
      .map((a) =>
        a
          .split('.')
          .map((n) => +n - 100000)
          .join('.')
      );
  };

  const arrayArrangeLetterAndNull = (list: string[] | undefined) => {
    const listStart: string[] = [];
    const listEnd: string[] = [];
    let hasNull = false;

    if (!list) return [];

    list.forEach((item: string) => {
      if (item === null) {
        hasNull = true;
      } else if (!Number.isNaN(parseFloat(item))) {
        listStart.push(item);
      } else if (Number.isNaN(parseFloat(item))) {
        listEnd.push(item);
      }
    });
    const finalArray: string[] = sortVersionsArray(listStart.concat(listEnd));
    if (hasNull) {
      finalArray.splice(0, 0, 'null');
    }
    return finalArray;
  };

  const getVersions = () => {
    const versionSelectPlaceHolder = { value: '', text: t('advanced_search.so_version') };

    setVersionsList({
      android: [versionSelectPlaceHolder],
      ios: [versionSelectPlaceHolder],
      chrome: [versionSelectPlaceHolder],
    });

    serviceLoki.getVersions().then((response) => {
      const androidArray: string[] = arrayArrangeLetterAndNull(response.android);
      const iOSArray: string[] = arrayArrangeLetterAndNull(response.iOS);
      const chromeArray: string[] = arrayArrangeLetterAndNull(response.chromeOS);
      const WSArray: string[] = arrayArrangeLetterAndNull(response.windows);

      if (iOSArray.length >= 1) {
        setHasIOS(true);
      }

      if (androidArray.length >= 1) {
        setHasAndroid(true);
      }

      if (chromeArray.length > 0) {
        setHasChrome(true);
      }

      if (WSArray.length >= 1) {
        setHasWS(true);
      }

      const androidAux = androidArray.reduce((arr: SelectProps[], item) => {
        if (item === 'null') {
          return [...arr, { value: item, text: t('advanced_search.so_unknow') }];
        }
        return [...arr, { value: item, text: item }];
      }, []);

      const iosAux = iOSArray.reduce((arr: SelectProps[], item) => {
        if (item === 'null') {
          return [...arr, { value: item, text: t('advanced_search.so_unknow') }];
        }
        return [...arr, { value: item, text: item }];
      }, []);

      const chromeAux = chromeArray.reduce((arr: SelectProps[], item) => {
        if (item === 'null') {
          return [...arr, { value: item, text: t('advanced_search.so_unknow') }];
        }
        return [...arr, { value: item, text: item }];
      }, []);

      setVersionsList((prevState) => ({
        android: [...prevState.android, ...androidAux],
        ios: [...prevState.ios, ...iosAux],
        chrome: [...prevState.chrome, ...chromeAux],
      }));
    });
  };

  const clearAllSwitches = () => {
    Object.keys(filterSwitches).forEach((item) => {
      setFilterSwitches((prevState) => ({ ...prevState, [item]: false }));
    });
  };

  const clearAllFields = () => {
    if (!checkedGroup) {
      setSelectedGroups([]);
    }

    if (!checkedDevicesId) {
      setIdentifierOption('');
      setIdentifierValue('');
    }

    if (!checkedStatus) {
      Object.keys(statusChecks).forEach((item) => {
        setStatusChecks((prevState) => ({ ...prevState, [item]: false }));
      });
    }

    if (!checkedOS) {
      setIsAndroid(false);
      setIsIos(false);
      setIsChrome(false);
    }

    if (!isAndroid) {
      setAndroidVersionOption('');
      Object.keys(androidManagementChecks).forEach((item) => {
        setAndroidManagementChecks((prevState) => ({ ...prevState, [item]: false }));
      });
    }

    if (!isIos) {
      setIosVersionOption('');
      Object.keys(IosManagementChecks).forEach((item) => {
        setIosManagementChecks((prevState) => ({ ...prevState, [item]: false }));
      });
    }

    if (!isChrome) {
      setChromeVersionOption('');
      Object.keys(chromeManagementChecks).forEach((item) => {
        setChromeManagementChecks((prevState) => ({ ...prevState, [item]: false }));
      });
      if (!checkedStorage) {
        Object.keys(storageUnit).forEach((item) => {
          setStorageUnit((prevState) => ({ ...prevState, [item]: false }));
        });

        setStorageValue('');
      } else {
        setStorageUnit({ ...storageUnit, MB: true });
      }

      if (!checkedChip) {
        Object.keys(chipCheck).forEach((item) => {
          setChipCheck((prevState) => ({ ...prevState, [item]: false }));
        });
      }
    }
  };

  useEffect(() => {
    if (startDateEnrollment && endDateEnrollment) {
      setHasDateErrorEnrollment(startDateEnrollment > endDateEnrollment);
    }
    if (startDateLastSync && endDateLastSync) {
      setHasDateErrorLastSync(startDateLastSync > endDateLastSync);
    }
    if (startDateActivated && endDateActivated) {
      setHasDateErrorActivated(startDateActivated > endDateActivated);
    }
  }, [startDateEnrollment, endDateEnrollment, startDateLastSync, endDateLastSync, startDateActivated, endDateActivated]);

  useEffect(() => {
    if (isOpen && !hasBeenOpened) {
      setHasBeenOpened(true);
      getVersions();
      const customFieldsAux: SelectProps[] = customFields.map((item) => {
        return { text: item.text, value: item.value };
      });
      setIdentifierOptions((prev) => [...prev, ...customFieldsAux]);
    }
    if (!isOpen) {
      clearAllSwitches();
    }
  }, [isOpen, hasBeenOpened]);

  useEffect(() => {
    clearAllFields();
  }, [filterSwitches, isAndroid, isIos, isChrome]);

  const prepareMixpanelReport = () => {
    const groups = selectedGroups.map((item) => item.name);
    const statusIds: StatusCode[] = Object.entries(statusChecks).reduce((arr: StatusCode[], [key, value]) => {
      if (value) {
        return [...arr, StatusCode[key]];
      }
      return [...arr];
    }, []);
    const statusNames = statusIds.map((item) => StatusCode[item]);
    const management = (managementChecks: Record<string, boolean>) => {
      const managementTypes = Object.entries(managementChecks).reduce((arr: string[], [key, value]) => {
        if (value) {
          return [...arr, key];
        }
        return [...arr];
      }, []);
      return managementTypes;
    };

    const filter: MixPanelReport = {
      ...(checkedGroup && groups.length && { groups }),
      ...(checkedDevicesId &&
        identifierValue && {
          deviceIdentifier: {
            type: customFields.find((item) => identifierOption === item.value)?.text || identifierOption,
            value: identifierValue,
          },
        }),
      ...(checkedDate && {
        enrollmentDate: {
          type: dateOption,
          dateStart: startDateEnrollment?.toISOString(),
          dateEnd: endDateEnrollment?.toISOString(),
        },
      }),
      ...(checkedStatus && { status: statusNames }),
      ...(checkedOrder && { orderBy: orderOptions }),
      ...(checkedLastSync && {
        lastSyncDate: {
          dateStart: startDateLastSync?.toISOString(),
          dateEnd: endDateLastSync?.toISOString(),
        },
      }),
      ...(checkedActivated && {
        activatedDate: {
          dateStart: startDateActivated?.toISOString(),
          dateEnd: endDateActivated?.toISOString(),
        },
      }),
      ...(checkedOS && {
        operationalSystem: {
          ...(isAndroid && {
            android: {
              version: androidVersionOption || undefined,
              managementType: management(androidManagementChecks).length ? management(androidManagementChecks) : undefined,
            },
          }),
          ...(isIos && {
            ios: {
              version: iOSVersionOption || undefined,
              managementType: management(IosManagementChecks).length ? management(IosManagementChecks) : undefined,
            },
          }),
          ...(isChrome && {
            chrome: {
              version: chromeVersionOption || undefined,
              managementType: management(chromeManagementChecks).length ? management(chromeManagementChecks) : undefined,
            },
          }),
        },
      }),
      ...(checkedStorage &&
        storageValue && {
          availableStorage: {
            value: storageValue,
            MB: storageUnit.MB,
            GB: storageUnit.GB,
          },
        }),
      ...(checkedBattery && {
        batteryLevel: {
          min: batteryValue[0],
          max: batteryValue[1],
        },
      }),
      ...(checkedChip &&
        (chipCheck.doesntHaveChip || chipCheck.hasChip) && {
          deviceWithChip: {
            hasChip: chipCheck.hasChip,
            doesntHaveChip: chipCheck.doesntHaveChip,
          },
        }),
    };
    return filter;
  };

  const prepareReportData = (selectedGroupIds: number[], statusArray: StatusCode[]) => {
    const hasAllAndroidManagementFalse = !Object.values(androidManagementChecks).some((value) => value);
    const hasAllIosManagementFalse = !Object.values(IosManagementChecks).some((value) => value);

    const hasAllChromeManagementFalse = !Object.values(chromeManagementChecks).some((value) => value);

    const reportDataLocal: ReportData = {
      filters: {
        groups: checkedGroup && selectedGroupIds.length ? selectedGroupIds.map((item) => item) : null,
        device_identifier:
          checkedDevicesId && identifierValue
            ? {
                type: customFields.find((item) => identifierOption === item.value)?.text || identifierOption,
                value: identifierValue,
                id: identifierOption,
              }
            : null,
        enrollmentDate: checkedDate
          ? {
              type: dateOption,
              date_start: startDateEnrollment?.toDate(),
              date_end: endDateEnrollment?.toDate(),
            }
          : null,
        status: checkedStatus && statusArray.length ? statusArray : null,
        orderBy:
          checkedOrder && orderOptions.length !== 0
            ? {
                orderOptions,
              }
            : null,
        lastSyncDate: checkedLastSync
          ? {
              date_start: startDateLastSync?.toDate(),
              date_end: endDateLastSync?.toDate(),
            }
          : null,
        activatedDate: checkedActivated
          ? {
              date_start: startDateActivated?.toDate(),
              date_end: endDateActivated?.toDate(),
            }
          : null,
        operational_system:
          checkedOS && (isAndroid || isIos || isChrome)
            ? {
                android: isAndroid
                  ? {
                      checkedAndroid: isAndroid,
                      version: androidVersionOption || null,
                      management_type: !hasAllAndroidManagementFalse
                        ? {
                            WP: androidManagementChecks.WP,
                            FM: androidManagementChecks.FM,
                            FMWP: androidManagementChecks.FMWP,
                            DA: androidManagementChecks.DA,
                          }
                        : null,
                    }
                  : null,
                ios: isIos
                  ? {
                      checkedIOS: isIos,
                      version: iOSVersionOption || null,
                      management_type: !hasAllIosManagementFalse
                        ? {
                            basic: IosManagementChecks.basic,
                            supervised: IosManagementChecks.supervised,
                          }
                        : null,
                    }
                  : null,
                chrome: isChrome
                  ? {
                      checkedChrome: isChrome,
                      version: chromeVersionOption || null,
                      management_type: !hasAllChromeManagementFalse
                        ? {
                            chromeOS: chromeManagementChecks.chromeOS,
                            flex: chromeManagementChecks.flex,
                            NU: true,
                          }
                        : null,
                    }
                  : null,
              }
            : null,
        available_storage:
          checkedStorage && storageValue
            ? {
                value: storageValue,
                MB: storageUnit.MB,
                GB: storageUnit.GB,
              }
            : null,
        battery_level: checkedBattery
          ? {
              min: batteryValue[0],
              max: batteryValue[1],
            }
          : null,
        device_with_chip:
          checkedChip && (chipCheck.doesntHaveChip || chipCheck.hasChip)
            ? {
                hasChip: chipCheck.hasChip,
                doesntHaveChip: chipCheck.doesntHaveChip,
              }
            : null,
      },
    };

    const { filters } = reportDataLocal;

    if (filters && !Object.values(filters).some((value) => value !== null)) {
      sendReportData({ filters: null });
    } else {
      sendReportData(reportDataLocal);
    }
  };

  const mountFilter = () => {
    let selectedGroupIds: number[] = [];

    if (selectedGroups.length) {
      selectedGroupIds = selectedGroups.map((item) => item.id);
    }

    const statusArray: StatusCode[] = Object.entries(statusChecks).reduce((arr: StatusCode[], [key, value]) => {
      if (value) {
        return [...arr, StatusCode[key]];
      }
      return [...arr];
    }, []);

    prepareReportData(selectedGroupIds, statusArray);

    Mixpanel.track(`dispositivos-filtrou-busca-avançada`, tenant, administrator, {
      ...prepareMixpanelReport(),
    });

    const filter = {
      and: [
        !statusChecks.removed && dateOption !== 'deleted' && { status: { ne: 3 } },
        {
          or: [
            checkedDevicesId && identifierOption === 'mac_address' && { mac_address: { contains: identifierValue } },
            checkedDevicesId && identifierOption === 'serial_number' && { serial_number: { contains: identifierValue } },
            checkedDevicesId &&
              identifierOption === 'iccid' && {
                last_heartbeat: {
                  or: [{ sim_serial_number_1: { contains: identifierValue } }, { sim_serial_number_2: { contains: identifierValue } }],
                },
              },
            checkedDevicesId &&
              identifierOption === 'imei' && {
                last_heartbeat: { or: [{ imei_1: { contains: identifierValue } }, { imei_2: { contains: identifierValue } }] },
              },
            checkedDate &&
              dateOption === 'created' && {
                created_at: {
                  ge: startDateEnrollment?.toISOString(),
                  le: endDateEnrollment?.toISOString(),
                },
              },
            checkedDate &&
              dateOption === 'deleted' && {
                and: [
                  { or: [{ status: 3 }, { status: 4 }, { status: 5 }, { status: 8 }, { status: 9 }] },
                  {
                    updated_at: {
                      ge: startDateEnrollment?.toISOString(),
                      le: endDateEnrollment?.toISOString(),
                    },
                  },
                ],
              },
          ],
        },
        {
          or: [checkedGroup && selectedGroups && { group_id: selectedGroupIds.length > 1 ? { in: selectedGroupIds } : { eq: selectedGroupIds[0] } }],
        },
        {
          or: [
            {
              and: [
                checkedOS && isIos && !iOSVersionOption && { and: [{ 'tolower(platform)': 'ios' }, { status: { ne: 0 } }] },
                checkedOS && iOSVersionOption && { and: [{ 'tolower(platform)': 'ios' }, { os_version: iOSVersionOption }] },
                {
                  or: [
                    checkedOS &&
                      IosManagementChecks.supervised && {
                        and: [{ 'tolower(platform)': 'ios' }, { ios_info: { supervised: true } }, { status: { ne: 0 } }],
                      },
                    checkedOS &&
                      IosManagementChecks.basic && {
                        and: [{ 'tolower(platform)': 'ios' }, { ios_info: { supervised: false } }, { status: { ne: 0 } }],
                      },
                  ],
                },
              ],
            },
            {
              and: [
                checkedOS && isAndroid && !androidVersionOption && { and: [{ 'tolower(platform)': 'android' }, { status: { ne: 0 } }] },
                checkedOS && androidVersionOption && { and: [{ 'tolower(platform)': 'android' }, { os_version: androidVersionOption }] },
                {
                  or: [
                    checkedOS &&
                      androidManagementChecks.DA && { and: [{ 'tolower(platform)': 'android' }, { device_owner: false }, { status: { ne: 0 } }] },
                    checkedOS &&
                      androidManagementChecks.WP && {
                        and: [
                          { 'tolower(platform)': 'android' },
                          {
                            and: [
                              { platform: 'Android' },
                              { and: [{ management_mode: 'PROFILE_OWNER' }, { ownership: 'PERSONALLY_OWNED' }] },
                              { status: { ne: 0 } },
                            ],
                          },
                        ],
                      },
                    checkedOS &&
                      androidManagementChecks.FM && { and: [{ 'tolower(platform)': 'android' }, { device_owner: true }, { status: { ne: 0 } }] },
                    checkedOS &&
                      androidManagementChecks.FMWP && {
                        and: [
                          { 'tolower(platform)': 'android' },
                          {
                            and: [
                              { platform: 'Android' },
                              { and: [{ management_mode: 'PROFILE_OWNER' }, { ownership: 'COMPANY_OWNED' }] },
                              { status: { ne: 0 } },
                            ],
                          },
                        ],
                      },
                  ],
                },
              ],
            },
            {
              and: [
                checkedOS && isChrome && chromeVersionOption === '' && { and: [{ 'tolower(platform)': 'chromeos' }, { status: { ne: 0 } }] },
                checkedOS && chromeVersionOption !== '' && { and: [{ 'tolower(platform)': 'chromeos' }, { os_version: chromeVersionOption }] },
                {
                  or: [
                    checkedOS &&
                      chromeManagementChecks.chromeOS && {
                        and: [{ 'tolower(platform)': 'chromeos' }, { management_mode: 'ChromeOS' }, { status: { ne: 0 } }],
                      },
                    checkedOS &&
                      chromeManagementChecks.flex && {
                        and: [{ 'tolower(platform)': 'chromeos' }, { management_mode: 'ChromeFlex' }, { status: { ne: 0 } }],
                      },
                  ],
                },
              ],
            },
            {
              and: [checkedOS && isWS && { and: [{ 'tolower(platform)': 'windows' }, { status: { ne: 0 } }] }],
            },
          ],
        },
        {
          or: [
            checkedLastSync && {
              last_contact_at: {
                ge: startDateLastSync?.toISOString(),
                le: endDateLastSync?.toISOString(),
              },
            },
          ],
        },
        {
          or: [
            checkedActivated && {
              activated_at: {
                ge: startDateActivated?.toISOString(),
                le: endDateActivated?.toISOString(),
              },
            },
          ],
        },
        {
          or: [
            checkedStorage && {
              last_heartbeat: { available_memory: { ge: storageUnit.MB ? Number(storageValue) * 10 ** 6 : Number(storageValue) * 10 ** 9 } },
            },
          ],
        },
        {
          or: [
            checkedBattery && {
              and: [{ last_heartbeat: { battery_level: { ge: batteryValue[0] } } }, { last_heartbeat: { battery_level: { le: batteryValue[1] } } }],
            },
          ],
        },
        {
          or: [
            checkedStatus && statusChecks.active && { status: 1 },
            checkedStatus && statusChecks.blocked && { status: 2 },
            checkedStatus && statusChecks.created && { status: 0 },
            checkedStatus && statusChecks.lost && { status: 6 },
            checkedStatus && statusChecks.removed && { status: 3 },
            checkedStatus && statusChecks.tempBlock && { status: 7 },
            checkedStatus && statusChecks.unenrolled && { status: 5 },
            checkedStatus && statusChecks.wiped && { status: 4 },
            checkedStatus && statusChecks.safetynetBlocked && { status: 8 },
            checkedStatus && statusChecks.safetynetWiped && { status: 9 },
          ],
        },
        {
          or: [
            checkedChip && chipCheck.doesntHaveChip && { last_heartbeat: { and: [{ sim_serial_number_1: null }, { sim_serial_number_2: null }] } },
            checkedChip &&
              chipCheck.hasChip && { last_heartbeat: { or: [{ sim_serial_number_1: { ne: null } }, { sim_serial_number_2: { ne: null } }] } },
          ],
        },
      ],
    };

    const orderByQuery = checkedOrder ? `&$orderby=${orderOptions}` : '';

    const isCustomFieldSelected = customFields.some((item) => item.value === identifierOption);

    const customFieldQuery = isCustomFieldSelected ? `&custom_field_id=${identifierOption}&search_str=${identifierValue}` : '';

    const filterWithOrderBy = buildQuery({ filter }) + orderByQuery + customFieldQuery;

    filterDevices(filterWithOrderBy);
  };

  const handleClearFilter = () => {
    filterDevices('');
    clearAllSwitches();
    sendReportData({ filters: null });
  };

  return (
    <S.Content>
      <S.Top>
        <S.Group>
          <S.Switch
            id="switch-group"
            label={t('advanced_search.title_groups')}
            checked={checkedGroup}
            onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedGroup: e.target.checked })}
          />
          <S.Card>
            <S.CenterButton>
              <EuiButton onClick={() => setOpenGroupModal(true)} disabled={!filterSwitches.checkedGroup} style={{ fontSize: 14 }}>
                {selectedGroups.length > 0 ? `${selectedGroups.length} Grupo(s) selecionado(s)` : t('advanced_search.no_group_selected')}
              </EuiButton>
            </S.CenterButton>
          </S.Card>
          <SelectGroupModalComponent
            hasDinamicMargin
            selectedGroups={selectedGroups}
            setGroups={setSelectedGroups}
            open={openGroupModal}
            setOpen={setOpenGroupModal}
          />
        </S.Group>
        <S.Group>
          <S.Switch
            id="switch-identifier"
            label={t('advanced_search.identifier')}
            checked={checkedDevicesId}
            onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedDevicesId: e.target.checked })}
          />
          <S.Switch
            id="switch-date"
            label={t('advanced_search.flag')}
            checked={checkedDate}
            onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedDate: e.target.checked })}
          />
          <S.Card>
            <section className="identifier">
              <EuiSelect
                disabled={!checkedDevicesId}
                id="selectIdentifier"
                options={identifierOptions}
                value={identifierOption}
                onChange={(e) => setIdentifierOption(e.target.value)}
                aria-label="select type of identifier"
              />
              <EuiFieldText
                disabled={!checkedDevicesId}
                placeholder={t('advanced_search.search_identifier')}
                value={identifierValue}
                onChange={(e) => setIdentifierValue(e.target.value)}
                aria-label="indentifier value"
              />
            </section>
            <section className="date-picker">
              <div className="has-label">
                <EuiSelect
                  disabled={!checkedDate}
                  id="selectDateType"
                  options={options.dateOptions}
                  value={dateOption}
                  onChange={(e) => setDateOption(e.target.value)}
                  aria-label="select type of date"
                />
              </div>
              <div className="has-label">
                <p>{t('advanced_search.date')}</p>
                <EuiDatePickerRange
                  iconType={false}
                  startDateControl={
                    <EuiDatePicker
                      dateFormat="DD/MM/YYYY"
                      allowSameDay
                      disabled={!checkedDate}
                      selected={startDateEnrollment}
                      onChange={(date) => setStartDateEnrollment(date)}
                      startDate={startDateEnrollment}
                      endDate={endDateEnrollment}
                      isInvalid={(startDateEnrollment || moment()) > (endDateEnrollment || moment().add(7, 'd'))}
                      aria-label="Start date"
                    />
                  }
                  endDateControl={
                    <EuiDatePicker
                      dateFormat="DD/MM/YYYY"
                      allowSameDay
                      disabled={!checkedDate}
                      selected={endDateEnrollment}
                      onChange={(date) => setEndDateEnrollment(date)}
                      startDate={startDateEnrollment}
                      endDate={endDateEnrollment}
                      isInvalid={hasDateErrorEnrollment}
                      aria-label="End date"
                    />
                  }
                />
                {hasDateErrorEnrollment && <span className="date-error">{t('advanced_search.device_data')}</span>}
              </div>
            </section>
          </S.Card>
        </S.Group>
      </S.Top>
      <S.Mid>
        <S.Group>
          <S.Switch
            id="switch-status"
            label={t('advanced_search.status')}
            checked={checkedStatus}
            onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedStatus: e.target.checked })}
          />
          <S.Card className="status">
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.new')}
              checked={statusChecks.created}
              onChange={(e) => setStatusChecks({ ...statusChecks, created: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.active')}
              checked={statusChecks.active}
              onChange={(e) => setStatusChecks({ ...statusChecks, active: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.erased')}
              checked={statusChecks.wiped}
              onChange={(e) => setStatusChecks({ ...statusChecks, wiped: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.uninstalled')}
              checked={statusChecks.unenrolled}
              onChange={(e) => setStatusChecks({ ...statusChecks, unenrolled: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.blocked')}
              checked={statusChecks.blocked}
              onChange={(e) => setStatusChecks({ ...statusChecks, blocked: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.removed')}
              checked={statusChecks.removed}
              onChange={(e) => setStatusChecks({ ...statusChecks, removed: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.lost')}
              checked={statusChecks.lost}
              onChange={(e) => setStatusChecks({ ...statusChecks, lost: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.quarantine')}
              checked={statusChecks.tempBlock}
              onChange={(e) => setStatusChecks({ ...statusChecks, tempBlock: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.block_playIntegrity')}
              checked={statusChecks.safetynetBlocked}
              onChange={(e) => setStatusChecks({ ...statusChecks, safetynetBlocked: e.target.checked })}
            />
            <EuiCheckbox
              disabled={!checkedStatus}
              id={htmlIdGenerator()()}
              label={t('status.wiped_playIntegrity')}
              checked={statusChecks.safetynetWiped}
              onChange={(e) => setStatusChecks({ ...statusChecks, safetynetWiped: e.target.checked })}
            />
          </S.Card>
        </S.Group>
        <S.MidRight>
          <div className="first-line">
            <S.Group>
              <S.Switch
                id="switch-order"
                label={t('advanced_search.order')}
                checked={checkedOrder}
                onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedOrder: e.target.checked })}
              />
              <S.Card className="mid-first-line">
                <div className="has-label">
                  <p>{t('advanced_search.order_by')}</p>
                  <EuiSelect
                    disabled={!checkedOrder}
                    id="selectListOrder"
                    options={options.orderOptions}
                    value={orderOptions}
                    onChange={(e) => setOrderOptions(e.target.value)}
                    aria-label="select order by"
                  />
                </div>
              </S.Card>
            </S.Group>
            <S.Group>
              <S.Switch
                id="switch-last-sync"
                label={t('advanced_search.last_sync')}
                checked={checkedLastSync}
                onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedLastSync: e.target.checked })}
              />
              <S.Card className="mid-first-line">
                <div className="has-label date-picker">
                  <p>{t('advanced_search.date')}</p>
                  <EuiDatePickerRange
                    iconType={false}
                    startDateControl={
                      <EuiDatePicker
                        dateFormat="DD/MM/YYYY"
                        allowSameDay
                        disabled={!checkedLastSync}
                        selected={startDateLastSync}
                        onChange={(date) => setStartDateLastSync(date)}
                        startDate={startDateLastSync}
                        endDate={endDateLastSync}
                        isInvalid={(startDateLastSync || moment()) > (endDateLastSync || moment().add(7, 'd'))}
                        aria-label="Start date last sync"
                      />
                    }
                    endDateControl={
                      <EuiDatePicker
                        dateFormat="DD/MM/YYYY"
                        allowSameDay
                        disabled={!checkedLastSync}
                        selected={endDateLastSync}
                        onChange={(date) => setEndDateLastSync(date)}
                        startDate={startDateLastSync}
                        endDate={endDateLastSync}
                        isInvalid={hasDateErrorLastSync}
                        aria-label="End date last sync"
                      />
                    }
                  />
                  {hasDateErrorLastSync && <span className="date-error">{t('advanced_search.device_data')}</span>}
                </div>
              </S.Card>
            </S.Group>
            <S.Group>
              <S.Switch
                id="switch-activated"
                label={t('advanced_search.activated')}
                checked={checkedActivated}
                onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedActivated: e.target.checked })}
              />
              <S.Card className="mid-first-line">
                <div className="has-label date-picker__hour">
                  <p>{t('advanced_search.date')}</p>
                  <EuiDatePickerRange
                    iconType={false}
                    startDateControl={
                      <EuiDatePicker
                        allowSameDay
                        disabled={!checkedActivated}
                        selected={startDateActivated}
                        onChange={(date) => setStartDateActivated(date)}
                        startDate={startDateActivated}
                        endDate={endDateActivated}
                        isInvalid={(startDateActivated || moment()) > (endDateActivated || moment().add(7, 'd'))}
                        aria-label="Start date activated"
                        showTimeSelect
                        dateFormat="DD/MM/YYYY HH:mm"
                        timeFormat="HH:mm"
                      />
                    }
                    endDateControl={
                      <EuiDatePicker
                        utcOffset={-3 * 60}
                        allowSameDay
                        disabled={!checkedActivated}
                        selected={endDateActivated}
                        onChange={(date) => setEndDateActivated(date)}
                        startDate={startDateActivated}
                        endDate={endDateActivated}
                        isInvalid={hasDateErrorActivated}
                        aria-label="End date activated"
                        showTimeSelect
                        dateFormat="DD/MM/YYYY HH:mm"
                        timeFormat="HH:mm"
                      />
                    }
                  />
                  {hasDateErrorActivated && <span className="date-error">{t('advanced_search.device_data')}</span>}
                </div>
              </S.Card>
            </S.Group>
          </div>
          <S.Group>
            <S.Switch
              id="switch-OS"
              label={t('advanced_search.flag_so')}
              checked={checkedOS}
              onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedOS: e.target.checked })}
            />
            <section className="so-wrapper">
              <S.Card className="so">
                <IconAndroid width="15px" height="17px" fill="#67758D" />
                {!hasAndroid ? (
                  <EuiToolTip position="top" content={t('advanced_search.android_none')}>
                    <EuiCheckbox
                      disabled
                      id={htmlIdGenerator()()}
                      label="Android"
                      checked={isAndroid}
                      onChange={(e) => setIsAndroid(e.target.checked)}
                    />
                  </EuiToolTip>
                ) : (
                  <EuiCheckbox
                    disabled={!checkedOS}
                    id={htmlIdGenerator()()}
                    label="Android"
                    checked={isAndroid}
                    onChange={(e) => setIsAndroid(e.target.checked)}
                  />
                )}
                <EuiSelect
                  disabled={!checkedOS || !isAndroid}
                  id="selectAndroidSO"
                  options={versionsList.android}
                  value={androidVersionOption}
                  onChange={(e) => setAndroidVersionOption(e.target.value)}
                  aria-label="select version comparator"
                />
                <div className="so-management">
                  <p>{t('advanced_search.management')}</p>
                  <div>
                    <EuiCheckbox
                      disabled={!checkedOS || !isAndroid}
                      id={htmlIdGenerator()()}
                      label="Work Profile"
                      checked={androidManagementChecks.WP}
                      onChange={(e) => setAndroidManagementChecks({ ...androidManagementChecks, WP: e.target.checked })}
                    />
                    <EuiCheckbox
                      disabled={!checkedOS || !isAndroid}
                      id={htmlIdGenerator()()}
                      label="FM + WP"
                      checked={androidManagementChecks.FMWP}
                      onChange={(e) => setAndroidManagementChecks({ ...androidManagementChecks, FMWP: e.target.checked })}
                    />
                    <EuiCheckbox
                      disabled={!checkedOS || !isAndroid}
                      id={htmlIdGenerator()()}
                      label="Fully Managed Device"
                      checked={androidManagementChecks.FM}
                      onChange={(e) => setAndroidManagementChecks({ ...androidManagementChecks, FM: e.target.checked })}
                    />
                    <EuiCheckbox
                      disabled={!checkedOS || !isAndroid}
                      id={htmlIdGenerator()()}
                      label="Device Admin"
                      checked={androidManagementChecks.DA}
                      onChange={(e) => setAndroidManagementChecks({ ...androidManagementChecks, DA: e.target.checked })}
                    />
                  </div>
                </div>
              </S.Card>
              <S.Card className="so">
                <IconIos width="15px" height="17px" fill="#67758D" />
                {!hasIOS ? (
                  <EuiToolTip position="top" content={!checkedOS ? '' : t('advanced_search.ios_none')}>
                    <EuiCheckbox
                      disabled
                      id={htmlIdGenerator()()}
                      label="iOS / iPadOS"
                      checked={isIos}
                      onChange={(e) => setIsIos(e.target.checked)}
                    />
                  </EuiToolTip>
                ) : (
                  <EuiCheckbox
                    disabled={!checkedOS}
                    id={htmlIdGenerator()()}
                    label="iOS / iPadOS"
                    checked={isIos}
                    onChange={(e) => setIsIos(e.target.checked)}
                  />
                )}
                <EuiSelect
                  disabled={!checkedOS || !isIos}
                  id="selectAppleSO"
                  options={versionsList.ios}
                  value={iOSVersionOption}
                  onChange={(e) => setIosVersionOption(e.target.value)}
                  aria-label="select version comparator"
                />
                <div className="so-management">
                  <p>{t('advanced_search.management')}</p>
                  <div>
                    <EuiCheckbox
                      disabled={!checkedOS || !isIos}
                      id={htmlIdGenerator()()}
                      label={t('advanced_search.supervised')}
                      checked={IosManagementChecks.supervised}
                      onChange={(e) => setIosManagementChecks({ ...IosManagementChecks, supervised: e.target.checked })}
                    />
                    <EuiCheckbox
                      disabled={!checkedOS || !isIos}
                      id={htmlIdGenerator()()}
                      label={t('advanced_search.basic')}
                      checked={IosManagementChecks.basic}
                      onChange={(e) => setIosManagementChecks({ ...IosManagementChecks, basic: e.target.checked })}
                    />
                  </div>
                </div>
              </S.Card>
              <S.Card className="so" style={!hasChrome ? { display: 'none' } : {}}>
                <IconChromeOS width="18px" height="18px" fill="#67758D" />
                {!hasChrome ? (
                  <EuiToolTip position="top" content={!checkedOS ? '' : t('advanced_search.chrome_none')}>
                    <EuiCheckbox
                      disabled
                      id={htmlIdGenerator()()}
                      label="Chrome"
                      checked={isChrome}
                      onChange={(e) => setIsChrome(e.target.checked)}
                    />
                  </EuiToolTip>
                ) : (
                  <EuiCheckbox
                    disabled={!checkedOS}
                    id={htmlIdGenerator()()}
                    label="Chrome"
                    checked={isChrome}
                    onChange={(e) => setIsChrome(e.target.checked)}
                  />
                )}
                <EuiSelect
                  disabled={!checkedOS || !isChrome}
                  id="selectChromeSO"
                  options={versionsList.chrome}
                  value={chromeVersionOption}
                  onChange={(e) => setChromeVersionOption(e.target.value)}
                  aria-label="select version comparator"
                />
                <div className="so-management">
                  <p>{t('advanced_search.management')}</p>
                  <div>
                    <EuiCheckbox
                      disabled={!checkedOS || !isChrome}
                      id={htmlIdGenerator()()}
                      label={t('advanced_search.chromeOS')}
                      checked={chromeManagementChecks.chromeOS}
                      onChange={(e) => setChromeManagementChecks({ ...chromeManagementChecks, chromeOS: e.target.checked })}
                    />
                    <EuiCheckbox
                      disabled={!checkedOS || !isChrome}
                      id={htmlIdGenerator()()}
                      label={t('advanced_search.chrome_flex')}
                      checked={chromeManagementChecks.flex}
                      onChange={(e) => setChromeManagementChecks({ ...chromeManagementChecks, flex: e.target.checked })}
                    />
                  </div>
                </div>
              </S.Card>
              <FeatureFlagCheckerComponent featureFlag="Windows_Management">
                <S.Card className="so">
                  <IconWindows width="15px" height="17px" fill="#67758D" />
                  {!hasWS ? (
                    <EuiToolTip position="top" content={!checkedOS ? '' : t('advanced_search.windows_none')}>
                      <EuiCheckbox disabled id={htmlIdGenerator()()} label="Windows" checked={isWS} onChange={(e) => setIsWS(e.target.checked)} />
                    </EuiToolTip>
                  ) : (
                    <EuiCheckbox
                      disabled={!checkedOS}
                      id={htmlIdGenerator()()}
                      label="Windows"
                      checked={isWS}
                      onChange={(e) => setIsWS(e.target.checked)}
                    />
                  )}
                </S.Card>
              </FeatureFlagCheckerComponent>
            </section>
          </S.Group>
        </S.MidRight>
      </S.Mid>
      <S.Bottom>
        <S.Card className="ranges">
          <section>
            <S.Switch
              id="switch-storage"
              label={t('advanced_search.storage')}
              checked={checkedStorage}
              onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedStorage: e.target.checked })}
            />
            <div className="storage-wrapper">
              <S.StorageUnitGroup>
                <S.StorageUnitButton
                  isDisabled={!checkedStorage}
                  hasActiveFilters={storageUnit.GB}
                  onClick={() => setStorageUnit({ ...storageUnit, GB: true, MB: false })}
                >
                  GB
                </S.StorageUnitButton>
                <S.StorageUnitButton
                  isDisabled={!checkedStorage}
                  hasActiveFilters={storageUnit.MB}
                  onClick={() => setStorageUnit({ ...storageUnit, GB: false, MB: true })}
                >
                  MB
                </S.StorageUnitButton>
              </S.StorageUnitGroup>
              <EuiFieldText
                type="number"
                disabled={!checkedStorage}
                placeholder={t('advanced_search.storage_placeholder')}
                value={storageValue}
                onChange={(e) => setStorageValue(e.target.value)}
                aria-label="storage value"
              />
            </div>
          </section>
          <section>
            <S.Switch
              id="switch-battery"
              label={t('advanced_search.battery_level')}
              checked={checkedBattery}
              onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedBattery: e.target.checked })}
            />
            <S.DualRange
              disabled={!checkedBattery}
              id={htmlIdGenerator()()}
              value={batteryValue}
              onChange={(value) => setBatteryValue(value as [number, number])}
              showTicks
              ticks={options.rangeTicks}
              levels={options.rangeLevels}
              aria-label="battery levels"
            />
          </section>
          <section>
            <S.Switch
              id="switch-chip"
              label={t('advanced_search.switch_chip')}
              checked={checkedChip}
              onChange={(e) => setFilterSwitches({ ...filterSwitches, checkedChip: e.target.checked })}
            />
            <div className="chip-check">
              <EuiCheckbox
                disabled={!checkedChip}
                id={htmlIdGenerator()()}
                label={t('advanced_search.chip')}
                checked={chipCheck.hasChip}
                onChange={(e) => setChipCheck({ ...chipCheck, hasChip: e.target.checked })}
              />
              <EuiCheckbox
                disabled={!checkedChip}
                id={htmlIdGenerator()()}
                label={t('advanced_search.no_chip')}
                checked={chipCheck.doesntHaveChip}
                onChange={(e) => setChipCheck({ ...chipCheck, doesntHaveChip: e.target.checked })}
              />
            </div>
          </section>
        </S.Card>
        <S.SearchContainer>
          <S.SearchButton onClick={() => mountFilter()}>{t('advanced_search.btn_search')}</S.SearchButton>
          <S.ClearButton iconType="broom" className="link" onClick={() => handleClearFilter()}>
            {t('advanced_search.btn_clear')}
          </S.ClearButton>
        </S.SearchContainer>
      </S.Bottom>
    </S.Content>
  );
}
