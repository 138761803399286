import type { ContainerProps } from './WrapperComponent.contracts';
import styled, { css } from 'styled-components';

export const Container = styled.section<ContainerProps>`
  ${({ color, hasVerticalPadding, hasFooter }) => css`
    padding: ${hasVerticalPadding ? '36px' : '0 36px'};
    background-color: ${color};
    transition: all ease 0.2s;
    min-height: ${hasFooter ? 'calc(100vh - 50px)' : '100vh'};
  `}
`;
