import { ComponentButtonEmpty } from 'components';
import { StatusWifi } from 'pages/settings/connectivity/status-wifi';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import type { StoreProps } from 'stores/stores.contracts';
import type { BoxWifiProps } from './page-connectivity.contracts';
import * as style from './boxs-connectivity.style';
import { StatusBluetooth } from './status-bluetooth';

export function BoxWifiBluetooth({ open_wifi_modal }: BoxWifiProps) {
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const dispatch = useDispatch();
  const [activeWifi, setActiveWifi] = useState(false);
  const [activeBluetooth, setActiveBluetooth] = useState(false);

  const [initialPolicy, setInitialPolicy] = useState({});

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initialPolicy)) {
      setActiveWifi(policy?.wifiConfigDisabled);
      setActiveBluetooth(policy?.bluetoothDisabled);
      setInitialPolicy(policy);
    }
  }, [policy]);

  function handleChangeWifi(value: boolean) {
    setActiveWifi(value);
    dispatch({ type: 'SET_POLICY_CHANGES', payload: { wifiConfigDisabled: value } });
  }

  function handleChangeBluetooth(value: boolean) {
    setActiveBluetooth(value);
    dispatch({ type: 'SET_POLICY_CHANGES', payload: { bluetoothDisabled: value } });
  }

  return (
    <style.Card title="" textAlign="left" className="card">
      <style.BoxWifiBluetoothMain>
        <style.WifiStatusSection>
          <style.TitleBox>Wifi</style.TitleBox>
          <StatusWifi active={activeWifi} onChange={(value: boolean) => handleChangeWifi(value)} />
          <style.NetworkWifiSection>
            <span className="left-span manage-networks">
              <small style={{ display: 'flex', gap: '3px' }}>
                <button type="button" onClick={() => open_wifi_modal()}>
                  {`(${3}) `}
                </button>
                Redes gerenciadas
              </small>
              <ComponentButtonEmpty icon_type="arrowDown" on_click={open_wifi_modal} label=" Gerenciar Redes" font="13px" />
            </span>
            <span className="right-span manage-networks">
              <h4>Redes Wifi</h4>
              <p>Disponível para todos os dispositivos</p>
            </span>
          </style.NetworkWifiSection>
        </style.WifiStatusSection>
        <style.BluetoothSection>
          <style.TitleBox>Bluetooth</style.TitleBox>
          <StatusBluetooth active={activeBluetooth} onChange={(value: boolean) => handleChangeBluetooth(value)} />
        </style.BluetoothSection>
      </style.BoxWifiBluetoothMain>
    </style.Card>
  );
}
