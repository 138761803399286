import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { SimManagement } from 'modules/management/components';
import type { FormEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConstantLanguages } from 'utilities';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import type { Paginate } from './EmbbededSimManagement.contracts';
import type { StatusCount, SelectProps } from 'modules/management/pages/SimManagement/PageSimManagement.contracts';
import type { FeatureFlagsProps } from 'modules/operational/pages/devices/PageDevices.contracts';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { AxiosError } from 'axios';

export function EmbbededSimManagement() {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'sim_management' });
  const query = useQuery();

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [csvLoading, setCsvLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<Toast[]>([]);

  const [modal, setModal] = useState<boolean>(false);

  const [paginate, setPaginate] = useState<Paginate>({ page: 0, items: [], totalItems: 0 });
  const [countStatus, setCountStatus] = useState<StatusCount>({
    total: 0,
    managed: 0,
    pending: 0,
  });

  const [searchValue, setSearchValue] = useState('');
  const [searchSelect, setSearchSelect] = useState('id');
  const [selectedStatus, setSelectedStatus] = useState('');
  const [customFieldLabels, setCustomFieldLabels] = useState<SelectProps[]>([]);
  const [featureFlags, setFeatureFlags] = useState(false);

  useEffect(() => {
    i18next.changeLanguage(query.get('language') || ConstantLanguages.PT_BR);
    getSimByStatus();
    getFeatureFlags();
  }, []);

  useEffect(() => {
    if (featureFlags) {
      getCustomFieldLabel();
    }
  }, [featureFlags]);

  const handleSelectedStatus = (status: string) => {
    setSelectedStatus(status);
  };

  const handleFilterBySelect = (option: string) => {
    setSearchSelect(option);
  };

  const handleInputValue = (value: string) => {
    setSearchValue(value);
  };
  const getCustomFieldLabel = async () => {
    try {
      const result = await serviceLoki.getCustomFields();
      setCustomFieldLabels(result);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    }
  };

  const getFeatureFlags = async () => {
    const data: FeatureFlagsProps = await servicePulsusWeb.getFlags();

    setFeatureFlags(data?.custom_fields || false);
  };

  const getSimByStatus = async () => {
    try {
      setLoading(true);
      const result = await serviceLoki.getNumberOfSims();
      setCountStatus(result);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    } finally {
      setLoading(false);
    }
  };

  const fetchData = async (pageToFetch: number, select?: string, value?: string, status?: string) => {
    try {
      if (status && status.length > 0) {
        await handleSelectedStatus(status);
      }

      setLoading(true);
      const hasCustomFieldSelected = customFieldLabels.some((item) => select === item.value || searchSelect === item.value);

      const selectedCustomField = customFieldLabels.filter((item) => item.value === searchSelect);

      if (hasCustomFieldSelected) {
        const result = await serviceLoki.getSims(
          pageToFetch + 1,
          i18n.language,
          selectedStatus || status,
          'custom_field_info',
          value,
          selectedCustomField[0].value
        );

        setPaginate({
          page: pageToFetch,
          items: result.sims,
          totalItems: result.total,
        });
      } else {
        const result = await serviceLoki.getSims(pageToFetch + 1, i18n.language, selectedStatus || status, select, value);

        setPaginate({
          page: pageToFetch,
          items: result.sims,
          totalItems: result.total,
        });
      }
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteSIM = async (): Promise<void> => {
    await fetchData(0);
  };

  const handleChangePage = async (currentPage: number): Promise<void> => {
    try {
      await fetchData(currentPage);
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
    }
  };

  const fetchCsv = async (e: FormEvent<HTMLFormElement>, file: FileList | null): Promise<void> => {
    e.preventDefault();

    try {
      setCsvLoading(true);

      const csv = file?.item(0) as File;

      await Promise.all([serviceLoki.uploadFile(csv), fetchData(0)]);
      handleChangePage(0);
      setModal(false);
    } catch (err: unknown) {
      setToast([
        {
          title: err instanceof AxiosError ? err.response?.data?.detail : t('upload_file.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: '',
        },
      ]);
    } finally {
      setCsvLoading(false);
    }
  };

  return (
    <>
      <SimManagement
        fetchCsv={fetchCsv}
        handleChangePage={handleChangePage}
        handleDeleteSIM={handleDeleteSIM}
        setModal={setModal}
        modal={modal}
        csvLoading={csvLoading}
        loading={loading}
        message={message}
        setMessage={setMessage}
        setToast={setToast}
        page={paginate.page}
        sims={paginate.items}
        totalItems={paginate.totalItems}
        searchSims={(select: string, value: string, status?: string) => fetchData(0, select, value, status)}
        countStatus={countStatus}
        selectedStatus={selectedStatus}
        handleSelectedStatus={handleSelectedStatus}
        searchSelect={searchSelect}
        handleFilterBySelect={handleFilterBySelect}
        searchValue={searchValue}
        handleInputValue={handleInputValue}
        customFieldLabels={customFieldLabels}
      />
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
}
