import { CircularProgress, LinearProgress } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 5fr;
  gap: 20px;

  section {
    position: relative;
    display: flex;
    justify-content: space-between;
    background-color: ${({ theme }) => theme.dashboardTheme.paper};
    padding: 25px 20px;
    border-radius: 4.469px;
    width: 100%;
  }
`;

export const BlockContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const BlockTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  min-height: 25px;

  span {
    text-transform: uppercase;
    color: #818181;
  }
`;

export const StatusLight = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  min-height: 12px;
  min-width: 12px;
  background-color: #f44336;
  border-radius: 50%;
  opacity: 1;

  &.transparent {
    transition: opacity 0.4s;
    opacity: 0;
  }
`;

export const StyledCircularProgress = styled(CircularProgress)`
  position: absolute;
  top: 15px;
  right: 15px;
  max-height: 20px !important;
  max-width: 20px !important;

  svg {
    color: ${({ theme }) => theme.main};
  }
`;
