import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';

import androidReducer from './android-settings-store';
import chromeOSReducer from './chrome-os-store';
import type { StoreProps } from './stores.contracts';

const rootReducers = combineReducers<StoreProps>({
  android_settings: androidReducer,
  chromeOS: chromeOSReducer,
});

const store = configureStore({ reducer: rootReducers });

export default store;
