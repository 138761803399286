import * as S from './PermittedWebSitesComponent.styles';
import type { PermittedWebSitesComponentProps } from './PermittedWebSitesComponent.contracts';
import { IconButton, InputAdornment } from '@mui/material';
import { TextEllipsisComponent } from 'modules/core/components';
import { useTypedTranslation } from 'modules/core/hooks';
import DeleteIcon from '@mui/icons-material/Delete';
import { useState, type ReactElement } from 'react';
import { isValidUrl } from 'helpers';
import { htmlIdGenerator } from '@elastic/eui';

export function PermittedWebSitesComponent({ webSitesData, setWebSitesData }: PermittedWebSitesComponentProps): ReactElement {
  const [addItem, setAddItem] = useState('');
  const [error, setError] = useState('');

  const { t } = useTypedTranslation<'webSites'>('webSites');

  const handleRemoveWebSite = (item: string) => {
    const listFiltered = webSitesData.filter((site) => site !== item);
    setWebSitesData(listFiltered);
  };

  const handleAddWebSite = () => {
    if (webSitesData.includes(addItem)) {
      setError('exist');
      return;
    } else if (!isValidUrl(addItem)) {
      setError('invalid');
      return;
    } else {
      setError('');

      setWebSitesData([...webSitesData, addItem]);
      setAddItem('');
    }
  };

  return (
    <S.Container>
      <S.FlexRow>
        <S.InputApp
          id="component-error"
          aria-describedby="component-error-text"
          type="url"
          placeholder="URL"
          value={addItem}
          onChange={(e) => setAddItem(e.target.value)}
          disabled={false}
          onKeyDown={(e) => e.key === 'Enter' && handleAddWebSite()}
          endAdornment={
            <InputAdornment position="end">
              <S.AddAppIcon data-testid="addAppIcon" onClick={handleAddWebSite} />
            </InputAdornment>
          }
        />
        {error === 'exist' && <S.InputError>{t('phraseError')}</S.InputError>}
        {error === 'invalid' && <S.InputError>{t('phraseErrorInvalid')}</S.InputError>}
        <S.PhraseInfo>{t('phraseInfo')}</S.PhraseInfo>
      </S.FlexRow>
      <S.WebSitesList>
        {webSitesData.map((item) => (
          <S.WebSiteItem key={htmlIdGenerator()()}>
            <TextEllipsisComponent>
              <p>{item}</p>
            </TextEllipsisComponent>
            <div>
              <IconButton data-testid="deleteIcon" onClick={() => handleRemoveWebSite(item)}>
                <DeleteIcon style={{ color: '#e42222' }} />
              </IconButton>
            </div>
          </S.WebSiteItem>
        ))}
      </S.WebSitesList>
    </S.Container>
  );
}
