import { useEffect, useState } from 'react';
import { TileLayer, useMap } from 'react-leaflet';
import { EuiCheckboxGroup, EuiSelect } from '@elastic/eui';
import L from 'leaflet';
import Geocoder from 'leaflet-control-geocoder';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import mapMarker from 'assets/images/map-marker.svg';
import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';
import '@netmust/leaflet-continuouszoom';
import pulsus_logo from 'assets/images/image-pulsus-logo.svg';
import { ServicePulsusBalder } from 'services/ServicePulsusBalder';
import type {
  MapDataProps,
  NetworkOptionsProps,
  OperatorOptionsProps,
  InputTypeProps,
  LayerTypeProps,
} from 'pages/pulsus-insights-signals/page-insights-signal.contracts';
import animation_logo_pulsus_grey from '../../assets/animations/animation-logo-pulsus-grey.json';
import * as style from './page-insights-signals.style';

export default function SignalsMapLayers() {
  const map = useMap();
  const { t, i18n } = useTranslation();
  const [marker, setMarker] = useState<L.Marker>();

  const [completeData, setCompleteData] = useState<MapDataProps[]>([]);

  const [dateActive, setDateActive] = useState('');
  const [dateLeaflet, setDateLeaflet] = useState('');
  const [dateIndex, setDateIndex] = useState(0);

  const [allOperators, setAllOperators] = useState<OperatorOptionsProps[]>([{ value: '', text: '' }]);
  const [operatorActive, setOperatorActive] = useState<string>('');
  const [operatorIndex, setOperatorIndex] = useState(0);

  const [allNetworks, setAllNetworks] = useState<NetworkOptionsProps[]>([{ id: '', label: '' }]);
  const [initialNetwork, setInitialNetwork] = useState('');
  const [networksStatus, setNetworksStatus] = useState({ [`${initialNetwork}`]: true });

  const getOptions = () => {
    ServicePulsusBalder.getMapOptions(i18n.language)
      .then((item) => {
        setCompleteData(item);
      })
      .catch(() => {
        // TODO
      });
  };

  const onChangeDate = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDateActive(e.target.value);
    setDateIndex(completeData.findIndex((item) => item.value === e.target.value));
    setOperatorIndex(0);
  };

  const getOperatorIndex = () => {
    setOperatorIndex(completeData?.[dateIndex]?.operatorNetwork?.findIndex((item) => item.operator === operatorActive));
  };

  const getAllNetworks = (operator?: string) => {
    const index = completeData?.[dateIndex]?.operatorNetwork?.findIndex((item) => item.operator === operator);

    setAllNetworks(
      completeData &&
        completeData[dateIndex]?.operatorNetwork[index !== -1 ? index : operatorIndex]?.network.map((item) => ({
          id: item,
          label: item,
        }))
    );
  };

  const onChangeOperator = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setOperatorActive(e.target.value);
    getOperatorIndex();
    getAllNetworks(e.target.value);
  };

  const onChangeNetwork = (optionId: string) => {
    const newCheckboxIdToSelectedMap = {
      ...networksStatus,
      ...{
        [optionId]: !networksStatus[optionId],
      },
    };
    setNetworksStatus(newCheckboxIdToSelectedMap);
  };

  const getInitialOperator = () => {
    setOperatorActive(completeData && completeData[dateIndex]?.operatorNetwork[0].operator);
  };

  const getAllOperators = () => {
    setAllOperators(completeData && completeData[dateIndex]?.operatorNetwork.map((item) => ({ value: item.operator, text: item.operator })));
    getAllNetworks();
  };

  const getInitialDate = () => {
    if (completeData.length > 0) {
      setDateActive(completeData[0].value);
      setDateLeaflet(completeData[0].value);
      getAllOperators();
      getAllNetworks();
    }
  };

  const createInputDiv = () => {
    const element_img_container = document.createElement('div');
    const element_inner_div = document.createElement('div');
    const element_img_signals = document.createElement('img');
    const element_signals = document.createElement('span');

    element_inner_div.className = 'signals-logo-inner';
    element_img_container.className = 'signals-logo-container';
    element_img_signals.setAttribute('src', pulsus_logo);
    element_signals.innerText = t('pages.pulsus_insights.modules.signals');
    element_inner_div.appendChild(element_img_signals);
    element_inner_div.appendChild(element_signals);
    element_img_container.appendChild(element_inner_div);
    const controlDiv = document.getElementsByClassName('leaflet-control-geocoder');
    controlDiv[0].insertAdjacentElement('afterend', element_img_container);
  };

  const getGeocoder = () => {
    const geocoder = new Geocoder();

    geocoder.options = {
      ...geocoder.options,
      collapsed: false,
      placeholder: t('pages.pulsus_insights_signals.input_search'),
      defaultMarkGeocode: false,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      position: 'topleft',
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geocoder.addTo(map);

    const markerIcon = L.icon({
      iconUrl: mapMarker,
      iconSize: [58, 68],
      iconAnchor: [29, 68],
      popupAnchor: [170, 5],
    });

    let localMarker: L.Marker | undefined;

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    geocoder.on('markgeocode', (e) => {
      const latlng = e.geocode.center;

      if (localMarker) {
        map.removeLayer(localMarker);
      }

      localMarker = L.marker(latlng, { icon: markerIcon });

      setMarker(localMarker);

      map.fitBounds(e.geocode.bbox);
    });
  };

  useEffect(() => {
    marker?.on('click', () => {
      map.removeLayer(marker);
    });

    if (marker) {
      map.addLayer(marker);
    }

    return () => {
      marker?.off();
    };
  }, [marker]);
  const handleResetMap = () => {
    const input = document.querySelector('input');

    input?.addEventListener('keydown', (e) => {
      const event = e as unknown as InputTypeProps;

      if (event.code === 'Enter' && event?.target?.value === '') {
        map.flyTo([-14.87992, -53.9791], 5);

        map.eachLayer((layer: LayerTypeProps) => {
          if (layer?.options && layer?.options?.pane === 'markerPane') {
            map.removeLayer(layer);
          }
        });
      }
    });
  };

  useEffect(() => {
    getGeocoder();
    handleResetMap();
    createInputDiv();
    getOptions();
    getAllOperators();
  }, []);

  useEffect(() => {
    getInitialDate();
  }, [completeData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setDateLeaflet(dateActive);
    }, 500);

    return () => clearTimeout(timer);
  }, [dateActive]);

  useEffect(() => {
    getAllOperators();
    getInitialOperator();
  }, [dateIndex, completeData]);

  useEffect(() => {
    getAllNetworks();
  }, [dateIndex, completeData]);

  useEffect(() => {
    getInitialOperator();
    getAllNetworks(operatorActive);
  }, [dateIndex]);

  useEffect(() => {
    if (allNetworks !== undefined) {
      setInitialNetwork(allNetworks[allNetworks.length - 1].id);
      setNetworksStatus({
        ...{
          [allNetworks[allNetworks.length - 1].id]: true,
        },
      });
    }
  }, [allNetworks]);

  return (
    <>
      {(dateActive.length === 0 || operatorActive === undefined || allNetworks === undefined) && (
        <style.PlaceHolder>
          <Lottie
            data-testid="animation_logo_pulsus"
            animationData={animation_logo_pulsus_grey}
            style={{
              width: '500px',
              height: '500px',
              pointerEvents: 'none',
              margin: '0',
            }}
          />
        </style.PlaceHolder>
      )}
      {dateActive.length > 0 &&
        (networksStatus['2G'] || networksStatus['3G'] || networksStatus['4G'] || networksStatus['5G']) &&
        allNetworks !== undefined &&
        initialNetwork.length > 0 &&
        dateActive === dateLeaflet &&
        allOperators.map((operatorLocal) => {
          return Object.entries(networksStatus).map((network) => {
            if (network[1]) {
              if (operatorLocal.value === operatorActive) {
                return (
                  <TileLayer
                    key={`${operatorLocal.value} ${network}`}
                    url={`https://tiles.pulsus.mobi/tiles-png/${dateActive.slice(-5, -1)}/${dateActive.slice(0, 2)}/${operatorActive}/${
                      network[0]
                    }/{z}/{x}/{y}.png`}
                  />
                );
              }
            }

            return null;
          });
        })}

      <style.NetworkControls>
        <EuiCheckboxGroup options={allNetworks} idToSelectedMap={networksStatus} onChange={(id) => onChangeNetwork(id)} />
      </style.NetworkControls>
      <div />
      <style.Legends>
        <h4>{`${t('pages.pulsus_insights_signals.signal_level')}:`}</h4>
        <div className="legends-container">
          <legend>
            <span className="number">0</span>
            <span className="zero-signal-level" />
          </legend>
          <legend>
            <span className="number">1</span>
            <span className="one-signal-level" />
          </legend>
          <legend>
            <span className="number">2</span>
            <span className="two-signal-level" />
          </legend>
          <legend>
            <span className="number">3</span>
            <span className="three-signal-level" />
          </legend>
          <legend>
            <span className="number">4</span>
            <span className="four-signal-level" />
          </legend>
        </div>
      </style.Legends>
      <style.LeafletLogo>Leaflet</style.LeafletLogo>
      <style.Provider onMouseOver={() => map.dragging.disable()} onMouseOut={() => map.dragging.enable()}>
        <EuiSelect
          prepend={`${t('pages.pulsus_insights_signals.select_provider')}`}
          id="select-provider"
          options={allOperators}
          value={operatorActive}
          onChange={(e) => {
            onChangeOperator(e);
          }}
          aria-label="operatorSelected"
        />
      </style.Provider>
      <style.Months onMouseOver={() => map.dragging.disable()} onMouseOut={() => map.dragging.enable()}>
        <EuiSelect
          prepend={`${t('pages.pulsus_insights_signals.select_date')}`}
          id="select-months"
          options={completeData}
          value={dateActive}
          onChange={(e) => onChangeDate(e)}
        />
      </style.Months>
    </>
  );
}
