import { EuiTextColor } from '@elastic/eui';
import device from 'assets/gifs/direct-zero-touch-device.gif';
import { Autocomplete } from 'components';
import type { AutocompleteOptionProps } from 'components/autocomplete/component-autocomplete.contracts';
import { useEffect, useMemo, useState } from 'react';
import { ServicePulsusFreyr } from 'services/ServicePulsusFreyr';
import type { DirectZeroTouchProps, GetResponseItem } from './embedded-direct-zero-touch.contracts';
import * as style from './embedded-direct-zero-touch.style';

export function DirectZeroTouch(props: DirectZeroTouchProps) {
  const { groupSelected, onChangeGroup } = props;
  const [selectOptions, setSelectOptions] = useState<AutocompleteOptionProps[]>([]);
  const FreyrService = useMemo(() => new ServicePulsusFreyr(), []);

  function onChangeAutocomplete(value: AutocompleteOptionProps | null) {
    onChangeGroup(value);
  }

  async function getOptions() {
    const data = await FreyrService.getDeviceGroups();
    const auxOptionsItems: AutocompleteOptionProps[] = [];
    data.forEach((item: GetResponseItem) => {
      auxOptionsItems.push({ id: item.name, dropdownDisplay: item.name });
    });
    setSelectOptions(auxOptionsItems);
  }

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <style.Container>
      <style.ContainerBox>
        <style.Content>
          <style.DescriptionBox className="item">
            <EuiTextColor>Para o uso do direct </EuiTextColor>
            <EuiTextColor className="colored">Zero-Touch </EuiTextColor>
            <EuiTextColor>Enrollment você precisa definir um </EuiTextColor>
            <EuiTextColor className="colored">grupo padrão </EuiTextColor>
            <EuiTextColor>para receber os </EuiTextColor>
            <EuiTextColor className="colored">novos dispositivos.</EuiTextColor>
          </style.DescriptionBox>
          <style.GroupSelection className="item">
            <Autocomplete
              disabled={!selectOptions || selectOptions?.length < 1}
              label="Procurar por grupo"
              options={selectOptions}
              value={groupSelected}
              onChange={(op) => onChangeAutocomplete(op)}
            />
          </style.GroupSelection>
        </style.Content>
      </style.ContainerBox>
      <style.ContainerBox>
        <div className="imgBox">
          <img src={device} height={400} alt="device-animation" />
        </div>
      </style.ContainerBox>
    </style.Container>
  );
}
