import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiInMemoryTable, EuiPagination, EuiToolTip } from '@elastic/eui';
import { useQuery } from 'hooks/use-query';
import type { ReactElement } from 'react';
import { useEffect, useState } from 'react';
import i18next from 'i18next';
import type { Geofence } from 'modules/management/entities/geofence';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';
import { useTranslation } from 'react-i18next';
import type { Toast } from 'modules/core/components';
import { ComponentToast, TextEllipsisComponent } from 'modules/core/components';
import * as S from './ComponentFences.style';
import { ModalFenceConfigureComponent } from './ModalFenceConfigure/ModalFenceConfigureComponent';
import { ModalActiveFenceComponent } from './ModalActiveFence/ModalActiveFenceComponent';
import type { ComponentFencesProps } from './ComponentFences.contracts';

export function ComponentFences({ hasChanges }: ComponentFencesProps): ReactElement {
  const query = useQuery();
  const language = query.get('language');

  const [modalEditOpen, setModalEditOpen] = useState<Geofence | null>(null);
  const [modalActiveFence, setModalActiveFence] = useState<Geofence | null>(null);

  const [pagination, setPagination] = useState({ activePage: 0, totalPages: 1 });
  const [loading, setLoading] = useState<boolean>(false);

  const [newGeofenceList, setNewGeofenceList] = useState<Geofence[]>([]);

  const [toast, setToast] = useState<Toast[]>([]);

  const { t } = useTranslation('translation', { keyPrefix: 'geofence.table_fences' });

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }

    getAllGeofences(pagination.activePage);
  }, [hasChanges]);

  const getAllGeofences = async (index: number): Promise<void> => {
    try {
      setLoading(true);

      const items: Geofence[] = await ServicePulsusValquiria.getAllGeofences(index + 1);

      setNewGeofenceList(items);
      setPagination({ activePage: index, totalPages: items[0]?.total_pages || 1 });
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '2',
          title: t('errors.baseTitle'),
          text: t('errors.searchFences'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const handlePagination = async (index: number): Promise<void> => {
    await getAllGeofences(index);
  };

  const handleCloseEditModal = async (fenceToActive: Geofence | null, fetchNewData) => {
    setModalEditOpen(null);

    if (fetchNewData) await getAllGeofences(pagination.activePage);

    if (fenceToActive) {
      setModalActiveFence(fenceToActive);
    }
  };

  const columns: EuiBasicTableColumn<Geofence>[] = [
    {
      field: 'name',
      name: t('rows.fence'),
      width: '100px',
      render: (name: string, item: Geofence) => {
        return (
          <S.FencesNameContainer>
            <EuiToolTip position="top" content={name}>
              <S.FencesName>
                <TextEllipsisComponent>{name}</TextEllipsisComponent>
              </S.FencesName>
            </EuiToolTip>
            <S.StyledEditIcon id="editIcon" onClick={() => setModalEditOpen(item)} />
          </S.FencesNameContainer>
        );
      },
    },
    {
      field: 'description',
      name: t('rows.description'),
      width: '150px',
      align: 'center',
      render: (description: string) => {
        return (
          <EuiToolTip position="top" content={description}>
            <S.DescriptionText>{description}</S.DescriptionText>
          </EuiToolTip>
        );
      },
    },
    {
      field: 'radius',
      name: t('rows.radius'),
      width: '80px',
      align: 'center',
      render: (raio: string) => {
        return <S.RadiusFlag>{raio}</S.RadiusFlag>;
      },
    },
    {
      field: 'latitude',
      name: t('rows.coords'),
      width: '150px',
      align: 'center',
      render: (_: string, item: Geofence) => {
        return (
          <S.CoordinatesColumn>
            <p>
              LAT:
              <span>{` ${item.latitude}`}</span>
            </p>
            <p>
              LONG:
              <span>{` ${item.longitude}`}</span>
            </p>
          </S.CoordinatesColumn>
        );
      },
    },
  ];

  return (
    <>
      <S.Body>
        <EuiInMemoryTable
          itemId="id"
          key="geofences_table_edit"
          items={newGeofenceList}
          columns={columns}
          isSelectable
          loading={loading}
          message={loading ? t('message.loading') : t('message.no_item')}
        />
        <EuiPagination pageCount={pagination.totalPages} activePage={pagination.activePage} onPageClick={(page) => handlePagination(page)} />
        {!!modalEditOpen && (
          <ModalFenceConfigureComponent
            fields={{ description: modalEditOpen.description, name: modalEditOpen.name, id: modalEditOpen.id }}
            onClose={(isConfigurable, fetchNewData) => handleCloseEditModal(isConfigurable, fetchNewData)}
          />
        )}

        <ModalActiveFenceComponent
          getFences={() => getAllGeofences(pagination.activePage)}
          onClose={setModalActiveFence}
          geofenceList={newGeofenceList}
          geofenceToUpdate={modalActiveFence!}
        />
      </S.Body>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
}
