import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import type { StoreProps } from 'stores/stores.contracts';
import * as style from './boxs-connectivity.style';
import { StatusAirplaneMode } from './status-airplane-mode';
import { StatusMobileData } from './status-mobile-data';
import { StatusTethering } from './status-tethering';

export function BoxMobileData() {
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const dispatch = useDispatch();

  const [mobileDataActive, setMobileDataActive] = useState(false);
  const [tetheringActive, setTetheringActive] = useState(false);
  const [allowAirplaneMode, setAllowAirplaneMode] = useState(false);

  const [initialPolicy, setInitialPolicy] = useState({});

  function handleChangeValues(value: boolean, key: string) {
    switch (key) {
      case 'mobileData':
        setMobileDataActive(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { mobileNetworksConfigDisabled: value } });
        break;
      case 'tethering':
        setTetheringActive(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { tetheringConfigDisabled: value } });
        break;
      case 'airplaneMode':
        setAllowAirplaneMode(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { allowAirplaneMode: value } });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initialPolicy)) {
      setMobileDataActive(policy.mobileNetworksConfigDisabled);
      setTetheringActive(policy.tetheringConfigDisabled);
      setAllowAirplaneMode(policy.allowAirplaneMode);
      setInitialPolicy(policy);
    }
  }, [policy]);

  return (
    <style.Card title="" textAlign="left" className="card">
      <style.BoxMobileDataBoxMain>
        <style.TitleBox>Dados móveis</style.TitleBox>
        <style.ActivationStatusSection className="activation-status-section">
          <StatusMobileData active={mobileDataActive} onChange={(value: boolean) => handleChangeValues(value, 'mobileData')} />
          <StatusTethering active={tetheringActive} onChange={(value: boolean) => handleChangeValues(value, 'tethering')} />
        </style.ActivationStatusSection>
        <style.AirplaneActivationStatusSection className="activation-status-section">
          <StatusAirplaneMode active={allowAirplaneMode} onChange={(value: boolean) => handleChangeValues(value, 'airplaneMode')} />
        </style.AirplaneActivationStatusSection>
      </style.BoxMobileDataBoxMain>
    </style.Card>
  );
}
