import { useMemo, type ReactElement, useState, useEffect } from 'react';
import * as S from './WorstInstalattionAppsComponent.styles';
import { DashboardCardComponent } from '../../DashboardCard/DashboardCardComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import type { SimpleStatistics } from 'modules/operational/pages/dashboard/DashboardPage.contracts';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';

export const WorstInstalattionAppsComponent = (): ReactElement => {
  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const servicePulsusHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);

  const [worstGroups, setWorstGroups] = useState<SimpleStatistics[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getWorstGroups = async () => {
    try {
      const worstGroupsLocal = await servicePulsusHagalaz.getDashboardWorstGroups();
      setWorstGroups(worstGroupsLocal);
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getWorstGroups();
  }, []);

  return (
    <DashboardCardComponent isLoading={isLoading} title={t('dashboards.worstInstalled.title')}>
      {!isLoading &&
        (worstGroups.length === 0 ? (
          <p>{t('dashboards.lessInstalled.empty')}</p>
        ) : (
          worstGroups.map((item) => {
            return (
              <S.Bar key={item.label + item.ratio}>
                <S.Name>{item.label}</S.Name>
                <S.Percentage>{Math.round(item.ratio)}%</S.Percentage>
              </S.Bar>
            );
          })
        ))}
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </DashboardCardComponent>
  );
};
