import { HelperAuthentication } from 'helpers';
import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import { PageGeofence } from 'modules/management/pages';

import { useLocation, useNavigate } from 'react-router';
import { ConstantLanguages } from 'utilities';

export function EmbeddedGeofence() {
  const query = useQuery();
  const history = useNavigate();
  const location = useLocation();

  const token = query.get('token');
  const language = query.get('language') || ConstantLanguages.PT_BR;

  i18next.changeLanguage(language);

  if (token) {
    HelperAuthentication.login(token);
    query.delete('token');
    history(`${location.pathname}?${query.toString()}`);
  }

  return <PageGeofence isEmbedded />;
}
