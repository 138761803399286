import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { IconWorkProfile, IconMobileDevice } from 'icons';
import type { StoreProps } from 'stores/stores.contracts';
import { GeneralCardGroup } from './general-card-group';
import * as style from './general-card-settings.style';

export function GeneralCardSettings() {
  const dispatch = useDispatch();
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const [initial_policy, set_initial_policy] = useState(false);

  const [cameraDisabled, setCameraDisabled] = useState(false);
  const [screenCaptureDisabled, setScreenCaptureDisabled] = useState(false);
  const [maxDaysWithWorkOff, setMaxDaysWithWorkOff] = useState({
    active: false,
    value: 3,
  });
  const [personalPlayStoreManagement, setPersonalPlayStoreManagement] = useState({
    active: false,
    personalApplications: [],
    personalPlayStoreMode: '',
  });

  function handleChangeBoolean(value: boolean, key: string) {
    switch (key) {
      case 'deviceCamera':
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { personalUsagePolicies: { ...policy.personalUsagePolicies, cameraDisabled: value } } });
        break;
      case 'screenshot':
        dispatch({
          type: 'SET_POLICY_CHANGES',
          payload: { personalUsagePolicies: { ...policy.personalUsagePolicies, screenCaptureDisabled: value } },
        });
        break;
      case 'maxDaysWithWorkOffActive':
        dispatch({
          type: 'SET_POLICY_CHANGES',
          payload: {
            personalUsagePolicies: {
              ...policy.personalUsagePolicies,
              maxDaysWithWorkOff: { ...policy?.personalUsagePolicies?.maxDaysWithWorkOff, active: value },
            },
          },
        });
        break;
      default:
        break;
    }
  }

  function handleChangeNumber(value: number, key: string) {
    switch (key) {
      case 'maxDaysWithWorkOffValue':
        dispatch({
          type: 'SET_POLICY_CHANGES',
          payload: {
            personalUsagePolicies: {
              ...policy.personalUsagePolicies,
              maxDaysWithWorkOff: { ...policy?.personalUsagePolicies?.maxDaysWithWorkOff, value },
            },
          },
        });
        break;
      default:
        break;
    }
  }

  function handleChangePlayStoreManagement(value, key: string) {
    switch (key) {
      case 'active':
        dispatch({
          type: 'SET_POLICY_CHANGES',
          payload: {
            personalUsagePolicies: {
              ...policy.personalUsagePolicies,
              personalPlayStoreManagement: { ...policy?.personalUsagePolicies?.personalPlayStoreManagement, active: value },
            },
          },
        });
        break;
      case 'apps':
        dispatch({
          type: 'SET_POLICY_CHANGES',
          payload: {
            personalUsagePolicies: {
              ...policy.personalUsagePolicies,
              personalPlayStoreManagement: { ...policy?.personalUsagePolicies?.personalPlayStoreManagement, personalApplications: [...value] },
            },
          },
        });
        break;
      case 'mode':
        dispatch({
          type: 'SET_POLICY_CHANGES',
          payload: {
            personalUsagePolicies: {
              ...policy.personalUsagePolicies,
              personalPlayStoreManagement: { ...policy?.personalUsagePolicies?.personalPlayStoreManagement, personalPlayStoreMode: value },
            },
          },
        });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initial_policy)) {
      if (policy.personalUsagePolicies && Object.keys(policy.personalUsagePolicies).length > 0) {
        setCameraDisabled(policy?.personalUsagePolicies?.cameraDisabled);
        setScreenCaptureDisabled(policy?.personalUsagePolicies?.screenCaptureDisabled);
        setMaxDaysWithWorkOff(policy?.personalUsagePolicies?.maxDaysWithWorkOff);
        setPersonalPlayStoreManagement(policy?.personalUsagePolicies?.personalPlayStoreManagement);
      }
      set_initial_policy(policy);
    }
  }, [policy]);

  return (
    <style.Container>
      <style.Header>
        <style.CustomIcon>
          <IconMobileDevice width="30px" heigth="18px" color="white" />
          <IconWorkProfile className="absoluteIcon" width={13} height={12} fill="#1483FF" />
        </style.CustomIcon>
        <style.Title>Fully Managed with a Work Profile</style.Title>
      </style.Header>
      <GeneralCardGroup
        cameraDisabled={cameraDisabled}
        screenCaptureDisabled={screenCaptureDisabled}
        maxDaysWithWorkOff={maxDaysWithWorkOff}
        personalPlayStoreManagement={personalPlayStoreManagement}
        onChangeBoolean={(value, key) => handleChangeBoolean(value, key)}
        onChangeNumber={(value, key) => handleChangeNumber(value, key)}
        handleChangePlayStoreManagement={(value, key) => handleChangePlayStoreManagement(value, key)}
      />
    </style.Container>
  );
}
