import { EuiTextColor } from '@elastic/eui';
import { mdiAndroid, mdiApple } from '@mdi/js';
import Icon from '@mdi/react';
import { ColoredChromeOSIcon } from 'icons';
import type { OperationalSystemPopupProps } from './enroll-popups.contracts';
import * as style from './operational-system-popup.style';

export function OperationalSystemPopup(props: OperationalSystemPopupProps) {
  const { onSelect } = props;
  const AndroidDescText = "Smartphones, Tablets, TV's, entre outros.";
  return (
    <style.Container>
      <style.Header>
        <style.Title>Adicionar dispositivos</style.Title>
        <style.SubTitle>Escolha o sistema operacional que você deseja gerenciar:</style.SubTitle>
      </style.Header>
      <style.Body>
        <style.Item>
          <style.Card className="actived" onClick={() => onSelect('android')}>
            <style.Icon>
              {/* #3DDB85 #656565 */}
              <Icon path={mdiAndroid} color="#3DDB85" size="90px" />
            </style.Icon>
            <style.Text>Android</style.Text>
          </style.Card>
          <style.Description>
            <EuiTextColor>Dispositivos como </EuiTextColor>
            <EuiTextColor className="bold">{AndroidDescText}</EuiTextColor>
          </style.Description>
        </style.Item>
        <style.Item>
          <style.Card className="disabled">
            <style.Icon>
              <Icon path={mdiApple} color="#656565" size="80px" />
            </style.Icon>
            <style.Text>iOS</style.Text>
          </style.Card>
          <style.Description>
            <EuiTextColor>Dispositivos como</EuiTextColor>
            <br />
            <EuiTextColor className="bold">iPhones e iPads.</EuiTextColor>
          </style.Description>
        </style.Item>
        <style.Item>
          <style.Card className="actived" onClick={() => onSelect('chrome')}>
            <style.Icon>
              <ColoredChromeOSIcon height="85px" width="61px" />
            </style.Icon>
            <style.Text>ChromeOS</style.Text>
          </style.Card>
          <style.Description>
            <EuiTextColor>Dispositivos como </EuiTextColor>
            <EuiTextColor className="bold">ChromeBooks, ChromeBases ou ChromeBoxes.</EuiTextColor>
          </style.Description>
        </style.Item>
      </style.Body>
    </style.Container>
  );
}
