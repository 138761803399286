import { IconWifiInCicle } from 'icons';
import type { StatusWifiProps } from './page-connectivity.contracts';
import * as style from './page-connectivity.style';

export function StatusWifi(props: StatusWifiProps) {
  const { active, onChange } = props;
  const handleChange = (value: boolean) => {
    onChange(value);
  };

  return (
    <style.SettingsStatusSection is_on={active} className="status_section_container">
      <div className="icon_status_container">
        <section className="left_section_status">
          <button type="button">
            <IconWifiInCicle elipse_fill={active ? '#30A8FF' : '#D6D6D6'} />
            {active ? 'Ligado' : 'Desligado'}
          </button>
        </section>
        <section className="right_section_status">
          <h4>Status de Wifi</h4>
          <p style={{ width: '110px' }}>Disponível para todos os dispositivos</p>
        </section>
      </div>
      <div className="options_container wifi">
        <button className={active ? 'active' : ''} type="button" onClick={() => handleChange(true)}>
          Ligado
        </button>
        <button type="button" className={active ? '' : 'active'} onClick={() => handleChange(false)}>
          Desligado
        </button>
      </div>
    </style.SettingsStatusSection>
  );
}
