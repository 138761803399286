import { EuiButton, EuiButtonEmpty, EuiFieldPassword, EuiFormRow } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const WrapperContent = styled.form`
  padding: 36px 48px;
  width: 512px;

  .information {
    display: flex;
    gap: 24px;
    flex-direction: column;
    align-items: center;
    padding-bottom: 48px;

    h1 {
      font-size: 26px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0px;
      color: ${ConstantColors.COMOROS};
    }

    p {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: #474747;
    }
  }

  label {
    color: ${ConstantColors.ITALY};
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
`;

export const StyledButton = styled(EuiButton)`
  background: ${ConstantColors.COMOROS} !important;
  border-color: ${ConstantColors.COMOROS} !important;
  color: #fff;
  width: 192px;
  &:disabled {
    color: #fff !important;
  }
`;

export const StyledButtonEmpty = styled(EuiButtonEmpty)`
  color: ${ConstantColors.CHILE};
`;

export const PasswordField = styled.div`
  display: flex;
  align-items: center;
`;

export const InputPassword = styled(EuiFieldPassword)`
  border-right: none !important;
  border-radius: 8px 0 0 8px;
  box-shadow: none;
  border: 1px solid black;
  border-right: none;
`;

export const FormRowPassword = styled(EuiFormRow)`
  max-width: 100% !important;
  margin-bottom: 48px;
`;
