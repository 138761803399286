import type { IntersectionObserverComponentProps } from './IntersectionObserverComponent.contracts';
import { useEffect, useRef, type ReactElement } from 'react';

export const IntersectionObserverComponent = ({ children, action, state }: IntersectionObserverComponentProps): ReactElement => {
  const observer = useRef();

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(
      (entries) => {
        if (entries.some((entry) => entry.isIntersecting)) {
          action();
        }
      },
      {
        root: null,
        rootMargin: '0px',
      }
    );
    intersectionObserver.observe(observer.current!);

    return () => intersectionObserver.disconnect();
  }, [state]);

  return (
    <>
      {children}
      <div ref={observer as any} />
    </>
  );
};
