type IconProps = {
  width?: number | string;
  height?: number | string;
  fill?: string;
};

export function IconChromeOS({ width, height, fill }: IconProps) {
  return (
    <svg width={width || 14} height={height || 14} viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_4393_290083)">
        <path
          d="M4.38477 7.00084C4.38477 8.44334 5.55833 9.61682 7.00088 9.61682C8.44334 9.61682 9.61682 8.4433 9.61682 7.00084C9.61682 5.55833 8.44334 4.38477 7.00088 4.38477C5.55833 4.38477 4.38477 5.55833 4.38477 7.00084Z"
          fill={fill || 'black'}
        />
        <path
          d="M13.0231 3.54677C13.0434 3.51092 13.0431 3.4669 13.022 3.43142C12.4127 2.40515 11.5455 1.54551 10.5143 0.945436C9.4514 0.326911 8.23588 0 6.99924 0C4.85908 0 2.86456 0.960446 1.52708 2.63508C1.49756 2.67198 1.49371 2.72316 1.51735 2.76406L3.4879 6.17708C3.50856 6.21288 3.54666 6.23445 3.58723 6.23445C3.59238 6.23445 3.59761 6.23409 3.60279 6.2334C3.64897 6.22711 3.68675 6.19351 3.69841 6.14839C4.08697 4.64286 5.44432 3.59139 6.99924 3.59139C7.09573 3.59139 7.19538 3.5958 7.29554 3.60433C7.29879 3.60466 7.3021 3.60479 7.30536 3.60479H12.9233C12.9646 3.60479 13.0027 3.58267 13.0231 3.54677Z"
          fill={fill || 'black'}
        />
        <path
          d="M8.03439 10.3246C8.00589 10.2878 7.95806 10.2719 7.91294 10.2844C7.61655 10.367 7.30942 10.4088 7 10.4088C5.68533 10.4088 4.47367 9.63773 3.91307 8.44447C3.91169 8.44153 3.91018 8.43868 3.90857 8.43584L1.09755 3.56714C1.07708 3.53157 1.03917 3.50977 0.998177 3.50977C0.997993 3.50977 0.997764 3.50977 0.99758 3.50977C0.956361 3.51 0.9184 3.53226 0.898203 3.56815C0.310616 4.61081 0 5.7976 0 7.00022C0 8.68733 0.608656 10.317 1.71383 11.589C2.80803 12.8483 4.31443 13.677 5.95565 13.9226C5.96134 13.9235 5.96703 13.9238 5.97268 13.9238C6.01321 13.9238 6.05131 13.9023 6.07201 13.8665L8.04307 10.4524C8.06639 10.412 8.06294 10.3615 8.03439 10.3246Z"
          fill={fill || 'black'}
        />
        <path
          d="M13.4108 4.37109H9.46447C9.41784 4.37109 9.37584 4.39932 9.35826 4.44247C9.34063 4.48562 9.35087 4.53519 9.38415 4.56778C10.0444 5.21509 10.4081 6.07905 10.4081 7.00057C10.4081 7.69818 10.1976 8.36954 9.79928 8.94212C9.79744 8.94478 9.79574 8.94749 9.79414 8.95029L6.97826 13.8274C6.95765 13.8631 6.95778 13.9072 6.97858 13.9428C6.99919 13.978 7.03692 13.9996 7.07763 13.9996C7.07805 13.9996 7.07851 13.9996 7.07892 13.9996C8.93197 13.9789 10.6709 13.2417 11.9752 11.9238C13.2806 10.6048 13.9995 8.85637 13.9995 7.00057C13.9995 6.11848 13.8374 5.25833 13.5176 4.44394C13.5004 4.39997 13.458 4.37109 13.4108 4.37109Z"
          fill={fill || 'black'}
        />
      </g>
    </svg>
  );
}
