import { EuiFieldSearch } from '@elastic/eui';
import styled from 'styled-components';

export const CustomSearchField = styled(EuiFieldSearch)`
  &.euiFieldSearch {
    background: #fbfcfd;
    border-radius: 6px;
  }
  &.euiFieldSearch:focus {
    border: 1px solid rgba(19, 34, 149, 0.1);
  }
`;
