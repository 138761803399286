import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.div`
  position: relative;
  background: white !important;
  width: 1000px;
  border-radius: 9px;
  overflow: hidden;

  .fullyManaged .item .euiSwitch__body {
    background-color: #3ddb85 !important;
    color: white;
  }

  .fullyManaged .item .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
    background-color: ${ConstantColors.ENGLAND}!important;
    opacity: 0.8;
  }
`;

export const Header = styled.div`
  padding: 30px 30px 0 30px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const CustomIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3ddb85;
  width: 30px;
  height: 30px;
  border-radius: 6px;
  .absoluteIcon {
    position: absolute;
    margin-left: 25px;
    margin-top: 22px;
  }
`;

export const Title = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  color: #636363;
`;
