import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { OperationalDefaultRoutes } from './OperationalRoutes';

export const OperationalRoutes: RouteObject[] = [
  {
    path: 'operational',
    element: <Outlet />,
    children: [...OperationalDefaultRoutes],
  },
];
