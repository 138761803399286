import styled from 'styled-components';

export const Container = styled.div<{ row: boolean }>`
  display: flex;
  flex-direction: ${({ row }) => (row ? 'row' : 'column')};
  gap: 5px;
  cursor: pointer;
  input {
    cursor: pointer;
  }
`;

export const RadioButton = styled.label`
  display: flex;
  gap: 5px;
  margin-right: 12px;
  cursor: pointer;
  p {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    color: #8e8e8e;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    border: 1.6px solid #8e8e8e;
    border-radius: 50%;
    outline: none;
    cursor: pointer;
    background-color: #fff;
    margin-top: 4px;
  }

  input[type='radio']:checked {
    background-color: #fff;
  }
  input[type='radio']:disabled:before {
    opacity: 0.2;
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    margin: auto;
    position: relative;
    top: 2.3px;
  }
  input[type='radio']:checked::before {
    content: '';
    display: block;
    width: 9px;
    height: 9px;
    border-radius: 100%;
    background-color: #ff4c0c;
    margin: auto;
    position: relative;
    top: 2.3px;
  }
`;
