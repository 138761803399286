import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  min-height: 500px;
`;

export const TwoCollumnSection = styled.section`
  display: grid;
  grid-template-columns: 1fr 3fr;
  gap: 30px;
`;

export const MapContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 560px;
`;

export const TabsContainer = styled.div`
  height: 100%;
`;
