import styled, { css } from 'styled-components';
import { EuiButtonEmpty, EuiContextMenu, EuiContextMenuPanel, EuiTabs } from '@elastic/eui';
import { ConstantColors } from 'utilities';
import type { CertificateBadgeProps } from '../page-certificate.contracts';

export const TabsContainer = styled.nav`
  width: 100%;
  margin-top: 30px;

  .certificates-list-popover {
    margin-right: 0;
    margin-left: auto;
  }
`;

export const TabsSelector = styled(EuiTabs)`
  button.euiTab {
    background-color: transparent !important;

    span.euiTab__content {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }

  button.euiTab.euiTab-isSelected {
    color: ${({ theme }) => theme.main} !important;
    box-shadow: inset 0 -2px 0 ${({ theme }) => theme.main};

    span.euiTab__content {
      color: ${({ theme }) => theme.main} !important;
    }
  }

  span.euiTab__content {
    color: ${ConstantColors.COLOMBIA} !important;
  }
`;

export const TabContent = styled.main`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const CertificateContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 45px;
  width: 100%;

  .switch-container {
    p {
      margin-top: -7px;
      font-size: 12px;
      color: ${ConstantColors.FINLAND};
    }

    button {
      font-size: 14px;
      color: ${ConstantColors.CHILE};
      margin-bottom: 5px;
    }
  }
`;

export const CertificateBadgeContainer = styled.div`
  display: flex;
  gap: 15px;
  align-items: flex-start;
  margin: 0 0 0 auto;
`;

export const Badge = styled.div<CertificateBadgeProps>`
  ${({ isAuthority }) => css`
    color: ${isAuthority ? ConstantColors.CHILE : ConstantColors.JAPAN};
    font-size: 12px;
    font-weight: 500;
    border: 1px solid ${isAuthority ? ConstantColors.CHILE : ConstantColors.JAPAN};
    border-radius: 3px;
    padding: 2px 8px;
  `}
`;

export const PrivateKey = styled.small`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: ${ConstantColors.CUBA};
  margin-top: 30px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const OptionsButton = styled(EuiButtonEmpty)`
  font-size: 14px;
  background-color: transparent !important;
`;

export const ContextMenuApps = styled(EuiContextMenuPanel)`
  .title {
    padding: 15px 15px;
    border-bottom: 0.5px solid ${ConstantColors.FINLAND};
  }

  ul {
    padding: 15px;
    list-style: disc inside none;
  }

  li {
    margin-top: 5px;
  }
`;

export const ContextMenu = styled(EuiContextMenu)`
  .euiContextMenuPanelTitle {
    font-size: 12px !important;
    color: ${ConstantColors.COLOMBIA} !important;
    height: 32px;
    padding: 10px;
    line-height: 18px;
  }

  .euiContextMenuItem {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    height: 40px;
  }
`;

export const SeparationLine = styled.hr`
  margin: 30px auto;
  width: 100%;
  border-top: 1px solid ${ConstantColors.DENMARK};
`;

export const TextPrimary = styled.p`
  color: ${ConstantColors.ITALY};
  line-height: 16px;
  font-weight: 500;
  max-width: 555px;
  margin-top: 20px;
`;
