import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import { PageChromeOSDevice } from 'modules/chromeOS/pages/index';
import { useEffect, useState } from 'react';
import { ConstantLanguages } from 'utilities';
import type { EventTypes, DeviceEmbeddedData } from './EmbeddedChromeOSDevice.contracts';

export function EmbeddedChromeOSDevice() {
  const [embeddedData, setEmbeddedData] = useState<DeviceEmbeddedData>();

  const query = useQuery();
  const language = query.get('language') || ConstantLanguages.PT_BR;
  i18next.changeLanguage(language);

  function onTriggerEvent(type: EventTypes, data?: string) {
    switch (type) {
      case 'ready':
        window.parent.postMessage({ type: 'ON_READY' }, process.env.REACT_APP_RUBY_LINK!);
        break;
      case 'receive':
        if (data && typeof data === 'string') {
          setEmbeddedData(JSON.parse(data));
        }
        break;
      case 'saveSuccess':
        window.parent.postMessage({ type: 'ON_SAVE_SUCCESS' }, process.env.REACT_APP_RUBY_LINK!);
        break;
      case 'saveError':
        window.parent.postMessage({ type: 'ON_SAVE_ERROR' }, process.env.REACT_APP_RUBY_LINK!);
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    window.addEventListener('message', ({ data }) => onTriggerEvent('receive', data));
    window.onload = () => onTriggerEvent('ready');
    return () => window.removeEventListener('message', (event) => event);
  });

  return <PageChromeOSDevice embeddedData={embeddedData} embeddedOnEvent={(type: EventTypes) => onTriggerEvent(type)} />;
}
