/* eslint-disable react/jsx-one-expression-per-line */
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import { DialogComponent } from 'components';
import type { EuiSwitchEvent } from '@elastic/eui';
import { EuiButton, EuiButtonEmpty, EuiComboBox, EuiFieldPassword, EuiFormRow, EuiSwitch, EuiTextArea } from '@elastic/eui';
import SoundSignalImg from 'assets/images/sound-signal-img.png';
import { HelperFunctions } from 'helpers';
import RerenderImg from 'assets/images/atention.png';
import RerenderIcons from 'assets/images/atention2.png';
import type { BulkActions } from 'modules/operational/pages/devices/PageDevices.contracts';
import type { Group } from 'entities/group';
import { useTypedTranslation } from 'modules/core/hooks';
import type { BulkActionsModalsProps } from './BulkActionsModalsComponent.contracts';
import { OTAUpdateComponent } from '../OTAUpdate/OTAUpdateComponent';
import * as S from './BulkActionsModalsComponent.style';

export function BulkActionsModals({
  handleAction,
  closeModals,
  openModal,
  groups,
  bulkActionsPasswordError,
  bulkActionsPasswordLoading,
  hasIosSelected,
  hasAndroid14Selected,
}: BulkActionsModalsProps) {
  const { t } = useTypedTranslation<'devices.bulk_modals'>('devices.bulk_modals');

  const [messageText, setMessageText] = useState('');
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [newPinSwitch, setNewPinSwitch] = useState(true);
  const [newPin, setNewPin] = useState('');
  const [newPinHasError, setPinHasError] = useState(false);
  const [newPinError, setNewPinError] = useState('');
  const [selectedGroup, setSelectedGroup] = useState<Group[]>();

  useEffect(() => {
    const passwordActions: BulkActions[] = ['get_phone_number', 'disable_quarantine', 'quarantine_mode', 'maintenance_mode', 'remove'];
    if (passwordActions.includes(openModal)) {
      setPassword('');
      setPasswordError(bulkActionsPasswordError);
    }

    if (openModal === 'send_message') {
      setMessageText('');
    }

    if (openModal === 'lock_device') {
      setNewPin('');
      setPinHasError(false);
      setNewPinSwitch(true);
    }
  }, [openModal, bulkActionsPasswordError]);

  const handlePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  const handleNewPin = (e: ChangeEvent<HTMLInputElement>) => {
    setNewPin(e.target.value);
    setPinHasError(false);

    if (!HelperFunctions.inputHasOnlyNumbers(e.target.value)) {
      setPinHasError(true);
      setNewPinError(t('block_modal.has_letters_error'));
    }
  };

  const handleNewPinSwitch = (e: EuiSwitchEvent) => {
    setNewPinSwitch(e.target.checked);

    if (!e.target.checked) {
      setNewPin('');
      setPinHasError(false);
    }
  };

  const trySaveBlock = () => {
    if (hasAndroid14Selected && newPin.length !== 6) {
      setPinHasError(true);
      setNewPinError(t('block_modal.invalid_pin_android_14'));
    } else if ((newPin.length > 6 || newPin.length < 4) && newPinSwitch && !hasIosSelected) {
      setPinHasError(true);
      setNewPinError(t('block_modal.no_input_error'));
    } else if (!newPinHasError) {
      handleAction('lock_device', newPin);
    }
  };

  const onChangeGroups = (selectedOptions) => {
    setSelectedGroup(selectedOptions);
  };

  // eslint-disable-next-line no-unused-vars
  const renderModalsFooter = (CTAText: string, CTAFunction: (data: unknown) => void, minWidth = '117px') => {
    return (
      <S.ModalsFooter>
        <EuiButtonEmpty onClick={closeModals}>{t('cancel_button')}</EuiButtonEmpty>
        <EuiButton fill onClick={CTAFunction} minWidth={minWidth}>
          {CTAText}
        </EuiButton>
      </S.ModalsFooter>
    );
  };

  return (
    <>
      {/* OTA update */}
      <OTAUpdateComponent open={openModal === 'OTAUpdate'} setOpen={() => closeModals()} />

      {/* RE-REGISTER */}
      <DialogComponent
        width="947px"
        open={openModal === 'renewal'}
        footer={renderModalsFooter(t('sound_signal.confirm'), () => handleAction('renewal'))}
        closeOnClickOutside
        onClose={() => closeModals()}
      >
        <S.SoundSignalContent>
          <S.SoundSignalText>{t('reregister_modal.title')}</S.SoundSignalText>
          <S.ReregisterIcons src={RerenderIcons} alt="Imagem sinal sonoro" />
          <S.ReregisterImg src={RerenderImg} alt="Imagem sinal sonoro" />
        </S.SoundSignalContent>
      </DialogComponent>

      {/* SOUND SIGNAL */}
      <DialogComponent
        width="947px"
        open={openModal === 'find_phone'}
        footer={renderModalsFooter(t('sound_signal.confirm'), () => handleAction('find_phone'))}
        closeOnClickOutside
        onClose={() => closeModals()}
      >
        <S.SoundSignalContent>
          <S.SoundSignalText>{t('sound_signal.phrase')}</S.SoundSignalText>
          <S.SoundSignalImg src={SoundSignalImg} alt="Imagem sinal sonoro" />
        </S.SoundSignalContent>
      </DialogComponent>

      {/* BACKUP_CONTACTS */}
      <DialogComponent
        width="947px"
        open={openModal === 'backup_contacts'}
        footer={renderModalsFooter(t('backup_contacts_modal.confirm'), () => handleAction('backup_contacts'))}
        closeOnClickOutside
        onClose={() => closeModals()}
      >
        <S.SoundSignalContent>
          <S.SoundSignalText>{t('backup_contacts_modal.title')}</S.SoundSignalText>
          <S.ReregisterIcons src={RerenderIcons} alt="Imagem sinal sonoro" />
          <S.ReregisterImg src={RerenderImg} alt="Imagem sinal sonoro" />
        </S.SoundSignalContent>
      </DialogComponent>

      {/* MAINTENANCE */}
      <DialogComponent
        width="947px"
        open={openModal === 'maintenance_mode' || openModal === 'get_phone_number'}
        footer={renderModalsFooter(t('password_modal.confirm'), () => handleAction(openModal, password), '175px')}
        closeOnClickOutside
        onClose={() => closeModals()}
      >
        <S.WarningPasswordContent>
          <S.WarningPasswordLeft />
          <S.WarningPasswordRight>
            <S.Container>
              <S.WarningPasswordFirst>{t('password_modal.phraseFirst')}</S.WarningPasswordFirst>
              <S.WarningPasswordSecond>{t('password_modal.phraseSecond')}</S.WarningPasswordSecond>
            </S.Container>

            <S.WarningPasswordLabel htmlFor="inputPassword">
              {t('password_modal.phraseInput')}
              <EuiFormRow isInvalid={passwordError} error={t('password_error')}>
                <EuiFieldPassword
                  isLoading={bulkActionsPasswordLoading}
                  id="inputPassword"
                  placeholder={t('password_modal.placeholder')}
                  type="dual"
                  value={password}
                  onChange={(e) => handlePassword(e)}
                />
              </EuiFormRow>
            </S.WarningPasswordLabel>
          </S.WarningPasswordRight>
        </S.WarningPasswordContent>
      </DialogComponent>

      {/* EDIT GROUPS */}
      <DialogComponent
        width="553px"
        open={openModal === 'update_group'}
        footer={renderModalsFooter(t('edit_modal.cta_button'), () => handleAction('update_group', selectedGroup ? selectedGroup[0].id : 0))}
        closeOnClickOutside
        onClose={() => closeModals()}
        title={t('edit_modal.title')}
      >
        <S.EditModalContent>
          <EuiComboBox
            singleSelection={{ asPlainText: true }}
            aria-label="groups select"
            placeholder={t('edit_modal.placeholder')}
            options={groups}
            selectedOptions={selectedGroup}
            className="group-select"
            onChange={onChangeGroups}
            isClearable
          />
        </S.EditModalContent>
      </DialogComponent>

      {/* SEND MESSAGEM */}
      <DialogComponent
        width="553px"
        open={openModal === 'send_message'}
        footer={renderModalsFooter(t('message_modal.cta_button'), () => handleAction('send_message', messageText))}
        closeOnClickOutside
        onClose={() => closeModals()}
        title={t('message_modal.title')}
      >
        <S.MessageModalContent>
          <EuiFormRow fullWidth label={t('message_modal.text_label')} helpText={t('message_modal.max_characters')}>
            <EuiTextArea
              fullWidth
              maxLength={1000}
              placeholder={t('message_modal.placeholder')}
              aria-label="Send message"
              value={messageText}
              onChange={(e) => setMessageText(e.target.value)}
            />
          </EuiFormRow>
        </S.MessageModalContent>
      </DialogComponent>

      {/* REMOVE */}
      <DialogComponent
        width="947px"
        open={openModal === 'remove'}
        footer={renderModalsFooter(t('password_modal.confirm'), () => handleAction('remove', password), '175px')}
        closeOnClickOutside
        onClose={() => closeModals()}
      >
        <S.WarningPasswordContent>
          <S.WarningPasswordLeft />
          <S.WarningPasswordRight>
            <S.Container>
              <S.WarningPasswordFirst>{t('password_modal.phraseFirst')}</S.WarningPasswordFirst>
              <S.WarningPasswordSecond>{t('password_modal.phraseSecond')}</S.WarningPasswordSecond>
            </S.Container>

            <S.WarningPasswordLabel htmlFor="inputPassword">
              {t('password_modal.phraseInput')}
              <EuiFormRow isInvalid={passwordError} error={t('password_error')}>
                <EuiFieldPassword
                  isLoading={bulkActionsPasswordLoading}
                  id="inputPassword"
                  placeholder={t('password_modal.placeholder')}
                  type="dual"
                  value={password}
                  onChange={(e) => handlePassword(e)}
                />
              </EuiFormRow>
            </S.WarningPasswordLabel>
          </S.WarningPasswordRight>
        </S.WarningPasswordContent>
      </DialogComponent>

      {/* BLOCK */}
      <DialogComponent
        titleTextAlign="left"
        width="553px"
        height={!hasIosSelected ? '380px' : '300px'}
        open={openModal === 'lock_device'}
        footer={renderModalsFooter(t('block_modal.cta_button'), () => trySaveBlock())}
        closeOnClickOutside
        onClose={() => closeModals()}
        title={t('block_modal.title')}
      >
        <S.BlockModalContent>
          <p>{t('block_modal.text')}</p>
          {!hasIosSelected && (
            <div>
              <EuiSwitch label={t('block_modal.switch_label')} checked={newPinSwitch} onChange={(e) => handleNewPinSwitch(e)} />
              <EuiFormRow fullWidth isInvalid={newPinHasError} error={newPinError}>
                <EuiFieldPassword
                  fullWidth
                  disabled={!newPinSwitch}
                  id="inputPassword"
                  placeholder={t('block_modal.placeholder')}
                  type="dual"
                  value={newPin}
                  onChange={(e) => handleNewPin(e)}
                />
              </EuiFormRow>
            </div>
          )}
        </S.BlockModalContent>
      </DialogComponent>

      {/* QUARANTINE */}
      <DialogComponent
        width="947px"
        open={['disableQuarantine', 'enableQuarantine'].includes(openModal)}
        footer={renderModalsFooter(t('password_modal.confirm'), () => handleAction('quarantine_mode', password), '175px')}
        closeOnClickOutside
        onClose={() => closeModals()}
      >
        <S.WarningPasswordContent>
          <S.WarningPasswordLeft />
          <S.WarningPasswordRight>
            <S.Container>
              <S.WarningPasswordFirst>{t('password_modal.phraseFirst')}</S.WarningPasswordFirst>
              <S.WarningPasswordSecond>{t('password_modal.phraseSecond')}</S.WarningPasswordSecond>
            </S.Container>

            <S.WarningPasswordLabel htmlFor="inputPassword">
              {t('password_modal.phraseInput')}
              <EuiFormRow isInvalid={passwordError} error={t('password_error')}>
                <EuiFieldPassword
                  isLoading={bulkActionsPasswordLoading}
                  id="inputPassword"
                  placeholder={t('password_modal.placeholder')}
                  type="dual"
                  value={password}
                  onChange={(e) => handlePassword(e)}
                />
              </EuiFormRow>
            </S.WarningPasswordLabel>
          </S.WarningPasswordRight>
        </S.WarningPasswordContent>
      </DialogComponent>
    </>
  );
}
