import React from 'react';
import { ConstantColors, ConstantRoutes } from 'utilities';
import { IconAndroid, IconChart, IconIos, IconUsers, IconChromeOS } from 'icons';
import { NavItemTitlePrimary } from 'components/application-main/nav-item-title-primary/nav-item-title-primary';
import { NavItemLink } from 'components/application-main/nav-item-link/nav-item-link';
import { NavItemTitleSecondary } from 'components/application-main/nav-item-title-secondary/nav-item-title-secondary';
import * as style from './nav-list.style';

export function NavList(t) {
  return [
    {
      id: 'management',
      name: t('components.application_main.nav_list.management.title'),
      renderItem: NavItemTitlePrimary,
      icon: (
        <style.IconContainer>
          <IconChart fill={ConstantColors.GERMANY} />
        </style.IconContainer>
      ),
      items: [
        {
          id: 'management-data-consumption',
          name: t('components.application_main.nav_list.management.links.data_consumption'),
          renderItem: NavItemLink,
          to: '/consumo-de-dados',
        },
        {
          id: 'management-pulsus-insights',
          name: t('components.application_main.nav_list.management.links.pulsus_insights'),
          renderItem: NavItemLink,
          to: ConstantRoutes.PULSUS_INSIGHTS.path,
        },
        {
          id: 'management-pulsus-locations',
          name: t('components.application_main.nav_list.management.links.pulsus_routes'),
          renderItem: NavItemLink,
          to: '/management/locations',
        },
        {
          id: 'management-reports',
          name: t('components.application_main.nav_list.management.links.reports'),
          renderItem: NavItemLink,
          to: '/relatorios',
        },
        {
          id: 'sim-management',
          name: t('components.application_main.nav_list.management.links.sim_management'),
          renderItem: NavItemLink,
          to: '/management/sim-management',
        },
      ],
    },
    {
      id: 'operational',
      name: t('components.application_main.nav_list.operational.title'),
      renderItem: NavItemTitlePrimary,
      icon: (
        <style.IconContainer>
          <IconUsers fill={ConstantColors.GERMANY} />
        </style.IconContainer>
      ),
      items: [
        {
          id: 'operational-device-management',
          name: t('components.application_main.nav_list.operational.links.device_management'),
          renderItem: NavItemLink,
          to: '/operational/device-management',
        },
        {
          id: 'operational-groups',
          name: t('components.application_main.nav_list.operational.links.groups'),
          renderItem: NavItemLink,
          to: '/grupos',
        },
        {
          id: 'operational-launcher',
          name: t('components.application_main.nav_list.operational.links.launcher'),
          renderItem: NavItemLink,
          to: '/launcher',
        },
        {
          id: 'operational-applications',
          name: t('components.application_main.nav_list.operational.links.applications'),
          renderItem: NavItemLink,
          to: '/operational/applications ',
        },
        {
          id: 'android',
          name: t('components.application_main.nav_list.android.title'),
          renderItem: NavItemTitleSecondary,
          icon: (
            <style.IconContainer>
              <IconAndroid fill={ConstantColors.GERMANY} />
            </style.IconContainer>
          ),
          items: [
            {
              id: 'android-launcher',
              name: t('components.application_main.nav_list.android.links.launcher'),
              renderItem: NavItemLink,
              to: '/android-launcher',
            },
            {
              id: 'android-configurations',
              name: t('components.application_main.nav_list.android.links.configurations'),
              renderItem: NavItemLink,
              to: '/android-settings',
            },
            {
              id: 'android-files',
              name: t('components.application_main.nav_list.android.links.files'),
              renderItem: NavItemLink,
              to: '/android-arquivos',
            },
            {
              id: 'android-enterprise',
              name: t('components.application_main.nav_list.android.links.enterprise'),
              renderItem: NavItemLink,
              to: '/android-enterprise',
            },
          ],
        },
        {
          id: 'ios',
          name: t('components.application_main.nav_list.ios.title'),
          renderItem: NavItemTitleSecondary,
          icon: (
            <style.IconContainer>
              <IconIos fill={ConstantColors.GERMANY} />
            </style.IconContainer>
          ),
          items: [
            {
              id: 'ios-launcher',
              name: t('components.application_main.nav_list.ios.links.launcher'),
              renderItem: NavItemLink,
              to: '/ios-launcher',
            },
            {
              id: 'ios-configurations',
              name: t('components.application_main.nav_list.ios.links.configurations'),
              renderItem: NavItemLink,
              to: '/ios-configuracoes',
            },
            {
              id: 'ios-certificates',
              name: t('components.application_main.nav_list.ios.links.certificates'),
              renderItem: NavItemLink,
              to: '/ios-certificados',
            },
          ],
        },
        {
          id: 'chromeOS',
          name: t('components.application_main.nav_list.chrome_os.title'),
          renderItem: NavItemTitleSecondary,
          icon: (
            <style.IconContainer>
              <IconChromeOS height={14} width={14} />
            </style.IconContainer>
          ),
          items: [
            {
              id: 'chromeOS-integration',
              name: t('components.application_main.nav_list.chrome_os.links.integration'),
              renderItem: NavItemLink,
              to: '/chromeOS/integration',
            },
            {
              id: 'chromeOS-configuration',
              name: t('components.application_main.nav_list.chrome_os.links.configurations'),
              renderItem: NavItemLink,
              to: '/chromeOS/configuration',
            },
            {
              id: 'chromeOS-report',
              name: t('components.application_main.nav_list.chrome_os.links.report'),
              renderItem: NavItemLink,
              to: '/chromeOS/report',
            },
          ],
        },
      ],
    },
  ];
}
