import type { PasswordRequirementProps } from './embedded-security-settings.contracts';

export enum Tabs {
  DEVICE = 'device',
  WORK_PROFILE = 'work_profile',
}

export type getInformationPhraseProps = {
  prefix?: string;
  sufix?: string;
};

export type EmbeddedSecuritySettingsTabProps = {
  visible: boolean;
  tab: Tabs;
  initialValues: PasswordRequirementProps;
  onChange: (value: PasswordRequirementProps) => void;
};
