import React from 'react';

type IconWifiInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconWifiInCicle({ width, height, elipse_fill, icon_fill }: IconWifiInCicleProps) {
  return (
    <svg width={width || 44} height={height || 45} viewBox="0 0 44 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.24" cy="22.5" rx="21.6783" ry="22.5" fill={elipse_fill || '#30A8FF'} />
      <path
        d="M11.16 19.5525L13.087 21.5525C17.8754 16.5825 25.6411 16.5825 30.4296 21.5525L32.3565 19.5525C26.5082 13.4825 17.018 13.4825 11.16 19.5525ZM18.8678 27.5525L21.7583 30.5525L24.6487 27.5525C23.059 25.8925 20.4672 25.8925 18.8678 27.5525ZM15.0139 23.5525L16.9409 25.5525C19.6001 22.7925 23.9165 22.7925 26.5757 25.5525L28.5026 23.5525C24.7836 19.6925 18.7426 19.6925 15.0139 23.5525Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
