/* eslint-disable react/jsx-no-useless-fragment */
import { useUserContext } from 'modules/core/contexts/user';
import type { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';
import type { FeatureFlagCheckerComponentProps } from './FeatureFlagCheckerComponent.contracts';

export const FeatureFlagCheckerComponent = ({
  featureFlag,
  children,
  redirectTo,
  withoutSuperadmin,
}: FeatureFlagCheckerComponentProps): ReactElement => {
  const { featureFlags, administrator } = useUserContext();

  const hasFeatureFlagAvailable = () => {
    return featureFlags.includes(featureFlag);
  };

  const canAccessFeature = () => {
    if (withoutSuperadmin) {
      return hasFeatureFlagAvailable();
    }

    if (administrator.role === 'SUPER_ADMIN') return true;

    return hasFeatureFlagAvailable();
  };

  const returnRedirectOption = () => {
    return canAccessFeature() ? children : <Navigate to={redirectTo!} replace />;
  };

  const isValidResource = () => {
    return canAccessFeature() ? children : <></>;
  };

  return <>{redirectTo ? returnRedirectOption() : isValidResource()}</>;
};
