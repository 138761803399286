export class DataUsageTableDevices {
  public totalItems: number;
  public devices: DevicesList[];

  constructor(data: DataUsageTableDevices.Server) {
    this.totalItems = data.total_items;
    this.devices = data.page_items.map((item) => ({
      name: item.device_name,
      id: String(item.device_id),
      totalValue: item.average,
      wifiValue: item.wifi_average,
      networkValue: item.mobile_average,
      wifiPercentage: item.wifi_percentage,
      networkPercentage: item.mobile_percentage,
    }));
  }
}

export namespace DataUsageTableDevices {
  export type Server = {
    total_items: number;
    page_items: {
      device_name: string;
      device_id: number;
      average: number;
      mobile_average: number;
      mobile_percentage: number;
      wifi_average: number;
      wifi_percentage: number;
    }[];
  };
}

export type DevicesList = {
  name: string;
  id: string;
  totalValue: number;
  wifiValue: number;
  networkValue: number;
  wifiPercentage: number;
  networkPercentage: number;
};
