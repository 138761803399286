export class Location {
  public id: number;
  public latitude: number;
  public longitude: number;
  public deviceId: number;
  public recentActivity: boolean;
  constructor(data: Location.Server) {
    this.id = data.id;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.deviceId = data.device_id;
    this.recentActivity = data.recent_activity;
  }
}

export namespace Location {
  export type Server = {
    id: number;
    latitude: number;
    longitude: number;
    device_id: number;
    recent_activity: boolean;
  };
}
