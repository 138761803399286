import { ComponentBreadcrumb, ComponentToast, CustomMentuTab, FilterFlag, SaveBar, ComponentTabPanel } from 'components';
import { mdiEarth, mdiMemory, mdiTextBoxOutline, mdiWifi } from '@mdi/js';
import { useEffect, useMemo, useState } from 'react';
import { ServicePulsusTyr } from 'services/ServicePulsusTyr';
import type { Toast } from 'components/toast/component-toast.contracts';
import { useSelector, useDispatch } from 'react-redux';
import type { PolicyBody, StoreProps } from 'stores/stores.contracts';
import { useTranslation } from 'react-i18next';
import { ChromeBreadcrumbs } from '../chromeBreadcrumbs';
import * as style from './page-chromeOS-configuration.style';
import { ChromeHardwareTab, ChromeNetworkTab, ChromeNavigationTab } from './tabs';
import type { ChromeOSConfigurationProps } from './page-chromeOS-configuration.contracts';

export function PageChromeOSConfiguration({ embeddedData, embeddedOnEvent }: ChromeOSConfigurationProps) {
  const dispatch = useDispatch();
  const chromeStore = useSelector((store: StoreProps) => store?.chromeOS?.configuration);
  const [activeTab, setActiveTab] = useState('general');
  const [activeFilter, setActiveFilter] = useState('all');
  const [hasChange, setHasChange] = useState(false);
  const [toast, setToast] = useState<Toast[]>([]);
  const { t } = useTranslation();

  const TyrServices = useMemo(() => new ServicePulsusTyr(), []);

  const [policy, setPolicy] = useState<PolicyBody>({
    device_policies: {
      allowBluetooth: false,
    },
    user_policies: {
      audioCaptureAllowed: false,
      audioOutputAllowed: false,
      videoCaptureAllowed: false,
      externalStorageDevices: 'EXTERNAL_STORAGE_ENUM_DISALLOW',
      defaultGeolocationSetting: 'GEOLOCATION_SETTING_ENUM_ALLOW_GEOLOCATION',
      defaultWebUsbGuardSetting: 'DEFAULT_WEB_USB_GUARD_SETTING_ENUM_ASK_WEB_USB',
      urlBlocking: { mode: 'Allowlist', urls: [] },
    },
    network: {
      mode: 'neutral',
      networks: [],
    },
  });

  async function getPolicy() {
    if (embeddedData) {
      const data = await TyrServices.getPolicy(embeddedData.customerId, embeddedData.orgUnitId);
      if (data) {
        dispatch({ type: 'CHROMEOS_SET_CONFIGURATION_POLICY', payload: data });
      }
    }
  }

  const saveChanges = async () => {
    if (embeddedData) {
      const resp = await TyrServices.setPolicy(policy, embeddedData.customerId, embeddedData.orgUnitId);
      if (resp) {
        dispatch({ type: 'CHROMEOS_SAVE_CONFIGURATION_POLICY', payload: policy });
        setHasChange(false);
        if (embeddedOnEvent) {
          embeddedOnEvent('saveSuccess');
        } else {
          setToast([
            {
              title: t('pages.pulsus_chrome_os_configuration.success_message'),
              color: 'success',
              iconType: 'faceHappy',
              id: '1',
              text: '',
            },
          ]);
        }
      } else if (embeddedOnEvent) {
        embeddedOnEvent('saveError');
      } else {
        setToast([
          {
            title: t('pages.pulsus_chrome_os_configuration.error_message'),
            color: 'danger',
            iconType: 'faceSad',
            id: '2',
            text: t('pages.pulsus_chrome_os_configuration.error_message_description'),
          },
        ]);
      }
    }
  };

  function discartChanges() {
    dispatch({ type: 'CHROMEOS_RESTORE_CONFIGURATION_POLICY' });
  }

  useEffect(() => {
    getPolicy();
  }, [embeddedData]);

  useEffect(() => {
    if (chromeStore?.policy && Object.keys(chromeStore).length > 0) {
      setPolicy(chromeStore.policy);
      setHasChange(!!chromeStore?.hasChanges);
    }
  }, [chromeStore.policy]);

  return (
    <>
      {JSON.stringify(embeddedData)}
      {!embeddedOnEvent && <ComponentBreadcrumb items={ChromeBreadcrumbs.PULSUS_CHROME_OS_CONFIGURATION.breadcrumbs} />}
      <style.Container>
        <style.HeaderContainer>
          <style.HeaderTitle>{t('pages.pulsus_chrome_os_configuration.title')}</style.HeaderTitle>
          <style.HeaderDescription>{t('pages.pulsus_chrome_os_configuration.header_description')}</style.HeaderDescription>
          <style.MenuBox>
            <CustomMentuTab
              isSelected={activeTab === 'general'}
              onClick={() => setActiveTab('general')}
              title={t('pages.pulsus_chrome_os_configuration.menu_general')}
              icon={mdiTextBoxOutline}
            />
            <CustomMentuTab
              isSelected={activeTab === 'hardware'}
              onClick={() => setActiveTab('hardware')}
              title={t('pages.pulsus_chrome_os_configuration.hardware')}
              icon={mdiMemory}
            />
            <CustomMentuTab
              isSelected={activeTab === 'network'}
              onClick={() => setActiveTab('network')}
              title={t('pages.pulsus_chrome_os_configuration.network')}
              icon={mdiWifi}
            />
            <CustomMentuTab
              isSelected={activeTab === 'navegation'}
              onClick={() => setActiveTab('navegation')}
              title={t('pages.pulsus_chrome_os_configuration.navegation')}
              icon={mdiEarth}
            />
          </style.MenuBox>
          <style.FlagsBox>
            <FilterFlag
              title={t('pages.pulsus_chrome_os_configuration.flag_all_settings')}
              isSelected={activeFilter === 'all'}
              onClick={() => setActiveFilter('all')}
            />
          </style.FlagsBox>
        </style.HeaderContainer>
        <style.BodyContainer>
          <style.Side className={activeTab === 'general' ? 'half' : ''}>
            <ComponentTabPanel tabKey="hardware" genericKey="general" half={activeTab === 'general'} value={activeTab}>
              <ChromeHardwareTab />
            </ComponentTabPanel>
            <ComponentTabPanel tabKey="navegation" genericKey="general" half={activeTab === 'general'} value={activeTab}>
              <ChromeNavigationTab emmitToast={(toastData: Toast[]) => setToast(toastData)} />
            </ComponentTabPanel>
          </style.Side>
          <style.Side className={activeTab === 'general' ? 'half' : ''}>
            <ComponentTabPanel tabKey="network" genericKey="general" half={activeTab === 'general'} value={activeTab}>
              <ChromeNetworkTab emmitToast={(toastData: Toast[]) => setToast(toastData)} />
            </ComponentTabPanel>
          </style.Side>
        </style.BodyContainer>
        <SaveBar animation={hasChange} onCancel={() => discartChanges()} onConfirm={() => saveChanges()} />
        <ComponentToast toasts={toast} toastLifeTimeMs={5000} side="right" dismissToast={() => setToast([])} />
      </style.Container>
    </>
  );
}
