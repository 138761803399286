import Icon from '@mdi/react';
import * as style from './component-menu-tab.style';
import type { MenuTabProps } from './component-menu-tab.contracts';

export function CustomMentuTab(props: MenuTabProps) {
  const { icon, title, isSelected, onClick } = props;
  return (
    <style.Container onClick={() => onClick()} isSelected={isSelected}>
      <style.Content>
        <style.IconBox>
          <Icon path={icon} size={1} />
        </style.IconBox>
        <style.Title className="text">{title}</style.Title>
      </style.Content>
    </style.Container>
  );
}
