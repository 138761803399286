import type { ReactElement, ReactNode } from 'react';
import { useEffect, useReducer, useState } from 'react';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';

import { useQuery } from 'hooks/use-query';
import { HelperAuthentication } from 'helpers';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import type { Geofence } from 'modules/management/entities';
import { geofenceReducer, initialState } from './reducer';
import { setFieldsState, setGeofenceAreas, setChangedFences } from './actions';
import type { AdminEmail, ChangedFences, GeofenceFieldsProps, TriggerValuesAdaptProps } from './contracts';
import { TriggerStatus } from './contracts';
import { GeofenceContext } from './contextDeclare';
import { useUserContext } from 'modules/core/contexts/user';

export const GeofenceProvider = ({ children }: { children: ReactNode }): ReactElement => {
  const [state, dispatch] = useReducer(geofenceReducer, initialState);
  const [toast, setToast] = useState<Toast[]>([]);
  const [pagination, setPagination] = useState({ activePage: 0, totalPages: 1 });
  const [serverTrigger, setServerTrigger] = useState<TriggerValuesAdaptProps | null>(null);
  const { t } = useTranslation('translation', { keyPrefix: 'geofence.table_fences.errors' });
  const { administrator } = useUserContext();

  const [loading, setLoading] = useState<boolean>(false);
  const query = useQuery();

  const tenantId = HelperAuthentication.decodingTenantId();
  const groupPolicy = query.get('group_policy');

  const setFields = (fields: GeofenceFieldsProps) => {
    dispatch(setFieldsState(fields));
  };

  const handleChangeSwitch = (switchValue: boolean, index: number): void => {
    state.geofences[index].status = switchValue;
    state.geofences[index].hasChanged = true;
    dispatch(setGeofenceAreas([...state.geofences]));
  };

  const adaptTriggerValues = (blockDevice: boolean, trigger: 0 | 1 | 2) => {
    return {
      blockDevice,
      fenceIn: trigger === TriggerStatus.ON_ENTER,
      fenceOut: trigger === TriggerStatus.ON_EXIT,
      both: trigger === TriggerStatus.BOTH,
    };
  };

  const getConfigAndFences = async (index: number): Promise<void> => {
    try {
      setLoading(true);

      const data = await ServicePulsusValquiria.getFencesByGroupPolicyId(tenantId, Number(groupPolicy), index + 1);

      const serverTriggerAdapt = adaptTriggerValues(data?.groupPolicy.block_device, data?.groupPolicy?.trigger);

      setPagination({ activePage: index, totalPages: data.totalPages });

      const adapteeFieldsState = adaptTriggerValues(data?.groupPolicy?.block_device ?? true, data?.groupPolicy?.trigger ?? 1);

      const soundAlertActionLogic = data?.groupPolicy.sound_alert_action === 0 ? 'ONE' : 'CONTINUED';

      const emailString = data?.groupPolicy.admin_emails;

      let adminEmails: AdminEmail[] = [];

      if (emailString) {
        adminEmails = emailString.split(',').map((email) => ({ label: email.trim() }));
      }

      setServerTrigger(
        data?.groupPolicy
          ? {
              ...serverTriggerAdapt,
              emailAlert: data?.groupPolicy.email_alert,
              emailAlertLanguage: data?.groupPolicy.email_alert_language,
              adminEmails: emailString ? adminEmails : [{ label: administrator.email }, ...adminEmails],
              soundAlert: data?.groupPolicy.sound_alert,
              soundAlertAction: soundAlertActionLogic,
            }
          : null
      );

      dispatch(
        setFieldsState({
          ...adapteeFieldsState,
          emailAlert: data?.groupPolicy.email_alert,
          emailAlertLanguage: data?.groupPolicy.email_alert_language,
          adminEmails: emailString ? adminEmails : [{ label: administrator.email }, ...adminEmails],
          soundAlert: data?.groupPolicy.sound_alert,
          soundAlertAction: soundAlertActionLogic,
        })
      );

      const fencesObj = formatChangedFences();

      dispatch(setChangedFences({ ...fencesObj }));
      dispatch(setGeofenceAreas(formatDataWithPreviousChangedFences(data.fences)));
    } catch (err) {
      setToast([
        {
          color: 'danger',
          id: '1',
          iconType: 'faceSad',
          title: t('baseTitle'),
          text: t('searchFences'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const formatChangedFences = (): ChangedFences => {
    const changedFences = state.geofences?.filter((fence) => fence.hasChanged === true);
    const fencesObj: ChangedFences = changedFences.reduce((prev, curr) => ({ ...prev, [curr.id]: curr.status }), {});

    return { ...state.changedFences, ...fencesObj };
  };

  const formatDataWithPreviousChangedFences = (newFences: Geofence[]): Geofence[] => {
    const currentFences = newFences.map((fence) => {
      const selectedFenceIsEqual = state.changedFences[fence.id] ?? state.changedFences[fence.id];
      return selectedFenceIsEqual ? { ...fence, status: selectedFenceIsEqual, hasChanged: true } : { ...fence };
    });

    return currentFences as Geofence[];
  };

  useEffect(() => {
    getConfigAndFences(pagination.activePage);
  }, []);

  return (
    <>
      <GeofenceContext.Provider
        value={{
          fields: state.fields,
          setFields,
          loading,
          geofences: state.geofences,
          handleChangeSwitch,
          getConfigAndFences,
          serverTrigger,
          pagination,
          formatChangedFences,
        }}
      >
        {children}
      </GeofenceContext.Provider>
      <ComponentToast toasts={toast} />
    </>
  );
};
