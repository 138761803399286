import {
  IconAndroid,
  IconBattery,
  IconCellphone,
  IconDevices,
  IconGps,
  IconGraph2,
  IconGraphDark,
  IconGraphLight,
  IconGroup,
  IconIos,
  IconReload,
} from 'icons';
import img1 from 'assets/images/mocks/news-card1.jpg';
import img2 from 'assets/images/mocks/news-card2.jpg';
import img3 from 'assets/images/mocks/news-card3.jpg';
import img4 from 'assets/images/mocks/news-card4.jpg';

export const devices_info = [
  {
    icon: <IconCellphone />,
    number: 2,
    text: 'ativos',
  },
  {
    icon: <IconBattery />,
    number: 34,
    text: 'com menos de 20% de bateria',
  },
  {
    icon: <IconGps />,
    number: 2,
    text: 'com GPS desligado',
  },
  {
    icon: <IconReload />,
    number: 2,
    text: 'sem comunicar há 72 horas',
  },
];

export const news = [
  {
    date: '16 maio 2021',
    title: 'Uma nova maneira de MDM',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img1,
    is_new_feature: true,
    is_beta: false,
  },
  {
    date: '16 maio 2021',
    title: 'Uma nova maneira de MDM',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img2,
    is_new_feature: false,
    is_beta: false,
  },
  {
    date: '16 maio 2021',
    title: 'Economía e eficiencia dentro do mesmo segmento, veja como.',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img3,
    is_new_feature: false,
    is_beta: true,
  },
  {
    date: '16 maio 2021',
    title: 'Pulsus Shop: Economía e eficiencia no mesmo lugar',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img4,
    is_new_feature: false,
    is_beta: true,
  },
  {
    date: '16 maio 2021',
    title: 'Pulsus Shop: Economía e eficiencia no mesmo lugar',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img4,
    is_new_feature: false,
    is_beta: true,
  },
  {
    date: '16 maio 2021',
    title: 'Pulsus Shop: Economía e eficiencia no mesmo lugar',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img4,
    is_new_feature: false,
    is_beta: true,
  },
  {
    date: '16 maio 2021',
    title: 'Pulsus Shop: Economía e eficiencia no mesmo lugar',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img4,
    is_new_feature: false,
    is_beta: true,
  },
  {
    date: '16 maio 2021',
    title: 'Pulsus Shop: Economía e eficiencia no mesmo lugar',
    text: 'Todos os indicadores de bateria na palma da sua mão',
    link: '/link',
    img: img4,
    is_new_feature: false,
    is_beta: true,
  },
];

export const quicklinks = [
  {
    icon: <IconGraphDark />,
    title: 'Consumo de Dados',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconGraphLight />,
    title: 'Pulsus Routes',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconDevices />,
    title: 'Gestão de dispositivos',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconAndroid fill="#000" width="24" height="20" />,
    title: 'Android | Launcher',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconGraph2 />,
    title: 'Relatórios',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconCellphone color="#000" />,
    title: 'Aplicativos',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconIos fill="#000" width="15" height="18" />,
    title: 'Ios | Certificados',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconGroup />,
    title: 'Grupos',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
  {
    icon: <IconGraphDark />,
    title: 'Configuração geral',
    text: 'Todos os indicadores de bateria na palma da sua mão.',
    link: '#',
  },
];
