/* eslint-disable new-cap */
import { useEffect, useMemo, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import ReactDOMServer from 'react-dom/server';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { AxiosError } from 'axios';
import * as S from './GoogleMapsComponent.styles';
import { InfoWindowComponent } from '../InfoWindow/InfoWindowComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useMapsContext } from 'modules/operational/contexts/maps';
import type { Location } from 'modules/operational/entities/Location/Location.entity';
import deviceMarkerActive from 'assets/images/cellphone-marker-active.webp';
import deviceMultipleMarkerActive from 'assets/images/device_active_spiderfiable.png';
import deviceMultipleMarkerInactive from 'assets/images/device_inactive_spiderfiable.png';
import deviceMarkerInactive from 'assets/images/cellphone-marker-inactive.webp';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import { useUserContext } from 'modules/core/contexts/user';

export const GoogleMapsComponent = () => {
  const mapsService = useMemo(() => new ServicePulsusLoki(), []);

  const { t } = useTypedTranslation<'maps'>('maps');
  const { administrator } = useUserContext();

  const [toast, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const mapRef = useRef<HTMLDivElement>(null);
  const googleMapRef = useRef<google.maps.Map>();
  const MapRef = useRef<typeof google.maps.Map>();
  const MarkerRef = useRef<typeof google.maps.Marker>();
  const ClusterRef = useRef<MarkerClusterer>();
  const boundsRef = useRef<google.maps.LatLngBounds>();
  const SpiderfyRef = useRef<OverlappingMarkerSpiderfier>();

  const abortController = new AbortController();

  const { filter } = useMapsContext();

  useEffect(() => {
    if (filter.deviceIds || filter.groupIds) {
      getLocations();
    }

    return () => {
      abortController.abort();
    };
  }, [filter]);

  const getLocations = async () => {
    let markers: google.maps.Marker[] = [];
    let hash = {};

    ClusterRef.current?.clearMarkers();
    boundsRef.current = new google.maps.LatLngBounds();

    setIsLoading(true);

    try {
      const locations = mapsService.fetchLocations({ ...filter }, abortController.signal);
      const firstLocation = await locations.next();

      const firstLocationKey = `${firstLocation.value?.latitude}${firstLocation.value?.longitude}`;

      hash[firstLocationKey] = {
        locations: [...(hash[firstLocationKey]?.locations || []), firstLocation?.value],
      };

      if (firstLocation.done) {
        setToast([
          {
            color: 'warning',
            iconType: 'faceNeutral',
            id: '1',
            title: t('requisitions.location.null.title'),
          },
        ]);
      } else {
        for await (const location of locations) {
          if (location.latitude && location.longitude) {
            const key = `${location.latitude}${location.longitude}`;

            hash[key] = {
              locations: [...(hash[key]?.locations || []), location],
            };

            if (Object.keys(hash).length === 1000) {
              for (const objectKey in hash) {
                const locationFromHash = hash[objectKey];
                const markerLocations = locationFromHash.locations;

                if (markerLocations.length === 1) {
                  markers.push(addMarker(markerLocations[0]));
                } else {
                  markerLocations.forEach((markerLocation) => {
                    markers.push(addDuplicateMarkers(markerLocation));
                  });
                }
              }

              ClusterRef.current?.addMarkers(markers);
              markers = [];
              hash = {};
            }
          }
        }

        if (Object.keys(hash).length > 0) {
          for (const objectKey in hash) {
            const locationFromHash = hash[objectKey];
            const markerLocations = locationFromHash.locations;

            if (markerLocations.length === 1) {
              markers.push(addMarker(markerLocations[0]));
            } else {
              markerLocations.forEach((markerLocation) => {
                markers.push(addDuplicateMarkers(markerLocation));
              });
            }
          }

          ClusterRef.current?.addMarkers(markers);

          markers = [];
        }
      }

      googleMapRef?.current?.fitBounds(boundsRef?.current!);
    } catch (error) {
      if (error instanceof AxiosError && error?.code !== 'ERR_CANCELED') {
        setToast([
          {
            color: 'danger',
            iconType: 'faceSad',
            id: '1',
            text: t('requisitions.location.error.text'),
            title: t('requisitions.location.error.title'),
          },
        ]);
      }
    } finally {
      if (!abortController.signal.aborted) {
        setIsLoading(false);
      }
    }
  };

  let currentInfoWindow: google.maps.InfoWindow | null = null;

  const addMarker = (location: Location): google.maps.Marker => {
    const marker = new MarkerRef.current!({
      position: { lat: location.latitude, lng: location.longitude },
      map: googleMapRef.current,
      optimized: true,
      title: String(location.deviceId),
      icon: {
        url: location.recentActivity ? deviceMarkerActive : deviceMarkerInactive,
        scaledSize: new google.maps.Size(35, 35),
      },
    });

    marker.addListener('click', async () => {
      try {
        const result = await mapsService.getLocationDetails(location.id);
        const infoWindow = new google.maps.InfoWindow();

        if (currentInfoWindow) {
          currentInfoWindow.close();
        }

        infoWindow.setContent(
          ReactDOMServer.renderToString(
            <InfoWindowComponent locationDetails={result} language={administrator.language} timezone={administrator.timezone} />
          )
        );
        infoWindow.open({
          anchor: marker,
          map: googleMapRef.current,
        });
        currentInfoWindow = infoWindow;
      } catch (error) {
        setToast([
          {
            color: 'danger',
            iconType: 'faceSad',
            id: '1',
            text: t('requisitions.details.error.text'),
            title: t('requisitions.details.error.title'),
          },
        ]);
      }
    });

    boundsRef?.current?.extend(marker.getPosition()!);

    return marker;
  };

  const addDuplicateMarkers = (location: Location) => {
    const marker = new MarkerRef.current!({
      position: { lat: location.latitude, lng: location.longitude },
      map: googleMapRef.current,
      optimized: true,
    });

    marker.addListener('spider_format', (status) => {
      if (status === 'SPIDERFIABLE') {
        marker.setIcon({
          url: location.recentActivity ? deviceMultipleMarkerActive : deviceMultipleMarkerInactive,
          scaledSize: new google.maps.Size(35, 35),
        });
      } else {
        marker.setIcon({
          url: location.recentActivity ? deviceMarkerActive : deviceMarkerInactive,
          scaledSize: new google.maps.Size(35, 35),
        });
      }
    });

    const infoWindow = new google.maps.InfoWindow();

    SpiderfyRef.current!.addMarker(marker, async () => {
      try {
        const result = await mapsService.getLocationDetails(location.id);

        if (currentInfoWindow) {
          currentInfoWindow.close();
        }

        infoWindow.setContent(
          ReactDOMServer.renderToString(
            <InfoWindowComponent locationDetails={result} language={administrator.language} timezone={administrator.timezone} />
          )
        );

        infoWindow.open({
          anchor: marker,
          map: googleMapRef.current,
        });

        currentInfoWindow = infoWindow;
      } catch (error) {
        setToast([
          {
            color: 'danger',
            iconType: 'faceSad',
            id: '1',
            text: t('requisitions.details.error.text'),
            title: t('requisitions.details.error.title'),
          },
        ]);
      }
    });

    boundsRef?.current?.extend(marker.getPosition()!);

    return marker;
  };

  const instanceSpiderfy = () => {
    if (googleMapRef.current) {
      const oms = new OverlappingMarkerSpiderfier(googleMapRef.current, {
        keepSpiderfied: true,
        ignoreMapClick: false,
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: false,
        legWeight: 1,
        circleFootSeparation: 80,
        spiralFootSeparation: 50,
        spiralLengthStart: 50,
        spiralLengthFactor: 2.5,
      });

      SpiderfyRef.current = oms;
    }
  };

  const initMap = async () => {
    const center = { lat: -16.1844284, lng: -47.0568449 };
    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      version: 'weekly',
      language: administrator.language,
    });

    const mapOptions: google.maps.MapOptions = {
      center,
      zoom: 4,
      mapId: 'map',
    };

    const { Map } = await loader.importLibrary('maps');
    const { Marker } = await loader.importLibrary('marker');

    googleMapRef.current = new Map(mapRef.current!, mapOptions);
    ClusterRef.current = new MarkerClusterer({ map: googleMapRef.current });

    MapRef.current = Map;
    MarkerRef.current = Marker;
  };

  useEffect(() => {
    initMap().then(() => instanceSpiderfy());
  }, []);

  return (
    <S.Main>
      {isLoading && <S.StyledLinearProgress />}
      <S.Map ref={mapRef} lang="es" />
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </S.Main>
  );
};
