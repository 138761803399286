import type { RouteObject } from 'react-router-dom';
import { PageChromeOSIntegration, PageChromeOSDevice, PageChromeOSConfiguration, PageConfigurationsList, PageChromeOSReport } from '../pages/index';

export const ChromeDefaultRoutes: RouteObject[] = [
  {
    path: 'integration',
    element: <PageChromeOSIntegration />,
  },
  {
    path: 'device',
    element: <PageChromeOSDevice />,
  },
  {
    path: 'configuration',
    element: <PageChromeOSConfiguration />,
  },
  {
    path: 'configurations-list',
    element: <PageConfigurationsList />,
  },
  {
    path: 'report',
    element: <PageChromeOSReport />,
  },
];
