import React from 'react';

type IconNfcInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconNfcInCicle({ width, height, elipse_fill, icon_fill }: IconNfcInCicleProps) {
  return (
    <svg width={width || 45} height={height || 45} viewBox="0 0 45 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill={elipse_fill || '#C92F2F'} />
      <path
        d="M28.4 14H15.6C14.72 14 14 14.72 14 15.6V28.4C14 29.28 14.72 30 15.6 30H28.4C29.28 30 30 29.28 30 28.4V15.6C30 14.72 29.28 14 28.4 14ZM28.4 28.4H15.6V15.6H28.4V28.4ZM26.8 17.2H22.8C21.92 17.2 21.2 17.92 21.2 18.8V20.624C20.72 20.904 20.4 21.408 20.4 22C20.4 22.88 21.12 23.6 22 23.6C22.88 23.6 23.6 22.88 23.6 22C23.6 21.408 23.28 20.896 22.8 20.624V18.8H25.2V25.2H18.8V18.8H20.4V17.2H17.2V26.8H26.8V17.2Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
