import styled from 'styled-components';
import { EuiButton, EuiFlexItem, EuiForm, EuiModal } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Modal = styled(EuiModal)`
  .euiModal__flex {
    padding: 50px;
    width: 500px;
  }
`;

export const ModalHeader = styled.header`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;
`;

export const ModalFigure = styled.figure`
  margin-top: 2px;
`;

export const ModalTitle = styled.h3`
  color: ${ConstantColors.SPAIN};
  font-size: '15px';
  letter-spacing: 0;
  line-height: '24px';
  span {
    font-weight: 700;
  }
`;

export const ModalForm = styled(EuiForm)`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const ModalButtonContainer = styled(EuiFlexItem)`
  margin: 30px 0;
`;

export const Button = styled(EuiButton)`
  background: ${ConstantColors.CHILE} !important;
  border-color: ${ConstantColors.CHILE} !important;
`;
