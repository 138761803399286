import styled from 'styled-components';

export const FieldBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .euiSelect {
    border-radius: 6px;
  }
`;

export const FieldLabel = styled.p`
  padding-top: 1.5px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;

export const HeaderContent = styled.div`
  display: flex;
  gap: 4px;
`;
