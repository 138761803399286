import { IconChromeOS } from 'icons';
import type { FlagsProps } from '../ComponentPoliciesModal.contracts';
import * as S from './GeneralConfigComponent.styles';

export const Flags: FlagsProps = {
  wifi: [{ OS: 'ChromeOS', version: '' }],
  bluetooth: [{ OS: 'ChromeOS', version: '' }],
  camera: [
    {
      OS: 'ChromeOS',
      managementType: 'NU',
      version: '5.0',
      elementIcon: (
        <S.FlagIcon>
          <IconChromeOS height={14} width={14} fill="#adadad" />
        </S.FlagIcon>
      ),
    },
  ],
  soundOutput: [
    {
      OS: 'ChromeOS',
      managementType: 'NU',
      version: '5.0',
      elementIcon: (
        <S.FlagIcon>
          <IconChromeOS height={14} width={14} fill="#adadad" />
        </S.FlagIcon>
      ),
    },
  ],
  microphone: [
    {
      OS: 'ChromeOS',
      managementType: 'NU',
      version: '5.0',
      elementIcon: (
        <S.FlagIcon>
          <IconChromeOS height={14} width={14} fill="#adadad" />
        </S.FlagIcon>
      ),
    },
  ],
};
