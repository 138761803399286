import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 20px;

  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;

  width: 100%;
  height: 58px;

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-40px);
    }
    60% {
      transform: translateY(-20px);
    }
    80% {
      transform: translateY(-30px);
    }
    100% {
      transform: translateY(-25px);
    }
  }

  @keyframes slideOutFromBottom {
    0% {
      transform: translateY(-25px);
    }
    100% {
      transform: translateY(100px);
    }
  }

  .animation {
    transform: translateY(-25px);
    animation-name: slideInFromBottom;
    animation-duration: 0.5s;
  }

  .not_animated {
    transform: translateY(100px);
    animation-name: slideOutFromBottom;
    animation-duration: 0.5s;
  }

  div.fade_in {
    display: flex;
    padding: 0 20px;
    align-items: center;
    background: black;
    height: 58px;
    background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
    box-shadow: 0px 3.16359px 33px rgba(0, 0, 0, 0.25);
    border-radius: 63px;

    div.fade_in_items {
      display: flex;
      align-items: center;
      gap: 15px;

      div {
        display: flex;
        gap: 4px;
      }
    }
  }

  .link-style {
    color: ${ConstantColors.CHILE};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SaveButton = styled(EuiButton)`
  color: ${ConstantColors.AUSTRIA};
  background-color: ${ConstantColors.ARGENTINA};
  height: 35px;
  border: none;

  &:hover {
    background-color: ${ConstantColors.ARGENTINA} !important;
  }

  &:focus {
    background-color: ${ConstantColors.ARGENTINA} !important;
  }
`;
