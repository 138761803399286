import { EuiButton, EuiButtonEmpty, EuiFilterGroup } from '@elastic/eui';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { BannerProps } from '../page-onboarding.contracts';

export const Container = styled.div<Pick<BannerProps, 'color'>>`
  ${({ color }) => css`
    background-color: ${color};
    padding: 35px 50px 40px 50px;
    display: flex;
    flex-direction: column;
    color: ${ConstantColors.AUSTRIA};
    width: 100%;
    position: relative;
    align-items: center;

    span {
      font-size: 14px;
    }
  `}
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1400px;
  height: 30px;
  position: relative;
`;

export const CloseButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 51%;
  max-width: 1400px;
  height: 40px;
  margin-right: 60px;
  margin-left: auto;
  position: relative;
`;

export const BackButton = styled(EuiButtonEmpty)`
  border: 1px solid ${ConstantColors.AUSTRIA};
  border-radius: 23px;
  color: ${ConstantColors.AUSTRIA};
  font-weight: 700;
  font-size: 12px !important;
  width: 100px;
  height: 36px !important;
  line-height: 18px;
  padding-top: 2px;
  padding-right: 2px;
  position: absolute;
  left: 60px;
  svg {
    width: 19px;
  }
`;
export const Main = styled.main`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-height: 325px;
  max-width: 1400px;
  width: 100%;
`;

export const InitialBannerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 970px;
  min-width: 670px;
  width: 100%;
  margin: 0 auto;
  height: 400px;

  span {
    color: ${ConstantColors.AUSTRIA};
    font-style: normal;
    font-weight: 300;
    font-size: 30px;
    line-height: 40px;
  }

  .hello-title {
    color: ${ConstantColors.HAITI};
    font-weight: 700;
  }
`;

export const BannersContent = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 830px;
  display: flex;
  position: relative;

  h2.stepTitle {
    font-style: normal;
    font-weight: 400;
    line-height: 40px;
    font-size: 28px;
    padding-bottom: 25px;
    width: 330px;
  }
`;
export const TitleContainerRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-right: auto;
  margin-left: 50%;
  padding-top: 50px;
  min-width: 200px;
  max-width: 200px;
  width: 50%;
`;

export const InitialButton = styled(EuiButton)`
  width: 284px;
  height: 47px;
  border: none;
  padding: 20px 17px;
  margin: 30px 0;

  .euiButton__text {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
  }
`;
export const FilterButtons = styled(EuiFilterGroup)`
  width: 105px;
  border-radius: 9px;

  .euiFilterButton {
    height: 35px;
  }

  .euiButtonEmpty--text:focus,
  .euiFilterButton-hasActiveFilters {
    background: #d8d8d8 !important;
  }
`;

export const StepHorizontal = styled.div`
  margin: 5px auto 0 auto;
  width: 80%;
  max-width: 730px;
  pointer-events: none;
  height: 70px;

  .euiStepHorizontal-isComplete::after,
  .euiStepHorizontal-isComplete::before,
  .euiStepHorizontal-isSelected::before,
  .euiStepHorizontal-isSelected::after {
    background-color: ${ConstantColors.AUSTRIA} !important;
  }
  .euiStepHorizontal::before,
  .euiStepHorizontal::after {
    background-color: ${ConstantColors.AUSTRIA};
  }
  .euiStepsHorizontal {
    background: none;

    .euiStepHorizontal__title {
      color: ${ConstantColors.AUSTRIA};
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
    }

    .euiStepHorizontal__number {
      color: ${ConstantColors.COMOROS};
    }

    .euiStepNumber.euiStepNumber--incomplete.euiStepHorizontal__number {
      background: transparent;
      color: ${ConstantColors.AUSTRIA};
      border: 2px solid white;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
    }

    .euiStepNumber {
      background: ${ConstantColors.AUSTRIA};
    }
  }
`;
