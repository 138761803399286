import type { ReactElement } from 'react';
import { useState } from 'react';
import { DialogComponent } from 'components';
import { EuiRadio } from '@elastic/eui';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import type { ModalActiveFenceComponentProps } from './ModalActiveFenceComponent.contracts';
import * as S from './ModalActiveFenceComponent.styles';

export const ModalActiveFenceComponent = ({ onClose, geofenceList, geofenceToUpdate, getFences }: ModalActiveFenceComponentProps): ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);
  const [toast, setToast] = useState<Toast[]>([]);

  const [optionSelected, setOptionSelect] = useState<'active' | 'inactive'>('active');

  const { t } = useTranslation('translation', { keyPrefix: 'geofence.modal_active_fence' });

  const fetchActiveFence = async (): Promise<void> => {
    try {
      setLoading(true);
      const newGeofenceList = geofenceList.map((item) => ({ id: item.id, status: item.id === geofenceToUpdate.id ? optionSelected : item.status }));

      await ServicePulsusValquiria.editAllGeofences(newGeofenceList);
      await getFences();

      setOptionSelect('active');
      onClose(null);
    } catch (err) {
      setToast([
        {
          title: t('error.title'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('error.description'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };
  return (
    <DialogComponent width="100%" height="317px" maxWidth="468px" open={!!geofenceToUpdate} onClose={() => onClose(null)}>
      <S.ModalContainer>
        <p>
          {t('descriptionOne')} <span>{t('descriptionTwo')}</span>
        </p>
        <h1>{t('title')}</h1>
        <S.CheckboxGroups>
          <EuiRadio id="activeFence" checked={optionSelected === 'active'} label={t('radioActiveLabel')} onChange={() => setOptionSelect('active')} />
          <EuiRadio
            id="inactiveFence"
            checked={optionSelected === 'inactive'}
            label={t('radioInactiveLabel')}
            onChange={() => setOptionSelect('inactive')}
          />
        </S.CheckboxGroups>
        <S.ButtonGroup>
          <S.ButtonLink onClick={() => onClose(null)}>{t('cancelButton')}</S.ButtonLink>
          <S.Button onClick={() => fetchActiveFence()} isLoading={loading}>
            {t('saveButton')}
          </S.Button>
        </S.ButtonGroup>
      </S.ModalContainer>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </DialogComponent>
  );
};
