import { Breadcrumb } from 'entities/breadcrumb';

export const OperationalBreadcrumbs = {
  PULSUS_OPERATIONAL_APPS: {
    route: '/operational/applications',
    breadcrumbs: [
      new Breadcrumb('core_components.breadcrumb.home', '/'),
      new Breadcrumb('core_components.breadcrumb.pulsus_operational_title', '/'),
      new Breadcrumb('core_components.breadcrumb.pulsus_operational_apps', ''),
    ],
  },
  PULSUS_OPERATIONAL_DEVICES: {
    route: '/operational/device-management',
    breadcrumbs: [
      new Breadcrumb('core_components.breadcrumb.home', '/'),
      new Breadcrumb('core_components.breadcrumb.pulsus_operational_title', '/'),
      new Breadcrumb('core_components.breadcrumb.pulsus_operational_devices', ''),
    ],
  },
};
