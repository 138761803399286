type BatteryProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconBattery({ width = 24, height = 24, color = '#000', test_id = 'icons__battery' }: BatteryProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 17v3.67C7 21.4 7.6 22 8.33 22h7.33c.74 0 1.34-.6 1.34-1.33V17H7Z" fill={color} />
      <path d="M17 5.33C17 4.6 16.4 4 15.67 4H14V2h-4v2H8.33C7.6 4 7 4.6 7 5.33V17h10V5.33Z" fill={color} fillOpacity=".3" />
    </svg>
  );
}
