import styled from 'styled-components';
import { EuiModal, EuiButton, EuiFieldText, EuiFormRow } from '@elastic/eui';

export const Modal = styled(EuiModal)`
  /* margin-top: 50px; */
  margin-bottom: 14vh; //with iframer
  .euiModal__flex {
    width: 880px;
    max-height: fit-content;
  }

  .euiStep__content {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 10px 15px 25px;
    width: 90%;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 0 90px;
  input::-webkit-input-placeholder {
    color: #b3b3b3;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
  color: ${({ theme }) => theme.main};
  margin: 40px 0 10px;
`;

export const BodyModal = styled.main`
  display: flex;
  width: 100%;
  .euiStep__title {
    font-style: normal;
    font-weight: bold !important;
    font-size: 22px !important;
    line-height: 32px !important;
    color: #1a1c21;
  }

  .euiStep__circle {
    font-style: normal;
    font-weight: 600;

    line-height: 21px;
    background-color: ${({ theme }) => theme.main};
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .euiCallOut--danger {
    position: absolute;
    right: 40px;
    bottom: 190px;
    left: 570px;
  }
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: normal;

  line-height: 24px;
  color: #343741;
`;

export const SecondText = styled.small`
  font-style: normal;
  font-weight: bold;
  font-size: 12px !important;
  line-height: 18px;
`;

export const FooterModal = styled.footer`
  margin-left: auto;
  margin-right: 20px;
  margin-bottom: 20px;
  height: 60px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: end;
`;

export const Button = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;
export const EntrustLogo = styled.div`
  width: 86px;
  height: 86px;
  border-radius: 6px;
  background-color: #77797b1a;
  margin-bottom: 40px;
  margin-top: 22px;
`;
export const BallContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
`;
export const IconEntrust = styled.img`
  margin: 0 auto;
  width: 86px;
  margin-top: -2px;
`;
export const Ball = styled.span`
  margin-top: 3px;
  margin-right: 5px;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  border: 6px solid #ff4c0c;
`;

export const ContainerLeft = styled.div`
  width: 275px;
`;
export const ContainerRight = styled.div`
  width: 275px;
`;
export const ContainerFull = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .euiFormControlLayout {
    max-width: 600px;
    width: 100%;
    width: 600px;
  }
`;
export const EuiFormRowStyled = styled(EuiFormRow)`
  max-width: 600px;
`;
export const EuiFieldTextStyled = styled(EuiFieldText)`
  margin: 0 auto;
  max-width: 600px;
`;
export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 600px;
  margin-top: 27px;
`;
export const ContainerFile = styled.div`
  margin-top: 27px;
  width: 100%;
  max-width: 600px;
  margin-bottom: 40px;
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 54px;
`;
