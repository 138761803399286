import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: flex-end;
`;

export const Left = styled.div`
  width: 45%;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 55%;
  gap: 30px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.p`
  width: 450px;
  font-weight: 700;
  font-size: 25.7391px;
  line-height: 28px;
  color: ${({ theme }) => theme.main};
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #474747;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  .euiFieldPassword:focus {
    background-color: white;
    background-size: 100% 100%;
    box-shadow:
      0 1px 1px -1px rgba(152, 162, 179, 0.2),
      0 4px 4px -2px rgba(152, 162, 179, 0.2),
      inset 0 0 0 1px rgba(15, 39, 118, 0.1);

    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%);`}
    `}
  }
`;
