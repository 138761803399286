type GroupProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconGroup({ width = 20, height = 20, color = '#000', test_id = 'icons__group' }: GroupProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0ZM6 15.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5ZM7.5 6a2.5 2.5 0 0 1 5 0 2.5 2.5 0 0 1-5 0Zm6.5 9.5a2.5 2.5 0 0 1 0-5 2.5 2.5 0 0 1 0 5Z"
        fill={color}
      />
    </svg>
  );
}
