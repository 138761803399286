import type { EuiSwitchEvent } from '@elastic/eui';
import { EuiButton, EuiModalHeader, EuiModalHeaderTitle, EuiSwitch } from '@elastic/eui';
import { IconWifiInCicle } from 'icons';
import { IconTrashInCicle } from 'icons/trash-in-cicle/icon-trash-in-cicle';
import { useState } from 'react';
import Lottie from 'lottie-react';
import animation_wifi from 'assets/animations/animation-wifi.json';
import type { ModalWifiNetworksListProps } from './page-connectivity.contracts';
import * as style from './page-connectivity.style';

export function ModalWifiNetworksListContent({ to_new_network_modal, networksList, setNetworksList }: ModalWifiNetworksListProps) {
  const [isDestroyModalVisible, setIsDestroyModalVisible] = useState(false);
  const [wifiIndexToDelete, setWifiIndexToDelete] = useState(0);
  const closeDestroyModal = () => setIsDestroyModalVisible(false);

  const showDestroyModal = (index: number) => {
    setIsDestroyModalVisible(true);
    setWifiIndexToDelete(index);
  };

  function on_change_switch(e: EuiSwitchEvent, index: number) {
    if (!e.target.checked) {
      networksList[index].status = 'Rede desabilitada';
      const new_networks_list = [...networksList];
      setNetworksList(new_networks_list);
    } else {
      networksList[index].status = 'Rede habilitada';
      const new_networks_list = [...networksList];
      setNetworksList(new_networks_list);
    }
  }

  function deleteWifi() {
    networksList.splice(wifiIndexToDelete, 1);
    const new_networks_list = [...networksList];
    setNetworksList(new_networks_list);
    setIsDestroyModalVisible(false);
  }

  function renderNewNetworkButton() {
    return (
      <EuiButton className="button_add_new_network" fill onClick={() => to_new_network_modal()}>
        Adicionar nova rede
      </EuiButton>
    );
  }

  return (
    <>
      <style.WifiNetworksModal>
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            <h1>Redes wifi disponíveis</h1>
            <p>
              Veja todas as redes de wifi configuradas em
              <b> Config Teste 3</b>
            </p>
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        {networksList.length ? (
          <>
            <style.Body>
              <style.TableWifiNetworks>
                <tr className="table_header">
                  <th className="table_titles name">Nome da rede</th>
                  <th className="table_titles security">Segurança</th>
                  <th className="table_titles status">Status</th>
                  <th className="table_titles delete">Eliminar</th>
                </tr>
                {networksList.map((network_item, index) => {
                  return (
                    <tr key={network_item.network + network_item.security} className="table_body">
                      <td className="network_name column">
                        <IconWifiInCicle elipse_fill="white" icon_fill="#747474" width={28} height={28} />
                        <h6 className="network_name">{network_item.network}</h6>
                      </td>
                      <td className="security column">
                        <h6 className="security">{network_item.security}</h6>
                      </td>
                      <td className="status column">
                        <EuiSwitch
                          label={network_item.status === 'Rede habilitada' ? 'Rede habilitada' : 'Rede desabilitada'}
                          checked={network_item.status === 'Rede habilitada'}
                          onChange={(e) => {
                            on_change_switch(e, index);
                          }}
                          className="network_status"
                          compressed
                        />
                      </td>
                      <td>
                        <button type="button" onClick={() => showDestroyModal(index)}>
                          <IconTrashInCicle />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </style.TableWifiNetworks>
            </style.Body>
            <style.Footer style={{ width: '100%' }}>{renderNewNetworkButton()}</style.Footer>
          </>
        ) : (
          <>
            <style.Body>
              <Lottie
                animationData={animation_wifi}
                style={{
                  width: '200px',
                  height: 'auto',
                }}
              />
              <style.Description>Nenhuma rede Wifi adicionada :(</style.Description>
            </style.Body>
            <style.Footer>{renderNewNetworkButton()}</style.Footer>
          </>
        )}
      </style.WifiNetworksModal>

      {isDestroyModalVisible && (
        <style.Poupup
          title="Tem certeza que deseja apagar esta rede?"
          onCancel={closeDestroyModal}
          onConfirm={() => deleteWifi()}
          cancelButtonText="Cancelar"
          confirmButtonText="Eliminar"
          buttonColor="danger"
          defaultFocusedButton="confirm"
          maxWidth="448px"
        >
          <p>Esta ação é irreversivel, tem certeza que deseja proseguir?</p>
        </style.Poupup>
      )}
    </>
  );
}
