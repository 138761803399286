import { useState, type ReactElement, useMemo, useEffect } from 'react';
import * as S from './DeviceByPlatformOrModelComponent.styles';
import { DashboardCardComponent } from '../../DashboardCard/DashboardCardComponent';
import { IndicatorPercentageBarComponent } from '../../IndicatorPercentageBar/IndicatorPercentageBarComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import type { StatisticsWithGraphics } from 'modules/operational/pages/dashboard/DashboardPage.contracts';

export const DeviceByPlatformOrModelComponent = (): ReactElement => {
  const [isDevicesByModel, setSwitchValue] = useState(false);
  const [devices, setDevices] = useState<StatisticsWithGraphics[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingModelOrPlatform, setIsLoadingModelOrPlatform] = useState(true);

  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const servicePulsusHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const getIsMultilaserFeatureFlag = async () => {
    try {
      const featureFlags = await servicePulsusWeb.getFlags();
      return featureFlags.PEM3149_tenant_multilaser;
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
      return false;
    }
  };

  const getDevicesByPlatformOrModel = async (isByModel: boolean) => {
    const isMultilaserTenant = await getIsMultilaserFeatureFlag();
    setIsLoadingModelOrPlatform(true);
    try {
      if (isByModel) {
        const devicesModel = await servicePulsusHagalaz.getDashboardDevicesByModel(isMultilaserTenant);
        setDevices(devicesModel);
      } else {
        const devicesPlatform = await servicePulsusHagalaz.getDashboardDevicesByPlatform();
        setDevices(devicesPlatform);
      }
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoading(false);
      setIsLoadingModelOrPlatform(false);
    }
  };

  useEffect(() => {
    getDevicesByPlatformOrModel(isDevicesByModel);
  }, [isDevicesByModel]);

  const renderTitle = () => {
    return (
      <S.IndicatorTitle>
        <p>{t('dashboards.platformOrModel.byPlatform')}</p>
        <S.Switch label="" checked={isDevicesByModel} onChange={() => setSwitchValue(!isDevicesByModel)} />
        <p>{t('dashboards.platformOrModel.byModel')}</p>
      </S.IndicatorTitle>
    );
  };

  return (
    <DashboardCardComponent isLoading={isLoading || isLoadingModelOrPlatform} title={renderTitle()}>
      <S.Main isDevicesByModel={isDevicesByModel}>
        {!isLoading &&
          devices.map((item) => (
            <IndicatorPercentageBarComponent key={item.label} count={item.count} label={item.label} value={Math.round(item.ratio)} />
          ))}
        <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
      </S.Main>
    </DashboardCardComponent>
  );
};
