import type { MenuTagProps } from '../page-settings.contracts';
import * as style from './menu-tag.style';

export function MenuTag({ text, isSelected, onClick }: MenuTagProps) {
  return (
    <style.Container onClick={onClick} isSelected={isSelected}>
      <p>{text}</p>
    </style.Container>
  );
}
