import { EuiBadge, EuiHighlight, EuiSelectable, EuiText } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import type { ComponentGroupsSelectProps, groupProps } from './component-groups-select.contracts';
import * as style from './component-groups-select.style';

export function ComponentGroupsSelect({
  disabled,
  groups,
  set_groups,
  loading,
  message,
  set_selection,
  singleSelection = false,
  setSearchValue = null,
  certificates = false,
}: ComponentGroupsSelectProps) {
  const { t } = useTranslation();

  const renderGroupsOptions = (option: groupProps, searchValue: string) => {
    return (
      <style.OptionContainer>
        <EuiHighlight className="EuiHighlight" search={searchValue}>
          {option.label}
        </EuiHighlight>
        {option.total_devices ? (
          <EuiBadge>
            <EuiText size="s">
              {option.total_devices}
              {option.total_devices === 1 ? ' device' : ' devices'}
            </EuiText>
          </EuiBadge>
        ) : null}
      </style.OptionContainer>
    );
  };
  const selectPlaceholder = certificates
    ? t('pages.pulsus_insights_batteries.modal.placeholder_certificates')
    : t('pages.pulsus_insights_batteries.modal.placeholder');
  return (
    <style.Selectable disabled={disabled}>
      <EuiSelectable
        listProps={{ bordered: true }}
        searchable
        singleSelection={singleSelection}
        isLoading={loading}
        loadingMessage={message}
        searchProps={{
          placeholder: selectPlaceholder,
        }}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onKeyUp={(event: any) => {
          setSearchValue(event.target.value);
        }}
        options={groups}
        renderOption={renderGroupsOptions}
        onChange={(newOptions: never[]) => {
          set_groups(newOptions);
          const selectionIds = newOptions.filter((option: groupProps) => option.checked);
          if (set_selection) {
            set_selection(selectionIds);
          }
        }}
      >
        {(list, search) => (
          <>
            {search}
            {!disabled && (
              <div className="list">
                <span>{list}</span>
              </div>
            )}
          </>
        )}
      </EuiSelectable>
    </style.Selectable>
  );
}
