import styled from 'styled-components';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export const Flags = styled.div``;

export const FlagIcon = styled.div`
  padding-left: 2px;
`;

export const Settings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  section {
    margin: 0;
    span {
      div {
        font-size: 11px;
        display: flex;
        align-items: center;
        color: #888b92;
        gap: 3px;
        span {
          min-width: 0px;
        }
      }
    }
  }
`;

export const Row = styled.div`
  display: flex;
`;

export const Side = styled.div`
  width: 50vw;
  align-self: center;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  overflow-x: auto;
  height: 50vh;
  scrollbar-width: thin;
`;

export const Input = styled.div`
  width: 100%;
`;

export const Title = styled.p`
  font-size: 10px;
  font-weight: bold;
  line-height: 15px;
  letter-spacing: 0em;
  text-transform: uppercase;
  margin-right: 2px;
`;

export const WrapperTitleTootip = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
`;

export const HelpIcon = styled(HelpOutlineIcon)`
  height: 14px !important;
  width: 14px !important;
  margin-top: 2px;
`;
