import MessageIcon from '@mui/icons-material/Message';
import EditIcon from '@mui/icons-material/Edit';
import { useTranslation } from 'react-i18next';
import VibrationIcon from '@mui/icons-material/Vibration';
import BuildIcon from '@mui/icons-material/Build';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import LockIcon from '@mui/icons-material/Lock';
import ReregisteIcon from '@mui/icons-material/HowToReg';
import type { MenuDevicesIconsProps } from 'components/menu-devices-icons/component-menu-devices-icons.contracts';
import { useQuery } from 'hooks/use-query';
import { useEffect } from 'react';
import i18next from 'i18next';
import { EuiToolTip } from '@elastic/eui';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import HealingIcon from '@mui/icons-material/Healing';
import { ComponentResourceChecker } from 'modules/core/components';
import { ConstantResource } from 'modules/core/utilities';
import * as style from './component-menu-devices-icons.style';

export function ComponentMenuDevicesIcons({
  isActive,
  onClickEdit,
  onClickMessage,
  onClickMaintenance,
  onClickSoundSignal,
  onClickReregister,
  onClickDelete,
  onClickUpdate,
  onClickBlock,
  onClickActivateQuarantine,
  onClickDisableQuarantine,
  hasIosSelected,
}: MenuDevicesIconsProps) {
  const { t } = useTranslation();
  const query = useQuery();
  const language = query.get('language');

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <style.Container className="options-container">
      <style.Section>
        <ComponentResourceChecker identifier={ConstantResource.DEVICES.QUARANTINE_MODE}>
          <EuiToolTip position="top" content={t('pages.device_management.disable_quarantine')}>
            <style.BgCircle isActive={isActive}>
              <InsertEmoticonIcon onClick={onClickDisableQuarantine} />
            </style.BgCircle>
          </EuiToolTip>

          <EuiToolTip position="top" content={t('pages.device_management.activate_quarantine')}>
            <style.BgCircle isActive={isActive}>
              <HealingIcon onClick={onClickActivateQuarantine} />
            </style.BgCircle>
          </EuiToolTip>
        </ComponentResourceChecker>
        <style.Divider />

        <EuiToolTip position="top" content={t('pages.device_management.advanced_update')}>
          <style.BgCircle isActive={isActive}>
            <CachedIcon onClick={onClickUpdate} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip
          position="top"
          content={hasIosSelected ? t('pages.device_management.advanced_ios_disabled') : t('pages.device_management.advanced_message')}
        >
          <style.BgCircle isActive={isActive && !hasIosSelected}>
            <MessageIcon onClick={onClickMessage} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip
          position="top"
          content={hasIosSelected ? t('pages.device_management.advanced_ios_disabled') : t('pages.device_management.advanced_re_register')}
        >
          <style.BgCircle isActive={isActive && !hasIosSelected}>
            <ReregisteIcon onClick={onClickReregister} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={t('pages.device_management.advanced_edit')}>
          <style.BgCircle isActive={isActive}>
            <EditIcon onClick={onClickEdit} />
          </style.BgCircle>
        </EuiToolTip>

        <style.Divider />

        <EuiToolTip
          position="top"
          content={hasIosSelected ? t('pages.device_management.advanced_ios_disabled') : t('pages.device_management.sound_signal')}
        >
          <style.BgCircle isActive={isActive && !hasIosSelected}>
            <VibrationIcon onClick={onClickSoundSignal} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip
          position="top"
          content={hasIosSelected ? t('pages.device_management.advanced_ios_disabled') : t('pages.device_management.advanced_build')}
        >
          <style.BgCircle isActive={isActive && !hasIosSelected}>
            <BuildIcon onClick={onClickMaintenance} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={t('pages.device_management.advanced_block')}>
          <style.BgCircle isActive={isActive}>
            <LockIcon onClick={onClickBlock} />
          </style.BgCircle>
        </EuiToolTip>

        <EuiToolTip position="top" content={t('pages.device_management.delete')}>
          <style.BgCircle isActive={isActive}>
            <DeleteIcon onClick={onClickDelete} style={{ color: '#f44336' }} />
          </style.BgCircle>
        </EuiToolTip>
      </style.Section>
    </style.Container>
  );
}
