import type { EuiTabbedContentTab } from '@elastic/eui';
import { EuiTabbedContent } from '@elastic/eui';
import { useEffect, useState } from 'react';
import { DeviceDrawFullyWp, ZeroTouchDraw } from 'icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './fully-managed-wp.style';
import { tabs } from './tabs-fully-managed-wp';
import type { EnrollComponentData } from '../modal-ae-enroll.contracts';

export function FullyManagedWP({ data }: EnrollComponentData) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'pages.enroll' });
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs(data?.QRcode ?? '', data?.jsonString, t)[0]);
  const onTabClick = (tab: EuiTabbedContentTab) => {
    setSelectedTab(tab);
  };

  function typeWrite() {
    const elemento = document.querySelector('.imageInputTextFully');
    const texto = 'afw#setup';
    if (elemento) {
      const textoArray = texto.split('');
      elemento.innerHTML = '';
      textoArray.forEach((letra, i) => {
        setTimeout(() => {
          elemento.innerHTML += letra;
        }, 100 * i);
      });
    }
  }

  useEffect(() => {
    const loop = setInterval(() => {
      typeWrite();
    }, 3000);
    return function clear() {
      clearInterval(loop);
    };
  }, []);

  return (
    <S.Container>
      <S.Top>
        <h2>{t('fully_managed_wp.title')}</h2>
      </S.Top>
      <S.OptionsContainer>
        <S.TabsContainer>
          <EuiTabbedContent tabs={tabs(data?.QRcode ?? '', data?.jsonString, t)} onTabClick={onTabClick} />
        </S.TabsContainer>
        <S.OptionsRight>
          {selectedTab.id === 'afw' && (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <div className="background">
                <S.ImageBox className="animation">
                  <DeviceDrawFullyWp />
                  {data?.QRcode && (
                    <S.QrCodeBox>
                      <img height={215} src={data?.QRcode} alt="qr-code" />
                    </S.QrCodeBox>
                  )}
                </S.ImageBox>
              </div>
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Fully Managed with Work Profile</p>
                <p className="secondary">/</p>
                <p className="primary">AFW</p>
              </S.LinkHistory>
            </div>
          )}
          {selectedTab.id === 'qr-code' && (
            <S.RightBox>
              <img src={data?.QRcode} alt="qr-code" />
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Fully Managed with Work Profile</p>
                <p className="secondary">/</p>
                <p className="primary">QR Code</p>
              </S.LinkHistory>
            </S.RightBox>
          )}
          {selectedTab.id === 'zero-touch' && (
            <S.RightBox>
              <div style={{ height: '80%', display: 'flex', alignItems: 'center' }}>
                <ZeroTouchDraw />
              </div>
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Fully Managed with Work Profile</p>
                <p className="secondary">/</p>
                <p className="primary">Zero Touch</p>
              </S.LinkHistory>
            </S.RightBox>
          )}
        </S.OptionsRight>
      </S.OptionsContainer>
    </S.Container>
  );
}
