import type { TabPanelProps } from './component-tab-panel.contracts';

export function ComponentTabPanel(props: TabPanelProps) {
  const { tabKey, genericKey, children, value, half } = props;
  const hidden = !(value === tabKey || value === genericKey);
  return (
    <div style={half ? { width: '50%' } : {}} id={`Tab_id_${tabKey}`}>
      {!hidden && <>{children}</>}
    </div>
  );
}
