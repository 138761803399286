import { EuiModal, EuiOverlayMask } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled(EuiOverlayMask)``;

export const Content = styled(EuiModal)`
  min-width: 850px;
  min-height: 590px;
  border-radius: 16.087px;
`;

export const Body = styled.section`
  display: flex;
  flex-direction: row;
  padding: 50px 15px 50px 50px;

  img {
    height: 420px;
    width: 480px;
  }
`;

export const LeftInfo = styled.section`
  height: 420px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  p {
    font-size: 15px;
    color: ${ConstantColors.INDIA};
  }

  h1 {
    color: ${ConstantColors.ANDORRA};
  }

  h2 {
    min-width: 110%;
    margin-bottom: 10px;
    color: ${ConstantColors.ANDORRA};
  }

  h4 {
    color: ${ConstantColors.INDIA};
  }

  .icon {
    width: 57px;
    height: 57px;
    margin: 0 0 -30px 0;
    color: ${ConstantColors.AUSTRIA};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    position: relative;

    p {
      font-size: 22px;
      color: ${ConstantColors.AUSTRIA};
    }

    svg {
      position: absolute;
      top: 0;
      right: -5px;
    }
  }
`;
