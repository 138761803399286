import type { ComboBoxOption } from 'components/combo-box/component-combo-box.contracts';
import { BaseServiceInstance } from '../BaseServiceInstance';
import type {
  SimpleStatistics,
  StatisticsWithGraphics,
  PercentageIndicator,
  AlertsSettings,
} from 'modules/operational/pages/dashboard/DashboardPage.contracts';
import type { DashboardBenchmarks } from 'modules/operational/entities/DashboardBenchmarks/DashboardBenchmarks.entity';
import { DashboardStatus } from 'modules/operational/entities/DashboardStatus/DashboardStatus.entity';
import { DataUsageTableApps } from 'modules/operational/entities/DataUsageTableApps/DataUsageTableApps.entity';
import { DataUsageTableGroups } from 'modules/operational/entities/DataUsageTableGroups/DataUsageTableGroups.entity';
import { DataUsageTableDevices } from 'modules/operational/entities/DataUsageTableDevices/DataUsageTableDevices.entity';
import { DataUsageDashboard } from 'modules/operational/entities/DataUsageDashboard/DataUsageDashboard.entity';
import { DataUsageIndicator } from 'modules/operational/entities/DataUsageIndicator/DataUsageIndicator.entity';
import type { DataUsageChartBodyType, DataUsageChartReturnType, DataUsageTableBodyRequisition } from './ServicePulsusHagalaz.contracts';

export class ServicePulsusHagalaz extends BaseServiceInstance {
  constructor() {
    super(process.env.REACT_APP_URL_PULSUS_HAGALAZ_API!);
  }

  async getChromeOSMetabaseDashboard() {
    const { data } = await this._instance.get('/chrome/dashboard');
    return data;
  }

  async getChromeOSMetabaseTables(startDate: string, endDate: string, groupId: ComboBoxOption[]) {
    const auxGrupsId = groupId.length > 0 ? groupId.map((item) => item.id) : '[]';
    const { data } = await this._instance.get(`/chrome/reports?startDate=${startDate}&endDate=${endDate}&groupId=${auxGrupsId}`);
    return data;
  }

  async getDashboardLessInstalledApps() {
    const { data } = await this._instance.get<SimpleStatistics[]>('/dashboard/less_installed_apps');

    return data;
  }

  async getDashboardWorstGroups() {
    const { data } = await this._instance.get<SimpleStatistics[]>('/dashboard/worst_group_installations');

    return data;
  }

  async getDashboardDevicesByPlatform() {
    const { data } = await this._instance.get<StatisticsWithGraphics[]>('/dashboard/devices_by_platform');

    return data;
  }

  async getDashboardDevicesByModel(hideManufacturer: boolean) {
    const params = hideManufacturer ? { hide_manufacturer: true } : {};

    const { data } = await this._instance.get<StatisticsWithGraphics[]>('/dashboard/devices_by_model', { params });

    return data;
  }

  async getDashboardDevicesPercentageGraphics(indicator: PercentageIndicator) {
    const { data } = await this._instance.get<StatisticsWithGraphics[]>(`/dashboard/devices_by_${indicator}`);

    return data;
  }

  async getDashboardStatus() {
    const { data } = await this._instance.get('/dashboard/stats');

    return new DashboardStatus(data);
  }

  async getAlertsBenchmarks(settings: AlertsSettings): Promise<DashboardBenchmarks.ServerDefault> {
    const { max_hours, min_battery, min_storage } = settings;
    const params = { max_hours, min_battery, min_storage };
    const { data } = await this._instance.get<DashboardBenchmarks.ServerDefault>('dashboard/alerts', { params });

    return data;
  }

  async getAndroidBenchmarks(): Promise<DashboardBenchmarks.ServerAndroid> {
    const { data } = await this._instance.get<DashboardBenchmarks.ServerAndroid>('dashboard/alerts_android');

    return data;
  }

  async getDataUsageTableApps(body: DataUsageTableBodyRequisition): Promise<DataUsageTableApps> {
    const route = body.device_id ? 'data_usage/device_page_apps' : 'data_usage/apps';
    const { data } = await this._instance.post<DataUsageTableApps.Server>(route, body);

    return new DataUsageTableApps(data);
  }

  async getDataUsageTableGroups(body: DataUsageTableBodyRequisition): Promise<DataUsageTableGroups> {
    const { data } = await this._instance.post<DataUsageTableGroups.Server>('data_usage/groups', body);

    return new DataUsageTableGroups(data);
  }

  async getDataUsageTableDevices(body: DataUsageTableBodyRequisition): Promise<DataUsageTableDevices> {
    const { data } = await this._instance.post<DataUsageTableDevices.Server>('data_usage/devices', body);

    return new DataUsageTableDevices(data);
  }

  async getDataUsageDataChart(filter: DataUsageChartBodyType): Promise<DataUsageChartReturnType> {
    const body = {
      groups: filter.groups?.map((group) => group.id) || [],
      min_date: filter.startDate,
      max_date: filter.endDate,
      device_id: filter.deviceId,
    };

    const route = filter.deviceId ? 'data_usage/device_page_chart' : 'data_usage/chart';

    const { data } = await this._instance.post(route, body);

    const dataUsageDashboard = data.chart_data.map((chart: DataUsageDashboard.Server) => new DataUsageDashboard(chart));

    const averageConsumption = new DataUsageIndicator({ dailyAverage: data.chart_cards.total_daily_average, value: data.chart_cards.total });
    const wifi = new DataUsageIndicator({
      value: data.chart_cards.wifi_total,
      percentage: data.chart_cards.wifi_percentage,
      dailyAverage: data.chart_cards.wifi_daily_average,
    });

    const mobile = new DataUsageIndicator({
      value: data.chart_cards.mobile_total,
      percentage: data.chart_cards.mobile_percentage,
      dailyAverage: data.chart_cards.mobile_daily_average,
    });

    return {
      dataUsageDashboard,
      averageConsumption,
      wifi,
      mobile,
    };
  }
}
