import styled from 'styled-components';
import { EuiCallOut } from '@elastic/eui';

export const Popup = styled(EuiCallOut)`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  border-left: none;
  border-radius: 6px;

  .euiCallOutHeader__title {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  p {
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 18px;
  }
`;
