import { ComponentBreadcrumb } from 'components';
import { IconPulsusLogo } from 'icons/pulsus-logo/icon-pulsus-logo';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ConstantRoutes } from 'utilities';
import { HelperTheme } from 'helpers';
import { ComponentApplicationContainer } from 'modules/core/components';
import { NewsSlider } from './news-slider/news-slider';
import { devices_info, quicklinks } from './mock';
import * as style from './page-home.style';
import { QuicklinksCard } from './quicklinks-card/quicklinks-card';
import { ContactCards } from './contact-card/contact-cards';
import { DevicesInfo } from './devices-info/component-devices-info';

export function PageHome() {
  const theme_color = HelperTheme.getTheme().main;
  const history = useNavigate();
  const { t } = useTranslation();

  return (
    <ComponentApplicationContainer test_id="page__home">
      <ComponentBreadcrumb items={ConstantRoutes.HOME.breadcrumbs} />
      <style.Wrapper>
        <style.TopContainer>
          <style.TopLeft>
            <IconPulsusLogo test_id="page__home-pulsus-logo" width={200} height={35} color="#525252" />
            <h1 className="theme-color">{t('pages.home.welcome1')} Username,</h1>
            <h1>{t('pages.home.welcome2')}</h1>
          </style.TopLeft>
          <style.TopRight>
            <h4>{t('pages.home.devices.title')}</h4>
            <p>{t('pages.home.devices.text')}</p>
            <style.DevicesCards>
              {devices_info.map((item) => {
                return (
                  <Link key={item.number + item.text} data-testid="page__home-devices-cards" to="/gestao-de-dispositivos">
                    <DevicesInfo icon={item.icon} number={item.number} text={item.text} />
                  </Link>
                );
              })}
            </style.DevicesCards>
          </style.TopRight>
        </style.TopContainer>

        <style.MidContainer>
          <style.NewsContainer>
            <h3 className="theme-color">{t('pages.home.highlights.title')}</h3>
            <style.SliderContainer>
              <NewsSlider t={t} history={history} />
            </style.SliderContainer>
          </style.NewsContainer>

          <style.ContactContainer>
            <h3 className="theme-color">{t('pages.home.contact.title')}</h3>
            <ContactCards t={t} color={theme_color} history={history} />
          </style.ContactContainer>
        </style.MidContainer>

        <style.QuicklinksContainer>
          <div className="texts">
            <h2 className="theme-color">Quicklinks</h2>
            <p>{t('pages.home.quicklinks.text')}</p>
          </div>
          <style.CardsContainer>
            {quicklinks.map((item) => {
              return <QuicklinksCard icon={item.icon} title={item.title} text={item.text} link={item.link} history={history} key={item.link} />;
            })}
          </style.CardsContainer>
        </style.QuicklinksContainer>
      </style.Wrapper>
    </ComponentApplicationContainer>
  );
}
