import type { DefaultTheme } from 'styled-components';
import type { DashboardTheme } from '../../../../../../types/styled';

export const light: DashboardTheme = {
  background: '#F2F2F2',
  paper: '#FFFFFF',
  font: '#4A4A4A',
  bar: '#d3dae6',
};

export const dark: DashboardTheme = {
  background: '#2F2F2F',
  paper: '#232323',
  font: '#FFFFFF',
  bar: '#181818',
};

export const mainTheme: DefaultTheme = {
  main: '#F15A22',
  palette: {
    AFGHANISTAN: '#58CD7E',
    ANDORRA: '#FF3B5E',
    ANGOLA: '#FFB93B',
    ARGENTINA: '#F15A22',
    AUSTRIA: '#FFFFFF',
    BAHREIN: '#4935C1',
    BELIZE: '#F6F9FC',
    BOLIVIA: '#191D28',
    BRAZIL: '#F9F9F9',
    BRUNEI: '#E2E2E2',
    BULGARIA: '#fafafa',
    CANADA: '#E6F1FA',
    CHILE: '#30A8FF',
    CHINA: 'rgba(0, 109, 228, 0.2)',
    COLOMBIA: '#343741 ',
    COMOROS: '#FF4C0C',
    CROATIA: '#e0e5ee',
    CUBA: '#c7c7c7',
    DENMARK: '#ececec',
    DJBOUTI: '#3DDB85',
    ECUADOR: '#696969',
    EGYPT: '#bfbfbf',
    EMIRATES: '#C8C8C8',
    ENGLAND: '#E3E3E3',
    FINLAND: '#AAA',
    FRANCE: '#0071C2',
    GERMANY: '#1A1C21',
    GREECE: '#626262',
    GUINEA: '#6DCCB1',
    HAITI: '#1a1c21',
    HUNGARY: 'rgba(19, 34, 149, 0.1)',
    ICELAND: '#ABB4C4',
    INDIA: '#5A606B',
    INDONESIA: '#F2F2F2',
    IRA: '#62CE9B',
    IRAQ: '#F3F6FB',
    ITALY: '#67758D',
    JAMAICA: '#EBEBEB',
    JAPAN: '#4B4B4B',
    JORDAN: '#64748F',
    LAOS: '#B1B2B4',
    MALTA: 'rgba(105, 112, 125, 0.2)',
    NABOO: '#0077CC',
    PAKISTAN: '#8A6A0A',
    PALESTINE: '#FFEDED',
    PORTUGAL: '#D3DAE6',
    QATAR: '#EB996E',
    SPAIN: '#69707D',
    UKREIN: '#a6a6a6',
    URUGUAY: '#007E77',
    WHITE: '#FFFFFF',
    ZAMBIA: '#E6F9F7',
  },
  contrast: '#FFF',
  dashboardTheme: {
    ...light,
  },
};
