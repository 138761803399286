import { ComponentPasswordField, ComponentToast, DialogComponent } from 'components';
import { BannerUnlinkDeviceAlert } from 'icons';
import { HelperAuthentication } from 'helpers';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { useMemo, useState } from 'react';
import type { Toast } from 'components/toast/component-toast.contracts';
import { useTranslation } from 'react-i18next';
import * as style from './ComponentDeleteModal.style';
import type { DeleteModalProps, JWTPostMessage } from './ComponentDeleteModal.contracts';

export function ComponentDeleteModal(props: DeleteModalProps) {
  const { open, password, onClose, onChange, onConfirm } = props;
  const [toast, setToast] = useState<Toast[]>([]);
  const { t } = useTranslation();

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  async function checkLoginAndPassword() {
    const token = localStorage.getItem('@jwt_token');
    const decodedJwt = HelperAuthentication.decodeJwtFrom(token) as JWTPostMessage;
    const validLogin = await servicePulsusWeb.checkLoginInformations(decodedJwt.email, password);
    if (validLogin) {
      onConfirm();
    } else {
      setToast([
        {
          title: t('components.component_delete_modal.error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('components.component_delete_modal.error_description'),
        },
      ]);
    }
  }

  return (
    <DialogComponent width="100%" height="486px" maxWidth="780px" open={open} onClose={() => onClose()}>
      <style.ModalContainer data-testid="component-delete-modal-container">
        <style.Left data-testid="component-delete-modal-left">
          <BannerUnlinkDeviceAlert width="auto" />
        </style.Left>
        <style.Right data-testid="component-delete-modal-right">
          <style.TextBox data-testid="component-delete-modal-right-texts">
            <style.Title data-testid="component-delete-modal-right-title">{t('components.component_delete_modal.title')}</style.Title>
            <style.Description data-testid="component-delete-modal-right-description">
              {t('components.component_delete_modal.description')}
            </style.Description>
          </style.TextBox>
          <ComponentPasswordField label={t('components.component_delete_modal.password_label')} value={password} onChange={(e) => onChange(e)} />
        </style.Right>
        <style.Actions data-testid="component-delete-modal-actions">
          <style.CancelButton data-testid="component-delete-modal-actions-cancel" onClick={() => onClose()}>
            {t('components.component_delete_modal.cancel')}
          </style.CancelButton>
          <style.ConfirmButton data-testid="component-delete-modal-actions-confirm" fill onClick={() => checkLoginAndPassword()}>
            {t('components.component_delete_modal.confirm')}
          </style.ConfirmButton>
        </style.Actions>
      </style.ModalContainer>
      <ComponentToast
        data-testid="component-delete-modal-toast"
        toasts={toast}
        toastLifeTimeMs={5000}
        side="right"
        dismissToast={() => setToast([])}
      />
    </DialogComponent>
  );
}
