export class Resource {
  public status: number;
  public inventory: boolean;
  public pro: boolean;
  public basicSecurity: boolean;
  public legacy: boolean;
  public id: number;
  public identifier: string;
  public basic: boolean;
  public intelligence: boolean;

  constructor(resource: Resource.Server) {
    this.legacy = resource.legacy;
    this.inventory = resource.inventory;
    this.basic = resource.basic;
    this.basicSecurity = resource.basic_security;
    this.pro = resource.pro;
    this.intelligence = resource.intelligence;
    this.id = resource.id;
    this.status = resource.status;
    this.identifier = resource.identifier;
  }
}

export namespace Resource {
  export type Server = {
    status: number;
    inventory: boolean;
    pro: boolean;
    basic_security: boolean;
    legacy: boolean;
    id: number;
    identifier: string;
    basic: boolean;
    intelligence: boolean;
  };
}
