export class UserManagement {
  public id: number;
  public username: string;
  public status: number;
  public firstName: string;
  public groupId: string;
  public lastLogin: string;
  public actions: number;
  public costCenter: string;
  public email: string;
  public workPhone: string;
  public devices: { id: number; identifier: string; management_type: string; model: string; status: number; platform: string }[];

  constructor(data: UserManagement.Server) {
    this.id = data.id;
    this.username = data.username;
    this.firstName = data.firstName;
    this.status = data.status;
    this.groupId = data.group_id;
    this.lastLogin = data.last_login;
    this.actions = data.status;
    this.devices = data.devices;
    this.costCenter = data.costCenter;
    this.workPhone = data.workPhone;
    this.email = data.email;
  }
}

export namespace UserManagement {
  export type Server = {
    id: number;
    username: string;
    firstName: string;
    status: number;
    group_id: string;
    last_login: string;
    costCenter: string;
    email: string;
    workPhone: string;
    actions: number;
    devices: { id: number; identifier: string; management_type: string; model: string; status: number; platform: string }[];
  };
}
