import type { RouteObject } from 'react-router-dom';
import { Outlet } from 'react-router-dom';
import { ChromeDefaultRoutes } from './ChromeRoutes';

export const ChromeRoutes: RouteObject[] = [
  {
    path: '/chromeOS',
    element: <Outlet />,
    children: [...ChromeDefaultRoutes],
  },
];
