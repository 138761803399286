import type { ReactElement } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { EuiFormRow, EuiTextArea } from '@elastic/eui';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';
import { HelperAuthentication } from 'helpers';
import * as S from './ModalFenceConfigureComponent.styles';
import type { ModalFenceConfigureComponentProps } from './ModalFenceConfigureComponent.contracts';
import { ButtonModalFormComponent } from './ModalBody/ButtonModalForm/ButtonModalFormComponent';

export const ModalFenceConfigureComponent = ({ onClose, fields }: ModalFenceConfigureComponentProps): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'geofence.modal_edit' });

  const [description, setDescription] = useState<string>(fields.description);
  const [loading, setLoading] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>('');
  const [isMaxCharacter, setIsMaxCharacter] = useState(false);
  const tenantId = HelperAuthentication.decodingTenantId();

  const handleClickButton = async (): Promise<void> => {
    setMessageError('');
    try {
      setLoading(true);
      await ServicePulsusValquiria.editGeofenceDescription(tenantId, fields.id, description);
      onClose(null, true);
      setMessageError('');
    } catch (err) {
      setMessageError(t('step1.errorMessage'));
    } finally {
      setLoading(false);
    }
  };

  const handleDescriptionInput = (e) => {
    setMessageError('');
    setDescription(e.target.value);
    if (e.target.value.length >= 250) {
      return setIsMaxCharacter(true);
    }
    return e.target.value.length < 250 && setIsMaxCharacter(false);
  };

  return (
    <S.ModalContainer onClose={() => onClose(null, false)}>
      <S.Title>{t('title')}</S.Title>

      <S.Body>
        <EuiFormRow label={t('step1.inputName')} fullWidth>
          <S.StyledFieldText disabled value={fields?.name || ''} fullWidth />
        </EuiFormRow>
        <EuiFormRow label={t('step1.inputDescription')} helpText={isMaxCharacter && `${t('step1.placeholder')}`} fullWidth>
          <EuiTextArea
            maxLength={250}
            placeholder={t('step1.helpText')}
            fullWidth
            value={description}
            rows={4}
            onChange={(e) => {
              handleDescriptionInput(e);
            }}
          />
        </EuiFormRow>
        {messageError.length > 0 && (
          <S.Error title={t('step1.error')} color="warning" iconType="alert">
            <p>{messageError}</p>
          </S.Error>
        )}
        <S.Buttons>
          <ButtonModalFormComponent
            loading={loading}
            onClick={() => handleClickButton()}
            onClose={() => {
              onClose(null, false);
            }}
          />
        </S.Buttons>
      </S.Body>
    </S.ModalContainer>
  );
};
