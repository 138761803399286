import type { RouteObject } from 'react-router-dom';
import {
  EmbeddedChromeOSIntegration,
  EmbeddedChromeOSConfiguration,
  EmbeddedConfigurationsList,
  EmbeddedChromeOSDevice,
  EmbeddedChromeOSReport,
} from 'modules/chromeOS/embedded/index';
import { EmbeddedPolicies } from '../embedded/policies/EmbeddedPolicies';

export const EmbeddedChromeRoutes: RouteObject[] = [
  {
    path: 'chromeOS/integration',
    element: <EmbeddedChromeOSIntegration />,
  },
  {
    path: 'chromeOS/configuration',
    element: <EmbeddedChromeOSConfiguration />,
  },
  {
    path: 'chromeOS/configurations-list',
    element: <EmbeddedConfigurationsList />,
  },
  {
    path: 'chromeOS/device',
    element: <EmbeddedChromeOSDevice />,
  },
  {
    path: 'chromeOS/report',
    element: <EmbeddedChromeOSReport />,
  },
  {
    path: 'chromeOS/policies',
    element: <EmbeddedPolicies />,
  },
];
