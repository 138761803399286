import React from 'react';
import PropTypes from 'prop-types';

export function IconIos({ width, height, fill }) {
  return (
    <svg width={width} height={height} viewBox="0 0 10 12" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.3517 6.36308C8.36895 8.17561 9.98213 8.77877 10 8.78647C9.98636 8.829 9.74224 9.64603 9.1501 10.49C8.63822 11.2196 8.10696 11.9465 7.27007 11.9616C6.44774 11.9764 6.18331 11.486 5.24315 11.486C4.30328 11.486 4.00949 11.9465 3.23106 11.9764C2.42324 12.0062 1.8081 11.1874 1.29197 10.4604C0.237316 8.97343 -0.568657 6.25853 0.513561 4.42592C1.05118 3.51584 2.01196 2.93954 3.05479 2.92477C3.84804 2.91001 4.59678 3.44523 5.08171 3.44523C5.56633 3.44523 6.4762 2.80158 7.43272 2.89611C7.83315 2.91236 8.95717 3.05385 9.67893 4.08417C9.62077 4.11933 8.33775 4.84775 8.3517 6.36309V6.36308ZM6.80622 1.91233C7.23509 1.40605 7.52375 0.701261 7.445 0C6.82681 0.0242305 6.07928 0.401744 5.63586 0.907747C5.23848 1.35584 4.89046 2.07303 4.98436 2.76041C5.67341 2.8124 6.37732 2.41893 6.80622 1.91233"
        fill={fill}
      />
    </svg>
  );
}

IconIos.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

IconIos.defaultProps = {
  width: '10px',
  height: '12px',
  fill: '#FFFFFF',
};
