import type { EventName, ModalConfigurationValue } from './DevicesStatisticsComponent.contracts';
import { mdiAlert, mdiBattery40, mdiBatteryAlert, mdiCrosshairsOff, mdiMemory, mdiServer, mdiAndroid } from '@mdi/js';
import Icon from '@mdi/react';
import * as S from './DevicesStatisticsComponent.styles';
import { IconCellComponent } from '../IconCell/IconCellComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import type { Toast } from 'modules/core/components';
import { ComponentResourceChecker, ComponentToast } from 'modules/core/components';
import { ConstantResource } from 'modules/core/utilities';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import { useEffect, useMemo, useRef, useState } from 'react';
import { DashboardBenchmarks } from 'modules/operational/entities/DashboardBenchmarks/DashboardBenchmarks.entity';

export const DevicesStatisticsComponent = () => {
  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const [benchmarks, setBenchmarks] = useState<DashboardBenchmarks>(null!);
  const [configsValues, setConfigsValues] = useState<ModalConfigurationValue>({ max_hours: 0, min_battery: 0, min_storage: 0 });
  const [toast, setToast] = useState<Toast[]>([]);
  const [serverAndroidBenchmarks, setServerAndroidBenchmarks] = useState<DashboardBenchmarks.ServerAndroid>(null!);
  const [isLoadingAlerts, setIsLoadingAlerts] = useState({ default: true, android: true });

  const serviceHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);

  const statusDefaultLightRef = useRef<HTMLDivElement>(null);
  const statusAndroidLightRef = useRef<HTMLDivElement>(null);

  const getDevicesBenchmarks = async (modalConfigs: ModalConfigurationValue) => {
    try {
      const promises = [serviceHagalaz.getAlertsBenchmarks(modalConfigs), serviceHagalaz.getAndroidBenchmarks()];
      setConfigsValues(modalConfigs);

      const [alerts, android] = (await Promise.all(promises)) as [DashboardBenchmarks.ServerDefault, DashboardBenchmarks.ServerAndroid];
      const dashboardBenchmarks = new DashboardBenchmarks({ ...alerts, ...android });

      setBenchmarks(dashboardBenchmarks);
      setServerAndroidBenchmarks(android);
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoadingAlerts({ default: false, android: false });
    }
  };

  const changeAlertsConfigs = async (modalConfigs: ModalConfigurationValue) => {
    setIsLoadingAlerts({ android: false, default: true });
    try {
      const alertsDefault = await serviceHagalaz.getAlertsBenchmarks(modalConfigs);
      setConfigsValues(modalConfigs);

      const dashboardBenchmarks = new DashboardBenchmarks({ ...alertsDefault, ...serverAndroidBenchmarks });

      setBenchmarks(dashboardBenchmarks);
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoadingAlerts({ android: false, default: false });
    }
  };

  useEffect(() => {
    window.parent.postMessage({ payloadType: 'COMPONENT_READY' }, process.env.REACT_APP_RUBY_LINK!);

    const listener = ({ data, origin }: MessageEvent) => {
      if (data.payloadType === 'DASHBOARD_SETTINGS' && origin.includes('pulsus.mobi')) {
        if (!serverAndroidBenchmarks) {
          getDevicesBenchmarks(data.body);
          setInterval(() => {
            getDevicesBenchmarks(data.body);
          }, 30000);
        } else {
          changeAlertsConfigs(data.body);
        }
      }
    };

    window.addEventListener('message', listener);

    return () => {
      window.removeEventListener('message', listener);
    };
  }, [serverAndroidBenchmarks]);

  useEffect(() => {
    const blinkInterval = setInterval(() => {
      if (statusAndroidLightRef.current && statusDefaultLightRef.current) {
        statusAndroidLightRef.current.classList.toggle('transparent');
        statusDefaultLightRef.current.classList.toggle('transparent');
      }
    }, 400);

    return () => clearInterval(blinkInterval);
  }, []);

  const emitClickEvent = (eventName: EventName) => {
    window.parent.postMessage({ payloadType: 'OPEN_PANEL', body: eventName }, process.env.REACT_APP_RUBY_LINK!);
  };

  const isNegativeBenchmark = (value: number) => {
    return value > 0 ? '#F44336' : '#4CAF50';
  };

  return (
    <>
      <S.Container>
        <S.BlockContainer>
          <S.BlockTitle>
            <span>{t('devicesStatistics.allDevices')}</span>
          </S.BlockTitle>
          <section>
            {isLoadingAlerts.default ? <S.StyledCircularProgress /> : <S.StatusLight ref={statusDefaultLightRef} />}
            <IconCellComponent
              color={isNegativeBenchmark(benchmarks?.deviceByHours || 0)}
              downloadedIcon="reloadWarning"
              value={benchmarks?.deviceByHours || 0}
              onClick={() => emitClickEvent('one_day_without_sync')}
              text={t('devicesStatistics.timeOff', { timeOffValue: configsValues?.max_hours })}
            />
            <IconCellComponent
              onClick={() => emitClickEvent('low_battery')}
              color={isNegativeBenchmark(benchmarks?.deviceByBattery || 0)}
              icon={mdiBattery40}
              value={benchmarks?.deviceByBattery || 0}
              text={t('devicesStatistics.battery', { batteryValue: configsValues?.min_battery })}
            />
            <IconCellComponent
              onClick={() => emitClickEvent('low_storage')}
              color={isNegativeBenchmark(benchmarks?.deviceByStorage || 0)}
              icon={mdiServer}
              value={benchmarks?.deviceByStorage || 0}
              text={t('devicesStatistics.storage', { storageValue: configsValues?.min_storage })}
            />
          </section>
        </S.BlockContainer>
        <S.BlockContainer>
          <S.BlockTitle>
            <Icon path={mdiAndroid} color="#818181" size={1} />
            <span>Android</span>
          </S.BlockTitle>
          <section>
            {isLoadingAlerts.android ? <S.StyledCircularProgress /> : <S.StatusLight ref={statusAndroidLightRef} />}
            <IconCellComponent
              downloadedIcon="HomeCellphone"
              onClick={() => emitClickEvent('inactive_launcher')}
              color={isNegativeBenchmark(benchmarks?.devicesByInactiveLauncher || 0)}
              value={benchmarks?.devicesByInactiveLauncher || 0}
              text={t('devicesStatistics.launcher')}
            />
            <IconCellComponent
              onClick={() => emitClickEvent('inactive_device_admin')}
              icon={mdiAlert}
              color={isNegativeBenchmark(benchmarks?.devicesByInactiveDeviceAdmin || 0)}
              value={benchmarks?.devicesByInactiveDeviceAdmin || 0}
              text={t('devicesStatistics.admin')}
            />
            <IconCellComponent
              onClick={() => emitClickEvent('gps_off')}
              icon={mdiCrosshairsOff}
              color={isNegativeBenchmark(benchmarks?.devicesByGpsOff || 0)}
              value={benchmarks?.devicesByGpsOff || 0}
              text={t('devicesStatistics.gps')}
            />
            <ComponentResourceChecker identifier={ConstantResource.DASHBOARD.BATTERY_HEALTH}>
              <IconCellComponent
                onClick={() => emitClickEvent('low_battery_health')}
                icon={mdiBatteryAlert}
                color={isNegativeBenchmark(benchmarks?.devicesByLowBatteryHealth || 0)}
                value={benchmarks?.devicesByLowBatteryHealth || 0}
                text={t('devicesStatistics.batteryProblems')}
              />
            </ComponentResourceChecker>
            <IconCellComponent
              onClick={() => emitClickEvent('low_memory')}
              icon={mdiMemory}
              color={isNegativeBenchmark(benchmarks?.devicesByLowBatteryHealth || 0)}
              value={benchmarks?.devicesByLowMemory || 0}
              text={t('devicesStatistics.memory')}
            />
          </section>
        </S.BlockContainer>
      </S.Container>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
};
