import type { MenuDevicesIconsProps } from 'components/menu-devices-icons/component-menu-devices-icons.contracts';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.section`
  display: flex;
  gap: 20px;
`;

export const Divider = styled.div`
  margin-left: 15px;
`;

export const BgCircle = styled.div<MenuDevicesIconsProps>`
  ${({ isActive }) => css`
    padding: 8px;
    background: ${ConstantColors.BRAZIL};
    border-radius: 50%;
    cursor: pointer;
    pointer-events: ${isActive ? 'unset' : 'none'};
    transition: 0.2s;

    svg {
      color: #67758d;
      opacity: ${isActive ? 1 : 0.3};
    }

    img {
      color: #67758d;
      opacity: ${isActive ? 1 : 0.3};
    }

    &:hover {
      filter: brightness(95%);
      transition: 0.2s;
    }
  `}
`;

export const Section = styled.section`
  gap: 8px;
  display: flex;
`;

export const TooltipContent = styled.span`
  font-size: 11px;
  padding: 0px;
`;

export const ImagePulsusBackupContacts = styled.img`
  width: 24px;
  height: 24px;
`;
