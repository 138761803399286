import React from 'react';
import PropTypes from 'prop-types';
import * as style from './component-popup-error.style';

export function ComponentPopupError({ width, height, title, description }) {
  return (
    <style.Popup title={title} color="danger" iconType="alert" width={width} height={height}>
      <p>{description}</p>
    </style.Popup>
  );
}

ComponentPopupError.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
};

ComponentPopupError.defaultProps = {
  width: 'auto',
  height: 'auto',
};
