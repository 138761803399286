import { useTranslation } from 'react-i18next';
import * as style from './page-pulsus-insights-batteries.style';
import type { ChartFilterDaysProps } from './pulsus-insights-batteries.contracts';

export function ChartFilterDays({ chartDays, handleChartDaysChange, disabled }: ChartFilterDaysProps) {
  const { t } = useTranslation();

  return (
    <>
      <style.ChartLabel>{t('pages.pulsus_insights_batteries.chart_filter_days.label')}</style.ChartLabel>
      <style.ChartTypeSelector
        data-testid="chart_days_selector"
        isDisabled={disabled}
        legend="Período"
        options={[
          {
            id: 'seven_days',
            label: `${t('pages.pulsus_insights_batteries.chart_filter_days.options.seven')}`,
          },
          {
            id: 'fifteen_days',
            label: `${t('pages.pulsus_insights_batteries.chart_filter_days.options.fifteen')}`,
          },
          {
            id: 'thirty_days',
            label: `${t('pages.pulsus_insights_batteries.chart_filter_days.options.thirty')}`,
          },
        ]}
        idSelected={chartDays}
        onChange={handleChartDaysChange}
      />
    </>
  );
}
