import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: flex-start;
  justify-content: center;
  margin-top: 40px;

  .euiSwitch__label {
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-decoration-line: underline;
    color: ${ConstantColors.ITALY};
  }
  .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
    background-color: ${({ theme }) => theme.contrast} !important;
  }

  .euiSwitch__body {
    background-color: ${({ theme }) => theme.main} !important;
  }
`;

export const LeftContainer = styled.section`
  width: 575px;
  max-width: 55%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  header {
    width: 100%;
  }
`;

export const TitleThird = styled.h3`
  height: 40px;
`;

export const TextPrimary = styled.p`
  color: #67758d;
  line-height: 16px;
  font-weight: 500;
  max-width: 555px;
`;

export const TextSecondary = styled.p`
  color: #67758d;
  font-weight: 500;
  font-size: 13.2174px;
  line-height: 16px;
  margin: 0 auto;
  width: 350px;
`;

export const RightContainer = styled.section`
  width: 380px;
  max-width: 45%;
  height: 587.74px;
  background: #fafafa;
  padding: 50px 15px;
  border-radius: 10.574px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ImagePulsusAddCertificate = styled.img`
  width: 305px;
  max-width: 80%;
`;

export const TitleSecondary = styled.h2`
  line-height: 40px;
  margin-top: 26px;
  max-width: 365px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  margin: auto 0 auto auto;
`;

export const InstructionButton = styled.button`
  font-size: 15px;
  color: #30a8ff;
`;

export const Button = styled(EuiButton)`
  background: ${ConstantColors.CHILE} !important;
  border-color: ${ConstantColors.CHILE} !important;

  width: 199px;
  font-size: 15px;
  margin-left: 10px;
`;
