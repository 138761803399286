import { EuiRadioGroup } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-width: 483px;
  gap: 30px;
  padding-bottom: 25px;
  max-width: 483px;
  .item {
    width: 100%;
  }
`;

export const CardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
`;

export const VpnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 483px;
  gap: 30px;
  background: #ffffff;
  border-radius: 6px;

  .tags-container {
    display: flex;
    gap: 10px;
  }

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  .bottom {
    p {
      font-weight: 600;
    }
  }

  .euiPopover {
    max-width: 270px;
  }

  .searchField {
    border-color: red !important;
  }

  .autocomplete {
    margin-top: 10px;
    div {
      height: 40px;
      padding: 0;
      div {
        border-color: green !important;
        input {
          padding: 0px 15px !important;
        }
        fieldset:active {
          border-color: green;
        }
      }
      label {
        font-size: 16px;
        top: -7px;
      }
    }
  }
`;

export const VpnRadios = styled(EuiRadioGroup)`
  ${({ theme }) => css`
    display: flex;
    gap: 20px;
    margin-top: 15px;

    .euiRadioGroup__item {
      margin: 0;
    }

    .euiRadio .euiRadio__input:checked + .euiRadio__circle {
      border-color: ${theme.main};
      background-color: ${theme.main};
    }

    .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
      border-color: #98a2b3;
      background-color: #98a2b3;
    }

    &:focus {
      outline-color: ${theme.main};
    }
  `}
`;
