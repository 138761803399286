import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 1000px;
  padding: 15px 30px 20px 60px;
  height: 600px;
`;

export const ContainerBox = styled.div`
  display: flex;
  justify-content: space-around;
  width: 525px;

  div.imgBox {
    display: flex;
    height: 398px;
    overflow: hidden;
    align-items: center;
  }
`;

export const DescriptionBox = styled.div`
  font-size: 25px;
  font-weight: 700;
  line-height: 28px;
  .euiTextColor {
    color: #6f6f6f;
  }
  .colored {
    color: ${({ theme }) => theme.main};
  }
`;

export const Content = styled.div`
  div.item {
    padding-top: 55px;
  }
`;

export const GroupSelection = styled.div``;
