/* eslint-disable react/jsx-one-expression-per-line */
import { IconWarning } from 'icons/warning/icon-warning';
import { useTypedTranslation } from 'modules/core/hooks';
import * as style from './ManagementFilterComponent.styles';
import type { ManagementFilterProps } from './ManagementFilterComponent.contracts';

export function ManagementFilterComponent({ onClick, color, initials, quantity, title, isSelected }: ManagementFilterProps) {
  const { t } = useTypedTranslation<'devices.management_filter'>('devices.management_filter');

  return (
    <style.Container isSelected={isSelected} onClick={() => onClick()}>
      <div className="icon" style={{ backgroundColor: color }}>
        {initials === 'DA' && <IconWarning />}
        <p>{initials}</p>
      </div>
      <div>
        <div className="title-container">
          <p className="title">{title}</p>
          {initials === 'DA' && <style.Deprecated>DEPRECATED</style.Deprecated>}
        </div>
        <p className="devices-quantity">
          {quantity} {t('devices_count')}
        </p>
      </div>
    </style.Container>
  );
}
