type Status = 'LOCK' | 'UNLOCK' | 'PENDING' | 'DELETED';

export class Sim {
  public pulsusId: string;
  public iccId: string;
  public userName: string;
  public phoneProvider: string;
  public status: Status;
  public registerAt: string;
  public simSerialNumber: string;
  public chip_id: string;

  constructor(
    currentLanguage: string,
    created_at: string,
    device_id: number,
    id: number,
    service_provider: string,
    sim_serial_number: string,
    status: string,
    updated_at: string,
    user_name: string
  ) {
    this.registerAt = new Date(created_at).toLocaleDateString(currentLanguage, {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    });
    this.pulsusId = String(device_id || '-');
    this.chip_id = String(id);
    this.phoneProvider = service_provider || '-';
    this.iccId = sim_serial_number || '-';
    this.simSerialNumber = sim_serial_number;
    this.status = this.getStatus(status);
    this.userName = user_name || '-';
  }

  getStatus(status: string): Status {
    const availableStatus: Record<string, Status> = {
      DESBLOQUEADO: 'UNLOCK',
      UNLOCKED: 'UNLOCK',
      LOCKED: 'LOCK',
      BLOQUEADO: 'LOCK',
      DELETED: 'DELETED',
      NEED_TO_LOCK: 'PENDING',
      PIN_CHANGED: 'LOCK',
    };

    return availableStatus?.[status] || 'PENDING';
  }
}

export namespace Sim {
  export interface Data {
    pulsusId: string;
    iccId: string;
    userName: string;
    phoneProvider: string;
    status: Status;
    registerAt: string;
  }

  export interface Server {
    id: string;
    status: string;
    userName: string;
    service_provider: string;
    created_at: string;
    device_id: string;
    sim_serial_number: string;
    updated_at: string;
  }
}
