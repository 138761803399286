import styled from 'styled-components';
import { ConstantColors } from 'utilities';
import type { ManagementFilterProps } from './ManagementFilterComponent.contracts';

export const Container = styled.main<Pick<ManagementFilterProps, 'isSelected'>>`
  display: flex;
  cursor: pointer;
  transition: 0.3s;
  margin-left: 5px;
  padding: 5px 7px;

  border-radius: 7px;
  background-color: ${(props) => (props.isSelected ? ConstantColors.BRUNEI : ConstantColors.BRAZIL)};

  &:hover {
    transition: 0.3s;
    transform: translateY(-2px);

    div:not(:first-child) {
      text-decoration: underline;
    }
  }

  p {
    font-size: 12px;
    white-space: nowrap;
  }

  .title-container {
    display: flex;
    align-items: center;
  }

  .devices-quantity {
    font-size: 11px;
  }

  .icon {
    width: 35px;
    height: 35px;
    margin-right: 5px;
    color: ${ConstantColors.AUSTRIA};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    position: relative;

    svg {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

export const Deprecated = styled.p`
  margin-left: 5px;
  padding: 0 7px;
  font-size: 10px !important;
  color: ${ConstantColors.AUSTRIA};
  background-color: #919191;
  border-radius: 3px;
`;
