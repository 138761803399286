import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  padding-left: 50px;
`;

export const Title = styled.h1``;

export const Text = styled.p`
  margin: 20px 0;
  max-width: 950px;
`;

export const NavContainer = styled.section``;

export const TabsContainer = styled.section`
  display: flex;
  column-gap: 20px;
`;

export const SelectAll = styled.p`
  color: ${ConstantColors.CHILE};
  align-self: center;
  margin-left: 10px;
  cursor: pointer;

  &:hover {
    transition: 0.3s;
    transform: translateY(-2px);
    text-decoration: underline;
  }
`;

export const TagsContainer = styled.section`
  display: flex;
  column-gap: 20px;
  margin: 20px 0;
`;
