import type { EuiRadioProps } from '@elastic/eui';
import { EuiButton, EuiCallOut, EuiFilterButton, EuiFilterGroup, EuiRadio, EuiSwitch } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';
import type { TriggerOptionProps } from './FenceConfigurationComponent.contracts';
import { Container as RadioContainer } from 'modules/core/components/ComponentRubyRadioButtons/ComponentRubyRadioButtons.style';

export const Container = styled.div`
  display: flex;
  gap: 46px;
  align-items: flex-end;
`;

export const ActionFields = styled.div`
  width: 60%;
  height: 287px;
  background-color: #f2f2f2;
  border-radius: 14px;
  padding: 15px 30px;
  h3 {
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    color: #636363;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const TriggerFields = styled.div`
  width: 30%;
  max-height: 240px;
  background-color: #f2f2f2;
  border-radius: 14px;
  align-self: flex-start;
  h3 {
    all: unset;
    font-size: 15px;
    font-weight: 600;
    width: 100%;
    color: #636363;
  }

  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const Soon = styled.div`
  display: flex;
  gap: 15px;

  span {
    font-weight: bold;
  }

  &.triggers {
    margin-top: 1rem;
  }
`;

export const Action = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  span {
    font-weight: bold;
  }

  &.column-right {
    padding-left: 35px;
  }
`;

export const Line = styled.div`
  height: 100%;
  border: 1px solid #e5e5e5;
`;

export const Subtitle = styled.div`
  display: flex;
  gap: 10px;
  margin-left: 5px;

  h4 {
    font-size: 14px;
    color: #67758d;
  }
`;

export const FenceLabel = styled.label`
  color: ${({ theme }) => theme.palette.ARGENTINA};
  font-weight: bold;
  font-size: 14px;
  margin-left: 3px;
`;

export const StyledRadio = styled(EuiRadio)<EuiRadioProps>`
  margin-top: 7px;

  .euiRadio__label {
    padding-left: 24px !important;
    font-weight: 600;
    font-size: 13px;
    color: #636363;
  }

  .euiRadio__circle {
    background-color: ${({ checked }) => (checked ? ConstantColors.COMOROS : 'inherit')} !important;
    border-color: ${({ checked }) => (checked ? ConstantColors.COMOROS : '#c9cbcd')} !important;
  }
`;

export const StyledSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background-color: #e3e3e3 !important;
  }
`;

export const Trigger = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  align-items: flex-start;
`;

export const Triggers = styled.div`
  display: flex;
  gap: 66px;
`;

export const RetangleTriggerOption = styled.div<TriggerOptionProps>`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background: ${({ highlight }) => (highlight ? ConstantColors.CHILE : '#CFCFCF')};
`;

export const WrapperActions = styled.div`
  height: 100%;
  display: flex;
`;

export const WrapperTriggers = styled.div`
  padding: 24px 40px;
  display: flex;
  flex-direction: column;
`;

export const StyledCallOut = styled(EuiCallOut)`
  font-size: 12px !important;
`;

export const ContainerFields = styled.div`
  height: 30px;

  ${RadioContainer} {
    margin-left: 50px;

    p {
      margin-top: 2px;
    }
  }
`;

export const EmailButton = styled(EuiButton)`
  width: 195px;
  height: 32px;
  font-size: 14px;
  background-color: #006bb426;
  margin-left: 50px;

  span {
    font-weight: 500;
  }
`;

export const LanguageGroup = styled(EuiFilterGroup)`
  box-shadow: none;
  max-width: 70%;
  border-radius: 6px;
`;

export const LanguageButton = styled(EuiFilterButton)`
  width: 30px;

  &.euiButtonEmpty--text:focus,
  &.euiFilterButton-hasActiveFilters {
    background-color: ${({ theme }) => theme.main};
    color: #fff;
  }

  span {
    text-decoration: none !important;
  }
`;

export const LanguageContainer = styled.div`
  margin-left: 50px;

  p {
    font-size: 12px;
    font-weight: 700;
    color: #343741;
    margin-bottom: 3px;
  }
`;
