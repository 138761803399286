import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LinkContainer = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 40px;
`;
