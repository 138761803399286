import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: 30px 0px;
  justify-content: flex-start;
  align-items: center;
`;

export const Left = styled.div`
  display: flex;
  height: 100%;
  width: 50%;
`;

export const Right = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  gap: 50px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.p`
  width: 350px;
  font-weight: 700;
  font-size: 25.7391px;
  line-height: 28px;
  color: ${({ theme }) => theme.main};
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #474747;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  .euiFieldPassword:focus {
    background-color: white;
    background-size: 100% 100%;
    box-shadow:
      0 1px 1px -1px rgba(152, 162, 179, 0.2),
      0 4px 4px -2px rgba(152, 162, 179, 0.2),
      inset 0 0 0 1px rgba(15, 39, 118, 0.1);

    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%);`}
    `}
  }
`;

export const RadioBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: ${({ theme }) => theme.main};
    background-color: ${({ theme }) => theme.main};
  }

  .euiTextColor--default {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #474747;
  }

  .euiTextColor--secondary {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #474747;
  }
`;

export const RadioDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #474747;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  gap: 20px;
  .euiButton:hover,
  .euiButton:focus {
    color: #ffffff;
    background: ${({ theme }) => theme.main};
  }
  .euiButtonEmpty:hover,
  .euiButtonEmpty:focus {
    background: transparent;
  }
`;

export const Button = styled(EuiButton)`
  min-width: 200px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: ${({ theme }) => theme.main};
  border: none;
  border-radius: 4.73624px;
`;

export const LinkButton = styled(EuiButtonEmpty)``;

export const SecondTitle = styled.p`
  width: 400px;
  font-weight: 700;
  font-size: 25.7391px;
  line-height: 28px;
  color: ${({ theme }) => theme.main};
`;

export const SecondRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 50%;
  height: 100%;
`;

export const UpContent = styled.div`
  height: 65%;
  gap: 30px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;
export const DownContent = styled.div`
  height: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

export const SecondFooter = styled.div`
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  gap: 20px;
  .euiButton:hover,
  .euiButton:focus {
    color: #ffffff;
    background: ${({ theme }) => theme.main};
  }
  .euiButtonEmpty:hover,
  .euiButtonEmpty:focus {
    background: transparent;
  }
`;
