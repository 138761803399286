import type { Flag } from './security/page-security-settings.contracts';

export function GetCardFlagsAndroid() {
  const factoryResetFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const accountManagementFlag: Flag[] = [{ id: 0, label: 'DO 2.1+' }];

  const factoryResetProtectionFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const googlePlayProtectFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const unknownAppsFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const debuggerModeFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const LocalizationFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const fireWallFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const firmwareUpdateFlags: Flag[] = [
    { id: 1, label: 'LG 2.1+' },
    { id: 2, label: 'SAMSUNG 3.0+' },
  ];

  const resourcesManagementFlags: Flag[] = [{ id: 0, label: 'DO 2.1+' }];

  const dataProtectionFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const vpnFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const deviceCameraFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const screenshotFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const workProfilePeriodFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 1, label: 'LG 2.0+' },
    { id: 2, label: 'SAMSUNG 2.0+' },
  ];

  const personalPlayStoreManagementFlags: Flag[] = [
    { id: 0, label: 'DO 2.1+' },
    { id: 0, label: 'LG 2.0+' },
    { id: 0, label: 'SAMSUNG 2.0+' },
  ];

  return {
    factoryResetFlags,
    accountManagementFlag,
    factoryResetProtectionFlags,
    googlePlayProtectFlags,
    unknownAppsFlags,
    debuggerModeFlags,
    resourcesManagementFlags,
    LocalizationFlags,
    fireWallFlags,
    firmwareUpdateFlags,
    dataProtectionFlags,
    vpnFlags,
    deviceCameraFlags,
    screenshotFlags,
    workProfilePeriodFlags,
    personalPlayStoreManagementFlags,
  };
}
