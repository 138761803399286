import styled from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { EuiButton } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  position: relative;
  background: #f9f9f9;
`;

export const SaveBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  position: fixed;
  bottom: 0;
  z-index: 100;

  width: 100%;
  height: 58px;
  background: linear-gradient(180deg, #ffffff 0%, #e0e0e0 100%);
  box-shadow: 0px 3.16359px 3.16359px rgba(0, 0, 0, 0.25);

  .link-style {
    margin: 0 5px 0 20px;
    color: ${ConstantColors.CHILE};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const SaveButton = styled(EuiButton)`
  color: ${ConstantColors.AUSTRIA};
  background-color: ${ConstantColors.ARGENTINA};
  margin-left: 15px;
  margin-right: 280px;

  &:hover {
    background-color: ${ConstantColors.ARGENTINA} !important;
  }

  &:focus {
    background-color: ${ConstantColors.ARGENTINA} !important;
  }
`;
