import styled from 'styled-components';

export const SettingsBox = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 55px;
  padding-left: 55px;
  border-radius: 6px;
`;
