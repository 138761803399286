import { EuiButton, EuiButtonEmpty, EuiTabs } from '@elastic/eui';
import styled from 'styled-components';

export const Title = styled.h1`
  font-size: 30px;
  font-weight: 700;
  color: ${({ theme }) => theme.main};
  margin-bottom: 16px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: auto;
  height: calc((723px / 3) * 2);

  @media (min-height: 720px) {
    height: 723px;
  }
`;

export const StyledTab = styled(EuiTabs)`
  .euiTab.euiTab-isSelected,
  .euiTab.euiTab-isSelected .euiTab__content {
    color: ${({ theme }) => theme.main} !important;
  }

  .euiTab:focus {
    background: none;
  }

  .euiTab.euiTab-isSelected {
    box-shadow: inset 0 -2px 0 ${({ theme }) => theme.main};
  }
`;

export const Content = styled.div`
  display: flex;
  gap: 36px;
  padding-bottom: 58px;
`;

export const BottomContent = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: flex-end;
`;

export const GroupContainer = styled.div`
  display: flex;
  gap: 15px;
  padding-top: 48px;
  flex-direction: column;
  max-height: 416px;
  overflow: auto;
`;

export const TabsContainer = styled.div`
  width: 50%;
`;

export const GroupTitle = styled.p`
  color: #1a1c21;
  font-size: 15px;
  font-weight: 700;
`;

export const TagList = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-height: 416px;
  overflow: auto;
`;

export const GroupList = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-height: 416px;
  overflow: auto;
`;

export const StyledButton = styled(EuiButton)`
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;

export const Wrapper = styled.div``;

export const StyledButtonEmpty = styled(EuiButtonEmpty)`
  color: #30a8ff;
`;
