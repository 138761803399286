import { HelperAuthentication } from 'helpers';
import React, { useEffect, useMemo, useState } from 'react';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';

export const ResourceContext = React.createContext<string[]>([]);

export const isResourceEnabled = (context: string[], resource: string): boolean => {
  return context.includes(resource);
};

export const ResourceContextProvider = ({ children }) => {
  const [resources, setResources] = useState<string[]>([]);

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  useEffect(() => {
    (async () => {
      if (HelperAuthentication.isAuthenticated()) {
        const response = await servicePulsusWeb.getResources();
        setResources(response.data);
      }
    })();
  }, []);

  return <ResourceContext.Provider value={resources}>{children}</ResourceContext.Provider>;
};
