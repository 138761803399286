/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import { EuiFieldSearch, EuiToolTip } from '@elastic/eui';
import { Skeleton } from '@mui/material';
import { useTypedTranslation } from 'modules/core/hooks';
import type { GroupListComponentProps } from './GroupListComponent.contracts';
import * as S from './GroupListComponent.styles';
import { TextEllipsisComponent } from 'modules/core/components';

export const GroupListComponent = ({ setTags, tags, loading }: GroupListComponentProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');

  const { t } = useTypedTranslation<'devices.add_group_modal.group_list'>('devices.add_group_modal.group_list');

  const addFilteredGroup = (groupId: number, tagId: string) => {
    const newArrTags = [...tags];

    const tagIndex = tags.findIndex((tag) => tag.id === tagId);
    const groupIndex = tags[tagIndex].groups.findIndex((group) => group.id === groupId);

    newArrTags[tagIndex].groups[groupIndex] = { ...tags[tagIndex].groups[groupIndex], selected: true };

    setTags([...newArrTags]);
  };

  const addGroup = (groupIndex: number, tagIndex: number) => {
    const newTags = [...tags];

    newTags[tagIndex].groups[groupIndex] = {
      ...newTags[tagIndex].groups[groupIndex],
      selected: true,
    };

    setTags(newTags);
  };

  const filterGroups = () => {
    return tags.map((tag) => ({
      ...tag,
      groups: tag.groups.filter((group) => group.name?.toLowerCase().includes(inputValue?.toLowerCase()) && group.selected !== true),
    }));
  };

  const filteredGroups = useMemo(() => {
    return filterGroups();
  }, [inputValue, tags]);

  const renderedItems = {};

  const renderGroupsOrFilteredGroups = () => {
    return inputValue.length > 0 ? (
      <>
        {filteredGroups.map((tag) => {
          return tag.groups.map((group) => {
            const hasAlreadyRendered = !!renderedItems[group.id];
            renderedItems[group.id] = group.id;
            return (
              <>
                {!group?.selected && !hasAlreadyRendered && (
                  <S.GroupItem key={group.id}>
                    <EuiToolTip content={group.name}>
                      <TextEllipsisComponent style={{ height: 18 }}>{group.name}</TextEllipsisComponent>
                    </EuiToolTip>
                    <S.AddGroupButton onClick={() => addFilteredGroup(group.id, tag.id)}>{t('include')}</S.AddGroupButton>
                  </S.GroupItem>
                )}
              </>
            );
          });
        })}
      </>
    ) : (
      <>
        {tags.map((tag, tagIndex) => {
          return tag.groups.map((group, groupIndex) => {
            const hasAlreadyRendered = !!renderedItems[group.id];
            renderedItems[group.id] = group.id;

            return (
              <>
                {!group?.selected && !hasAlreadyRendered && (
                  <S.GroupItem key={group.id}>
                    <EuiToolTip content={group.name}>
                      <TextEllipsisComponent style={{ height: 18 }}>{group.name}</TextEllipsisComponent>
                    </EuiToolTip>
                    <S.AddGroupButton onClick={() => addGroup(groupIndex, tagIndex!)}>{t('include')}</S.AddGroupButton>
                  </S.GroupItem>
                )}
              </>
            );
          });
        })}
      </>
    );
  };

  return (
    <>
      <S.GroupTitle>{t('title')}</S.GroupTitle>
      <EuiFieldSearch
        placeholder={t('inputPlaceholder')}
        isLoading={loading}
        disabled={loading}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
      />
      <S.GroupList>
        {loading ? (
          <>
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
            <Skeleton variant="rectangular" width="100%" height={50} />
          </>
        ) : (
          renderGroupsOrFilteredGroups()
        )}
      </S.GroupList>
    </>
  );
};
