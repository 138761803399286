import React from 'react';

type IconChipInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconChipInCicle({ width, height, elipse_fill, icon_fill }: IconChipInCicleProps) {
  return (
    <svg width={width || 45} height={height || 45} viewBox="0 0 45 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill={elipse_fill || '#C92F2F'} />
      <path
        d="M27 14.666H20.3333L15.3333 19.666V29.666C15.3333 30.5827 16.0833 31.3327 17 31.3327H27C27.9166 31.3327 28.6666 30.5827 28.6666 29.666V16.3327C28.6666 15.416 27.9166 14.666 27 14.666ZM27 29.666H17V20.3577L21.025 16.3327H27V29.666ZM19.5 18.8327H21.1666V22.166H19.5V18.8327ZM22 18.8327H23.6666V22.166H22V18.8327ZM24.5 18.8327H26.1666V22.166H24.5V18.8327Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
