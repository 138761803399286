import styled from 'styled-components';
import Icon from '@mdi/react';
import SettingsIcon from '@mui/icons-material/Settings';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 570px;
  gap: 30px;
  justify-content: space-between;
  height: 100%;
  padding: 16px;

  .euiTab {
    padding: 3px 16px;
  }

  .euiTabs--bottomBorder {
    box-shadow: inset 0 -1px 0 transparent;
  }

  .euiTab.euiTab,
  .euiTab.euiTab .euiTab__content {
    color: #2196f3;
  }

  .euiTab.euiTab-isSelected,
  .euiTab.euiTab-isSelected .euiTab__content {
    color: #000000 !important;
  }

  .euiTab.euiTab-isSelected {
    box-shadow: inset 0 -3px 0 #000000;
  }
`;

export const WrapperBody = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 16px;
`;

export const Header = styled.div`
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 4px;
  p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0em;
  }
  padding: 0 16px;
`;

export const StyledIcon = styled(Icon)`
  color: black;
  height: 12px;
  width: auto;
  margin-top: 3px;
`;

export const IconBox = styled.div``;

export const WrapTabTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: fixed;
  bottom: -16px;
  padding: 0 26px 26px 26px;
  background-color: #f9f9f9;
`;

export const WrapperLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 300px;
  width: 100%;
`;

export const CloseModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: -18px;
`;

export const WrapperTabs = styled.div`
  padding: 0 16px;
`;

export const Content = styled.div`
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const TitleTab = styled.h3`
  font-size: 10px;
`;

export const ConfigTitle = styled.span`
  font-style: 20px;
  font-weight: 600;
`;

export const ConfigIcon = styled(SettingsIcon)`
  width: 15px !important;
  height: 15px !important;
`;

export const ConfigEditTitle = styled.p`
  font-size: 12px !important;
  color: #adadad;
  margin-top: 3px;
`;
