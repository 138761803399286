export class AppsList {
  application: {
    icon: React.ReactNode;

    name: string;

    identifier: string;
  };

  appId: string;

  numberOfGroups: number | null;

  installations: {
    total: number;
    updated: number;
    installed: number;
    isPlayStore: boolean;
  };

  constructor(
    application: {
      name: string;
      icon: React.ReactNode;
      identifier: string;
    },
    appId: string,
    numberOfGroups: number | null,
    installations: {
      total: number;
      updated: number;
      installed: number;
      isPlayStore: boolean;
    }
  ) {
    this.appId = appId;
    this.application = application;
    this.numberOfGroups = numberOfGroups;
    this.installations = installations;
  }
}

interface APIApplicationVersion {
  version: string;
  version_code: number;
  enterprise_app: boolean;
}

export interface APIAppsApplication {
  id: number;
  identifier: string;
  name: string;
  status: number;
  kind: number;
  platform: string;
  current_version_id: number;
  current_version: APIApplicationVersion;
  group_count: number | null;
  device_count_in_groups: number | null;
  installed_device_count: number | null;
  updated_device_count: number | null;
}

export interface APIApps {
  total: number;
  apps: APIAppsApplication[];
}

export interface APIDistributionCounter {
  kind: number;
  platform: 'Android' | 'iOS' | 'Chrome';
  enterprise_app: boolean;
  dist_count: number;
}
