import type { Geofence } from 'modules/management/entities';

export type AdminEmail = {
  label: string;
};

export type TriggerValuesAdaptProps = {
  blockDevice: boolean;
  fenceIn: boolean;
  fenceOut: boolean;
  both: boolean;
  emailAlert: boolean;
  emailAlertLanguage: string;
  adminEmails: AdminEmail[];
  soundAlert: boolean;
  soundAlertAction: string;
};

export type GeofenceFieldsProps = {
  registerEvent?: boolean;
  blockDevice: boolean;
  fenceIn: boolean;
  fenceOut: boolean;
  both: boolean;
  emailAlert: boolean;
  emailAlertLanguage: string;
  adminEmails: AdminEmail[];
  soundAlert: boolean;
  soundAlertAction: string;
};

export type ChangedFences = {
  [key: number]: number;
};

export enum TriggerStatus {
  ON_ENTER = 0,
  ON_EXIT = 1,
  BOTH = 2,
}

export type InitialState = {
  fields: GeofenceFieldsProps;
  geofences: Geofence[];
  changedFences: ChangedFences;
};

export enum GeofenceActionType {
  UPDATE_FIELDS = 'UPDATE_FIELDS',
  UPDATE_GEOFENCE_AREAS = 'UPDATE_GEOFENCE_AREAS',
  UPDATE_CHANGED_FENCES = 'UPDATE_CHANGED_FENCES',
}

export type UpdateFields = {
  type: GeofenceActionType.UPDATE_FIELDS;
  payload: GeofenceFieldsProps;
};

export type UpdateGeofenceAreas = {
  type: GeofenceActionType.UPDATE_GEOFENCE_AREAS;
  payload: Geofence[];
};

export type UpdateChangedFences = {
  type: GeofenceActionType.UPDATE_CHANGED_FENCES;
  payload: ChangedFences;
};

export type ReducerActions = UpdateFields | UpdateGeofenceAreas | UpdateChangedFences;
