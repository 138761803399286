import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import type { StoreProps } from 'stores/stores.contracts';
import * as style from './security-cards-group.style';
import { GetCardFlagsAndroid } from '../flags-label-android';
import { QuickSettingsCard } from '../quick-settings-card';
import { PermissionToggle } from './permission-toggle';

export const SecurityCardsGroup: React.FC = () => {
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const [initialPolicy, set_initial_policy] = useState({});
  const dispatch = useDispatch();

  const [values, set_values] = useState({
    factoryReset: policy?.factoryResetDisabled || false,
    accountManagement: policy?.modifyAccountsDisabled || false,
    factoryResetProtection: policy?.frpAdminEmails || false,
    googlePlayProtect: policy?.advancedSecurityOverrides?.googlePlayProtectVerifyApps || 'VERIFY_APPS_USER_CHOICE',
    unknownApps: policy?.advancedSecurityOverrides?.untrustedAppsPolicy || 'ALLOW_INSTALL_DEVICE_WIDE',
    debuggerMode: policy?.advancedSecurityOverrides?.developerSettings || 'DEVELOPER_SETTINGS_ALLOWED',
    keyguard: {
      keyguardActiveAll: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'ALL_FEATURES'),
      notifyOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'NOTIFICATIONS'),
      cameraOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'CAMERA'),
      customNotifyOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'UNREDACTED_NOTIFICATIONS'),
      ignoreTrustedAgents: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'TRUST_AGENTS'),
      fingerprintOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'DISABLE_FINGERPRINT'),
    },
  });

  const [resourcesManagement, setResourcesManagement] = useState<boolean>(false);
  const options = GetCardFlagsAndroid();
  const { t } = useTranslation();

  async function populateFields() {
    const aux: Record<string, unknown> = {
      factoryReset: policy?.factoryResetDisabled || false,
      accountManagement: policy?.modifyAccountsDisabled || false,
      factoryResetProtection: policy?.frpAdminEmails || false,
      googlePlayProtect: policy?.advancedSecurityOverrides?.googlePlayProtectVerifyApps || 'VERIFY_APPS_USER_CHOICE',
      unknownApps: policy?.advancedSecurityOverrides?.untrustedAppsPolicy || 'ALLOW_INSTALL_DEVICE_WIDE',
      debuggerMode: policy?.advancedSecurityOverrides?.developerSettings || 'DEVELOPER_SETTINGS_ALLOWED',
    };

    if (!_.includes(policy.keyguardDisabledFeatures, 'KEYGUARD_DISABLED_FEATURE_UNSPECIFIED')) {
      aux.keyguard = {
        keyguardActiveAll: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'ALL_FEATURES'),
        notifyOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'NOTIFICATIONS'),
        cameraOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'CAMERA'),
        customNotifyOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'UNREDACTED_NOTIFICATIONS'),
        ignoreTrustedAgents: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'TRUST_AGENTS'),
        fingerprintOff: policy?.keyguardDisabledFeatures && _.includes(policy.keyguardDisabledFeatures, 'DISABLE_FINGERPRINT'),
      };
    }
    set_values((prevState) => ({ ...prevState, ...aux }));
  }

  function handleChange(value: string | boolean, key: string) {
    set_values((prevState) => {
      const aux = {};
      aux[key] = value;
      return { ...prevState, ...aux };
    });
  }

  function keyguardHandleChange(value: boolean, key: string) {
    const aux: any = values.keyguard;
    aux[key] = value;
    handleChange(aux, 'keyguard');
  }

  function translationData() {
    const keyguardAux: string[] = [];
    Object.entries(values.keyguard).forEach(([key, value]) => {
      if (value) {
        switch (key) {
          case 'keyguardActiveAll':
            keyguardAux.push('ALL_FEATURES');
            break;
          case 'notifyOff':
            keyguardAux.push('NOTIFICATIONS');
            break;
          case 'cameraOff':
            keyguardAux.push('CAMERA');
            break;
          case 'customNotifyOff':
            keyguardAux.push('UNREDACTED_NOTIFICATIONS');
            break;
          case 'ignoreTrustedAgents':
            keyguardAux.push('TRUST_AGENTS');
            break;
          case 'fingerprintOff':
            keyguardAux.push('DISABLE_FINGERPRINT');
            break;
          default:
            break;
        }
      }
    });
    const aux = {
      factoryResetDisabled: values.factoryReset,
      modifyAccountsDisabled: values.accountManagement,
      frpAdminEmails: values.factoryResetProtection,
      keyguardDisabledFeatures: keyguardAux.length > 0 ? keyguardAux : ['KEYGUARD_DISABLED_FEATURE_UNSPECIFIED'],
      advancedSecurityOverrides: {
        ...policy.advancedSecurityOverrides,
        googlePlayProtectVerifyApps: values.googlePlayProtect,
        untrustedAppsPolicy: values.unknownApps,
        developerSettings: values.debuggerMode,
      },
    };
    dispatch({
      type: 'SET_POLICY_CHANGES',
      payload: aux,
    });
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initialPolicy)) {
      populateFields();
      set_initial_policy(policy);
    }
  }, [policy]);

  useEffect(() => {
    translationData();
  }, [values]);

  return (
    <style.Container>
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.factory_reset_title')}
        label={t('pages.pulsus_security_cards.labels.factory_reset')}
        flags={options.factoryResetFlags}
        active={values.factoryReset}
        onChange={() => {
          handleChange(!values.factoryReset, 'factoryReset');
        }}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.factory_reset_description')}</style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.account_management_title')}
        label={t('pages.pulsus_security_cards.labels.account_management')}
        flags={options.accountManagementFlag}
        active={values.accountManagement}
        onChange={() => {
          handleChange(!values.accountManagement, 'accountManagement');
        }}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.account_management_description')}</style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.factory_reset_protection_title')}
        label={`${t('pages.pulsus_security_cards.labels.factory_reset_protection')} gabitestepulsus@gmail.com`}
        flags={options.factoryResetProtectionFlags}
        active={values.factoryResetProtection}
        onChange={() => {
          handleChange(!values.factoryResetProtection, 'factoryResetProtection');
        }}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.factory_reset_protection_description')}</style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.google_play_protect_title')}
        label={t('pages.pulsus_security_cards.labels.google_play_protect')}
        flags={options.googlePlayProtectFlags}
        active={values.googlePlayProtect !== 'VERIFY_APPS_USER_CHOICE'}
        onChange={() => {
          const enforcement_type = values.googlePlayProtect === 'VERIFY_APPS_USER_CHOICE' ? 'VERIFY_APPS_ENFORCED' : 'VERIFY_APPS_USER_CHOICE';
          handleChange(enforcement_type, 'googlePlayProtect');
        }}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.google_play_protect_description')}</style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.unknown_apps_title')}
        label={t('pages.pulsus_security_cards.labels.unknown_apps')}
        flags={options.unknownAppsFlags}
        active={values.unknownApps !== 'ALLOW_INSTALL_DEVICE_WIDE'}
        onChange={() => {
          const unknown_apps_policy = values.unknownApps === 'ALLOW_INSTALL_DEVICE_WIDE' ? 'DISALLOW_INSTALL' : 'ALLOW_INSTALL_DEVICE_WIDE';
          handleChange(unknown_apps_policy, 'unknownApps');
        }}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.unknown_apps_description')}</style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.debugger_mode_title')}
        label={t('pages.pulsus_security_cards.labels.debugger_mode')}
        flags={options.debuggerModeFlags}
        active={values.debuggerMode !== 'DEVELOPER_SETTINGS_ALLOWED'}
        onChange={() => {
          const block_debugging_mode =
            values.debuggerMode === 'DEVELOPER_SETTINGS_ALLOWED' ? 'DEVELOPER_SETTINGS_DISABLED' : 'DEVELOPER_SETTINGS_ALLOWED';
          handleChange(block_debugging_mode, 'debuggerMode');
        }}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.debugger_mode_description')}</style.CardDescription>
      </QuickSettingsCard>
      <PermissionToggle />
      <QuickSettingsCard
        title={t('pages.pulsus_security_cards.labels.keyguard_management_title')}
        label={t('pages.pulsus_security_cards.labels.keyguard_management')}
        flags={options.resourcesManagementFlags}
        active={resourcesManagement}
        noSwitch
        onChange={() => setResourcesManagement(!resourcesManagement)}
      >
        <style.CardDescription>{t('pages.pulsus_security_cards.text.keyguard_management_description')}</style.CardDescription>
        <style.CustomCheckbox
          className="full"
          id="keyguardActiveAll"
          label={t('pages.pulsus_security_cards.labels.keyguard_management')}
          checked={values.keyguard.keyguardActiveAll}
          onChange={() => keyguardHandleChange(!values.keyguard.keyguardActiveAll, 'keyguardActiveAll')}
        />
        <style.CheckboxGroup>
          <style.CustomCheckbox
            className="checkbox_item"
            id="notifyOff"
            checked={!values.keyguard.keyguardActiveAll ? values.keyguard.notifyOff : true}
            label={t('pages.pulsus_security_cards.labels.keyguard_deactivate_notifications')}
            onChange={() => {
              if (!values.keyguard.keyguardActiveAll) {
                keyguardHandleChange(!values.keyguard.notifyOff, 'notifyOff');
              }
            }}
          />
          <style.CustomCheckbox
            className="checkbox_item"
            id="cameraOff"
            checked={!values.keyguard.keyguardActiveAll ? values.keyguard.cameraOff : true}
            label={t('pages.pulsus_security_cards.labels.keyguard_deactivate_camera')}
            onChange={() => {
              if (!values.keyguard.keyguardActiveAll) {
                keyguardHandleChange(!values.keyguard.cameraOff, 'cameraOff');
              }
            }}
          />
          <style.CustomCheckbox
            className="checkbox_item"
            id="customNotifyOff"
            checked={!values.keyguard.keyguardActiveAll ? values.keyguard.customNotifyOff : true}
            label={t('pages.pulsus_security_cards.labels.keyguard_deactivate_custom_notifications')}
            onChange={() => {
              if (!values.keyguard.keyguardActiveAll) {
                keyguardHandleChange(!values.keyguard.customNotifyOff, 'customNotifyOff');
              }
            }}
          />
          <style.CustomCheckbox
            className="checkbox_item"
            id="ignoreTrustedAgents"
            checked={!values.keyguard.keyguardActiveAll ? values.keyguard.ignoreTrustedAgents : true}
            label={t('pages.pulsus_security_cards.labels.keyguard_ignore_trusted_agents')}
            onChange={() => {
              if (!values.keyguard.keyguardActiveAll) {
                keyguardHandleChange(!values.keyguard.ignoreTrustedAgents, 'ignoreTrustedAgents');
              }
            }}
          />
          <style.CustomCheckbox
            className="checkbox_item"
            id="fingerprintOff"
            checked={!values.keyguard.keyguardActiveAll ? values.keyguard.fingerprintOff : true}
            label={t('pages.pulsus_security_cards.labels.keyguard_deactivate_fingerprint')}
            onChange={() => {
              if (!values.keyguard.keyguardActiveAll) {
                keyguardHandleChange(!values.keyguard.fingerprintOff, 'fingerprintOff');
              }
            }}
          />
        </style.CheckboxGroup>
      </QuickSettingsCard>
    </style.Container>
  );
};
