import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import * as style from './component-wifi-management.style';
import type { WifiManagementProps } from './component-wifi-management.contracts';

export function WifiManagement(props: WifiManagementProps) {
  const { title, icon, active, onClick, description } = props;
  const { t } = useTranslation();
  const status = {
    active: t('components.component_wifi_management.active'),
    neutral: t('components.component_wifi_management.neutral'),
    blocked: t('components.component_wifi_management.blocked'),
  };
  return (
    <style.Container>
      <style.Body>
        <style.Left>
          <style.IconBox>
            <style.CustomIconButtom className={active}>
              <Icon path={icon} color="white" size={1} />
            </style.CustomIconButtom>
          </style.IconBox>
          <style.Status>{status[active]}</style.Status>
        </style.Left>
        <style.Right>
          <style.Title>{title}</style.Title>
          <style.Description>{description}</style.Description>
        </style.Right>
      </style.Body>
      <style.ButtonGroup className="options">
        <style.ButtonGroupOption onClick={() => onClick('active')} className={active === 'active' ? 'active' : ''}>
          {status.active}
        </style.ButtonGroupOption>
        <style.ButtonGroupOption onClick={() => onClick('neutral')} className={active === 'neutral' ? 'active' : ''}>
          {status.neutral}
        </style.ButtonGroupOption>
        <style.ButtonGroupOption onClick={() => onClick('blocked')} className={active === 'blocked' ? 'active' : ''}>
          {status.blocked}
        </style.ButtonGroupOption>
      </style.ButtonGroup>
    </style.Container>
  );
}
