import { EuiTab } from '@elastic/eui';
import type { ComponentTabsProps } from './ComponentTabs.contracts';
import * as style from './ComponentTabs.style';

export function ComponentTabs({ tabs, selectedTab, onClick }: ComponentTabsProps) {
  return (
    <style.TabsConrainer>
      {tabs.map((tab) => {
        return (
          <EuiTab isSelected={tab.id === selectedTab} disabled={tab.disabled} onClick={() => onClick(tab.id)} key={tab.id}>
            {tab.name}
          </EuiTab>
        );
      })}
    </style.TabsConrainer>
  );
}
