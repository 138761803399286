import axios from 'axios';
import { ConstantServicesEndpoints } from 'utilities';
import { HelperAuthentication } from 'helpers';
import type { AxiosInstance } from 'axios';

export class ServicePulsusNjord {
  static instance: AxiosInstance;

  static getInstance() {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: process.env.REACT_APP_URL_PULSUS_NJORD_API,
        headers: {
          Authorization: HelperAuthentication.bearer(),
          'X-Schema': HelperAuthentication.decodedSchemaName(),
        },
      });
    }
    return this.instance;
  }

  static async getGeneralSettings(parent = 'LC02vz2feo') {
    const schema = 'tenant_gabi_qa2_793';
    const qa_policy_name = 'teste_grupo_QA';
    const { data } = await this.getInstance().get(
      `${ConstantServicesEndpoints.PULSUS_WEB.GROUP_POLICIES}?parent=${parent}&policy_name=${qa_policy_name}&x_schema=${schema}`
    );
    return data;
  }

  static async setGeneralSettings(data, dispatch, parent = 'LC02vz2feo') {
    const schema = 'tenant_gabi_qa2_793';
    const qa_policy_name = 'teste_grupo_QA';
    this.getInstance()
      .put(`${ConstantServicesEndpoints.PULSUS_WEB.GROUP_POLICIES}?parent=${parent}&policy_name=${qa_policy_name}&x_schema=${schema}`, data)
      .then(() => {
        dispatch({ type: 'SAVE_ANDROID_SETTINGS', payload: data });
      });
  }

  static async getVpnManagementAppList() {
    const schema = 'tenant_gabi_qa2_793';
    const { data } = await this.getInstance().get(`${ConstantServicesEndpoints.PULSUS_WEB.APPLICATIONS}?x_schema=${schema}`);
    return data;
  }
}
