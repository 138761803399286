import type { PolicyBody } from 'stores/stores.contracts';
import { BaseServiceInstance } from './BaseServiceInstance';

export class ServicePulsusTyr extends BaseServiceInstance {
  constructor() {
    super(process.env.REACT_APP_URL_PULSUS_TYR_API!);
  }

  async getInfos() {
    const { data } = await this._instance.get('/show_info');
    return data;
  }

  async getPolicy(customerId = 'my_customer', orgUnitId = '03ph8a2z25ltuvm') {
    const { data } = await this._instance.get(`/get_current_policies?org_unit_id=${orgUnitId}&customer_id=${customerId}`);
    return data;
  }

  async setPolicy(policy: PolicyBody, customerId = 'my_customer', orgUnitId = '03ph8a2z25ltuvm') {
    try {
      const data = await this._instance.post(`/patch_policy?org_unit_id=${orgUnitId}&customer_id=${customerId}`, policy);
      return data.status;
    } catch (error) {
      return false;
    }
  }

  async getConfigurations(customer_id = 'my_customer') {
    try {
      const { data } = await this._instance.get(`/${customer_id}/org_units/list?report_type=children`);
      return data.organizationUnits;
    } catch (error) {
      return false;
    }
  }

  async getGroups(customer_id = 'my_customer') {
    try {
      const { data } = await this._instance.get(`/groups/list?customer_id=${customer_id}`);
      return data.groups;
    } catch (error) {
      return false;
    }
  }

  async runCommand(device_id: string, command: string, customer_id = 'my_customer') {
    try {
      const data = await this._instance.post(`/${customer_id}/chrome_devices/issue_command?device_id=${device_id}`, {
        commandType: command,
        payload: '',
      });
      return data;
    } catch (error) {
      return false;
    }
  }

  async deprovisionDevice(device_id: string, customer_id = 'my_customer') {
    try {
      const data = await this._instance.post(`/${customer_id}/chrome_devices/action?device_id=${device_id}`, {
        action: 'deprovision',
        deprovisionReason: 'retiring_device',
      });
      return data;
    } catch (error) {
      return false;
    }
  }
}
