import type { Dict } from 'mixpanel-browser';
import mixpanel from 'mixpanel-browser';
import type { Administrator } from 'modules/core/entities/Administrator/Administrator.entity';
import type { Tenant } from 'modules/core/entities/Tenant/Tenant.entity';

mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY!);

// TODO: Use the commented isProductionEnv when all the tests are over
// const isProductionEnv = process.env.REACT_APP_ENVIRONMENT === 'production';
const isProductionEnv = process.env.REACT_APP_ENVIRONMENT === 'production' || process.env.REACT_APP_ENVIRONMENT === 'staging';

const actions = {
  identify: (id: string) => {
    if (isProductionEnv) mixpanel.identify(id);
  },
  alias: (id: string) => {
    if (isProductionEnv) mixpanel.alias(id);
  },
  track: (name: string, tenant: Tenant, administrator: Administrator, props?: Dict) => {
    if (isProductionEnv) {
      mixpanel.track(name, {
        tenant_id: tenant.id,
        tenant: tenant.name,
        administrator_id: administrator.id,
        bundle: tenant.bundle.identifier,
        ...props,
      });
    }
  },
  people: {
    set: (props: Dict) => {
      if (isProductionEnv) mixpanel.people.set(props);
    },
  },
};

export const Mixpanel = actions;
