import styled from 'styled-components';

export const ComponentBox = styled.div`
  .MuiPaper-root-MuiAutocomplete-paper {
    background-color: red !important;
  }

  .MuiOutlinedInput-root {
    height: 50px !important;
  }
`;
