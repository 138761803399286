import { IconDevice, IconWorkProfile } from 'icons';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ConstantLanguages } from 'utilities';
import { useQuery } from 'hooks/use-query';
import { Events, Origins } from 'modules/core/entities';
import * as S from './modal-enroll-selector.style';
import type { EnrollObjectType, ModalEnrollSelectorProps } from './enroll-type-selector.contracts';

export function ModalEnrollSelector(props: ModalEnrollSelectorProps) {
  const { onSelect, managedMode } = props;
  const [modalEnrollContent, setModalEnrollContent] = useState<EnrollObjectType>(null!);
  const history = useNavigate();
  const query = useQuery();
  const { t } = useTranslation();

  const getPostMessageData = (event: MessageEvent<string>) => {
    if (event?.data && typeof event?.data === 'string') {
      const data: EnrollObjectType = JSON.parse(event.data);

      if (data) {
        const fullyManagedZeroTouch = JSON.stringify(data.fullyManaged.zeroTouch, undefined, 4);
        const fmWorkProfileZeroTouch = JSON.stringify(data.fmWorkProfile.zeroTouch, undefined, 4);
        const byodZeroTouch = JSON.stringify(data.byod.zeroTouch, undefined, 4);
        setModalEnrollContent({
          QRcode: data.QRcode,
          byod: {
            zeroTouch: byodZeroTouch,
          },
          fmWorkProfile: {
            zeroTouch: fmWorkProfileZeroTouch,
          },
          fullyManaged: {
            zeroTouch: fullyManagedZeroTouch,
            groupPin: data.fullyManaged.groupPin,
            packageDownloadLocation: data.fullyManaged.packageDownloadLocation,
          },
        });
      }
    }
  };

  useEffect(() => {
    i18next.changeLanguage(query.get('language') || ConstantLanguages.PT_BR);

    if (!managedMode) {
      sendPostMessage({
        from: Origins.NJORD,
        event: Events.ON_READY,
      });
    }

    window.addEventListener('message', getPostMessageData);

    return () => {
      window.removeEventListener('message', getPostMessageData);
    };
  }, []);

  const sendPostMessage = (payload: Record<string, unknown>) => {
    window.parent.postMessage(JSON.stringify(payload), String(process.env.REACT_APP_RUBY_LINK || '*'));
  };

  return (
    <S.Container>
      <S.Content>
        <S.ModalTitle>{managedMode ? t('pages.enroll.title.managedMode') : t('pages.enroll.title.default')}</S.ModalTitle>
        <S.SubTitle>{managedMode ? t('pages.enroll.subtitle.managedMode') : t('pages.enroll.subtitle.default')}</S.SubTitle>
        <S.CardGroup>
          <S.CardBox>
            <S.CustomCard title="">
              <S.CardContent
                onClick={() => {
                  if (managedMode && onSelect) {
                    onSelect('fully');
                  } else {
                    history('/enroll/options', {
                      state: {
                        type: 'fully',
                        data: modalEnrollContent?.fullyManaged?.zeroTouch,
                        groupPin: modalEnrollContent?.fullyManaged.groupPin,
                        packageDownloadLocation: modalEnrollContent?.fullyManaged.packageDownloadLocation,
                      },
                    });
                  }
                }}
              >
                <S.CardContentIcon color="#3ddb85">
                  <IconDevice width={15} height={24} fill="white" />
                </S.CardContentIcon>
                <S.ContentIconTitle>Fully Managed Device</S.ContentIconTitle>
              </S.CardContent>
            </S.CustomCard>
            <S.CardDescription>
              <p>{t('pages.enroll.fully_managed_device.title')}</p>
              <p className="green">{t('pages.enroll.fully_managed_device.green_text')}</p>
            </S.CardDescription>
          </S.CardBox>
          <S.CardBox>
            <S.CustomCard title="">
              <S.CardContent
                onClick={() => {
                  if (managedMode && onSelect) {
                    onSelect('fully_work_profile');
                  } else {
                    history('/enroll/options', {
                      state: {
                        type: 'fully_work_profile',
                        data: modalEnrollContent?.fmWorkProfile?.zeroTouch,
                        QRcode: modalEnrollContent?.QRcode,
                      },
                    });
                  }
                }}
              >
                <S.CardContentIcon color="#3ddb85">
                  <IconDevice width={15} height={24} fill="white" />
                </S.CardContentIcon>
                <div style={{ position: 'absolute', marginLeft: 45 }}>
                  <IconWorkProfile width={23} height={22} fill="#1483FF" />
                </div>
                <S.ContentIconTitle>Fully Managed with a Work Profile</S.ContentIconTitle>
              </S.CardContent>
            </S.CustomCard>
            <S.CardDescription>
              <p>{t('pages.enroll.fully_managed_WP.title')}</p>
              <p className="green">{t('pages.enroll.fully_managed_WP.green_text')}</p>
              <p className="blue">{t('pages.enroll.fully_managed_WP.blue_text')}</p>
            </S.CardDescription>
          </S.CardBox>
          <S.CardBox>
            <S.CustomCard title="">
              <S.CardContent
                onClick={() => {
                  if (managedMode && onSelect) {
                    onSelect('work_profile');
                  } else {
                    history('/enroll/options', {
                      state: {
                        type: 'work_profile',
                        data: modalEnrollContent?.byod?.zeroTouch,
                        QRcode: modalEnrollContent?.QRcode,
                      },
                    });
                  }
                }}
              >
                <S.CardContentIcon>
                  <IconWorkProfile width={46} height={44} fill="#1483FF" />
                </S.CardContentIcon>
                <S.ContentIconTitle>Work Profile (BYOD)</S.ContentIconTitle>
              </S.CardContent>
            </S.CustomCard>
            <S.CardDescription>
              <p>{t('pages.enroll.byod.title')}</p>
              <p className="blue">{t('pages.enroll.byod.blue_text')}</p>
            </S.CardDescription>
          </S.CardBox>
        </S.CardGroup>
        <S.WarningMessage>
          {managedMode ? (
            <>
              <p>
                <span>{t('pages.enroll.warning.managed_mode.text')}</span>
                <b>{t('pages.enroll.warning.managed_mode.bold_text')}</b>
                <span>{t('pages.enroll.warning.managed_mode.span')}</span>
              </p>
              <p>{t('pages.enroll.warning.managed_mode.text_secondary')}</p>
              <p>
                <span>{t('pages.enroll.warning.managed_mode.span_secondary')}</span>
                <b>{t('pages.enroll.warning.managed_mode.bold_secondary')}</b>
              </p>
            </>
          ) : (
            <>
              <p className="title">{t('pages.enroll.warning.default.bold_title')}</p>
              <p>{t('pages.enroll.warning.default.text')}</p>
              <p className="highlight">{t('pages.enroll.warning.default.highlight')}</p>
              <p>{t('pages.enroll.warning.default.letter')}</p>
              <p className="highlight">{t('pages.enroll.warning.default.highlight_secondary')}</p>
              <p>{t('pages.enroll.warning.default.text_secondary')}</p>
            </>
          )}
        </S.WarningMessage>
      </S.Content>
    </S.Container>
  );
}
