import { IconArticles, IconDollar, IconSmiley, IconSupport } from 'icons';
import { Link } from 'react-router-dom';
import type { ContactCardsProps, LinkWrapperProps } from '../page-home.contracts';
import * as style from './contact-cards.style';

export function ContactCards({ color, t }: ContactCardsProps) {
  const contacts = [
    {
      title: 'pages.home.contact.customer_success',
      text: 'Setor responsável pelo <b>sucesso da sua jornada na Pulsus,</b> pode nos escrever a: <a href = "mailto: cs@pulsus.mobi">cs@pulsus.mobi</a>',
      link: '',
      icon: <IconSmiley color={color} />,
    },

    {
      title: 'pages.home.contact.support',
      text: 'Precisa de <b>ajuda técnica com nosso produto?</b> Estamos aqui pra ajuda-lo: <a href = "mailto: suporte@pulsus.mobi">suporte@pulsus.mobi</a>',
      link: '',
      icon: <IconSupport color={color} />,
    },
    {
      title: 'pages.home.contact.financial',
      text: 'Dúvidas ou pendências podem ser encaminhadas para: <a href = "mailto: financeiro@pulsus.mobi">financeiro@pulsus.mobi</a>',
      link: '',
      icon: <IconDollar color={color} />,
    },
    {
      title: 'pages.home.contact.articles',
      text: 'Base de conhecimento para <b>conhecer a fundo</b> nossa ferramenta e funcionalidades.',
      link: '/articles',
      icon: <IconArticles color={color} />,
    },
  ];

  const LinkWrapper = ({ has_link, renderWrapper, children }: LinkWrapperProps) => (has_link ? renderWrapper(children) : children);

  return (
    <style.Wrapper>
      {contacts.map((item) => {
        return (
          <LinkWrapper key={item.link} has_link={!!item.link} renderWrapper={(children) => <Link to={item.link}>{children}</Link>}>
            <style.Card has_link={!!item.link} data-testid="page__home-contact-cards">
              <div>
                {item.icon}
                <h4 className="theme-color">{t(item.title)}</h4>
              </div>
              <p dangerouslySetInnerHTML={{ __html: item.text }} />
            </style.Card>
          </LinkWrapper>
        );
      })}
    </style.Wrapper>
  );
}
