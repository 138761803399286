export const BytesToHumanReadable = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes';

  const kb = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];
  const i = Math.floor(Math.log(bytes) / Math.log(kb));

  const formattedSize = parseFloat((bytes / Math.pow(kb, i)).toFixed(2));

  if (formattedSize >= 1000 && i < sizes.length - 1) {
    return parseFloat((bytes / Math.pow(kb, i + 1)).toFixed(2)) + ' ' + sizes[i + 1];
  }

  return `${formattedSize} ${sizes[i == -1 ? 0 : i]}`;
};
