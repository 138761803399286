import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100% !important;
  padding: 0 50px 0 50px;
  gap: 40px;
`;

export const TopActions = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  top: 32px;
  right: 50px;
  gap: 30px;
`;

export const InfoLink = styled(EuiButtonEmpty)`
  .euiButtonEmpty__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #30a8ff;
  }
  :focus,
  :hover {
    color: #30a8ff;
    background-color: transparent;
  }
`;

export const TopButton = styled(EuiButton)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.08px;
  color: #ffffff;
  background-color: #30a8ff !important;
  border: none;
  height: 32px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #67758d;
`;

export const Field = styled.div`
  width: 310px;
  margin-top: 30px;
`;

export const InfoBox = styled.div`
  display: flex;
  align-items: center;
`;

export const CountText = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 118%;
  color: #67758d;
`;

export const UndoLink = styled(EuiButtonEmpty)`
  .euiButtonEmpty__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 118%;
    color: #30a8ff;
  }
  :focus,
  :hover {
    color: #30a8ff;
    background-color: transparent;
  }
`;

export const GroupCount = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 12px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 114px;
  height: 21px;
  background: #e0e5ee;
  border-radius: 2.28px;
  padding: 5px;
`;

export const DeviceCount = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #4a4a4a;
`;

export const Line = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const Actions = styled.div`
  display: flex;
  gap: 10px;

  button.disabled,
  button:focus.disabled,
  button:hover.disabled {
    cursor: not-allowed !important;
    background: #f9f9f9 !important;
    path {
      fill: #ced2d9 !important;
    }
  }
`;

export const CustomIconButton = styled(IconButton)`
  background: #f9f9f9 !important;
`;

export const DialogContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 75px;
`;

export const DialogTitle = styled.p`
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
  color: ${({ theme }) => theme.main};
`;

export const DialogBody = styled.div`
  width: 100%;
  padding: 0 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;

  .euiFieldText:focus,
  .focused .euiComboBox__inputWrap {
    background-color: white;
    max-width: 100% !important;
    background-size: 100% 100% !important;
    box-shadow:
      0 1px 1px -1px rgba(152, 162, 179, 0.2),
      0 4px 4px -2px rgba(152, 162, 179, 0.2),
      inset 0 0 0 1px rgba(15, 39, 118, 0.1);

    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%) !important;`}
    `}
  }
`;

export const Flags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const TagsBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const CustomTagButton = styled(IconButton)`
  :hover {
    background: transparent !important;
  }
`;

export const TagsListLink = styled(EuiButtonEmpty)`
  .euiButtonEmpty__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 118%;
    color: #30a8ff;
  }
  :focus,
  :hover {
    color: #30a8ff;
    background-color: transparent;
  }
`;

export const DialogFooter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 25px;
  justify-content: end;
`;

export const CancelButton = styled(EuiButtonEmpty)`
  .euiButtonEmpty__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #30a8ff;
  }
  :focus,
  :hover {
    color: #30a8ff;
    background-color: transparent;
  }
`;

export const AddConfigButton = styled(EuiButton)`
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.08px;
  color: #ffffff;
  background-color: ${({ theme }) => theme.main} !important;
  border: none;
  height: 37px;
  width: 200px;
`;
