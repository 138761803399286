import { useTranslation } from 'react-i18next';
import * as style from '../tabs.style';
import { PeripheralsManagementCard } from './cards';

export function ChromeHardwareTab() {
  const { t } = useTranslation();
  return (
    <style.Content>
      <style.Title>{t('pages.pulsus_chrome_os_configuration.hardware')}</style.Title>
      <style.Body>
        <style.Side>
          <PeripheralsManagementCard />
        </style.Side>
        <style.Side />
      </style.Body>
    </style.Content>
  );
}
