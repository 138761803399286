import { Breadcrumb } from 'entities/breadcrumb';

export const ChromeBreadcrumbs = {
  PULSUS_CHROME_OS_INTEGRATION: {
    route: '/chromeOS/integration',
    breadcrumbs: [
      new Breadcrumb('components.breadcrumb.home', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_title', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_integration', ''),
    ],
  },
  PULSUS_CHROME_OS_DEVICE: {
    route: '/chromeOS/device',
    breadcrumbs: [
      new Breadcrumb('components.breadcrumb.home', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_devices', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_device', ''),
    ],
  },
  PULSUS_CHROME_OS_CONFIGURATION: {
    route: 'chromeOS/configuration',
    breadcrumbs: [
      new Breadcrumb('components.breadcrumb.home', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_title', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_configuration', ''),
    ],
  },
  PULSUS_CHROME_OS_CONFIGURATIONS_LIST: {
    route: 'chromeOS/configurations-list',
    breadcrumbs: [
      new Breadcrumb('components.breadcrumb.home', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_title', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_groups_list', ''),
    ],
  },
  PULSUS_CHROME_OS_REPORT: {
    route: 'chromeOS/report',
    breadcrumbs: [
      new Breadcrumb('components.breadcrumb.home', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_title', '/'),
      new Breadcrumb('components.breadcrumb.pulsus_chrome_os_report', ''),
    ],
  },
};
