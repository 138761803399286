import { ComponentBreadcrumb } from 'components';
import { ComponentFences, ComponentUploadModal } from 'modules/management/components';
import { ManagementBreadcrumbs } from 'modules/management/pages/ManagementBreadcrumbs';
import { useQuery } from 'hooks/use-query';
import { IconPulsusLogo } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import i18next from 'i18next';
import { RegisterComponent } from 'modules/management/components/Geofence/Registers/RegisterComponent';
import { useTranslation } from 'react-i18next';
import { EuiCallOut, EuiIcon, EuiTab } from '@elastic/eui';
import * as S from './PageGeofence.style';
import type { GeofencePageProps } from './PageGeofence.contracts';

export function PageGeofence({ isEmbedded }: GeofencePageProps) {
  const query = useQuery();
  const [selectedTabId, setSelectedTabId] = useState('geofencesList');

  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const [buttonAnimation, setButtonAnimation] = useState(false);
  const [hasChanges, setHasChanges] = useState<boolean>(false);

  const language = query.get('language');
  const { t } = useTranslation('translation', { keyPrefix: 'geofence' });

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  useEffect(() => {
    const observeElement = document.querySelector('#component-geofence-content');

    if (observeElement) {
      new ResizeObserver((entries) => {
        entries.forEach((item) => {
          const postMessageHeight = {
            target: 'geofence',
            event: 'ON_RESIZE',
            args: {
              height: item.contentBoxSize[0].blockSize,
            },
          };

          window.parent.postMessage(JSON.stringify(postMessageHeight), process.env.REACT_APP_RUBY_LINK!);
        });
      }).observe(observeElement);
    }
  }, []);

  const onSelectedTabChanged = (id: string) => {
    setSelectedTabId(id);

    setTimeout(() => {
      setButtonAnimation(true);
    }, 500);

    setTimeout(() => {
      setButtonAnimation(false);
    }, 1800);
  };

  const tabsContent = [
    {
      id: 'geofencesList',
      name: t('tabs.fences'),
      content: <ComponentFences hasChanges={hasChanges} />,
    },
    {
      id: 'registerList',
      name: t('tabs.registers'),
      content: <RegisterComponent embedded={isEmbedded} />,
    },
  ];

  const selectedTabsContent = useMemo(() => {
    return tabsContent.find((obj) => obj.id === selectedTabId)?.content;
  }, [selectedTabId, tabsContent]);

  const renderContent = () => {
    return (
      <>
        <S.BodyTop id="component-geofence-content">
          <S.HeadContainer>
            <S.LeftContainer>
              <S.Title>
                <IconPulsusLogo width={200} height={35} color="#525252" />
                <h1>GEOFENCE</h1>
              </S.Title>
              <S.SecondaryText>{t('text')}</S.SecondaryText>
              <EuiCallOut color="warning" iconType="help" style={{ maxWidth: '750px' }}>
                <S.AlertText>
                  <EuiIcon type="help" />
                  <div>
                    {t('alert')}
                    <S.AlertLink href="https://help.pulsus.mobi/pt-BR/articles/7224669-geofence" target="_blank" rel="noreferrer">
                      {t('link')}
                    </S.AlertLink>
                  </div>
                </S.AlertText>
              </EuiCallOut>
            </S.LeftContainer>
            <S.RightContainer>
              {selectedTabId === 'geofencesList' && (
                <S.CsvUploadButton className={`buttonGeofence ${buttonAnimation}`} iconType="plusInCircle" onClick={() => setIsOpenUploadModal(true)}>
                  {t('button_upload')}
                </S.CsvUploadButton>
              )}
            </S.RightContainer>
          </S.HeadContainer>
          <S.TabsContainer>
            <S.TabsSelector>
              {tabsContent.map((tab) => (
                <EuiTab key={tab.id} onClick={() => onSelectedTabChanged(tab.id)} isSelected={tab.id === selectedTabId}>
                  {tab.name}
                </EuiTab>
              ))}
            </S.TabsSelector>
          </S.TabsContainer>
          {selectedTabsContent}
        </S.BodyTop>
        {isOpenUploadModal && <ComponentUploadModal onClose={() => setIsOpenUploadModal(false)} emitChange={() => setHasChanges(true)} />}
      </>
    );
  };

  return (
    <>
      {!isEmbedded && <ComponentBreadcrumb items={ManagementBreadcrumbs.PULSUS_GEOFENCE.breadcrumbs} />}
      {!isEmbedded ? <S.Container>{renderContent()}</S.Container> : renderContent()}
    </>
  );
}
