import { ComponentBreadcrumb } from 'components';
import { ConstantRoutes } from 'utilities';
import { ComponentApplicationContainer } from 'modules/core/components';
import { ComponentPulsusInsightsSignalsContent } from 'components/pulsus-insights-batteries-signals/component-pulsus-insights-signals-content';

export function PagePulsusInsightsSignals() {
  return (
    <ComponentApplicationContainer>
      <ComponentBreadcrumb items={ConstantRoutes.PULSUS_INSIGHTS_SIGNAL.breadcrumbs} />
      <ComponentPulsusInsightsSignalsContent />
    </ComponentApplicationContainer>
  );
}
