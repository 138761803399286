import { initReactI18next } from 'react-i18next';
import i18n from 'i18next';

import pt_pages from 'translations/pt/pt-pages.json';
import en_pages from 'translations/en/en-pages.json';
import es_pages from 'translations/es/es-pages.json';
import pt_components from 'translations/pt/pt-components.json';
import en_components from 'translations/en/en-components.json';
import es_components from 'translations/es/es-components.json';
import { ManagementTranslations } from 'modules/management/translations';
import { CoreComponentsTranslations } from 'modules/core/translations';
import { OperationalTranslations } from 'modules/operational/translations';
import { ChromeOSTranslations } from 'modules/chromeOS/Translations';

i18n.use(initReactI18next).init({
  resources: {
    'pt-BR': {
      translation: {
        ...pt_pages,
        ...pt_components,
        ...ManagementTranslations.pt,
        ...OperationalTranslations.pt,
        ...CoreComponentsTranslations.pt,
        ...ChromeOSTranslations.pt,
      },
    },
    'en-US': {
      translation: {
        ...en_pages,
        ...en_components,
        ...ManagementTranslations.en,
        ...OperationalTranslations.en,
        ...CoreComponentsTranslations.en,
        ...ChromeOSTranslations.en,
      },
    },
    en: {
      translation: {
        ...en_pages,
        ...en_components,
        ...ManagementTranslations.en,
        ...OperationalTranslations.en,
        ...CoreComponentsTranslations.en,
        ...ChromeOSTranslations.en,
      },
    },
    'es-ES': {
      translation: {
        ...es_pages,
        ...es_components,
        ...ManagementTranslations.es,
        ...OperationalTranslations.es,
        ...CoreComponentsTranslations.es,
        ...ChromeOSTranslations.es,
      },
    },
    es: {
      translation: {
        ...es_pages,
        ...es_components,
        ...ManagementTranslations.es,
        ...OperationalTranslations.es,
        ...CoreComponentsTranslations.es,
        ...ChromeOSTranslations.es,
      },
    },
  },

  fallbackLng: 'pt-BR',
});
