import TranslationsChromeOS from './TranslationsChromeOS';

export const ChromeOSTranslations = {
  pt: {
    ...TranslationsChromeOS.pt,
  },
  en: {
    ...TranslationsChromeOS.en,
  },
  es: {
    ...TranslationsChromeOS.es,
  },
};
