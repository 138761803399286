import styled from 'styled-components';

export const Container = styled.div``;

export const TabColumns = styled.div`
  .euiTab {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 12px 53px !important;
  }
`;

export const Body = styled.div`
  min-height: 50vh;
  padding: 36px 0;
`;
