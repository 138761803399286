import styled, { css } from 'styled-components';

export const ModalOTAFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  & > span {
    font-size: 12px;
    color: #d32121;
  }
`;

export const ModalsFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 20px;

    .euiButton {
      background-color: ${theme.main} !important;
      border: none;

      &:hover {
        filter: brightness(0.9);
        background-color: ${theme.main};
        border: none;
      }

      &:disabled {
        background-color: #afafaf !important;
      }
    }

    .euiButton__text {
      color: #fff;
    }
  `}
`;

export const OTAContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 12px;
  gap: 24px;
  width: 100%;

  .euiFormRow {
    width: 100%;
  }

  .euiButton {
    &:disabled {
      background: grey;
    }
  }

  .euiFormRow + .euiFormRow,
  .euiFormRow + .euiButton {
    margin-top: 0px !important;
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  width: 100%;
`;
