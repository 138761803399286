import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom';
import '@elastic/eui/dist/eui_theme_light.css';
import 'assets/stylesheets/stylesheet-elastic-ui.scss';
import { Application } from 'modules/core/components';
import store from 'stores';
import { Provider } from 'react-redux';

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Application />
      </Provider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
