import { htmlIdGenerator } from '@elastic/eui';

export class ChartLine {
  constructor(id, data, color, device_id = null, period_available = null, chart_days, type) {
    this.label = this.handleFormatLabel(device_id, id, period_available, chart_days, type);
    this.data = this.normalize(data);
    this.name = device_id ? id : null;
    this.borderColor = color;
    this.backgroundColor = color;
    this.borderWidth = 2;
    this.tension = 0.1;
    this.id = htmlIdGenerator()();
  }

  handleFormatLabel(device_id, id, period_available, chart_days) {
    const availableChartDays = {
      seven_days: true,
      fifteen_days: true,
      thirty_days: true,
    };

    let status = device_id?.toString() || id;
    if (period_available && !period_available[chart_days] && availableChartDays[chart_days]) {
      status = device_id ? `${device_id?.toString()} *` : id;
      return status;
    }

    return device_id?.toString() || id;
  }

  normalize(data) {
    if (data && data.length) {
      return data.map((item) => {
        return {
          x: Math.round((item.x + Number.EPSILON) * 100) / 100,
          y: Math.round((item.y + Number.EPSILON) * 100) / 100,
        };
      });
    }

    return [];
  }
}
