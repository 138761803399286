import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { LinkWrapperProps } from '../page-home.contracts';

export const Wrapper = styled.main`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;

  margin-top: 40px;

  button {
    border: 0.1px solid ${ConstantColors.CUBA};
  }

  a {
    color: unset;
    font-weight: unset;
    &:hover {
      text-decoration: unset;
    }
  }

  .theme-color {
    color: ${({ theme }) => theme.main};
  }
`;

export const Card = styled.div<Pick<LinkWrapperProps, 'has_link'>>`
  width: 240px;
  padding: 15px 20px;
  border-radius: 4.5px;

  background-color: ${ConstantColors.AUSTRIA};
  transition: 0.3s;

  div {
    display: flex;
    margin-bottom: 8px;
  }

  h4 {
    margin: 2px 0 0 10px;
  }

  p {
    a {
      color: ${ConstantColors.CHILE};
      font-weight: 700;
      &:hover {
        text-decoration: underline;
      }
    }

    font-size: 12px;
  }

  ${(props) =>
    // eslint-disable-next-line implicit-arrow-linebreak
    props.has_link &&
    css`
      &:hover {
        transition: 0.3s;
        transform: translateY(-2px);
        p {
          text-decoration: underline;
        }
      }
    `}
`;
