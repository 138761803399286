import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as style from './page-security-settings.style';
import { SecurityCardsGroup } from './security-cards-group';
import { WifiSettings } from './wifi-toggle-policy';

export const PageSecuritySettings: React.FC = () => {
  const [wifiPolicyValue, setWifiPolicyValue] = useState<boolean>(false);
  const { t } = useTranslation();

  function toggleWifiPolicy() {
    setWifiPolicyValue(!wifiPolicyValue);
  }

  return (
    <>
      <style.Container>
        <style.Header>
          <style.Title>{t('pages.pulsus_android_settings.text.security_page_title')}</style.Title>
        </style.Header>
        <style.AlertGroup>
          <style.Item>
            <WifiSettings active={wifiPolicyValue} onClick={() => toggleWifiPolicy()} />
          </style.Item>
        </style.AlertGroup>
      </style.Container>
      <style.Container>
        <SecurityCardsGroup />
      </style.Container>
    </>
  );
};
