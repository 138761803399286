import type { EnvironmentVerificationComponentProps } from './EnvironmentVerificationComponent.contracts';
import { type ReactElement } from 'react';

export const EnvironmentVerificationComponent = ({ children }: EnvironmentVerificationComponentProps): ReactElement => {
  const verify = () => {
    if (['staging', 'production'].includes(process.env.REACT_APP_ENVIRONMENT!)) {
      window.location.href = process.env.REACT_APP_PULSUS_WEB_HOST!;
    }

    return children;
  };

  return <>{verify()}</>;
};
