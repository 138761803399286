import { EuiTabs } from '@elastic/eui';
import styled from 'styled-components';

export const TabsConrainer = styled(EuiTabs)`
  .euiTab-isSelected .euiTab__content {
    color: ${({ theme }) => theme.main} !important;
  }

  .euiTab-isSelected {
    box-shadow: unset;
    border-bottom: ${({ theme }) => `3px solid ${theme.main}`};
  }
`;
