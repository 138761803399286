import * as style from './component-flags.style';
import type { FlagsContainerProps } from './component-flags.contracts';

export const Flags = (props: FlagsContainerProps) => {
  const { flags } = props;

  return (
    <>
      {flags && flags.length > 0 && (
        <style.CardFlags>
          {flags.map((item) => {
            return <style.Flag key={`${item.id}_${item.label}`}>{item.label}</style.Flag>;
          })}
        </style.CardFlags>
      )}
    </>
  );
};
