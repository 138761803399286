import { useTranslation } from 'react-i18next';
import * as style from './page-pulsus-insights-batteries.style';
import type { ChartFilterTypeProps } from './pulsus-insights-batteries.contracts';

export function ChartFilterType({ chartType, handleChartTypeChange, disabled }: ChartFilterTypeProps) {
  const { t } = useTranslation();

  return (
    <>
      <style.ChartLabel>{t('pages.pulsus_insights_batteries.chart_filter_type.label')}</style.ChartLabel>
      <style.ChartTypeSelector
        data-testid="chart_type_selector"
        legend=""
        isDisabled={disabled}
        options={[
          {
            id: 'groups',
            label: t('pages.pulsus_insights_batteries.chart_filter_type.options.groups'),
          },
          {
            id: 'models',
            label: t('pages.pulsus_insights_batteries.chart_filter_type.options.models'),
          },
          {
            id: 'users',
            label: t('pages.pulsus_insights_batteries.chart_filter_type.options.users'),
          },
        ]}
        idSelected={chartType}
        onChange={handleChartTypeChange}
      />
    </>
  );
}
