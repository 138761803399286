import { EuiCard } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

const Card = styled(EuiCard)`
  display: flex;
  border-radius: 9px;
  padding: 25px !important;
  border: none !important;

  .euiTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
`;

const SwitchContainer = styled.div`
  margin-top: 15px;
  font-size: 14px;

  .euiSwitch__body {
    background: ${({ theme }) => theme.main};
  }

  .euiSwitch__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const CardDescription = styled.p`
  margin-top: 15px;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;

  small {
    padding-top: 13px;
    display: flex;
    flex-direction: column;
    p,
    span {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
    }

    span {
      font-weight: 700;
      font-size: 10px;
      line-height: 12px;
      color: ${({ theme }) => theme.main};
    }
  }
`;

const CardFlags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
`;

const Flag = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 24px;
  padding: 0 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: 24px;
  color: #343741;
  background: #e0e5ee;
`;

const PermissionOnSection = styled.section`
  margin-top: 15px;

  h6 {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    text-align: start;
    color: #1a1c21;
    padding: 3px 0;
  }

  input {
    padding-left: 0;
  }

  .euiFormControlLayout {
    max-width: 260px;
  }

  .euiFieldText {
    box-shadow: none;
  }

  .lmMEBx .euiFieldText {
    max-width: 151px;
  }

  .euiFormControlLayout--group .euiFormLabel,
  .euiFormControlLayout--group .euiText {
    background-color: transparent;
    padding-left: 10px;
    padding-right: 0;
    line-height: 16px !important;
    cursor: default !important;
    min-width: 90px;
  }

  .input_container {
    display: flex;
    flex-wrap: wrap;
    gap: 23px;
    align-items: center;
  }

  .euiButtonEmpty__text {
    display: flex;
    gap: 9px;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .eui_radio_group {
    display: flex;
    align-items: center;
    text-align: center;
    gap: 32px;
    padding: 10px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #1a1c21;
  }
`;

export const ErrorMessage = styled.p`
  color: ${ConstantColors.ANDORRA};
`;

export { Card, SwitchContainer, CardDescription, CardFlags, Flag, PermissionOnSection };
