import TranslationsSimManagement from './TranslationSimManagement';
import TranslationsLocations from './TranslationLocations';
import TranslationsContacts from './TranslationContacts';

export const ManagementTranslations = {
  pt: {
    ...TranslationsSimManagement.pt,
    ...TranslationsLocations.pt,
    ...TranslationsContacts.pt,
  },
  en: {
    ...TranslationsSimManagement.en,
    ...TranslationsLocations.en,
    ...TranslationsContacts.en,
  },
  es: {
    ...TranslationsSimManagement.es,
    ...TranslationsLocations.es,
    ...TranslationsContacts.es,
  },
};
