export function IconGroup2({ width = 15, height = 15, fill1 = '#C8C8C8', fill2 = '#595959' }) {
  return (
    <svg className="IconGroup2" width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="14.825" height="14.531" rx="1.744" fill={fill1} />
      <path
        d="M6.875 11.021c0 .688-.563 1.25-1.25 1.25-.688 0-1.25-.563-1.25-1.25 0-.688.563-1.25 1.25-1.25.688 0 1.25.563 1.25 1.25Zm-1.25-5c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25Zm0-3.75c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25Zm3.75 2.5c.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25Zm0 1.25c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25Zm0 3.75c-.688 0-1.25.563-1.25 1.25 0 .688.563 1.25 1.25 1.25.688 0 1.25-.563 1.25-1.25 0-.688-.563-1.25-1.25-1.25Z"
        fill={fill2}
      />
    </svg>
  );
}
