import styled from 'styled-components';
import { ConstantColors } from 'utilities';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.08px;
  padding-left: 12px !important;
  color: ${ConstantColors.SPAIN};
`;
