import { EuiButton, EuiFieldSearch } from '@elastic/eui';
import styled from 'styled-components';

export const Container = styled.div`
  overflow: none;
`;

export const GroupListSection = styled.section`
  height: 330px;
  overflow: auto;
  margin-bottom: 20px;
  margin-top: 20px;

  .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
    border-color: ${({ theme }) => theme.main} !important;
    background-color: ${({ theme }) => theme.main} !important;
  }

  th,
  td {
    border: none;
  }
`;

export const SearchInput = styled(EuiFieldSearch)`
  height: 37px;
  width: 100%;
`;

export const Row = styled.div`
  font-size: 12px;
  font-weight: 500;
  color: #67758d;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;

export const RowValue = styled.span`
  color: #343741;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
`;

export const LocationButton = styled(EuiButton)`
  background: ${({ theme }) => theme.main} !important;
  border-color: ${({ theme }) => theme.main} !important;
  color: #fff;
  margin-top: 25px;
  &:disabled {
    color: #fff !important;
  }

  &:disabled {
    filter: opacity(50%);
  }
`;
