import { useTypedTranslation } from 'modules/core/hooks';
import * as S from './ContactsPage.styles';
import { WrapperComponent } from 'modules/core/components/Wrapper/WrapperComponent';
import { HeaderPageComponent } from 'modules/core/components/HeaderPage/HeaderPageComponent';
import { ContactsTableComponent } from 'modules/management/components/contacts/ContactsTable/ContactsTableComponent';
import { useState } from 'react';

export const ContactsPage = () => {
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);

  const { t } = useTypedTranslation<'contacts'>('contacts');

  return (
    <WrapperComponent hasFooter>
      <S.Content>
        <HeaderPageComponent title={t('title')} description={t('subtitle')}>
          <S.UploadButton onClick={() => setIsUploadModalOpen(true)} iconType={'plusInCircle'} fill>
            {t('upload')}
          </S.UploadButton>
        </HeaderPageComponent>
        <ContactsTableComponent closeUploadModal={() => setIsUploadModalOpen(false)} isUploadModalOpen={isUploadModalOpen} />
      </S.Content>
    </WrapperComponent>
  );
};

export default ContactsPage;
