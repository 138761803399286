import React from 'react';
import PropTypes from 'prop-types';

export function IconWindows({ width, height, fill }) {
  return (
    <svg width={width} height={height} viewBox="0 0 19 19" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1 18H9.05887V9.94462H1V18ZM9.94113 18H18V9.94462H9.94113V18ZM1 9.05887H9.05887V1H1V9.05887ZM9.94113 9.05887H18V1H9.94113"
        fill="#67758D"
      />
    </svg>
  );
}

IconWindows.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

IconWindows.defaultProps = {
  width: '19px',
  height: '19px',
  fill: 'none',
};
