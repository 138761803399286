import type { InitialState, ReducerActions } from './contracts';
import { GeofenceActionType } from './contracts';

export const initialState: InitialState = {
  fields: {
    registerEvent: false,
    blockDevice: true,
    fenceIn: false,
    fenceOut: true,
    both: false,
    emailAlert: false,
    emailAlertLanguage: 'pt-BR',
    adminEmails: [],
    soundAlert: false,
    soundAlertAction: 'ONE',
  },
  changedFences: {},
  geofences: [],
};

export const geofenceReducer = (state = initialState, action: ReducerActions): InitialState => {
  switch (action.type) {
    case GeofenceActionType.UPDATE_FIELDS:
      return {
        ...state,
        fields: action.payload,
      };

    case GeofenceActionType.UPDATE_GEOFENCE_AREAS:
      return {
        ...state,
        geofences: action.payload,
      };

    case GeofenceActionType.UPDATE_CHANGED_FENCES:
      return {
        ...state,
        changedFences: action.payload,
      };

    default:
      return initialState;
  }
};
