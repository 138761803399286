export type PageDevicesProps = {
  embedded?: boolean;
};

export type StyleProps = {
  isDisabled?: boolean;
  color?: string;
  isEmbedded?: boolean;
  isAllDevicesSelected?: boolean;
};

export type OperationalSystem = 'ANDROID' | 'IOS' | 'CHROMEOS' | 'WINDOWS';

export type Management = 'FM' | 'FM_WP' | 'WP' | 'DA' | 'SU' | 'MSU' | 'BA' | 'NU' | 'WS' | 'ChromeOS' | 'ChromeFlex' | 'Windows' | 'UN';

export type DashBoardFilter = Management | OperationalSystem | '';

export type BulkActions =
  | 'disable_quarantine'
  | 'quarantine_mode'
  | 'get_phone_number'
  | 'sync'
  | 'send_message'
  | 'renewal'
  | 'update_group'
  | 'find_phone'
  | 'maintenance_mode'
  | 'lock_device'
  | 'remove'
  | 'OTAUpdate'
  | 'shutdown'
  | 'backup_contacts'
  | '';

export type SelectAllBulkActionsQuery = 'sync' | 'send_message' | 'renewal';

export type SelectAllBulkActionsBody = {
  filter: {
    odata_filters: string;
    custom_field_id?: number;
    filter_by?: string;
    search_str?: string;
  };
  content?: {
    message: string;
  };
};

export type DevicesCountPerManagement = {
  FM: number;
  FM_WP: number;
  WP: number;
  DA: number;
  SU: number;
  BA: number;
  NU: number;
  MSU: number;
  WS: number;
};

export type ManagementAttributes = {
  color: string;
  text: string;
  filter: Record<string, unknown>;
  initials: string;
};

export enum StatusCode {
  created = 0,
  active = 1,
  blocked = 2,
  removed = 3,
  wiped = 4,
  unenrolled = 5,
  lost = 6,
  tempBlock = 7,
  safetynetBlocked = 8,
  safetynetWiped = 9,
}

export type DevicesCountPerStatus = {
  created: number;
  active: number;
  blocked: number;
  removed: number;
  wiped: number;
  unenrolled: number;
  lost: number;
  tempBlock: number;
  safetynetBlocked: number;
  safetynetWiped: number;
};

export type StatusAttributes = {
  color: string;
  text: string;
};

export type DevicesStatus =
  | 'created'
  | 'active'
  | 'blocked'
  | 'removed'
  | 'wiped'
  | 'unenrolled'
  | 'lost'
  | 'temp block'
  | 'safetynet block'
  | 'safetynet wipe';

export type FeatureFlagsProps =
  | {
      remote_control: boolean;
      remote_view: boolean;
      battery_temp: boolean;
      bulk_device_actions: boolean;
      quarantine_mode: boolean;
      custom_fields: boolean;
      PEM1453_select_all_devices: boolean;
      CHROMEOS201_chromeos_feature: boolean;
      EVOLU162_phone_number_capture: boolean;
      PEM2810_ota_update: boolean;
      PEM5655_backup_contacts: boolean;
    }
  | undefined;
