import { EuiCallOut, EuiFieldText, EuiModal, EuiStepsHorizontal } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const ModalContainer = styled(EuiModal)`
  width: 1019px !important;
  max-width: 1019px !important;
  border-radius: 16px;
  margin-bottom: 30vh;

  .euiStepsHorizontal {
    background: white;
  }

  .euiModal__closeIcon {
    display: none;
  }

  text-align: center;
`;

export const Title = styled.h2`
  color: ${ConstantColors.COMOROS};
  margin: 55px 0;
  text-align: center;
`;

export const StyledStepperHorizontal = styled(EuiStepsHorizontal)`
  margin-bottom: 36px;

  .euiStepHorizontal {
    cursor: inherit;
  }

  .euiStepNumber {
    background: #263238;
    font-size: 16px;
    font-weight: 600;
    color: #ffffff;
  }

  .euiStepHorizontal__title {
    font-weight: 700;
    color: #1a1c21;
    text-decoration: none !important;
  }

  .euiStepHorizontal-isDisabled .euiStepHorizontal__title {
    color: #bababa;
  }

  .euiStepNumber--disabled {
    background: #abb4c41a;
    color: #a2abba;
  }
`;

export const Body = styled.div`
  padding: 0 48px 38px 48px;

  .euiFormHelpText {
    text-align: end;
  }
`;

export const StyledFieldText = styled(EuiFieldText)`
  width: 100%;
  input {
    width: 100%;
  }
`;

export const Buttons = styled.div`
  margin-top: 16px;
`;

export const Error = styled(EuiCallOut)`
  width: 190px !important;
  position: absolute;
  left: 50px;
  top: 350px;

  .euiCallOutHeader__title {
    font-size: 13px !important;
    line-height: 1rem !important;
  }

  .euiTextColor.euiTextColor--default {
    p {
      font-size: 11px !important;
    }
  }

  .euiCallOut {
    padding: 2px 10px;
  }
`;
