import { EuiButton, EuiPagination } from '@elastic/eui';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';

type ContainerProps = {
  groupsLocale: string;
};

export const Container = styled.div<ContainerProps>`
  ${({ theme, groupsLocale }) => css`
    padding: 30px 50px 30px 50px;

    display: flex;
    flex-direction: column;
    gap: 45px;

    .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
      border-color: ${theme.main} !important;
      background-color: ${theme.main} !important;

      @keyframes focusRingAnimate {
        0% {
          box-shadow: 0 0 0 6px ${theme.main}00;
        }
        100% {
          box-shadow: 0 0 0 3px ${theme.main}60;
        }
      }
    }

    .link {
      color: ${ConstantColors.CHILE};
      font-weight: 700;
      cursor: pointer;
      transition: 0.3s;

      &:hover {
        transition: 0.3s;
        transform: translateY(-2px);

        p:first-child {
          text-decoration: underline;
        }
      }
    }

    span[title=${groupsLocale}] {
      padding-left: 33px;
    }
  `}
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderActions = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const OpenModalButton = styled(EuiButton)`
  color: white !important;
  background: #30a8ff !important;
  border: none;

  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.08px;

  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15);
  border-radius: 4px;
`;

export const FiltersBox = styled.div`
  display: flex;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
  width: 40%;
  max-width: 467px;
`;

export const Right = styled.div`
  display: flex;
  justify-content: end;
  width: 60%;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #343434;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #67758d;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;

  .euiButton--primary.euiButton--fill,
  .euiButton--primary.euiButton--fill:focus,
  .euiButton--primary.euiButton--fill:hover {
    background: ${({ theme }) => theme.main};
    border: ${({ theme }) => theme.main};
    text-decoration: none;
  }

  .euiButton__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .euiButtonEmpty__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.08px;
    color: #30a8ff;
  }
`;

export const TableBox = styled.div`
  position: relative;
  padding-bottom: 50px;
  padding-top: 30px;

  .euiTableCellContent {
    padding: 4px 8px;

    &__text {
      width: 100%;
      color: #67758d;
    }
  }

  .euiLoadingContent__singleLine {
    height: 50px;

    &:last-child {
      width: 100%;
    }
  }
`;

export const Selected = styled.div`
  position: absolute;
  top: -10px;
  left: 9px;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  color: #67758d;
`;

export const ColumnApplication = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
`;

export const AppName = styled.p`
  font-size: 13px;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AppIdentifier = styled.p`
  font-size: 11px;
  color: #afafaf;
  font-weight: 400;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ColumnGroup = styled.div`
  border: 0.77686px solid #d3dae6;
  border-radius: 9.32231px;
  padding: 2px 18px;
  min-width: 110px;

  p {
    color: #343741;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
  }
`;

export const ColumnEvolution = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
  height: 45px;

  progress {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }
`;

export const Count = styled.span<{ countApps: number }>`
  font-size: 10px;
  font-weight: 700;
  color: #7d7d7d;
  text-align: right;
  width: fit-content;
  align-self: flex-end;
  margin-bottom: -3px;
  margin-right: ${({ countApps }) => `calc((${countApps} * -2.5px))`};
`;

export const Bars = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  width: 100%;
`;

export const Informations = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const Information = styled.p<{ color: string }>`
  font-size: 10px;
  font-weight: 700;
  white-space: nowrap;
  color: ${({ color }) => color};
  font-weight: 400;
`;

export const WrapperBars = styled.div`
  display: flex;
  align-items: flex-end;
  align-items: center;
`;

export const IndicatorBar = styled.div`
  width: 1px;
  height: 13px;
  background: #d3dae6;
`;

export const Unavailable = styled.p`
  font-weight: 700;
  font-size: 10px;
  color: #909090;
  white-space: nowrap;
`;

export const WrapperTooltip = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const ColumnActions = styled.div`
  display: flex;
  gap: 7px;
`;

export const IconsBg = styled.div`
  width: 29px;
  height: 29px;
  background-color: #f9f9f9;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 14px;
  cursor: pointer;
`;

export const Pagination = styled(EuiPagination)`
  background-color: ${ConstantColors.AUSTRIA};
  position: absolute;
  bottom: 0px;
  right: 0px;
`;
