import React, { useEffect, useState } from 'react';
import { EuiRadioGroup, EuiDatePicker } from '@elastic/eui';
import type { Moment } from 'moment';
import moment from 'moment';
import * as style from './operational-system-cards-group.style';
import { GetCardFlagsAndroid } from '../flags-label-android';
import { QuickSettingsCard } from '../quick-settings-card';
import type { OperationalSystemCardsGroupProps } from './page-operational-system.contracts';

export function OperationalSystemCardsGroup(props: OperationalSystemCardsGroupProps) {
  const { systemUpdate, autoDateAndTimeZone, allowFirmwareOtaUpgrade, onChange } = props;
  const [isFireWallOn, setIsFireWallOn] = useState(false);
  const [manageUpdateIsOn, setManageUpdateIsOn] = useState(false);

  const [minTime, setMinTime] = useState(moment('00:00', 'HH:mm'));
  const [maxTime, setMaxTime] = useState(moment('23:59', 'HH:mm'));

  function handleChange(value: unknown, key: string) {
    onChange(value, key);
  }

  function customDateToMoment(minutes: number) {
    const first = Math.floor(minutes / 60) < 10 ? `0${Math.floor(minutes / 60)}` : Math.floor(minutes / 60);
    const second = minutes % 60 < 10 ? `${minutes % 60}` : minutes % 60;
    const auxTime = `${first}:${second}`;
    return moment(auxTime, 'HH:mm');
  }

  useEffect(() => {
    if (systemUpdate) {
      const startAuxTime = moment(minTime).format('HH:mm');
      const endAuxTime = moment(maxTime).format('HH:mm');
      const startTimeInMinute = Number(startAuxTime.split(':')[0]) * 60 + Number(startAuxTime.split(':')[1]);
      const endTimeInMinute = Number(endAuxTime.split(':')[0]) * 60 + Number(endAuxTime.split(':')[1]);

      if (systemUpdate.type && !manageUpdateIsOn) {
        setManageUpdateIsOn(true);
      } else if (!systemUpdate?.type) {
        setManageUpdateIsOn(false);
      }
      if (systemUpdate.startMinutes && systemUpdate.startMinutes !== startTimeInMinute) {
        const time = customDateToMoment(systemUpdate.startMinutes);
        setMinTime(time);
      }
      if (systemUpdate.endMinutes && systemUpdate.endMinutes !== endTimeInMinute) {
        const time = customDateToMoment(systemUpdate.endMinutes);
        setMaxTime(time);
      }
    }
  }, [systemUpdate]);

  const options = GetCardFlagsAndroid();
  return (
    <style.Card title="">
      <QuickSettingsCard
        title="Firewall"
        label={isFireWallOn ? 'Desabilitar firewall' : 'Habilitar firewall'}
        flags={options.fireWallFlags}
        active={isFireWallOn}
        onChange={() => setIsFireWallOn(!isFireWallOn)}
        isContentOnly
      >
        <style.CardDescription>
          Modo depuração é usado por desenvolvedores para analisis especificas, por segurança é recomendado manter esta funcionalidade desabilitada.
        </style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title="Política de atualização do sistema"
        label="Gerenciar a atualização do Android"
        active={manageUpdateIsOn}
        onChange={() => {
          setManageUpdateIsOn(!manageUpdateIsOn);
          if (!manageUpdateIsOn === false) {
            handleChange('', 'systemUpdateType');
          }
        }}
        isContentOnly
      >
        <style.CardDescription>Esta funcionalidade permite que seja definida como ocorrerá a atualização do Android.</style.CardDescription>
        <EuiRadioGroup
          options={[
            {
              id: 'AUTOMATIC',
              label: 'Atualização Automática',
            },
            {
              id: 'POSTPONE',
              label: 'Adiar atualização por 30 dias',
            },
            {
              id: 'WINDOWED',
              label: 'Atualizar somente entre',
            },
          ]}
          disabled={!manageUpdateIsOn}
          idSelected={systemUpdate?.type || ''}
          onChange={(id) => handleChange(id, 'systemUpdateType')}
          name="radio group"
          className="radio-options"
        />
        {systemUpdate?.type === 'WINDOWED' && (
          <style.DateInterval>
            <EuiDatePicker
              className="timeInput"
              showTimeSelectOnly
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              selected={minTime}
              onFocus={(e) => e.target.blur()}
              onChange={(date: Moment) => {
                const startAuxTime = moment(date).format('HH:mm');
                const endAuxTime = moment(maxTime).format('HH:mm');
                const startTimeInMinute = Number(startAuxTime.split(':')[0]) * 60 + Number(startAuxTime.split(':')[1]);
                const endTimeInMinute = Number(endAuxTime.split(':')[0]) * 60 + Number(endAuxTime.split(':')[1]);
                if (startTimeInMinute < endTimeInMinute) {
                  setMinTime(date);
                  handleChange(startTimeInMinute, 'systemUpdateStart');
                }
              }}
            />
            e
            <EuiDatePicker
              className="timeInput"
              showTimeSelectOnly
              timeFormat="HH:mm"
              dateFormat="HH:mm"
              selected={maxTime}
              onFocus={(e) => e.target.blur()}
              onChange={(date: Moment) => {
                const startAuxTime = moment(minTime).format('HH:mm');
                const endAuxTime = moment(date).format('HH:mm');
                const startTimeInMinute = Number(startAuxTime.split(':')[0]) * 60 + Number(startAuxTime.split(':')[1]);
                const endTimeInMinute = Number(endAuxTime.split(':')[0]) * 60 + Number(endAuxTime.split(':')[1]);
                if (endTimeInMinute > startTimeInMinute) {
                  setMaxTime(date);
                  handleChange(endTimeInMinute, 'systemUpdateEnd');
                }
              }}
            />
          </style.DateInterval>
        )}
      </QuickSettingsCard>
      <QuickSettingsCard
        title="Atualização de Firmware - Over the Air"
        label={allowFirmwareOtaUpgrade ? 'Atualização de firmware (OTA) habilitada' : 'Atualização de firmware (OTA) desabilitada'}
        flags={options.firmwareUpdateFlags}
        active={allowFirmwareOtaUpgrade}
        onChange={() => handleChange(!allowFirmwareOtaUpgrade, 'allowFirmwareOtaUpgrade')}
        isContentOnly
      >
        <style.CardDescription>
          Lorem ipsum dolor sit hamet dolor sit hamet ipsum dolor sit hamet dolor sit hamet ipsum dolor sit hamet dolor sit hamet ipsum dolor sit
          hamet dolor sit.
        </style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title="Definir data e hora automáticamente"
        label={autoDateAndTimeZone === 'AUTO_DATE_AND_TIME_ZONE_ENFORCED' ? 'Hora automatica habilitada' : 'Hora automatica desabilitada'}
        flags={options.fireWallFlags}
        active={autoDateAndTimeZone === 'AUTO_DATE_AND_TIME_ZONE_ENFORCED'}
        onChange={() => {
          const aux =
            autoDateAndTimeZone === 'AUTO_DATE_AND_TIME_ZONE_ENFORCED' ? 'AUTO_DATE_AND_TIME_ZONE_USER_CHOICE' : 'AUTO_DATE_AND_TIME_ZONE_ENFORCED';
          handleChange(aux, 'autoDateAndTimeZone');
        }}
        isContentOnly
      >
        <style.CardDescription>
          Modo depuração é usado por desenvolvedores para analisis especificas, por segurança é recomendado manter esta funcionalidade desabilitada.
        </style.CardDescription>
      </QuickSettingsCard>
    </style.Card>
  );
}
