import { TileLayer, ScaleControl, ZoomControl } from 'react-leaflet';

import 'leaflet-control-geocoder/dist/Control.Geocoder.css';
import 'leaflet-control-geocoder/dist/Control.Geocoder.js';

export default function MapDeviceLocalization() {
  return (
    <>
      <TileLayer url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png" />
      <ScaleControl position="bottomleft" imperial={false} />
      <ZoomControl position="bottomleft" />
    </>
  );
}
