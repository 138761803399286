import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: 40%;
`;

export const Right = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 40px;
  gap: 40px;

  .euiFieldText:focus,
  .euiFieldPassword:focus,
  .euiSelect:focus {
    background-color: white;
    max-width: 100% !important;
    background-size: 100% 100% !important;
    box-shadow:
      0 1px 1px -1px rgba(152, 162, 179, 0.2),
      0 4px 4px -2px rgba(152, 162, 179, 0.2),
      inset 0 0 0 1px rgba(15, 39, 118, 0.1);

    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%);`}
    `}
  }
`;

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  color: ${({ theme }) => theme.main};
`;

export const Description = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #474747;
`;

export const Actions = styled.div`
  display: flex;
  position: absolute;
  bottom: 40px;
  right: 40px;
  gap: 20px;
`;

export const CancelButton = styled(EuiButtonEmpty)`
  color: #30a8ff;
  font-size: 14px;
  font-weight: 700;
  line-height: 35px;
`;

export const ConfirmButton = styled(EuiButton)`
  &.euiButton,
  &:hover,
  &:focus {
    border: none;
    width: 202px;
    border-radius: 5px;
    text-decoration: none !important;
    background: ${({ theme }) => theme.main} !important;
  }
`;
