/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from 'react';
import { IconWorkProfile } from 'icons';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useQuery } from 'hooks/use-query';
import { ConstantLanguages } from 'utilities';
import type { PasswordRequirementProps } from './embedded-security-settings.contracts';
import { defaultValues, Events, Origins } from './embedded-security-settings.contracts';
import * as style from './embedded-security-settings.style';
import { DeviceSecurityTab } from './embedded-security-settings-tab';
import { Tabs } from './embedded-security-settings-tab.contracts';

export const DeviceSecurity = () => {
  const [selectedTab, setSelectedTab] = useState(Tabs.DEVICE);
  const [devicePasswordPolicy, setDevicePasswordPolicy] = useState<PasswordRequirementProps>(defaultValues);
  const [workProfilePasswordPolicy, setWorkProfilePasswordPolicy] = useState<PasswordRequirementProps>(defaultValues);
  const [devicePasswordPolicyInitialValue, setDevicePasswordPolicyInitialValue] = useState<PasswordRequirementProps>(defaultValues);
  const [workProfilePasswordPolicyInitialValue, setWorkProfilePasswordPolicyInitialValue] = useState<PasswordRequirementProps>(defaultValues);

  const { t } = useTranslation();
  const query = useQuery();

  const handleSelectedTabChange = (value: Tabs) => {
    setSelectedTab(value);
  };

  const handleDevicePasswordPolicyChange = (value: PasswordRequirementProps) => {
    setDevicePasswordPolicy(value);
  };

  const handleWorkProfilePasswordPolicyChange = (value: PasswordRequirementProps) => {
    setWorkProfilePasswordPolicy(value);
  };

  const isTabSelected = (value: Tabs): boolean => {
    return selectedTab === value;
  };

  const sendPostMessage = (payload = {}) => {
    window.parent.postMessage(JSON.stringify(payload), process.env.REACT_APP_RUBY_LINK!);
  };
  window.addEventListener('message', ({ data }) => {
    if (data && typeof data === 'string') {
      const payload = JSON.parse(data);
      if (payload && payload.from === Origins.RAILS && payload.tab === 'password_policy') {
        setDevicePasswordPolicyInitialValue(payload.device);
        setWorkProfilePasswordPolicyInitialValue(payload.workProfile);
      }
    }
  });
  useEffect(() => {
    i18next.changeLanguage(query.get('language') || ConstantLanguages.PT_BR);

    sendPostMessage({
      from: Origins.NJORD,
      tab: 'password_policy',
      event: Events.ON_READY,
    });

    return () => window.removeEventListener('message', (event) => event);
  }, []);

  useEffect(() => {
    sendPostMessage({
      from: Origins.NJORD,
      tab: 'password_policy',
      event: Events.ON_CHANGE,
      device: devicePasswordPolicy,
      workProfile: workProfilePasswordPolicy,
    });
  }, [devicePasswordPolicy, workProfilePasswordPolicy]);

  return (
    <>
      <style.GlobalStyle />
      <style.Container>
        <style.SecurityCard className="component" title="" textAlign="left">
          <style.CardItem style={{ paddingBottom: 0 }}>
            <style.SecurityTitle data-testid="embedded-security-title">
              {t('pages.pulsus_android_settings.text.password_card_title')}
            </style.SecurityTitle>
            <div style={{ display: 'flex' }}>
              <style.Tabs data-testid="embedded-security-tabs">
                <style.Tab
                  data-testid="embedded-security-tab-01"
                  isSelected={isTabSelected(Tabs.DEVICE)}
                  onClick={() => handleSelectedTabChange(Tabs.DEVICE)}
                >
                  {t('pages.pulsus_android_settings.text.password_device_title')}
                </style.Tab>
                <style.Tab
                  data-testid="embedded-security-tab-02"
                  className="workProfile"
                  isSelected={isTabSelected(Tabs.WORK_PROFILE)}
                  onClick={() => handleSelectedTabChange(Tabs.WORK_PROFILE)}
                >
                  <style.TabContent data-testid="embedded-security-work-icon">
                    <IconWorkProfile width={24} height={24} fill="#1483ff" />
                    {t('pages.pulsus_android_settings.text.password_profile_title')}
                  </style.TabContent>
                </style.Tab>
              </style.Tabs>
            </div>
          </style.CardItem>
          <DeviceSecurityTab
            visible={isTabSelected(Tabs.DEVICE)}
            tab={Tabs.DEVICE}
            initialValues={devicePasswordPolicyInitialValue}
            onChange={handleDevicePasswordPolicyChange}
          />
          <DeviceSecurityTab
            visible={isTabSelected(Tabs.WORK_PROFILE)}
            tab={Tabs.WORK_PROFILE}
            initialValues={workProfilePasswordPolicyInitialValue}
            onChange={handleWorkProfilePasswordPolicyChange}
          />
        </style.SecurityCard>
      </style.Container>
    </>
  );
};
