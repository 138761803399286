import { htmlIdGenerator } from '@elastic/eui';
import * as S from './ComponentRubyRadioButtons.style';
import type { ComponentRubyRadioButtonsProps, RadioSelectedTypes } from './ComponentRubyRadioButtons.contracts';

export function ComponentRubyRadioButtons({
  options,
  selected,
  onClick,
  allowEmpty = false,
  row = false,
  disabled = false,
}: ComponentRubyRadioButtonsProps) {
  const validateSelection = (id: RadioSelectedTypes) => {
    if (allowEmpty) {
      onClick(selected === id ? undefined! : id);
    }

    onClick(id);
  };

  return (
    <S.Container row={row}>
      {options.map((item) => (
        <S.RadioButton key={htmlIdGenerator()()} id={item.id.toString()}>
          <input
            type="radio"
            checked={selected === item.id}
            id={item.id.toString()}
            onClick={() => validateSelection(item.id)}
            onChange={() => null}
            disabled={disabled}
          />
          <p>{item.text}</p>
        </S.RadioButton>
      ))}
    </S.Container>
  );
}
