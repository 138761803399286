import { useQuery } from 'hooks/use-query';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { ResourceContextProvider } from 'modules/core/contexts';
import { SafetyNet } from '../safety-net/safety-net';
import { Support } from '../support/support';
import * as style from './general-settings-management.style';

export function SettingsManagement({ iframe }: { iframe?: boolean }) {
  const query = useQuery();
  const language = query.get('language');
  const [isEditable, setIsEditable] = useState(false);
  const [saveConfig, setSaveConfig] = useState(false);
  const { t } = useTranslation();

  const onClickSave = () => {
    setSaveConfig(true);
    setIsEditable(false);
  };

  const onClickEdit = () => {
    setSaveConfig(false);
    setIsEditable(true);
  };

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <div data-testid="android-management-container">
      <ResourceContextProvider>
        <style.SaveConfig data-testid="android-management-header">
          <h2>{t('pages.android_enterprise_settings.text.title')}</h2>
          {isEditable ? (
            <style.SaveButton data-testid="android-management-header-save" onClick={() => onClickSave()}>
              {t('pages.android_enterprise_settings.button.save')}
            </style.SaveButton>
          ) : (
            <style.SaveButton data-testid="android-management-header-edit" onClick={() => onClickEdit()} iconSide="right" iconType="pencil">
              {t('pages.android_enterprise_settings.button.edit')}
            </style.SaveButton>
          )}
        </style.SaveConfig>
        <style.ConfigContainer isEditable={isEditable}>
          <SafetyNet iframe={iframe} isEditable={isEditable} saveConfig={saveConfig} />
          <Support iframe={iframe} isEditable={isEditable} saveConfig={saveConfig} />
        </style.ConfigContainer>
      </ResourceContextProvider>
    </div>
  );
}
