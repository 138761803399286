import iosMenu from 'assets/images/onboarding-ios-menu.png';

import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useQuery } from 'hooks/use-query';
import { useEffect } from 'react';
import type { StepIosProps } from '../page-onboarding.contracts';
import * as style from '../page-onboarding.style';

export function StepIos({ setStep }: StepIosProps) {
  const { t } = useTranslation();
  const query = useQuery();

  useEffect(() => {
    const language = query.get('language');
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <style.StepContainer>
      <style.StepLeft />
      <style.StepRight>
        <style.StepTitle
          style={{
            marginTop: '30px',
            color: '#242424',
            width: '80%',
          }}
        >
          <h3
            style={{
              fontStyle: 'normal',
              fontWeight: 'bold',
              fontSize: '20px',
              color: '#242424',
              lineHeight: '30px',
            }}
          >
            {t('pages.onboarding.ios_phrase')}
          </h3>
          <span>{t('pages.onboarding.ios_phrase_center')}</span>
        </style.StepTitle>
        <div className="content" style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', alignItems: 'start' }}>
          <img className="ios-img" src={iosMenu} alt="grupo com uma bandeira ios" style={{ width: '170px' }} />
          <style.StepButtonEmpty size="m" onClick={() => setStep('enroll')}>
            {t('pages.onboarding.ios_phrase_last')}
          </style.StepButtonEmpty>
        </div>
      </style.StepRight>
    </style.StepContainer>
  );
}
