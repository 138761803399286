import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled from 'styled-components';

export const ContentGroup = styled.div`
  display: flex;
  margin-top: 40px;
  width: 100% !important;
  padding: 0 50px 0 50px;
  gap: 40px;

  .jEVQqx {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    gap: 15px;
    margin-bottom: 24px;
  }
`;

export const BreadcrumbBox = styled.div`
  ul {
    padding-bottom: 0;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding-top: 20px;
  width: 450px;
`;

export const LogoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: ${({ theme }) => theme.main};
`;

export const Description = styled.div`
  .euiTextColor--default {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #67758d;
  }
  .euiTextColor--secondary {
    font-size: 16px;
    line-height: 18px;
    font-weight: 700;
    color: #67758d;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const SyncBox = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

export const SyncButton = styled(EuiButton)`
  padding: 0;
  width: 180px;
  height: 35px;
  background: #30a8ff !important;
  border: none;
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15) !important;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.08px;
`;

export const UnsyncButton = styled(EuiButton)`
  padding: 0;
  width: 180px;
  height: 35px;
  background: #67758d !important;
  border: none;
  box-shadow: 0px 2px 2px rgba(152, 162, 179, 0.15) !important;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.08px;
`;

export const UnsyncGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const UnsyncGroupTitle = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: #67758d;
`;

export const UnsyncBox = styled.div`
  display: flex;
  flex-direction: column;
  background: #f5f7fa;
  min-width: 380px;
  padding: 25px;
  border-radius: 12px;
  gap: 20px;
`;

export const UnsyncHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const UnsyncBody = styled.div``;

export const UnsyncLabel = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 141.02%;
  color: #b1b2b4;
`;

export const UnsyncTitle = styled.div`
  font-weight: 700;
  font-size: 28px;
  line-height: 35px;
  color: #343434;
`;

export const AccountInormations = styled.div`
  .euiTextColor--default {
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #67758d;
  }
  .euiTextColor--secondary {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #b1b2b4;
  }
`;

export const AccountInformationsActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const LinkButton = styled(EuiButtonEmpty)`
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.08px;
  color: #30a8ff !important;
  :focus {
    background-color: transparent;
  }
`;

export const BannerBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const SubContent = styled.div`
  display: flex;
  width: 100% !important;
  gap: 50px;
`;

export const LeftSubContent = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`;

export const RightSubContent = styled.div`
  width: 700px;
  .euiStepNumber {
    background-color: ${({ theme }) => theme.main};
  }

  @keyframes fadein {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  #chromeOS-integration-sub-steps {
    /* animation-name: fadein;
    animation-duration: 1s;
    animation-timing-function: linear; */
  }
`;

export const SubTitle = styled.h2`
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  color: ${({ theme }) => theme.main};
`;

export const ColoredText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  .euiTextColor--default {
    color: #343741;
  }
  .euiTextColor--secondary {
    color: #30a8ff;
    cursor: pointer;
  }
  .euiTextColor--secondary:hover {
    text-decoration: underline;
  }
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const ModalTitle = styled.h1`
  width: 100%;
  color: ${({ theme }) => theme.main};
`;

export const LeftContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  .euiSwitch .euiSwitch__body {
    background-color: ${({ theme }) => theme.main};
  }
`;

export const Fields = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ButtonBox = styled.div`
  .euiButton--primary.euiButton--fill,
  .euiButton--primary.euiButton--fill:focus,
  .euiButton--primary.euiButton--fill:hover {
    background: ${({ theme }) => theme.main};
    border: ${({ theme }) => theme.main};
    text-decoration: none;
  }
  .euiButtonContent {
    width: 230px;
    height: 38px;
  }
  .euiButton__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
`;

export const RightContent = styled.div``;

export const UnlinkModalContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 60px;

  img {
    margin-bottom: 40px;
  }
`;

export const ModalRightSide = styled.div`
  width: 350px;
  margin-top: 50px;
`;

export const UnlinkModalText = styled.p`
  line-height: 18px;
  font-weight: 400;
  font-size: 16px;
  margin: 20px 0;
  color: #404040;
`;

export const UnlinkModalTitle = styled.h2`
  font-weight: 700;
  line-height: 35px;
  color: ${({ theme }) => theme.main};
  font-size: 28px;
  width: 300px;
`;

export const UnlinkButton = styled(EuiButton)`
  width: 230px;
  background-color: ${({ theme }) => theme.main} !important;
  border-color: ${({ theme }) => theme.main} !important;
`;

export const ModalWarningText = styled.div`
  .euiTextColor--default {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #67758d;
  }
  .euiTextColor--secondary {
    font-weight: 700;
    font-size: 16px;
    line-height: 18px;
    color: #67758d;
  }
`;

export const InformationModalConatent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
`;

export const InformationModalActions = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: end;
`;

export const ContinueButton = styled(EuiButton)`
  &.euiButton,
  &.euiButton:focus,
  &.euiButton:hover {
    background: ${({ theme }) => theme.main};
    border: ${({ theme }) => theme.main};
    text-decoration: none;
  }

  .euiButton__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }
`;

export const ErrorModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const ErrorModalTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.main};
`;

export const ErrorModalBody = styled.div`
  display: flex;
  align-items: center;
`;

export const ErrorModalDescription = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #474747;
  &.center {
    text-align: center;
  }
  .bold {
    font-weight: 700;
  }
`;

export const ErrorModalLeft = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ErrorModalRight = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 15px;

  .euiStepNumber {
    background-color: ${({ theme }) => theme.main};
  }

  .euiStep__content {
    padding-top: 0;
  }
`;

export const AddDeviceModalContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 60px;
`;
