export enum Events {
  ON_READY = 'ON_READY',
  ON_CHANGE = 'ON_CHANGE',
  ON_RESIZE = 'ON_RESIZE',
  ON_SET_FILTERS = 'ON_SET_FILTERS',
}

export enum Origins {
  NJORD = 'njord',
  RAILS = 'rails',
}
