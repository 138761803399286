/* eslint-disable react/jsx-one-expression-per-line */
import { IconButton } from '@mui/material';
import { AppStoreIcon, AndroidStoreIcon, AppleFilterIcon, AppleStoreIcon } from 'icons';
import AndroidIcon from '@mui/icons-material/Android';
import AppleIcon from '@mui/icons-material/Apple';
import { useTranslation } from 'react-i18next';
import * as style from './AppsDashboardFilter.style';
import type { AppsDashboardFilterProps } from './AppsDashboardFilter.contracts';

export function AppsDashboardFilter(props: AppsDashboardFilterProps) {
  const { filterSelected, onChangeFilter, appsPerDistribution } = props;

  const { t } = useTranslation();

  return (
    <style.Dashboard>
      <style.DashboardTitle>{t('apps.dashboard_filter.title')}</style.DashboardTitle>
      <style.Lines className="divider">
        <style.CategoryIcon isActive={filterSelected === 'android'}>
          <IconButton onClick={() => onChangeFilter('android')}>
            <AndroidIcon style={{ color: '#3DDB85' }} />
          </IconButton>
        </style.CategoryIcon>
        <style.ItemsGroup>
          <style.Item isActive={filterSelected === 'androidPlayStore'} onClick={() => onChangeFilter('androidPlayStore')}>
            <style.ItemIcon>
              <AppStoreIcon size={1.2} />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>Play Store</style.ItemTitle>
              <style.ItemDescription>
                {`${appsPerDistribution?.androidStore !== undefined ? appsPerDistribution?.androidStore : '-'} 
                ${t('apps.dashboard_filter.devices')}`}
              </style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
          <style.Item isActive={filterSelected === 'androidAPK'} onClick={() => onChangeFilter('androidAPK')}>
            <style.ItemIcon>
              <AndroidStoreIcon size={1.2} />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>APK</style.ItemTitle>
              <style.ItemDescription>
                {`${appsPerDistribution?.androidPackage !== undefined ? appsPerDistribution?.androidPackage : '-'}
                ${t('apps.dashboard_filter.apps')}`}
              </style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
        </style.ItemsGroup>
      </style.Lines>
      <style.Lines>
        <style.CategoryIcon isActive={filterSelected === 'ios'}>
          <IconButton onClick={() => onChangeFilter('ios')}>
            <AppleIcon />
          </IconButton>
        </style.CategoryIcon>
        <style.ItemsGroup>
          <style.Item isActive={filterSelected === 'iosAppleStore'} onClick={() => onChangeFilter('iosAppleStore')}>
            <style.ItemIcon>
              <AppleStoreIcon />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>Apple Store</style.ItemTitle>
              <style.ItemDescription>
                {`${appsPerDistribution?.iOSStore !== undefined ? appsPerDistribution?.iOSStore : '-'} 
                ${t('apps.dashboard_filter.devices')}`}
              </style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
          <style.Item isActive={filterSelected === 'iosAppleStorePackage'} onClick={() => onChangeFilter('iosAppleStorePackage')}>
            <style.ItemIcon>
              <AppleFilterIcon size={1.2} />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>iOS App Store Package</style.ItemTitle>
              <style.ItemDescription>
                {`${appsPerDistribution?.iOSPackage !== undefined ? appsPerDistribution?.iOSPackage : '-'} 
              ${t('apps.dashboard_filter.devices')}`}
              </style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
        </style.ItemsGroup>
      </style.Lines>
      {/* TODO: waiting Chrome decisions }
      {/* <style.Lines>
        <style.CategoryIcon isActive={filterSelected === 'chrome'}>
          <IconButton onClick={() => onChangeFilter('chrome')}>
            <ColoredChromeOSIcon />
          </IconButton>
        </style.CategoryIcon>
        <style.ItemsGroup>
          <style.Item isActive={filterSelected === 'chromeAppStore'} onClick={() => onChangeFilter('chromeAppStore')}>
            <style.ItemIcon>
              <AppStoreIcon size={1.2} />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>Play Store</style.ItemTitle>
              <style.ItemDescription>93 dispositivos</style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
          <style.Item isActive={filterSelected === 'chromeAPK'} onClick={() => onChangeFilter('chromeAPK')}>
            <style.ItemIcon>
              <AndroidStoreIcon size={1.2} />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>APK</style.ItemTitle>
              <style.ItemDescription>49 aplicativos</style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
          <style.Item isActive={filterSelected === 'chromeExtension'} onClick={() => onChangeFilter('chromeExtension')}>
            <style.ItemIcon>
              <ChromeExtensionIcon size={1.2} />
            </style.ItemIcon>
            <style.ItemInfo>
              <style.ItemTitle>Extension</style.ItemTitle>
              <style.ItemDescription>49 aplicativos</style.ItemDescription>
            </style.ItemInfo>
          </style.Item>
        </style.ItemsGroup>
      </style.Lines> */}
      {filterSelected && <style.InfoButton onClick={() => onChangeFilter('')}>Limpar filtros</style.InfoButton>}
    </style.Dashboard>
  );
}
