export class DashboardStatus {
  public activeDevices: number;
  public totalDevices: number;
  public created: number;
  public wiped: number;
  public groups: number;
  public totalApps: number;
  public active: number;
  public blocked: number;
  public unenrolled: number;
  public temp_block: number;

  constructor(data: DashboardStatus.Server) {
    this.activeDevices = data.devices.active_devices;
    this.totalDevices = data.devices.total_devices;
    this.created = data.devices.created;
    this.wiped = data.devices.wiped;
    this.temp_block = data.devices.temp_block;
    this.unenrolled = data.devices.unenrolled;
    this.groups = data.groups;
    this.totalApps = data.applications.total_apps;
    this.active = data.applications.active;
    this.blocked = data.applications.blocked;
  }
}

export namespace DashboardStatus {
  export type Server = {
    devices: {
      total_devices: number;
      active_devices: number;
      created: number;
      wiped: number;
      unenrolled: number;
      temp_block: number;
    };
    groups: number;
    applications: {
      total_apps: number;
      active: number;
      blocked: number;
    };
  };
}
