import styled from 'styled-components';

export const TablesHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const HeaderFilters = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 9px;
  .reportDeviceGroups {
    width: 250px;
  }
  .reportDeviceDateRange {
    width: 235px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Tab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  iframe {
    width: 100%;
    height: 300px;
  }
`;

export const TabTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #1a1c21;
  font-weight: 500;
`;
