type CellphoneProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconCellphone({ width = 14, height = 22, color = '#6E6E6E', test_id = 'icons__cellphone' }: CellphoneProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11 0H3C1.34 0 0 1.34 0 3v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V3c0-1.66-1.34-3-3-3ZM9 20H5v-1h4v1Zm3.25-3H1.75V3h10.5v14Z"
        fill={color}
      />
    </svg>
  );
}
