import { EuiBetaBadge, EuiButton } from '@elastic/eui';
import styled from 'styled-components';
import { FixedSizeList as List } from 'react-window';

export const Container = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const ListsContainer = styled.div`
  height: 338px;
`;

export const DevicesList = styled(List)`
  &::-webkit-scrollbar-thumb {
    min-height: 50px;
  }
  margin-bottom: 36px;
`;

export const SelectedDevicesList = styled.div`
  display: flex;
  max-height: 149px;
  overflow: auto;
  flex-direction: column;
`;

export const DeviceItem = styled.button`
  border: none;
  border-top: 1px solid #d3dae6;
  display: flex;
  gap: 12px;
  align-items: center;
  padding: 12px 0;
`;

export const SelectedDeviceItem = styled.div`
  display: flex;
  border: none;
  border-top: 1px solid #d3dae6;
  align-items: center;
  padding: 12px 0;
  justify-content: space-between;
`;

export const WrapperMainInfos = styled.div`
  border: none;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StyledBadge = styled(EuiBetaBadge)`
  box-shadow: inset 0 0 0 1px #30a8ff;
  color: #30a8ff;
  text-decoration: underline;
  cursor: pointer;
`;

export const DeviceName = styled.span`
  color: #67758d;
  font-size: 14px;
  font-weight: 500;
`;

export const CenteredLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
`;

export const Close = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const StyledButton = styled(EuiButton)`
  background: ${({ theme }) => theme.main} !important;
  border-color: ${({ theme }) => theme.main} !important;
  color: #fff;
  width: fit-content;
  margin-top: 25px;
  &:disabled {
    color: #fff !important;
  }

  &:disabled {
    filter: opacity(50%);
  }
`;

export const EmptyDiv = styled.div`
  width: 24px;
`;
