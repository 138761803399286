import { EuiCard, EuiFieldSearch, EuiFilterButton, EuiFilterGroup, EuiFlexItem, EuiFormRow } from '@elastic/eui';
import Icon from '@mdi/react';
import { mdiArrowLeftBottom } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { ModalAddCsv, TableSim } from 'modules/management/components';
import type { SimManagementContracts } from './ComponentSimManagement.contracts';
import * as S from './ComponentSimManagement.style';
import { HelperFunctions } from 'helpers';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';
import SimCardOutlinedIcon from '@mui/icons-material/SimCardOutlined';

export function SimManagement({
  csvLoading,
  fetchCsv,
  handleChangePage,
  handleDeleteSIM,
  searchSims,
  loading,
  message,
  setMessage,
  setToast,
  modal,
  page,
  setModal,
  sims,
  totalItems,
  countStatus,
  selectedStatus,
  handleSelectedStatus,
  searchSelect,
  handleFilterBySelect,
  searchValue,
  handleInputValue,
  customFieldLabels,
}: SimManagementContracts) {
  const { t } = useTranslation('translation', { keyPrefix: 'sim_management' });

  const [searchPlaceholder, setSearchPlaceholder] = useState(t('page_description.table_text.pulsus_id'));
  const [isPinChanged, setIsPinChanged] = useState(false);
  const [isNeedToLock, setIsNeedToLock] = useState(false);
  const [isDeleted, setIsDeleted] = useState(false);

  useEffect(() => {
    updateSelectedStatus(isPinChanged, isNeedToLock, isDeleted);
  }, [isPinChanged, isNeedToLock, isDeleted]);

  useEffect(() => {
    searchSims(searchSelect, searchValue, selectedStatus);
  }, [selectedStatus]);

  const updateSelectedStatus = async (pinChanged: boolean, needToLock: boolean, deleted: boolean) => {
    const selectedStatusList = [pinChanged && 'LOCKED,PIN_CHANGED', needToLock && 'NEED_TO_LOCK', deleted && 'DELETED'].filter(Boolean);

    const newSelectedStatus = selectedStatusList.join(',');

    handleSelectedStatus(newSelectedStatus);
  };

  const onChangeSearchSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    const optionValue = e.target.value;
    const optionIdx = e.target.selectedIndex;
    const optionName = e.target.options[optionIdx].text;
    handleFilterBySelect(optionValue);

    const placeHolderOptions = {
      iccid: 'ICCID',
      id: t('page_description.table_text.pulsus_id'),
      name: t('filter_placeholder.user_name'),
      email: 'E-mail',
      serial_number: t('filter_placeholder.serial_number'),
      imei: 'IMEI',
      customField: { customFieldLabels },
    };

    setSearchPlaceholder(placeHolderOptions[optionValue] || optionName || '');
  };

  const handleFilterButtonClick = (prevState: boolean, actionType: string) => {
    const newState = !prevState;

    updateSelectedStatus(
      actionType === 'pinChanged' ? newState : isPinChanged,
      actionType === 'needToLock' ? newState : isNeedToLock,
      actionType === 'isDeleted' ? newState : isDeleted
    );

    switch (actionType) {
      case 'pinChanged':
        setIsPinChanged(newState);
        break;
      case 'needToLock':
        setIsNeedToLock(newState);
        break;
      case 'isDeleted':
        setIsDeleted(newState);
        break;
      default:
        break;
    }
  };

  const renderSearch = () => {
    return (
      <EuiFilterGroup>
        <EuiFilterButton
          hasActiveFilters={isPinChanged}
          onClick={() => {
            handleFilterButtonClick(isPinChanged, 'pinChanged');
          }}
        >
          {t('page_description.table_text.status.lock')}
        </EuiFilterButton>
        <EuiFilterButton
          hasActiveFilters={isNeedToLock}
          onClick={() => {
            handleFilterButtonClick(isNeedToLock, 'needToLock');
          }}
        >
          {t('page_description.table_text.status.pending')}
        </EuiFilterButton>
        <EuiFilterButton
          hasActiveFilters={isDeleted}
          onClick={() => {
            handleFilterButtonClick(isDeleted, 'isDeleted');
          }}
        >
          {t('page_description.table_text.status.deleted')}
        </EuiFilterButton>
      </EuiFilterGroup>
    );
  };

  const cardsStatus = () => {
    return (
      <S.CardsStatusContainer>
        <EuiFlexItem>
          <EuiCard
            icon={<SimCardOutlinedIcon sx={{ color: '#34B56F', fontSize: '31px' }} />}
            title={<h3 className="managementStatusTitle">{`${countStatus?.managed} ${t('card_status.management_status_title')}`}</h3>}
            description={<p className="statusDescription">{t('card_status.management_status_description')}</p>}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<SimCardOutlinedIcon sx={{ color: '#EB996E', fontSize: '31px' }} />}
            title={<h3 className="pendingStatusTitle">{` ${countStatus?.pending} ${t('card_status.pending_status_title')}`}</h3>}
            description={<p className="statusDescription">{t('card_status.pending_status_description')}</p>}
          />
        </EuiFlexItem>
      </S.CardsStatusContainer>
    );
  };

  return (
    <>
      <S.Container contentOnly>
        <S.ContainerButton>
          <S.StyledButton fill iconType="plusInCircle" onClick={() => setModal(true)}>
            {t('page_description.button')}
          </S.StyledButton>
        </S.ContainerButton>
        <S.TopContainer>
          <S.StyledH1>{t('page_description.title')}</S.StyledH1>
          <S.SimCardsTotal className="small">
            <b>{countStatus.total}</b>
            {t('page_description.total')}
          </S.SimCardsTotal>
          <S.StyledP>{t('page_description.subtitle1')}</S.StyledP>
          <S.StyledP>{t('page_description.subtitle2')}</S.StyledP>
        </S.TopContainer>
        {cardsStatus()}
        <S.SearchBarContainer>
          <S.SearchBar>
            <div className="text-field">
              <S.SearchSelect onChange={(e) => onChangeSearchSelect(e)}>
                <option value="iccid">ICCID</option>
                <option value="id">{t('page_description.table_text.pulsus_id')}</option>
                <option value="name">{t('filter_placeholder.user_name')}</option>
                <option value="email">E-mail</option>
                <option value="serial_number">{t('filter_placeholder.serial_number')}</option>
                <option value="imei">IMEI</option>
                {customFieldLabels?.map((item) => {
                  return (
                    <option key={item.value} value={item.value}>
                      {item.text}
                    </option>
                  );
                })}
              </S.SearchSelect>
              <EuiFormRow isInvalid={!HelperFunctions.inputHasOnlyNumbers(searchValue) && searchSelect === 'id'} error={t('title_error')}>
                <EuiFieldSearch
                  placeholder={`${t('text_search_placeholder')} ${searchPlaceholder}`}
                  value={searchValue}
                  onChange={(e) => handleInputValue(e.target.value)}
                  aria-label={t('text_search')}
                  onSearch={(search) => searchSims(searchSelect, search, selectedStatus)}
                />
              </EuiFormRow>
              <S.SearchAppend>
                <S.SearchButtonIcon title={t('text_search')} onClick={() => searchSims(searchSelect, searchValue)}>
                  <Icon path={mdiArrowLeftBottom} size={0.9} />
                </S.SearchButtonIcon>
              </S.SearchAppend>
            </div>
          </S.SearchBar>
          <S.SearchStatus>{renderSearch()}</S.SearchStatus>
        </S.SearchBarContainer>
        <S.TableContainer>
          <TableSim
            totalItems={totalItems}
            currentPage={page}
            handleChangePage={(currentPage) => handleChangePage(currentPage)}
            handleDeleteSIM={() => handleDeleteSIM()}
            loading={loading}
            message={message}
            setMessage={setMessage}
            setToast={setToast}
            data={sims}
          />
        </S.TableContainer>
      </S.Container>
      {modal && <ModalAddCsv addList={(e, file) => fetchCsv(e, file)} loading={csvLoading} setOpen={setModal} />}
    </>
  );
}
