import styled, { css } from 'styled-components';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { InputAdornment } from '@mui/material';

import { Input } from '@mui/material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #000000;
`;

export const FlexRow = styled.div<{ row?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ row }) =>
    row &&
    css`
      flex-direction: row;
      justify-content: space-between;
      width: 70%;
    `}
`;

export const Title = styled.span`
  font-size: 13px;
  line-height: 16px;
  font-weight: 400;
  margin: 12px 0;
`;

export const SubTitle = styled.p`
  font-size: 13px;
  line-height: 16px;
  font-weight: 300;
  margin: 5px 0 29px 0;
  color: #000;

  span {
    font-weight: 400;
  }

  p {
    font-style: italic;
    display: inline;
  }
`;

export const Items = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #cec7d1;
  height: 50px;
  align-items: center;

  div {
    justify-self: flex-start;
    width: 250px;
    display: flex;
  }
`;
export const Headers = styled.div`
  width: 90%;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    width: 250px;
    justify-self: flex-start;
  }
`;
export const GridContainer = styled.div`
  width: 100%;
  font-size: 13px;
  padding: 5px;
  margin-top: 20px;

  .topTable {
    font-weight: 700;
  }
`;

export const UrlAppArea = styled.div`
  height: 58px;
  display: flex;
`;

export const AppDescription = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const LogoApp = styled.img`
  width: 45px;
  height: 41px;
  margin-right: 4px;
`;

export const AppStatusArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-style: 13px;
  padding-right: 8px;
  width: 50px;
`;

export const InfoOs = styled.div`
  width: 451px;
  height: 37px;
  background-color: #fff9e8;
  display: flex;
  align-items: center;
  margin-top: 20px;
  p {
    font-size: 12px;
    color: #8a6a0a;
  }
`;

export const EmptyApp = styled.div`
  width: 95%;
  height: 63px;
  background-color: #f4f4f4;
  font-size: 13px;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000000;
`;

export const InputApp = styled(Input)`
  width: 45%;
  margin-top: 20px;
`;

export const InputError = styled.p`
  color: red;
  margin-top: 10px;
`;

export const InfoIconMessage = styled(InfoIcon)`
  color: #8a6a0a;
  width: 12px !important;
  height: 12px !important;
  margin: 0 4px 0 8px;
`;

export const DeleteAppIcon = styled(DeleteIcon)`
  color: #f44336;
  width: 16px !important;
  height: 20px !important;
  cursor: pointer;
`;

export const AddAppIcon = styled(AddCircleIcon)<{ disable: boolean; title?: string }>`
  ${({ disable }) =>
    disable
      ? css`
          color: #cbcbcb;
          cursor: auto;
          pointer-events: none;
        `
      : css`
          color: #30a8ff;
          cursor: pointer;
          pointer-events: auto;
        `};
`;

export const Plus = styled.span`
  align-self: flex-end;
  padding-bottom: 10px;
  font-size: 12px;
  color: #a9a9a9;
`;

export const ContainerInputAdornment = styled(InputAdornment)<{ title?: string }>`
  height: 20px !important;
  width: 20px;
`;
