import { IconInformation } from 'icons';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import * as style from './button-contact.style';
import type { ButtonProps } from '../component-application-header.contracts';

export function ButtonContact({ onClick }: ButtonProps) {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <style.Button onClick={onClick} data-testid="button-contact">
      <style.Container>
        <style.Label data-testid="button-contact-label">{t('components.application_header.buttons.contact_label')}</style.Label>
        <IconInformation fill={theme.contrast} test_id="button-contact-icon" />
      </style.Container>
    </style.Button>
  );
}
