import type { ReactNode } from 'react';
import type { AvailableOptionalThemes, ThemeProps } from './contracts';
import { ThemeProvider, type DefaultTheme } from 'styled-components';
import { createContext, useContext, useState } from 'react';
import { mainTheme, dark, light } from './AvailableThemes/main';
import { AVAILABLE_OPTIONAL_THEMES } from './contracts';

export const ThemeContext = createContext<ThemeProps>({} as ThemeProps);

export const useThemeContext = (): ThemeProps => useContext(ThemeContext);

export const ThemeLocalProvider = ({ children, initialTheme }: { children: ReactNode; initialTheme?: DefaultTheme }) => {
  const dashboardThemeDefault = localStorage.getItem('@dashboard_theme');
  const availableDashboardThemes = {
    dark,
    light,
  };

  const getInitialTheme = (): DefaultTheme => {
    if (initialTheme) return initialTheme;

    return { ...mainTheme, dashboardTheme: availableDashboardThemes[dashboardThemeDefault as string] || light };
  };

  const [theme, setTheme] = useState<DefaultTheme>(getInitialTheme());

  const getOptionalTheme = (themeName: AvailableOptionalThemes) => {
    return AVAILABLE_OPTIONAL_THEMES?.[themeName] || AVAILABLE_OPTIONAL_THEMES?.PULSUS;
  };

  const changeTheme = (themeColor: string) => {
    setTheme((old) => ({ ...old, main: getOptionalTheme(themeColor as AvailableOptionalThemes) }));
  };

  const changeDashboardTheme = (newTheme: 'dark' | 'light') => {
    setTheme((old) => ({ ...old, dashboardTheme: availableDashboardThemes[newTheme] }));
    localStorage.setItem('@dashboard_theme', newTheme);
  };

  return (
    <ThemeContext.Provider value={{ changeTheme, changeDashboardTheme }}>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};
