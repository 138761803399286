import type { RouteObject } from 'react-router-dom';
import { EmbeddedPageDevices } from '../embedded';
import { DeviceLocationHistoryPage } from '../pages/DeviceHistoryLocation/DeviceHistoryLocationPage';
import React, { lazy, Suspense } from 'react';
import { MapsProvider } from '../contexts/maps';
import MapsPage from '../pages/maps/MapsPage';
import { LoadingFallbackComponent } from 'modules/core/components/LoadingFallback/LoadingFallbackComponent';
import { RouteGuardComponent } from 'modules/core/components/RouteGuard/RouteGuardComponent';
import { UserProvider } from 'modules/core/contexts/user';
import DashboardPage from '../pages/dashboard/DashboardPage';
import { FooterComponent } from 'modules/core/components/Footer/FooterComponent';
import { SearchBarProvider } from 'modules/core/contexts/searchBar';

const UsersPage = lazy(async () => import('modules/operational/pages/users/UsersPage'));
const EmbeddedAddEmployeeUserModal = lazy(async () => import('modules/operational/embedded/AddEmployeeUserModal/EmbeddedAddEmployeeUserModal'));
import { DataUsageProvider } from '../contexts/dataUsage/DataUsageProvider';
import UsersDetailsPage from '../pages/usersDetails/UsersDetailsPage';
const DataUsagePage = React.lazy(async () => await import('../pages/dataUsage/DataUsagePage'));
const DeviceDataUsagePage = React.lazy(async () => await import('../pages/deviceDataUsage/DeviceDataUsagePage'));

export const EmbeddedOperationalRoutes: RouteObject[] = [
  {
    path: 'device-management',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <EmbeddedPageDevices />,
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <DashboardPage />
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'device-history-location',
    element: (
      <RouteGuardComponent>
        <UserProvider>
          <DeviceLocationHistoryPage />
        </UserProvider>
      </RouteGuardComponent>
    ),
  },
  {
    path: 'maps',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <MapsProvider>
              <MapsPage />
              <FooterComponent />
            </MapsProvider>
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'data-usage',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <DataUsageProvider>
              <DataUsagePage />
              <FooterComponent />
            </DataUsageProvider>
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'add-employee-user',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <EmbeddedAddEmployeeUserModal />
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'users',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <SearchBarProvider>
              <UsersPage />
              <FooterComponent />
            </SearchBarProvider>
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: `users/:id`,
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <SearchBarProvider>
              <UsersDetailsPage />
              <FooterComponent />
            </SearchBarProvider>
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'device-data-usage',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <DataUsageProvider>
              <DeviceDataUsagePage />
            </DataUsageProvider>
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
];
