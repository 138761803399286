type IconPulsusLogoProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconPulsus({ width = 22, height = 22, color = '#FFFFFF', test_id = 'icons__icon-pulsus-logo' }: IconPulsusLogoProps) {
  return (
    <svg
      data-testid={test_id}
      id="icon-pulsus"
      width={width}
      height={height}
      fill={color}
      data-name="pulsus-icon"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 22 22"
    >
      <path
        d="M12.1931 6.62305H9.7803C7.88168 6.62305 6.93237 7.20845 6.93237 8.3674V14.9137C6.93237 15.1114 6.98776 15.238 7.08664 15.2894C7.23488 15.3583 7.39823 15.3883 7.56127 15.3764C7.72799 15.3854 7.89455 15.3557 8.04781 15.2894C8.15856 15.238 8.21393 15.1114 8.21393 14.9137V12.3031H12.1931C14.0917 12.3031 15.041 11.7177 15.041 10.5587V8.38322C15.041 7.20978 14.0917 6.62305 12.1931 6.62305ZM13.7753 10.5587C13.7673 10.6716 13.7279 10.7799 13.6615 10.8715C13.5951 10.9631 13.5043 11.0343 13.3995 11.0769C13.0112 11.241 12.5901 11.3127 12.1694 11.2865H8.23766V8.3674C8.24909 8.25286 8.29213 8.14375 8.36197 8.05225C8.43182 7.96075 8.52573 7.89046 8.63321 7.84924C9.0214 7.68474 9.44258 7.61296 9.86335 7.6396H12.1931C12.6138 7.6134 13.0349 7.68516 13.4232 7.84924C13.527 7.89335 13.6168 7.96488 13.683 8.05617C13.7492 8.14746 13.7893 8.25505 13.799 8.3674L13.7753 10.5587Z"
        fill="white"
      />
      <path
        d="M11.0071 21.1199C9.6613 21.1465 8.32369 20.9044 7.07258 20.4078C5.82147 19.9112 4.68199 19.17 3.72081 18.2276C2.75963 17.2853 1.99606 16.1606 1.4748 14.9196C0.953535 13.6785 0.685059 12.346 0.685059 10.9999C0.685059 9.65383 0.953535 8.32128 1.4748 7.08023C1.99606 5.83918 2.75963 4.71456 3.72081 3.77219C4.68199 2.82983 5.82147 2.08864 7.07258 1.59202C8.32369 1.09539 9.6613 0.8533 11.0071 0.879908C12.3656 0.873048 13.7112 1.14218 14.9626 1.671C15.0372 1.69929 15.1054 1.74236 15.163 1.79763C15.2207 1.85291 15.2665 1.91925 15.2979 1.99268C15.3293 2.0661 15.3455 2.14511 15.3456 2.22496C15.3458 2.30481 15.3298 2.38386 15.2986 2.45738C15.2674 2.53089 15.2217 2.59737 15.1643 2.65281C15.1068 2.70825 15.0387 2.75152 14.9641 2.78002C14.8896 2.80853 14.81 2.8217 14.7302 2.81872C14.6504 2.81575 14.572 2.79669 14.4998 2.7627C12.6358 1.97756 10.5602 1.84955 8.61386 2.3997C6.66751 2.94985 4.96606 4.14545 3.78889 5.79021C2.61172 7.43496 2.02878 9.4311 2.13581 11.4509C2.24283 13.4707 3.03344 15.394 4.37784 16.9052C5.72223 18.4163 7.54052 19.4254 9.53411 19.7668C11.5277 20.1082 13.5781 19.7615 15.3487 18.7838C17.1193 17.8061 18.5048 16.2553 19.2777 14.3863C20.0507 12.5172 20.1651 10.4408 19.6023 8.49809C19.5768 8.42218 19.567 8.3419 19.5734 8.26209C19.5797 8.18228 19.6022 8.10458 19.6394 8.03368C19.6766 7.96279 19.7278 7.90015 19.7898 7.84955C19.8519 7.79895 19.9235 7.76144 20.0005 7.73927C20.0774 7.7171 20.158 7.71072 20.2375 7.72054C20.317 7.73035 20.3936 7.75614 20.4629 7.79637C20.5321 7.83659 20.5925 7.89042 20.6403 7.95459C20.6882 8.01877 20.7226 8.09197 20.7415 8.16979C21.0077 9.08741 21.1409 10.0385 21.137 10.994C21.1349 13.6796 20.0669 16.2545 18.1675 18.1532C16.2681 20.0518 13.6927 21.1189 11.0071 21.1199V21.1199Z"
        fill="white"
      />
      <path
        d="M18.0987 5.51575C18.4701 5.51575 18.7711 5.21469 18.7711 4.84332C18.7711 4.47195 18.4701 4.1709 18.0987 4.1709C17.7273 4.1709 17.4263 4.47195 17.4263 4.84332C17.4263 5.21469 17.7273 5.51575 18.0987 5.51575Z"
        fill="white"
      />
    </svg>
  );
}
