import { EuiRadioGroup, EuiTextArea } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';
import type { MessageContainerProps, TextAreaProps } from '../page-android-enterprise.contracts';

export const Content = styled.main`
  padding: 60px 60px 40px 50px;
  margin-left: 50px;
  background-color: ${ConstantColors.AUSTRIA};
  position: relative;
`;

export const Top = styled.section`
  max-width: 520px;

  p {
    margin: 20px 0;
    color: ${ConstantColors.ITALY};
  }
`;

export const ColumnsContainer = styled.section`
  display: flex;
  gap: 50px;
`;

export const Column = styled.section`
  margin-top: 70px;
  max-width: 45%;

  .icon-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    p {
      margin-left: 5px;
      font-size: 15px;
      color: ${ConstantColors.GREECE};
      font-weight: 600;
    }
  }

  .icon {
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${ConstantColors.DJBOUTI};
    border-radius: 4px;

    svg {
      width: 15px;
    }
  }
`;

export const MessageContainer = styled.section<MessageContainerProps>`
  margin-top: 50px;

  h5 {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 10px;
    margin-top: 15px;
    color: ${({ isLong }) => (isLong ? ConstantColors.HAITI : ConstantColors.FRANCE)};
  }

  p {
    color: ${ConstantColors.SPAIN};
    margin-top: 5px;
  }
`;

export const RadioGroup = styled(EuiRadioGroup)`
  display: flex;
  margin-bottom: 15px;

  .euiRadioGroup__item {
    margin: 0;
    margin-right: 25px;
  }
`;

/* eslint-disable-next-line */
export const TextArea = styled(EuiTextArea) <TextAreaProps>`
  border-radius: 4px;
`;

export const Line = styled.div`
  position: absolute;
  max-width: 95%;
  opacity: 0.2;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
  border-top: 1px solid ${ConstantColors.LAOS};
`;
