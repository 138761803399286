import { HelperAuthentication } from 'helpers';
import { useQuery } from 'hooks/use-query';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router';
import { ConstantRoutes } from 'utilities';

export const AuthComponent = ({ children }) => {
  const query = useQuery();
  const history = useNavigate();
  const location = useLocation();

  const token = query.get('token');

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (token) {
      HelperAuthentication.login(token);
      query.delete('token');
      history(`${location.pathname}?${query.toString()}`);
    }
    setLoading(false);
  }, [token]);

  const render = () => {
    if (loading) return 'Loading...';
    if (HelperAuthentication.isAuthenticated()) return children;
    return <Navigate to={ConstantRoutes.LOGIN.path} state={{ location }} replace />;
  };

  return render();
};
