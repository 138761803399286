import { ComponentBreadcrumb } from 'components';
import { IconPulsusLogo } from 'icons';

import image_pulsus_locations_home from 'assets/images/image-pulsus-locations-home.svg';
import image_pulsus_locations_maps from 'assets/images/image-pulsus-locations-maps.svg';
import image_pulsus_locations_geofence from 'assets/images/image-pulsus-locations-geofence.svg';
import image_pulsus_locations_tracking from 'assets/images/image-pulsus-locations-tracking.svg';

import { Link, useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { ManagementBreadcrumbs } from 'modules/management/pages/ManagementBreadcrumbs';
import { useTranslation } from 'react-i18next';
import * as S from './PageLocations.style';

export function PageLocations() {
  const [displayTracking] = useState(true);
  const [displayMaps] = useState(true);
  const [displayGeofence] = useState(true);

  const history = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'geofence.main_page' });

  return (
    <>
      <ComponentBreadcrumb items={ManagementBreadcrumbs.PULSUS_LOCATION.breadcrumbs} />
      <S.Container data-testid="page__pulsus_insight">
        <S.TopContainer>
          <S.LeftContainer>
            <S.Title>
              <IconPulsusLogo width={200} height={35} color="#525252" />
              <h1>LOCATIONS</h1>
            </S.Title>
            <S.TextPrimary data-testid="text_primary__pulsus-insight">{t('title')}</S.TextPrimary>
          </S.LeftContainer>
          <S.RightContainer>
            <S.ImagePulsusLocationsHome data-testid="image_pulsus_locations" src={image_pulsus_locations_home} alt={t('imageAlt')} />
          </S.RightContainer>
        </S.TopContainer>
        <S.BottomContainer>
          <S.TitleSolutions>{t('solutions')}</S.TitleSolutions>
          <S.LinksContainer>
            <S.Link isDisabled={!displayTracking}>
              <button
                type="button"
                onClick={() => {
                  if (window.self !== window.top) {
                    window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_HOST}/maps/tracks`;
                  } else {
                    history('/locations/tracking');
                  }
                }}
              >
                <S.Card>
                  <S.CardLeft>
                    <S.CardTitle isDisabled={!displayTracking}>
                      <IconPulsusLogo width={90} height={17} color="#525252" />
                      <h2>Tracking</h2>
                      <S.FlagComingSoon isDisabled={!displayTracking}>{t('soon')}</S.FlagComingSoon>
                    </S.CardTitle>
                    <S.OpenLink isDisabled={!displayTracking}>{`${t('open')} >`}</S.OpenLink>
                  </S.CardLeft>
                  <S.CardRight isDisabled={!displayTracking}>
                    <S.ImagePulsusLocationsCards data-testid="image_pulsus_tracking" src={image_pulsus_locations_tracking} alt="" />
                  </S.CardRight>
                </S.Card>
              </button>
              <S.LinkTexts isDisabled={!displayMaps}>
                <p>{t('tracking')}</p>
              </S.LinkTexts>
            </S.Link>
            <S.Link isDisabled={!displayMaps}>
              <button
                type="button"
                onClick={() => {
                  if (window.self !== window.top) {
                    window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_HOST}/maps`;
                  } else {
                    history('/locations/maps');
                  }
                }}
              >
                <S.Card>
                  <S.CardLeft>
                    <S.CardTitle isDisabled={!displayMaps}>
                      <IconPulsusLogo width={90} height={17} color="#525252" />
                      <h2>Maps</h2>
                      <S.FlagComingSoon isDisabled={!displayMaps}>{t('soon')}</S.FlagComingSoon>
                    </S.CardTitle>
                    <S.OpenLink isDisabled={!displayMaps}>
                      {t('open')}
                      {' >'}
                    </S.OpenLink>
                  </S.CardLeft>
                  <S.CardRight isDisabled={!displayMaps}>
                    <S.ImagePulsusLocationsCards data-testid="image_pulsus_locations" src={image_pulsus_locations_maps} alt={t('imageAltMap')} />
                  </S.CardRight>
                </S.Card>
              </button>
              <S.LinkTexts isDisabled={!displayMaps}>
                <p>{t('titleMaps')}</p>
              </S.LinkTexts>
            </S.Link>
            <S.Link isDisabled={!displayGeofence}>
              <Link to="/management/locations/geofence">
                <S.Card>
                  <S.CardLeft>
                    <S.CardTitle isDisabled={!displayGeofence}>
                      <IconPulsusLogo width={90} height={17} color="#525252" />
                      <h2>Geofence</h2>
                      <S.FlagComingSoon isDisabled={!displayGeofence}>{t('soon')}</S.FlagComingSoon>
                    </S.CardTitle>
                    <S.OpenLink isDisabled={!displayGeofence}>
                      {t('open')}
                      {' >'}
                    </S.OpenLink>
                  </S.CardLeft>
                  <S.CardRight isDisabled={!displayGeofence}>
                    <S.ImagePulsusLocationsCards data-testid="image_pulsus_geofence" src={image_pulsus_locations_geofence} alt={t('imageAltFence')} />
                  </S.CardRight>
                </S.Card>
              </Link>
              <S.LinkTexts isDisabled={!displayGeofence}>
                <p>{t('fenceDescription')}</p>
              </S.LinkTexts>
            </S.Link>
          </S.LinksContainer>
        </S.BottomContainer>
      </S.Container>
    </>
  );
}
