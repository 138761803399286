import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HelperAuthentication } from 'helpers';
import { ModalChangePassword } from 'components/application-header/modal-change-password/modal-change-password';

import { useTheme } from 'styled-components';
import * as style from './modal-profile.style';
import { IconProfile } from '../../../icons/profile/icon-profile';
import type { ModalProfileProps } from '../component-application-header.contracts';

export function ModalProfile({ openTenantFlyout }: ModalProfileProps) {
  const [is_open_modal_password, set_is_open_modal_password] = useState(false);

  const { t } = useTranslation();
  const theme = useTheme();

  const email = HelperAuthentication.decodingEmail();

  const handleModalPassword = () => {
    set_is_open_modal_password(true);
  };

  const handleCloseModal = () => {
    set_is_open_modal_password(false);
  };

  return (
    <>
      <style.ModalContainer data-testid="modal-profile">
        <style.ModalHeader>
          <IconProfile fill={theme.main} width="50px" height="50px" />
          <h1 data-testid="profile_title">{t('components.component_profile_modal.profile_title')}</h1>
          <p>{email}</p>
        </style.ModalHeader>

        <style.SeparationLine />

        <style.ModalSection>
          <h3 data-testid="client_title">{t('components.component_profile_modal.client_title')}</h3>
          <button type="button" data-testid="change_client_button" onClick={() => openTenantFlyout()}>
            {t('components.component_profile_modal.change_client_button')}
          </button>
        </style.ModalSection>

        <style.SeparationLine />

        <style.ModalSection>
          <h3 data-testid="user_title">{t('components.component_profile_modal.user_title')}</h3>
          <nav>
            <button type="button" onClick={() => handleModalPassword()} data-testid="password_change_button">
              <span>{t('components.component_profile_modal.user_password_change')}</span>
            </button>
            <Link to="/login" onClick={() => HelperAuthentication.logout()} data-testid="user_logout_link">
              <span>{t('components.component_profile_modal.user_logout')}</span>
            </Link>
          </nav>
        </style.ModalSection>
      </style.ModalContainer>
      {is_open_modal_password && <ModalChangePassword onClose={handleCloseModal} />}
    </>
  );
}
