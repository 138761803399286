import type { EuiBasicTableColumn, EuiTableSelectionType } from '@elastic/eui';
import { EuiInMemoryTable } from '@elastic/eui';
import { mdiContentCopy, mdiPencil, mdiDelete, mdiTag } from '@mdi/js';
import Icon from '@mdi/react';
import {
  ComponentBreadcrumb,
  ComponentComboBox,
  ComponentSearchField,
  ComponentTextField,
  ComponentToast,
  DialogComponent,
  SaveBar,
} from 'components';
import type { ComboBoxOption } from 'components/combo-box/component-combo-box.contracts';
import _ from 'lodash';
import type { Toast } from 'modules/core/components';
import { ComponentDeleteModal } from 'modules/core/components';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServicePulsusTyr } from 'services/ServicePulsusTyr';
import { ChromeBreadcrumbs } from '../chromeBreadcrumbs';
import type { EmbeddedDataProps, Group, TableDataProps } from './page-chromeOS-configurations-list.contracts';
import * as style from './page-chromeOS-configurations-list.style';

export function PageConfigurationsList({ embeddedData, embeddedOnEvent }: EmbeddedDataProps) {
  const [searchValue, setSearchValue] = useState('');
  const [selectedItems, setSelectedItems] = useState<TableDataProps[]>([]);
  const [data, setData] = useState<TableDataProps[]>([]);
  const [initialData, setInitialData] = useState<TableDataProps[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [open, setOpen] = useState(false);
  const [version, setVersion] = useState(0);
  const [newConfigurationName, setNewConfigurationName] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<ComboBoxOption[]>([]);
  const [comboBoxIsFocused, setComboBoxIsFocused] = useState(false);
  const [groups, setGroups] = useState<ComboBoxOption[]>([]);
  const [temporaryID, setTemporaryID] = useState(0);
  const [password, setPassword] = useState('');
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newList, setNewList] = useState<TableDataProps[]>([]);

  const { t } = useTranslation();

  const TyrService = useMemo(() => new ServicePulsusTyr(), []);

  const cols: EuiBasicTableColumn<TableDataProps>[] = [
    {
      field: 'name',
      name: t('pages.chromeOS_configurations_list.table_collumn_configuration'),
      render: (name: string, item: TableDataProps) => <style.UndoLink>{item.name}</style.UndoLink>,
    },
    {
      field: 'groups',
      name: t('pages.chromeOS_configurations_list.table_collumn_groups'),
      align: 'center',
      render: () => <style.GroupCount>{`0 ${t('pages.chromeOS_configurations_list.table_collumn_value_groups')}`}</style.GroupCount>,
    },
    {
      field: 'devices',
      name: t('pages.chromeOS_configurations_list.table_collumn_devices'),
      align: 'center',
      render: () => <style.DeviceCount>{`0 ${t('pages.chromeOS_configurations_list.table_collumn_value_devices')}`}</style.DeviceCount>,
    },
    {
      field: 'action',
      name: '',
      align: 'right',
      render: (name: string, item: TableDataProps) => {
        return (
          <style.Actions>
            <style.CustomIconButton className="disabled">
              <Icon path={mdiPencil} size="16px" color="black" />
            </style.CustomIconButton>
            <style.CustomIconButton onClick={() => copy(item)}>
              <Icon path={mdiContentCopy} size="16px" color="black" />
            </style.CustomIconButton>
            <style.CustomIconButton onClick={() => confirmDelete([item])}>
              <Icon path={mdiDelete} size="16px" color="black" />
            </style.CustomIconButton>
          </style.Actions>
        );
      },
    },
  ];

  const selectionValue: EuiTableSelectionType<TableDataProps> = {
    onSelectionChange: (item: TableDataProps[]) => setSelectedItems([...item]),
  };

  function uncheckGroups() {
    setVersion(version + 1);
    setSelectedItems([]);
  }

  function confirmDelete(elements: TableDataProps[]) {
    setNewList([...elements]);
    setDeleteModalOpen(true);
  }

  function removeElements() {
    const filteredList = _.differenceBy(data, newList, 'orgUnitId');
    setData([...filteredList]);
    setNewList([]);
    setPassword('');
    setDeleteModalOpen(false);
    uncheckGroups();
  }

  function copy(element: TableDataProps) {
    setOpen(true);
    setNewConfigurationName(`${element.name} ${t('pages.chromeOS_configurations_list.copy_element_title_sufix')}`);
  }

  function closeModal() {
    setOpen(false);
    setNewConfigurationName('');
    setSelectedOptions([]);
    setComboBoxIsFocused(false);
  }

  async function populateList() {
    if (embeddedData) {
      TyrService.getConfigurations(embeddedData.customerId).then((list: TableDataProps[]) => {
        setData(list || []);
        setInitialData(list || []);
      });
      TyrService.getGroups(embeddedData.customerId).then((items) => {
        const auxGroups = items ? items.map((item: Group) => ({ id: item.id, label: item.name })) : [];
        setGroups(auxGroups);
      });
    }
  }

  function discartChanges() {
    setData(initialData);
  }

  function saveChanges() {
    // setInitialData(data);
  }

  function addNewConfiguration() {
    const copyData = JSON.parse(JSON.stringify(data));
    copyData.push({ name: newConfigurationName, orgUnitId: temporaryID.toString(), orgUnitPath: '' });
    setData(copyData);
    setTemporaryID(temporaryID + 1);
    closeModal();
  }

  function closeDeleteModal() {
    setDeleteModalOpen(false);
    setPassword('');
  }

  useEffect(() => {
    populateList();
  }, [embeddedData]);

  return (
    <>
      {!embeddedOnEvent && <ComponentBreadcrumb items={ChromeBreadcrumbs.PULSUS_CHROME_OS_CONFIGURATIONS_LIST.breadcrumbs} />}
      <style.Container data-testid="chromeOS-configuratons-list-container">
        <style.TopActions data-testid="chromeOS-configuratons-list-top-actions">
          <style.InfoLink style={{ display: 'none' }}>{t('pages.chromeOS_configurations_list.header_more_info_button')}</style.InfoLink>
          <style.TopButton data-testid="chromeOS-configuratons-list-modal-open-button" iconType="plusInCircle" fill onClick={() => setOpen(true)}>
            {t('pages.chromeOS_configurations_list.header_add_new_configuration')}
          </style.TopButton>
        </style.TopActions>
        <style.Header data-testid="chromeOS-configuratons-list-header">
          <style.Title data-testid="chromeOS-configuratons-list-header-title">{t('pages.chromeOS_configurations_list.page_title')}</style.Title>
          <style.Description data-testid="chromeOS-configuratons-list-header-description">
            {t('pages.chromeOS_configurations_list.page_description')}
          </style.Description>
          <style.Field data-testid="chromeOS-configuratons-list-header-field">
            <ComponentSearchField
              test_id="chromeOS-configuratons-list-header-search-field"
              placeholder={t('pages.chromeOS_configurations_list.configuration_search_label')}
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
            />
          </style.Field>
          <style.Line data-testid="chromeOS-configuratons-list-header-line">
            <style.InfoBox data-testid="chromeOS-configuratons-list-header-info-box">
              <style.CountText>{`${selectedItems.length} ${t('pages.chromeOS_configurations_list.selected_items')}`}</style.CountText>
              <style.UndoLink data-testid="chromeOS-configuratons-list-header-undo-link" onClick={() => uncheckGroups()}>
                {t('pages.chromeOS_configurations_list.undo_selection')}
              </style.UndoLink>
            </style.InfoBox>
            <style.Actions data-testid="chromeOS-configuratons-list-header-actions">
              <style.CustomIconButton
                data-testid="chromeOS-configuratons-list-header-remove-button"
                className={selectedItems.length === 0 ? 'disabled' : ''}
                onClick={() => {
                  if (selectedItems.length > 0) {
                    confirmDelete(selectedItems);
                  }
                }}
              >
                <Icon path={mdiDelete} size={1} color="black" />
              </style.CustomIconButton>
            </style.Actions>
          </style.Line>
        </style.Header>
        <EuiInMemoryTable
          data-testid="chromeOS-configuratons-list-table"
          itemId="orgUnitId"
          key={`chromeOS_groups_${version}`}
          items={data.filter((o) => o.name.includes(searchValue))}
          columns={cols}
          isSelectable
          selection={selectionValue}
        />
      </style.Container>
      <ComponentToast
        data-testid="chromeOS-configuratons-list-toast"
        toasts={toast}
        toastLifeTimeMs={5000}
        side="right"
        dismissToast={() => setToast([])}
      />
      <DialogComponent width="100%" height="465px" closeOnClickOutside open={open} onClose={() => setOpen(false)}>
        <style.DialogContainer data-testid="chromeOS-configuratons-list-dialog">
          <style.DialogTitle data-testid="chromeOS-configuratons-list-dialog-title">
            {t('pages.chromeOS_configurations_list.modal_title')}
          </style.DialogTitle>
          <style.DialogBody data-testid="chromeOS-configuratons-list-dialog-body">
            <ComponentTextField
              test_id="chromeOS-configuratons-list-dialog-configuration-name"
              value={newConfigurationName}
              label={t('pages.chromeOS_configurations_list.configuration_name_input_label')}
              placeholder={t('pages.chromeOS_configurations_list.configuration_name_input_placeholder')}
              onChange={(value) => setNewConfigurationName(value)}
              fullWidth
            />
            <style.Flags data-testid="chromeOS-configuratons-list-dialog-flags">
              <ComponentComboBox
                test_id="chromeOS-configuratons-list-dialog-combo-box"
                className={comboBoxIsFocused ? 'focused' : ''}
                id="newConfigurationComboBox"
                options={groups}
                fullWidth
                selectedOptions={selectedOptions}
                onChange={(e) => setSelectedOptions(e)}
                label={t('pages.chromeOS_configurations_list.groups_link_field_label')}
                onFocus={() => setComboBoxIsFocused(true)}
                onBlur={() => setComboBoxIsFocused(false)}
              />
              <style.TagsBox data-testid="chromeOS-configuratons-list-dialog-tags-box">
                <style.CustomTagButton>
                  <Icon path={mdiTag} color="#30a8ff" size={0.75} rotate={90} />
                </style.CustomTagButton>
                <style.TagsListLink>{t('pages.chromeOS_configurations_list.select_by_tags_label')}</style.TagsListLink>
              </style.TagsBox>
            </style.Flags>
            <style.DialogFooter data-testid="chromeOS-configuratons-list-dialog-footer">
              <style.CancelButton data-testid="chromeOS-configuratons-list-dialog-footer-cancel" onClick={() => closeModal()}>
                {t('pages.chromeOS_configurations_list.modal_cancel_button')}
              </style.CancelButton>
              <style.AddConfigButton data-testid="chromeOS-configuratons-list-dialog-footer-confirm" fill onClick={() => addNewConfiguration()}>
                {t('pages.chromeOS_configurations_list.modal_set_configuration_button')}
              </style.AddConfigButton>
            </style.DialogFooter>
          </style.DialogBody>
        </style.DialogContainer>
      </DialogComponent>
      <SaveBar
        animation={!_.isEqual(data, initialData)}
        message={<b>{t('pages.chromeOS_configurations_list.seve_bar_text')}</b>}
        onCancel={() => discartChanges()}
        onConfirm={() => saveChanges()}
      />
      <ComponentDeleteModal
        open={deleteModalOpen}
        password={password}
        onChange={(e) => setPassword(e)}
        onClose={() => closeDeleteModal()}
        onConfirm={() => removeElements()}
      />
    </>
  );
}
