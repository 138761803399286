import { EuiButtonGroup, EuiCallOut, EuiRadioGroup, EuiSwitch } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Content = styled.main`
  padding: 40px 60px 60px 50px;
  margin-left: 50px;
  background-color: ${ConstantColors.AUSTRIA};
`;

export const TopContainer = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const TopLeft = styled.div`
  width: 45%;

  p {
    color: ${ConstantColors.ITALY};
  }

  div {
    margin: 15px 0;
  }

  h1 {
  }
`;

export const SafetyNetSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background-color: ${({ theme }) => theme.main};
  }

  span {
    font-weight: 500;
  }
`;

export const TopRight = styled.div`
  p {
    font-weight: 700;
    margin-bottom: 15px;
  }
`;

export const GroupButton = styled(EuiButtonGroup)`
  .euiButtonGroup__buttons {
    border-radius: 0 !important;
  }

  .euiButton__content {
    padding: 0 !important;
  }

  .euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    color: #fff;
    background-color: ${({ theme }) => theme.main};
    border-color: ${({ theme }) => theme.main};
    font-weight: 700;
  }

  .euiButtonGroupButton {
    font-size: 14px;
    line-height: 21px;
    letter-spacing: -0.07px;
    height: 38px;
    padding: 0 20px;
    border-radius: 0 !important;
  }
`;

export const Warning = styled(EuiCallOut)`
  max-width: 600px;
  margin-top: 15px;
  border: none;

  p {
    color: ${ConstantColors.PAKISTAN};
  }
`;

export const BottomContainer = styled.section`
  margin-top: 45px;
  position: relative;

  span {
    font-size: 13px;
    font-weight: 600;
  }

  .title {
    display: grid;
    grid-template-columns: 25% 20% 60%;
    margin-bottom: 20px;
  }

  .vertical-line {
    border-bottom: 1px solid #ededed;
    width: 130px;
    position: absolute;
    transform: rotate(90deg);
    left: 34%;
    bottom: 65px;
    margin-left: 15px;
  }
`;

export const Options = styled.section`
  display: grid;
  grid-template-columns: 25% 20% 55%;
  padding-bottom: 15px;

  p {
    margin-left: 5px;
  }

  .icon {
    width: 23px;
    height: 23px;
    display: flex;
    justify-content: center;
    align-items: center;

    background: ${ConstantColors.DJBOUTI};
    border-radius: 4px;

    svg {
      width: 15px;
    }
  }

  &:last-child {
    margin-top: 15px;
  }

  &:not(:last-child) {
    border-bottom: 1px solid #ededed;
  }

  .icon-container {
    display: flex;
    align-items: center;
  }
`;

export const OptionsWPSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background-color: ${ConstantColors.FRANCE};
  }

  span {
    font-weight: 500;
  }
`;

export const OptionsWPRadios = styled(EuiRadioGroup)`
  display: grid;
  grid-template-columns: 30% 40% 30%;

  .euiRadioGroup__item {
    margin: 0;
  }
`;

export const OptionsFMSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background-color: ${ConstantColors.DJBOUTI};
  }

  span {
    font-weight: 500;
  }
`;

export const OptionsFMRadios = styled(EuiRadioGroup)`
  display: grid;
  grid-template-columns: 30% 40% 30%;

  .euiRadioGroup__item {
    margin: 0;
  }

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: ${ConstantColors.DJBOUTI};
    background-color: ${ConstantColors.DJBOUTI};
  }

  .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
    border-color: #d3dae6;
    background-color: #d3dae6;
  }

  &:focus {
    outline-color: ${ConstantColors.DJBOUTI};
  }
`;
