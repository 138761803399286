import { EuiButton, EuiRadio, EuiTextColor } from '@elastic/eui';
import styled from 'styled-components';

export const UrlInputBox = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 20px;
  .input {
    width: 328px;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #343741;
  }
`;

export const AddButton = styled(EuiButton)`
  &.euiButton {
    height: 32px !important;
    width: 120px;
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    padding: 0px;
    border-radius: 4px;

    &:hover {
      background-color: white;
      text-decoration: none;
      box-shadow: none;
    }
    &:focus {
      background-color: transparent;
    }
  }
`;

export const ListModeBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Radio = styled(EuiRadio)`
  .euiRadio__label {
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 24px !important;
    color: #343741 !important;
    padding-left: 40px !important;
  }

  &.euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: ${({ theme }) => theme.main};
    background-color: ${({ theme }) => theme.main};
    box-shadow: none !important;
  }

  &.euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
    border-color: #98a2b3;
    background-color: #98a2b3;
  }
`;

export const Option = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const CustomText = styled(EuiTextColor)`
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #67758d;
`;

export const TableTitle = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #343741;
`;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;

  .allowFlagCol {
    color: white;
    background: #3ddb85;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 59px;
    height: 20px;
    padding: 1px 8px;
    border-radius: 3px;
  }
  .blockFlagCol {
    color: white;
    background: #ff4c0c;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 59px;
    height: 20px;
    padding: 1px 8px;
    border-radius: 3px;
  }
`;
