import { useState, useMemo, useEffect } from 'react';
import BatteryFullIcon from '@mui/icons-material/BatteryFull';
import * as S from './DevicesByBatteryComponent.styles';
import { IndicatorPercentageBarComponent } from '../../IndicatorPercentageBar/IndicatorPercentageBarComponent';
import { DashboardCardComponent } from '../../DashboardCard/DashboardCardComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import type { StatisticsWithGraphics } from 'modules/operational/pages/dashboard/DashboardPage.contracts';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';

export const DevicesByBatteryComponent = () => {
  const [devices, setDevices] = useState<StatisticsWithGraphics[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const servicePulsusHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);

  const getDevicesByBattery = async () => {
    try {
      const devicesByBattery = await servicePulsusHagalaz.getDashboardDevicesPercentageGraphics('battery');
      setDevices(devicesByBattery.slice().reverse());
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDevicesByBattery();
  }, []);

  return (
    <DashboardCardComponent isLoading={isLoading} icon={<BatteryFullIcon />} title={t('dashboards.byBattery.title')}>
      <S.Table>
        <h3>{t('dashboards.byBattery.column1')}</h3>
        <div />
        <h3>{t('dashboards.byBattery.column2')}</h3>
      </S.Table>
      {!isLoading &&
        devices.map((item, idx) => {
          const roundedPercentageValue = Math.round(item.ratio);
          if (idx >= devices.length - 2) {
            return <IndicatorPercentageBarComponent key={item.label} inLimit count={item.count} label={item.label} value={roundedPercentageValue} />;
          }
          return <IndicatorPercentageBarComponent key={item.label} count={item.count} label={item.label} value={roundedPercentageValue} />;
        })}
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </DashboardCardComponent>
  );
};
