import { useTranslation } from 'react-i18next';
import type { SaveBarProps } from './component-save-bar.contracts';
import * as style from './component-save-bar.style';

export function SaveBar(props: SaveBarProps) {
  const { onCancel, onConfirm, message, animation } = props;
  const { t } = useTranslation();
  return (
    <style.Container>
      <div className={animation ? 'fade_in animation' : 'fade_in not_animated'}>
        <div className="fade_in_items">
          <div>
            {message ? (
              <p>{message}</p>
            ) : (
              <>
                <p>{t('components.component_save_bar.save_bar_message')}</p>
                <p>
                  <b>{t('components.component_save_bar.save_bar_message_question')}</b>
                </p>
              </>
            )}
          </div>
          <button type="button" onClick={() => onCancel()} className="link-style">
            {t('components.component_save_bar.save_bar_undo_button')}
          </button>
          <p>{t('components.component_save_bar.save_bar_or')}</p>
          <style.SaveButton onClick={() => onConfirm()}>{t('components.component_save_bar.save_bar_button')}</style.SaveButton>
        </div>
      </div>
    </style.Container>
  );
}
