/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { EuiBasicTableColumn, Pagination } from '@elastic/eui';
import { EuiBasicTable, EuiBetaBadge } from '@elastic/eui';
import React, { useMemo, useState } from 'react';
import CopyAllIcon from '@mui/icons-material/CopyAll';
import { useTranslation } from 'react-i18next';
import type { Sim } from 'modules/management/entities';
import { ModalPasswordConfirm, ModalDeleteSIM } from 'modules/management/components';
import Icon from '@mdi/react';
import { mdiDelete } from '@mdi/js';
import * as style from './ComponentTableSim.style';
import type { TableSimData, TableSimStatus } from './ComponentTableSim.contracts';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';

export function TableSim({ data, loading, handleDeleteSIM, currentPage, handleChangePage, totalItems, setMessage, setToast }: TableSimData) {
  const { t } = useTranslation('translation', { keyPrefix: 'sim_management' });

  const [selectedChipId, setSelectedChipId] = useState('');
  const [pinModal, setPinModal] = useState<boolean>(false);
  const [pukModal, setPukModal] = useState<boolean>(false);
  const [pinObj, setPinObj] = useState({});
  const [pukObj, setPukObj] = useState({});
  const [deletable, setDeletable] = useState<Sim | null>(null);

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const handlePasswordForPin = () => {
    getPin();
    setPinModal(false);
  };

  const handlePasswordForPuk = () => {
    getPuk();
    setPukModal(false);
  };

  const renderBadge = (field: TableSimStatus): React.ReactNode => {
    const text = t(`page_description.table_text.status.${field.toLowerCase()}`);

    return <style.StyledBadge status={field}>{text}</style.StyledBadge>;
  };

  const renderBadgeId = (field: string): React.ReactNode => {
    return <style.StyledBetaBadge label={field} />;
  };

  const renderTextCell = (field: string): React.ReactNode => {
    return <style.TextCell title={field}>{field || '-'}</style.TextCell>;
  };

  const renderPin = (_: string, item: Sim): React.ReactNode => {
    return (
      <style.BadgeAdornment>
        <EuiBetaBadge label={pinObj[item.chip_id]?.pin || '-'} title="" />
        <EuiBetaBadge
          label=""
          iconType={!pinObj[item.chip_id]?.pin ? 'eyeClosed' : 'eye'}
          onClick={(e) => (
            setSelectedChipId(item.chip_id), pinObj[item.chip_id]?.pin ? (delete pinObj[item.chip_id], setPinObj({ ...pinObj })) : setPinModal(true)
          )}
        />
      </style.BadgeAdornment>
    );
  };

  const renderPuk = (_: string, item: Sim): React.ReactNode => {
    return (
      <style.BadgeAdornment>
        <EuiBetaBadge label={pukObj[item.chip_id]?.puk || '-'} title="" />
        <EuiBetaBadge
          label=""
          iconType={!pukObj[item.chip_id]?.puk ? 'eyeClosed' : 'eye'}
          onClick={(e) => (
            setSelectedChipId(item.chip_id), pukObj[item.chip_id]?.puk ? (delete pukObj[item.chip_id], setPukObj({ ...pukObj })) : setPukModal(true)
          )}
        />
      </style.BadgeAdornment>
    );
  };

  const renderOptions = (field: any): React.ReactNode => {
    const [_, tableSIMInformations]: [any, Sim] = field;
    const isDeletable = tableSIMInformations?.status === 'DELETED';

    return (
      <style.Actions>
        <style.WrapperButtonIcon title={t('tooltip_copy')} onClick={() => copyLine(field)}>
          <CopyAllIcon style={{ color: '#828282', height: '15px' }} />
        </style.WrapperButtonIcon>
        <style.WrapperButtonIcon
          title={t('tooltip_delete')}
          disabled={isDeletable}
          onClick={() => !isDeletable && setDeletable(tableSIMInformations)}
        >
          <Icon color={isDeletable ? '#ffffff' : '#f44336'} size="15px" path={mdiDelete} />
        </style.WrapperButtonIcon>
      </style.Actions>
    );
  };

  const deleteSIMCard = async (): Promise<void> => {
    handleDeleteSIM();
    setDeletable(null);
  };

  const getPin = async () => {
    try {
      const result = await serviceLoki.getPin(selectedChipId);
      setPinObj({
        ...pinObj,
        [selectedChipId]: {
          ...pinObj[selectedChipId],
          pin: result,
        },
      });

      if (result === null) {
        setToast([
          {
            title: t('page_description.pin_alert'),
            color: 'warning',
            iconType: 'faceNeutral',
            id: '1',
            text: '',
          },
        ]);
      }
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    }
  };

  const getPuk = async () => {
    try {
      const result = await serviceLoki.getPuk(selectedChipId);
      setPukObj({
        ...pukObj,
        [selectedChipId]: {
          ...pukObj[selectedChipId],
          puk: result,
        },
      });

      if (result === null) {
        setToast([
          {
            title: t('page_description.puk_alert'),
            color: 'warning',
            iconType: 'faceNeutral',
            id: '1',
            text: '',
          },
        ]);
      }
    } catch (err) {
      setToast([
        {
          title: t('page_description.title_error'),
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          text: t('page_description.text_error'),
        },
      ]);
      setMessage(t('page_description.fetch_error'));
    }
  };

  const copyLine = async (line: [any, Partial<Sim>]): Promise<void> => {
    const [_, simInfos] = line;

    const keys = Object.keys(simInfos);
    const stringConcat = keys.map((key) => simInfos?.[key as keyof Sim]).join('; ');

    await navigator?.clipboard?.writeText(stringConcat);
  };

  const COLUMNS: EuiBasicTableColumn<Sim>[] = [
    {
      field: 'iccId',
      name: 'ICCID',
      render: (field: string) => renderTextCell(field),
      align: 'center',
    },
    {
      field: 'pulsusId',
      name: t('page_description.table_text.pulsus_id'),
      render: (field: string) => renderBadgeId(field),
      align: 'center',
    },
    {
      field: 'userName',
      name: t('page_description.table_text.name'),
      render: (field: string) => renderTextCell(field),
      align: 'center',
    },
    {
      field: 'phoneProvider',
      name: t('page_description.table_text.phone_provider'),
      render: (field: string) => renderTextCell(field),
      style: { textTransform: 'uppercase' },
      align: 'center',
    },
    {
      field: 'status',
      name: 'Status',
      render: (field: TableSimStatus) => renderBadge(field),
      align: 'center',
      width: '150px',
    },
    {
      field: 'pin',
      name: 'PIN',
      render: (_: any, item: Sim) => renderPin(_, item),
      align: 'center',
    },
    {
      field: 'puk',
      name: 'PUK',
      render: (_: any, item: Sim) => renderPuk(_, item),
      align: 'center',
      width: '125px',
    },
    {
      field: 'registerAt',
      name: t('page_description.table_text.subscribe_date'),
      render: (field: string) => renderTextCell(field),
      align: 'center',
    },
    {
      field: 'actions',
      name: t('page_description.table_text.actions'),
      render: (...args: any) => renderOptions(args),
      align: 'center',
    },
  ];

  const PAGINATION: Pagination = {
    pageSize: 25,
    hidePerPageOptions: true,
    totalItemCount: totalItems,
    pageIndex: currentPage,
  };

  return (
    <>
      <style.TableContainer>
        <EuiBasicTable
          onChange={(criteria) => handleChangePage(criteria?.page?.index)}
          className="table"
          pagination={PAGINATION}
          loading={loading}
          items={data}
          columns={COLUMNS}
        />
      </style.TableContainer>
      {pinModal && <ModalPasswordConfirm setOpen={setPinModal} handleShowPin={handlePasswordForPin} kind="pin" />}
      {pukModal && <ModalPasswordConfirm setOpen={setPukModal} handleShowPin={handlePasswordForPuk} kind="puk" />}
      {!!deletable && <ModalDeleteSIM sim={deletable} setModal={setDeletable} handleDeletePin={() => deleteSIMCard()} />}
    </>
  );
}
