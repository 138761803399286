import { ConstantThemes, DashboardDark } from 'utilities';
import { HelperStorage } from './helper-storage';
import { Theme } from 'entities/theme';

export class HelperTheme {
  static key() {
    return '@theme_key';
  }

  static dashboardKey() {
    return '@dashboard_theme';
  }

  static getTheme() {
    const themeName = HelperStorage.fetch(this.key());
    const dashboardTheme = HelperStorage.fetch(this.dashboardKey());
    let theme;

    switch (themeName) {
      case ConstantThemes.PULSUS.name:
        theme = ConstantThemes.PULSUS.theme();
        break;
      case ConstantThemes.OCEAN.name:
        theme = ConstantThemes.OCEAN.theme();
        break;
      case ConstantThemes.DEEP_OCEAN.name:
        theme = ConstantThemes.DEEP_OCEAN.theme();
        break;
      case ConstantThemes.SPACE.name:
        theme = ConstantThemes.SPACE.theme();
        break;
      case ConstantThemes.ROYAL_PURPLE.name:
        theme = ConstantThemes.ROYAL_PURPLE.theme();
        break;
      case ConstantThemes.MATERIAL_BLUE.name:
        theme = ConstantThemes.MATERIAL_BLUE.theme();
        break;
      case ConstantThemes.MATERIAL_BROWN.name:
        theme = ConstantThemes.MATERIAL_BROWN.theme();
        break;
      case ConstantThemes.MATERIAL_GRAY.name:
        theme = ConstantThemes.MATERIAL_GRAY.theme();
        break;
      case ConstantThemes.MATERIAL_GREEN.name:
        theme = ConstantThemes.MATERIAL_GREEN.theme();
        break;
      case ConstantThemes.MATERIAL_RED.name:
        theme = ConstantThemes.MATERIAL_RED.theme();
        break;
      default:
        theme = ConstantThemes.PULSUS.theme();
    }

    if (dashboardTheme === 'dark') {
      theme = new Theme(theme.name, theme.main, theme.contrast, DashboardDark);
    }

    return theme;
  }

  static setTheme(theme_name = ConstantThemes.PULSUS.name) {
    HelperStorage.save(this.key(), theme_name);
  }

  static setDashboardTheme(theme_name = 'white') {
    HelperStorage.save(this.dashboardKey, theme_name);
  }
}
