import { useTranslation } from 'react-i18next';
import { IconPulsusLogo, IconSupport, IconArticles } from 'icons';
import { useTheme } from 'styled-components';
import * as style from './modal-contact.style';

export function ModalContact() {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <style.ModalContainer data-testid="modal-contact">
      <style.ModalHeader>
        <figure>
          <IconPulsusLogo color={theme.main} test_id="modal_contact_logo_icon" />
        </figure>
        <style.ModalTitle data-testid="component__modal_contact_title">{t('components.component_modal_contact.modal_title')}</style.ModalTitle>
      </style.ModalHeader>

      <style.ModalMain>
        <section>
          <style.ModalBoxLink
            href="https://pulsus.movidesk.com/form/3955/"
            target="_blank"
            rel="noreferrer"
            data-testid="component__modal_contact_link_support"
          >
            <figure>
              <IconSupport color={theme.main} test_id="modal_contact_support_icon" />
            </figure>
            <h4 data-testid="component__modal_contact_support_label">{t('components.component_modal_contact.support_label')}</h4>
          </style.ModalBoxLink>
          <style.ModalText data-testid="component__modal_contact_support_text">
            {t('components.component_modal_contact.support_text')}
          </style.ModalText>
        </section>
        <section>
          <style.ModalBoxLink
            href="https://pulsus.movidesk.com/kb"
            target="_blank"
            rel="noreferrer"
            data-testid="component__modal_contact_link_articles"
          >
            <figure>
              <IconArticles color={theme.main} test_id="modal_contact_articles_icon" />
            </figure>
            <h4 data-testid="component__modal_contact_articles_label">{t('components.component_modal_contact.articles_label')}</h4>
          </style.ModalBoxLink>
          <style.ModalText data-testid="component__modal_contact_articles_text">
            {t('components.component_modal_contact.articles_text')}
          </style.ModalText>
        </section>
      </style.ModalMain>
    </style.ModalContainer>
  );
}
