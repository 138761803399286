import type { ReactElement } from 'react';
import * as S from './TextEllipsisComponent.styles';
import type { TextEllipsisComponentProps } from './TextEllipsisComponent.contracts';

export const TextEllipsisComponent = ({ className, title, children, rows, ...props }: TextEllipsisComponentProps): ReactElement => {
  return (
    <S.Ellipsis className={className} title={title} rows={rows || 1} {...props}>
      {children}
    </S.Ellipsis>
  );
};
