import { IconChipInCicle } from 'icons/chip/icon-chip-in-cicle';
import * as style from './card-protection.style';
import { Flags } from '../flags';
import { GetCardFlagsAndroid } from '../flags-label-android';
import type { ProtectionDataCardProps } from './card-data-protection.contracts';

export function MountPhysicalMediaDisabled(props: ProtectionDataCardProps) {
  const { value, onChange } = props;

  const options = GetCardFlagsAndroid();

  function handleChange(param: boolean) {
    onChange(param);
  }

  return (
    <style.SettingsStatusSection is_on={value} is_off={!value} className="status_section_container">
      <div className="icon_status_container">
        <section className="left_section_status">
          <button type="button">
            <IconChipInCicle elipse_fill={value ? '#30A8FF' : '#C92F2F'} />
            {value ? 'Permitido' : 'Bloqueado'}
          </button>
        </section>
        <section className="right_section_status">
          <h4>Mídia fisica</h4>
          <p>Disponível para todos os dispositivos</p>
        </section>
      </div>
      <div className="options_container mobile_data">
        <button className="turn_on_button" type="button" onClick={() => handleChange(true)}>
          Permitido
        </button>
        <button type="button" className="turn_off_button" onClick={() => handleChange(false)}>
          Bloqueado
        </button>
      </div>
      <Flags flags={options.dataProtectionFlags} />
    </style.SettingsStatusSection>
  );
}
