import { EuiButtonEmpty, EuiModal } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const ModalConfig = styled(EuiModal)`
  display: flex;
  position: relative;

  min-width: 890px;
  height: 510px;
  border-radius: 24px;

  .euiModal__flex {
    padding: 50px;
    width: 500px;
  }

  .euiButtonIcon.euiButtonIcon--text.euiButtonIcon--empty.euiButtonIcon--xSmall.euiModal__closeIcon {
    margin: 25px;
  }

  section {
    max-width: 370px;

    img {
      width: 219px;
    }
  }

  h2 {
    color: ${ConstantColors.ARGENTINA};
    line-height: 30px;
  }

  p {
    margin: 30px 0;
    max-width: 250px;
  }

  .android-img {
    position: absolute;
    width: 520px;
    right: 30px;
  }
`;

export const Modal = styled(EuiModal)`
  min-width: 890px;
  min-height: 510px;
  border-radius: 24px;

  .euiModal__flex {
    min-height: 510px;
  }
  .euiButtonIcon.euiButtonIcon--text.euiButtonIcon--empty.euiButtonIcon--xSmall.euiModal__closeIcon {
    padding: 30px;
  }
`;

export const StepButton = styled(EuiButtonEmpty)`
  position: absolute;
  right: 20px;
  bottom: 10px;
`;

export const AnimationContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  width: 100%;
  min-width: 690px;
  max-width: 690px;
  margin-left: auto;
  margin-right: auto;
`;

export const Title = styled.h2`
  color: ${({ theme }) => theme.main};
  margin-bottom: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  width: 100%;
  min-width: 400px;
  max-width: 400px;
  margin-top: -30px;
`;

export const Text = styled.p`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: ${ConstantColors.EGYPT};
  margin-bottom: 18px;

  span {
    display: block;
  }

  b {
    font-weight: 700;
    color: #676767;
  }
`;

export const FooterModal = styled.footer`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: ${ConstantColors.CHILE};
`;
