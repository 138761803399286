import { EuiDatePicker, EuiDatePickerRange } from '@elastic/eui';
import { ConstantLanguages } from 'utilities';
import type { ComponentDateRangeProps } from './ComponentDateRange.contracts';
import * as style from './ComponentDateRange.style';

export function ComponentDateRange({
  initialDate,
  endDate,
  label,
  className,
  onChangeInitialDate,
  onChangeEndDate,
  language,
}: ComponentDateRangeProps) {
  const initial: JSX.Element = (
    <EuiDatePicker
      dateFormat="DD/MM/YYYY"
      allowSameDay
      selected={initialDate}
      isInvalid={initialDate > endDate}
      onChange={() => null}
      locale={language || ConstantLanguages.PT_BR}
      aria-label="Initial date"
      onSelect={(e) => onChangeInitialDate(e)}
    />
  );

  const end: JSX.Element = (
    <EuiDatePicker
      dateFormat="DD/MM/YYYY"
      allowSameDay
      selected={endDate}
      isInvalid={initialDate > endDate}
      onChange={() => null}
      locale={language || ConstantLanguages.PT_BR}
      aria-label="End date"
      onSelect={(e) => onChangeEndDate(e)}
    />
  );

  return (
    <style.Container>
      {label && <style.Label>{label}</style.Label>}
      <EuiDatePickerRange className={className} iconType={false} startDateControl={initial} endDateControl={end} />
    </style.Container>
  );
}
