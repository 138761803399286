import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  @keyframes hideElement {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  @keyframes showElement {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  :hover {
    .options {
      animation-duration: 0.2s;
      animation-name: showElement;
      animation-timing-function: linear;
      opacity: 1;
    }
  }
  .options {
    transition: 0.2s;
  }
`;

export const Body = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  gap: 8px;
`;

export const IconBox = styled.div`
  .active {
    transition: 0.7s;
    background: #30a8ff !important;
  }
  .neutral {
    background: white !important;
    path {
      transition: 0.7s;
      fill: #e7e7e7 !important;
    }
  }
  .blocked {
    transition: 0.7s;
    background: #e7e7e7 !important;
  }
`;

export const CustomIconButtom = styled(IconButton)`
  padding: 13px !important;
  cursor: default !important;
`;

export const Status = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #67758d;
`;

export const ButtonGroup = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  opacity: 0;
`;

export const ButtonGroupOption = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
  color: #67758d;

  background: #e4e4e4;
  border-radius: 14px;
  padding: 5px 10px;
  cursor: pointer;

  &.active {
    color: white;
    background: #30a8ff;
  }
`;

export const Right = styled.div``;

export const Title = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: #1a1c21;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 12px;
  line-height: 13px;
  color: #67758d;
`;
