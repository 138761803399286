import { IconButton } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 30px;
  height: 74px;
`;

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 70px;
  gap: 8px;
`;

export const IconBox = styled.div`
  .active {
    background: #30a8ff !important;
  }
  .blocked {
    background: #e7e7e7 !important;
  }
`;

export const CustomIconButtom = styled(IconButton)`
  padding: 13px !important;
  @keyframes slideup {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-3px);
    }
  }
  @keyframes slidedown {
    0% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  :hover {
    transform: translateY(-3px);
    animation-duration: 0.15s;
    animation-name: slideup;
    animation-timing-function: linear;
    .text {
      text-decoration: underline;
    }
  }
  transform: translateY(0px);
  animation-duration: 0.15s;
  animation-name: slidedown;
  animation-timing-function: linear;
`;

export const Status = styled.p`
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
`;

export const Right = styled.div``;

export const Title = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #1a1c21;
`;

export const Description = styled.p`
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  color: #67758d;
`;
