import styled from 'styled-components';

export const LeftContainer = styled.div`
  padding-top: 10px;
`;

export const Title = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 40px;

  h1 {
    font-weight: 500;
    font-size: 40px;
    color: ${({ theme }) => theme.main};
    line-height: 21px;
  }
`;

export const SecondaryText = styled.p`
  font-weight: 400;
  font-size: 15px;
  width: 680px;
  color: #67758d;
  margin-bottom: 20px;
`;

export const RightContainer = styled.div`
  .buttonGeofence.true {
    @keyframes shake {
      10%,
      90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
        filter: brightness(0.9);
        transition: 0.5s;
      }
      20%,
      80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
      }
      30%,
      50%,
      70% {
        -webkit-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
      }
    }
    -webkit-animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 16px;
  padding-top: 32px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 52px;
  padding: 0 16px;
`;

export const AlertText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #8a6a0a;
  display: flex;
  gap: 10px;
  align-items: center;
`;
