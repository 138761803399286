/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-explicit-any */
import type { ReactElement } from 'react';
import React, { useState } from 'react';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';
import { useTranslation } from 'react-i18next';
import type { ComponentUploadModalProps } from './ComponentUploadModal.contracts';
import * as S from './ComponentUploadModal.style';

export function ComponentUploadModal({ onClose, emitChange }: ComponentUploadModalProps): ReactElement {
  const [isCsvFormat, setIsCsvFormat] = useState<boolean>(true);
  const [messageError, setMessageError] = useState<string>('');
  const [file, setFile] = useState([]);

  const { t } = useTranslation('translation', { keyPrefix: 'geofence.uploadModal' });

  const handleOnClose = () => {
    setFile([]);
    onClose();
    setMessageError('');
  };

  const onChange = (value: any) => {
    setFile(value.length > 0 ? value : {});

    setIsCsvFormat(value.length > 0 && value[0].name.substr(-4).includes('.csv'));

    if (value.length === 0) setMessageError('');
  };

  const handleUploadFile = () => {
    ServicePulsusValquiria.uploadGeofenceAreas(file[0])
      .then((data) => {
        if (data.data.failed.length === 0) {
          emitChange();
          handleOnClose();
        } else {
          setMessageError(t('geofences_modal_error_text'));
        }
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.warn(error);
        setMessageError(t('geofences_modal_error_text'));
      });
    setFile([]);
    setMessageError('');
  };

  const handleDownloadStandardCSV = () => {
    ServicePulsusValquiria.downloadStandardCSV().then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'geofence-standard-csv.csv');
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    });
  };

  return (
    <S.Modal onClose={handleOnClose} maxWidth="850px">
      <S.Content>
        <S.Title>{t('geofences_modal_title')}</S.Title>
        <S.Text>{t('geofences_modal_text')}</S.Text>
        <S.BodyModal>
          <S.FilePicker
            initialPromptText={t('geofences_modal_label')}
            onChange={onChange}
            aria-label={t('geofences_modal_label')}
            fullWidth
            display="large"
            accept=".csv"
            required
          />
        </S.BodyModal>
      </S.Content>
      <S.FooterModal>
        <S.ButtonLink iconType="download" onClick={handleDownloadStandardCSV}>
          {t('geofences_modal_model')}
        </S.ButtonLink>

        {messageError.length > 0 && (
          <S.Error title="Erro" color="warning" iconType="alert">
            <p>{messageError}</p>
          </S.Error>
        )}
        <div>
          <S.ButtonLink onClick={handleOnClose}>{t('geofences_modal_cancel')}</S.ButtonLink>
          <S.Button
            fill
            onClick={handleUploadFile}
            disabled={file.length === 0 || file.length === undefined || messageError.length > 0 || !isCsvFormat}
          >
            {t('geofences_modal_confirm')}
          </S.Button>
        </div>
      </S.FooterModal>
    </S.Modal>
  );
}
