export function IconInsightsSignalsMap() {
  return (
    <svg width="153" height="135" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M35.63 61.017H51.22V11.135H35.63v49.882Z" fill="#F5F5F5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M43.647 61.017h8.017V11.135h-8.017v49.882Z" fill="#EBEBEB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M37.412 11.135h5.79v-4.9h-5.79v4.9Z" fill="#F5F5F5" />
      <path fillRule="evenodd" clipRule="evenodd" d="M41.865 11.135h1.337v-4.9h-1.337v4.9Z" fill="#EBEBEB" />
      <path fillRule="evenodd" clipRule="evenodd" d="M34.294 11.108h17.37v-.391h-17.37v.39Z" fill="#E0E0E0" />
      <path fillRule="evenodd" clipRule="evenodd" d="M38.748 16.034h1.336V13.36h-1.336v2.673Z" fill="#FAFAFA" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.552 17.228c0 .03-1.767.053-3.946.053-2.18 0-3.946-.024-3.946-.053 0-.03 1.766-.054 3.946-.054 2.179 0 3.946.024 3.946.054"
        fill="#E0E0E0"
      />
      <mask id="a" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="153" height="135">
        <path fillRule="evenodd" clipRule="evenodd" d="M0 134.504h152.765V0H0v134.504Z" fill="#fff" />
      </mask>
      <g mask="url(#a)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M46.736 16.123h1.336v-2.815h-1.336v2.815ZM51.512 17.228c0 .03-1.767.053-3.946.053-2.18 0-3.946-.024-3.946-.053 0-.03 1.767-.054 3.946-.054 2.179 0 3.946.024 3.946.054M46.736 21.712h1.336v-2.816h-1.336v2.816ZM51.512 22.817c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.767-.053 3.946-.053c2.179 0 3.946.024 3.946.053M46.736 27.47h1.336v-2.815h-1.336v2.815ZM51.512 28.575c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.767-.054 3.946-.054c2.179 0 3.946.025 3.946.054M46.736 33.228h1.336v-2.815h-1.336v2.815ZM51.512 34.333c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.767-.054 3.946-.054c2.179 0 3.946.024 3.946.054M46.736 38.822h1.336v-2.815h-1.336v2.815ZM51.512 39.927c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.767-.054 3.946-.054c2.179 0 3.946.024 3.946.054M46.736 44.438h1.336v-2.815h-1.336v2.816ZM46.736 50.128h1.336v-2.816h-1.336v2.816ZM51.512 45.543c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.767-.054 3.946-.054c2.179 0 3.946.024 3.946.054"
          fill="#E0E0E0"
        />
        <path d="M38.776 21.798h1.336v-2.816h-1.336v2.816Z" fill="#FAFAFA" />
        <path
          d="M43.552 22.902c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.766-.053 3.946-.053c2.179 0 3.946.024 3.946.053"
          fill="#E0E0E0"
        />
        <path d="M38.776 27.473h1.336v-2.816h-1.336v2.816Z" fill="#FAFAFA" />
        <path
          d="M43.552 28.577c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.179 0 3.946.024 3.946.054"
          fill="#E0E0E0"
        />
        <path d="M38.776 33.147h1.336v-2.815h-1.336v2.815Z" fill="#FAFAFA" />
        <path
          d="M43.552 34.252c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.179 0 3.946.024 3.946.054"
          fill="#E0E0E0"
        />
        <path d="M38.776 38.822h1.336v-2.815h-1.336v2.815Z" fill="#FAFAFA" />
        <path
          d="M43.552 39.927c0 .03-1.767.054-3.946.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.179 0 3.946.024 3.946.054"
          fill="#E0E0E0"
        />
        <path d="M38.776 44.497h1.336v-2.815h-1.336v2.815Z" fill="#FAFAFA" />
        <path
          d="M43.552 45.602c0 .03-1.767.054-3.946.054-2.18 0-3.946-.025-3.946-.054 0-.03 1.766-.054 3.946-.054 2.179 0 3.946.024 3.946.054"
          fill="#E0E0E0"
        />
        <path d="M38.776 50.172h1.336v-2.816h-1.336v2.816Z" fill="#FAFAFA" />
        <path d="M93.46 71.757h15.784v-49.99H93.461v49.99Z" fill="#F5F5F5" />
        <path d="M101.353 71.62h7.891V21.767h-7.891V71.62Z" fill="#EBEBEB" />
        <path d="M95.263 21.766h5.574v-4.908h-5.574v4.908Z" fill="#F5F5F5" />
        <path d="M99.508 21.766h1.329v-4.908h-1.329v4.908Z" fill="#EBEBEB" />
        <path d="M92.145 21.766v-.286h17.561v.286" fill="#E0E0E0" />
        <path d="M96.577 26.722h1.336v-2.815h-1.336v2.815Z" fill="#FAFAFA" />
        <path
          d="M101.352 27.827c0 .03-1.767.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.178 0 3.945.024 3.945.054M104.536 26.722h1.336v-2.815h-1.336v2.815ZM109.312 27.827c0 .03-1.766.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.179 0 3.945.024 3.945.054M104.536 32.312h1.336v-2.815h-1.336v2.815ZM109.312 33.417c0 .03-1.766.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.179 0 3.945.024 3.945.054M104.536 38.07h1.336v-2.815h-1.336v2.815ZM109.312 39.175c0 .03-1.766.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.179 0 3.945.024 3.945.054M104.536 43.828h1.336v-2.815h-1.336v2.815ZM109.312 44.934c0 .03-1.766.053-3.945.053-2.18 0-3.946-.024-3.946-.053 0-.03 1.766-.054 3.946-.054 2.179 0 3.945.024 3.945.054M104.536 49.422h1.336v-2.816h-1.336v2.816ZM109.312 50.526c0 .03-1.766.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.053 3.946-.053c2.179 0 3.945.024 3.945.053M104.536 55.038h1.336v-2.815h-1.336v2.815ZM104.536 60.727h1.336v-2.815h-1.336v2.815ZM109.312 56.143c0 .03-1.766.053-3.945.053-2.18 0-3.946-.024-3.946-.053 0-.03 1.766-.054 3.946-.054 2.179 0 3.945.024 3.945.054"
          fill="#E0E0E0"
        />
        <path d="M96.577 32.397h1.336V29.58h-1.336v2.816Z" fill="#FAFAFA" />
        <path
          d="M101.352 33.502c0 .03-1.767.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.178 0 3.945.024 3.945.054"
          fill="#E0E0E0"
        />
        <path d="M96.577 38.072h1.336v-2.815h-1.336v2.815Z" fill="#FAFAFA" />
        <path
          d="M101.352 39.177c0 .03-1.767.054-3.945.054-2.18 0-3.946-.025-3.946-.054 0-.03 1.766-.054 3.946-.054 2.178 0 3.945.024 3.945.054"
          fill="#E0E0E0"
        />
        <path d="M96.577 43.747h1.336v-2.815h-1.336v2.815Z" fill="#FAFAFA" />
        <path
          d="M101.352 44.852c0 .03-1.767.054-3.945.054-2.18 0-3.946-.025-3.946-.054 0-.03 1.766-.054 3.946-.054 2.178 0 3.945.024 3.945.054"
          fill="#E0E0E0"
        />
        <path d="M96.577 49.422h1.336v-2.816h-1.336v2.816Z" fill="#FAFAFA" />
        <path
          d="M101.352 50.526c0 .03-1.767.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.053 3.946-.053c2.178 0 3.945.024 3.945.053"
          fill="#E0E0E0"
        />
        <path d="M96.577 55.097h1.336V52.28h-1.336v2.816Z" fill="#FAFAFA" />
        <path
          d="M101.352 56.201c0 .03-1.767.054-3.945.054-2.18 0-3.946-.024-3.946-.054s1.766-.054 3.946-.054c2.178 0 3.945.025 3.945.054"
          fill="#E0E0E0"
        />
        <path d="M96.577 60.772h1.336v-2.816h-1.336v2.816Z" fill="#FAFAFA" />
        <path d="M103.394 74.85h25.555V41.161h-25.555V74.85Z" fill="#EBEBEB" />
        <path d="M116.966 74.85h11.983V41.161h-11.983V74.85Z" fill="#E0E0E0" />
        <path
          d="M108.752 51.302h2.298v-5.58h-2.298v5.58ZM108.752 73.138h2.298v-5.58h-2.298v5.58ZM116.966 53.492c0 .063-3.038.114-6.786.114-3.749 0-6.786-.051-6.786-.114 0-.062 3.037-.113 6.786-.113 3.748 0 6.786.05 6.786.113M108.752 62.303h2.298v-5.58h-2.298v5.58ZM116.966 64.493c0 .063-3.038.114-6.786.114-3.749 0-6.786-.051-6.786-.114 0-.062 3.037-.113 6.786-.113 3.748 0 6.786.05 6.786.113M119.511 51.302h2.298v-5.58h-2.298v5.58ZM124.496 51.302h2.297v-5.58h-2.297v5.58ZM119.511 61.889h2.298v-5.58h-2.298v5.58ZM124.496 61.889h2.297v-5.58h-2.297v5.58ZM119.511 72.476h2.298v-5.58h-2.298v5.58ZM124.496 72.476h2.297v-5.58h-2.297v5.58Z"
          fill="#F5F5F5"
        />
        <path d="M101.473 41.204h29.397v-1.215h-29.397v1.215Z" fill="#E0E0E0" />
        <path
          d="M129.127 41.318c0 .063-5.712.114-12.756.114-7.047 0-12.757-.05-12.757-.114 0-.062 5.71-.113 12.757-.113 7.044 0 12.756.05 12.756.113M45.588 75.522H22.363l-.101-36.684h23.224l.102 36.684Z"
          fill="#EBEBEB"
        />
        <path d="M33.874 75.522h11.613V38.838H33.874v36.684Z" fill="#E0E0E0" />
        <path d="M24.914 38.838h8.201v-7.222h-8.201v7.222Z" fill="#EBEBEB" />
        <path d="M31.16 38.838h1.955v-7.222h-1.954v7.222Z" fill="#E0E0E0" />
        <path d="M20.326 38.838h25.84v-.421h-25.84v.421Z" fill="#E0E0E0" />
        <path d="M26.846 46.13h1.966v-4.143h-1.966v4.143Z" fill="#fff" />
        <path d="M33.874 47.757c0 .044-2.6.079-5.806.079-3.207 0-5.806-.035-5.806-.079s2.6-.08 5.806-.08c3.207 0 5.806.036 5.806.08" fill="#E0E0E0" />
        <path d="M38.56 46.13h1.966v-4.143H38.56v4.143Z" fill="#F5F5F5" />
        <path
          d="M45.588 47.757c0 .044-2.6.079-5.806.079-3.207 0-5.806-.035-5.806-.079s2.599-.08 5.806-.08c3.206 0 5.806.036 5.806.08"
          fill="#EBEBEB"
        />
        <path d="M38.56 54.356h1.966v-4.143H38.56v4.143Z" fill="#F5F5F5" />
        <path
          d="M45.588 55.981c0 .044-2.6.08-5.806.08-3.207 0-5.806-.036-5.806-.08 0-.043 2.599-.079 5.806-.079 3.206 0 5.806.036 5.806.08"
          fill="#EBEBEB"
        />
        <path d="M38.56 62.83h1.966v-4.143H38.56v4.142Z" fill="#F5F5F5" />
        <path
          d="M45.588 64.455c0 .044-2.6.08-5.806.08-3.207 0-5.806-.036-5.806-.08 0-.044 2.599-.08 5.806-.08 3.206 0 5.806.036 5.806.08"
          fill="#EBEBEB"
        />
        <path d="M38.56 71.303h1.966V67.16H38.56v4.143Z" fill="#F5F5F5" />
        <path
          d="M45.588 72.929c0 .043-2.6.079-5.806.079-3.207 0-5.806-.036-5.806-.08 0-.043 2.599-.078 5.806-.078 3.206 0 5.806.035 5.806.079"
          fill="#EBEBEB"
        />
        <path d="M26.846 54.482h1.966v-4.143h-1.966v4.143Z" fill="#fff" />
        <path
          d="M33.874 56.107c0 .044-2.6.08-5.806.08-3.207 0-5.806-.036-5.806-.08 0-.043 2.6-.079 5.806-.079 3.207 0 5.806.036 5.806.08"
          fill="#E0E0E0"
        />
        <path d="M26.846 62.832h1.966V58.69h-1.966v4.142Z" fill="#fff" />
        <path
          d="M33.874 64.458c0 .044-2.6.08-5.806.08-3.207 0-5.806-.036-5.806-.08 0-.044 2.6-.08 5.806-.08 3.207 0 5.806.036 5.806.08"
          fill="#E0E0E0"
        />
        <path d="M26.846 71.183h1.966V67.04h-1.966v4.142Z" fill="#fff" />
        <path
          d="M33.874 72.808c0 .044-2.6.08-5.806.08-3.207 0-5.806-.035-5.806-.08 0-.043 2.6-.079 5.806-.079 3.207 0 5.806.036 5.806.08"
          fill="#E0E0E0"
        />
        <path
          d="M137.565 31.422c.123-.891-1.136-1.625-2.082-1.7-.451-.037-.92.032-1.347-.117-.926-.323-1.296-1.519-2.2-1.9-.675-.284-1.453-.03-2.108.299-.655.33-1.302.744-2.034.79-.637.041-1.328-.195-1.884.116-.416.232-.633.71-1.015.991-.374.276-.863.335-1.327.36-.464.025-.945.03-1.367.223-.423.194-.773.64-.685 1.097l16.049-.159Z"
          fill="#F5F5F5"
        />
        <path
          d="M98.275 6.011c.192-1.395-1.779-2.544-3.26-2.663-.706-.057-1.44.051-2.109-.182-1.45-.505-2.029-2.376-3.443-2.973-1.057-.445-2.275-.049-3.3.467s-2.038 1.165-3.184 1.238c-.996.064-2.078-.306-2.95.18-.649.363-.99 1.111-1.587 1.553-.586.431-1.35.523-2.077.562-.727.04-1.48.047-2.14.35-.662.302-1.21 1.002-1.072 1.716l25.122-.248Z"
          fill="#EBEBEB"
        />
        <path
          d="M31.15 25.312c.153-1.113-1.418-2.028-2.598-2.123-.564-.046-1.148.04-1.682-.145-1.155-.403-1.617-1.895-2.745-2.37-.843-.356-1.813-.04-2.63.372-.818.41-1.625.928-2.538.986-.795.051-1.657-.243-2.352.145-.518.288-.789.885-1.266 1.237-.467.344-1.076.417-1.655.448-.58.032-1.18.038-1.707.279-.527.241-.964.799-.855 1.368l20.028-.197Z"
          fill="#F5F5F5"
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.857 61.88a5.246 5.246 0 0 1-5.242-5.25c0-2.899 2.347-5.25 5.242-5.25a5.246 5.246 0 0 1 5.243 5.25c0 2.9-2.348 5.25-5.243 5.25m-.165-12.058c-3.7.086-6.717 3.106-6.806 6.811a6.958 6.958 0 0 0 1.311 4.246h-.002l.068.093.01.013 4.937 6.725a1.059 1.059 0 0 0 1.734-.036l4.463-6.644c.844-1.29 1.423-2.638 1.423-4.227 0-3.912-3.212-7.072-7.138-6.98"
        fill="#FF725E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.857 61.88a5.246 5.246 0 0 1-5.242-5.25c0-2.899 2.347-5.25 5.242-5.25a5.246 5.246 0 0 1 5.243 5.25c0 2.9-2.348 5.25-5.243 5.25m-.165-12.058c-3.7.086-6.717 3.106-6.806 6.811a6.958 6.958 0 0 0 1.311 4.246h-.002l.068.093.01.013 4.937 6.725a1.059 1.059 0 0 0 1.734-.036l4.463-6.644c.844-1.29 1.423-2.638 1.423-4.227 0-3.912-3.212-7.072-7.138-6.98"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.444 60.935a3.973 3.973 0 0 1-3.97-3.976 3.973 3.973 0 0 1 3.97-3.976c2.192 0 3.97 1.78 3.97 3.976a3.973 3.973 0 0 1-3.97 3.976m-.166-11.113c-3.7.086-6.717 3.106-6.805 6.811a6.96 6.96 0 0 0 1.31 4.246h-.001l.068.093.01.013 4.937 6.725a1.059 1.059 0 0 0 1.733-.036l4.463-6.644c.845-1.29 1.424-2.638 1.424-4.227 0-3.912-3.212-7.072-7.139-6.98"
        fill="#FF4C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.19 52.394a3.998 3.998 0 0 1-3.995-4.001 3.998 3.998 0 0 1 3.995-4.001 3.999 3.999 0 0 1 3.996 4c0 2.21-1.789 4.002-3.996 4.002m-.126-9.19c-2.82.065-5.119 2.367-5.186 5.19a5.302 5.302 0 0 0 .999 3.236h-.002l.052.07a.05.05 0 0 1 .007.011l3.763 5.125a.807.807 0 0 0 1.321-.028l3.401-5.063c.644-.983 1.085-2.01 1.085-3.221a5.317 5.317 0 0 0-5.44-5.32"
        fill="#FF725E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.19 52.394a3.998 3.998 0 0 1-3.995-4.001 3.998 3.998 0 0 1 3.995-4.001 3.999 3.999 0 0 1 3.996 4c0 2.21-1.789 4.002-3.996 4.002m-.126-9.19c-2.82.065-5.119 2.367-5.186 5.19a5.302 5.302 0 0 0 .999 3.236h-.002l.052.07a.05.05 0 0 1 .007.011l3.763 5.125a.807.807 0 0 0 1.321-.028l3.401-5.063c.644-.983 1.085-2.01 1.085-3.221a5.317 5.317 0 0 0-5.44-5.32"
        fill="#000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M128.876 51.672a3.028 3.028 0 0 1-3.026-3.03 3.028 3.028 0 0 1 3.026-3.03 3.028 3.028 0 0 1 3.026 3.03 3.028 3.028 0 0 1-3.026 3.03m-.127-8.468c-2.819.065-5.119 2.366-5.186 5.19a5.31 5.31 0 0 0 .999 3.236h-.001l.052.07a.05.05 0 0 0 .007.01l3.762 5.126a.808.808 0 0 0 1.322-.028l3.401-5.063c.643-.983 1.085-2.01 1.085-3.221a5.318 5.318 0 0 0-5.441-5.32"
        fill="#FF4C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M76.16 73.623 49.09 68.588l-25.806 6.584L0 132.875l43.658-7.165 32.016 7.165 32.793-7.165 43.852 8.349-23.478-58.887-26.195-6.584-26.486 5.035Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.416 74.823s.142-.04.428-.115l1.276-.331 5.002-1.29 19.126-4.915.009-.002.01.001 27.108 5.04h-.022l26.521-5.066.015-.002.015.004 26.233 6.602.038.01.014.036 23.519 59.121.057.143-.151-.029-22.59-4.311-21.324-4.07.034-.001-32.838 7.19-.018.004-.017-.004-32.06-7.199.03.001-43.722 7.18-.109.018.041-.103 17.09-42.382 4.654-11.521L23 75.839c.274-.674.417-1.016.417-1.016s-.136.351-.405 1.03l-1.201 3.005c-1.06 2.64-2.62 6.531-4.628 11.541L.157 132.782l-.068-.085 43.717-7.206.014-.003.015.003 32.061 7.188h-.035l32.837-7.196.017-.004.017.003 21.324 4.07 22.589 4.312-.094.114-23.503-59.127.052.046-26.23-6.617.03.001-26.525 5.045-.01.002-.012-.002-27.102-5.07.018-.001-19.122 4.869-5.012 1.27-1.283.323c-.288.072-.436.106-.436.106"
        fill="#263238"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.776 76.378 4.179 129.842l39.445-6.416 32.74 7.582 32.352-7.776 40.222 7.776-21.18-54.63-24.482-6.026-26.912 5.054-27.494-5.054-24.094 6.026Z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m6.468 123.902 31.352-7.265-1.158-6.758 2.605-1.641-4.052-15.783-12.537-10.63-16.21 42.077Z"
        fill="url(#b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m4.18 129.842 1.528-3.968 52.567-12.22 2.856 7.863-.926 1.588-9.873 1.875-6.708-1.554-39.444 6.416ZM61.13 112.894l2.086 7.779 6.053 2.633 8.615-3.83 18.068-5.266-5.638-8.497-29.183 7.181Z"
        fill="#EBEBEB"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="m40.133 115.775 9.767-2.12-1.949-6.35-7.115 1.72-1.895 1.338 1.192 5.412Z" fill="#E0E0E0" />
      <path fillRule="evenodd" clipRule="evenodd" d="m38.139 94.73 3.546 11.644 5.542-1.219-.997-4.474 12.191-2.734L38.14 94.73Z" fill="#FF4C0C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m23.6 79.43 15.341 12.324 20.367 3.863 21.724-5.403-9.917-15.773-22.245-4.09-24.094 6.027-1.176 3.053ZM74.082 74.987l31.849 48.915 2.785-.669L77.891 75.12l-1.526.287-2.283-.42Z"
        fill="#EBEBEB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m48.28 101.912 4.036 11.743 35.825-9.158-6.63-11.293-33.231 8.708ZM68.21 129.121l.349-.519 2.353-3.5 5.646-1.796 3.883-2.927 16.838-4.604 5.23 8.95-26.144 6.284M98.035 71.336l8.439 9.392 21.284-4.35-24.482-6.026-5.24.984ZM132.238 87.934l-15.633 4.345-7.889-10.453 19.576-4.07 3.946 10.178Z"
        fill="#E0E0E0"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="m79.563 74.806 16.39 23.555 18.916-5.43-18.917-21.203-16.39 3.078Z" fill="#EBEBEB" />
      <path fillRule="evenodd" clipRule="evenodd" d="m125.385 126.456 18.913-7.413 4.64 11.966-23.553-4.553Z" fill="#E0E0E0" />
      <path fillRule="evenodd" clipRule="evenodd" d="m105.605 96.953 10.277-2.68 10.855 13.032-10.566 3.51-10.566-13.862Z" fill="#FF4C0C" />
      <path fillRule="evenodd" clipRule="evenodd" d="m118.198 93.204 11.433 13.117 8.614-2.892-5.663-14.609-14.384 4.384Z" fill="#E0E0E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m126.737 123.393-8.539-11.853 20.639-6.583 4.194 10.819-2.689 2.71-13.605 4.907Z"
        fill="#FF4C0C"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m96.957 100.094 15.691 23.808 9.661 1.958 1.822-.835-19.881-27.13-7.293 2.199ZM32.655 117.834l-3.909-18.39-13.784 2.41-8.494 22.048 26.187-6.068Z"
        fill="#E0E0E0"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M45.33 71.237v8.42l2.95.373 8.24 9.604 7.083-.814 3.372 4.89-7.667 1.908-20.367-3.863L23.6 79.43l1.176-3.052 20.553-5.142Z"
        fill="url(#c)"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="m88.41 73.216 11.573 16.898 9.969-2.694-14-15.692-7.542 1.488Z" fill="#E0E0E0" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m122.384 90.673-4.186-6.55 11.321-3.202 2.719 7.012-9.854 2.74ZM105.605 113.217l5.481-1.895 10.629 14.419-9.068-1.838-7.042-10.686Z"
        fill="#EBEBEB"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="m55.05 126.073 5.097-.62 1.698-2.488 6.365 2.002-1.665 3.768-11.495-2.662Z" fill="#FF4C0C" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.776 76.379s.537-.142 1.588-.408l4.662-1.177L48.86 70.31l.009-.002.009.001 27.497 5.038h-.021l26.909-5.065.015-.003.014.004 24.485 6.02.039.01.014.037 21.187 54.627.055.143-.15-.029-20.18-3.901-20.042-3.876h.036l-32.352 7.773-.02.005-.018-.005-32.74-7.588.03.001-39.446 6.402-.106.017.038-.1 15.102-39.118 4.111-10.627 1.072-2.76c.242-.621.37-.936.37-.936s-.12.324-.358.949l-1.06 2.773-4.084 10.646-15.038 39.118-.067-.083 39.442-6.429.014-.002.015.003 32.742 7.576h-.038l32.351-7.78.018-.004.019.004 20.041 3.874 20.18 3.902-.095.114-21.172-54.633.053.047-24.481-6.034.03.001-26.914 5.044-.01.002-.011-.002-27.492-5.071h.018L31.052 74.83l-4.673 1.159c-1.056.26-1.603.39-1.603.39M58.633 47.873c.061-.016.27.595.464 1.364.195.77.304 1.406.243 1.422-.06.015-.268-.596-.463-1.365-.196-.77-.305-1.406-.244-1.421"
        fill="#263238"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M66.882 73.719c.014-.005.07.116.142.347.073.23.15.573.191 1.004a5.8 5.8 0 0 1-.68 3.28c-.319.576-.678 1.101-1.088 1.51a3.632 3.632 0 0 1-1.27.847c-.407.159-.76.206-.999.226-.24.01-.374.003-.374-.012-.008-.049.541-.032 1.312-.362a3.694 3.694 0 0 0 1.182-.845c.382-.398.726-.91 1.039-1.474a6.068 6.068 0 0 0 .717-3.159c-.038-.845-.212-1.351-.172-1.362M65.517 74.407c-.052-.003.29-.789.001-2.044-.14-.618-.432-1.327-.892-2.03-.46-.707-1.078-1.405-1.736-2.134-.661-.726-1.275-1.45-1.744-2.17a13.624 13.624 0 0 1-1.095-1.996 12.463 12.463 0 0 1-.536-1.436c-.105-.346-.143-.543-.13-.547.017-.005.084.183.214.518s.32.82.598 1.397c.28.576.645 1.248 1.123 1.946.472.704 1.077 1.408 1.739 2.136.658.728 1.282 1.441 1.743 2.172.464.73.75 1.468.874 2.111.131.644.088 1.185.018 1.54a2.671 2.671 0 0 1-.115.404c-.033.09-.055.135-.062.133M50.517 53.194c.046.002.075-.442.182-1.137.114-.676.348-1.684 1.173-2.336.361-.29.79-.454 1.185-.453h.146l-.01-.158c-.045-.7-.02-1.366-.045-1.808-.014-.45-.062-.72-.098-.715-.026.005-.036.263-.07.713-.02.457-.087 1.065-.056 1.853l.136-.158c-.465-.015-.966.172-1.37.497a2.971 2.971 0 0 0-.921 1.29c-.174.457-.24.89-.274 1.249-.058.72-.012 1.162.022 1.163"
        fill="#EB996E"
      />
      <path
        d="M51.376 71.46c-7.137-5.627-11.23-14.03-11.23-23.055 0-9.024 4.092-17.427 11.23-23.055a1.94 1.94 0 0 0 .313-2.745 1.988 1.988 0 0 0-2.774-.31c-8.082 6.373-12.717 15.89-12.717 26.11 0 10.22 4.635 19.738 12.718 26.111a1.988 1.988 0 0 0 2.773-.31 1.94 1.94 0 0 0-.313-2.745ZM90.2 22.295a1.988 1.988 0 0 0-2.774.31 1.94 1.94 0 0 0 .314 2.745c7.137 5.628 11.23 14.031 11.23 23.056 0 9.024-4.093 17.427-11.23 23.055a1.94 1.94 0 0 0-.314 2.745c.68.844 1.922.982 2.774.31 8.082-6.373 12.718-15.89 12.718-26.11 0-10.22-4.636-19.738-12.718-26.111Z"
        fill="#525252"
      />
      <path
        d="M58.188 63.084c-4.688-3.68-7.377-9.168-7.377-15.057 0-5.89 2.69-11.378 7.377-15.058a1.956 1.956 0 0 0 .32-2.773 2.018 2.018 0 0 0-2.81-.316c-5.647 4.433-8.886 11.047-8.886 18.147 0 7.099 3.24 13.713 8.886 18.146a2.018 2.018 0 0 0 2.81-.316 1.956 1.956 0 0 0-.32-2.773ZM83.417 29.88a2.018 2.018 0 0 0-2.81.316 1.956 1.956 0 0 0 .32 2.773c4.688 3.68 7.377 9.168 7.377 15.057 0 5.89-2.689 11.378-7.377 15.058a1.956 1.956 0 0 0-.32 2.773 2.018 2.018 0 0 0 2.81.316c5.647-4.433 8.886-11.047 8.886-18.147 0-7.099-3.239-13.713-8.886-18.146Z"
        fill="#525252"
      />
      <path
        d="M69.558 38.549c-5.435 0-9.857 4.393-9.857 9.792 0 4.729 3.392 8.685 7.886 9.595v21.87c0 1.082.882 1.959 1.97 1.959a1.965 1.965 0 0 0 1.972-1.958v-21.87c4.493-.91 7.885-4.867 7.885-9.596 0-5.4-4.421-9.792-9.856-9.792Z"
        fill="#FF4C0C"
      />
      <defs>
        <linearGradient id="b" x1="12.354" y1="80.86" x2="9.885" y2="107.746" gradientUnits="userSpaceOnUse">
          <stop offset="1" stopColor="#FF4C0C" />
        </linearGradient>
        <linearGradient id="c" x1="31.384" y1="70.678" x2="30.753" y2="86.362" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF4C0C" />
        </linearGradient>
      </defs>
    </svg>
  );
}
