import React, { useState } from 'react';
import { EuiRadio } from '@elastic/eui';
import LockIcon from '@mui/icons-material/Lock';
import { ConstantColors } from 'utilities';
import { useTranslation } from 'react-i18next';
import * as style from './certificate-type-modal.style';
import type { CertificateTypeModalProps } from '../page-certificate.contracts';

export function CertificateTypeModal({ onClose, openConfigModal, setKind, authorityEnabled, identityEnabled }: CertificateTypeModalProps) {
  const [checked_ca_certificate, set_checked_ca_certificate] = useState(true);
  const [checked_user_identity, set_checked_user_identity] = useState(false);
  const { t } = useTranslation();

  const handleOnClose = () => {
    onClose();
  };

  const caCertificateChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_checked_ca_certificate(e.target.checked);
    set_checked_user_identity(false);
    setKind('authority');
  };

  const userIdentityChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    set_checked_user_identity(e.target.checked);
    set_checked_ca_certificate(false);
    setKind('identity');
  };

  return (
    <style.Modal onClose={handleOnClose}>
      <style.Content>
        <style.Title>{t('pages.certificate_selection.title')}</style.Title>
        <style.Text>{t('pages.certificate_selection.subtitle')}</style.Text>

        <style.BodyModal>
          {authorityEnabled && (
            <style.LeftContainer>
              <style.RadioContainer>
                <div className="icon-container">
                  <LockIcon style={{ color: ConstantColors.CHILE, fontSize: '52px' }} />
                  <h1 style={{ color: ConstantColors.CHILE }}>CA</h1>
                </div>
                <EuiRadio
                  id="certificate_ca"
                  label={t('pages.certificate_selection.title_ca')}
                  checked={checked_ca_certificate}
                  onChange={(e) => caCertificateChecked(e)}
                />
              </style.RadioContainer>
              <p>{t('pages.certificate_selection.title_ca_phrase')}</p>
            </style.LeftContainer>
          )}
          {identityEnabled && (
            <style.RightContainer>
              <style.RadioContainer>
                <div className="icon-container">
                  <LockIcon style={{ color: ConstantColors.JAPAN, fontSize: '52px' }} />
                  <h1 style={{ color: ConstantColors.JAPAN }}>Identity</h1>
                </div>
                <EuiRadio
                  id="user_identity"
                  label={t('pages.certificate_selection.title_ui')}
                  checked={checked_user_identity}
                  onChange={(e) => userIdentityChecked(e)}
                />
              </style.RadioContainer>
              <p>{t('pages.certificate_selection.title_ui_phrase')}</p>
            </style.RightContainer>
          )}
        </style.BodyModal>

        <style.FooterModal>
          <style.SwitchContainer />

          <style.Button fill onClick={() => openConfigModal()}>
            {t('pages.certificate_selection.text_button')}
          </style.Button>
        </style.FooterModal>
      </style.Content>
    </style.Modal>
  );
}
