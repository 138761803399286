import styled from 'styled-components';

export const CardFlags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 18px;
  padding: 0 10px;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 18px;
  color: #343741;
  background: #e0e5ee;
`;
