import React from 'react';
import * as style from './custom-slider.style';
import type * as types from './page-security-settings.contracts';

export const CustomSlider = (props: types.CustomSliderProps) => {
  const { label, min, max, onChange, value, children, step, disabled, ticks, fullWidth } = props;

  function handleChange(e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChange(Number(e.currentTarget.value));
  }

  return (
    <style.SliderContainer>
      {label && <>{label}</>}
      {children && <>{children}</>}
      <style.Slider
        min={min}
        step={step}
        showInput
        showTicks={!!ticks}
        ticks={ticks}
        fullWidth={fullWidth}
        max={max}
        showRange
        disabled={disabled}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </style.SliderContainer>
  );
};
