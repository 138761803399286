import axios from 'axios';
import { HelperAuthentication } from 'helpers';
import type { AxiosInstance } from 'axios';

export class ServicePulsusIduna {
  static instance: AxiosInstance;

  static getInstance() {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: process.env.REACT_APP_URL_PULSUS_IDUNA,
        headers: {
          Authorization: HelperAuthentication.bearer(),
          'X-Schema': HelperAuthentication.decodedSchemaName(),
        },
      });
    }

    return this.instance;
  }

  static async createTemplate(tenant_id: string, body) {
    await this.getInstance().post(`${tenant_id}/templates`, body);
  }

  static async loginEntrust(tenant_id: string, body) {
    const response = await this.getInstance().post(`${tenant_id}/credentials`, body);
    return response;
  }
}
