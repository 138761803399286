import type { Nested, TranslationByPrefixType, UseTypedTranslationsProps } from './UseTypedTranslation.contracts';
import type { UseTranslationOptions } from 'react-i18next';
import { useTranslation } from 'react-i18next';

export function useTypedTranslation<T extends string>(path: Nested): UseTypedTranslationsProps<T> {
  const translationsInstance = useTranslation('translation', { keyPrefix: path });

  const translation = (pathing: TranslationByPrefixType<T>, opt?: UseTranslationOptions<unknown>): string => {
    return translationsInstance.t(pathing as string, opt) satisfies string;
  };

  return { ...translationsInstance, t: translation };
}
