/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiForm,
  EuiFormRow,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSuperSelect,
  EuiSwitch,
} from '@elastic/eui';
import { useState } from 'react';
import type { ModalAddNewWifiProps } from './page-connectivity.contracts';

import * as style from './page-connectivity.style';

export function ModalAddNewWifiContent({ to_networks_list_modal, close_wifi_modal }: ModalAddNewWifiProps) {
  const [is_hidden_network, set_is_hidden_network] = useState(false);
  const [is_automatic_connection, set_is_automatic_connection] = useState(false);
  const [select_value, set_select_value] = useState('none');
  const [password_value, set_password_value] = useState('');

  const handle_hidden_network = () => {
    set_is_hidden_network(!is_hidden_network);
  };

  const handle_automatic_connection = () => {
    set_is_automatic_connection(!is_automatic_connection);
  };

  const on_select_change = (value: string) => {
    set_select_value(value);
  };

  const create_new_wifi_network = () => {
    close_wifi_modal();
  };

  return (
    <style.WifiNetworksModal>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          <EuiModalHeaderTitle>
            <h1>Adicionar Wifi</h1>
            <p>Adicione redes wiifi que podem ser acessadas pelos dispositivos.</p>
          </EuiModalHeaderTitle>
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <style.Body>
        <EuiForm component="form" className="add_wifi_form">
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow label="SSID">
                <EuiFieldText name="SSID" />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow label="Tipo de segurança">
                <EuiSuperSelect
                  options={[
                    {
                      value: 'none',
                      inputDisplay: 'Nenhuma segurança',
                    },
                    {
                      value: 'wep',
                      inputDisplay: 'WEP',
                    },
                    {
                      value: 'wpa_wpa2',
                      inputDisplay: 'WPA/WPA2',
                    },
                    {
                      value: 'any',
                      inputDisplay: 'Any (Pessoal)',
                    },
                  ]}
                  valueOfSelected={select_value}
                  onChange={(value) => on_select_change(value)}
                  itemLayoutAlign="top"
                  hasDividers
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow fullWidth>
                <EuiFieldPassword
                  value={password_value}
                  onChange={(e) => set_password_value(e.target.value)}
                  aria-label="Senha da rede"
                  type="dual"
                  fullWidth
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexGroup>
            <EuiFlexItem>
              <EuiFormRow>
                <EuiSwitch name="hidden_network" label="Rede oculta" checked={is_hidden_network} onChange={handle_hidden_network} />
              </EuiFormRow>
            </EuiFlexItem>
            <EuiFlexItem>
              <EuiFormRow>
                <EuiSwitch
                  name="automatic_connection"
                  label="Conexão automática"
                  checked={is_automatic_connection}
                  onChange={handle_automatic_connection}
                />
              </EuiFormRow>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiForm>
      </style.Body>
      <style.Footer style={{ width: '100%' }}>
        <EuiButtonEmpty className="button_to_networks_list" size="xs" onClick={() => to_networks_list_modal()}>
          Ver toda a lista de redes Wi-fi disponíveis
        </EuiButtonEmpty>
        <EuiButton className="button_add_new_network" fill onClick={() => create_new_wifi_network()}>
          Adicionar rede
        </EuiButton>
      </style.Footer>
    </style.WifiNetworksModal>
  );
}
