import TranslationsApps from './TranslationApps';
import TranslationsDevices from './TranslationDevices';
import TranslationsDashboard from './DashboardTranslations';
import TranslationsHistoryLocation from './TranslationHistoryLocation';
import MapsTranslations from './MapsTranslations';
import DataUsageTranslations from './DataUsageTranslations';
import UsersTranslations from './UsersTranslations';

export const OperationalTranslations = {
  pt: {
    ...TranslationsApps.pt,
    ...TranslationsDevices.pt,
    ...TranslationsDashboard.pt,
    ...TranslationsHistoryLocation.pt,
    ...MapsTranslations.pt,
    ...DataUsageTranslations.pt,
    ...UsersTranslations.pt,
  },
  en: {
    ...TranslationsApps.en,
    ...TranslationsDevices.en,
    ...TranslationsDashboard.en,
    ...TranslationsHistoryLocation.en,
    ...MapsTranslations.en,
    ...DataUsageTranslations.en,
    ...UsersTranslations.en,
  },
  es: {
    ...TranslationsApps.es,
    ...TranslationsDevices.es,
    ...TranslationsDashboard.es,
    ...TranslationsHistoryLocation.es,
    ...MapsTranslations.es,
    ...DataUsageTranslations.es,
    ...UsersTranslations.es,
  },
};
