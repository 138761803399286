import styled from 'styled-components';
import { EuiCard, EuiForm, EuiFormRow, EuiButton } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Card = styled(EuiCard)`
  width: 500px !important;
  flex-grow: 0 !important;
  padding: 55px 50px 40px 50px !important;

  @media (max-height: 1000px) {
    width: 480px !important;
    padding: 40px 40px 35px 40px !important;
  }

  & .euiCard__children {
    margin: 0 !important;
  }
`;

export const Form = styled(EuiForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormTitle = styled.h1`
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 5px;
  color: ${ConstantColors.ARGENTINA};

  @media (max-height: 1000px) {
    margin-bottom: 5px;
  }
`;

export const FormDescription = styled.p`
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 15.1636px;
  line-height: 18px;
  margin-bottom: 45px;
  color: ${ConstantColors.GREECE};

  @media (max-height: 1000px) {
    margin-bottom: 40px;
  }
`;

export const FormRowEmail = styled(EuiFormRow)`
  margin-bottom: 10px;
`;

export const FormRowPassword = styled(EuiFormRow)`
  height: 85px;
  margin-bottom: 10px;
  text-align: left;
`;

export const FormForgotPasswordLink = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  font-size: 13px;
  line-height: 21px;
  margin-bottom: 70px;

  @media (max-height: 1000px) {
    margin-bottom: 25px;
  }
`;

export const FormEnterButton = styled(EuiButton)`
  margin-bottom: 25px;
  min-height: 40px !important;
`;

export const FormEnterSSOLink = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
`;

export const FormTrialContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 50px;
  margin-top: 10px;
  background: ${ConstantColors.CANADA};
`;

export const FormTrialText = styled.p`
  font-style: normal;
  font-weight: 500;
  line-height: 21px;
  color: #5f5f5f;
`;
