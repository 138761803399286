export class GroupList {
  public id: number;

  public basicInfo: {
    id: number;
    name: string;
  };

  public pin: string;
  public tags: string;
  public numberOfDevices: number;

  constructor(data: GroupList.ServerGroup) {
    this.id = data.id;
    this.basicInfo = {
      id: data.id,
      name: data.name,
    };
    this.pin = data.pin;
    this.tags = data.tags.length > 0 ? data.tags.join(', ') : '-';
    this.numberOfDevices = data.device_count;
  }
}

export namespace GroupList {
  export type Server = {
    total: number;
    totalDevices: number;
    groups: ServerGroup[];
  };

  export type ServerGroup = {
    id: number;
    name: string;
    pin: string;
    tags: string[];
    device_count: number;
  };
}
