import { useState, useMemo } from 'react';
import { EuiFieldText, EuiFieldPassword, EuiHorizontalRule } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { ConstantRoutes } from 'utilities';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { ComponentLoginContainer } from 'components';
import * as style from './page-login.style';

export function PageLogin() {
  const history = useNavigate();
  const { t } = useTranslation();

  const [has_error, set_has_error] = useState(false);
  const [is_loading, set_is_loading] = useState(false);
  const [email, set_email] = useState('');
  const [password, set_password] = useState('');

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const handleEmailChange = (event) => {
    set_email(event.target.value);
  };

  const handleChangePassword = (event) => {
    set_password(event.target.value);
  };

  const handleLogin = () => {
    set_is_loading(true);

    servicePulsusWeb
      .login(email, password)
      .then(() => {
        set_is_loading(false);
        set_has_error(false);

        history(ConstantRoutes.HOME.path);
      })
      .catch(() => {
        set_is_loading(false);
        set_has_error(true);
      });
  };

  return (
    <ComponentLoginContainer test_id="page__login">
      <style.Card title="">
        <style.Form>
          <style.FormTitle>{t('pages.login.text.title')}</style.FormTitle>
          <style.FormDescription>{t('pages.login.text.description')}</style.FormDescription>
          <style.FormRowEmail data-testid="page_login-email" isInvalid={has_error} label={t('pages.login.input.email.label')}>
            <EuiFieldText
              isInvalid={has_error}
              placeholder={t('pages.login.input.email.placeholder')}
              value={email}
              onChange={handleEmailChange}
              fullWidth
            />
          </style.FormRowEmail>
          <style.FormRowPassword
            data-testid="page_login-password"
            isInvalid={has_error}
            label={t('pages.login.input.password.label')}
            error={t('pages.login.text.error')}
          >
            <EuiFieldPassword
              placeholder={t('pages.login.input.password.placeholder')}
              type="dual"
              value={password}
              onChange={handleChangePassword}
              fullWidth
              isInvalid={has_error}
            />
          </style.FormRowPassword>
          <style.FormForgotPasswordLink>
            <Link data-testid="page_login-forgot-password" to={ConstantRoutes.FORGOT_PASSWORD.path}>
              {t('pages.login.link.forgot_password')}
            </Link>
          </style.FormForgotPasswordLink>
          <style.FormEnterButton data-testid="page_login-button" fill isLoading={is_loading} onClick={handleLogin}>
            {t('pages.login.button.enter')}
          </style.FormEnterButton>
          <style.FormEnterSSOLink>
            <Link data-testid="page_login-sso-link" to={ConstantRoutes.SSO.path}>
              {t('pages.login.button.sso')}
            </Link>
          </style.FormEnterSSOLink>
          <EuiHorizontalRule />
          <style.FormTrialContainer>
            <style.FormTrialText>
              {t('pages.login.text.trial')}
              <Link data-testid="page_login-signup" to={ConstantRoutes.SIGNUP.path}>
                {t('pages.login.link.trial')}
              </Link>
            </style.FormTrialText>
          </style.FormTrialContainer>
        </style.Form>
      </style.Card>
    </ComponentLoginContainer>
  );
}
