export class HelperStorage {
  static save(key, value) {
    localStorage.setItem(key, value);
  }

  static fetch(key) {
    return localStorage.getItem(key);
  }

  static delete(key) {
    localStorage.removeItem(key);
  }
}
