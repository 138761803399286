import React from 'react';

const LogoIconComponent = React.lazy(async () => await import('./LogoIconComponent'));
const InformationIconComponent = React.lazy(async () => await import('./InformationIconComponent'));
const ArticlesIconComponent = React.lazy(async () => await import('./ArticlesIconComponent'));
const SupportIconComponent = React.lazy(async () => await import('./SupportIconComponent'));
const PreviewThemeIconComponent = React.lazy(async () => await import('./PreviewThemeIconComponent'));
const Battery2IconComponent = React.lazy(async () => await import('./Battery2IconComponent'));
const ColoredChromeOSIconComponent = React.lazy(async () => await import('./ColoredChromeOSIconComponent'));
const Group2IconComponent = React.lazy(async () => await import('./Group2IconComponent'));
const WarningIconComponent = React.lazy(async () => await import('./WarningIconComponent'));
const AndroidIconComponent = React.lazy(async () => await import('./AndroidIconComponent'));
const IosIconComponent = React.lazy(async () => await import('./IosIconComponent'));
const AndroidRobotIconComponent = React.lazy(async () => await import('./AndroidRobotIconComponent'));
const CirclesInCircleIconComponent = React.lazy(async () => await import('./CirclesInCircleIconComponent'));
const FileDownloadIconComponent = React.lazy(async () => await import('./FileDownloadIconComponent'));
const PulsusLogoComponent = React.lazy(async () => await import('./PulsusLogoComponent'));
const PulsusLogoOnlyPIconComponent = React.lazy(async () => await import('./PulsusLogoOnlyPComponent'));
const ReloadWarningIconComponent = React.lazy(async () => await import('./ReloadWarningIconComponent'));
const HomeCellphoneIconComponent = React.lazy(async () => await import('./HomeCellphoneIconComponent'));
const FenceInOutIconComponent = React.lazy(async () => await import('./FenceInOutIconComponent'));
const ChromeOSIconComponent = React.lazy(async () => await import('./ChromeOSIconComponent'));
const AndroidHeadIconComponent = React.lazy(async () => await import('./AndroidHeadIconComponent'));

export const AVAILABLE_ICONS = {
  androidHead: AndroidHeadIconComponent,
  logo: LogoIconComponent,
  information: InformationIconComponent,
  articles: ArticlesIconComponent,
  support: SupportIconComponent,
  previewTheme: PreviewThemeIconComponent,
  battery2: Battery2IconComponent,
  coloredChromeOS: ColoredChromeOSIconComponent,
  group2: Group2IconComponent,
  warning: WarningIconComponent,
  android: AndroidIconComponent,
  ios: IosIconComponent,
  fenceInOut: FenceInOutIconComponent,
  androidRobot: AndroidRobotIconComponent,
  circlesInCircles: CirclesInCircleIconComponent,
  fileDownload: FileDownloadIconComponent,
  logoOnlyP: PulsusLogoOnlyPIconComponent,
  pulsusLogo: PulsusLogoComponent,
  chromeOS: ChromeOSIconComponent,
  reloadWarning: ReloadWarningIconComponent,
  HomeCellphone: HomeCellphoneIconComponent,
};
