import { useState, useMemo } from 'react';
import { EuiFieldText, EuiIcon } from '@elastic/eui';
import { ConstantColors, ConstantRoutes } from 'utilities';
import * as style from 'pages/forgot-password/page-forgot-password.style';
import { ComponentLoginContainer } from 'components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';

export function PageForgotPassword() {
  const { t } = useTranslation();

  const [is_loading, set_is_loading] = useState(false);
  const [success, set_success] = useState(false);
  const [email, set_email] = useState('');

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const handleEmailChange = (event) => {
    set_email(event.target.value);
  };

  const handleRecoverPassword = () => {
    set_is_loading(true);

    servicePulsusWeb
      .recoverPassword(email)
      .then(() => {
        set_is_loading(false);
        set_success(true);
      })
      .catch(() => {
        set_is_loading(false);
        set_success(false);
      });
  };

  return (
    <ComponentLoginContainer>
      <style.Card title="" success={success ? 1 : 0}>
        <style.Form>
          <style.FormTitle>{t('pages.forgot_password.text.title')}</style.FormTitle>
          <style.FormDescription>{t('pages.forgot_password.text.description')}</style.FormDescription>
          <style.FormRow label={t('pages.forgot_password.input.email.label')}>
            <EuiFieldText onChange={handleEmailChange} value={email} placeholder={t('pages.forgot_password.input.email.placeholder')} fullWidth />
          </style.FormRow>
          <style.FormRecoverPasswordButton data-testid="button-recover-password" fill onClick={handleRecoverPassword} isLoading={is_loading}>
            {t('pages.forgot_password.button.recover_password')}
          </style.FormRecoverPasswordButton>
          <style.FormBackLoginLink>
            <Link to={ConstantRoutes.LOGIN.path}>{t('pages.forgot_password.link.back_to_login')}</Link>
          </style.FormBackLoginLink>
          <style.FormSuccessContainer success={success}>
            <style.FormSuccessTitleContainer>
              <EuiIcon data-testid="check-icon" color={ConstantColors.URUGUAY} type="check" />

              <style.FormSuccessTitle>{t('pages.forgot_password.text.success_title')}</style.FormSuccessTitle>
            </style.FormSuccessTitleContainer>
            <style.FormSuccessText>{t('pages.forgot_password.text.success_text')}</style.FormSuccessText>
          </style.FormSuccessContainer>
        </style.Form>
      </style.Card>
    </ComponentLoginContainer>
  );
}
