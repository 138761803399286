import { useEffect } from 'react';

export function IframeGoogleZeroTouch(props: { url: string }) {
  const { url } = props;

  async function triggerGapi() {
    const gapi = await import('gapi-script').then((pack) => pack.gapi);
    gapi.load('gapi.iframes', () => {
      const options = {
        url,
        where: document.getElementById('container'),
        attributes: { style: 'width: 600px; height:1000px', scrolling: 'yes' },
      };

      gapi.iframes.getContext().openChild(options);
    });
  }

  useEffect(() => {
    triggerGapi();
  }, []);
  return <div id="container"> </div>;
}
