import { mdiViewDashboard, mdiWifi, mdiVolumeHigh, mdiThermometer, mdiCellphoneSettings, mdiFolderOutline, mdiCircle } from '@mdi/js';
import Icon from '@mdi/react';
import { ComponentDashboardCard, GenericMap } from 'components';
import { EuiTextColor } from '@elastic/eui';
import { useTranslation } from 'react-i18next';
import * as style from './informations-tab.style';
import type { InformationTabProps } from './information-tab.contracts';
import MapChromeDeviceLocalization from './generic-map-layer';

export function InformationTab(props: InformationTabProps) {
  const { t } = useTranslation();

  const { deviceInfo } = props;
  const icon = <Icon path={mdiViewDashboard} style={{ border: 'solid 2px #757575', borderRadius: '5px' }} color="#757575" size="20px" />;
  const icon2 = <Icon path={mdiWifi} color="#757575" size="20px" />;
  const icon3 = <Icon path={mdiVolumeHigh} color="#757575" size="20px" />;
  const icon4 = <Icon path={mdiThermometer} color="#757575" size="20px" />;
  const icon5 = <Icon path={mdiCellphoneSettings} color="#757575" size="20px" />;
  const icon6 = (
    <Icon
      path="M1 19.1738H11C11.55 19.1738 12 19.6238 12 20.1738C12 20.7238 11.55 21.1738 11 21.1738H1C0.45 21.1738 0 20.7238 0 20.1738C0 19.6238 0.45 19.1738 1 19.1738ZM4.24 6.24376L7.07 3.41376L19.8 16.1438C20.58 16.9238 20.58 18.1938 19.8 18.9738C19.02 19.7538 17.75 19.7538 16.97 18.9738L4.24 6.24376ZM12.73 0.583756L15.56 3.41376C16.34 4.19376 16.34 5.46376 15.56 6.24376L14.14 7.66376L8.49 2.00376L9.9 0.593756C10.68 -0.196244 11.95 -0.196244 12.73 0.583756ZM2.83 7.65376L8.49 13.3138L7.08 14.7238C6.3 15.5038 5.03 15.5038 4.25 14.7238L1.42 11.8938C0.64 11.1138 0.64 9.84376 1.42 9.06376L2.83 7.65376Z"
      color="#757575"
      size="20px"
    />
  );
  const icon7 = <Icon path={mdiFolderOutline} color="#757575" size="20px" />;
  const icon8 = <Icon path={mdiCellphoneSettings} color="#757575" size="20px" />;

  return (
    <style.Container>
      <style.LeftBox>
        <style.InfoBox>
          <style.Label>{t('pages.device_management_item.item_chrome_configs_registration')}</style.Label>
          <style.Value>{deviceInfo?.inscriptionDate || '-'}</style.Value>
        </style.InfoBox>
        <style.InfoBox>
          <style.Label>{t('pages.device_management_item.item_chrome_configs_reset')}</style.Label>
          <style.Value>{deviceInfo?.rebootDate || '-'}</style.Value>
        </style.InfoBox>
        <style.InfoBox>
          <style.Label>{t('pages.device_management_item.item_chrome_configs_user')}</style.Label>
          <style.Value>{deviceInfo?.user || '-'}</style.Value>
        </style.InfoBox>
      </style.LeftBox>
      <style.RightBox>
        <style.PanelBox>
          <style.PanelTitle>{t('pages.device_management_item.item_chrome_configs_dashboard')}</style.PanelTitle>
          <style.PanelContent>
            <ComponentDashboardCard
              className="item"
              icon={icon}
              title={t('pages.device_management_item.item_ram')}
              info="-%"
              description={`- MB ${t('pages.device_management_item.item_battery_health_free_ram')} / - MB ${t(
                'pages.device_management_item.item_battery_health_free_total'
              )}`}
              statusColor="#FFB93B"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon2}
              title={t('pages.device_management_item.item_chrome_configs_wifi')}
              info="-"
              // description="Atualizado faz - minutos"
              description="-"
              statusColor="#00BFB3"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon3}
              title={t('pages.device_management_item.item_chrome_configs_level')}
              info="-%"
              description={t('pages.device_management_item.item_battery_percent')}
              statusColor="#00BFB3"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon4}
              title={t('pages.device_management_item.item_chrome_configs_temperature')}
              info="-°"
              // description="de - pendentes"
              description=" - "
              statusColor="#30A8FF"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon5}
              title={t('pages.device_management_item.item_chrome_configs_cpu')}
              info="-"
              // description="de - pendentes"
              description="-"
              statusColor="#00BFB3"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon6}
              title={t('pages.device_management_item.item_chrome_configs_so')}
              info="-"
              description=""
              statusColor="#00BFB3"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon7}
              title={t('pages.device_management_item.item_chrome_configs_disc')}
              info="-%"
              // description="- MB livre / - GB total"
              description={`- MB ${t('pages.device_management_item.item_battery_health_free_ram')} / - GB ${t(
                'pages.device_management_item.item_battery_health_free_total'
              )}`}
              statusColor="#FF3B5E"
            />
            <ComponentDashboardCard
              className="item"
              icon={icon8}
              title={t('pages.device_management_item.item_apps')}
              info="-"
              description={`- MB ${t('pages.device_management_item.item_battery_health_free_ram')} / - MB ${t(
                'pages.device_management_item.item_battery_health_free_total'
              )}`}
              statusColor="#00BFB3"
            />
          </style.PanelContent>
        </style.PanelBox>
        <style.MapBox>
          <style.MapTitle>{t('pages.device_management_item.item_localization')}</style.MapTitle>
          <style.MapDescription>
            <EuiTextColor>{t('pages.device_management_item.item_chrome_configs_localization')}</EuiTextColor>
            <EuiTextColor className="bold">{`ID-${deviceInfo?.id}.`}</EuiTextColor>
            <Icon path={mdiCircle} style={{ marginLeft: 10 }} title="status" size="6px" color="#4D4D4D" />
            <EuiTextColor>{t('pages.device_management_item.item_chrome_configs_updated_ago')}</EuiTextColor>
            <EuiTextColor className="bold">{`45 ${t('pages.device_management_item.item_chrome_configs_min')}`}</EuiTextColor>
          </style.MapDescription>

          <GenericMap zooms={[15]} mapHeight="200px" mapWidth="550px">
            <MapChromeDeviceLocalization />
          </GenericMap>
        </style.MapBox>
      </style.RightBox>
    </style.Container>
  );
}
