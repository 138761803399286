import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const ButtonEmptyContainer = styled.div`
  margin-top: 5px;
  button {
    height: auto;
    width: auto;
  }
  .euiButtonEmpty .euiButtonEmpty__content {
    padding: 0 !important;
  }
  .euiButtonEmpty__text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    color: ${ConstantColors.CHILE};
  }

  .euiIcon-isLoaded {
    margin-right: 9px;
  }
`;
