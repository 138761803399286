import styled from 'styled-components';
import type { HeaderStyleProps } from './HeaderPageComponent.contracts';

export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;

export const TitleAndDescription = styled.div<HeaderStyleProps>`
  display: flex;
  flex-direction: column;
  gap: 12px;

  h1 {
    font-size: 27px;
    color: ${({ theme }) => theme.main};
  }

  p {
    color: #67758d;
    font-size: 12px;
    font-weight: 500;
  }
`;
