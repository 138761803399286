import { useEffect, useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import _ from 'lodash';
import { HelperTheme } from 'helpers';
import { ServicePulsusNjord } from 'services/ServicePulsusNjord';
import * as style from './localization-cards-group.style';
import { GetCardFlagsAndroid } from '../flags-label-android';
import { QuickSettingsCard } from '../quick-settings-card';
import type { LocalizationCardProps, VpnManagementProps, VpnOptionType } from './card-localization.contracts';

export function LocalizationCardsGroup(props: LocalizationCardProps) {
  const { localization, vpnManagement, onChange } = props;
  const [deviceApps, setDeviceApps] = useState<any[]>([]);

  const options = GetCardFlagsAndroid();

  function onChangeAutocomplete(value: VpnOptionType | null) {
    const aux = { ...vpnManagement, identifier: value?.identifier || '' };
    handleChange(aux, 'vpn');
  }

  const onChangeRadios = () => {
    const aux = { ...vpnManagement, allowTraffic: !vpnManagement.allowTraffic };
    handleChange(aux, 'vpn');
  };

  function handleChange(param: string | VpnManagementProps, key: string) {
    onChange(param, key);
  }

  const radios = [
    {
      id: 'blockVpn',
      label: 'Impedir o tráfego de rede',
    },
    {
      id: 'allowVpn',
      label: 'Permitir o tráfego de rede',
    },
  ];

  const theme = HelperTheme.getTheme();

  const element = (
    <style.VpnContainer>
      <Autocomplete
        className="autocomplete"
        options={deviceApps || []}
        value={
          deviceApps.length > 0 ? deviceApps[_.findIndex(deviceApps, (o) => o.identifier === vpnManagement.identifier)] : { name: '', identifier: '' }
        }
        disabled={!vpnManagement.active}
        onChange={(event, option) => {
          onChangeAutocomplete(option);
        }}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            sx={{
              '& .MuiInputLabel-root.Mui-focused': { color: theme.main },
              '& .MuiOutlinedInput-root.Mui-focused': {
                '& > fieldset': {
                  borderColor: theme.main,
                },
              },
            }}
            {...params}
            label={vpnManagement?.identifier ? 'Selecionado' : 'Selecionar aplicativo de VPN'}
          />
        )}
      />
      <div className="bottom">
        <div style={{ display: 'flex', gap: 5 }}>
          <p>Quando a</p>
          <p className="theme-color">VPN não estiver conectada</p>
          <p>desejo:</p>
        </div>
        <style.VpnRadios
          disabled={!vpnManagement.active}
          options={radios}
          idSelected={vpnManagement.allowTraffic ? 'blockVpn' : 'allowVpn'}
          name="vpn radio"
          onChange={() => {
            onChangeRadios();
          }}
        />
      </div>
    </style.VpnContainer>
  );
  async function populateApps() {
    const apps = await ServicePulsusNjord.getVpnManagementAppList();
    if (Array.isArray(apps)) {
      setDeviceApps(apps);
    }
  }

  useEffect(() => {
    populateApps();
  }, []);

  return (
    <style.Container>
      <QuickSettingsCard
        title="Alta precisão do GPS do dispositivo"
        label={localization === 'LOCATION_ENFORCED' ? 'Alta precisão habilitada' : 'Alta precisão desabilitada'}
        flags={options.LocalizationFlags}
        active={localization === 'LOCATION_ENFORCED'}
        onChange={() => {
          const auxValue = localization === 'LOCATION_ENFORCED' ? 'LOCATION_DISABLED' : 'LOCATION_ENFORCED';
          handleChange(auxValue, 'localization');
        }}
      >
        <style.CardDescription>
          Modo depuração é usado por desenvolvedores para analisis especificas, por segurança é recomendado manter esta funcionalidade desabilitada.
        </style.CardDescription>
      </QuickSettingsCard>
      <QuickSettingsCard
        title="Gerenciamento de VPN"
        label="Forçar VPN sempre ligada"
        active={vpnManagement.active}
        flags={options.vpnFlags}
        bottomContent={element}
        onChange={() => {
          const aux = { ...vpnManagement, active: !vpnManagement.active };
          handleChange(aux, 'vpn');
        }}
      >
        <style.CardDescription>Funcionalidade que permite o gerenciamento da VPN configurada.</style.CardDescription>
      </QuickSettingsCard>
    </style.Container>
  );
}
