import { EuiButtonEmpty, EuiIcon } from '@elastic/eui';
import * as style from './component-button-empty.style';
import type { ButtonEmptyProps } from './button-empty.contracts';

export function ComponentButtonEmpty({ icon_type, on_click, label, font }: ButtonEmptyProps) {
  return (
    <style.ButtonEmptyContainer>
      <EuiButtonEmpty iconSide="right" iconType={icon_type} size="xs" onClick={() => on_click()}>
        <EuiIcon type="plusInCircle" />
        <span style={{ fontSize: font }}>{label}</span>
      </EuiButtonEmpty>
    </style.ButtonEmptyContainer>
  );
}
