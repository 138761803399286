import styled, { css } from 'styled-components';
import { EuiButton, EuiButtonGroup, EuiDualRange, EuiFilterGroup } from '@elastic/eui';

import { ConstantColors } from 'utilities';
import type { BatteryLevelProps, FilterGrayscaleProps } from './component-pulsus-insights-batteries.contracts';

export const Container = styled.main`
  padding: 0 50px 50px;
  width: 100%;
  max-width: 1600px;
  margin: auto;
`;

export const NavigationHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: space-between;
  gap: 30px;
  width: 100%;
  padding-bottom: 30px;
`;

export const TitleContainer = styled.div`
  width: 100%;
  max-width: 730px;

  h1 {
    color: ${({ theme }) => theme.main};
    margin-bottom: 10px;
    font-size: 28px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    color: ${ConstantColors.ITALY};
  }
`;

export const FilterGroup = styled(EuiFilterGroup)`
  width: 100%;
  min-width: 820px;
  flex-wrap: wrap;
  max-width: 1100px;
  gap: 14px;
  box-shadow: none;
  border: none;
  align-items: baseline;
  display: flex;
`;

export const ChartFilterSection = styled.section`
  display: flex;
  align-items: left;
  justify-content: flex-start;
  flex-direction: column;

  small {
    color: ${ConstantColors.ITALY};
  }
`;
export const ChartLabel = styled.span`
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: ${ConstantColors.ITALY};
  margin-bottom: 10px;
`;

export const EditFilterButton = styled(EuiButton)`
  font-size: 12px !important;
  font-weight: 500;
  width: 180px;
  height: 35px;
  border: none;
  background: ${ConstantColors.CHINA} !important;
  border-radius: 6px;
  line-height: 21px;
  color: ${ConstantColors.FRANCE}!important;
`;

export const ChartTypeSelector = styled(EuiButtonGroup)`
  .euiButtonGroupButton.euiButtonGroupButton-isDisabled.euiButtonGroupButton-isSelected {
    color: ${ConstantColors.ICELAND} !important;
    background-color: ${ConstantColors.AUSTRIA} !important;
    border-color: ${ConstantColors.ICELAND} !important;
  }
  .euiButtonGroup__buttons {
    box-shadow: none;
  }
  .euiButtonGroupButton {
    width: 54x;
    height: 35px;
    font-size: 12px;

    .euiButton__content {
      padding: 0 8px !important;
    }
  }

  .euiButtonGroupButton-isSelected {
    font-weight: 700;
    background: ${({ theme }) => theme.main} !important;
    border-color: ${({ theme }) => theme.main} !important;
  }
`;

export const EuiDualRangeContainer = styled.div`
  display: flex;
  align-items: left;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  max-width: 270px;
`;

export const EuiDualRangeHours = styled(EuiDualRange)`
  max-width: 510px !important;

  .euiFieldNumber.euiRangeInput {
    border-radius: 6px;
    height: 33px;
    width: 59px !important;
  }

  .euiFieldNumber {
    font-size: 12px;
  }

  .euiRangeInput {
    min-width: 59px !important;
  }

  .euiRangeTrack--hasTicks {
    margin-left: 0.2em;
    margin-right: 0.2em;
  }

  .euiRangeHighlight {
    background: ${ConstantColors.PORTUGAL};
  }

  .euiRangeSlider.euiRangeSlider--hasRange.euiDualRange__slider {
    z-index: 0;
  }

  .euiRangeHighlight__progress {
    background: ${({ theme }) => theme.main} !important;
  }

  .euiRangeThumb {
    background: ${({ theme }) => theme.main} !important;

    border: 1.85px solid ${ConstantColors.AUSTRIA};
    box-shadow:
      0px 0.925px 4.625px rgba(0, 0, 0, 0.1),
      0px 3.33px 12.025px rgba(0, 0, 0, 0.07),
      0px 7.77px 21.275px rgba(0, 0, 0, 0.06),
      0px 21.275px 32.375px rgba(0, 0, 0, 0.05);
  }
`;

export const SearchButtonSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: end;
  gap: 15px;

  small {
    color: ${ConstantColors.ITALY};
    font-size: 11px;
  }
`;

export const SearchButton = styled(EuiButton)`
  width: 300px;
  height: 40px;
  border: none;
  background: ${ConstantColors.CHINA} !important;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  color: ${ConstantColors.FRANCE}!important;
`;

export const ChartContainer = styled.div`
  min-width: 900px;
  height: 450px;
  background: ${ConstantColors.BRAZIL};
  padding: 20px 5px 5px 5px;
  border-radius: 19px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  canvas {
    height: 780px;
    width: 1560px;
    left: 20px;
    top: 20px;
  }
`;

export const LegendContainer = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 330px;
  max-width: 250px;
  margin-bottom: 30px;

  ::-webkit-scrollbar {
    width: 3px !important;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 16px !important;
    border: 6px solid rgba(105, 112, 125, 0.5);
  }

  ::-webkit-scrollbar-track {
    background: #efefef !important;
    border-radius: 16px !important;
  }

  ul {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;

    padding-right: 20px;
    min-height: 330px;
    min-width: 50px;
    max-width: 100px;
  }

  li {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;

    span {
      display: inline-block;
      height: 10px;
      margin-right: 4px;
      width: 10px;
      border-radius: 50%;
    }

    p {
      font-size: 11px;
      margin: 0;
      padding: 0;
      max-width: 70px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
`;

export const BatteriesTable = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const GroupLabelsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

export const TitleList = styled.div`
  display: flex;
`;

export const RangeHoursLabel = styled.h3`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${ConstantColors.BELIZE};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  height: 45px;
  width: 100%;
  padding-left: 15px;
`;

export const GroupLabelListItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 40px;
  padding-left: 5px;
`;

export const GroupsLabelDot = styled.span`
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  margin-right: 5px;
`;

export const GroupsLabelId = styled.label`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  color: ${ConstantColors.ITALY};
`;

export const RangeHoursContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  font-style: normal;
  font-weight: 500;
  background: ${ConstantColors.BELIZE};
  overflow-x: auto;
  overflow-y: hidden;
  margin-left: 30px;
`;

export const LevelSectionContainer = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  span {
    margin: 13px 26px 0 26px;
    font-size: 12.03px;
  }
`;

export const TimeLabel = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${ConstantColors.BELIZE};
  width: 62px;
  height: 45px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: ${ConstantColors.BOLIVIA};
`;

export const BatteryLevelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 100%;
`;

export const BatteryLevel = styled.div<BatteryLevelProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  width: 60px;
  background: ${(props) => props.background};
  border-radius: 4.62597px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  color: ${ConstantColors.AUSTRIA};
`;

export const GroupsLabel = styled.h3`
  font-size: 12.95px;
  font-weight: 700;
  margin-left: 15px;
  line-height: 15.68px;
  margin-top: 55px;
  margin-bottom: 35px;
`;

export const FilterGrayscale = styled.div<FilterGrayscaleProps>`
  ${({ is_loading }) => css`
    ${is_loading &&
    css`
      filter: grayscale(95%);
      width: 100%;
      height: 100%;
      opacity: 0.3;
    `}
  `}
`;

export const PlaceHolder = styled.div`
  position: fixed;
  height: 95%;
  width: 85%;
  background-color: rgba(255, 255, 255, 0.35);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Error = styled.div`
  font-size: 8px;
  width: 100%;
  margin-top: 2px;
`;
