export type AvailableLanguages = 'pt_BR' | 'en' | 'es';

export type AdministratorRole = 'OWNER' | 'ADMIN' | 'SUPER_ADMIN' | 'SUPER_ADMIN_READ_ONLY';

export type TenantAccess = {
  id: number;
  name: string;
};
export class Administrator {
  public name: string;
  public email: string;
  public language: AvailableLanguages;
  public role: AdministratorRole;
  public timezone: string;
  public id: number;
  public offset?: number;
  public availableTenantAccess: TenantAccess[];

  constructor(administrator: Administrator.Server) {
    this.name = administrator.name;
    this.email = administrator.email;
    this.language = administrator.language;
    this.role = administrator.role;
    this.id = administrator.id;
    this.availableTenantAccess = administrator.can_access;
    this.timezone = administrator.time_zone;
  }
}

export namespace Administrator {
  export type Server = {
    name: string;
    email: string;
    language: AvailableLanguages;
    role: AdministratorRole;
    id: number;
    can_access: TenantAccess[];
    time_zone: string;
  };
}
