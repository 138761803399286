import styled, { css } from 'styled-components';
import type { FlagStyleProps } from './component-flag.contracts';

export const Container = styled.div<FlagStyleProps>`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 22px;
  ${({ isSelected }) => css`
    background: ${isSelected ? '#ebebeb' : '#ffffff'};
    border: ${isSelected ? '' : 'solid 1px #ebebeb'};
  `}
  border-radius: 12px;
  cursor: pointer;

  @keyframes slideup {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-3px);
    }
  }
  @keyframes slidedown {
    0% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  :hover {
    transform: translateY(-3px);
    animation-duration: 0.15s;
    animation-name: slideup;
    animation-timing-function: linear;
    .text {
      text-decoration: underline;
    }
  }
  transform: translateY(0px);
  animation-duration: 0.15s;
  animation-name: slidedown;
  animation-timing-function: linear;
`;

export const Title = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
  color: #343741;
`;
