import { User } from '../User/User.entity';

export type Platform = 'android' | 'ios' | 'chromeos';

export class DeviceMapList {
  id: number;
  platform: Platform;
  user: User;

  constructor(data: DeviceMapList.Server) {
    this.id = data.id;
    this.platform = data.platform?.toLocaleLowerCase() as Platform;
    this.user = data.user && new User(data.user);
  }
}

export namespace DeviceMapList {
  export type Server = {
    id: number;
    platform: Platform;
    user: User.Server;
  };
}
