import type { NewsCardProps } from '../page-home.contracts';
import * as style from './news-card.style';

export function NewsCard({ image, date, title, text, link, is_new_feature, is_beta, t, history }: NewsCardProps) {
  return (
    <style.Wrapper onClick={() => history(link)}>
      <style.ImageContainer>
        <img src={image} alt={title} />
        {(is_new_feature || is_beta) && (
          <style.Ribbon>
            <h4>{is_beta ? 'Beta' : t('components.home-news-card.new-feature')}</h4>
          </style.Ribbon>
        )}
      </style.ImageContainer>

      <style.Texts>
        <style.Date>{date}</style.Date>
        <h4 className="theme-color">{title}</h4>
        <p>{text}</p>
      </style.Texts>
    </style.Wrapper>
  );
}
