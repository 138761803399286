import { EuiCallOut } from '@elastic/eui';
import { GenericMap } from 'components';
import { ComponentDashboardCard } from 'components/dashboard-card/component-dashboard-card';
import DeveloperBoardOutlinedIcon from '@mui/icons-material/DeveloperBoardOutlined';
import GpsFixedOutlinedIcon from '@mui/icons-material/GpsFixedOutlined';
import BatteryFullOutlinedIcon from '@mui/icons-material/BatteryFullOutlined';
import DeveloperModeOutlinedIcon from '@mui/icons-material/DeveloperModeOutlined';
import SettingsCellOutlinedIcon from '@mui/icons-material/SettingsCellOutlined';
import BatteryAlertOutlinedIcon from '@mui/icons-material/BatteryAlertOutlined';
import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import SdCardIcon from '@mui/icons-material/SdCard';
import { ConstantColors } from 'utilities';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/use-query';
import { useEffect } from 'react';
import i18next from 'i18next';
import MapDeviceLocalization from './map-device-localization';
import type { InformationTabProps } from './page-device-details.contracts';
import * as style from './page-device-details.style';

export function TabDeviceInformations({
  cpuInfo,
  groupName,
  groupId,
  serialNumber,
  agentSpecificVersion,
  platform,
  deviceId,
  battery_level,
  gps_on,
  total_memory,
  available_memory,
  pendingAppsLength,
  installAppsLength,
}: InformationTabProps) {
  const backgroundColorCard = ConstantColors.BRAZIL;

  const { t } = useTranslation();
  const query = useQuery();
  const language = query.get('language');
  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);
  function availableMemory() {
    return Number(((available_memory * 100) / total_memory).toFixed(1));
  }

  function unavailableMemory() {
    return Number((100 - availableMemory()).toFixed(1));
  }

  function convertToMbTotalMemory() {
    return Number((total_memory / 10000000).toFixed(2));
  }

  function convertToMbAvailableMemory() {
    return Number((available_memory / 10000000).toFixed(2));
  }

  return (
    <style.TabsContainer>
      <div style={{ display: 'grid', gap: '60px', gridTemplateColumns: '1fr 1fr', width: '80%' }}>
        <div>
          <style.DeviceInfoText>
            <span className="label">{t('pages.device_management_item.item_title')}</span>
            <span className="label">
              {cpuInfo && cpuInfo.length ? (
                cpuInfo
              ) : (
                <EuiCallOut
                  title={`${t('pages.device_management_item.item_text')} ${agentSpecificVersion}`}
                  color="warning"
                  iconType="help"
                  style={{ width: '380px', marginTop: '15px' }}
                >
                  <p>{t('pages.device_management_item.item_second_text')}</p>
                </EuiCallOut>
              )}
            </span>
          </style.DeviceInfoText>
          <style.DeviceInfoText>
            <span className="label">LAUNCHER</span>
            <span className="text">
              {platform || '---'}
              **
            </span>
          </style.DeviceInfoText>
          <style.DeviceInfoText>
            <span className="label">{t('pages.device_management_item.item_serie_number')}</span>
            <span className="text">{serialNumber || '---'}</span>
          </style.DeviceInfoText>
          <style.DeviceInfoText>
            <span className="label">{t('pages.device_management_item.item_group')}</span>

            <style.GroupNameButton
              onClick={() => {
                window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_HOST}/groups/${groupId}`;
              }}
            >
              {groupName || '---'}
            </style.GroupNameButton>
          </style.DeviceInfoText>
          <style.DeviceInfoText>
            <span className="label">{t('pages.device_management_item.item_version')}</span>
            <span className="text">{agentSpecificVersion || '---'}</span>
          </style.DeviceInfoText>
          <style.DeviceInfoText>
            <span className="label">{t('pages.device_management_item.item_config')}</span>
            <span className="text">Configuração do Diogo ***</span>
          </style.DeviceInfoText>
          <style.DeviceInfoText>
            <span className="label">{t('pages.device_management_item.item_password')}</span>
            <span className="text">55116 ***</span>
          </style.DeviceInfoText>
        </div>
        <div>
          <section>
            <style.DeviceInfoText>
              <span className="label">{t('pages.device_management_item.item_dashboard')}</span>
            </style.DeviceInfoText>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div style={{ display: 'flex' }}>
                <ComponentDashboardCard
                  icon={<DeveloperBoardOutlinedIcon />}
                  title={t('pages.device_management_item.item_ram')}
                  info="29%*"
                  description={`260,5 MB ${t('pages.device_management_item.item_battery_health_free_ram')} / 902 MB ${t(
                    'pages.device_management_item.item_battery_health_free_total'
                  )} *`}
                  statusColor="#FFB93B"
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
                <ComponentDashboardCard
                  icon={<GpsFixedOutlinedIcon />}
                  title={t('pages.device_management_item.item_gps')}
                  info={gps_on ? t('pages.device_management_item.item_on') : t('pages.device_management_item.item_off')}
                  description=""
                  statusColor={gps_on ? '#00BFB3' : '#FF4C0C'}
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
                <ComponentDashboardCard
                  icon={<BatteryFullOutlinedIcon />}
                  title={t('pages.device_management_item.item_battery')}
                  info={`${battery_level}%`}
                  description={t('pages.device_management_item.item_battery_percent')}
                  // eslint-disable-next-line no-nested-ternary
                  statusColor={battery_level > 60 ? '#00BFB3' : battery_level > 30 ? '#FFB93B' : '#FF4C0C'}
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
                <ComponentDashboardCard
                  icon={<DeveloperModeOutlinedIcon />}
                  title="LAUNCHER"
                  info={`${platform}*`}
                  description=""
                  statusColor="#30A8FF"
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
              </div>
              <div style={{ display: 'flex' }}>
                <ComponentDashboardCard
                  icon={<SettingsCellOutlinedIcon />}
                  title={t('pages.device_management_item.item_apps')}
                  info={`${installAppsLength}`}
                  description={`${pendingAppsLength} ${t('pages.device_management_item.item_battery_pending')}`}
                  statusColor="#30A8FF"
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />

                <ComponentDashboardCard
                  icon={<BatteryAlertOutlinedIcon />}
                  title={t('pages.device_management_item.item_battery_health')}
                  info={t('pages.device_management_item.item_battery_health_info')}
                  description={t('pages.device_management_item.item_battery_health_description')}
                  statusColor="#00BFB3"
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
                <ComponentDashboardCard
                  icon={<FolderOpenOutlinedIcon />}
                  title={t('pages.device_management_item.item_battery_health_storage')}
                  info={`${availableMemory()}%`}
                  description={`${convertToMbAvailableMemory()}MB ${t(
                    'pages.device_management_item.item_battery_health_free_ram'
                  )} / ${convertToMbTotalMemory()}MB ${t('pages.device_management_item.item_battery_health_free_total')}`}
                  statusColor={availableMemory() > 15 ? '#00BFB3' : '#FF4C0C'}
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
                <ComponentDashboardCard
                  icon={<SdCardIcon />}
                  title={t('pages.device_management_item.item_battery_health_free_storage')}
                  info={`${unavailableMemory()}%`}
                  description={`${convertToMbAvailableMemory()}MB livre / ${convertToMbTotalMemory()}MB total`}
                  statusColor={availableMemory() > 15 ? '#00BFB3' : '#FF4C0C'}
                  className="dashboardCardDeviceDetails"
                  background={backgroundColorCard}
                />
              </div>
            </div>
          </section>
          <section>
            <style.DeviceInfoText>
              <span className="label">{t('pages.device_management_item.item_localization')}</span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: '700',
                  fontSize: '14px',
                  lineHeight: '24px',
                  color: '#1A1C21',
                  marginTop: '15px',
                }}
              >
                {t('pages.device_management_item.item_history')}
              </span>
              <span
                style={{
                  fontStyle: 'normal',
                  fontWeight: '400',
                  fontSize: '13px',
                  lineHeight: '21px',
                  color: '#343741',
                  marginTop: '4px',
                  marginBottom: '25px',
                }}
              >
                {t('pages.device_management_item.item_report')}
                <b>{` ID-${deviceId}`}</b>
              </span>
            </style.DeviceInfoText>
            <GenericMap zooms={[15]} mapHeight="200px" mapWidth="550px">
              <MapDeviceLocalization />
            </GenericMap>
          </section>
        </div>
      </div>
    </style.TabsContainer>
  );
}
