import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  min-width: 200px;
  margin: 15px 25px;
  text-align: center;
  color: ${ConstantColors.SPAIN};
  font-size: 14px;
`;

export const ModalSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  gap: 5px;
  margin: 10px 0;

  h3 {
    font-weight: 700;
  }

  button {
    &:hover {
      text-decoration: underline;
    }
  }

  nav {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: center;
    line-height: 24px;
    letter-spacing: 0px;

    a {
      font-weight: normal;
      text-align: center;
      color: ${ConstantColors.SPAIN};
    }
  }
`;

export const SeparationLine = styled.hr`
  width: calc(100% + 50px);
  border-color: ${ConstantColors.BRAZIL};
  opacity: 0.5;
`;
