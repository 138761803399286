import { EuiButtonEmpty } from '@elastic/eui';
import { IconProfile } from 'icons';
import { useTheme } from 'styled-components';
import * as style from './button-profile.style';
import type { ButtonProps } from '../component-application-header.contracts';

export function ButtonProfile({ onClick }: ButtonProps) {
  const theme = useTheme();

  return (
    <EuiButtonEmpty onClick={onClick} data-testid="button-profile">
      <style.Container>
        <IconProfile fill={theme.contrast} width="32px" height="32px" test_id="button-profile-icon" />
      </style.Container>
    </EuiButtonEmpty>
  );
}
