import React from 'react';

type IconMobileDataInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconMobileDataInCicle({ width, height, elipse_fill, icon_fill }: IconMobileDataInCicleProps) {
  return (
    <svg width={width || 44} height={height || 45} viewBox="0 0 44 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.2035" cy="22.5" rx="21.6783" ry="22.5" fill={elipse_fill || '#30A8FF'} />
      <path
        d="M18.8313 13L14.9774 16.99H17.8678V24H19.7948V16.99H22.6852L18.8313 13ZM25.5756 27.01V20H23.6487V27.01H20.7583L24.6122 31L28.4661 27.01H25.5756Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
