/* eslint-disable no-unused-vars */

import type { Management, StatusCode } from 'modules/operational/pages/devices/PageDevices.contracts';

export class DevicesList {
  id: string;

  deviceId: {
    icon: React.ReactNode;
    id: string;
    os: string | null;
  };

  status: string;

  name: string;

  management: Management;

  information: {
    identifier: string;
    model: string;
    serialNumber: string;
  };

  soVersion: string;

  group: {
    name: string;
    id: string;
    launcherId: number | undefined | null;
  };

  lastSync: string;

  battery: {
    level: string;
    temperature: number;
    isIOS: boolean;
  };

  constructor(
    id: string,
    deviceId: { icon: React.ReactNode; id: string; os: string | null },
    status: string,
    name: string,
    management: Management,
    information: { identifier: string; model: string; serialNumber: string },
    soVersion: string,
    group: { name: string; id: string; launcherId: number | undefined | null },
    lastSync: string,
    battery: { level: string; temperature: number; isIOS: boolean }
  ) {
    this.id = id;
    this.deviceId = deviceId;
    this.status = status;
    this.name = name;
    this.management = management;
    this.information = information;
    this.soVersion = soVersion;
    this.group = group;
    this.lastSync = lastSync;
    this.battery = battery;
  }
}

export interface APIDevices {
  total: number;
  custom_field_labels: APICustomFieldLabels[] | null;
  devices: APIDevicesList[];
}

export interface APIDevicesList {
  id: number;
  status: number;
  management_mode: string | null;
  management_type: Management;
  device_owner: boolean | null;
  group_id: number;
  os_version: string | null;
  model: string;
  manufacturer: string;
  serial_number: string;
  identifier: string;
  platform: string | null;
  ownership: string;
  last_heartbeat: LastHeartbeat | null;
  group: Group | null;
  user: User | null;
  ios_info: iOSInfo | null;
  last_contact_at: string | null;
}

export interface APIDeviceStatus {
  status: StatusCode;
  count: number;
}

export interface APIDeviceManagement {
  management: APIManagementCode;
  count: number;
}

export enum APIManagementCode {
  FM = 'FM',
  FM_WP = 'FM_WP',
  WP = 'WP',
  DA = 'DA',
  SU = 'SU',
  BA = 'BA',
  NU = 'NU',
  MSU = 'MSU',
  WS = 'WS',
}

export type APIGroups = {
  name: string;
  work_profile_policy_name: string;
  id: number;
  group_policy_id: number;
};

interface LastHeartbeat {
  id: number;
  recorded_at: string;
  battery_level: number;
  battery_temperature: number;
}

interface Group {
  id: number;
  name: string;
  launcher_id: number | undefined | null;
}

interface iOSInfo {
  id: number;
  supervised: boolean;
}

interface User {
  id: number;
  first_name: string;
  last_name: string;
  full_name: string;
  email: string;
}

interface APICustomFieldLabels {
  id: number;
  label: string;
}
