import React from 'react';
import PropTypes from 'prop-types';

export function IconChart({ width, height, fill }) {
  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 13H13V9.9132L10.4285 7.08653L8.56069 8.75255C8.34446 8.94542 8.01009 8.91469 7.83263 8.68566L4.44774 4.3171L3 6.19115V13ZM8.29492 7.64961L10.1324 6.01068C10.337 5.82815 10.6505 5.84451 10.8351 6.04736L13.8699 9.38334C13.9536 9.47539 14 9.59536 14 9.7198V13.5C14 13.7761 13.7761 14 13.5 14H2.5C2.22386 14 2 13.7761 2 13.5V6.02051C2 5.90988 2.03669 5.80238 2.10432 5.71483L4.05147 3.19433C4.25145 2.93546 4.64204 2.93518 4.8424 3.19376L8.29492 7.64961ZM1 15H14.5C14.7761 15 15 15.2239 15 15.5C15 15.7761 14.7761 16 14.5 16H0.5C0.223858 16 0 15.7761 0 15.5V1.5C0 1.22386 0.223858 1 0.5 1C0.776142 1 1 1.22386 1 1.5V15Z"
        fill={fill}
      />
    </svg>
  );
}

IconChart.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

IconChart.defaultProps = {
  width: '16px',
  height: '16px',
  fill: '#FFFFFF',
};
