import styled, { css } from 'styled-components';
import 'leaflet/dist/leaflet.css';
import search from 'assets/images/search-icon.png';
import { EuiButton } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  position: relative;

  .signals-logo-inner {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-top: 15px;
  }

  .signals-logo-container {
    height: 107px;
    width: 280px;
    background-color: ${ConstantColors.AUSTRIA};
    position: absolute;
    left: 36px;
    top: 16px;
    border: 0.807692px solid ${ConstantColors.HUNGARY};
    border-radius: 8.07692px;

    img {
      margin-top: 5px;
      margin-right: 5px;
    }

    span {
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 27px;
      color: ${({ theme }) => theme.main};
    }
  }
  .leaflet-control-geocoder {
    border: 0.807692px solid ${ConstantColors.HUNGARY};
    margin-left: 60px;
    margin-top: 76px;

    input {
      height: 32px;
      width: 190px;
      border-radius: 4.84615px;
      padding: 5px;
    }

    button {
      background-image: url(${search});
      background-size: 60%;
    }
  }
`;

export const NetworkControls = styled.div`
  ${({ theme }) => css`
    position: absolute;
    right: 10px;
    top: 20px;
    padding: 20px 10px;
    border-radius: 8px;
    background-color: ${ConstantColors.AUSTRIA};

    box-shadow:
      0 1px 1px -1px rgb(152 162 179 / 20%),
      0 3px 2px -2px rgb(152 162 179 / 20%),
      inset 0 0 0 1px rgb(15 39 118 / 10%);
    z-index: 900;
    .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
      border-color: ${theme.main} !important;
      background-color: ${theme.main} !important;

      @keyframes focusRingAnimate {
        0% {
          box-shadow: 0 0 0 6px ${theme.main}00;
        }
        100% {
          box-shadow: 0 0 0 3px ${theme.main}60;
        }
      }
    }
    legend {
      width: 100%;
    }
    p {
      font-size: 10px;
      font-weight: 700;
      text-align: center;
      color: ${ConstantColors.UKREIN};
    }
  `}
`;
export const Legends = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  width: 125px;
  height: 170px;
  position: absolute;
  right: 10px;
  bottom: 30px;
  padding: 20px 10px;
  border-radius: 8px;
  background-color: ${ConstantColors.AUSTRIA};

  box-shadow:
    0 1px 1px -1px rgb(152 162 179 / 20%),
    0 3px 2px -2px rgb(152 162 179 / 20%),
    inset 0 0 0 1px rgb(15 39 118 / 10%);
  z-index: 900;

  legend {
    display: flex;
    flex-direction: column;
    align-items: center;

    .number {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 16px;
    }
  }

  h4 {
    font-style: normal;
    font-weight: 700;
    font-size: 10.2889px;
    line-height: 12px;
  }

  .legends-container {
    display: flex;
    height: 100px;
    align-items: flex-end;

    .zero-signal-level {
      width: 18px;
      height: 10px;
      background: #80808080;
    }

    .one-signal-level {
      width: 18px;
      height: 30px;
      background: #de212180;
    }

    .two-signal-level {
      width: 18px;
      height: 50px;
      background: #ff7e0080;
    }
    .three-signal-level {
      width: 18px;
      height: 70px;
      background: #c0e00080;
    }

    .four-signal-level {
      width: 18px;
      height: 90px;
      background: #00800080;
    }
  }
`;

export const LeafletLogo = styled.div`
  position: absolute;
  right: 10px;
  bottom: 1px;
  background-color: ${ConstantColors.AUSTRIA};
  opacity: 0.5;
  z-index: 900;
  padding: 1px 5px;
  border-radius: 5px;
`;

export const Provider = styled.div`
  position: absolute;
  width: 240px;
  z-index: 900;
  left: 34%;
  top: 16px;
  pointer-events: visiblePainted;

  select {
    z-index: 1000;
    border-radius: 8px;
    font-weight: 500;
  }
`;

export const Months = styled.div`
  position: absolute;
  width: 245px;
  z-index: 900;
  right: 11%;
  top: 16px;
  pointer-events: visiblePainted;

  select {
    z-index: 1000;
    border-radius: 8px;
    font-weight: 500;
  }
`;

export const HasNoPoints = styled.div`
  position: absolute;
  height: 65px;
  width: 470px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
  margin: auto;
  inset: 0;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 10px;

  p {
    color: ${ConstantColors.AUSTRIA};
  }
`;

export const PlaceHolder = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.65);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FilterButton = styled(EuiButton)`
  position: absolute;
  bottom: 40px;
  right: 40%;
  height: 35px;
  width: 324px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${ConstantColors.ECUADOR}!important;
  background: ${ConstantColors.AUSTRIA} !important;
  border: 0.807692px solid ${ConstantColors.HUNGARY} !important;
  box-shadow: 0 2px 2px -1px rgba(54, 97, 126, 0.5) !important;
  border-radius: 47px !important;
`;
