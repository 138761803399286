import moment from 'moment';
import 'moment/locale/pt-br';
import 'moment/locale/es';
import type { operatorNetworkProps } from 'pages/pulsus-insights-signals/page-insights-signal.contracts';

export class MapOption {
  public year: string;

  public monthNumber: string;

  public monthName: string;

  public text: string;

  public value: string;

  public operatorNetwork: operatorNetworkProps[];

  constructor(year: string, month: string, operatorNetwork: operatorNetworkProps[], language: string) {
    this.year = year;
    this.monthNumber = month;
    this.monthName = moment(this.monthNumber).locale(language).format('MMMM');
    this.text = `${this.monthName} (${this.year})`;
    this.value = `${this.monthNumber}-(${this.year})`;
    this.operatorNetwork = operatorNetwork;
  }
}
