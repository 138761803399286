/* eslint-disable react/jsx-one-expression-per-line */
import { IconWarning } from 'icons/warning/icon-warning';
import { useEffect, useState } from 'react';
import { ConstantColors } from 'utilities';
import sadGirl from 'assets/images/device-admin-deprecated.png';
import { useTypedTranslation } from 'modules/core/hooks';
import * as S from './ModalDeprecatedComponent.styles';
import type { ModalDeprecatedComponentProps } from './ModalDeprecatedComponent.contracts';

export function ModalDeprecatedComponent({ isVisible }: ModalDeprecatedComponentProps) {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { t } = useTypedTranslation<'devices.modal_deprecated'>('devices.modal_deprecated');

  useEffect(() => {
    setIsModalVisible(isVisible);
  }, [isVisible]);

  return (
    <>
      {isModalVisible && (
        <S.Container>
          <S.Content onClose={() => setIsModalVisible(false)} initialFocus="[name=popswitch]">
            <S.Body>
              <S.LeftInfo>
                <div className="icon" style={{ backgroundColor: ConstantColors.ANDORRA }}>
                  <IconWarning width={20} height={20} />
                  <p>DA</p>
                </div>
                <h1>{t('attention')}</h1>
                <div>
                  <h2>{t('title')}</h2>
                  <h4>{t('subtitle')}</h4>
                </div>
                <p>
                  {t('text1')}
                  <b>”Device Admin”</b>
                  {t('text2')}
                </p>
              </S.LeftInfo>
              <img src={sadGirl} alt="A sad device admin fangirl" />
            </S.Body>
          </S.Content>
        </S.Container>
      )}
    </>
  );
}
