import styled from 'styled-components';
import { EuiModal, EuiButton } from '@elastic/eui';

export const Modal = styled(EuiModal)`
  margin-bottom: 27vh; //with iframer

  .euiModal__flex {
    max-height: fit-content;
    height: 515px;
    width: 851px;
  }

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: ${({ theme }) => theme.main} !important;
    background-color: ${({ theme }) => theme.main} !important ;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 50px;
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${({ theme }) => theme.main};
  margin-bottom: 18px;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  text-align: center;
  color: #67758d;
  margin-bottom: 18px;
`;

export const BodyModal = styled.main`
  display: flex;
  width: 100%;
  justify-content: space-around;
  gap: 80px;
  margin-bottom: 35px;
  margin-top: 60px;

  p {
    font-size: 12px;
  }
`;

export const LeftContainer = styled.section`
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  width: 100%;
`;

export const RadioContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  .icon-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    h1 {
      margin-top: 10px;
      font-size: 30px;
    }
  }
`;

export const RightContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;

    .euiBetaBadge {
      width: 104px;
    }
    small {
      display: block;
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
      color: #bfbfbf;
    }
  }
`;

export const FooterModal = styled.footer`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const SwitchContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: flex-start;
  gap: 10px;

  .euiSwitch__button[aria-checked='true'] .euiSwitch__body {
    background-color: ${({ theme }) => theme.main} !important;
  }

  .euiSwitch .euiSwitch__label {
    font-size: 12px !important;
  }

  .euiIcon-isLoaded {
    margin-top: 2px;
  }
`;

export const Button = styled(EuiButton)`
  min-width: 136px;
  min-height: 39px;
  background: ${({ theme }) => theme.main} !important;
  border-color: ${({ theme }) => theme.main} !important;
`;
