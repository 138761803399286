import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: black;
`;

export const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

export const Side = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 520px;
  height: 100%;
  gap: 25px;
  width: 50%;
`;
