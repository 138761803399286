/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable array-callback-return */
import { EuiButton, EuiButtonEmpty, EuiIcon, EuiSwitch } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import { IconAndroid } from 'icons';
import { ConstantColors } from 'utilities';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import * as style from './certificate-variables-modal.style';
import type { CertificateVariablesModalProps } from '../page-certificate.contracts';

export function CertificateVariablesModal({ onClose, openFinishedModal, certificateId, certificateName }: CertificateVariablesModalProps) {
  const [isAuthorizedCertificate, setIsAuthorizedCertificate] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [permissionsList, setPermissionsList] = useState<never | string[]>([]);

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const handleOnClose = () => {
    onClose();
  };

  const cancelForm = () => {
    onClose();
  };

  const save = () => {
    servicePulsusWeb.saveCertificatePackages(certificateId, isAuthorizedCertificate, permissionsList).then(() => {
      onClose();
      openFinishedModal();
    });
  };

  const removePermission = (name: string) => {
    const newList = permissionsList.filter((item) => {
      return !item.includes(name);
    });

    setPermissionsList(newList);
  };

  const addPermission = (name: string) => {
    if (name) {
      setPermissionsList([name, ...permissionsList]);
    }
  };

  useEffect(() => {
    servicePulsusWeb.fetchCertificatePackages(certificateId).then((response) => {
      setIsAuthorizedCertificate(response.authorize_package_access);
      setPermissionsList(response.packages);
    });
  }, []);

  return (
    <style.Modal onClose={handleOnClose} maxWidth="880px">
      <style.Content>
        <style.Title>Acesso automático ao certificado {certificateName}</style.Title>
        <EuiSwitch
          label="Autorizar silenciosamente acesso a este certificado pelos aplicativos abaixo"
          checked={isAuthorizedCertificate}
          onChange={() => setIsAuthorizedCertificate(!isAuthorizedCertificate)}
        />

        <style.Subtitle>Inclua os aplicativos que devem acesar este certificado silenciosamente:</style.Subtitle>

        <style.PermissionsContainer>
          <style.FieldSearch
            disabled={!isAuthorizedCertificate}
            placeholder="Digite o nome do pacote"
            fullWidth
            append={
              <EuiButton disabled={!isAuthorizedCertificate} onClick={() => addPermission(searchValue)} fill size="m">
                Adicionar
              </EuiButton>
            }
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            isClearable
            aria-label="search installed apps"
          />

          <style.PermissionsList isDisabled={!isAuthorizedCertificate}>
            {permissionsList.map((item) => {
              return (
                <style.Permission key={item}>
                  <div>
                    <IconAndroid width="15px" height="19px" fill={ConstantColors.ITALY} />
                    <p>{item}</p>
                  </div>
                  <EuiIcon onClick={() => removePermission(item)} size="m" color={ConstantColors.ITALY} type="minusInCircleFilled" />
                </style.Permission>
              );
            })}
          </style.PermissionsList>
        </style.PermissionsContainer>
        <style.SubmitContainer>
          <div />
          <div>
            <EuiButtonEmpty onClick={cancelForm}>Cancelar</EuiButtonEmpty>
            <style.Button onClick={save}>Salvar</style.Button>
          </div>
        </style.SubmitContainer>
      </style.Content>
    </style.Modal>
  );
}
