import styled, { css } from 'styled-components';

export const ModalsFooter = styled.div`
  ${({ theme }) => css`
    margin-top: 20px;
    display: flex;
    gap: 20px;

    .euiButton {
      background-color: ${theme.main} !important;
      border: none;

      &:hover {
        filter: brightness(0.9);
        background-color: ${theme.main};
        border: none;
      }
    }

    .euiButton__text {
      color: #fff;
    }
  `}
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.p`
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 24px;
  text-align: left;
  color: ${({ theme }) => theme.main};
`;
