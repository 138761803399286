import type { FilterProps, MapsContextProps, MapsProviderProps } from './MapsProvider.contracts';
import moment from 'moment-timezone';
import { createContext, useContext, useState } from 'react';

export const MapsContext = createContext<MapsContextProps>({} as MapsContextProps);
export const useMapsContext = (): MapsContextProps => useContext(MapsContext);

export const MapsProvider = ({ children }: MapsProviderProps) => {
  const initialState: FilterProps = {
    deviceIds: undefined,
    groupIds: undefined,
    dateFilter: moment().utcOffset(0).format('YYYY-MM-DDTHH:mm:ss'),
    hourRange: '3',
    isAllGroupsSelected: false,
    currentSearchDate: true,
  };

  const [filter, setFilterState] = useState<FilterProps>(initialState);

  const setFilter = (newFilter: Partial<FilterProps>) => {
    setFilterState((prevFilter) => ({
      ...prevFilter,
      ...newFilter,
    }));
  };

  return <MapsContext.Provider value={{ setFilter, filter }}>{children}</MapsContext.Provider>;
};
