import React from 'react';
import type { SecuritySelectBoxProps } from './security-select-box.contracts';
import * as style from './security-select-box.style';

export const SelectBox = (props: SecuritySelectBoxProps) => {
  const { options, label, additionalText, required, fullWidth, disabled, fieldDescription, warningText, value, onChange, id } = props;
  return (
    <style.SelectContainer data-testid="select-container">
      <style.TitleGroup data-testid="select-title-group">
        {label && <style.SelectLabel data-testid="select-lebel">{label}</style.SelectLabel>}
        {required && <style.RequiredIcon data-testid="select-required-icon">*</style.RequiredIcon>}
        {additionalText && <style.InfoButton data-testid="select-additional-text">{additionalText}</style.InfoButton>}
      </style.TitleGroup>
      <style.FieldGroup data-testid="select-field-group" active={!!fieldDescription}>
        <div>
          <style.SelectField
            data-testid="select-field"
            id={id}
            fullWidth={fullWidth}
            value={value || undefined}
            options={options}
            disabled={disabled}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
        {fieldDescription && <style.SelectFieldDescription data-testid="select-field-description">{fieldDescription}</style.SelectFieldDescription>}
      </style.FieldGroup>
      {warningText && (
        <style.WarningGroup data-testid="select-warning-group">
          <style.WarningIcon type="iInCircle" />
          <style.WarningMessage data-testid="select-warning-message">{warningText}</style.WarningMessage>
        </style.WarningGroup>
      )}
    </style.SelectContainer>
  );
};
