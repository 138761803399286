import axios from 'axios';
import { ConstantServicesEndpoints } from 'utilities';
import { HelperAuthentication } from 'helpers';
import { Geofence } from 'modules/management/entities/geofence';
import { FenceId } from 'entities/fence-id';
import { Group } from 'entities/group';
import type { AxiosInstance } from 'axios';

export class ServicePulsusValquiria {
  static instance: AxiosInstance;

  static getInstancePulsusWeb() {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: process.env.REACT_APP_URL_PULSUS_WEB_V4,
        headers: {
          Authorization: HelperAuthentication.bearer(),
          'X-Schema': HelperAuthentication.decodedSchemaName(),
        },
      });
    }

    return this.instance;
  }

  static getValquiria() {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
        headers: {
          Authorization: HelperAuthentication.bearer(),
          'X-Schema': HelperAuthentication.decodedSchemaName(),
        },
      });
    }

    return this.instance;
  }

  static async getAllGeofences(pageIndex) {
    const response = await this.getInstancePulsusWeb().get(`${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCES_AREAS}?page=${pageIndex}`);

    return response.data.areas.map((item) => new Geofence({ ...item, total_pages: response.data.total_pages }));
  }

  static async editGeofenceById(newStatus, id) {
    return this.getInstancePulsusWeb().patch(`${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCES_AREAS}/${id}`, {
      status: newStatus,
    });
  }

  static async uploadGeofenceAreas(file) {
    const data = new FormData();
    data.append('file', file);
    return this.getInstancePulsusWeb().post(ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCE_FILE_UPLOAD, data);
  }

  static async downloadStandardCSV() {
    return this.getInstancePulsusWeb().get(`${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCE_STANDARD_CSV_DOWNLOAD}`, {
      responseType: 'blob',
    });
  }

  static async editAllGeofences(newList) {
    return this.getInstancePulsusWeb().patch(`${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCES_UPDATE_GLOBAL}`, {
      areas: newList,
    });
  }

  static async getAllActions(tenant_id) {
    const response = await this.getValquiria().get(
      `https://valquiria-test.pulsus.mobi${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCES_ACTIONS}/${tenant_id || '2'}`
    );

    return response.data.geofence_actions;
  }

  static async getLinkedGroupsByFence(tenant_id: string, fenceId) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization:
          window.self !== window.top
            ? HelperAuthentication.bearer()
            : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRfaWQiOjc2MiwiYWRtaW5pc3RyYXRvcl9pZCI6Mzc5LCJleHAiOjE2OTE3NzQyMTQsIm5iZiI6MTY2MDIzODIxNH0.YUdgNbTMFNH3kg87tqLqCtmckk0VyaP-iam2LU8TkDg',
        'X-Schema': HelperAuthentication.decodedSchemaName() || process.env.REACT_APP_TENANT,
      },
    });

    const response = await axiosInstance.get(`/area/group_count/${tenant_id}/${fenceId}`);
    return response.data.groups;
  }

  static async getFencesIds(tenant_id) {
    const response = await this.getValquiria().get(`/areas/${tenant_id}`);

    return response.data.geofence_areas.map((item) => {
      return new FenceId(item.id, item.name);
    });
  }

  static async getAllGeofencesWithGroup(pageIndex) {
    const response = await this.getAllGeofences(pageIndex);
    return response;
  }

  static async editGeofenceDescription(tenantId, geofenceAreaId, newDescription) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization:
          window.self !== window.top
            ? HelperAuthentication.bearer()
            : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRfaWQiOjc2MiwiYWRtaW5pc3RyYXRvcl9pZCI6Mzc5LCJleHAiOjE2OTE3NzQyMTQsIm5iZiI6MTY2MDIzODIxNH0.YUdgNbTMFNH3kg87tqLqCtmckk0VyaP-iam2LU8TkDg',
        'X-Schema': HelperAuthentication.decodedSchemaName() || process.env.REACT_APP_TENANT,
      },
    });

    await axiosInstance.patch(`/area/${tenantId}/${geofenceAreaId}`, {
      description: newDescription,
    });
  }

  static async updateFences(tenantId, groupPolicyId, body) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization:
          window.self !== window.top
            ? HelperAuthentication.bearer()
            : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRfaWQiOjc2MiwiYWRtaW5pc3RyYXRvcl9pZCI6Mzc5LCJleHAiOjE2OTE3NzQyMTQsIm5iZiI6MTY2MDIzODIxNH0.YUdgNbTMFNH3kg87tqLqCtmckk0VyaP-iam2LU8TkDg',
        'X-Schema': HelperAuthentication.decodedSchemaName() || process.env.REACT_APP_TENANT,
      },
    });

    await axiosInstance.patch(`/group_policy/${tenantId}/${groupPolicyId}`, body);
  }

  static async getAllGroups(tenantId, geofence) {
    try {
      const response = await this.getLinkedGroupsByFence(tenantId, geofence.id);
      const adapteeGroups = response?.map((group) => new Group(group?.id, group?.name, group?.total_devices));

      return { ...geofence, groups: adapteeGroups || [] };
    } catch (err) {
      return { ...geofence, groups: [] };
    }
  }

  static async getRegisters(tenantId, page) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization:
          window.self !== window.top
            ? HelperAuthentication.bearer()
            : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRfaWQiOjc2MiwiYWRtaW5pc3RyYXRvcl9pZCI6Mzc5LCJleHAiOjE2OTE3NzQyMTQsIm5iZiI6MTY2MDIzODIxNH0.YUdgNbTMFNH3kg87tqLqCtmckk0VyaP-iam2LU8TkDg',
        'X-Schema': HelperAuthentication.decodedSchemaName() || process.env.REACT_APP_TENANT,
      },
    });
    const response = await axiosInstance.get(`/register/${tenantId}?page=${page}&limit=25`);

    return {
      registers: response.data?.geofence_registers,
      page: response.data?.current_page,
      limit: response.data?.page_limit,
      totalPages: response.data?.total_pages,
    };
  }

  static async updateArea(tenantId, areaId, body) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization:
          window.self !== window.top
            ? HelperAuthentication.bearer()
            : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRfaWQiOjc2MiwiYWRtaW5pc3RyYXRvcl9pZCI6Mzc5LCJleHAiOjE2OTE3NzQyMTQsIm5iZiI6MTY2MDIzODIxNH0.YUdgNbTMFNH3kg87tqLqCtmckk0VyaP-iam2LU8TkDg',
        'X-Schema': HelperAuthentication.decodedSchemaName() || process.env.REACT_APP_TENANT,
      },
    });
    await axiosInstance.patch(`/area/${tenantId}/${areaId}`, body);
  }

  static async getGroupsByTenant(tenantId) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization:
          window.self !== window.top
            ? HelperAuthentication.bearer()
            : 'Bearer eyJhbGciOiJIUzI1NiJ9.eyJ0ZW5hbnRfaWQiOjc2MiwiYWRtaW5pc3RyYXRvcl9pZCI6Mzc5LCJleHAiOjE2OTE3NzQyMTQsIm5iZiI6MTY2MDIzODIxNH0.YUdgNbTMFNH3kg87tqLqCtmckk0VyaP-iam2LU8TkDg',
        'X-Schema': HelperAuthentication.decodedSchemaName() || process.env.REACT_APP_TENANT,
      },
    });
    const response = await axiosInstance.get(`/groups/${tenantId}`);

    return response.data.map((group) => new Group(group.id, group.name));
  }

  static async getFencesByGroupPolicyId(tenantId, policyId, page) {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization: HelperAuthentication.bearer(),
        'X-Schema': HelperAuthentication.decodedSchemaName(),
      },
    });

    const policyUrl = `?group_policy_id=${policyId}&page=${page || 1}&limit=25`;

    const response = await axiosInstance.get(`/group_policy/${tenantId}${policyUrl}`);

    const fences = response.data.geofence_areas.map((fence, index) => new Geofence({ ...fence, index }));

    return {
      fences: [...fences],
      page: response.data?.current_page,
      limit: response.data?.page_limit,
      totalPages: response.data?.total_pages,
      groupPolicy: response.data.group_policy ? { ...response.data.group_policy } : null,
    };
  }

  static async createNewAction(
    tenant_id,
    name,
    trigger,
    send_email_notification,
    notified_emails,
    send_web_notification,
    block_device,
    group_body,
    fence_body
  ) {
    return this.getValquiria().post(
      `https://valquiria-test.pulsus.mobi${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GEOFENCES_ACTIONS}/${tenant_id}`,
      {
        action_body: {
          name,
          trigger,
          send_email_notification,
          notified_emails,
          send_web_notification,
          block_device,
        },
        group_body,
        fence_body,
      }
    );
  }

  static async editGroupPolicyGeofence(tenantId, groupPolicyId, groupPolicyBody, addAreaId) {
    return this.getInstancePulsusWeb().patch(`${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GROUP_POLICY}/${tenantId}/${groupPolicyId}`, {
      group_policy_body: {
        groupPolicyBody,
      },
      add_area_id: addAreaId,
    });
  }

  static async getAdminEmails() {
    const axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_URL_PULSUS_VALQUIRIA_API,
      headers: {
        Authorization: HelperAuthentication.bearer(),
        'X-Schema': HelperAuthentication.decodedSchemaName(),
      },
    });
    const response = await axiosInstance.get(`${ConstantServicesEndpoints.PULSUS_VALQUIRIA.GET_ADMIN_EMAILS}`);
    const adminEmails = response.data;

    return adminEmails;
  }
}
