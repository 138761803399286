import React from 'react';
import PropTypes from 'prop-types';

export function IconInformation({ width, height, fill, test_id }) {
  return (
    <svg data-testid={test_id} id="icon-information" width={width} height={height} viewBox="0 0 15 17" fill={fill} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.8503 6.40796C7.48655 6.40796 7.22791 6.57467 7.22791 6.82033V10.163C7.22791 10.3736 7.48658 10.5842 7.8503 10.5842C8.19787 10.5842 8.48077 10.3736 8.48077 10.163V6.82033C8.48077 6.57467 8.19787 6.40796 7.8503 6.40796Z"
        fill={fill}
      />
      <path
        d="M7.85817 4.31982C7.50461 4.31982 7.22791 4.60894 7.22791 4.94187C7.22791 5.27479 7.50461 5.57269 7.85817 5.57269C8.20406 5.57269 8.48077 5.27479 8.48077 4.94187C8.48077 4.60897 8.20404 4.31982 7.85817 4.31982Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.963623 7.43534C0.963623 3.86689 4.0456 0.979004 7.85436 0.979004C11.6606 0.979004 14.7451 3.86463 14.7451 7.43534C14.7451 10.3479 12.7 12.7682 9.93984 13.5903L8.37276 15.7558C8.25618 15.9169 8.06202 16.0133 7.85436 16.0133C7.6467 16.0133 7.45254 15.9169 7.33596 15.7558L5.76888 13.5903C3.00995 12.7686 0.963623 10.3492 0.963623 7.43534ZM9.0283 12.7578C9.11146 12.6429 9.23534 12.5593 9.37801 12.5219C11.7238 11.907 13.4922 9.88397 13.4922 7.43528C13.4922 4.52288 10.9631 2.1535 7.85437 2.1535C4.74565 2.1535 2.21649 4.52288 2.21649 7.43528C2.21649 9.88153 3.98231 11.9063 6.33073 12.5219C6.4734 12.5593 6.59728 12.6429 6.68047 12.7578L7.85437 14.3799L9.0283 12.7578Z"
        fill={fill}
      />
    </svg>
  );
}

IconInformation.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
  test_id: PropTypes.string,
};

IconInformation.defaultProps = {
  width: '16px',
  height: '16px',
  fill: '#FFFFFF',
  test_id: 'icons__icon-information',
};
