import type { RouteObject } from 'react-router-dom';
import { PageGeofence, PageLocations, PageSimManagement } from '../pages';

export const ManagementDefaultRoutes: RouteObject[] = [
  {
    path: 'sim-management',
    element: <PageSimManagement />,
  },
  {
    path: 'locations',
    element: <PageLocations />,
  },
  {
    path: 'locations/geofence',
    element: <PageGeofence />,
  },
];
