/* eslint-disable react/jsx-one-expression-per-line */
import { DialogComponent } from 'components';
import type { BulkSuccessModalProps } from './BulkSuccessModal.contracts';
import * as S from './BulkSuccessModal.style';
import { EuiButtonEmpty } from '@elastic/eui';
import { useTypedTranslation } from 'modules/core/hooks';
import Success from 'assets/images/success.svg';

export const BulkSuccessModal = ({ isOpen, close }: BulkSuccessModalProps) => {
  const { t } = useTypedTranslation<'devices.modal_select_all_success'>('devices.modal_select_all_success');

  // eslint-disable-next-line no-unused-vars
  const renderModalsFooter = () => {
    return (
      <S.ModalsFooter>
        <EuiButtonEmpty onClick={close}>{t('close')}</EuiButtonEmpty>
      </S.ModalsFooter>
    );
  };

  return (
    <DialogComponent width="600px" open={isOpen} footer={renderModalsFooter()} closeOnClickOutside onClose={() => close()}>
      <S.Content>
        <S.Text>{t('title')}</S.Text>
        <S.Img src={Success} alt="atention" />
      </S.Content>
    </DialogComponent>
  );
};
