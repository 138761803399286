/* eslint-disable react/jsx-one-expression-per-line */
import * as S from './OTAUpdateComponent.styles';
import type { OTAUpdateComponentProps } from './OTAUpdateComponent.contracts';
import { DialogComponent } from 'components';
import { useTypedTranslation } from 'modules/core/hooks';
import type { EuiSelectOption } from '@elastic/eui';
import { EuiButton, EuiButtonEmpty, EuiFieldText, EuiFormRow, EuiIconTip, EuiSelect } from '@elastic/eui';
import { useEffect, useMemo, useState } from 'react';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';

export function OTAUpdateComponent({ open, setOpen }: OTAUpdateComponentProps) {
  const { t } = useTypedTranslation<'devices.bulk_modals'>('devices.bulk_modals');

  const [downloadUrl, setDownloadUrl] = useState('');
  const [buildTime, setBuildTime] = useState('');
  const [checkSum, setCheckSum] = useState('');
  const [model, setModel] = useState('');

  const [models, setModels] = useState<EuiSelectOption[]>([{ value: '', text: t('OTAUpdate.select'), disabled: true }]);

  const [loading, setLoading] = useState(false);
  const [toast, setToast] = useState<Toast[]>([]);

  const lokiService = useMemo(() => new ServicePulsusLoki(), []);

  const getModels = async () => {
    try {
      const serverModels = await lokiService.getModels();

      const adapteeModels = serverModels.map((modelItem) => ({ value: modelItem, text: modelItem }));
      setModels([...models, ...adapteeModels]);
    } catch (error) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '2',
          title: t('OTAUpdate.error.title'),
          text: t('OTAUpdate.error.errorSearchModel'),
        },
      ]);
    }
  };

  const clearInputs = () => {
    setDownloadUrl('');
    setBuildTime('');
    setCheckSum('');
    setModel('');
  };

  const fetchInfos = async () => {
    try {
      setLoading(true);
      await lokiService.batchOTAUpdate({ buildTime, checkSum, downloadUrl, model });
      setToast([
        {
          color: 'success',
          iconType: 'faceHappy',
          id: '1',
          title: t('OTAUpdate.success.title'),
          text: t('OTAUpdate.success.description'),
        },
      ]);
      clearInputs();
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('OTAUpdate.error.title'),
          text: t('OTAUpdate.error.description'),
        },
      ]);
    } finally {
      setOpen();
      setLoading(false);
    }
  };

  useEffect(() => {
    getModels();
  }, []);

  const handleClose = () => {
    clearInputs();
    setOpen();
  };

  const renderOTAFooter = () => {
    const isDisabled = [downloadUrl, buildTime, checkSum, model].includes('');

    return (
      <S.ModalOTAFooter>
        <span>*{t('OTAUpdate.required')}</span>
        <S.ModalsFooter>
          <EuiButtonEmpty onClick={handleClose}>{t('cancel_button')}</EuiButtonEmpty>
          <EuiButton disabled={isDisabled} fill isLoading={loading} onClick={() => fetchInfos()}>
            {t('message_modal.cta_button')}
          </EuiButton>
        </S.ModalsFooter>
      </S.ModalOTAFooter>
    );
  };

  return (
    <>
      <DialogComponent
        width="650px"
        open={open}
        footer={renderOTAFooter()}
        closeOnClickOutside
        onClose={() => handleClose()}
        title={t('OTAUpdate.title')}
      >
        <S.OTAContent>
          <S.WrapperInput>
            <EuiFormRow error fullWidth label={t('OTAUpdate.url')}>
              <EuiFieldText fullWidth aria-label="URL download" value={downloadUrl} onChange={(e) => setDownloadUrl(e.target.value)} />
            </EuiFormRow>

            <EuiFormRow
              fullWidth
              label={t('OTAUpdate.buildTime')}
              labelAppend={<EuiIconTip size="m" type="questionInCircle" color="warning" content={t('OTAUpdate.tip')} />}
            >
              <EuiFieldText fullWidth type="number" aria-label="Build time" value={buildTime} onChange={(e) => setBuildTime(e.target.value)} />
            </EuiFormRow>
          </S.WrapperInput>
          <S.WrapperInput>
            <EuiFormRow fullWidth label={t('OTAUpdate.checkSum')}>
              <EuiFieldText fullWidth aria-label="Send message" value={checkSum} onChange={(e) => setCheckSum(e.target.value)} />
            </EuiFormRow>

            <EuiFormRow fullWidth label={t('OTAUpdate.model')}>
              <EuiSelect options={models} value={model} onChange={(e) => setModel(e.target.value)} />
            </EuiFormRow>
          </S.WrapperInput>
        </S.OTAContent>
      </DialogComponent>

      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
}
