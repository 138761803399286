import { EuiLink } from '@elastic/eui';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  padding: 0 36px;
  background: ${({ theme }) => theme.palette.INDONESIA};
`;

export const Date = styled.time`
  font-size: 12px;
  font-weight: 700;
`;

export const StyledLink = styled(EuiLink)`
  display: flex;
  align-items: center;
  gap: 4px;
  color: #69707d !important;
  font-weight: 500 !important;
`;
