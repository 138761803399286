export const ConstantColors = {
  ARGENTINA: '#F15A22',
  QATAR: '#EB996E',
  PALESTINE: '#FFEDED',
  COMOROS: '#FF4C0C',
  BELIZE: '#F6F9FC',
  AUSTRIA: '#FFFFFF',
  BRAZIL: '#F9F9F9',
  INDONESIA: '#F2F2F2',
  CANADA: '#E6F1FA',
  IRAQ: '#F3F6FB',
  CHILE: '#30A8FF',
  CHINA: 'rgba(0, 109, 228, 0.2)',
  FRANCE: '#0071C2',
  NABOO: '#0077CC',
  BAHREIN: '#4935C1',
  BOLIVIA: '#191D28',
  GERMANY: '#1A1C21',
  JAMAICA: '#EBEBEB',
  EMIRATES: '#C8C8C8',
  LAOS: '#B1B2B4',
  GREECE: '#626262',
  BRUNEI: '#E2E2E2',
  INDIA: '#5A606B',
  ITALY: '#67758D',
  JAPAN: '#4B4B4B',
  JORDAN: '#64748F',
  MALTA: 'rgba(105, 112, 125, 0.2)',
  PORTUGAL: '#D3DAE6',
  SPAIN: '#69707D',
  URUGUAY: '#007E77',
  ZAMBIA: '#E6F9F7',
  AFGHANISTAN: '#58CD7E',
  IRA: '#62CE9B',
  DJBOUTI: '#3DDB85',
  ANDORRA: '#FF3B5E',
  ANGOLA: '#FFB93B',
  PAKISTAN: '#8A6A0A',
  ENGLAND: '#E3E3E3',
  FINLAND: '#AAA',
  BULGARIA: '#fafafa',
  COLOMBIA: '#343741 ',
  CROATIA: '#e0e5ee',
  CUBA: '#c7c7c7',
  DENMARK: '#ececec',
  ECUADOR: '#696969',
  EGYPT: '#bfbfbf',
  GUINEA: '#6DCCB1',
  HAITI: '#1a1c21',
  ICELAND: '#ABB4C4',
  UKREIN: '#a6a6a6',
  HUNGARY: 'rgba(19, 34, 149, 0.1)',
} as const;
