import { EuiButtonEmpty, EuiFilterButton, EuiStepsHorizontal } from '@elastic/eui';
import { IconPulsusLogo } from 'icons';
import { useEffect, useState } from 'react';
import { ConstantColors } from 'utilities';
import AEstep1 from 'assets/images/onboarding-initial.png';
import androidBanner from 'assets/images/onboarding-android.png';
import iosBanner from 'assets/images/onboarding-ios.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import type { BannerProps } from '../page-onboarding.contracts';
import * as style from './banner.style';
import { useQuery } from '../../../hooks/use-query';

export function Banner({ color = ConstantColors.COMOROS, step, handleClick, withBackButton }: BannerProps) {
  const [isOnAndroid, setIsOnAndroid] = useState<boolean>();
  const [isOnIos, setIsOnIos] = useState<boolean>();

  const { t } = useTranslation();
  const query = useQuery();
  const language = query.get('language');
  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  const queryHasEnterpriseInfo = query.get('has_enterprise_info');

  const toggleOnAndroid = () => {
    setIsOnAndroid(true);
    handleClick('android2');
  };
  const toggleOffAndroid = () => {
    setIsOnAndroid(false);
    handleClick('ios1');
  };
  const toggleOnIos = () => {
    setIsOnIos(true);
    handleClick('ios2');
  };
  const toggleOffIos = () => {
    setIsOnIos(false);
    handleClick('enroll');
  };

  function backStep() {
    if (step === 'android1') {
      handleClick('0');
    } else if (step === 'android2') {
      handleClick('android1');
    } else if (step === 'ios1') {
      handleClick('android1');
    } else if (step === 'ios2') {
      handleClick('ios1');
    }
    setIsOnAndroid(false);
    setIsOnIos(false);
  }

  const renderStepInitial = () => {
    return (
      <style.InitialBannerContent className="initialBannerContent">
        <h3 style={{ padding: '30px 0 15px 0' }}>
          <span className="hello-title">{t('pages.onboarding.hello_title')}</span>
          <span className="hello-text">
            {t('pages.onboarding.hello_text')}
            <b>{t('pages.onboarding.pulsus')} </b>
          </span>
        </h3>
        <div style={{ display: 'flex', marginBottom: '0', marginTop: 'auto' }}>
          <img src={AEstep1} alt="setep 1 - onboarding" style={{ width: '380px', position: 'absolute', bottom: '0' }} />
          <section style={{ marginRight: '0', marginLeft: 'auto', width: '50%' }}>
            <h3 style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '30px', lineHeight: '35px' }}>{t('pages.onboarding.phrase')}</h3>
            <style.InitialButton
              style={{ background: ConstantColors.DJBOUTI }}
              size="m"
              fill
              onClick={() => {
                handleClick('android1');
              }}
            >
              {t('pages.onboarding.init')}
            </style.InitialButton>
          </section>
        </div>
      </style.InitialBannerContent>
    );
  };

  const renderStepAndroid1 = () => {
    return (
      <style.BannersContent className="bannersContent">
        <img
          className="android-img"
          src={androidBanner}
          alt="grupo com uma bandeira android"
          style={{ position: 'absolute', left: '0', marginBottom: '300px', height: '335px', inset: '0', transform: ' scale(1.0)' }}
        />
        <style.TitleContainerRight>
          <h2 className="stepTitle">
            {t('pages.onboarding.manage_phrase')}
            <strong> {t('pages.onboarding.manage_android')}</strong>
          </h2>
          <style.FilterButtons>
            <EuiFilterButton hasActiveFilters={isOnAndroid} onClick={toggleOnAndroid}>
              {t('pages.onboarding.yes')}
            </EuiFilterButton>

            <EuiFilterButton onClick={toggleOffAndroid}>{t('pages.onboarding.no')}</EuiFilterButton>
          </style.FilterButtons>
        </style.TitleContainerRight>
      </style.BannersContent>
    );
  };

  const renderStepIos1 = () => {
    return (
      <style.BannersContent className="bannersContent">
        <img
          className="ios-img"
          src={iosBanner}
          alt="grupo com uma bandeira ios"
          style={{ position: 'absolute', left: '0', marginBottom: '300px', height: '335px', inset: '0', transform: ' scale(1.0)' }}
        />
        <style.TitleContainerRight>
          <h2 className="stepTitle">
            {t('pages.onboarding.manage_phrase')}
            <b> {t('pages.onboarding.manage_ios')}</b>
          </h2>
          <style.FilterButtons>
            <EuiFilterButton hasActiveFilters={isOnIos} onClick={toggleOnIos}>
              {t('pages.onboarding.yes')}
            </EuiFilterButton>

            <EuiFilterButton onClick={toggleOffIos}>{t('pages.onboarding.no')}</EuiFilterButton>
          </style.FilterButtons>
        </style.TitleContainerRight>
      </style.BannersContent>
    );
  };

  const renderStepEnroll = () => {
    return (
      <style.BannersContent className="bannersContent" style={{ alignContent: 'center', justifyContent: 'center', marginTop: '25px' }}>
        <h2
          className="stepTitle"
          style={{
            fontWeight: 'bold',
            fontSize: '28px',
            lineHeight: '35px',
            color: '#FFFFFF',
            width: '600px',
            textAlign: 'center',
          }}
        >
          {queryHasEnterpriseInfo === 'false' ? t('pages.onboarding.enroll_phrase_first') : t('pages.onboarding.enroll_phrase_first_new')}
        </h2>
      </style.BannersContent>
    );
  };

  return (
    <style.Container
      color={color}
      style={{
        height:
          step === 'android1' || step === 'android2' || step === 'ios1' || step === 'ios2' || step === 'ios2'
            ? '280px'
            : step === 'enroll'
            ? '310px'
            : '400px',
      }}
    >
      <style.Header>
        {(step === 'android1' || step === 'android2' || step === 'ios1' || step === 'ios2') && withBackButton === true && (
          <style.BackButton
            onClick={() => {
              backStep();
            }}
            iconType="returnKey"
          >
            {t('pages.onboarding.back')}
          </style.BackButton>
        )}

        {step === 'ios1' && queryHasEnterpriseInfo === 'false' && (
          <style.BackButton
            onClick={() => {
              backStep();
            }}
            iconType="returnKey"
          >
            {t('pages.onboarding.back')}
          </style.BackButton>
        )}
        <style.CloseButtonContainer>
          <IconPulsusLogo width={130} height={20} color={ConstantColors.AUSTRIA} test_id="header-logo-icon" />
          <EuiButtonEmpty
            color="ghost"
            onClick={() => {
              window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_TEST}/home?njord=true`;
            }}
          >
            <span>{t('pages.onboarding.close_onboarding')}</span>
          </EuiButtonEmpty>
        </style.CloseButtonContainer>
      </style.Header>
      <style.Main className="mainBanner">
        {step !== '0' && (
          <style.StepHorizontal className="stepHorizontal">
            <EuiStepsHorizontal
              steps={[
                {
                  title: 'Android',
                  status: step === 'android1' ? 'current' : 'complete',
                  onClick: () => null,
                },
                {
                  title: 'iOS',
                  status: step === 'ios2' || step === 'enroll' ? 'complete' : step === 'ios1' ? 'current' : undefined,
                  onClick: () => null,
                },
                {
                  title: 'Enroll',
                  status: step === 'enroll' ? 'complete' : undefined,
                  onClick: () => null,
                },
              ]}
            />
          </style.StepHorizontal>
        )}
        {step === '0' && renderStepInitial()}
        {(step === 'android1' || step === 'android2') && renderStepAndroid1()}
        {(step === 'ios1' || step === 'ios2') && renderStepIos1()}
        {step === 'enroll' && renderStepEnroll()}
      </style.Main>
    </style.Container>
  );
}
