import * as S from './ComponentPoliciesModal.styles';
import { mdiChevronRight } from '@mdi/js';

import { ComponentEmptyButton } from 'modules/core/components';
import { useEffect, useMemo, useState } from 'react';

import { EuiTab, EuiTabs } from '@elastic/eui';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WifiOutlinedIcon from '@mui/icons-material/WifiOutlined';
import ScreenLockPortraitIcon from '@mui/icons-material/ScreenLockPortrait';
import PublicIcon from '@mui/icons-material/Public';
import { GeneralConfigComponent } from './GeneralConfig/GeneralConfigComponent';
import { WifiConfigComponent } from './WifiConfig/WifiConfigComponent';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import type { ChromePolicies } from 'modules/chromeOS/entities/ChromePolicies/ChromePolicies.entity';
import { CircularProgress } from '@mui/material';
import type { WifiList } from 'modules/chromeOS/entities/WifiList/WifiList.entity';
import { useTypedTranslation } from 'modules/core/hooks';
import { useQuery } from 'hooks/use-query';
import { KioskModeComponent } from './KioskMode/KioskModeComponent';
import { PermittedWebSitesComponent } from './PermittedWebSites/PermittedWebSitesComponent';

import { htmlIdGenerator } from '@elastic/eui';

export function ComponentPoliciesModal() {
  const [wifiData, setWifiData] = useState<WifiList[]>([]);
  const [webSitesData, setWebSitesData] = useState(['']);

  const [kioskApp, setKioskApp] = useState({
    app: '',
    type: 'web',
    customUrl: '',
  });

  const [generalData, setGeneralData] = useState<Omit<ChromePolicies, 'wifiList'>>({
    allowBluetooth: false,
    videoCaptureAllowed: false,
    audioOutputAllowed: false,
    audioCaptureAllowed: false,
    networkMode: 'blocked',
    usbConfig: 'EXTERNAL_STORAGE_ENUM_DISALLOW',
    ephemeralUsersEnabled: false,
    urlBlocking: {
      mode: '',
      urls: [],
    },
    deviceDisabledMessage: '',
    urlAllowedList: [],
  });

  const [selectedTab, setSelectedTab] = useState('general');
  const [loading, setLoading] = useState(true);

  const query = useQuery();
  const groupId = query.get('groupId');

  const { t } = useTypedTranslation<'policies'>('policies');

  const lokiService = useMemo(() => new ServicePulsusLoki(), []);

  const tabs = [
    {
      id: 'general',
      title: t('titleTabGeneral'),
      icon: <InfoOutlinedIcon />,
    },
    {
      id: 'wifi',
      title: t('titleTabWifi'),
      icon: <WifiOutlinedIcon />,
    },
    {
      id: 'kiosk',
      title: t('titleTabKiosk'),
      icon: <ScreenLockPortraitIcon />,
    },
    {
      id: 'webSites',
      title: t('titleTabWebSites'),
      icon: <PublicIcon />,
    },
  ];

  const handleChangeForm = (data: Omit<ChromePolicies, 'wifiList'>) => {
    setGeneralData(data);
  };

  const handleChangeWifi = (newList: WifiList[]) => {
    setWifiData(newList);
  };

  const handleDeleteKioskApp = async () => {
    try {
      setLoading(true);
      await lokiService.deleteKioskModeApp(groupId!);
      window.parent.postMessage(JSON.stringify({ event: 'success', payload: t('successDelApp') }), process.env.REACT_APP_PULSUS_WEB_HOST!);
    } catch (error) {
      window.parent.postMessage(JSON.stringify({ event: 'error', payload: t('errorDelApp') }), process.env.REACT_APP_PULSUS_WEB_HOST!);
    } finally {
      setLoading(false);
    }
  };

  const getData = async () => {
    try {
      const dataKiosk = await lokiService.getKioskModeApp(groupId!);

      const appList = dataKiosk.appId.split(':');

      if (dataKiosk?.appId === 'None') {
        setKioskApp({ app: '', type: 'web', customUrl: '' });
      } else {
        setKioskApp({
          app: appList[1] + (appList[2] || ''),
          type: appList[0],
          customUrl: dataKiosk?.installationUrl,
        });
      }

      const policies = await lokiService.getChromeOSPolicies(groupId!);
      updateStateData(policies);
      setWifiData([...policies.wifiList]);
      setWebSitesData([...policies.urlAllowedList]);
    } catch (err) {
      window.parent.postMessage(JSON.stringify({ event: 'error', payload: t('errorRequest') }), process.env.REACT_APP_PULSUS_WEB_HOST!);
    } finally {
      setLoading(false);
    }
  };

  const sendData = async () => {
    try {
      setLoading(true);
      if (selectedTab === 'kiosk') {
        if (kioskApp.app === '') {
          handleDeleteKioskApp();
          await getData();
          window.parent.postMessage(JSON.stringify({ event: 'success', payload: t('saveDataSuccess') }), process.env.REACT_APP_PULSUS_WEB_HOST!);

          return;
        }

        await lokiService.postKioskModeApp(groupId!, kioskApp);
      } else {
        await lokiService.updateChromeOSPolicies({ ...generalData, wifiList: wifiData, urlAllowedList: webSitesData }, groupId!);
      }
      window.parent.postMessage(JSON.stringify({ event: 'success', payload: t('saveDataSuccess') }), process.env.REACT_APP_PULSUS_WEB_HOST!);
      await getData();
    } catch (err) {
      console.error('error: ', err);
      window.parent.postMessage(JSON.stringify({ event: 'error', payload: t('submitError') }), process.env.REACT_APP_PULSUS_WEB_HOST!);
    } finally {
      window.parent.postMessage(JSON.stringify({ event: 'onClose' }), process.env.REACT_APP_PULSUS_WEB_HOST!);

      setLoading(false);
    }
  };

  const updateStateData = (policies: ChromePolicies) => {
    setGeneralData({
      allowBluetooth: policies.allowBluetooth,
      videoCaptureAllowed: policies.videoCaptureAllowed,
      audioOutputAllowed: policies.audioOutputAllowed,
      audioCaptureAllowed: policies.audioCaptureAllowed,
      networkMode: policies.networkMode,
      usbConfig: policies.usbConfig,
      ephemeralUsersEnabled: policies.ephemeralUsersEnabled,
      urlBlocking: policies.urlBlocking,
      deviceDisabledMessage: policies.deviceDisabledMessage,
      urlAllowedList: policies.urlAllowedList,
    });
  };

  useEffect(() => {
    getData();
  }, []);

  const MemoizedGeneralTab = useMemo(() => <GeneralConfigComponent handleChangeForm={handleChangeForm} generalData={generalData} />, [generalData]);
  const MemoizedWifiTab = useMemo(() => <WifiConfigComponent handleChangeForm={handleChangeWifi} wifiData={wifiData} />, [wifiData, generalData]);
  const MemoizedKioskModeTab = useMemo(() => <KioskModeComponent {...{ setKioskApp, kioskApp, handleDeleteKioskApp }} />, [kioskApp]);
  const MemoizedWebSites = useMemo(() => <PermittedWebSitesComponent {...{ webSitesData, setWebSitesData }} />, [webSitesData]);

  const handleCloseModal = () => {
    getData();
    window.parent.postMessage(JSON.stringify({ event: 'onClose' }), process.env.REACT_APP_PULSUS_WEB_HOST!);
  };

  return (
    <>
      <S.Container>
        <S.Header>
          <S.ConfigIcon />
          <S.ConfigTitle>Configurações</S.ConfigTitle>
          <S.StyledIcon path={mdiChevronRight} />
          <S.ConfigEditTitle>Editar</S.ConfigEditTitle>
        </S.Header>

        <S.Content>
          <S.WrapperTabs>
            <EuiTabs>
              {tabs.map((tab) => (
                <EuiTab key={htmlIdGenerator()()} isSelected={tab.id === selectedTab} onClick={() => setSelectedTab(tab.id)}>
                  <S.WrapTabTitle>
                    {tab.icon}
                    <S.TitleTab>{tab.title}</S.TitleTab>
                  </S.WrapTabTitle>
                </EuiTab>
              ))}
            </EuiTabs>
          </S.WrapperTabs>
          <S.WrapperBody>
            {loading ? (
              <S.WrapperLoader>
                <CircularProgress />
              </S.WrapperLoader>
            ) : (
              <>
                {selectedTab === 'general' && MemoizedGeneralTab}
                {selectedTab === 'wifi' && MemoizedWifiTab}
                {selectedTab === 'kiosk' && MemoizedKioskModeTab}
                {selectedTab === 'webSites' && MemoizedWebSites}
              </>
            )}
          </S.WrapperBody>
        </S.Content>
      </S.Container>
      <S.Footer>
        <ComponentEmptyButton label={t('cancel')} type="cancel" onClick={() => handleCloseModal()} />
        <ComponentEmptyButton label={t('save')} type="primary" onClick={() => sendData()} />
      </S.Footer>
    </>
  );
}
