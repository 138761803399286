import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import { PageChromeOSReport } from 'modules/chromeOS/pages';
import { ConstantLanguages } from 'utilities';

export function EmbeddedChromeOSReport() {
  const query = useQuery();
  const language = query.get('language') || ConstantLanguages.PT_BR;
  i18next.changeLanguage(language);

  return <PageChromeOSReport embedded />;
}
