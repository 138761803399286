import * as style from './quick-settings-card.style';
import type { FlagsContainerProps } from './andoid-settings.contracts';

export const Flags = (props: FlagsContainerProps) => {
  const { flags } = props;

  return (
    <>
      {flags && flags.length > 0 && (
        <style.CardFlags>
          {flags.map((item) => (
            <style.Flag key={item.id}>{item.label}</style.Flag>
          ))}
        </style.CardFlags>
      )}
    </>
  );
};
