import _ from 'lodash';
import type { ActionProps, AndroidStoreProps, PolicyProps } from './stores.contracts';

const INITIAL_STATE: AndroidStoreProps = {
  active: false,
  policy: {},
  initialPolicy: {},
};

function setAllSettings(policy: PolicyProps, state: AndroidStoreProps) {
  return { ...state, policy, initialPolicy: policy };
}

function changesVerify(state: AndroidStoreProps, data: PolicyProps) {
  if (
    Object.keys(_.pickBy(state.initialPolicy, _.identity)).length <= 1 ||
    (_.isEqual(state.initialPolicy, data) && _.isEqual(state.policy, state.initialPolicy))
  ) {
    return { ...state, active: false };
  }
  // const a = _.fromPairs(_.differenceWith(_.toPairs(state.initialPolicy), _.toPairs(data), _.isEqual));
  return { ...state, active: true, policy: data };
}

function setOldPolicy(state: AndroidStoreProps) {
  return { ...state, active: false, policy: state.initialPolicy };
}

function setDisable(state: AndroidStoreProps) {
  return { ...state, active: false };
}

function setPolicyChanges(state: AndroidStoreProps, data: PolicyProps) {
  let aux = JSON.parse(JSON.stringify(state.policy));
  aux = { ...aux, ...data };
  return changesVerify(state, aux);
}

const androidReducer = (state = INITIAL_STATE, action: ActionProps) => {
  switch (action.type) {
    case 'SET_ALL_SETTINGS':
      return setAllSettings(action.payload, state);
    case 'DISCARD_ANDROID_SETTINGS':
      return setOldPolicy(state);
    case 'SAVE_ANDROID_SETTINGS':
      return setDisable(state);
    case 'SET_POLICY_CHANGES':
      return setPolicyChanges(state, action.payload);
    default:
      return state;
  }
};

export default androidReducer;
