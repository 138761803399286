import styled from 'styled-components';

export const Wrapper = styled.main`
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 297px;
  height: 48px;
  padding: 13px 20px;

  box-shadow:
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  transition: 0.2s;

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  &:hover {
    transform: translateY(-2px);
    p {
      transition: 0.3s;
      text-decoration: underline;
    }
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  p {
    font-size: 12px;
  }

  span {
    margin: 0 8px 1px 10px;
    font-size: 18px;
    font-weight: 700;
  }
`;
