import { EuiButtonEmpty } from '@elastic/eui';
import { IconButton } from '@mui/material';
import styled, { css } from 'styled-components';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 553px;
  overflow-y: auto;
`;

export const ModalHeader = styled.div``;

export const ModalTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;

  p {
    font-weight: 700;
    font-size: 20px;
    line-height: 20px;
    color: #343434;
  }
`;

export const ModalDescription = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 30px;
  letter-spacing: -0.08px;
  color: #afafaf;
`;

export const ConfigurationBox = styled.div``;
export const BoxTitle = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #343741;
`;

export const BoxVersions = styled.div`
  display: flex;
  gap: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    background-color: ${({ theme }) => theme.main};
    border-color: ${({ theme }) => theme.main};
    box-shadow: none !important;
  }

  &.collumn {
    padding-top: 10px;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
  }
`;

export const Flags = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  .euiFieldText:focus,
  .focused .euiComboBox__inputWrap {
    background-color: white;
    max-width: 100% !important;
    background-size: 100% 100% !important;
    box-shadow:
      0 1px 1px -1px rgba(152, 162, 179, 0.2),
      0 4px 4px -2px rgba(152, 162, 179, 0.2),
      inset 0 0 0 1px rgba(15, 39, 118, 0.1);

    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%) !important;`}
    `}
  }
`;

export const TagsBox = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
  }
`;

export const CustomTagButton = styled(IconButton)`
  :hover {
    background: transparent !important;
  }
`;

export const TagsListLink = styled(EuiButtonEmpty)`
  .euiButtonEmpty__text {
    font-weight: 700;
    font-size: 14px;
    line-height: 118%;
    color: #30a8ff;
  }
  :focus,
  :hover {
    color: #30a8ff;
    background-color: transparent;
  }
`;
