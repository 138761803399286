import { EuiTextColor, EuiInMemoryTable } from '@elastic/eui';
import { mdiDelete } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton } from '@mui/material';
import { ColoredChromeOSIcon } from 'icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as style from './applications-tab.style';

export function ApplicationsTab() {
  const [pageIndex, setPageIndex] = useState(0);
  const { t } = useTranslation();

  const data = [
    { app: 'Freshdesk', identifier: 'mobi.pulsus.app.shopify', size: '1.2 Mb', version: '00:40:16', status: false },
    { app: 'Klaviyo', identifier: 'mobi.pulsus.app.shopify', size: '1.6 Mb', version: '20:45:47', status: true },
    { app: 'Help Scout', identifier: 'mobi.pulsus.app.shopify', size: '4.5 Mb', version: '04:39:48', status: false },
    { app: 'Shopify', identifier: 'mobi.pulsus.app.shopify', size: '2.6 Mb', version: '12:42:25', status: true },
    { app: 'Mailchimp', identifier: 'mobi.pulsus.app.mailchip', size: '3.2 Mb', version: '02:46:34', status: false },
  ];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = [
    {
      field: 'icon',
      name: '',
      render: () => <ColoredChromeOSIcon height={18} width={18} />,
      width: '40px',
    },
    {
      field: 'app',
      name: t('pages.page_device_apps.device_apps'),
      width: '185px',
    },
    {
      field: 'identifier',
      name: t('pages.page_device_apps.details_apps_identifier'),
      width: '215px',
    },
    {
      field: 'size',
      name: t('pages.page_device_apps.details_apps_size'),
      width: '145px',
    },
    {
      field: 'version',
      name: t('pages.page_device_apps.details_apps_version'),
      width: '145px',
    },
    {
      field: 'status',
      name: t('pages.page_device_apps.details_apps_status'),
      width: '200px',
      render: (isInstall: boolean) => {
        if (isInstall) {
          return <style.StatusFlag>{t('pages.page_device_apps.details_apps_installed')}</style.StatusFlag>;
        }
        return <style.StatusFlag style={{ background: '#ff3b5e' }}>{t('pages.page_device_apps.details_apps_pending_installation')}</style.StatusFlag>;
      },
    },
    {
      field: 'delete',
      name: t('pages.page_device_apps.details_apps_uninstall'),
      width: '90px',
      align: 'center',
      render: () => (
        <IconButton style={{ padding: 5 }}>
          <Icon path={mdiDelete} size={0.95} color="#575757" />
        </IconButton>
      ),
    },
  ];

  const pagination = {
    pageIndex,
    pageSize: 15,
    hidePerPageOptions: true,
    totalItemCount: data.length,
  };

  function onTableChange(index: number) {
    setPageIndex(index);
  }

  return (
    <style.Container>
      <style.Header>
        <style.Title>{t('pages.page_device_apps.details_apps_chrome_installed')}</style.Title>
        <style.Description>
          <EuiTextColor>{t('pages.page_device_apps.details_apps_chrome_list')}</EuiTextColor>
          <EuiTextColor color="secondary"> ID-2344.</EuiTextColor>
        </style.Description>
      </style.Header>
      <style.Body>
        <EuiInMemoryTable
          columns={columns}
          items={data}
          pagination={pagination}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          onChange={(e: any) => {
            const { page } = e;
            onTableChange(page.index);
          }}
        />
      </style.Body>
    </style.Container>
  );
}
