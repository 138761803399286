import Lottie from 'lottie-react';
import animation_confirm from 'assets/animations/animation-confirm.json';
import { useTranslation } from 'react-i18next';
import * as style from './modal-finished-certificate.style';
import type { ModalFinishedCertificateProps } from '../page-certificate.contracts';

export function ModalFinishedCertificate({ onClose }: ModalFinishedCertificateProps) {
  const { t } = useTranslation();

  const handleOnClose = () => {
    onClose();
  };
  return (
    <style.Modal onClose={handleOnClose}>
      <style.AnimationContainer>
        <Lottie
          data-testid="not-found-animation"
          animationData={animation_confirm}
          style={{
            width: '391px',
            height: 'auto',
            pointerEvents: 'none',
            margin: '0',
          }}
        />

        <style.Title>{t('pages.certificate_modal_success.phrase_one')}</style.Title>

        <style.FooterModal>
          <style.Text>
            {t('pages.certificate_modal_success.phrase_two')}
            <b>{t('pages.certificate_modal_success.phrase_three')}</b>
          </style.Text>
        </style.FooterModal>
      </style.AnimationContainer>
    </style.Modal>
  );
}
