import { EuiButtonGroup } from '@elastic/eui';
import Icon from '@mdi/react';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const CustomIcon = styled(Icon)`
  height: 24px;
`;

export const ButtonGroup = styled(EuiButtonGroup)`
  &.euiButtonGroup {
    background-color: transparent;
  }
  .euiButtonGroupButton--small {
    height: 30px;
    align-self: center;
    span {
      align-self: center;
    }
  }

  .euiButtonGroupButton {
    border: none;
    background-color: #d8d9db;
    text-decoration: none !important;
  }

  .euiButtonGroupButton:last-child {
    border-radius: 0 30px 30px 0;
  }

  .euiButtonGroupButton:first-child {
    border-radius: 30px 0 0 30px;
  }

  .euiButtonGroupButton:hover {
    background-color: #d8d9db !important;
    text-decoration: none;
  }

  .euiButtonGroupButton.euiButtonGroupButton--text:not([class*='isDisabled']).euiButtonGroupButton-isSelected {
    background-color: #d8d9db;
    border-color: #69707d;
    color: #fff;
  }

  .euiButtonGroup__buttons {
    box-shadow: none;
  }

  .euiButton__text {
    font-size: 14px;
  }
`;
