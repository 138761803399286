import { EuiCheckbox } from '@elastic/eui';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;

  gap: 30px;
  .item {
    padding: 15px 0;
    min-width: 483px;
    max-width: 483px;
  }
`;

const CheckboxGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .checkbox_item {
    width: 50%;
  }
`;

const CardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
`;

const CustomCheckbox = styled(EuiCheckbox)`
  margin-top: 15px;
  .euiCheckbox__input:checked + .euiCheckbox__square {
    background-color: ${({ theme }) => theme.main} !important;
    border-color: ${({ theme }) => theme.main};
  }
`;

export { Container, CheckboxGroup, CustomCheckbox, CardDescription };
