/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-one-expression-per-line */
import type { ReactElement } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { DialogComponent } from 'components';
import { EuiTab } from '@elastic/eui';
import type { Tag } from 'modules/operational/entities';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useTypedTranslation } from 'modules/core/hooks';
import type { SelectGroupModalComponentProps } from './SelectGroupModalComponent.contracts';
import * as S from './SelectGroupModalComponent.styles';
import { SelectedGroupListComponent } from './SelectedGroupList/SelectedGroupListComponent';
import { GroupListComponent } from './GroupList/GroupListComponent';
import { TagListComponent } from './TagList/TagListComponent';

export const SelectGroupModalComponent = ({
  setGroups,
  open,
  selectedGroups,
  setOpen,
  hasDinamicMargin,
}: SelectGroupModalComponentProps): ReactElement => {
  const [selectedTabId, setSelectedTabId] = useState('groups');
  const [loading, setLoading] = useState<boolean>(true);

  const [tags, setTags] = useState<Tag[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [margin, setMargin] = useState(0);

  const { t } = useTypedTranslation<'devices.add_group_modal'>('devices.add_group_modal');

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const MODAL_HEIGHT = 723;

  useEffect(() => {
    const newTagsPreviousSelected = tags.map((tag) => {
      const newTags = tag.groups.map((group) => {
        if (selectedGroups.find((selected) => selected.id === group.id)) {
          return { ...group, selected: true };
        }

        return { ...group, selected: false };
      });

      return { ...tag, groups: newTags };
    });

    setTags(newTagsPreviousSelected as Tag[]);
  }, [open]);

  const getTags = async (): Promise<void> => {
    try {
      const serviceTags = await serviceLoki.getTagsWithGroups();

      setTags(serviceTags);
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('toast.title'),
          text: t('toast.text'),
          id: '1',
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTags();
  }, []);

  useEffect(() => {
    const height = -(document.body.clientHeight - MODAL_HEIGHT);
    const putSpacingTopModal = height + 100;

    setMargin(putSpacingTopModal);
  }, [open]);

  const tabs = [
    {
      id: 'groups',
      name: t('tabs.groups'),
      content: (
        <S.GroupContainer>
          <GroupListComponent setTags={setTags} tags={tags} loading={loading} />
        </S.GroupContainer>
      ),
    },
    {
      id: 'tags',
      name: t('tabs.tags'),
      content: (
        <S.GroupContainer>
          <TagListComponent tags={tags} setTags={setTags} loading={loading} />
        </S.GroupContainer>
      ),
    },
  ];

  const selectedTabContent = tabs.find((obj) => obj.id === selectedTabId)?.content;

  const renderTabs = () => {
    return tabs.map((tab) => (
      <EuiTab key={tab.id} onClick={() => setSelectedTabId(tab.id)} isSelected={tab.id === selectedTabId}>
        {tab.name}
      </EuiTab>
    ));
  };

  const setSelectedGroups = () => {
    const selectedGroupsArray: Array<{ id: number; name: string }> = [];

    tags.forEach((tag) => {
      tag.groups.forEach((group) => {
        console.log(group);
        if (group.selected) selectedGroupsArray.push({ id: group.id, name: group.name });
      });
    });

    setGroups(selectedGroupsArray);
    setOpen(false);
  };

  return (
    <S.Wrapper>
      <DialogComponent
        closeOnClickOutside
        open={open}
        onClose={() => setOpen(false)}
        maxHeight={`${MODAL_HEIGHT}px`}
        maxWidth="914px"
        width="914px"
        marginTop={`${hasDinamicMargin ? margin : '0'}px`}
      >
        <S.Container>
          <S.Title>{t('title')}</S.Title>
          <S.Content>
            <S.TabsContainer style={{ width: '50%' }}>
              <S.StyledTab>{renderTabs()}</S.StyledTab>
              {selectedTabContent}
            </S.TabsContainer>
            <SelectedGroupListComponent tags={tags} setTags={setTags} />
          </S.Content>
          <S.BottomContent>
            <S.StyledButtonEmpty type="button" onClick={() => setOpen(false)}>
              {t('footer_buttons.cancel')}
            </S.StyledButtonEmpty>
            <S.StyledButton type="button" onClick={() => setSelectedGroups()}>
              {t('footer_buttons.confirm')}
            </S.StyledButton>
          </S.BottomContent>
        </S.Container>
      </DialogComponent>
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </S.Wrapper>
  );
};
