/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import type { IconProps } from './IconComponent.contracts';
import { Suspense } from 'react';
import { AVAILABLE_ICONS } from './AvailableIcons';

export type AvailableIconProps = keyof typeof AVAILABLE_ICONS;

export type IconComponentProps = IconProps & {
  icon: AvailableIconProps;
};

export const IconComponent = ({ icon, ...props }: IconComponentProps): ReactElement => {
  const ChoicedIcon = AVAILABLE_ICONS[icon];

  return (
    <Suspense fallback={<></>}>
      <ChoicedIcon {...props} />
    </Suspense>
  );
};
