import { EuiButton, EuiSwitch } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-items: center;
  width: 100%;
  justify-content: space-between;
`;

export const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const AddButton = styled(EuiButton)`
  &.euiButton {
    padding: 0px;
    width: 185px;
    height: 32px;
    background: rgba(0, 109, 228, 0.2);
    border-radius: 4px;
    border: none;
  }
  .euiButton__text,
  .euiIcon {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #005ec4;
  }
  &:hover,
  &:focus {
    background: rgba(0, 109, 228, 0.2) !important;
    text-decoration: none !important;
  }
`;

export const StatusBox = styled.div`
  display: flex;
  align-items: center;
`;

export const StatusTitle = styled.p`
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  color: #1a1c21;
`;

export const StatusDescription = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 13px;
  color: #67758d;
`;

export const TableNameCollumn = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const TableContentText = styled.p`
  font-weight: 400;
  font-size: 11px;
  line-height: 15px;
  color: #67758d;
  &.bold {
    font-weight: 500;
  }
`;

export const TableStatusBox = styled.div`
  .euiSwitch {
    display: flex;
    align-items: center;
  }
  .euiSwitch__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #343741;
  }
  .euiSwitch__thumb {
    border-color: white !important;
  }
`;

export const DialogContent = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

export const DialogTitle = styled.p`
  color: ${({ theme }) => theme.main};
  font-size: 28px;
  font-weight: 700;
  line-height: 35px;
`;

export const DialogMessage = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 35px;
  color: #555555;
`;

export const DialogButton = styled(EuiButton)`
  &.euiButton {
    padding: 0px;
    width: 200px;
    height: 38px;
    background: ${({ theme }) => theme.main};
    border-radius: 4px;
    border: none;
  }
  .euiButton__text,
  .euiIcon {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    color: #ffffff;
  }
  &:hover,
  &:focus {
    background: ${({ theme }) => theme.main} !important;
    text-decoration: none !important;
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 25px;
`;

export const Line = styled.div`
  display: flex;
  gap: 40px;
  width: 475px;
`;

export const Field = styled.div`
  width: 475px;
  &.name {
    width: 260px;
  }

  &.security {
    width: 195px;
  }
  .euiFormControlLayout {
    max-width: 475px !important;
  }
  .euiFieldPassword:disabled {
    max-width: 100% !important;
  }
  .euiFieldText:focus,
  .euiFieldPassword:focus,
  .euiSelect:focus {
    background-color: white;
    max-width: 100% !important;
    background-size: 100% 100% !important;
    box-shadow:
      0 1px 1px -1px rgba(152, 162, 179, 0.2),
      0 4px 4px -2px rgba(152, 162, 179, 0.2),
      inset 0 0 0 1px rgba(15, 39, 118, 0.1);

    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%);`}
    `}
  }
`;

export const CustomSwitch = styled(EuiSwitch)`
  .euiSwitch__body,
  .euiSwitch__body:focus {
    background-color: #f15a22 !important;
  }
  .euiSwitch__label {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #343741;
  }
  .euiSwitch__thumb {
    border-color: white !important;
  }
  .euiSwitch__track {
    border: none;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  width: 100%;
`;
