import styled, { css } from 'styled-components';
import { EuiCard } from '@elastic/eui';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1050px;
  width: 100%;
  gap: 10px;
  padding: 40px 100px;
`;

export const ModalTitle = styled.h2`
  font-weight: 700;
  line-height: 26px;
  color: ${({ theme }) => theme.main};
  width: 500px;
  text-align: center;
  font-size: 20px;
`;

export const SubTitle = styled.p`
  font-weight: 400;
  line-height: 15px;
  color: #474747;
  font-size: 13px;
`;

export const CardGroup = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 60px;
`;

export const CardBox = styled.div`
  width: 170px;
`;

export const CustomCard = styled(EuiCard)`
  height: 150px;
  border-radius: 14px !important;
  border: none !important;
  box-shadow:
    0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
    0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
    0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
    0px 15px 15px -1px rgba(0, 0, 0, 0.04) !important;
  cursor: pointer;
  padding: 0 !important;
  .euiCard__children {
    margin: 0;
    height: 100%;
  }
`;

export const CardContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 10px;
  height: 100%;
`;

export const CardContentIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 12px;
  ${(props: { color?: string }) => {
    if (props?.color) {
      return css`
        background-color: ${props.color};
      `;
    }
    return css``;
  }}
`;

export const ContentIconTitle = styled.h4`
  font-weight: 600;
  line-height: 16px;
  color: #636363;
  font-size: 14px;
`;

export const CardDescription = styled.p`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-weight: 400;
  margin-top: 10px;
  justify-content: center;
  p.green {
    color: #3ddb85;
    font-weight: 700;
    line-height: 19px;
  }
  p.blue {
    color: #1483ff;
    font-weight: 700;
    line-height: 19px;
  }

  p {
    font-weight: 400;

    line-height: 19px;
    font-size: 13px;
  }

  p {
    font-weight: 400;
    line-height: 19px;
    font-size: 13px;
  }
`;

export const WarningMessage = styled.div`
  background: #fff9e8;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  padding: 10px 20px;
  margin: 0px 30px;

  color: #8a6a0a;
  width: 680px;
  p.title {
    font-weight: 700;
  }

  p.highlight {
    font-weight: 700;
    font-style: italic;
  }

  p {
    line-height: 25px;
    font-size: 12px;
  }
`;
