import { useTypedTranslation } from 'modules/core/hooks';
import type { ReactElement } from 'react';
import { useEffect, useMemo, useRef, useState } from 'react';
import * as S from './DeviceHistoryLocationPage.styles';
import { EuiButtonIcon, htmlIdGenerator } from '@elastic/eui';
import PlaceIcon from '@mui/icons-material/Place';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import type { DeviceHistory } from 'modules/operational/entities/DeviceHistory/DeviceHistory.entity';
import { Skeleton } from '@mui/material';
import { Loader } from '@googlemaps/js-api-loader';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import moment from 'moment';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import { useUserContext } from 'modules/core/contexts/user';

export const DeviceLocationHistoryPage = (): ReactElement => {
  const [selectedLocation, setSelectedLocation] = useState<DeviceHistory>(null!);
  const [loading, setLoading] = useState(true);
  const [toast, setToast] = useState<Toast[]>([]);
  const [deviceHistory, setDeviceHistory] = useState<DeviceHistory[]>([]);

  const { administrator } = useUserContext();

  const { t } = useTypedTranslation<'historyLocation'>('historyLocation');
  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const mapRef = useRef<HTMLDivElement>(null);
  const googleMapRef = useRef<google.maps.Map>();
  const MapRef = useRef<typeof google.maps.Map>();
  const MarkerRef = useRef<typeof google.maps.marker.AdvancedMarkerElement>();
  const activeMarkerRef = useRef<google.maps.marker.AdvancedMarkerElement>();
  const activeCircleRef = useRef<google.maps.Circle>();

  const initMap = async () => {
    const center = { lat: -16.1844284, lng: -47.0568449 };

    const loader = new Loader({
      apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY!,
      version: 'weekly',
      language: administrator.language,
    });

    const mapOptions: google.maps.MapOptions = {
      center,
      zoom: 4,
      mapId: 'last-location',
    };

    const { Map } = await loader.importLibrary('maps');
    const { AdvancedMarkerElement } = await loader.importLibrary('marker');

    googleMapRef.current = new Map(mapRef.current!, mapOptions);

    MapRef.current = Map;
    MarkerRef.current = AdvancedMarkerElement;
  };

  const getDeviceIdFromRuby = (): void => {
    window.parent.postMessage(JSON.stringify({ type: 'component-ready' }), process.env.REACT_APP_RUBY_LINK!);

    window.addEventListener('message', async ({ data, origin }: MessageEvent) => {
      if (origin.includes('pulsus.mobi')) {
        const { type, deviceId } = JSON.parse(data);

        if (type === 'location_device_id') {
          await getLocations(deviceId);
        }
      }
    });
  };

  const getDateWithTZ = (date?: string) => {
    const momentHour = moment(`${date}+00:00`).utcOffset(administrator.offset!);

    return momentHour;
  };

  const getLocations = async (deviceId: string) => {
    try {
      const lastLocations = await serviceLoki.getDeviceLastLocations(deviceId);

      setDeviceHistory(lastLocations);
      pinLocation(lastLocations[0]);
    } catch {
      setToast([
        {
          color: 'danger',
          id: '1',
          iconType: 'faceSad',
          title: t('requisition.error.title'),
          text: t('requisition.error.text'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    initMap().then(() => {
      getDeviceIdFromRuby();
    });
  }, []);

  const pinLocation = (location: DeviceHistory) => {
    setSelectedLocation(location);

    const { latitude, longitude, id, precision } = location;

    googleMapRef.current!.setCenter({ lat: latitude, lng: longitude });
    googleMapRef.current?.setZoom(13);

    if (activeMarkerRef.current && activeCircleRef.current) {
      activeMarkerRef.current.map = null!;
      activeCircleRef.current.setMap(null);
    }

    const marker = new MarkerRef.current!({
      position: { lat: latitude, lng: longitude },
      map: googleMapRef.current,
      title: String(id),
    });

    const circle = new google.maps.Circle({
      map: googleMapRef.current,
      center: { lat: latitude, lng: longitude },
      radius: precision,
      fillColor: '#00aaff',
      fillOpacity: 0.2,
      strokeColor: '#00aaff',
      strokeOpacity: 0.8,
      strokeWeight: 1,
    });

    activeCircleRef.current = circle;
    activeMarkerRef.current = marker;
  };

  const closeModal = () => {
    window.parent.postMessage(JSON.stringify({ type: 'close-history-maps' }), process.env.REACT_APP_RUBY_LINK!);
  };

  const renderHistoryItems = (devicesHistory: DeviceHistory[]) => {
    if (loading) {
      return Array(20)
        .fill(null)
        .map(() => <Skeleton key={htmlIdGenerator()()} variant="rectangular" style={{ minHeight: '130px' }} />);
    }

    return devicesHistory.map((history) => (
      <S.HistoryItem onClick={() => pinLocation(history)} key={history.id} type="button" selected={selectedLocation.id === history.id}>
        <div>
          <PlaceIcon style={{ color: selectedLocation.id === history.id ? 'white' : '#a1a1a1' }} />
        </div>
        <S.Body selected={selectedLocation.id === history.id}>
          <h2>{getDateWithTZ(history.lastLocation).format('LL LT')}</h2>
          <p>{`${t('aproximately')} ${getDateWithTZ(history.lastLocation).fromNow(true)}`}</p>
          <p>
            <strong>{t('source')}:</strong> {t(`provider.${history.source}`)}
          </p>
          <p>
            <strong>{t('precision')}:</strong> {history.precision?.toFixed(1) || 0}m
          </p>
        </S.Body>
        <S.Forward>
          <ArrowForwardIosIcon style={{ width: '15px', color: selectedLocation.id === history.id ? 'white' : '#a1a1a1' }} />
        </S.Forward>
      </S.HistoryItem>
    ));
  };

  return (
    <S.Container>
      <S.MapContainer ref={mapRef} />
      <S.HistoryList>
        <S.Header>
          <div />
          <h1>{t('title')}</h1>
          <S.Forward>
            <EuiButtonIcon aria-labelledby="close" iconType="cross" onClick={() => closeModal()} />
          </S.Forward>
        </S.Header>
        <S.List>{deviceHistory.length ? renderHistoryItems(deviceHistory) : <S.NoLocation>{t('requisition.noDevices')}</S.NoLocation>}</S.List>
      </S.HistoryList>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </S.Container>
  );
};
