import React from 'react';
import * as style from './elastic-custom-slider.style';
import type * as types from './elastic-custom-slider.contracts';

export const CustomSlider = (props: types.CustomSliderProps) => {
  const { label, min, max, onChange, value, children, step, disabled, ticks, fullWidth, className, id } = props;

  function handleChange(e: React.ChangeEvent<HTMLInputElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    onChange(Number(e.currentTarget.value));
  }

  return (
    <style.SliderContainer data-testid="slider-container">
      {label && <div data-testid="slider-label">{label}</div>}
      {children && <div data-testid="slider-children">{children}</div>}
      <style.Slider
        id={id || undefined}
        className={className || ''}
        min={min}
        step={step}
        showInput
        showTicks={!!ticks}
        ticks={ticks}
        fullWidth={fullWidth}
        max={max}
        showRange
        disabled={disabled}
        value={value}
        onChange={(e) => handleChange(e)}
      />
    </style.SliderContainer>
  );
};
