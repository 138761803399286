import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import type { StoreProps } from 'stores/stores.contracts';
import * as style from './localization.style';
import { LocalizationCardsGroup } from './localization-cards-group';

export const PageLocalization: React.FC = () => {
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const dispatch = useDispatch();

  const [localization, setLocalization] = useState('LOCATION_DISABLED');
  const [vpnManagement, setVpnManagement] = useState({
    active: false,
    identifier: '',
    allowTraffic: false,
  });
  const [initialPolicy, setInitialPolicy] = useState({});

  function handleChange(value, key: string) {
    switch (key) {
      case 'localization':
        setLocalization(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { locationMode: value } });
        break;
      case 'vpn':
        setVpnManagement(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { vpnManagement: value } });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initialPolicy)) {
      setLocalization(policy.locationMode);
      if (policy.vpnManagement) {
        setVpnManagement(policy.vpnManagement);
      }
      setInitialPolicy(policy);
    }
  }, [policy]);

  return (
    <style.Container>
      <style.Header>
        <style.Title>Localização</style.Title>
      </style.Header>
      <LocalizationCardsGroup localization={localization} vpnManagement={vpnManagement} onChange={(value, key: string) => handleChange(value, key)} />
    </style.Container>
  );
};
