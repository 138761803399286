/* eslint-disable react/jsx-no-useless-fragment */
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { DialogActions, DialogTitle, IconButton } from '@mui/material';
import { useTheme } from 'styled-components';
import type { ComponentModalProps } from './component-dialog.contracts';
import * as style from './component-dialog.style';

export function DialogComponent(props: ComponentModalProps) {
  const {
    open,
    title,
    children,
    footer,
    width,
    height,
    maxWidth,
    onClose,
    closeOnClickOutside,
    padding,
    maxHeight,
    zoom,
    id,
    titleTextAlign = 'center',
    marginTop,
    overflow = 'hidden',
    styles,
    containerStyles,
  } = props;
  const theme = useTheme();

  return (
    <style.CustomModal
      id={id}
      open={open}
      sx={{
        '& .MuiDialog-container': {
          ...containerStyles,
          '& .MuiPaper-root': {
            display: 'flex',
            width: width || 'auto',
            maxWidth: maxWidth || '678px',
            maxHeight: maxHeight || '514px',
            margin: 0,
            borderRadius: '16px',
            justifyContent: 'center',
            overflow,
            padding: padding || '40px',
            height: height || 'auto',
            zoom,
            marginTop: marginTop || 0,
            ...styles,
          },
        },
      }}
      fullWidth
      onClose={(_, reason) => {
        if (reason !== 'backdropClick' || closeOnClickOutside) {
          onClose();
        }
      }}
    >
      <style.CloseButtom>
        <IconButton onClick={() => onClose()}>
          <Icon path={mdiClose} size="16px" color="#DBDBDB" />
        </IconButton>
      </style.CloseButtom>
      {title && (
        <DialogTitle fontWeight="bold" textAlign={titleTextAlign} color={theme.main}>
          {title}
        </DialogTitle>
      )}
      {children || <></>}
      {footer && <DialogActions>{footer}</DialogActions>}
    </style.CustomModal>
  );
}
