import type { ReactElement } from 'react';
import Lottie from 'lottie-react';
import AnimationPulsusLogoGrey from 'assets/animations/animation-logo-pulsus-grey.json';
import * as S from './LoadingFallbackComponent.styles';

export const LoadingFallbackComponent = (): ReactElement => {
  return (
    <S.Container>
      <Lottie
        data-testid="animation_logo_pulsus"
        animationData={AnimationPulsusLogoGrey}
        style={{
          width: '500px',
          height: '500px',
          pointerEvents: 'none',
          margin: '0',
        }}
      />
    </S.Container>
  );
};
