import styled, { css } from 'styled-components';

export const ModalsFooter = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 20px;

    .euiButton {
      background-color: ${theme.main} !important;
      border: none;

      &:hover {
        filter: brightness(0.9);
        background-color: ${theme.main};
        border: none;
      }
    }

    .euiButton__text {
      color: #fff;
    }
  `}
`;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.main};
  max-width: 400px;
  margin: 0 auto;
`;

export const Img = styled.img`
  width: 270px;
  height: 297px;
  margin: -80px auto 10px auto;
`;

export const ReregisterIcons = styled.img`
  width: 63px;
  height: 74px;
  position: absolute;
  top: 110px;
  left: 150px;
`;

export const WarningText = styled.p`
  text-align: center;
  margin-bottom: 40px;
  font-weight: 500;
`;
