import * as S from './UsersDetailsPage.styles';
import { type ReactElement } from 'react';
import { UserModalTabsComponent } from 'modules/operational/components/users/UserModalTabs/UserModalTabsComponent';
import { WrapperComponent } from 'modules/core/components/Wrapper/WrapperComponent';
import { UserInformationComponent } from 'modules/operational/components/employeeUsers/UserInformation/UserInformationComponent';

const UsersDetailsPage = (): ReactElement => {
  return (
    <WrapperComponent hasFooter>
      <S.ModalBody>
        <UserInformationComponent />
        <UserModalTabsComponent />
      </S.ModalBody>
    </WrapperComponent>
  );
};

export default UsersDetailsPage;
