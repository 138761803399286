import type { Group } from 'entities/group';
import type { APICustomFieldInfo } from './deviceDetails.entity';

export class EditDeviceFormAtt {
  id: number;
  identifier: string;
  group: Group[];
  firstName?: string;
  lastName?: string;
  email?: string;
  exchangeUserName?: string;
  userIdentifier?: string;
  userCustomFields?: APICustomFieldInfo[];

  constructor(
    id: number,
    identifier: string,
    group: Group[],
    firstName?: string,
    lastName?: string,
    email?: string,
    exchangeUserName?: string,
    userIdentifier?: string,
    userCustomFields?: APICustomFieldInfo[]
  ) {
    this.id = id;
    this.identifier = identifier;
    this.group = group;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.exchangeUserName = exchangeUserName;
    this.userIdentifier = userIdentifier;
    this.userCustomFields = userCustomFields;
  }
}
