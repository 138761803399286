import { Dialog } from '@mui/material';
import styled from 'styled-components';

export const CustomModal = styled(Dialog)``;

export const CloseButtom = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
`;
