import { EuiButton } from '@elastic/eui';
import { Container } from 'modules/core/components/HeaderPage/HeaderPageComponent.styles';
import styled from 'styled-components';

export const Content = styled.main`
  .link {
    color: ${({ theme }) => theme.palette.CHILE};
    font-weight: 500;
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      text-decoration: underline;
      transition: 0.3s;
      transform: translateY(-2px);
    }
  }

  ${Container} {
    margin-bottom: 70px;
  }
`;

export const HeaderContainer = styled.header`
  margin-bottom: 50px;
`;

export const UploadButton = styled(EuiButton)`
  margin-bottom: 36px;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
  height: 36px;
  font-size: 14px;
`;
