import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;
  }

  section {
    background-color: ${({ theme }) => theme.dashboardTheme.paper};
    padding: 15px 10px;
    border-radius: 4.469px;
    display: flex;
  }

  section:not(:last-child) {
    margin-right: 20px;
  }
`;

export const SettingsButton = styled.button`
  margin-left: 5px;
`;
