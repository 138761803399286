import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import type { StoreProps } from 'stores/stores.contracts';
import * as style from './card-protection.style';
import { ScreenshotDisabled } from './status-screenshot';
import { UsbFileTransferDisabled } from './status-usb';
import { OutgoingBeamDisabled } from './status-nfc-data';
import { MountPhysicalMediaDisabled } from './status-physical-media';

export function CardDataPrtotection() {
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const dispatch = useDispatch();
  const [initialPolicy, setInitialPolicy] = useState({});
  const [screenshotDisabled, setScreenshotDisabled] = useState(false);
  const [outgoingBeamDisabled, setOutgoingBeamDisabled] = useState(false);
  const [usbFileTransferDisabled, setUsbFileTransferDisabled] = useState(false);
  const [mountPhysicalMediaDisabled, setMountPhysicalMediaDisabled] = useState(false);

  function handleChange(value: boolean, key: string) {
    switch (key) {
      case 'screenshot':
        setScreenshotDisabled(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { screenCaptureDisabled: value } });
        break;
      case 'outgoingBeam':
        setOutgoingBeamDisabled(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { outgoingBeamDisabled: value } });
        break;
      case 'usbFileTransfer':
        setUsbFileTransferDisabled(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { usbFileTransferDisabled: value } });
        break;
      case 'mountPhysicalMedia':
        setMountPhysicalMediaDisabled(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { mountPhysicalMediaDisabled: value } });
        break;
      default:
        break;
    }
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initialPolicy)) {
      setScreenshotDisabled(policy.screenCaptureDisabled);
      setOutgoingBeamDisabled(policy.outgoingBeamDisabled);
      setUsbFileTransferDisabled(policy.usbFileTransferDisabled);
      setMountPhysicalMediaDisabled(policy.mountPhysicalMediaDisabled);
      setInitialPolicy(policy);
    }
  }, [policy]);

  return (
    <style.Container>
      <h3> Proteção de dados</h3>
      <style.Card title="">
        <section style={{ display: 'flex' }}>
          <div className="section_big_box">
            <ScreenshotDisabled value={screenshotDisabled} onChange={(value: boolean) => handleChange(value, 'screenshot')} />
          </div>
          <div className="section_big_box">
            <OutgoingBeamDisabled value={outgoingBeamDisabled} onChange={(value: boolean) => handleChange(value, 'outgoingBeam')} />
          </div>
        </section>
        <section style={{ display: 'flex' }}>
          <div className="section_big_box">
            <UsbFileTransferDisabled value={usbFileTransferDisabled} onChange={(value: boolean) => handleChange(value, 'usbFileTransfer')} />
          </div>
          <div className="section_big_box">
            <MountPhysicalMediaDisabled value={mountPhysicalMediaDisabled} onChange={(value: boolean) => handleChange(value, 'mountPhysicalMedia')} />
          </div>
        </section>
      </style.Card>
    </style.Container>
  );
}
