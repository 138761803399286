import type { ReactElement } from 'react';
import type { HeaderPageComponentProps } from './HeaderPageComponent.contracts';
import * as S from './HeaderPageComponent.styles';

export const HeaderPageComponent = ({ title, description, children, countNumber, countText }: HeaderPageComponentProps): ReactElement => {
  return (
    <S.Container>
      <S.TitleAndDescription count={!!countText}>
        <h1>{title}</h1>
        {countNumber && countText && (
          <div>
            <b>{countNumber} </b>
            <span>{countText}</span>
          </div>
        )}
        <p>{description}</p>
      </S.TitleAndDescription>
      {children}
    </S.Container>
  );
};
