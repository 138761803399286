import { EuiButton, EuiCollapsibleNavGroup, EuiRadioGroup } from '@elastic/eui';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 290px;
  height: auto;
  padding: 10px;
`;

export const TabColumns = styled.div`
  display: flex;
  .euiTab {
    padding: 6px 30px !important;
  }
`;

export const Body = styled.div`
  height: auto;

  .css-1vs7z2v-MuiYearCalendar-root {
    width: 252px;
  }
`;

export const SelectionBtn = styled(EuiButton)`
  background: ${({ theme }) => theme.main} !important;
  border-color: ${({ theme }) => theme.main} !important;
  color: #fff;
  width: 100% !important;
  &:disabled {
    color: #fff !important;
  }
`;

export const DateBtn = styled(EuiButton)`
  &.euiButton {
    width: 100% !important;
  }

  :hover {
    text-decoration: none !important;
    text-transform: none !important;
    background-color: inherit !important;
  }
  pointer-events: none;
  cursor: auto !important;
`;

export const WrapperCalendar = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px 0;

  .euiTitle--xxsmall {
    color: #2196f3;
  }

  .euiCollapsibleNavGroup .euiAccordion__triggerWrapper {
    padding: 20px 0 0 0;
  }
`;

export const Radio = styled.input`
  height: 16px;
  width: 16px;
  cursor: pointer;
  z-index: 1;
`;

export const WrapRadio = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const Trace = styled.div`
  position: absolute;
  height: 1px;
  width: 95%;
  background-color: #d0d1d3;
  z-index: -1;
  top: 8px;
`;

export const Labels = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
`;

export const Radios = styled(EuiRadioGroup)`
  .euiRadioGroup__item {
    margin: 0;
    height: 16px;
    width: 16px;
    margin: 0px;
    margin-right: 28px;
    cursor: pointer;
    z-index: 1;
  }

  .euiRadio .euiRadio__input:focus + .euiRadio__circle {
    animation: none !important;
    -webkit-animation: none !important;
  }

  ${({ theme }) => css`
    .euiRadio .euiRadio__input:checked + .euiRadio__circle {
      border-color: ${theme.main};
      background-color: ${theme.main};
      width: 17px;
      height: 17px;
    }

    .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
      border-color: ${theme.main};
      background-color: ${theme.main};
    }

    &:focus {
      outline-color: ${theme.main};
    }
  `}
`;

export const RadioSection = styled(EuiCollapsibleNavGroup)`
  .euiTitle--xxsmall {
    color: ${({ theme }) => theme.main};
  }
`;

export const RadioSubtitle = styled.p`
  color: #a7a7a7;
  margin-bottom: 36px;
`;

export const SliderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const HourSlider = styled.div`
  font-weight: 400;
  font-size: 12px;
`;

export const LabelSlider = styled.label`
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
`;
