import axios from 'axios';
import { HelperAuthentication } from 'helpers';

import type { AxiosInstance } from 'axios';

export class ServicePulsusFrigg {
  static instance: AxiosInstance;

  static getInstance() {
    if (!this.instance) {
      this.instance = axios.create({
        baseURL: process.env.REACT_APP_URL_PULSUS_FRIGG_API,
        headers: {
          Authorization: HelperAuthentication.bearer(),
          'X-Schema': HelperAuthentication.decodedSchemaName(),
        },
      });
    }
    return this.instance;
  }

  static async addNewPermission(parent, policy_id: string, body) {
    const { data } = await this.getInstance().post(
      `/enterprise_${parent}/policies/${policy_id}/runtime_permission_policy/grant/set?x_schema=${HelperAuthentication.decodedSchemaName()}`,
      body
    );
    return data;
  }

  static async getPasswordSettings(parent = 'LC02vz2feo', policy_name = 'test_20') {
    const { data } = await this.getInstance().get(`/enterprise_${parent}/policies/get?policy_name=${policy_name}`);
    return data?.content?.passwordPolicies;
  }

  static async getGeneralSettings(parent = 'LC02vz2feo', policy_name = 'test_20') {
    const { data } = await this.getInstance().get(`/enterprise_${parent}/policies/get?policy_name=${policy_name}`);
    return data?.content;
  }

  static async setGeneralSettings(data, dispatch, parent = 'LC02vz2feo', policy_name = 'test_20') {
    this.getInstance()
      .post(`/enterprise_${parent}/policies/update?policy_name=${policy_name}&x_schema=${HelperAuthentication.decodedSchemaName()}`, data)
      .then(() => {
        dispatch({ type: 'SAVE_ANDROID_SETTINGS', payload: data });
      });
  }

  static async getQRCodeWP(parent = 'LC01w75bj5', policy_name = 'teste_grupo_QA') {
    const { data } = await this.getInstance().post(
      `/enterprise_${parent}/policies/${policy_name}/enroll?duration=3600s&personal_usage=true&one_time=false`
    );
    return data;
  }

  static async signUp(callbackUrl) {
    const { data } = await this.getInstance().get('/sign_up', {
      params: {
        callback_url: callbackUrl,
      },
    });

    return data?.content;
  }

  static async createCustomer(enterpriseToken, completion_token) {
    const { data } = await this.getInstance().post('/enterprise/customer_create', null, {
      params: {
        enterpriseToken,
        completion_token,
      },
    });

    return data?.content.private_key.data;
  }
}
