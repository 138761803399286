/* eslint-disable array-callback-return */
import React, { useState } from 'react';
import { EuiButtonEmpty, EuiFieldText, EuiFilePicker, EuiFlexItem, EuiFormRow, EuiText } from '@elastic/eui';

import { ComponentPopupError } from 'components/popup-error/component-popup-error';

import IntrustIcon from 'assets/images/entrust-icon.png';

import { ServicePulsusIduna } from 'services/ServicePulsusIduna';
import { useTranslation } from 'react-i18next';
import { HelperAuthentication } from 'helpers';
import * as style from './certificate-entrust-login-modal.style';
import type { CertificateConfigModalProps, InputFormProps } from '../page-certificate.contracts';

export function CertificateLoginModal({ onClose, handleOpenIdentityModal }: CertificateConfigModalProps) {
  const { t } = useTranslation();
  const [url, setUrl] = useState<InputFormProps>({ value: '', hasError: false });
  const [password, setPassword] = useState<InputFormProps>({ value: '', hasError: false });
  const [certificate, setCertificate] = useState<InputFormProps>({ value: '', hasError: false });
  const [identifier, setidentifier] = useState<InputFormProps>({ value: '', hasError: false });
  const [hasError, setHasError] = useState<boolean>(false);
  const [isB12Format, setIsB12Format] = useState<boolean>(true);

  const handleOnClose = () => {
    setCertificate({ value: '', hasError: false });
    setHasError(false);
    onClose();
  };

  function fileToBase64(fileElement: File) {
    const reader = new FileReader();
    reader.addEventListener(
      'load',
      () => {
        setCertificate({ value: reader.result as string, hasError: false });
      },
      false
    );
    if (fileElement) {
      reader.readAsDataURL(fileElement);
    }
  }

  const onChange = (value: [] | any) => {
    setIsB12Format(value[0].name.substr(-4).includes('.p12'));
    fileToBase64(value[0]);
  };

  const submitForm = () => {
    setHasError(false);

    if (url.value === '') {
      setUrl({ ...url, hasError: true });
      setHasError(true);
    }
    if (identifier.value === '') {
      setidentifier({ ...identifier, hasError: true });
      setHasError(true);
    }
    if (password.value === '') {
      setPassword({ ...password, hasError: true });
      setHasError(true);
    }

    if (!hasError) {
      const body = {
        url: url.value,
        password: password.value,
        certificate: certificate.value.split(',')[1],
        certification_authority_id: identifier.value,
      };

      ServicePulsusIduna.loginEntrust(HelperAuthentication.decodingTenantId(), body)
        .then((response) => {
          if (response.status === 200) {
            setHasError(false);
            handleOnClose();
            if (handleOpenIdentityModal) {
              handleOpenIdentityModal();
            }
          }
        })
        .catch(() => {
          setHasError(true);
        });
    }
  };
  return (
    <style.Modal onClose={handleOnClose} maxWidth="880px">
      <style.Content>
        <style.BodyModal />
        <style.Title>{t('pages.certificate_login.title')}</style.Title>
        <style.Text>{t('pages.certificate_login.phrase')}</style.Text>

        <style.EntrustLogo>
          <style.BallContainer>
            <style.Ball />
          </style.BallContainer>
          <style.IconEntrust src={IntrustIcon} />
        </style.EntrustLogo>

        <style.ContainerFull>
          <style.EuiFormRowStyled label="CA Gateway URL:">
            <style.EuiFieldTextStyled
              placeholder="URL"
              value={url.value}
              onChange={(e) => setUrl({ value: e.target.value, hasError: false })}
              name="url"
              isInvalid={url.hasError}
            />
          </style.EuiFormRowStyled>
        </style.ContainerFull>

        <style.FlexContainer>
          <style.ContainerLeft>
            <EuiFormRow label="CA Identifier:">
              <EuiFieldText
                placeholder="ecs1s3b5ffgvw-r7gvggd5wInfj8t"
                value={identifier.value}
                onChange={(e) => setidentifier({ value: e.target.value, hasError: false })}
                name="identifier"
                isInvalid={identifier.hasError}
              />
            </EuiFormRow>
          </style.ContainerLeft>

          <style.ContainerRight>
            <EuiFormRow label="PKCS12 Password:">
              <EuiFieldText
                type="password"
                value={password.value}
                placeholder="••••••••"
                onChange={(e) => setPassword({ value: e.target.value, hasError: false })}
                name="password"
                isInvalid={password.hasError}
              />
            </EuiFormRow>
          </style.ContainerRight>
        </style.FlexContainer>

        <style.ContainerFile>
          <EuiFlexItem>
            <style.SecondText>{t('pages.certificate_login.certificate_file')}</style.SecondText>
            <EuiText>
              <EuiFilePicker
                initialPromptText={t('pages.certificate_login.certificate_file_aria_label')}
                onChange={onChange}
                aria-label={t('pages.certificate_login.certificate_file_aria_label')}
                fullWidth
                compressed
                display="default"
                accept=".p12"
                required
                isInvalid={certificate.hasError}
              />
            </EuiText>
          </EuiFlexItem>
        </style.ContainerFile>

        <style.ButtonsContainer>
          {hasError && (
            <ComponentPopupError
              width="350px"
              height="94px"
              title={t('pages.certificate_login.generic_error_label')}
              description={t('pages.certificate_login.generic_error_phrase')}
            />
          )}
          <EuiButtonEmpty onClick={handleOnClose}>{t('pages.certificate_login.cancel_button')}</EuiButtonEmpty>
          <style.Button onClick={submitForm}>{t('pages.certificate_login.logon_button')}</style.Button>
        </style.ButtonsContainer>
      </style.Content>
    </style.Modal>
  );
}
