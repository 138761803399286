import { useTheme } from 'styled-components';
import * as S from './DashboardPage.styles';
import { DevicesStatisticsComponent, HeaderComponent } from '../../components/dashboard';
import { PercentageIndicatorsComponent } from '../../components/dashboard/PercentageIndicators/PercentageIndicatorsComponent';
import { WrapperComponent } from 'modules/core/components/Wrapper/WrapperComponent';

const DashboardPage = () => {
  const theme = useTheme();

  return (
    <WrapperComponent color={theme.dashboardTheme.background}>
      <S.Container>
        <HeaderComponent />
        <DevicesStatisticsComponent />
        <PercentageIndicatorsComponent />
      </S.Container>
    </WrapperComponent>
  );
};

export default DashboardPage;
