import { useEffect, useMemo, useState } from 'react';
import type { EuiSwitchEvent } from '@elastic/eui';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { useTranslation } from 'react-i18next';
import { ConstantColors } from 'utilities';
import { AdvancedFlag } from 'components';
import { mdiAndroid } from '@mdi/js';
import type { SafetyNetProps } from '../page-android-enterprise.contracts';
import * as style from './safety-net.style';
import type { GetSefetyNetProps } from './safety-net.contracts';
import { ConstantResource } from '../../../modules/core/utilities';
import { ComponentResourceChecker } from '../../../modules/core/components';

export function SafetyNet({ saveConfig, isEditable }: SafetyNetProps) {
  const { t } = useTranslation();
  const [isSafetyNetOn, setIsSafetyNetOn] = useState(true);
  const [isWPEnrollOn, setIsWPEnrollOn] = useState(false);
  const [isFMEnrollOn, setIsFMEnrollOn] = useState(false);
  const [dataFetched, setDataFetched] = useState(false);
  const msMultiplier = 3600000;
  const [verificationPeriod, setVerificationPeriod] = useState('verification_24');
  const [radioWPIdSelected, setRadioWPIdSelected] = useState('wp_BLOCK');
  const [radioFMIdSelected, setRadioFMIdSelected] = useState('fm_BLOCK');

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const radiosWP = [
    {
      id: 'wp_NOTHING',
      label: t('components.component_safetynet.inline_buttons_label.nothing'),
    },
    {
      id: 'wp_BLOCK',
      label: t('components.component_safetynet.inline_buttons_label.work_profile_block'),
    },
    {
      id: 'wp_WIPE',
      label: t('components.component_safetynet.inline_buttons_label.work_profile_wipe'),
    },
  ];

  const radiosFM = [
    {
      id: 'fm_NOTHING',
      label: t('components.component_safetynet.inline_buttons_label.nothing'),
    },
    {
      id: 'fm_WIPE',
      label: t('components.component_safetynet.inline_buttons_label.fully_managed_wipe'),
    },
  ];

  const periodOptions = [
    {
      id: 'verification_24',
      label: t('components.component_safetynet.button.period_24_option'),
    },
    {
      id: 'verification_12',
      label: t('components.component_safetynet.button.period_12_option'),
    },
    {
      id: 'verification_6',
      label: t('components.component_safetynet.button.period_6_option'),
    },
    {
      id: 'verification_2',
      label: t('components.component_safetynet.button.period_2_option'),
    },
  ];

  const onChangeWPOptions = (optionId: string) => {
    setRadioWPIdSelected(optionId);
  };

  const onChangeFMOptions = (optionId: string) => {
    setRadioFMIdSelected(optionId);
  };

  const onChangeSafetyNet = (e: EuiSwitchEvent) => {
    setIsSafetyNetOn(e.target.checked);
  };

  const onChangeWPEnroll = (e: EuiSwitchEvent) => {
    setIsWPEnrollOn(e.target.checked);
  };

  const onChangeFMEnroll = (e: EuiSwitchEvent) => {
    setIsFMEnrollOn(e.target.checked);
  };

  const saveSafetyNetConfig = () => {
    const WPAction = radioWPIdSelected.split('_')[1];
    const FMAction = radioFMIdSelected.split('_')[1];

    const body = {
      allowAttestation: isSafetyNetOn,
      timestampMS: Number(verificationPeriod.split('_')[1]) * msMultiplier,
      details: [
        {
          profile: 'WP',
          allowAttestationOnEnroll: isWPEnrollOn,
          action: WPAction,
        },
        {
          profile: 'FM',
          allowAttestationOnEnroll: isFMEnrollOn,
          action: FMAction,
        },
      ],
    };
    servicePulsusWeb.setSafetyNetSettings(body);
  };

  async function getSafetyNetConfig() {
    const data: GetSefetyNetProps = await servicePulsusWeb.getSafetyNetSettings();
    if (data && Object.keys(data).length > 0) {
      setDataFetched(true);
      setIsSafetyNetOn(data.allowAttestation);
      setVerificationPeriod(`verification_${data.timestampMS / msMultiplier}`);
      if (data.details && data.details.length > 0) {
        data.details.forEach((item) => {
          if (item.profile === 'WP') {
            setRadioWPIdSelected(`wp_${item.action}`);
            setIsWPEnrollOn(item.allowAttestationOnEnroll);
          } else {
            setRadioFMIdSelected(`fm_${item.action}`);
            setIsFMEnrollOn(item.allowAttestationOnEnroll);
          }
        });
      }
    }
  }

  useEffect(() => {
    getSafetyNetConfig();
  }, []);

  useEffect(() => {
    if (saveConfig) {
      saveSafetyNetConfig();
    }
  }, [saveConfig]);

  return (
    <ComponentResourceChecker identifier={ConstantResource.ANDROID_ENTERPRISE.PLAY_INTEGRITY}>
      {dataFetched && (
        <style.Content>
          <style.TopContainer>
            <style.TopLeft>
              <h4>{t('components.component_safetynet.text.title')}</h4>
              <style.SafetyNetSwitch
                data-testid="safety-net-check"
                label={t(
                  isSafetyNetOn
                    ? 'components.component_safetynet.button.safetynet_enabled'
                    : 'components.component_safetynet.button.safetynet_disabled'
                )}
                disabled={!isEditable}
                checked={isSafetyNetOn}
                onChange={(e) => onChangeSafetyNet(e)}
              />
              <p>{t('components.component_safetynet.text.safetynet_verify_description')}</p>
            </style.TopLeft>
            <style.TopRight>
              <p>{t('components.component_safetynet.text.period_label')}</p>
              <style.GroupButton
                legend=""
                data-testid="safety-net-period"
                isDisabled={!isSafetyNetOn || !isEditable}
                id="settingsManagementPeriod"
                idSelected={verificationPeriod}
                onChange={(e) => setVerificationPeriod(e)}
                options={periodOptions}
              />
            </style.TopRight>
          </style.TopContainer>
          <AdvancedFlag
            flags={[
              { managementType: 'FM', icon: mdiAndroid, version: '5.0' },
              { managementType: 'WP', icon: mdiAndroid, version: '5.0' },
              { managementType: 'FM + WP', icon: mdiAndroid, version: '5.0' },
            ]}
          />
          <style.Warning color="warning" data-testid="safety-net-warning">
            <p>
              <b>{t('components.component_safetynet.text.prefix_warning_message')}</b>
              {t('components.component_safetynet.text.warning_message')}
            </p>
          </style.Warning>
          <style.BottomContainer data-testid="safety-net-table">
            <div className="vertical-line" />
            <section className="title">
              <span />
              <span>{t('components.component_safetynet.column.enroll_activation')}</span>
              <span>
                {t('components.component_safetynet.column.prefix_device_integrity')}
                <span className="theme-color">{t('components.component_safetynet.column.device_integrity')}</span>
              </span>
            </section>
            <style.Options>
              <div className="icon-container">
                <WorkOutlinedIcon style={{ color: ConstantColors.FRANCE }} />
                <p>Work Profile</p>
              </div>
              <style.OptionsWPSwitch
                data-testid="safety-net-wp-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isWPEnrollOn
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isWPEnrollOn}
                onChange={(e) => onChangeWPEnroll(e)}
              />
              <style.OptionsWPRadios
                data-testid="safety-net-wp-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosWP}
                idSelected={radioWPIdSelected}
                onChange={(id) => onChangeWPOptions(id)}
                name="radio group WP"
              />
            </style.Options>
            <style.Options>
              <div className="icon-container">
                <div className="icon">
                  <PhoneAndroidIcon fontSize="small" style={{ color: ConstantColors.AUSTRIA }} />
                </div>
                <p>Fully Managed Device</p>
              </div>
              <style.OptionsFMSwitch
                data-testid="safety-net-fm-check"
                disabled={!isSafetyNetOn || !isEditable}
                label={
                  isFMEnrollOn
                    ? t('components.component_safetynet.button.allowed_label')
                    : t('components.component_safetynet.button.not_allowed_label')
                }
                checked={isFMEnrollOn}
                onChange={(e) => onChangeFMEnroll(e)}
              />
              <style.OptionsFMRadios
                data-testid="safety-net-fm-radios"
                disabled={!isSafetyNetOn || !isEditable}
                options={radiosFM}
                idSelected={radioFMIdSelected}
                onChange={(id) => onChangeFMOptions(id)}
                name="radio group FM"
              />
            </style.Options>
          </style.BottomContainer>
        </style.Content>
      )}
    </ComponentResourceChecker>
  );
}
