export class DashboardBenchmarks {
  public deviceByHours: number;
  public deviceByBattery: number;
  public deviceByStorage: number;
  public devicesByInactiveLauncher: number;
  public devicesByInactiveDeviceAdmin: number;
  public devicesByGpsOff: number;
  public devicesByLowBatteryHealth: number;
  public devicesByLowMemory: number;

  constructor(data: DashboardBenchmarks.ServerDefault & DashboardBenchmarks.ServerAndroid) {
    this.deviceByHours = data.devices_by_hours;
    this.deviceByBattery = data.devices_by_battery;
    this.deviceByStorage = data.devices_by_storage;
    this.devicesByInactiveLauncher = data.devices_by_inactive_launcher;
    this.devicesByInactiveDeviceAdmin = data.devices_by_inactive_device_admin;
    this.devicesByGpsOff = data.devices_by_gps_off;
    this.devicesByLowBatteryHealth = data.devices_by_low_battery_health;
    this.devicesByLowMemory = data.devices_by_low_memory;
  }
}

export namespace DashboardBenchmarks {
  export type ServerDefault = {
    devices_by_hours: number;
    devices_by_battery: number;
    devices_by_storage: number;
  };

  export type ServerAndroid = {
    devices_by_inactive_launcher: number;
    devices_by_inactive_device_admin: number;
    devices_by_gps_off: number;
    devices_by_low_battery_health: number;
    devices_by_low_memory: number;
  };
}
