import styled, { css } from 'styled-components';

export const Container = styled.section`
  width: 100%;
  display: grid;
  height: 100%;
  grid-template-columns: 8fr 4fr;
`;

export const MapContainer = styled.section`
  height: 100%;
  width: 100%;
`;

export const HistoryList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  gap: 4px;
  width: 300px;
  height: 100vh;
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 10fr 4fr;
  align-items: center;
  padding: 5px 16px;

  h1 {
    color: #828282;
    font-size: 12px;
    font-weight: 700;
  }
`;

export const List = styled.div`
  overflow: auto;
`;

export const HistoryItem = styled.a<{ selected?: boolean }>`
  display: grid;
  grid-template-columns: 1fr 10fr 1fr;
  align-items: center;
  padding: 20px 16px;
  text-decoration: none !important;
  background: ${({ selected, theme }) => (selected ? theme.main : 'inherit')};

  div:first-of-type {
    height: 100%;

    svg {
      width: 20px;
      margin-top: -3px;
    }
  }

  &:hover {
    background: ${({ selected, theme }) => (selected ? theme.main : ' #f3f3f3')};
  }
`;

export const Body = styled.div<{ selected?: boolean }>`
  ${({ selected, theme }) => css`
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: ${selected ? 'white' : '#a1a1a1'};

    h2 {
      color: ${selected ? 'white' : theme.main};
      font-size: 14px;
    }

    p {
      font-weight: 500;
      font-size: 12px;
      opacity: ${selected ? 0.7 : 1};

      strong {
        font-weight: 700;
      }
    }
  `}
`;

export const Forward = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NoLocation = styled.p`
  padding: 20px 32px;
  font-size: 14px;
  color: #a1a1a1;
`;
