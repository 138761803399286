import type { ReactElement } from 'react';
import type { IndicatorPercentageBarComponentProps } from './IndicatorPercentageBarComponent.contracts';
import * as S from './IndicatorPercentageBarComponent.styles';

export const IndicatorPercentageBarComponent = ({ label, count, value, inLimit }: IndicatorPercentageBarComponentProps): ReactElement => {
  return (
    <S.Table>
      <S.Label title={label} inLimit={inLimit}>
        {label}
      </S.Label>
      <p>{count}</p>
      <S.BasePercentageBar size={value}>
        <S.PercentageBar size={value} />
        <span>{value}%</span>
      </S.BasePercentageBar>
    </S.Table>
  );
};
