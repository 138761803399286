import styled from 'styled-components';
import { EuiText, EuiPanel, EuiFlyout } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Flyout = styled(EuiFlyout)`
  margin-top: 49px;
  overflow: auto;
  padding-bottom: 69px;
  width: 255px;
`;

export const Title = styled.h1`
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  color: ${({ theme }) => theme.main};
  width: 200px;
  margin-top: 10px;
`;

export const NavList = styled.nav`
  padding: 16px 0px 16px 5px;
`;

export const Panel = styled(EuiPanel)`
  color: ${ConstantColors.SPAIN};
  margin: 5px 0;

  &:hover {
    background: ${ConstantColors.BELIZE};
  }

  button:hover {
    text-decoration: underline;
  }
`;

export const Text = styled(EuiText)`
  font-size: 14px;
`;
