import { useState } from 'react';
import { ConstantRoutes } from 'utilities';
import { EuiPopover, EuiContextMenuPanel } from '@elastic/eui';
import { IconPulsusLogo } from 'icons';
import { useTheme } from 'styled-components';
import { DialogComponent } from 'components/modal/component-dialog';
import { LinkNotification } from './link-notifications/link-notification';
import { ButtonProfile } from './button-profile/button-profile';
import { ButtonContact } from './button-contact/button-contact';
import { ModalContact } from './modal-contact/modal-contact';
import { ModalProfile } from './modal-profile/modal-profile';

import { ButtonAdmin } from './button-admin/button-admin';
import { ModalAdmin } from './modal-admin/modal-admin';
import { FlyoutTenant } from './flyout-tenant/flyout-tenant';
import * as style from './component-application-header.style';
import { OperationalSystemPopup } from './enroll-popups/operational-system-popup';
import { EnrollInformationPopup } from './enroll-popups/enroll-information-popup';

export function ComponentApplicationHeader() {
  const theme = useTheme();

  const [is_contact_popover_open, set_is_contact_popover_open] = useState(false);
  const [is_admin_popover_open, set_is_admin_popover_open] = useState(false);
  const [is_profile_popover_open, set_is_profile_popover_open] = useState(false);
  const [is_tenant_flyout_open, set_is_tenant_flyout_open] = useState(false);
  const [enrollModalOpen, setEnrollModalOpen] = useState(false);
  const [enrollType, setEnrollType] = useState<'chrome' | 'android' | 'ios' | ''>('');

  const handleOpenContactPopover = () => {
    set_is_contact_popover_open(!is_contact_popover_open);
    set_is_admin_popover_open(false);
    set_is_profile_popover_open(false);
    set_is_tenant_flyout_open(false);
  };

  const handleOpenAdminPopover = () => {
    set_is_admin_popover_open(!is_admin_popover_open);
    set_is_contact_popover_open(false);
    set_is_profile_popover_open(false);
    set_is_tenant_flyout_open(false);
  };

  const handleOpenProfilePopover = () => {
    set_is_profile_popover_open(!is_profile_popover_open);
    set_is_admin_popover_open(false);
    set_is_contact_popover_open(false);
    set_is_tenant_flyout_open(false);
  };

  const handleOpenFlyoutTenant = () => {
    set_is_tenant_flyout_open(!is_tenant_flyout_open);
    set_is_profile_popover_open(false);
    set_is_admin_popover_open(false);
    set_is_contact_popover_open(false);
  };

  const handleCloseContactPopover = () => {
    set_is_contact_popover_open(false);
  };

  const handleCloseAdminPopover = () => {
    set_is_admin_popover_open(false);
  };

  const handleCloseProfilePopover = () => {
    set_is_profile_popover_open(false);
  };

  function onCloseEnrollModal() {
    setEnrollModalOpen(false);
    setTimeout(() => {
      setEnrollType('');
    }, 1000);
  }

  return (
    <style.Header position="fixed" data-testid="component-aplication-header">
      <style.Content>
        <style.TenantInformation>
          <style.TenantLogoLink to={ConstantRoutes.HOME.path} data-testid="header-logo-link">
            <IconPulsusLogo width={135} height={25} color={theme.contrast} test_id="header-logo-icon" />
          </style.TenantLogoLink>
          <style.TenantName data-testid="header-tenat-name">MWC 2022</style.TenantName>
        </style.TenantInformation>
        <style.ActionsContainer>
          {/* Linha de baixo comentada até o termino dos ajuste para o tipo Android e iOS */}
          {/* <style.EnrollButton iconType="plusInCircle" fill onClick={() => setEnrollModalOpen(true)}>
            Adicionar dispositivos
          </style.EnrollButton> */}
          <EuiPopover
            id="contact-popover"
            button={<ButtonContact onClick={handleOpenContactPopover} />}
            isOpen={is_contact_popover_open}
            closePopover={handleCloseContactPopover}
            panelPaddingSize="none"
            data-testid="header-contact-popover"
          >
            <EuiContextMenuPanel size="s">
              <ModalContact />
            </EuiContextMenuPanel>
          </EuiPopover>
          <EuiPopover
            id="admin-popover"
            button={<ButtonAdmin onClick={handleOpenAdminPopover} data-testid="header-button-admin" />}
            isOpen={is_admin_popover_open}
            closePopover={handleCloseAdminPopover}
            panelPaddingSize="none"
            data-testid="header-admin-popover"
          >
            <EuiContextMenuPanel>
              <ModalAdmin />
            </EuiContextMenuPanel>
          </EuiPopover>
          <LinkNotification />
          <EuiPopover
            id="profile-popover"
            button={<ButtonProfile onClick={handleOpenProfilePopover} data-testid="header-button-profile" />}
            isOpen={is_profile_popover_open}
            closePopover={handleCloseProfilePopover}
            panelPaddingSize="none"
            data-testid="header-profile-popover"
          >
            <EuiContextMenuPanel>
              <ModalProfile openTenantFlyout={handleOpenFlyoutTenant} />
            </EuiContextMenuPanel>
          </EuiPopover>
        </style.ActionsContainer>
        {is_tenant_flyout_open && <FlyoutTenant setIsTenantFlyoutOpen={set_is_tenant_flyout_open} />}
        <DialogComponent
          height="100%"
          width="100%"
          maxHeight="600px"
          maxWidth="1000px"
          zoom={0.9}
          closeOnClickOutside
          open={enrollModalOpen}
          onClose={() => {
            onCloseEnrollModal();
          }}
        >
          {!enrollType && <OperationalSystemPopup onSelect={(e) => setEnrollType(e)} />}
          {enrollType && <EnrollInformationPopup onReturn={() => setEnrollType('')} onCancel={() => onCloseEnrollModal()} type={enrollType} />}
        </DialogComponent>
      </style.Content>
    </style.Header>
  );
}
