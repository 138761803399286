import styled from 'styled-components';
import { EuiModal, EuiButton, EuiForm, EuiRadioGroup } from '@elastic/eui';

export const Modal = styled(EuiModal)`
  position: relative;

  .euiFormRow {
    label {
      font-weight: 700;
    }
  }

  .euiModal__flex {
    width: 880px;
    max-height: fit-content;
  }
`;

export const Variables = styled.div`
  height: 41px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 109, 228, 0.1);
  }

  span {
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 25px 70px 32px;
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  line-height: 40px;
  font-size: 22px;
  color: ${({ theme }) => theme.main};
  margin-bottom: 20px;
`;

export const Form = styled(EuiForm)`
  width: 100%;
`;

export const TopContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const TopLeft = styled.section`
  .euiFormControlLayout {
    width: 297px;
    margin: 8px 0;
  }
`;
export const TopRight = styled.section`
  .euiFormControlLayout {
    width: 381px;
    margin: 10px 0;
  }

  .euiCallOut {
    display: flex;
    align-items: center;
    border: none;
    padding: 10px;
    border-radius: 5.31936px;
    margin-top: 25px;
    height: 47px;
    width: 381px;

    p {
      color: #8a6a0a;
      font-weight: 500;
    }
  }

  .euiText {
    margin: 0;
  }
`;

export const CertifierCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 381px;
  height: 148px;
  border-radius: 4.619px;
  padding: 25px 80px;
  margin: 30px 0;
  box-shadow:
    0px 11.548px 11.548px -0.77px rgba(0, 0, 0, 0.04),
    0px 4.388px 9.239px -0.77px rgba(0, 0, 0, 0.05),
    0px 2.002px 6.159px -0.77px rgba(0, 0, 0, 0.06),
    0px 0.693px 3.08px -0.77px rgba(0, 0, 0, 0.08);

  .title {
    position: absolute;
    border-radius: 9.239px;
    border: 0.77px solid #d3dae6;
    width: 105px;
    height: 19px;
    display: flex;
    align-items: center;
    top: -10px;
    background-color: #fff;

    p {
      width: 100%;
      font-size: 10px;
      font-weight: 700;
      text-transform: uppercase;
      text-align: center;
    }
  }

  .entrust-logo {
    width: 173px;
    margin-bottom: 15px;
  }

  p {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
  }
`;

export const BottomContainer = styled.section`
  display: flex;
  margin-top: 30px;
  gap: 23px;

  .euiFormControlLayout {
    width: 230px;
  }

  .euiFormRow {
    margin: 0;
  }
`;

export const SubmitContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

export const Container = styled.div`
  position: relative;
`;

export const ExclamationIcon = styled.img`
  margin-left: 5px;
  top: 2px;
  position: relative;
`;
export const Label = styled.label`
  font-size: 12px;
  font-size: 0.75rem;
  line-height: 1.5;
  color: #1a1c21;
  font-weight: 600;
`;
export const Button = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  margin-left: 20px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;

export const AuthorizeSwitch = styled.div`
  height: 50px;
  width: 100%;
  margin-top: 30px;
`;

export const BtnPlus = styled.img`
  height: 15px;
  width: 15px;
  margin-right: 9px;
`;

export const WrapBottonContent = styled.div`
  display: flex;
  align-items: center;
`;
export const LinkAddApp = styled.span`
  display: flex;
  align-items: center;
`;
export const AddAppText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0px;
  text-align: center;
  color: #30a8ff;
  cursor: pointer;
`;

export const RadiosCN = styled(EuiRadioGroup)`
  display: flex;

  .euiRadioGroup__item {
    margin-top: 10px;
    margin-right: 15px;
  }

  .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
    border-color: #ff4c0c;
    background-color: #ff4c0c;
  }

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: #ff4c0c;
    background-color: #ff4c0c;
  }
  &:focus {
    outline-color: #ff4c0c;
  }
`;
