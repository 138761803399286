import styled from 'styled-components';
import type { DashboardStyleProps } from './component-dashboard-card.contracts';

export const Container = styled.div``;

export const Card = styled.div<Pick<DashboardStyleProps, 'background'>>`
  display: flex;
  flex-direction: column;
  height: 113px;
  width: 133px;
  border-radius: 5px;
  padding: 16px 14px;
  gap: 4px;
  margin: 2px;
  background: ${({ background }) => background};
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardIcon = styled.div``;

export const CardTitle = styled.p`
  font-weight: 600;
  font-size: 8px;
  line-height: 24px;
  color: #343741;
`;

export const CardBody = styled.div``;

export const CardInfo = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 13px;
  text-align: right;
  color: ${({ theme }) => theme.main};
`;

export const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
  gap: 3px;
`;
export const CardStatus = styled.div`
  display: flex;
  padding-top: 2px;
`;
export const CardDescription = styled.div`
  font-weight: 400;
  font-size: 8.86364px;
  line-height: 13px;
  text-align: right;
  color: #343741;
  width: 100%;
`;
