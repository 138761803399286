import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 50px 0 40px;
  width: 100%;
  max-width: 1200px;
  background: white;
`;

export const BreadcrumbBox = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ul {
    padding: 8px 0 40px 0;
  }
`;

export const Content = styled.div`
  overflow: hidden;
  .compressed {
    animation-duration: 0.22s;
    animation-name: compress;
    animation-timing-function: linear;
    height: 60px;

    @keyframes compress {
      0% {
        opacity: 1;
        height: 410px;
        transform: translateY(60px);
      }
      25% {
        opacity: 0.66;
      }
      50% {
        opacity: 0.33;
      }
      75% {
        opacity: 0;
        height: 60px;
      }
      100% {
        transform: translateY(-150px);
      }
    }
  }

  .expand {
    animation-duration: 0.22s;
    animation-name: expand;
    animation-timing-function: linear;

    @keyframes expand {
      0% {
        opacity: 0;
        height: 0px;
      }
      25% {
        opacity: 0.33;
      }
      50% {
        opacity: 0.66;
      }
      75% {
        opacity: 1;
      }
      100% {
        height: 300px;
      }
    }
  }
`;

export const FixedContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  z-index: 450;
  overflow: hidden;
  background-color: white;
  width: 100%;
`;

export const CustomContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  z-index: 400;
  width: 100%;
`;

export const HeaderInfos = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const HeaderIdentifier = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  font-weight: 700;
  font-size: 25px;
  line-height: 40px;
  color: #1a1c21;
`;

export const HeaderStatus = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  p {
    font-weight: 400;
    font-size: 14.6667px;
    line-height: 24px;
    color: #343741;
  }
`;

export const LastSync = styled.div`
  font-style: italic;
  font-weight: 300;
  font-size: 14.6667px;
  line-height: 24px;
  .euiTextColor--secondary {
    font-weight: 700;
    color: #343741;
  }
`;

export const IconGroup = styled.div`
  display: flex;
  gap: 20px;
  section {
    display: flex;
    gap: 5px;
  }
  button {
    background: #f9f9f9;
  }

  button:focus,
  button:hover {
    background: #878787;
    path {
      fill: white !important;
    }
  }

  button.disabled,
  button:focus.disabled,
  button:hover.disabled {
    cursor: not-allowed;
    background: #f9f9f9 !important;
    path {
      fill: #ced2d9 !important;
    }
  }
`;

export const BodyInfos = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0 0 40px;

  .maxWidth {
    width: 66.6666% !important;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 33.3333%;
  padding-bottom: 40px;
`;

export const Label = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;
export const Value = styled.p`
  font-weight: 400;
  font-size: 15px;
  line-height: 24px;
  color: #343741;
`;

export const ExpandButton = styled(EuiButton)`
  width: 231px;
  height: 40px;
  background: rgba(105, 112, 125, 0.2) !important;
  border-radius: 6px;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #343741 !important;
`;

export const TabsHeader = styled.div`
  .MuiTab-textColorPrimary {
    text-transform: none !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #343741;
  }
  .Mui-selected {
    color: ${({ theme }) => theme.main} !important;
  }

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.main} !important;
  }
`;

export const TabsBox = styled.div`
  background-color: #f9f9f9;
`;

export const TabsContent = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1200px;
  background-color: #f9f9f9;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const UnlinkModalContent = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 45px;
  height: 100%;
  width: 100%;
`;

export const Footer = styled.div`
  display: flex;
  gap: 20px;
  .euiButton:hover,
  .euiButton:focus {
    color: #ffffff;
    background: ${({ theme }) => theme.main};
  }
  .euiButtonEmpty:hover,
  .euiButtonEmpty:focus {
    background: transparent;
  }
`;

export const Button = styled(EuiButton)`
  min-width: 200px;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  background: ${({ theme }) => theme.main};
  border: none;
  border-radius: 4.73624px;
`;

export const LinkButton = styled(EuiButtonEmpty)``;
