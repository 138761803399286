import { ConstantRoutes } from 'utilities';
import { EuiIcon } from '@elastic/eui';
import { useTheme } from 'styled-components';
import * as style from './link-notification.style';

export function LinkNotification() {
  const theme = useTheme();

  return (
    <style.LinkContainer data-testid="link-notification" to={ConstantRoutes.NOTIFICATIONS.path}>
      <EuiIcon data-testid="link-notification-icon" type="bell" color={theme.contrast} />
    </style.LinkContainer>
  );
}
