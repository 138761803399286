import { useState } from 'react';

import { EuiOverlayMask, EuiFieldPassword, EuiFormRow, EuiButton } from '@elastic/eui';

import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import * as style from './modal-change-password.style';
import { IconProfile } from '../../../icons/profile/icon-profile';
import type { ModalChangePasswordProps } from '../component-application-header.contracts';

export function ModalChangePassword({ onClose }: ModalChangePasswordProps) {
  const theme = useTheme();
  const { t } = useTranslation();

  const [current_password, set_current_password] = useState('');
  const [new_password, set_new_password] = useState('');
  const [confirmation_password, set_confirmation_password] = useState('');
  const [showErrors] = useState(true);

  const handleOnClose = () => {
    onClose();
  };

  return (
    <EuiOverlayMask>
      <style.Modal onClose={handleOnClose} data-testid="modal_change_password">
        <style.ModalHeader>
          <style.ModalFigure>
            <IconProfile fill={theme.main} width="30px" height="30px" />
          </style.ModalFigure>
          <style.ModalTitle data-testid="change_password_titles">
            <span>{t('components.component_change_password_modal.change_password_title')}</span>
            {t('components.component_change_password_modal.change_password_subtitle')}
          </style.ModalTitle>
        </style.ModalHeader>

        <style.ModalForm data-testid="change_password_form">
          <EuiFormRow
            label={`${t('components.component_change_password_modal.current_password_label')}`}
            isInvalid={showErrors}
            error={`${t('components.component_change_password_modal.current_password_error')}`}
          >
            <EuiFieldPassword
              placeholder={`${t('components.component_change_password_modal.current_password_label')}`}
              type="dual"
              value={current_password}
              onChange={(e) => set_current_password(e.target.value)}
              aria-label={`${t('components.component_change_password_modal.current_password_label')}`}
              isInvalid={showErrors}
              data-testid="input_current_password"
            />
          </EuiFormRow>
          <EuiFormRow label={`${t('components.component_change_password_modal.new_password_label')}`}>
            <EuiFieldPassword
              placeholder={`${t('components.component_change_password_modal.new_password_label')}`}
              type="dual"
              value={new_password}
              onChange={(e) => set_new_password(e.target.value)}
              aria-label={`${t('components.component_change_password_modal.new_password_label')}`}
              data-testid="input_new_password"
            />
          </EuiFormRow>
          <EuiFormRow label={`${t('components.component_change_password_modal.confirmation_password_label')}`}>
            <EuiFieldPassword
              placeholder={`${t('components.component_change_password_modal.confirmation_password_label')}`}
              type="dual"
              value={confirmation_password}
              onChange={(e) => set_confirmation_password(e.target.value)}
              aria-label={`${t('components.component_change_password_modal.confirmation_password_label')}`}
              data-testid="input_confirmation_password"
            />
          </EuiFormRow>

          <style.ModalButtonContainer grow={false}>
            <EuiButton fullWidth fill onClick={() => null} data-testid="confirmation_password_button">
              {t('components.component_change_password_modal.confirmation_password_button')}
            </EuiButton>
          </style.ModalButtonContainer>
        </style.ModalForm>
      </style.Modal>
    </EuiOverlayMask>
  );
}
