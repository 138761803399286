import { EuiFlyoutHeader, EuiTitle } from '@elastic/eui';
import { Tenant } from 'entities/tenant';
import { useTranslation } from 'react-i18next';
import type { FlyoutTenantProps } from '../component-application-header.contracts';
import * as style from './flyout-tenant.style';

const tenant = [
  new Tenant(1, 'CLIENTE 01'),
  new Tenant(2, 'CLIENTE 02'),
  new Tenant(3, 'CLIENTE 03'),
  new Tenant(4, 'CLIENTE 04'),
  new Tenant(5, 'CLIENTE 05'),
  new Tenant(6, 'CLIENTE 06'),
  new Tenant(7, 'CLIENTE 07'),
  new Tenant(8, 'CLIENTE 08'),
  new Tenant(9, 'CLIENTE 09'),
  new Tenant(10, 'CLIENTE 10'),
  new Tenant(11, 'CLIENTE 11'),
  new Tenant(12, 'CLIENTE 12'),
  new Tenant(13, 'CLIENTE 13'),
  new Tenant(14, 'CLIENTE 14'),
];

export function FlyoutTenant({ setIsTenantFlyoutOpen }: FlyoutTenantProps) {
  const { t } = useTranslation();

  const handleOnClose = () => {
    setIsTenantFlyoutOpen(false);
  };

  return (
    <style.Flyout onClose={() => handleOnClose()} ownFocus={false} size="280px" paddingSize="m" data-testid="flyout_tenant">
      <EuiFlyoutHeader hasBorder>
        <EuiTitle size="xs">
          <style.Title data-testid="flyout_tenant.title">{t('components.component_flyout_tenant.title')}</style.Title>
        </EuiTitle>
      </EuiFlyoutHeader>

      <style.NavList>
        {tenant.map((item) => {
          return (
            <style.Panel key={item.id}>
              <a href="/">
                <style.Text>{item.name}</style.Text>
              </a>
            </style.Panel>
          );
        })}
      </style.NavList>
    </style.Flyout>
  );
}
