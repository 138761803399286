import styled from 'styled-components';

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Label = styled.label`
  font-size: 12px;
  font-weight: 700;
  text-transform: uppercase;
  color: #1a1c21;
`;

export const Value = styled.span`
  font-size: 15px;
  font-weight: 400;
  color: #343741;
`;
