import type { Group } from 'entities/group';

export type TriggerType = 'ON_ENTER' | 'ON_EXIT' | 'BOTH' | null;

const ONE_KILOMETER = 1000;

export class Geofence {
  public name: string;
  public description: string;
  public radius: string;
  public status: boolean;
  public latitude: number;
  public longitude: number;
  public id: number;
  public total_pages: number;
  public groups: Group[] | null;
  public blockDevice: boolean;
  public trigger: TriggerType;
  public index?: number;
  public hasChanged: boolean;

  constructor(data: Geofence.Server) {
    this.name = data.name;
    this.description = data.description;
    this.radius = this.formatRadius(data.radius);
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.status = data.status;
    this.id = data.id;
    this.total_pages = data.total_pages;
    this.blockDevice = data?.block_device;
    this.trigger = data?.trigger as TriggerType;
    this.groups = null;
    this.index = data?.index;
    this.hasChanged = false;
  }

  formatRadius(radius: number): string {
    if (radius >= ONE_KILOMETER) {
      const kmValue = radius / ONE_KILOMETER;
      return `${kmValue}km`;
    }
    return `${radius}m`;
  }
}

export namespace Geofence {
  export type Server = {
    name: string;
    description: string;
    radius: number;
    latitude: number;
    longitude: number;
    id: number;
    status: boolean;
    total_pages: number;
    block_device: boolean;
    trigger: string;
    index?: number;
  };
}
