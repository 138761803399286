import { Breadcrumb } from 'entities/breadcrumb';

export const ManagementBreadcrumbs = {
  PULSUS_LOCATION: {
    route: '/management/locations',
    breadcrumbs: [new Breadcrumb('components.breadcrumb.home', '/'), new Breadcrumb('components.breadcrumb.location', '/')],
  },
  PULSUS_GEOFENCE: {
    route: 'management/locations/geofence',
    breadcrumbs: [
      new Breadcrumb('components.breadcrumb.home', '/'),
      new Breadcrumb('components.breadcrumb.location', '/management/locations'),
      new Breadcrumb('components.breadcrumb.geofence', '/management/locations/geofence'),
    ],
  },
  PULSUS_MANAGEMENT_SIM: {
    route: '/management/sim-management',
    breadcrumbs: [new Breadcrumb('components.breadcrumb.home', '/'), new Breadcrumb('components.breadcrumb.sim_management', '')],
  },
};
