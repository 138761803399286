import { useTypedTranslation } from 'modules/core/hooks';
import type { DataAdvancedSearchProps, SelectProps } from './AdvancedSearchComponent.contracts';

export function DataAdvancedSearch() {
  const { t } = useTypedTranslation<'devices.advanced_search'>('devices.advanced_search');

  const dateOptions: SelectProps[] = [
    { value: 'created', text: t('date_options_A') },
    { value: 'deleted', text: t('date_options_B') },
  ];

  const SOVersionOptionsAndroid = [{ value: '', text: t('so_version') }];

  const SOVersionOptionsIOS = [{ value: '', text: t('so_version') }];

  const SOVersionOptionsChrome = [{ value: '', text: t('so_version') }];
  const orderOptions: DataAdvancedSearchProps[] = [
    { value: 'group_name', text: t('order_options.group_name') },
    { value: 'battery_level', text: t('order_options.battery_level') },
    { value: 'user_name', text: t('order_options.username') },
    { value: 'last_sync', text: t('order_options.last_sync') },
    { value: '', text: t('order_options.id') },
  ];

  const rangeLevels = [
    {
      min: 0,
      max: 20,
      color: 'danger',
    },
    {
      min: 20,
      max: 40,
      color: 'warning',
    },
    {
      min: 40,
      max: 100,
      color: 'success',
    },
  ];

  const rangeTicks = [
    { label: '0', value: 0 },
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: '60', value: 60 },
    { label: '70', value: 70 },
    { label: '80', value: 80 },
    { label: '90', value: 90 },
    { label: '100', value: 100 },
  ];

  return {
    dateOptions,
    SOVersionOptionsAndroid,
    SOVersionOptionsIOS,
    SOVersionOptionsChrome,
    rangeLevels,
    rangeTicks,
    orderOptions,
  };
}
