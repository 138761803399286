import styled from 'styled-components';
import { Input } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export const Container = styled.div``;

export const WebSitesList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: auto;
  scrollbar-width: thin;
`;

export const WebSiteItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #cec7d1;

  p {
    font-size: 13px;
    font-weight: 400;
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InputApp = styled(Input)`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const AddAppIcon = styled(AddCircleIcon)`
  color: #30a8ff;
  cursor: pointer;
  pointer-events: auto;
`;

export const PhraseInfo = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 13px;
  margin-bottom: 25px;
`;

export const InputError = styled.p`
  color: red;
  margin: 2px 0 5px 0;
`;
