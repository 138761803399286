import type { StyleProps } from './IconCellComponent.contracts';
import styled, { css } from 'styled-components';

const getFontSize = (hasColor: boolean, hasIcon: boolean) => {
  if (hasColor) {
    return '24px';
  }

  if (hasIcon) {
    return '20px';
  }

  return '16px';
};

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 5px;
  cursor: inherit;

  ${({ onClick }) =>
    onClick &&
    css`
      cursor: pointer;
      &:hover {
        backdrop-filter: invert(5%);
        transition: all ease 0.3s;
      }
    `}

  div {
    display: flex;
    align-items: center;
    min-height: 28px;
  }
`;

export const IconCellValue = styled.p<StyleProps>`
  ${({ theme, color, hasIcon, hasColor }) => css`
    color: ${color || theme.dashboardTheme.font};
    font-size: ${getFontSize(!!hasColor, !!hasIcon)};
    font-weight: 400;
    margin-left: ${hasColor ? '0' : '5px'};
  `}
`;

export const IconCellText = styled.p<StyleProps>`
  text-transform: uppercase;
  margin-top: 5px;
  color: #818181;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  max-width: 120px;
`;
