import type { MenuTabProps } from '../page-settings.contracts';
import * as style from './menu-tab.style';

export function MenuTab({ icon, text, isSelected, onClick }: MenuTabProps) {
  return (
    <style.Container onClick={() => onClick(text)} isSelected={isSelected}>
      {icon}
      <p>{text}</p>
    </style.Container>
  );
}
