import { AddEmployeeUserModalComponent } from 'modules/operational/components/employeeUsers';
import * as S from './EmbeddedAddEmployeUserModal.styles';
import type { Toast } from 'components/toast/component-toast.contracts';

const EmbeddedAddEmployeeUserModal = () => {
  const handleCloseModal = () => {
    window.parent.postMessage(JSON.stringify({ event: 'onClose' }), process.env.REACT_APP_PULSUS_WEB_HOST!);
  };

  const handleError = (value: Toast[]) => {
    window.parent.postMessage({ event: 'error', payload: value[0]?.title }, process.env.REACT_APP_PULSUS_WEB_HOST!);
  };

  const handleSuccess = (value: Toast[]) => {
    window.parent.postMessage({ event: 'success', payload: value[0]?.title }, process.env.REACT_APP_PULSUS_WEB_HOST!);
  };

  const emitChange = () => {
    window.parent.postMessage({ event: 'reload' }, process.env.REACT_APP_PULSUS_WEB_HOST!);
  };

  return (
    <S.EmbeddedContent>
      <AddEmployeeUserModalComponent
        isEmbedded
        emitChange={emitChange}
        handleCloseModal={handleCloseModal}
        handleError={handleError}
        handleSuccess={handleSuccess}
      />
    </S.EmbeddedContent>
  );
};

export default EmbeddedAddEmployeeUserModal;
