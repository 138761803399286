import type { AvailableOptionalThemes } from 'modules/core/contexts/theme';
import { Resource } from '../Resource/Resource.entity';
import DEFAULT_LOGO from 'assets/images/new-logo-white.svg';

type BillingStatusProps = 'TRIAL' | 'PAYING' | 'TRIAL_BLOCKED' | 'OVERDUE_PAYMENT';

export class Tenant {
  public id: number;
  public name: string;
  public theme: AvailableOptionalThemes;
  public logoUrl: string;
  public bundle: { identifier: BundleIdentifier; id: number };
  public resources: Resource[];
  public billingStatus: BillingStatusProps;
  public trialDaysRemaining: number;

  constructor(tenant: Tenant.Server) {
    this.id = tenant.tenant_id;
    this.name = tenant.name;
    this.theme = tenant.theme?.toUpperCase() as AvailableOptionalThemes;
    this.logoUrl = tenant.logo_url || DEFAULT_LOGO;
    this.bundle = tenant.bundle;
    this.resources = tenant.resources.map((item) => new Resource(item));
    this.billingStatus = tenant.billing_status;
    this.trialDaysRemaining = tenant.trial_days_remaining;
  }
}

export namespace Tenant {
  export type Server = {
    tenant_id: number;
    name: string;
    theme: string;
    logo_url: string;
    bundle: { identifier: BundleIdentifier; id: number };
    resources: Resource.Server[];
    billing_status: BillingStatusProps;
    trial_days_remaining: number;
  };
}

type BundleIdentifier = 'legacy' | 'inventory' | 'basic_management' | 'basic_security' | 'pro' | 'intelligence';
