import { useEffect, useState } from 'react';
import i18next from 'i18next';
import { useQuery } from 'hooks/use-query';
import { ConstantLanguages } from 'utilities';
import { FullyCardSettings } from 'components';
import * as style from './embedded-fm-card-settings.style';
import { Events, Origins } from './embedded-fm-card-settings.contracts';

export function EmbeddedFullyCardSettings() {
  const query = useQuery();
  const [cameraDisabled, setCameraDisabled] = useState(false);
  const [screenCaptureDisabled, setScreenCaptureDisabled] = useState(false);
  const [maxDaysWithWorkOff, setMaxDaysWithWorkOff] = useState({ active: false, value: 3 });
  const [personalPlayStoreManagement, setPersonalPlayStoreManagement] = useState({
    active: false,
    personalApplications: [],
    personalPlayStoreMode: 'BLOCKLIST',
  });

  function sendChanges() {
    sendPostMessage({
      from: Origins.NJORD,
      event: Events.ON_CHANGE,
      tab: 'fully_managed',
      cameraDisabled,
      screenCaptureDisabled,
      maxDaysWithWorkOff,
      personalPlayStoreManagement,
      send: true,
    });
  }

  async function handleChangeBoolean(value: boolean, key: string) {
    switch (key) {
      case 'deviceCamera':
        setCameraDisabled(value);
        break;
      case 'maxDaysWithWorkOffActive':
        setMaxDaysWithWorkOff((prevProps) => {
          return { ...prevProps, active: value };
        });
        break;
      case 'screenshot':
        setScreenCaptureDisabled(value);
        break;
      default:
        break;
    }
  }

  function handleChangeNumber(value: number, key: string) {
    switch (key) {
      case 'maxDaysWithWorkOffValue':
        setMaxDaysWithWorkOff((prevProps) => {
          return { ...prevProps, value };
        });
        break;
      default:
        break;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleChangePlayStoreManagement(value: any, key: string) {
    switch (key) {
      case 'active':
        setPersonalPlayStoreManagement((prevProps) => {
          return { ...prevProps, active: value };
        });
        break;
      case 'mode':
        setPersonalPlayStoreManagement((prevProps) => {
          return { ...prevProps, personalPlayStoreMode: value };
        });
        break;
      case 'apps':
        setPersonalPlayStoreManagement((prevProps) => {
          return { ...prevProps, personalApplications: value };
        });
        break;
      default:
        break;
    }
  }

  function populate(event: MessageEvent) {
    if (event?.data) {
      const data = JSON.parse(event.data);
      if (!data.send) {
        setCameraDisabled(data.cameraDisabled);
        setScreenCaptureDisabled(data.screenCaptureDisabled);
        setMaxDaysWithWorkOff(data.maxDaysWithWorkOff);
        setPersonalPlayStoreManagement(data.personalPlayStoreManagement);
      }
    }
  }

  const sendPostMessage = (payload = {}) => {
    window.parent.postMessage(JSON.stringify(payload), process.env.REACT_APP_RUBY_LINK!);
  };

  window.addEventListener('message', (event) => {
    if (event && event.data) {
      if (typeof event.data === 'string' && event.data.charAt(0) === '{') {
        const payload = JSON.parse(event.data);

        if (payload.from === Origins.RAILS && payload.tab === 'fully_managed') {
          populate(event);
        }
      }
    }
  });

  useEffect(() => {
    i18next.changeLanguage(query.get('language') || ConstantLanguages.PT_BR);

    sendPostMessage({
      from: Origins.NJORD,
      tab: 'fully_managed',
      event: Events.ON_READY,
    });

    return () => {
      window.removeEventListener('message', (e) => e);
    };
  }, []);

  useEffect(() => {
    sendChanges();
  }, [cameraDisabled, screenCaptureDisabled, maxDaysWithWorkOff, personalPlayStoreManagement]);

  return (
    <style.Container>
      <FullyCardSettings
        cameraDisabled={cameraDisabled}
        screenCaptureDisabled={screenCaptureDisabled}
        maxDaysWithWorkOff={maxDaysWithWorkOff}
        personalPlayStoreManagement={personalPlayStoreManagement}
        onChangeNumber={(value, key) => handleChangeNumber(value, key)}
        onChangeBoolean={(value, key) => handleChangeBoolean(value, key)}
        handleChangePlayStoreManagement={(value, key) => handleChangePlayStoreManagement(value, key)}
      />
    </style.Container>
  );
}
