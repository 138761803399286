import { DialogComponent } from 'components';
import type { BulkConfirmationModalProps } from './BulkConfirmationModal.contracts';
import * as S from './BulkConfirmationModal.style';
import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import { useTypedTranslation } from 'modules/core/hooks';
import RerenderImg from 'assets/images/atention.svg';

export const BulkConfirmationModal = ({ isOpen, close, confirmAction, action }: BulkConfirmationModalProps) => {
  const { t } = useTypedTranslation<'devices.modal_select_all_confirmation'>('devices.modal_select_all_confirmation');

  // eslint-disable-next-line no-unused-vars
  const renderModalsFooter = (CTAText: string, CTAFunction: (data: unknown) => void, minWidth = '117px') => {
    return (
      <S.ModalsFooter>
        <EuiButtonEmpty onClick={close}>{t('cancel')}</EuiButtonEmpty>
        <EuiButton fill onClick={CTAFunction} minWidth={minWidth}>
          {CTAText}
        </EuiButton>
      </S.ModalsFooter>
    );
  };

  const confirm = () => {
    confirmAction(action);
    close();
  };

  return (
    <DialogComponent width="600px" open={isOpen} footer={renderModalsFooter(t('cta'), () => confirm())} closeOnClickOutside onClose={() => close()}>
      <S.Content>
        <S.Text>{t('title')}</S.Text>
        <S.Img src={RerenderImg} alt="atention" />
        {['renewal', 'find_phone'].includes(action) && <S.WarningText>{t('ios_warning')}</S.WarningText>}
        {['sync', 'send_message'].includes(action) && <S.WarningText>{t('chrome_warning')}</S.WarningText>}
      </S.Content>
    </DialogComponent>
  );
};
