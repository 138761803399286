import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.div`
  display: flex;
  gap: 30px;
  width: 100% !important;
  background: ${ConstantColors.BRAZIL};
  flex-wrap: wrap;
  flex-direction: column;
  gap: 30px;
  flex-wrap: wrap;
  max-width: 485px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  color: #1a1c21;
`;

export const Title = styled.h3``;
