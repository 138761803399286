export class EmployeeUser {
  public id?: number;
  public username: string;
  public firstName: string;
  public lastName: string;
  public password?: string;
  public status?: number;

  public employeeId?: string;
  public workPhone?: string;
  public homePhone?: string;
  public email?: string;
  public managerEmail?: string;
  public managerPhoneNumber?: string;
  public managerName?: string;
  public costCenter?: string;
  public employeeTitle?: string;
  public department?: string;
  public lastLogin?: string;
  public createdAt?: string;

  constructor(data: EmployeeUser.Server) {
    this.id = data.id;
    this.username = data.username;
    this.firstName = data.firstName;
    this.lastName = data.lastName;
    this.status = data.status;

    this.employeeId = data.employeeId;
    this.homePhone = data.homePhone;
    this.workPhone = data.workPhone;
    this.email = data.email;
    this.managerName = data.managerName;
    this.managerEmail = data.managerEmail;
    this.managerPhoneNumber = data.managerPhoneNumber;
    this.costCenter = data.costCenter;
    this.employeeTitle = data.employeeTitle;
    this.department = data.department;
    this.lastLogin = data?.lastLogin!;
    this.createdAt = data.createdAt;
  }
}

export namespace EmployeeUser {
  export type Server = {
    id?: number;
    username: string;
    firstName: string;
    lastName: string;
    status?: number;
    employeeId?: string;
    workPhone?: string;
    managerName?: string;
    homePhone?: string;
    email?: string;
    managerEmail?: string;
    managerPhoneNumber?: string;
    costCenter?: string;
    employeeTitle?: string;
    department?: string;
    lastLogin?: string;
    createdAt?: string;
  };
}
