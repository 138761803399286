type IconPulsusLogoProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconPulsusLogo({ width = 135, height = 25, color = '#FFFFFF', test_id = 'icons__icon-pulsus-logo' }: IconPulsusLogoProps) {
  return (
    <svg
      data-testid={test_id}
      id="icon-pulsus-logo"
      width={width}
      height={height}
      fill={color}
      data-name="pulsus-logo"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 137.29 24.52"
    >
      <title>Logo</title>
      <g id="Group_1" data-name="Group 1">
        <path
          id="Path_368"
          data-name="Path 368"
          className="cls-1"
          d="M50.43,111.9H44.75c-4.46,0-6.71,1.37-6.71,4.11v15.43c0,.47.14.77.36.88a2.25,2.25,0,0,0,1.12.19,2.52,2.52,0,0,0,1.15-.19c.27-.13.38-.44.38-.88v-6.16h9.38c4.47,0,6.72-1.37,6.72-4.12V116c0-2.77-2.23-4.14-6.72-4.14m3.73,9.26a1.41,1.41,0,0,1-.88,1.21,6.43,6.43,0,0,1-2.91.49H41.11V116A1.45,1.45,0,0,1,42,114.8a6.55,6.55,0,0,1,2.9-.49h5.48a6.57,6.57,0,0,1,2.91.49,1.44,1.44,0,0,1,.88,1.21Z"
          transform="translate(-38.04 -108)"
        />
        <path
          id="Path_369"
          data-name="Path 369"
          className="cls-1"
          d="M120.5,118.23a9.16,9.16,0,0,0-4.77-1h-5.24a4.77,4.77,0,0,1-2.08-.33,1,1,0,0,1-.66-.87v-.63a.9.9,0,0,1,.63-.85,4.94,4.94,0,0,1,2-.33h9.84a.79.79,0,0,0,.71-.3,1.91,1.91,0,0,0,.16-.88,2.1,2.1,0,0,0-.16-.93.77.77,0,0,0-.71-.31h-9.84a7.72,7.72,0,0,0-4.23,1,3,3,0,0,0-1.42,2.61v.68a2.93,2.93,0,0,0,1.45,2.58,8,8,0,0,0,4.3.93h5.3a7.52,7.52,0,0,1,1.5.11,2.88,2.88,0,0,1,1,.28,1.17,1.17,0,0,1,.5.38.72.72,0,0,1,.13.49v.85a.74.74,0,0,1-.13.5,1.11,1.11,0,0,1-.5.35,2.74,2.74,0,0,1-1,.22,12.14,12.14,0,0,1-1.59.11h-10a.79.79,0,0,0-.71.3,1.92,1.92,0,0,0-.17.88,2.26,2.26,0,0,0,.17.93.76.76,0,0,0,.71.3h9.6a16.65,16.65,0,0,0,3.07-.24,6.43,6.43,0,0,0,2.08-.72,3,3,0,0,0,1.18-1.15,2.82,2.82,0,0,0,.38-1.5v-.77a2.84,2.84,0,0,0-1.42-2.69"
          transform="translate(-38.04 -108)"
        />
        <path
          id="Path_370"
          data-name="Path 370"
          className="cls-1"
          d="M84.34,112a2.27,2.27,0,0,0-1.12-.19,2.56,2.56,0,0,0-1.16.19c-.27.14-.38.44-.38.88v8.28a1.41,1.41,0,0,1-.88,1.2,6.41,6.41,0,0,1-2.9.49H72.44a6.41,6.41,0,0,1-2.9-.49,1.42,1.42,0,0,1-.88-1.2v-8.26c0-.46-.14-.76-.36-.87a2.4,2.4,0,0,0-1.12-.2A2.66,2.66,0,0,0,66,112c-.28.13-.38.43-.38.87v8.26c0,2.76,2.24,4.11,6.71,4.11H78q6.7,0,6.71-4.11v-8.26a.93.93,0,0,0-.38-.9"
          transform="translate(-38.04 -108)"
        />
        <path
          id="Path_371"
          data-name="Path 371"
          className="cls-1"
          d="M173.9,118.23a9.16,9.16,0,0,0-4.77-1h-5.24a4.77,4.77,0,0,1-2.08-.33,1,1,0,0,1-.66-.87v-.63a.9.9,0,0,1,.63-.85,4.94,4.94,0,0,1,2-.33h9.84a.79.79,0,0,0,.71-.3,1.79,1.79,0,0,0,.16-.88,2.1,2.1,0,0,0-.16-.93.77.77,0,0,0-.71-.31h-9.84a7.72,7.72,0,0,0-4.23,1,3,3,0,0,0-1.42,2.61v.68a2.93,2.93,0,0,0,1.45,2.58,8,8,0,0,0,4.31.93h5.29a7.52,7.52,0,0,1,1.5.11,2.88,2.88,0,0,1,1,.28,1.17,1.17,0,0,1,.5.38.77.77,0,0,1,.13.49v.85a.74.74,0,0,1-.13.5,1.11,1.11,0,0,1-.5.35,2.74,2.74,0,0,1-1,.22,12.14,12.14,0,0,1-1.59.11H159a.79.79,0,0,0-.71.3,1.79,1.79,0,0,0-.16.88,2.1,2.1,0,0,0,.16.93.76.76,0,0,0,.71.3h9.6a16.65,16.65,0,0,0,3.07-.24,6.43,6.43,0,0,0,2.08-.72,3,3,0,0,0,1.18-1.15,2.82,2.82,0,0,0,.38-1.5v-.77a2.84,2.84,0,0,0-1.42-2.69"
          transform="translate(-38.04 -108)"
        />
        <path
          id="Path_372"
          data-name="Path 372"
          className="cls-1"
          d="M149.25,112a2.25,2.25,0,0,0-1.12-.19A2.52,2.52,0,0,0,147,112c-.27.14-.38.44-.38.88v8.28a1.41,1.41,0,0,1-.88,1.2,6.43,6.43,0,0,1-2.91.49h-5.45a6.43,6.43,0,0,1-2.91-.49,1.44,1.44,0,0,1-.88-1.2v-8.26c0-.46-.13-.76-.35-.87a2.44,2.44,0,0,0-1.13-.2,2.69,2.69,0,0,0-1.15.2c-.27.13-.38.43-.38.87v8.26c0,2.76,2.25,4.11,6.72,4.11h5.64c4.47,0,6.72-1.37,6.72-4.11v-8.26a1.1,1.1,0,0,0-.38-.9"
          transform="translate(-38.04 -108)"
        />
        <path
          id="Path_373"
          data-name="Path 373"
          className="cls-1"
          d="M95.88,108.2a2.52,2.52,0,0,0-1.15-.19,2.25,2.25,0,0,0-1.12.19,1,1,0,0,0-.36.88v15.13c0,.46.14.77.36.88a2.37,2.37,0,0,0,1.12.19,2.81,2.81,0,0,0,1.15-.19c.27-.14.38-.44.38-.88V109.1a1,1,0,0,0-.38-.9"
          transform="translate(-38.04 -108)"
        />
      </g>
    </svg>
  );
}
