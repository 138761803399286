import { useState, type ReactElement } from 'react';
import * as S from './MapsTabsComponent.styles';
import { MapsGroupListComponent } from './MapsGroupList/MapsGroupListComponent';
import { MapsDevicesListComponent } from './MapsDevicesList/MapsDevicesListComponent';
import { NavigationTabsComponent } from 'modules/core/components/NavigationTabs/NavigationTabsComponent';
import { NavigationTabPanelComponent } from 'modules/core/components/NavigationTabPanel/NavigationTabPanelComponent';
import { useTypedTranslation } from 'modules/core/hooks';

export const MapsTabsComponent = (): ReactElement => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { t } = useTypedTranslation<'maps'>('maps');

  const tabs = [
    {
      id: 0,
      name: t('groupsTag.title'),
    },
    {
      id: 1,
      name: t('devicesTag.title'),
    },
  ];

  return (
    <S.Container>
      <S.TabColumns>
        <NavigationTabsComponent tabs={tabs} selectedTab={selectedTab} onClick={(id: number) => setSelectedTab(id)} />
      </S.TabColumns>
      <S.Body>
        <NavigationTabPanelComponent index={0} value={selectedTab}>
          <MapsGroupListComponent />
        </NavigationTabPanelComponent>
        <NavigationTabPanelComponent index={1} value={selectedTab}>
          <MapsDevicesListComponent />
        </NavigationTabPanelComponent>
      </S.Body>
    </S.Container>
  );
};
