import { useEffect, useState } from 'react';
import { ConstantColors } from 'utilities';
import { EuiToolTip } from '@elastic/eui';
import Icon from '@mdi/react';
import { mdiAndroid } from '@mdi/js';
import * as style from './component-advanced-flag.style';
import type { AdvancedFlagsProps, AdvancedFlagProps } from './component-flags.contracts';
import { useTranslation } from 'react-i18next';

export const AdvancedFlag = ({ flags, styles }: AdvancedFlagsProps) => {
  const [managementCounter, setManagementCounter] = useState(1);
  const { t } = useTranslation();

  useEffect(() => {
    let count = 1;
    setInterval(() => {
      count++;
      if (count > 2) {
        count = 1;
      }
      setManagementCounter(count);
    }, 1500);
  }, []);

  const renderTooltipContent = (flag: AdvancedFlagProps) => {
    let managementType = '';

    switch (flag.managementType) {
      case 'FM':
        managementType = t('components.component_support_message.component_fm_card_content.apps_management_fm');
        break;
      case 'FM + WP':
        managementType = t('components.component_support_message.component_fm_card_content.apps_management_fm_wp');
        break;
      case 'WP':
        managementType = t('components.component_support_message.component_fm_card_content.apps_management_wp');
        break;
      default:
        break;
    }

    return (
      <p style={{ fontSize: '11px' }}>
        {' '}
        {t('components.component_support_message.component_fm_card_content.apps_management_feature_available_devices')} {` ${managementType}`}{' '}
        {flag.OS && `${flag.OS} `}
        {flag.version && `${flag.version} `}
        {flag.version && t('components.component_support_message.component_fm_card_content.apps_management_or_superior')}
      </p>
    );
  };

  const renderAgentVersionTooltipContent = (flag: AdvancedFlagProps) => {
    return (
      <p style={{ fontSize: '11px' }}>
        {' '}
        {t('components.component_support_message.component_pulsus_agent_content.title')} {flag.version && ` ${flag.version}+`}
      </p>
    );
  };

  return (
    <style.CardFlags style={styles}>
      {flags.map((item) => {
        if (item.isPulsusAgent) {
          return (
            <EuiToolTip
              key={`${(item.managementType, item.version)}`}
              display="inlineBlock"
              position="top"
              content={renderAgentVersionTooltipContent(item)}
            >
              <style.Flag>
                {'Pulsus'}
                {item.version ? <span>{item.version}+</span> : <span></span>}
              </style.Flag>
            </EuiToolTip>
          );
        } else {
          return (
            <EuiToolTip key={`${(item.managementType, item.version)}`} display="inlineBlock" position="top" content={renderTooltipContent(item)}>
              <style.Flag>
                {item.managementType &&
                  (item.managementType === 'FM' ? (
                    <>{managementCounter === 1 ? <span>DO</span> : <span>FM</span>}</>
                  ) : (
                    <span>{item.managementType === 'NU' ? 'OS' : item.managementType}</span>
                  ))}
                {item?.elementIcon}
                {item.icon && <Icon path={item.icon} size={1} color={item.icon === mdiAndroid ? ConstantColors.DJBOUTI : ConstantColors.ITALY} />}
                {item.version ? <span>{item.version}+</span> : t('components.component_support_message.component_fm_card_content.all')}
              </style.Flag>
            </EuiToolTip>
          );
        }
      })}
    </style.CardFlags>
  );
};
