import { IconTheteringInCicle } from 'icons';
import type { MobileDataCardProps } from './page-connectivity.contracts';
import * as style from './page-connectivity.style';

export function StatusTethering(props: MobileDataCardProps) {
  const { active, onChange } = props;

  function handleChange(value: boolean) {
    onChange(value);
  }

  return (
    <style.SettingsStatusSection is_on={active} className="status_section_container">
      <div className="icon_status_container">
        <section className="left_section_status">
          <button type="button">
            <IconTheteringInCicle elipse_fill={active ? '#30A8FF' : '#D6D6D6'} />
            {active ? 'Permitido' : 'Desligado'}
          </button>
        </section>
        <section className="right_section_status">
          <h4>Ancoragem (Tethering)</h4>
          <p style={{ width: '110px' }}>Disponível para todos os dispositivos</p>
        </section>
      </div>
      <div className="options_container tethering">
        <button className={active ? 'active' : ''} type="button" onClick={() => handleChange(true)}>
          Permitido
        </button>
        <button type="button" className={!active ? 'active' : ''} onClick={() => handleChange(false)}>
          Desligado
        </button>
      </div>
    </style.SettingsStatusSection>
  );
}
