import Lottie from 'lottie-react';
import animation_logo_pulsus_grey from 'assets/animations/animation-logo-pulsus-grey.json';
import * as S from './ComponentLoader.style';
import type { ComponentLoaderProps } from './ComponentLoader.contracts';

export function ComponentLoader({ isLoading, children, overflow, height }: ComponentLoaderProps) {
  return (
    <S.Container>
      {isLoading && (
        <S.LoaderContainer className="PulsusLoaderAnimation" height={height || '500px'} overflow={overflow}>
          <Lottie
            data-testid="PulsusLoaderAnimation"
            className="PulsusLoaderAnimation"
            animationData={animation_logo_pulsus_grey}
            size={0}
            style={{
              width: '100%',
              height: '100%',
              maxHeight: '450px',
              pointerEvents: 'none',
              margin: '0',
            }}
          />
        </S.LoaderContainer>
      )}
      <S.LoaderContent isLoading={!overflow && isLoading}>{children}</S.LoaderContent>
    </S.Container>
  );
}
