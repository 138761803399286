import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const WrapperButtons = styled.div`
  display: flex;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
`;

export const StyledButton = styled(EuiButton)`
  background: ${ConstantColors.COMOROS} !important;
  border-color: ${ConstantColors.COMOROS} !important;
  color: #fff;
  font-size: 14px !important;
  width: 192px;

  &:disabled {
    color: #fff !important;
    background: ${ConstantColors.EMIRATES} !important;
    border-color: ${ConstantColors.EMIRATES} !important;
  }
`;
