import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { FullyManagedWP } from './fully-managed-wp/fully-managed-wp';
import { FullyManaged } from './fully-managed/fully-managed';
import type { EnrollDataType, ModalAEEnrollProps, UseLocationStateProps } from './modal-ae-enroll.contracts';
import { WorkProfile } from './work-profile/work-profile';

export function ModalAEEnroll(props: ModalAEEnrollProps) {
  const { managedMode, enroll } = props;
  const [typeSelected, setTypeSelected] = useState('fully');
  const state = useLocation().state as UseLocationStateProps;
  const [enrollDataType, setEnrollDataType] = useState<EnrollDataType>(null!);

  useEffect(() => {
    if (!managedMode) {
      setTypeSelected(state?.type);
      setEnrollDataType({
        jsonString: state?.data,
        QRcode: state?.QRcode,
        groupPin: state?.groupPin,
        packageDownloadLocation: state?.packageDownloadLocation,
      });
    } else {
      setTypeSelected(enroll?.type || null!);
      setEnrollDataType({ jsonString: state?.data, QRcode: state?.QRcode });
    }
  }, []);

  return (
    <>
      {typeSelected === 'fully' && <FullyManaged data={enrollDataType} />}
      {typeSelected === 'fully_work_profile' && <FullyManagedWP data={enrollDataType} />}
      {typeSelected === 'work_profile' && <WorkProfile data={enrollDataType} />}
    </>
  );
}
