import styled, { css } from 'styled-components';
import { EuiButton } from '@elastic/eui';
import { ConstantColors } from 'utilities';
import type { PulsusInsightsStyle } from 'components/pulsus-insights-content/component-pulsus-insights-content.contracts';

export const Container = styled.main`
  background-color: ${ConstantColors.AUSTRIA};
  padding: 0 50px 50px;
`;

export const TopContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LeftContainer = styled.section`
  max-width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

export const PulsusInsights = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 440px;
  margin-bottom: 30px;
`;

export const TextPrimary = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 20px;
  color: ${ConstantColors.ITALY};
`;

export const TextSecondary = styled.p`
  font-style: normal;
  font-weight: 500;
  margin-top: 50px;
  margin-bottom: 80px;
  color: ${ConstantColors.ITALY};
`;

export const TitleSolutions = styled.h2`
  color: ${({ theme }) => theme.main};
`;

export const Button = styled(EuiButton)`
  background: ${ConstantColors.CHILE} !important;
  border-color: ${ConstantColors.CHILE} !important;
`;

export const RightContainer = styled.section`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 55%;
`;

export const ImagePulsusInsightsHome = styled.img`
  width: 85%;
`;

export const BottomContainer = styled.section``;

export const LinksContainer = styled.div`
  display: inline-flex;
  gap: 50px;
  margin-top: 30px;
`;

export const Link = styled.div<PulsusInsightsStyle>`
  max-width: 360px;

  a {
    &:hover {
      text-decoration: none;
    }
  }

  ${({ isDisabled }) => css`
    ${isDisabled &&
    css`
      pointer-events: none;
      width: 100%;
      height: 100%;

      a {
        &:hover {
          text-decoration: none;
          cursor: not-allowed;
        }
      }
    `}
  `}
`;

export const Card = styled.div`
  ${({ theme }) => css`
    max-width: 353px;
    min-height: 170px;
    padding: 15px 28px;

    background-color: #fff;
    box-shadow:
      0px 0.9px 4px -1px rgba(0, 0, 0, 0.08),
      0px 2.6px 8px -1px rgba(0, 0, 0, 0.06),
      0px 5.7px 12px -1px rgba(0, 0, 0, 0.05),
      0px 15px 15px -1px rgba(0, 0, 0, 0.04);
    border-radius: 6px;

    display: flex;
    justify-content: space-between;

    h2 {
      color: ${theme.main};
      font-size: 22px;
    }

    p {
      color: ${theme.main};
      font-weight: 700;
    }

    &:hover {
      box-shadow:
        0px 0.9px 4px -1px rgba(0, 0, 0, 0.1),
        0px 2.6px 8px -1px rgba(0, 0, 0, 0.1),
        0px 5.7px 12px -1px rgba(0, 0, 0, 0.08),
        0px 15px 15px -1px rgba(0, 0, 0, 0.08);
    }
  `}
`;

export const CardTitle = styled.div<PulsusInsightsStyle>`
  padding: 10px 0;

  h2,
  svg {
    ${({ isDisabled }) => css`
      ${isDisabled &&
      css`
        filter: grayscale(95%);
        opacity: 0.5;
      `}
    `}
  }
  h2 {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

export const OpenLink = styled.p<PulsusInsightsStyle>`
  ${({ isDisabled }) => css`
    ${isDisabled &&
    css`
      filter: grayscale(95%);
      opacity: 0.5;
    `}
  `}
`;

export const CardLeft = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardRight = styled.div<PulsusInsightsStyle>`
  ${({ isDisabled }) => css`
    ${isDisabled &&
    css`
      filter: grayscale(95%);
      opacity: 0.5;
    `}
  `}
`;

export const LinkTexts = styled.div<PulsusInsightsStyle>`
  margin-top: 22px;

  ${({ isDisabled }) => css`
    ${isDisabled &&
    css`
      filter: grayscale(95%);
      opacity: 0.5;
    `}
  `}

  h2 {
    color: ${({ theme }) => theme.main};
    margin-bottom: 10px;
  }

  p {
    color: ${ConstantColors.ITALY};
  }
`;

export const FlagComingSoon = styled.span<PulsusInsightsStyle>`
  display: none;

  ${({ isDisabled }) => css`
    ${isDisabled &&
    css`
      display: inline;
      background: ${({ theme }) => theme.main};
      color: ${ConstantColors.AUSTRIA};
      border-radius: 10px;
      font-size: 10px;
      padding: 2px 15px;
      line-height: 15px;
      font-weight: 500;
      text-align: center;
      font-style: normal;
      letter-spacing: 0.04em;
    `}
  `}
`;
