import { EuiComboBox } from '@elastic/eui';
import type { ComboBoxProps } from './component-combo-box.contracts';
import * as style from './component-combo-box.style';

export function ComponentComboBox(props: ComboBoxProps) {
  const { label, selectedOptions, options, fullWidth, onChange, className, id, onBlur, onFocus, test_id, placeholder } = props;
  return (
    <style.Container data-testid={test_id || 'component-combo-box'}>
      {label && <style.Label>{label}</style.Label>}
      <EuiComboBox
        id={id}
        placeholder={placeholder}
        className={className}
        options={options}
        selectedOptions={selectedOptions}
        fullWidth={fullWidth}
        onChange={(e) => onChange(e)}
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={() => {
          if (onBlur) {
            onBlur();
          }
        }}
      />
    </style.Container>
  );
}
