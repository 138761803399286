type GraphLightProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconGraphLight({ width = 14, height = 14, color = '#000', test_id = 'icons__graph-light' }: GraphLightProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 4.2h3V14H0V4.2ZM5.6 0h2.8v14H5.6V0Zm5.6 8H14v6h-2.8V8Z" fill={color} />
    </svg>
  );
}
