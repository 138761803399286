import { EuiFilePicker, EuiFormRow } from '@elastic/eui';
import { DialogComponent } from 'components';
import type { UploadModalComponentProps } from './UploadModalComponent.contracts';
import { useTypedTranslation } from 'modules/core/hooks';
import { useState } from 'react';
import * as S from './UploadModalComponent.styles';

const UploadModalComponent = ({ open, close, openEditModal }: UploadModalComponentProps) => {
  const [error, setError] = useState('');

  const { t } = useTypedTranslation<'contacts.upload_modal'>('contacts.upload_modal');

  const onChangeFile = (files: FileList | null) => {
    setError('');
    if (files?.length) {
      const isVCFFile = files[0].name.endsWith('.vcf');
      const isCSVFile = files[0].name.endsWith('.csv');
      const fileType = isVCFFile ? 'text/vcard' : 'text/csv';
      if (isVCFFile || isCSVFile) {
        openEditModal(new File([files[0]], files[0].name, { type: fileType }));
      } else {
        setError(t('error_message'));
      }
    }
  };

  return (
    <DialogComponent width="612px" title={t('title')} onClose={close} open={open} closeOnClickOutside>
      <S.Content>
        <EuiFormRow isInvalid={!!error} error={error} fullWidth>
          <EuiFilePicker
            isInvalid={!!error}
            accept=".vcf, .csv"
            initialPromptText={t('file_placeholder')}
            onChange={onChangeFile}
            display="large"
            aria-label="upload file"
            fullWidth
          />
        </EuiFormRow>

        <S.DownloadButton className="link" iconType={'download'} href="/import_contact_sample.csv" download="example.csv">
          {t('download')}
        </S.DownloadButton>
      </S.Content>
    </DialogComponent>
  );
};

export default UploadModalComponent;
