import styled from 'styled-components';
import { EuiModal } from '@elastic/eui';
import type { ModalProps } from './modal.contracts';

export const Modal = styled(EuiModal)<ModalProps>`
  .euiModal__flex {
    padding: 20px 40px;
  }

  .euiButtonIcon.euiButtonIcon--text.euiButtonIcon--empty.euiButtonIcon--xSmall.euiModal__closeIcon {
    margin: 25px 30px !important;
  }
`;
