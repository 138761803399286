import PropTypes from 'prop-types';

export function IconAndroid({ width, height, fill }) {
  return (
    <svg width={width} height={height} fill={fill} viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.80078 0C2.72111 0 2.64144 0.0302864 2.58075 0.0905693C2.45939 0.211135 2.45939 0.407151 2.58075 0.527717L3.39643 1.33801C2.47036 2.01309 1.86719 3.09932 1.86719 4.328H9.33593C9.33593 3.09932 8.73276 2.01309 7.80669 1.33801L8.62237 0.527717C8.74373 0.406533 8.74373 0.211753 8.62237 0.0905693C8.501 -0.0299966 8.30368 -0.0299966 8.18231 0.0905693L7.25966 1.00713C6.7596 0.76022 6.19793 0.618286 5.60156 0.618286C5.00519 0.618286 4.44352 0.76022 3.94346 1.00713L3.02081 0.0905693C2.96012 0.0302864 2.88045 0 2.80078 0ZM3.73437 2.47314H4.35677V3.09143H3.73437V2.47314ZM6.84635 2.47314H7.46875V3.09143H6.84635V2.47314ZM0.622396 4.94629C0.278833 4.94629 0 5.22328 0 5.56458V9.27429C0 9.61559 0.278833 9.89258 0.622396 9.89258C0.965958 9.89258 1.24479 9.61559 1.24479 9.27429V5.56458C1.24479 5.22328 0.965958 4.94629 0.622396 4.94629ZM1.86719 4.94629V9.89258C1.86719 10.2339 2.14602 10.5109 2.48958 10.5109H3.11198V12.6749C3.11198 13.1868 3.53023 13.6023 4.04557 13.6023C4.56091 13.6023 4.97917 13.1868 4.97917 12.6749V10.5109H6.22396V12.6749C6.22396 13.1868 6.64221 13.6023 7.15755 13.6023C7.67289 13.6023 8.09114 13.1868 8.09114 12.6749V10.5109H8.71354C9.0571 10.5109 9.33593 10.2339 9.33593 9.89258V4.94629H1.86719ZM10.5807 4.94629C10.2372 4.94629 9.95833 5.22328 9.95833 5.56458V9.27429C9.95833 9.61559 10.2372 9.89258 10.5807 9.89258C10.9243 9.89258 11.2031 9.61559 11.2031 9.27429V5.56458C11.2031 5.22328 10.9243 4.94629 10.5807 4.94629Z"
        fill={fill}
      />
    </svg>
  );
}

IconAndroid.propTypes = {
  width: PropTypes.string,
  height: PropTypes.string,
  fill: PropTypes.string,
};

IconAndroid.defaultProps = {
  width: '12px',
  height: '14px',
  fill: '#FFFFFF',
};
