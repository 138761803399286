import type { EuiTabbedContentTab } from '@elastic/eui';
import { EuiTabbedContent } from '@elastic/eui';
import { useState } from 'react';
import { ZeroTouchDraw } from 'icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './work-profile.style';
import { tabs } from './tabs-work-profile';
import type { EnrollComponentData } from '../modal-ae-enroll.contracts';

export function WorkProfile({ data }: EnrollComponentData) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'pages.enroll' });
  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs(data?.jsonString, t)[0]);

  return (
    <S.Container>
      <S.Top>
        <h2>{t('byod_option.title')}</h2>
      </S.Top>
      <S.OptionsContainer>
        <S.TabsContainer>
          <EuiTabbedContent tabs={tabs(data?.jsonString, t)} onTabClick={(tab) => setSelectedTab(tab)} />
        </S.TabsContainer>
        <S.OptionsRight>
          {selectedTab.id === 'qr-code' && (
            <S.RightBox>
              <img src={data?.QRcode} alt="qr-code" />
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Work Profile</p>
                <p className="secondary">/</p>
                <p className="primary">QR Code</p>
              </S.LinkHistory>
            </S.RightBox>
          )}
          {selectedTab.id === 'zero-touch' && (
            <S.RightBox>
              <div style={{ height: '80%', display: 'flex', alignItems: 'center' }}>
                <ZeroTouchDraw />
              </div>
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Work Profile</p>
                <p className="secondary">/</p>
                <p className="primary">Zero Touch</p>
              </S.LinkHistory>
            </S.RightBox>
          )}
        </S.OptionsRight>
      </S.OptionsContainer>
    </S.Container>
  );
}
