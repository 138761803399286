type WorkProfileProps = {
  width?: number;
  height?: number;
  fill?: string;
  className?: string;
};

export function IconWorkProfile({ width = 23, height = 22, fill = '#1483FF', className }: WorkProfileProps) {
  return (
    <svg className={className} width={width} height={height} viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.5009 21.9996C8.54169 21.9996 5.58223 21.9996 2.62247 21.9996C0.944437 21.9996 0 21.0563 0 19.3784C0 15.3167 0.00201615 11.2553 0.00604851 7.1941C0.00604851 6.17085 0.377602 5.36841 1.30216 4.87808C1.65644 4.68943 2.03663 4.64856 2.42546 4.64943C3.76133 4.64943 5.09805 4.63205 6.43305 4.66074C6.83226 4.66856 6.91694 4.53902 6.89879 4.17128C6.86941 3.5801 6.8841 2.98631 6.89274 2.3934C6.91694 1.02326 7.90544 0.0191263 9.27759 0.00869378C10.7575 -0.00289793 12.2373 -0.00289793 13.7172 0.00869378C15.1101 0.0173876 16.0952 1.01717 16.1038 2.41426C16.1038 2.9924 16.1263 3.57141 16.0969 4.1478C16.077 4.52859 16.166 4.66943 16.5808 4.65726C17.8881 4.62683 19.1955 4.64335 20.5028 4.64683C22.0582 4.64683 22.9991 5.59271 23 7.15237C23 11.2431 23 15.3335 23 19.4236C23 21.0485 22.0495 21.9944 20.4216 21.9953C17.4486 21.9993 14.475 22.0008 11.5009 21.9996ZM13.7889 12.7225C13.7846 11.4575 12.727 10.403 11.4793 10.4204C10.8786 10.4368 10.3075 10.6859 9.88477 11.1155C9.46207 11.5452 9.22043 12.1224 9.2102 12.7268C9.2102 13.9848 10.2652 15.0568 11.506 15.0515C12.1146 15.0435 12.6954 14.7946 13.1228 14.3586C13.5501 13.9226 13.7894 13.3348 13.7889 12.7225V12.7225ZM11.4732 4.64943C12.1472 4.64943 12.822 4.64248 13.496 4.64943C13.7172 4.64943 13.8002 4.58162 13.7959 4.35298C13.7838 3.77629 13.7838 3.19902 13.7959 2.62118C13.801 2.39601 13.7302 2.32385 13.5038 2.32559C12.1697 2.33515 10.8355 2.33689 9.50139 2.32559C9.23439 2.32559 9.20069 2.43513 9.20501 2.65943C9.21711 3.20714 9.22748 3.75658 9.20501 4.30429C9.19032 4.61466 9.32339 4.65813 9.58175 4.65204C10.2108 4.64074 10.8416 4.64943 11.4732 4.64943V4.64943Z"
        fill={fill}
      />
    </svg>
  );
}
