import TranslationComponents from './TranslationComponents';

export const CoreComponentsTranslations = {
  pt: {
    ...TranslationComponents.pt,
  },
  en: {
    ...TranslationComponents.en,
  },
  es: {
    ...TranslationComponents.es,
  },
};
