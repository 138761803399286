import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  margin: 0 auto;
  border-radius: 16.087px;
  max-width: 1050px;
  height: 670px;
  padding: 40px 0;

  background: ${ConstantColors.AUSTRIA};

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  .link-like {
    color: ${ConstantColors.CHILE};
    font-weight: 700;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Top = styled.section`
  margin-bottom: 35px;

  h2 {
    color: ${({ theme }) => theme.main};
    text-align: center;
    margin-bottom: 15px;
    font-size: 25px;
  }

  p {
    text-align: center;
  }
`;

export const OptionsContainer = styled.section`
  padding: 0 30px 0 60px;
  display: flex;
  overflow: hidden;
`;

export const OptionsRight = styled.section`
  margin-top: 35px;
  max-width: 394px;
  div.background {
    background-color: #ff4c0c;
    width: 394px;
    height: 450px;
    border-radius: 30px 30px 0px 0px;
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(300px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  .animation {
    animation: 1s ease-out 0s 1 slideInFromBottom;
    transform: translate(0, 0px) !important;
    transition: all 3s !important;
  }
`;

export const TabsContainer = styled.section`
  padding-right: 30px;
  ${({ theme }) => css`
    max-width: 600px;

    p {
      font-size: 15px;
      margin-bottom: 7px;
      margin-top: 5px;
      line-height: 20px;
    }

    .euiTabs {
      margin-bottom: 40px;
    }

    .euiTab {
      padding: 12px 35px;
      border-bottom: 2px solid transparent;
      font-weight: 500;

      &:focus {
        background-color: transparent;
      }

      &.euiTab:not(.euiTab-isDisabled):focus .euiTab__content {
        text-decoration: none;
      }

      &.euiTab-isSelected .euiTab__content {
        color: ${theme.main} !important;
      }

      &.euiTab-isSelected {
        box-shadow: unset;
        border-bottom: 3px solid ${theme.main};
      }
    }

    .euiStep__content {
      padding-top: 10px;
      padding-bottom: 25px;
    }

    .euiTitle {
      font-size: 22px;
      font-weight: 700;
    }

    .euiStep__circle {
      background-color: ${theme.main};
    }
  `}
`;

export const ImageBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 100%;

  @keyframes pisca {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .imageInputTextFully:after {
    content: '|';
    opacity: 1;
    animation: pisca 0.7s infinite;
  }
`;

export const ImageInput = styled.h4`
  display: flex;
  align-items: center;
  position: absolute;
  background: white;
  color: black;
  height: 27px;
  width: 230px;
  margin-bottom: 179px;
`;

export const QrCodeBox = styled.div`
  position: absolute;
  margin: 23px;
  margin-bottom: 12px !important;
  z-index: 1;
`;

export const RightBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

export const LinkHistory = styled.div`
  display: flex;
  width: 100%;
  padding: 20px 0px;
  justify-content: end;
  gap: 4px;

  p.secondary,
  button.secondary,
  a.secondary {
    font-size: 14px;
    line-height: 21px;
    font-weight: 400;
    color: #69707d;
  }

  button.secondary {
    height: 20px;
    padding: 0;
    .euiButtonEmpty__content {
      padding: 0 !important;
    }
  }

  p.primary {
    font-size: 14px;
    line-height: 21px;
    font-weight: 600;
    color: #343741;
  }
`;
