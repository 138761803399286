import * as S from './MapsPage.styles';
import { MapsCalendarComponent } from '../../components/maps/MapsCalendar/MapsCalendarComponent';
import { MapsTabsComponent } from '../../components/maps/MapsTabs/MapsTabsComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import { WrapperComponent } from 'modules/core/components/Wrapper/WrapperComponent';
import { HeaderPageComponent } from 'modules/core/components/HeaderPage/HeaderPageComponent';
import { GoogleMapsComponent } from 'modules/operational/components/maps/GoogleMaps/GoogleMapsComponent';

const MapsPage = () => {
  const { t } = useTypedTranslation<'maps'>('maps');

  return (
    <WrapperComponent hasFooter>
      <S.Container>
        <HeaderPageComponent title={t('title')} description={t('description')}>
          <MapsCalendarComponent />
        </HeaderPageComponent>
        <S.TwoCollumnSection>
          <MapsTabsComponent />
          <S.MapContainer>
            <GoogleMapsComponent />
          </S.MapContainer>
        </S.TwoCollumnSection>
      </S.Container>
    </WrapperComponent>
  );
};

export default MapsPage;
