import styled from 'styled-components';

export const Table = styled.table`
  display: grid;
  grid-template-columns: 2.3fr 2fr 10fr;
  gap: 15px;
  margin-bottom: 4px;
  align-items: center;

  h3 {
    font-weight: 600;
    font-size: 14px;
  }

  p {
    color: #818181;
    font-size: 13px;
    text-align: center;
  }
`;

export const BasePercentageBar = styled.div<{ size: number }>`
  width: 100%;
  background: ${({ theme }) => theme.dashboardTheme.bar};
  display: flex;
  align-items: center;
  position: relative;

  span {
    font-size: 12px;
    font-weight: 400;
    margin-left: ${({ size }) => (size > 50 ? '-36px ' : '4px')};
    color: ${({ size, theme }) => (size > 50 ? '#FFFFFF' : theme.dashboardTheme.font)};
  }
`;

export const PercentageBar = styled.div<{ size: number }>`
  height: 16px;
  width: ${({ size }) => size}%;
  background: ${({ theme }) => theme.main};
  display: flex;
  justify-content: flex-end;
  transition: width ease 1.5s;
`;

export const Label = styled.span<{ inLimit: boolean | undefined }>`
  color: ${({ inLimit }) => (inLimit ? '#F44336' : '#818181')} !important;
  font-size: 12px;
  white-space: nowrap;
  text-align: center;
`;
