import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Wrapper = styled.button`
  display: flex;
  max-width: 530px;
  text-align: left;
  transition: 0.3s;

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  &:hover {
    transform: translateY(-2px);
    p:not(:first-of-type) {
      transition: 0.3s;
      text-decoration: underline;
    }
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  min-width: 250px;
  min-height: 100px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 11px;
  }
`;

export const Texts = styled.div`
  margin-left: 30px;
  width: 100%;

  h4 {
    margin: 6px 0;
  }
`;

export const Date = styled.p`
  text-transform: uppercase;
`;

export const Ribbon = styled.div`
  position: absolute;
  bottom: 1px;
  right: 0;
  left: 0;

  margin-left: auto;
  margin-right: auto;

  width: 140px;
  height: 30px;

  background-color: ${({ theme }) => theme.main};

  display: flex;
  justify-content: center;
  align-items: center;

  h4 {
    color: ${ConstantColors.AUSTRIA};
  }
`;
