import styled, { css } from 'styled-components';
import 'leaflet/dist/leaflet.css';
import { ConstantColors } from 'utilities';
import { EuiCard, EuiRadioGroup } from '@elastic/eui';

export const VpnContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 25px 40px;
  min-width: 483px;
  gap: 30px;
  padding-bottom: 25px;
  background: #ffffff;
  border-radius: 6px;

  .tags-container {
    display: flex;
    gap: 10px;
  }

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  .bottom {
    p {
      font-weight: 600;
    }
  }

  .euiPopover {
    max-width: 270px;
  }
`;

export const VpnRadios = styled(EuiRadioGroup)`
  ${({ theme }) => css`
    display: flex;
    gap: 20px;
    margin-top: 20px;

    .euiRadioGroup__item {
      margin: 0;
    }

    .euiRadio .euiRadio__input:checked + .euiRadio__circle {
      border-color: ${theme.main};
      background-color: ${theme.main};
    }

    &:focus {
      outline-color: ${theme.main};
    }
  `}
`;

export const Tag = styled.div`
  width: fit-content;
  padding: 1px 10px;
  background-color: ${ConstantColors.CROATIA};
  border-radius: 12px;

  p {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
  }
`;

export const BoxMobileDataContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 48%;
  min-width: 350px;
  height: 300px;
  flex-wrap: wrap;
  border-radius: 9px;
  background: ${ConstantColors.AUSTRIA};
  padding: 23px 9px 20px 23px;
  max-width: 483px;
`;

export const Card = styled(EuiCard)`
  display: flex;
  border-radius: 9px;
  padding: 25px !important;
  border: none !important;
  width: 100%;
  max-width: 48%;
  background: ${ConstantColors.AUSTRIA};

  .euiTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
`;

export const BoxMobileDataBoxMain = styled.main`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;

  .activation-status-section {
    display: flex;
    width: 100%;
  }
`;

export const TitleBox = styled.h3`
  color: ${ConstantColors.GERMANY};
  margin-left: 5px;
`;

export const ActivationStatusSection = styled.section``;

export const AirplaneActivationStatusSection = styled.section`
  margin: 30px 0 20px 0;
`;

export const BoxWifiBluetoothContainer = styled.div`
  background: ${ConstantColors.AUSTRIA};
  padding: 23px 9px 20px 23px;
  border-radius: 9px;
  width: 48%;
  min-width: 250px;
  max-width: 483px;
`;

export const BoxWifiBluetoothMain = styled.main`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 5px;
`;

export const WifiStatusSection = styled.section`
  width: 220px;
  display: flex;
  flex-direction: column;
`;

export const NetworkWifiSection = styled.section`
  margin-top: 25px;
  display: flex;
  width: 100%;

  .left-span.manage-networks {
    margin-top: 10px;

    small {
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 12px;
      color: #67758d;
    }
  }

  .right-span.manage-networks {
    width: 100%;

    h4 {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      margin-bottom: 10px;
      color: ${ConstantColors.GERMANY};
    }

    p {
      font-style: normal;
      font-weight: 500;
      font-size: 11px;
      line-height: 13px;
      color: ${ConstantColors.ITALY};
      width: 110px;
    }
  }
`;

export const BluetoothSection = styled.section`
  width: 180px;
`;

export const CardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
`;
