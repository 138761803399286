/* eslint-disable array-callback-return */
import { EuiButtonEmpty } from '@elastic/eui';
import { ComponentGroupsSelect } from 'components/groups-select/components-groups-select';
import { useTranslation } from 'react-i18next';
import * as style from './certificate-identity-app-modal.style';
import type { AppModalProps } from './certificate-identity-app-modal.contracts';

export function AppModal({ groups, setGroups, setSearchValue, onClose, certificateName, setSelectedGroups, loading }: AppModalProps) {
  const { t } = useTranslation();

  const handleCancelAddApp = () => {
    handleOnClose();
    setSelectedGroups([]);
  };

  const handleOnClose = () => {
    onClose();
  };

  return (
    <style.Modal onClose={handleOnClose} maxWidth="auto">
      <style.Content>
        <style.Title>{`${t('pages.certificate.label_options_certificate_modal')} ${certificateName}`}</style.Title>
        <style.SecondTitle>{t('pages.certificate.label_options_certificate_modal_subtitle')}</style.SecondTitle>

        <ComponentGroupsSelect
          set_selection={setSelectedGroups}
          disabled={false}
          loading={loading}
          groups={groups}
          message="Loading..."
          set_groups={setGroups}
          setSearchValue={setSearchValue}
          certificates
        />

        <style.FooterModal>
          <style.WrapButtons>
            <EuiButtonEmpty onClick={() => handleCancelAddApp()}>{t('pages.certificate.label_options_certificate_cancel')}</EuiButtonEmpty>
            <style.Button onClick={() => handleOnClose()}>{t('pages.certificate.label_options_certificate_save')}</style.Button>
          </style.WrapButtons>
        </style.FooterModal>
      </style.Content>
    </style.Modal>
  );
}
