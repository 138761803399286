import type { EuiRangeTick } from '@elastic/eui/src/components/form/range/range_ticks';
import { useTranslation } from 'react-i18next';
import type * as Types from 'components/select-box/security-select-box.contracts';
import { PasswordQuality, PasswordRequired } from './embedded-security-settings.contracts';

export function PopulateAndroidSettingsOptions() {
  const { t } = useTranslation();

  const passwordQuality: Types.SelectOption[] = [
    {
      value: PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED,
      text: t('pages.pulsus_android_settings.options.password_type_select'),
    },
    {
      value: PasswordQuality.COMPLEXITY_LOW,
      text: t('pages.pulsus_android_settings.options.password_quality_complexity_low'),
    },
    {
      value: PasswordQuality.COMPLEXITY_MEDIUM,
      text: t('pages.pulsus_android_settings.options.password_quality_complexity_medium'),
    },
    {
      value: PasswordQuality.COMPLEXITY_HIGH,
      text: t('pages.pulsus_android_settings.options.password_quality_numeric_high'),
    },
    { value: PasswordQuality.SOMETHING, text: t('pages.pulsus_android_settings.options.password_quality_something') },
    { value: PasswordQuality.NUMERIC, text: t('pages.pulsus_android_settings.options.password_quality_numeric') },
    { value: PasswordQuality.ALPHABETIC, text: t('pages.pulsus_android_settings.options.password_quality_alphabetic') },
    {
      value: PasswordQuality.ALPHANUMERIC,
      text: t('pages.pulsus_android_settings.options.password_quality_alphanumeric'),
    },
    {
      value: PasswordQuality.BIOMETRIC_WEAK,
      text: t('pages.pulsus_android_settings.options.password_quality_biometric_weak'),
    },
    {
      value: PasswordQuality.NUMERIC_COMPLEX,
      text: t('pages.pulsus_android_settings.options.password_quality_numeric_complex'),
    },
  ];

  const passwordQualityWorkProfile: Types.SelectOption[] = [
    {
      value: PasswordQuality.PASSWORD_QUALITY_UNSPECIFIED,
      text: t('pages.pulsus_android_settings.options.password_type_select'),
    },
    { value: PasswordQuality.SOMETHING, text: t('pages.pulsus_android_settings.options.password_quality_something') },
    { value: PasswordQuality.NUMERIC, text: t('pages.pulsus_android_settings.options.password_quality_numeric') },
    { value: PasswordQuality.ALPHABETIC, text: t('pages.pulsus_android_settings.options.password_quality_alphabetic') },
    {
      value: PasswordQuality.ALPHANUMERIC,
      text: t('pages.pulsus_android_settings.options.password_quality_alphanumeric'),
    },
    {
      value: PasswordQuality.BIOMETRIC_WEAK,
      text: t('pages.pulsus_android_settings.options.password_quality_biometric_weak'),
    },
    {
      value: PasswordQuality.NUMERIC_COMPLEX,
      text: t('pages.pulsus_android_settings.options.password_quality_numeric_complex'),
    },
  ];

  const passwordRequired: { id: string; label: string }[] = [
    {
      id: PasswordRequired.USE_DEFAULT_DEVICE_TIMEOUT,
      label: t('pages.pulsus_android_settings.options.password_require_default'),
    },
    {
      id: PasswordRequired.REQUIRE_EVERY_DAY,
      label: t('pages.pulsus_android_settings.options.password_require_every_day'),
    },
  ];

  const passwordRequiredWorkProfile: { id: string; label: string }[] = [
    {
      id: PasswordRequired.USE_DEFAULT_DEVICE_TIMEOUT,
      label: t('pages.pulsus_android_settings.options.password_require_default'),
    },
    {
      id: PasswordRequired.REQUIRE_EVERY_DAY,
      label: t('pages.pulsus_android_settings.options.password_require_every_day'),
    },
  ];

  const eraseDataOptions: EuiRangeTick[] = [
    { label: t('pages.pulsus_android_settings.options.password_max_failed_for_wipe_not_active'), value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ];

  return {
    passwordQuality,
    passwordQualityWorkProfile,
    passwordRequiredWorkProfile,
    passwordRequired,
    eraseDataOptions,
  };
}
