import * as S from './UserFieldComponent.styles';
import type { UserFieldComponentProps } from './UserFieldComponent.contracts';

export const UserFieldComponent = ({ children, label }: UserFieldComponentProps) => {
  return (
    <S.Field>
      <S.Label>{label}</S.Label>
      <S.Value>{children}</S.Value>
    </S.Field>
  );
};
