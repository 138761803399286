/* eslint-disable react/jsx-one-expression-per-line */
import androidFrame from 'assets/images/onboarding-android-frame.jpg';
import iOsFrame from 'assets/images/onboarding-ios-frame.png';
import { IconPulsus } from 'icons/pulsus-icon/icon-pulsus';
import { ConstantColors } from 'utilities';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useQuery } from 'hooks/use-query';
import * as style from '../page-onboarding.style';

export function StepEnroll() {
  const { t } = useTranslation();
  const query = useQuery();

  const language = query.get('language') || 'pt-BR';

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  const Links = {
    'pt-BR': {
      ANDROID: 'https://intercom.help/pulsus-kb/pt-BR/articles/7182972-ativacao-via-fully-managed-device',
      IOS: 'https://intercom.help/pulsus-kb/pt-BR/articles/7183637-ativacao-de-dispositivos-ios',
    },
    es: {
      ANDROID: 'https://intercom.help/pulsus-kb/es/articles/7182972-activacion-del-fully-managed-device',
      IOS: 'https://intercom.help/pulsus-kb/es/articles/7183609-preparacion-de-la-plataforma-para-ios',
    },
    en: {
      ANDROID: 'https://intercom.help/pulsus-kb/en/articles/7182972-enrollment-fully-managed-device',
      IOS: 'https://intercom.help/pulsus-kb/en/articles/7183609-preparing-the-platform-for-ios',
    },
  };

  return (
    <style.StepContainer className="enroll-right-container" style={{ position: 'relative', maxWidth: '1400px' }}>
      <style.StepRight
        className="enroll-step-right"
        style={{
          width: '100%',
          maxWidth: '1400px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px',
        }}
      >
        <section
          className="enroll-right-section"
          style={{
            display: 'flex',
            gap: '80px',
            marginTop: '-100px',
            width: '100%',
            alignItems: ' center',
            justifyContent: 'center',
          }}
        >
          <div style={{ width: '339px' }}>
            <div className="enroll-os">
              <img src={androidFrame} alt="usuário android" style={{ height: '150px' }} />
            </div>
            <a href={Links[language]?.ANDROID} target="_blank" rel="noreferrer" data-testid="component__modal_contact_link_support">
              {t('pages.onboarding.enroll_phrase_android_center')}
            </a>
            <p className="enroll-text">{t('pages.onboarding.enroll_phrase_android_last')}</p>
          </div>
          <div style={{ width: '339px' }}>
            <div className="enroll-os">
              <img src={iOsFrame} alt="usuário android" style={{ height: '150px' }} />
            </div>
            <a href={Links[language]?.IOS} target="_blank" rel="noreferrer" data-testid="component__modal_contact_link_support">
              {t('pages.onboarding.enroll_phrase_ios_center')}
            </a>
            <p className="enroll-text">{t('pages.onboarding.enroll_phrase_ios_last')}</p>
          </div>
        </section>
        <style.StepButton
          style={{ background: ConstantColors.COMOROS }}
          size="m"
          fill
          onClick={() => {
            window.parent.location.href = `${process.env.REACT_APP_PULSUS_WEB_TEST}/home?njord=true`;
          }}
        >
          <span style={{ display: 'flex', justifyContent: 'center', gap: '7px', paddingTop: '3px' }}>
            <span>{t('pages.onboarding.go_pulsus')} </span>
            <span style={{ paddingTop: '2px' }}>
              <IconPulsus />
            </span>
          </span>
        </style.StepButton>
      </style.StepRight>
    </style.StepContainer>
  );
}
