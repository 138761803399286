import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 40px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

export const Title = styled.p`
  font-weight: 700;
  line-height: 26px;
  color: ${({ theme }) => theme.main};
  font-size: 28px;
`;

export const Description = styled.p`
  font-weight: 400;
  line-height: 15px;
  color: #474747;
  font-size: 16px;
`;

export const Body = styled.div`
  width: 700px;
  display: flex;
  justify-content: center;
  gap: 60px;
`;

export const Item = styled.div`
  width: 33%;
  max-width: 170px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 170px;
  height: 150px;
  background: #ffffff;
  box-shadow:
    0px 0.972736px 4.32327px -1.08082px rgba(0, 0, 0, 0.08),
    0px 2.81013px 8.64654px -1.08082px rgba(0, 0, 0, 0.06),
    0px 6.16066px 12.9698px -1.08082px rgba(0, 0, 0, 0.05),
    0px 16.2123px 16.2123px -1.08082px rgba(0, 0, 0, 0.04);
  border-radius: 15.1315px;
  cursor: pointer;

  @keyframes slideup {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-5px);
    }
  }
  @keyframes slidedown {
    0% {
      transform: translateY(-5px);
    }
    100% {
      transform: translateY(0px);
    }
  }

  & {
    :hover {
      transform: translateY(-5px);
      animation-duration: 0.15s;
      animation-name: slideup;
      animation-timing-function: linear;
    }
    transform: translateY(0px);
    animation-duration: 0.15s;
    animation-name: slidedown;
    animation-timing-function: linear;
  }
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 100%;
  width: 100%;
`;

export const CardContentIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 12px;
  ${(props: { color?: string }) => {
    if (props?.color) {
      return css`
        background-color: ${props.color};
      `;
    }
    return css``;
  }}
`;

export const CardTitle = styled.p`
  font-weight: 600;
  line-height: 16px;
  color: rgb(99, 99, 99);
  font-size: 14px;
  text-align: center;
`;

export const CardDescription = styled.div`
  text-align: center;
  font-weight: 400;
  line-height: 19px;
  font-size: 14px;
  .fully {
    color: rgb(61, 219, 133);
    font-weight: 700;
  }
  .work {
    color: rgb(20, 131, 255);
    font-weight: 700;
  }
`;

export const Footer = styled.div`
  width: 700px;
  background: rgb(255, 249, 232);
  padding: 10px 10px;
  color: rgb(138, 106, 10);
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
`;

export const Line = styled.div`
  .bold {
    font-weight: 700;
  }
`;
