import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';

type ContainerProps = {
  contentOnly?: boolean;
};

export const Container = styled.div<ContainerProps>`
  ${({ contentOnly }) => css`
    position: relative;
    margin-top: ${contentOnly ? '0' : '-30px'};

    background-color: #ffffff;
    height: 100%;
    width: 100%;
  `}
`;

export const TopContainer = styled.section`
  padding-left: 50px;
  padding-right: 45px;
  padding-top: 20px;
`;

export const ContainerButton = styled.div`
  position: absolute;
  top: 0;
  right: 45px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 22px;
  align-items: center;

  div:first-child {
    display: flex;
    gap: 8px;
    align-items: center;
  }
`;

export const StyledButton = styled(EuiButton)`
  background: ${ConstantColors.CHILE} !important;
  border-color: ${ConstantColors.CHILE} !important;
  max-height: 34px;
  font-size: 15px;
`;

export const StyledH1 = styled.h1`
  font-size: 28px;
  font-weight: 700;
  line-height: 40px;
  color: #ff4c0c;
`;

export const SimCardsTotal = styled.p`
  font-weight: 300;
  font-size: 11px;
  line-height: 25px;
  color: #5a606b;
  margin-bottom: 10px;

  b {
    padding-right: 4px;
  }
`;

export const StyledP = styled.p`
  font-size: 15px;
  font-weight: 500;
  line-height: 18px;
  color: ${ConstantColors.ITALY};
  margin-top: 2px;
`;

export const KeyPad = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #ebebeb;
  border: 1px solid #c8c8c8;
  border-radius: 6px;
  width: 86px;
  height: 86px;
  margin-top: 40px;

  p {
    font-size: 11px;
    font-weight: 700;
    line-height: 16px;
  }
`;

export const TableContainer = styled.div`
  padding-left: 50px;
  padding-right: 45px;
  height: 100%;
  width: 100%;
`;

export const CardsStatusContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 25px 50px 0px 50px;

  .euiPanel {
    display: flex;
    width: 250px;
    height: 185px;
    border: none;
    padding: 0 15px !important;
    background-color: rgb(255, 255, 255);
    box-shadow:
      rgba(0, 0, 0, 0.08) 0px 0.9px 4px -1px,
      rgba(0, 0, 0, 0.06) 0px 2.6px 8px -1px,
      rgba(0, 0, 0, 0.05) 0px 5.7px 12px -1px,
      rgba(0, 0, 0, 0.04) 0px 15px 15px -1px;
  }

  .euiCard__top + .euiCard__content {
    margin-top: 0;
  }

  .euiCard__top {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .euiCard__content {
    flex: 1.5;
    margin-top: 5px;
    p {
      margin: 0;
    }
  }

  .managementStatusTitle {
    color: #34b56f;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .pendingStatusTitle {
    color: #eb996e;
    font-weight: 700;
    font-size: 20px;
    line-height: 32px;
  }

  .statusDescription {
    font-weight: 400;
    font-size: 14px;
    color: #343741;
  }
`;

export const SearchBarContainer = styled.div`
  padding-left: 50px;
  padding-right: 45px;
  padding-top: 30px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
`;

export const SearchBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 430px;

  .text-field {
    display: flex;
  }

  .euiFieldSearch {
    width: 280px;
    &::placeholder {
      font-size: 11px;
    }
  }
`;

export const SearchStatus = styled.div``;

export const SearchSelect = styled.select`
  max-width: 100px;
  height: 40.5px;
  padding: 12px;
  background-color: #e9edf3;
  color: #1a1c21;
  font-weight: 600;
  font-size: 12px;
  border: none;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  z-index: 1;
`;

export const SearchAppend = styled.div`
  display: flex;
  justify-content: center;

  width: 42px;
  height: 41px;

  background-color: #e9edf3;
  border: 2px solid #e9edf3;
  border-radius: 6px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
`;

export const SearchButtonIcon = styled(EuiButtonEmpty)`
  color: #4e4b49;
`;
