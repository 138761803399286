import { EuiTextColor } from '@elastic/eui';
import { IconDevice, IconWorkProfile } from 'icons';
import type { AndroidEnrollSelectorProps } from '../enroll-popups.contracts';
import * as style from './android-enroll-selector.style';

export function AndroidEnrollSelector(props: AndroidEnrollSelectorProps) {
  const { onSelect } = props;
  return (
    <style.Container>
      <style.Header>
        <style.Title>Que tipo de ativação vamos fazer?</style.Title>
        <style.Description>Escolha o tipo de gerenciamento que deseja utilizar</style.Description>
      </style.Header>
      <style.Body>
        <style.Item>
          <style.Card onClick={() => onSelect('fully')} title="">
            <style.CardContent>
              <style.CardContentIcon color="#3ddb85">
                <IconDevice width={15} height={24} fill="white" />
              </style.CardContentIcon>
              <style.CardTitle>Fully Managed Device</style.CardTitle>
            </style.CardContent>
          </style.Card>
          <style.CardDescription>
            <EuiTextColor>Dispositivo de propriedade da empresa </EuiTextColor>
            <EuiTextColor className="fully">100% gerenciado.</EuiTextColor>
          </style.CardDescription>
        </style.Item>
        <style.Item>
          <style.Card onClick={() => onSelect('fully_work_profile')} title="">
            <style.CardContent>
              <style.CardContentIcon>
                <style.CardContentIcon color="#3ddb85">
                  <IconDevice width={15} height={24} fill="white" />
                </style.CardContentIcon>
                <div style={{ position: 'absolute', marginLeft: 45, marginTop: 45 }}>
                  <IconWorkProfile width={23} height={22} fill="#1483FF" />
                </div>
              </style.CardContentIcon>
              <style.CardTitle>Fully Managed with a Work Profile</style.CardTitle>
            </style.CardContent>
          </style.Card>
          <style.CardDescription>
            <EuiTextColor>Dispositivo de propriedade da empresa </EuiTextColor>
            <EuiTextColor className="fully">parcialmente gerenciado </EuiTextColor>
            <EuiTextColor className="work">com perfil para trabalho.</EuiTextColor>
          </style.CardDescription>
        </style.Item>
        <style.Item>
          <style.Card onClick={() => onSelect('work_profile')} title="">
            <style.CardContent>
              <style.CardContentIcon>
                <IconWorkProfile width={46} height={44} fill="#1483FF" />
              </style.CardContentIcon>
              <style.CardTitle>Work Profile (BYOD)</style.CardTitle>
            </style.CardContent>
          </style.Card>
          <style.CardDescription>
            <EuiTextColor>Dispositivo de propriedade do colaborador </EuiTextColor>
            <EuiTextColor className="work">com perfil para trabalho.</EuiTextColor>
          </style.CardDescription>
        </style.Item>
      </style.Body>
      <style.Footer>
        <style.Line>
          <EuiTextColor className="bold">Requisito: </EuiTextColor>
          <EuiTextColor>Para ativações de </EuiTextColor>
          <EuiTextColor className="bold">“Fully Managed Device”</EuiTextColor>
          <EuiTextColor>e </EuiTextColor>
          <EuiTextColor className="bold">”Fully Managed with Work Profile” </EuiTextColor>
        </style.Line>
        <style.Line>
          <EuiTextColor>é necessário um dispositivo novo, sendo ligado pela primera vez ou logo depois de um reset de fábrica.</EuiTextColor>
        </style.Line>
      </style.Footer>
    </style.Container>
  );
}
