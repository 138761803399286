import type { ReactElement } from 'react';
import type { DashboardCardComponentProps } from './DashboardCardComponent.contracts';
import * as S from './DashboardCardComponent.styles';

export const DashboardCardComponent = ({ children, icon, title, isLoading }: DashboardCardComponentProps): ReactElement => {
  return (
    <S.Container>
      <S.Title>
        {icon || null}
        <p>
          {title}
          {isLoading && <S.StyledCircularProgress />}
        </p>
      </S.Title>
      {children}
    </S.Container>
  );
};
