import { EuiGlobalToastList } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const StyledToast = styled(EuiGlobalToastList)`
  .euiToastHeader span {
    font-weight: bold !important;
    color: ${ConstantColors.HAITI};
  }
`;
