import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  .euiComboBox.euiComboBox-isOpen .euiComboBox__inputWrap {
    ${({ theme }) => css`
      ${`background-image: linear-gradient(to top, ${theme.main}, ${theme.main} 2px, transparent 2px, transparent 100%);`}
    `}
  }
`;

export const Label = styled.p`
  padding-top: 1.5px;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #1a1c21;
`;
