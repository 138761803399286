import type { InfoWindowProps } from './InfoWindowComponent.contracts';
import React from 'react';
import Icon from '@mdi/react';
import { mdiAndroid, mdiApple, mdiGoogleChrome } from '@mdi/js';
import moment from 'moment';
import type { MomentInput } from 'moment-timezone';
import TZ from 'moment-timezone';
import 'moment/dist/locale/pt-br';
import 'moment/dist/locale/es';
import * as S from './InfoWindowComponent.styles';
import { useTypedTranslation } from 'modules/core/hooks';

export const InfoWindowComponent = ({ locationDetails, language, timezone }: InfoWindowProps) => {
  const { t } = useTypedTranslation<'maps.infoWindow'>('maps.infoWindow');

  if (!locationDetails) return null;

  const { device, location, telephonyInfo, user } = locationDetails;

  let icon: React.ReactNode;

  switch (locationDetails.device?.platform?.toLowerCase()) {
    case 'android':
      icon = <Icon path={mdiAndroid} title="Android" color="#3DDB85" size={0.8} />;
      break;
    case 'ios':
      icon = <Icon path={mdiApple} title="iOS" color="#67758d" size={0.7} />;
      break;
    case 'chromeos':
      icon = <Icon path={mdiGoogleChrome} title="iOS" size={0.7} />;
      break;
    default:
      icon = null;
      break;
  }

  const getFormattedHour = (date?: string) => {
    const momentHour = moment(`${date}+00:00`).locale(language);

    return TZ.utc(momentHour as MomentInput, true)
      .tz(timezone)
      .format('L LT');
  };

  return (
    <S.Container>
      <S.InfoWindowLine>
        <p className="info-title">{t('titles.device')}</p>
        <div>
          <div>
            {device?.id && <span className="link">{device.id}</span>}
            {(user?.firstName || user?.lastName) && <span>{`${user.firstName} ${user.lastName}`}</span>}
          </div>
          {telephonyInfo?.phoneNumber1 && <p className="secondary-info">{telephonyInfo.phoneNumber1}</p>}
          {telephonyInfo?.phoneNumber2 && <p className="secondary-info">{telephonyInfo.phoneNumber2}</p>}
        </div>
      </S.InfoWindowLine>
      <S.InfoWindowLine>
        <p className="info-title">{t('titles.location')}</p>
        <div>
          <div>
            <p>{getFormattedHour(location?.recordedAt)}</p>
            {location?.provider && <p className="secondary-info">{t(`provider.${location.provider}`)}</p>}
            {location?.accuracy && <p className="secondary-info">{`${t('precision')} ${location.accuracy}`}</p>}
          </div>
        </div>
      </S.InfoWindowLine>
      <S.InfoWindowLine>
        <p className="info-title">{t('titles.user')}</p>
        <div>
          <div>
            {(user?.firstName || user?.lastName) && <p>{`${user.firstName} ${user.lastName}`}</p>}
            {user?.email && <p>{user.email}</p>}
            {user?.id && <p>{user.id}</p>}
          </div>
        </div>
      </S.InfoWindowLine>
      <S.InfoWindowLine>
        <p className="info-title">{t('titles.info')}</p>
        <div>
          <div>
            {device?.identifier && <p>{device.identifier}</p>}
            {telephonyInfo?.imei1 && <p className="secondary-info">{telephonyInfo.imei1}</p>}
            {telephonyInfo?.imei2 && <p className="secondary-info">{telephonyInfo.imei2}</p>}
            {device?.model && (
              <div className="model-info secondary-info">
                <span className="secondary-info">{device.model}</span>
                {icon}
                {device.osVersion && <span>{device.osVersion}</span>}
              </div>
            )}
          </div>
        </div>
      </S.InfoWindowLine>
    </S.Container>
  );
};
