/* eslint-disable react/jsx-no-useless-fragment */
import type { EnrollInformationPopupProps, SwitchRenderProps } from './enroll-popups.contracts';
import * as style from './enroll-information-popup.style';
import { ChromeEnrollInformation } from './chromeOS/chromeOS-information-topic';
import { AndroidEnrollInformation } from './android/android-information-topic';

export function EnrollInformationPopup(props: EnrollInformationPopupProps) {
  const { type, onCancel, onReturn } = props;

  const render: SwitchRenderProps = {
    chrome: <ChromeEnrollInformation onReturn={() => onReturn()} onCancel={() => onCancel()} />,
    android: <AndroidEnrollInformation />,
    ios: <></>,
  };

  return <style.Container>{render[type]}</style.Container>;
}
