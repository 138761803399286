import { ComponentButtonEmpty } from 'components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ConstantRoutes } from 'utilities';
import * as style from './certificates-cards-group.style';
import type { CertificatesCardsGroupProps } from './certificates-cards-settings.contracts';
import { QuickCertificatesSettings } from './quick-certificates-settings';

export const CertificatesCardsGroup = (props: CertificatesCardsGroupProps) => {
  const { credentials_config_disabled, onChange } = props;
  const [is_sending_to_android, set_is_sending_to_android] = useState(false);
  const [number_of_ca_cert] = useState(5);
  const [number_of_identity_cert] = useState(0);

  const history = useNavigate();

  const go_to_certificate_page = () => {
    history(ConstantRoutes.AE_CERTIFICATE.path);
  };
  return (
    <style.Container>
      <QuickCertificatesSettings
        title="Instalação de certificados na KeyStore"
        label="Impedir que o usuário adicione e remova certificados"
        active={credentials_config_disabled}
        onChange={() => onChange(!credentials_config_disabled, 'credentials_config')}
      >
        <style.CardDescription>
          Recurso de fontes desconhecidas permite a instalação de aplicativos por meios diferentes da Play Store. Habilitar este recurso pode gerar
          vulnerabilidades se você não tiver certeza da origem do app instalado (.apk).
        </style.CardDescription>
      </QuickCertificatesSettings>
      <QuickCertificatesSettings
        title="Instalação remota de certificados nos dispositivos"
        label="Enviar certificados para o Android"
        active={is_sending_to_android}
        onChange={() => set_is_sending_to_android(!is_sending_to_android)}
      >
        <style.CardDescription>
          Recurso de fontes desconhecidas permite a instalação de aplicativos por meios diferentes da Play Store. Habilitar este recurso pode gerar
          vulnerabilidades se você não tiver certeza da origem do app instalado (.apk).
        </style.CardDescription>
        <style.Footer>
          <ul>
            <li>({number_of_ca_cert}) Certificados CA instalados </li>
            <li>({number_of_identity_cert}) Certificados de Identidade instalados </li>
          </ul>

          <ComponentButtonEmpty icon_type="arrowDown" on_click={go_to_certificate_page} label="Gerenciar Certificados" />
        </style.Footer>
      </QuickCertificatesSettings>
    </style.Container>
  );
};
