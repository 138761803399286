import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Wrapper = styled.main`
  .slick-track,
  .slick-list {
    display: flex;
    height: 380px;
    padding: 5px 0;
  }

  .slick-slide > div {
    margin: 0 8px;
    row-gap: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .slick-list {
    margin: 0 -4px;
  }

  .slick-dots {
    display: flex !important;
    justify-content: center;

    li {
      margin: 0 6px;
      list-style: none;
      button {
        height: 6px;
        width: 6px;
        margin-top: 15px;
        border-radius: 50%;
        background-color: ${ConstantColors.CUBA};
        border: none;
        font-size: 0;
        transition: 0.3s;
      }

      &.slick-active {
        button {
          background-color: ${({ theme }) => theme.main};
          transition: 0.3s;
        }
      }

      &::marker {
        content: none;
      }
    }
  }
`;
