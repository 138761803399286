import styled from 'styled-components';

export const DescriptionText = styled.p`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  color: #4a4a4a;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const RadiusFlag = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #4a4a4a;
`;

export const CoordinatesColumn = styled.div`
  font-style: italic;
  font-weight: 700;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;

  p {
    font-size: 10px !important;
  }
  span {
    font-weight: normal;
  }
`;

export const FenceName = styled.p`
  font-size: 12px;
  font-weight: 700;
  color: #4a4a4a;
`;

export const Container = styled.div`
  thead,
  tbody {
    background: #f9f9f9 !important;
  }

  .euiTableCellContent__text {
    font-weight: 500;
    font-size: 12px;
    color: #67758d;
  }
  display: flex;
  gap: 12px;
  flex-direction: column;
  align-items: flex-end;
`;
