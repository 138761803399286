/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
import { useEffect, useMemo, useState } from 'react';
import MemoryIcon from '@mui/icons-material/Memory';
import * as S from './DevicesByMemoryRAMComponent.styles';
import { DashboardCardComponent } from '../../DashboardCard/DashboardCardComponent';
import { IndicatorPercentageBarComponent } from '../../IndicatorPercentageBar/IndicatorPercentageBarComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';
import type { StatisticsWithGraphics } from 'modules/operational/pages/dashboard/DashboardPage.contracts';

export const DevicesByMemoryRAMComponent = () => {
  const [devices, setDevices] = useState<StatisticsWithGraphics[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const servicePulsusHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);

  const getDevicesByMemory = async () => {
    try {
      const devicesByMemory = await servicePulsusHagalaz.getDashboardDevicesPercentageGraphics('memory');
      setDevices(devicesByMemory.slice().reverse());
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDevicesByMemory();
  }, []);

  return (
    <DashboardCardComponent isLoading={isLoading} title={t('dashboards.byMemoryRAM.title')} icon={<MemoryIcon />}>
      <S.Table>
        <h3>{t('dashboards.byMemoryRAM.column1')}</h3>
        <div />
        <h3>{t('dashboards.byMemoryRAM.column2')}</h3>
      </S.Table>
      {devices.map((item, idx) => {
        const roundedPercentageValue = Math.round(item.ratio);
        if (idx >= devices.length - 2) {
          return <IndicatorPercentageBarComponent key={item.label} inLimit count={item.count} label={item.label} value={roundedPercentageValue} />;
        }
        return <IndicatorPercentageBarComponent key={item.label} count={item.count} label={item.label} value={roundedPercentageValue} />;
      })}
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </DashboardCardComponent>
  );
};
