import { EuiCard } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Card = styled(EuiCard)`
  display: flex;
  border-radius: 9px;
  padding: 25px !important;
  border: none !important;
  width: 100%;
  background: ${ConstantColors.AUSTRIA};

  .euiTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
`;
export const Container = styled.div`
  padding-bottom: 25px;
  max-width: 483px;

  div.radio-options {
    padding: 20px 0 0 0;
    .euiRadioGroup__item {
      margin: 10px 0;
    }
    .euiRadio__label {
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
      color: ${ConstantColors.COLOMBIA};
    }
  }
`;

export const CardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: ${ConstantColors.ITALY};
`;

export const DateInterval = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input.timeInput {
    border: none;
    border-radius: 6px;
    max-width: 100px;
    height: 30px;

    box-shadow:
      0 1px 1px -1px rgb(152 162 179 / 20%),
      0 3px 2px -2px rgb(152 162 179 / 20%),
      inset 0 0 0 1px rgb(15 39 118 / 10%);
    cursor: pointer;
  }
`;
