export class HelperFunctions {
  static inputHasOnlyNumbers(inputValue: string) {
    let result: boolean;

    if (/^[0-9]+$/.test(inputValue) || inputValue === '') {
      result = true;
    } else {
      result = false;
    }

    return result;
  }
}
