import { useState, type ReactElement } from 'react';
import * as S from './UserModalTabsComponent.styles';

import { NavigationTabsComponent } from 'modules/core/components/NavigationTabs/NavigationTabsComponent';
import { NavigationTabPanelComponent } from 'modules/core/components/NavigationTabPanel/NavigationTabPanelComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import { DevicerPerUserTabComponent } from './DevicesPerUserTab/DevicesPerUserTabComponent';

export const UserModalTabsComponent = (): ReactElement => {
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const { t } = useTypedTranslation<'users'>('users');

  const tabs = [
    {
      id: 0,
      name: t('tab_devices'),
    },
    {
      id: 1,
      name: t('tab_terms'),
      disabled: true,
    },
    {
      id: 2,
      name: t('tab_log'),
      disabled: true,
    },
  ];

  return (
    <S.Container>
      <S.TabColumns>
        <NavigationTabsComponent tabs={tabs} selectedTab={selectedTab} onClick={(id: number) => setSelectedTab(id)} />
      </S.TabColumns>
      <S.Body>
        <NavigationTabPanelComponent index={0} value={selectedTab}>
          <DevicerPerUserTabComponent />
        </NavigationTabPanelComponent>
      </S.Body>
    </S.Container>
  );
};
