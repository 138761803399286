import styled from 'styled-components';

export const Container = styled.div`
  padding: 40px 80px;
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 31.418px;
  line-height: 35px;
  color: ${({ theme }) => theme.main};
`;

export const SubTitle = styled.p`
  font-weight: 400;
  font-size: 17.0888px;
  line-height: 23px;
  color: #474747;
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-around;
`;

export const Item = styled.div`
  width: 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  @keyframes hoverFloat {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .actived {
    :hover {
      animation-name: hoverFloat;
      animation-duration: 0.15s;
      animation-timing-function: linear;
      transform: translateY(-5px);
    }
  }

  .disabled {
    opacity: 0.2;
    background: lightgray;
    cursor: not-allowed;
  }
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 202.11px;
  height: 191.3px;
  background: #ffffff;
  box-shadow:
    0px 0.972736px 4.32327px -1.08082px rgba(0, 0, 0, 0.08),
    0px 2.81013px 8.64654px -1.08082px rgba(0, 0, 0, 0.06),
    0px 6.16066px 12.9698px -1.08082px rgba(0, 0, 0, 0.05),
    0px 16.2123px 16.2123px -1.08082px rgba(0, 0, 0, 0.04);
  border-radius: 15.1315px;
  cursor: pointer;
`;

export const Icon = styled.div`
  height: 85px;
`;

export const Text = styled.p`
  font-weight: 600;
  font-size: 18.081px;
  line-height: 22px;
  color: #636363;
`;

export const Description = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  .euiTextColor {
    color: #474747;
  }
  .bold {
    font-weight: 700 !important;
  }
`;
