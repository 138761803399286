import styled from 'styled-components';

export const Table = styled.table`
  display: grid;
  grid-template-columns: 2.3fr 2fr 10fr;
  gap: 15px;
  margin-bottom: 4px;
  align-items: center;
  justify-content: center;

  h3 {
    font-weight: 600;
    font-size: 14px;
    color: ${({ theme }) => theme.dashboardTheme.font};
  }
`;
