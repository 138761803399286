import { IconPulsusLogo, IconInsights, IconInsightsBatteries } from 'icons';
import { useTheme } from 'styled-components';
import image_pulsus_insights_home from 'assets/images/image-pulsus-insights-home.svg';
import { Link } from 'react-router-dom';
import { IconInsightsSignalsMap } from 'icons/insights/icon-insights-signals-map';
import { useTranslation } from 'react-i18next';
import * as style from './component-pulsus-insights-content.style';
import type { PulsusInsightsProps } from './component-pulsus-insights-content.contracts';
import { ComponentResourceChecker } from '../../modules/core/components';
import { ConstantResource } from '../../modules/core/utilities';

export function ComponentPulsusInsightsContent(props: PulsusInsightsProps) {
  const { displaySignals, displayBatteries, urlPulsusSignals, urlPulsusBatteries } = props;
  const theme = useTheme();
  const { t } = useTranslation();

  function renderBatteries() {
    return (
      <ComponentResourceChecker identifier={ConstantResource.INSIGHTS.BATTERY}>
        <style.Link isDisabled={!displayBatteries}>
          <style.Card>
            <style.CardLeft>
              <style.CardTitle isDisabled={!displayBatteries}>
                <IconPulsusLogo width={90} height={17} color="#525252" />
                <h2>{t('pages.pulsus_insights.modules.batteries')}</h2>
                <style.FlagComingSoon isDisabled={!displayBatteries}>{t('pages.pulsus_insights.flag')}</style.FlagComingSoon>
              </style.CardTitle>
              <style.OpenLink isDisabled={!displayBatteries}>
                {t('pages.pulsus_insights.cards.batteries.link')}
                {' >'}
              </style.OpenLink>
            </style.CardLeft>

            <style.CardRight isDisabled={!displayBatteries}>
              <IconInsightsBatteries />
            </style.CardRight>
          </style.Card>
          <style.LinkTexts isDisabled={!displayBatteries}>
            <p>{t('pages.pulsus_insights.cards.batteries.text')}</p>
          </style.LinkTexts>
        </style.Link>
      </ComponentResourceChecker>
    );
  }

  function renderSignals() {
    return (
      <ComponentResourceChecker identifier={ConstantResource.INSIGHTS.SIGNALS}>
        <style.Link isDisabled={!displaySignals} style={{ position: 'relative' }}>
          <style.Card>
            <style.CardLeft>
              <style.CardTitle isDisabled={!displaySignals}>
                <IconPulsusLogo width={90} height={17} color="#525252" />
                <h2>{t('pages.pulsus_insights.modules.signals')}</h2>
                <style.FlagComingSoon isDisabled={!displaySignals}>{t('pages.pulsus_insights.flag')}</style.FlagComingSoon>
              </style.CardTitle>
              <style.OpenLink isDisabled={!displaySignals}>
                {t('pages.pulsus_insights.cards.signals.link')}
                {' >'}
              </style.OpenLink>
            </style.CardLeft>
            <style.CardRight isDisabled={!displaySignals}>
              <IconInsightsSignalsMap />
            </style.CardRight>
          </style.Card>
          <style.LinkTexts isDisabled={!displaySignals}>
            <p>{t('pages.pulsus_insights.cards.signals.text')}</p>
          </style.LinkTexts>
        </style.Link>
      </ComponentResourceChecker>
    );
  }

  return (
    <style.Container data-testid="page__pulsus_insight">
      <style.TopContainer>
        <style.LeftContainer>
          <style.PulsusInsights>
            <IconPulsusLogo width={200} height={35} color="#525252" />
            <IconInsights width="230px" height="50px" color={theme.main} />
          </style.PulsusInsights>
          <style.TextPrimary data-testid="text_primary__pulsus-insight">{t('pages.pulsus_insights.text_primary_1')}</style.TextPrimary>
          <style.TextPrimary data-testid="text_primary__pulsus-insight">{t('pages.pulsus_insights.text_primary_2')}</style.TextPrimary>
        </style.LeftContainer>
        <style.RightContainer>
          <style.ImagePulsusInsightsHome
            data-testid="image_pulsus_insights"
            src={image_pulsus_insights_home}
            alt="um grupo de pessoas usando celulares"
          />
        </style.RightContainer>
      </style.TopContainer>
      <style.BottomContainer>
        <style.TitleSolutions>{t('pages.pulsus_insights.subtitle')}</style.TitleSolutions>
        <style.LinksContainer>
          {displaySignals ? <Link to={urlPulsusSignals}>{renderSignals()}</Link> : <>{renderSignals()}</>}
          {displayBatteries ? <Link to={urlPulsusBatteries}>{renderBatteries()}</Link> : <>{renderBatteries()}</>}
        </style.LinksContainer>
      </style.BottomContainer>
    </style.Container>
  );
}
