import { ComponentApplicationContainer } from 'modules/core/components';
import { ConstantRoutes } from 'utilities';
import { ComponentBreadcrumb, ComponentPulsusInsightsBatteriesContent } from 'components';

export function PagePulsusInsightsBatteries() {
  return (
    <ComponentApplicationContainer test_id="page__pulsus-insights-batteries">
      <ComponentBreadcrumb items={ConstantRoutes.PULSUS_INSIGHTS_BATTERIES.breadcrumbs} />
      <ComponentPulsusInsightsBatteriesContent route={ConstantRoutes.PULSUS_INSIGHTS_BATTERIES.path} />
    </ComponentApplicationContainer>
  );
}
