/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useState } from 'react';
import { EuiButtonEmpty, EuiFieldText, EuiFormControlLayout, EuiIcon, EuiRadioGroup, EuiSwitch } from '@elastic/eui';
import { ServicePulsusFrigg } from 'services/ServicePulsusFrigg';

import * as style from './permission-toggle.style';
import { GetCardFlagsAndroid } from '../flags-label-android';

export const PermissionToggle = () => {
  const [is_permission_on, set_is_permission_on] = useState(false);
  const [value_input, set_value_input] = useState('');
  const [has_valid_permission, set_has_valid_permission] = useState(false);
  const [radio_id_selected, set_radio_id_selected] = useState('');
  const [has_error, set_has_error] = useState(false);
  const options = GetCardFlagsAndroid();

  const handleAddPermission = (event: any) => {
    set_value_input(`android.permission.${event.target.value}`);
    set_has_error(false);
  };

  const handle_radio_options = (option_id: string) => {
    set_radio_id_selected(option_id);
    set_has_error(false);
  };

  const add_permission = (permission: string, policy: string) => {
    set_has_error(false);
    const mock = {
      parent: 'LC02vz2feo',
      policy_name: '1',
      x_schema: process.env.REACT_APP_TENANT,
    };

    const body = [
      {
        permission,
        policy,
      },
    ];

    ServicePulsusFrigg.addNewPermission(mock.parent, mock.policy_name, body)
      .then(() => {
        set_has_valid_permission(!has_valid_permission);
      })
      .catch((error) => {
        set_has_error(true);
        console.log(error);
      });
  };

  return (
    <div className="item">
      <style.Card title="Definir padrões para permissões" textAlign="left">
        <main>
          <style.SwitchContainer>
            <EuiSwitch
              label={is_permission_on ? 'Permissões habilitadas' : 'Permissões desabilitadas'}
              checked={is_permission_on}
              onChange={() => set_is_permission_on(!is_permission_on)}
            />
          </style.SwitchContainer>
          <style.CardDescription>
            Adicione permissões e defina o comportamento esperado sempre que um app enviado pela Play Store corporativa solicitar.
            <small>
              <p>
                <span> Prompt: </span>
                solicita ao usuário que escolha se quer ou não aceitar
              </p>
              <p>
                <span> Grant: </span>
                aceita automaticamente a permissão
                <span> Deny: </span>
                nega automaticamente a permissão
              </p>
            </small>
          </style.CardDescription>

          <style.CardFlags>
            {options.debuggerModeFlags.map((item) => (
              <style.Flag key={item.id}>{item.label}</style.Flag>
            ))}
          </style.CardFlags>

          {is_permission_on && (
            <style.PermissionOnSection className="permission_on_section">
              <h6>Escreva a politica que deseja adicionar:</h6>
              <div className="input_container">
                <EuiFormControlLayout>
                  <EuiFieldText
                    disabled={has_valid_permission}
                    type="text"
                    placeholder="nome.da.política"
                    onChange={handleAddPermission}
                    prepend="android.permission."
                  />
                </EuiFormControlLayout>
                <EuiButtonEmpty iconSide="right" iconType="arrowDown" size="xs" onClick={() => add_permission(value_input, radio_id_selected)}>
                  {has_valid_permission ? (
                    'Editar permissão'
                  ) : (
                    <>
                      <EuiIcon type="plusInCircle" />
                      Adicionar permissão
                    </>
                  )}
                </EuiButtonEmpty>
                {has_error && <style.ErrorMessage>Erro ao adicionar permissão</style.ErrorMessage>}
              </div>

              <EuiRadioGroup
                options={[
                  {
                    id: 'Prompt',
                    label: 'Prompt',
                  },
                  {
                    id: 'Grant',
                    label: 'Grant',
                  },
                  {
                    id: 'Deny',
                    label: 'Deny',
                  },
                ]}
                idSelected={radio_id_selected}
                onChange={(label) => handle_radio_options(label)}
                name="radio group"
                className="eui_radio_group"
                disabled={has_valid_permission}
              />
            </style.PermissionOnSection>
          )}
        </main>
      </style.Card>
    </div>
  );
};
