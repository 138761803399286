import { ResourceContext } from 'modules/core/contexts/ResourceContext/ResourceContext';
import { useContext } from 'react';
import { Navigate } from 'react-router';
import { ConstantRoutes } from 'utilities';

export const ComponentResourceChecker = ({ children, identifier = '', redirect = false }) => {
  const resources = useContext(ResourceContext);

  if (redirect) {
    return resources.includes(identifier) ? children : <Navigate to={ConstantRoutes.HOME.path} replace />;
  }

  return resources.includes(identifier) ? children : null;
};
