import { ActionType, type Action, type InitialState } from './contracts';

export const initialState: InitialState = {
  administrator: undefined!,
  tenant: undefined!,
  featureFlags: [],
};

export const userReducer = (state = initialState, action: Action): InitialState => {
  switch (action.type) {
    case ActionType.UPDATE_ADMINISTRATOR_INFOS:
      return {
        ...state,
        administrator: action.payload,
      };
    case ActionType.UPDATE_TENANT_INFOS:
      return {
        ...state,
        tenant: action.payload,
      };
    case ActionType.UPDATE_LOGO:
      return {
        ...state,
        tenant: {
          ...state.tenant,
          logoUrl: action.payload,
        },
      };

    case ActionType.UPDATE_FEATURE_FLAGS:
      return {
        ...state,
        featureFlags: action.payload,
      };
    default:
      return state;
  }
};
