import { EuiResizableContainer } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  display: flex;
  width: 100%;
  justify-content: center;
  #content {
    overflow: hidden;
    .euiPanel {
      padding: 0;
      background-color: ${ConstantColors.AUSTRIA};

      ::-webkit-scrollbar {
        width: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: rgba(105, 112, 125, 10%);
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: rgb(105 112 125 / 10%);
      }
    }
  }
`;

export const Boxed = styled.div`
  width: 100%;
  position: relative;
  margin-top: 49px !important;
  height: calc(100vh - 50px);
`;

export const ResizableContainer = styled(EuiResizableContainer)`
  background: ${ConstantColors.BRAZIL};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  max-width: 1440px;
  overflow-x: hidden;

  .euiSideNavItem--branch::after {
    display: none;
  }

  & .euiSideNav__mobileToggle {
    display: none !important;
  }

  & .euiSideNav__content {
    padding: 0;
  }
`;

export const ResizableContainerPanel = styled.div``;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  background-color: ${ConstantColors.AUSTRIA};
`;
