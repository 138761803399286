import styled, { css } from 'styled-components';
import { CircularProgress } from '@mui/material';

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 33.3%;
    flex-direction: column;
    gap: 8px;
    background-color: ${theme.dashboardTheme.paper};
    padding: 6px 0;
    height: 100%;

    table {
      h3 {
        text-align: center;
      }
    }
  `}
`;

export const Title = styled.h2`
  ${({ theme }) => css`
    font-weight: 500;
    margin-bottom: 12px;
    height: 20px;
    display: flex;
    align-items: center;
    gap: 4px;
    color: ${theme.dashboardTheme.font};

    p {
      font-size: 14px;
      display: flex;
      gap: 8px;
    }

    svg {
      color: ${theme.dashboardTheme.paper === '#232323' ? theme.main : 'unset'};
    }
  `}
`;

export const StyledCircularProgress = styled(CircularProgress)`
  max-height: 20px !important;
  max-width: 20px !important;

  svg {
    color: ${({ theme }) => theme.main};
  }
`;
