import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Wrapper = styled.main`
  p {
    color: ${ConstantColors.COLOMBIA};
  }

  .theme-color {
    color: ${({ theme }) => theme.main};
  }

  h1 {
    color: ${ConstantColors.SPAIN};
  }
`;

export const TopContainer = styled.section`
  display: flex;
  justify-content: space-between;

  padding-left: 50px;

  @media (min-width: 1600px) {
    justify-content: space-around;
  }
`;

export const TopLeft = styled.div`
  svg {
    margin-bottom: 50px;
  }
`;

export const TopRight = styled.div`
  p {
    margin: 15px 0;
  }
`;

export const DevicesCards = styled.div`
  display: flex;
  flex-wrap: wrap;
  row-gap: 16px;
  column-gap: 27px;
  max-width: 630px;

  margin-top: 20px;

  a {
    color: unset;
    font-weight: unset;

    &:hover {
      text-decoration: unset;
    }
  }
`;

export const MidContainer = styled.section`
  margin-top: 70px;
  padding: 40px 0 60px 50px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  background-color: ${ConstantColors.BRAZIL};

  @media (min-width: 1600px) {
    justify-content: space-around;
  }
`;

export const ContactContainer = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 100px;
`;

export const NewsContainer = styled.section``;

export const SliderContainer = styled.div`
  width: 600px;
  margin-top: 30px;
  padding-right: 50px;
`;

export const QuicklinksContainer = styled.section`
  background-color: ${ConstantColors.BRAZIL};
  padding: 0 0 50px 50px;
  .texts {
    display: flex;

    p {
      margin-left: 30px;
      margin-top: 5px;
    }
  }
`;

export const CardsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
`;
