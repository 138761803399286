import { EuiSwitch } from '@elastic/eui';
import styled from 'styled-components';
import { Label, Table } from '../../IndicatorPercentageBar/IndicatorPercentageBarComponent.styles';

export const Main = styled.div<{ isDevicesByModel: boolean }>`
  max-width: 750px;
  overflow: auto;
  ${Table} {
    grid-template-columns: ${({ isDevicesByModel }) => (isDevicesByModel ? '4fr 1fr 7fr' : '2.3fr 1fr 10fr')};
  }

  ${Label} {
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 15px;
  }
`;

export const IndicatorTitle = styled.h2`
  font-weight: 500;
  margin-bottom: 12px;
  height: 20px;
  display: flex;
  gap: 4px;

  div {
    margin: 0 5px;
    max-width: 44px;
  }

  p {
    font-size: 13px;
  }
`;

export const Switch = styled(EuiSwitch)`
  .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
    background-color: ${({ theme }) => theme.main} !important;
    opacity: 1;
  }

  svg {
    display: none;
  }
`;
