import styled, { css } from 'styled-components';
import { EuiSelect, EuiButtonEmpty, EuiIcon } from '@elastic/eui';
import { ConstantColors } from 'utilities';
import type { FieldGroupProps } from './security-select-box.contracts';

const SelectContainer = styled.div`
  padding: 10px 0;
`;

const SelectLabel = styled.p`
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`;

const SelectField = styled(EuiSelect)`
  border-radius: 6px;
`;

const TitleGroup = styled.div`
  display: flex;
`;

const RequiredIcon = styled.p`
  padding-left: 5px;
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
`;

const InfoButton = styled(EuiButtonEmpty)`
  display: flex;
  color: ${ConstantColors.ECUADOR};
  height: 17px;
  font-size: 12px;
`;

const FieldGroup = styled.div`
  ${(props: FieldGroupProps) => {
    if (props.active) {
      return css`
        display: flex;
        align-items: center;
      `;
    }
    return css``;
  }}
`;

const SelectFieldDescription = styled.p`
  padding-left: 5px;
`;

const WarningGroup = styled.div`
  padding-top: 5px;
  display: flex;
  align-items: center;
`;

const WarningIcon = styled(EuiIcon)``;

const WarningMessage = styled.p`
  padding-left: 5px;
  font-size: 12px;
`;

export {
  SelectContainer,
  SelectLabel,
  SelectField,
  TitleGroup,
  RequiredIcon,
  InfoButton,
  FieldGroup,
  SelectFieldDescription,
  WarningGroup,
  WarningIcon,
  WarningMessage,
};
