import type { ReactElement } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import * as S from './FooterComponent.styles';
import { useTypedTranslation } from 'modules/core/hooks';

export const FooterComponent = (): ReactElement => {
  const currentYear = new Date().getFullYear().toString();

  const { t } = useTypedTranslation<'core_components.footer'>('core_components.footer');

  return (
    <S.Container>
      <S.Date dateTime={currentYear}>Copyright © {currentYear} Pulsus</S.Date>
      <S.StyledLink href="https://apk.pulsus.mobi">
        <DownloadIcon />
        {t('download')}
      </S.StyledLink>
    </S.Container>
  );
};
