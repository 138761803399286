import { type ReactElement } from 'react';
import * as S from './PercentageIndicatorsComponent.styles';
import { LessInstalledAppsComponent } from './LessInstalledApps/LessInstalledAppsComponent';
import { WorstInstalattionAppsComponent } from './WorstInstalattionApps/WorstInstalattionAppsComponent';
import { DeviceByPlatformOrModelComponent } from './DeviceByPlatformOrModel/DeviceByPlatformOrModelComponent';
import { DevicesByBatteryComponent } from './DevicesByBattery/DevicesByBatteryComponent';
import { DevicesByStorageComponent } from './DevicesByStorage/DevicesByStorageComponent';
import { DevicesByMemoryRAMComponent } from './DevicesByMemoryRAM/DevicesByMemoryRAMComponent';
import { ComponentResourceChecker } from 'modules/core/components';
import { ConstantResource } from 'modules/core/utilities';

export const PercentageIndicatorsComponent = (): ReactElement => {
  return (
    <S.Container>
      <S.Card className="first-row">
        <ComponentResourceChecker identifier={ConstantResource.DASHBOARD.STATS_APPS}>
          <LessInstalledAppsComponent />
          <WorstInstalattionAppsComponent />
        </ComponentResourceChecker>

        <ComponentResourceChecker identifier={ConstantResource.DASHBOARD.STATS_OS_MODELS}>
          <DeviceByPlatformOrModelComponent />
        </ComponentResourceChecker>
      </S.Card>
      <S.Card className="second-row">
        <ComponentResourceChecker identifier={ConstantResource.DASHBOARD.STATS_BATTERY}>
          <DevicesByBatteryComponent />
        </ComponentResourceChecker>

        <ComponentResourceChecker identifier={ConstantResource.DASHBOARD.STATS_STORAGE}>
          <DevicesByStorageComponent />
        </ComponentResourceChecker>

        <ComponentResourceChecker identifier={ConstantResource.DASHBOARD.STATS_MEMORY}>
          <DevicesByMemoryRAMComponent />
        </ComponentResourceChecker>
      </S.Card>
    </S.Container>
  );
};
