import { Certificate } from 'entities/certificate';
import { ConstantServicesEndpoints } from 'utilities';
import { HelperAuthentication } from 'helpers';
import { BaseServiceInstance } from './BaseServiceInstance';

export class ServicePulsusWeb extends BaseServiceInstance {
  constructor() {
    super(process.env.REACT_APP_PULSUS_WEB_HOST!);
  }

  async login(email, password) {
    const response = await this._instance.post(`/v3${ConstantServicesEndpoints.PULSUS_WEB.LOGIN}`, {
      identifier: email,
      password,
    });

    if (response.data && response.data.token) {
      HelperAuthentication.login(response.data.token);
    }
  }

  async signUp(callbackUrl, tenantId, adminId) {
    const { data } = await this._instance.get('/android_enterprise/signup.json', {
      params: {
        callback_url: callbackUrl,
        tenantId,
        adminId,
      },
    });

    return data;
  }

  async recoverPassword(email) {
    return this._instance.post(`/v3${ConstantServicesEndpoints.PULSUS_WEB.RECOVER_PASSWORD}`, {
      email,
    });
  }

  async fetchAllCertificates() {
    const response = await this._instance.get(`/v3${ConstantServicesEndpoints.PULSUS_WEB.CERTIFICATES}`);

    return response.data.certificates.map((item) => {
      return new Certificate(
        item.id,
        item.group_policy_id,
        item.name,
        item.kind,
        item.administrator_id,
        item.enabled,
        item.expired,
        item.applications,
        response.data.token
      );
    });
  }

  async getGroupPolicyName() {
    const response = await this._instance.get(`/v3${ConstantServicesEndpoints.PULSUS_WEB.CERTIFICATES}`, {
      params: {
        kind: 'authority',
      },
    });

    return response.data.certificates_data.group_policy_name;
  }

  async newCertificate(file, kind) {
    const data = new FormData();
    data.append('file', file);
    data.append('kind', kind);

    return this._instance.post(`/v3${ConstantServicesEndpoints.PULSUS_WEB.CERTIFICATES}`, data);
  }

  async editCertificateIdentity(id, kind, enabled) {
    return this._instance.patch(`/v3${ConstantServicesEndpoints.PULSUS_WEB.CERTIFICATES_EDIT_IDENTITY}`, {
      id,
      kind,
      enabled,
    });
  }

  async editCertificateCA(id, kind, enabled) {
    return this._instance.patch(`/v3${ConstantServicesEndpoints.PULSUS_WEB.CERTIFICATES}`, {
      id,
      kind,
      enabled,
    });
  }

  async deleteCertificate(id, kind) {
    return this._instance.delete(`/v3${ConstantServicesEndpoints.PULSUS_WEB.CERTIFICATES}`, {
      data: {
        id,
        kind,
      },
    });
  }
  async fetchCertificatePackages(id) {
    const response = await this._instance.get(`/v3/certificates/${id}/packages`);

    return response.data;
  }

  async saveCertificatePackages(id, authorize_package_access, packages) {
    await this._instance.post(`/v3/certificates/${id}/packages`, {
      authorize_package_access,
      packages,
    });
  }

  async getSafetyNetSettings() {
    const { data } = await this._instance.get(`/v3${ConstantServicesEndpoints.PULSUS_WEB.SAFETYNET_SETTINGS}`);

    return data;
  }

  async setSafetyNetSettings(data) {
    this._instance.put(`/v3${ConstantServicesEndpoints.PULSUS_WEB.SAFETYNET_SETTINGS}`, data);
  }

  async getSupportMessages() {
    const { data } = await this._instance.get(`/v3${ConstantServicesEndpoints.PULSUS_WEB.SUPPORT_MESSAGES}`);

    return data;
  }

  async setSupportMessages(data) {
    this._instance.put(`/v3${ConstantServicesEndpoints.PULSUS_WEB.SUPPORT_MESSAGES}`, data);
  }

  async checkLoginInformations(email, password) {
    let data;
    try {
      const response = await this._instance.post(`/v3${ConstantServicesEndpoints.PULSUS_WEB.LOGIN}`, {
        identifier: email,
        password,
      });

      if (response.data && response.data.token) {
        data = response.data;
      }
    } catch (error) {
      data = false;
    }
    return data;
  }

  async pushToDevice(roomName, deviceId) {
    return this._instance.get(`/v4${ConstantServicesEndpoints.PULSUS_WEB.REMOTE_VIEW}`, {
      params: {
        room_id: roomName,
        device_id: deviceId,
      },
    });
  }

  async getFlags() {
    const { data } = await this._instance.get(`/v4${ConstantServicesEndpoints.PULSUS_WEB.GET_FLAGS}`);
    return data;
  }

  async batchGetPhoneNumber(device_ids: string[]) {
    const response = await this._instance.post(`${ConstantServicesEndpoints.PULSUS_WEB.GET_PHONE_NUMBER}`, { ids: device_ids });
    return response.data;
  }

  async batchBackupContacts(device_ids: string[]) {
    const response = await this._instance.post(`${ConstantServicesEndpoints.PULSUS_WEB.POST_BACKUP_CONTACTS}`, { ids: device_ids });
    return response.data;
  }

  async getResources() {
    return this._instance.get(`/v4${ConstantServicesEndpoints.PULSUS_WEB.GET_RESOURCES}`);
  }

  async getGroupPolicyFlags() {
    const response = await this._instance.get(`/v4${ConstantServicesEndpoints.PULSUS_WEB.GET_GROUP_POLICY_FLAGS}`);
    return response.data;
  }

  async sendContactsAppInstall(group_ids: number[]) {
    const body = {
      group_ids,
    };

    const { data } = await this._instance.post('/v4/contacts/installation_groups', body, {
      headers: {
        Authorization: HelperAuthentication.token(),
      },
    });
    return data;
  }
}
