import { Slider } from '@mui/material';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 25px;
  gap: 30px;
  .item {
    width: 493px;
  }
`;

export const Content = styled.div`
  width: 480px;
`;

export const CardDescription = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #67758d;
`;

export const SliderBoxWarning = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 5px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 25px;

  span {
    font-weight: 500;
    font-size: 13px;
    line-height: 16px;
    color: #3ddb85;
  }
`;

export const SliderBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;
  width: 100%;
  padding: 0px 25px;

  .euiFieldNumber--compressed:focus {
    background-color: white;
    background-image: linear-gradient(to top, #3ddb85, #3ddb85 2px, transparent 2px, transparent 100%);
    background-size: 100% 100%;
  }
`;

export const FlagBox = styled.div`
  padding: 0px 25px 25px 25px;
  div {
    margin: 0;
  }
`;

export const FullySlider = styled(Slider)({
  color: '#3ddb85 !important',
  height: 8,
  '& .MuiSlider-track': {
    border: 'none',
  },
  '& .MuiSlider-thumb': {
    height: 18,
    width: 18,
    backgroundColor: '#3ddb85',
    border: '2px solid #FFF;',
    boxShadow:
      '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 3.6px 13px rgba(0, 0, 0, 0.07), 0px 8.4px 23px rgba(0, 0, 0, 0.06), 0px 23px 35px rgba(0, 0, 0, 0.05)',
    '&:focus, &:hover, &.Mui-active, &.Mui-focusVisible': {
      boxShadow:
        '0px 1px 5px rgba(0, 0, 0, 0.1), 0px 3.6px 13px rgba(0, 0, 0, 0.07), 0px 8.4px 23px rgba(0, 0, 0, 0.06), 0px 23px 35px rgba(0, 0, 0, 0.05)',
    },
    '&:before': {
      display: 'none',
    },
  },
  '& .MuiSlider-valueLabel': {
    lineHeight: 1.2,
    fontSize: 12,
    background: 'unset',
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: '50% 50% 50% 0',
    backgroundColor: '#3ddb85',
    transformOrigin: 'bottom left',
    transform: 'translate(50%, -100%) rotate(-45deg) scale(0)',
    '&:before': { display: 'none' },
    '&.MuiSlider-valueLabelOpen': {
      transform: 'translate(50%, -100%) rotate(-45deg) scale(1)',
    },
    '& > *': {
      transform: 'rotate(45deg)',
    },
  },
});

export const BottomContent = styled.div`
  display: flex;
  padding: 10px 25px;
`;

export const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;

  .identifierField {
    display: flex;
    gap: 5px;
    flex-direction: column;

    .textField {
      display: flex;
      gap: 15px;

      .addButton {
        box-shadow: none;
      }

      .addButton:hover,
      .addButton:focus {
        background: white;
        box-shadow: none;
      }
    }
    .euiFieldText {
      font-weight: 400;
      font-size: 14px;
      line-height: 21px;
    }
    .euiFieldText:focus {
      background-color: white;
      background-image: linear-gradient(to top, #3ddb85, #3ddb85 2px, transparent 2px, transparent 100%);
      background-size: 100% 100%;
    }
  }

  .removeButton {
    box-shadow: none;
    margin-bottom: 10px;
  }

  .removeButton:hover,
  .removeButton:focus {
    background: white;
    box-shadow: none;
  }

  .allowFlagCol {
    color: white;
    background: #3ddb85;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 59px;
    height: 20px;
    padding: 1px 8px;
    border-radius: 3px;
  }
  .blockFlagCol {
    color: white;
    background: #ff4c0c;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    width: 59px;
    height: 20px;
    padding: 1px 8px;
    border-radius: 3px;
  }
`;

export const BoxItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .euiRadio .euiRadio__input:checked + .euiRadio__circle {
    border-color: #3ddb85;
    background-color: #3ddb85;
  }

  .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
    border-color: #98a2b3;
    background-color: #98a2b3;
  }

  &:focus {
    outline-color: #3ddb85;
  }

  .title {
    width: 80px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    color: #343741;
  }

  .description {
    width: 300px;
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    text-align: inherit;
    color: #67758d !important;
  }
`;

export const Label = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;
