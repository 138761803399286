import React from 'react';

type IconUsbInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconUsbInCicle({ width, height, elipse_fill, icon_fill }: IconUsbInCicleProps) {
  return (
    <svg width={width || 45} height={height || 45} viewBox="0 0 45 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill={elipse_fill || '#C92F2F'} />
      <path
        d="M24.6197 19.0943V22.4906H25.4648V24.1887H22.9296V17.3962H24.6197L22.0845 14L19.5493 17.3962H21.2394V24.1887H18.7042V22.4311C19.2958 22.117 19.7183 21.5142 19.7183 20.7925C19.7183 19.7651 18.8817 18.9245 17.8592 18.9245C16.8366 18.9245 16 19.7651 16 20.7925C16 21.5142 16.4225 22.117 17.0141 22.4311V24.1887C17.0141 25.1311 17.7662 25.8868 18.7042 25.8868H21.2394V28.4764C20.6394 28.7906 20.2254 29.4104 20.2254 30.1321C20.2254 31.1679 21.062 32 22.0845 32C23.107 32 23.9437 31.1679 23.9437 30.1321C23.9437 29.4104 23.5296 28.7906 22.9296 28.4764V25.8868H25.4648C26.4028 25.8868 27.1549 25.1311 27.1549 24.1887V22.4906H28V19.0943H24.6197Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
