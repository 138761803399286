import { useEffect, useMemo, useState } from 'react';
import { ComponentBreadcrumb } from 'components';
import { ComponentLoader } from 'modules/core/components';
import { ChromeBreadcrumbs } from '../chromeBreadcrumbs';
import { ComponentIframeSwitch } from './ComponentIframeSwitch/ComponentIframeSwitch';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import * as style from './PageChromeOSReport.style';
import type { IframeDashboardUrl } from './PageChromeOSReport.contracts';

export function PageChromeOSReport({ embedded }: { embedded?: boolean }) {
  const [URLs, setURLs] = useState<IframeDashboardUrl | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const HagalazServices = useMemo(() => new ServicePulsusHagalaz(), []);

  async function getIframesURL() {
    const urls = await HagalazServices.getChromeOSMetabaseDashboard();
    setURLs(urls);
  }

  useEffect(() => {
    getIframesURL();
  }, []);

  return (
    <style.Container>
      {!embedded && (
        <style.BreadcrumbBox data-testid="chromeOS-integration-breadcrumb">
          <ComponentBreadcrumb items={ChromeBreadcrumbs.PULSUS_CHROME_OS_REPORT.breadcrumbs} />
        </style.BreadcrumbBox>
      )}
      <ComponentLoader isLoading={isLoading}>
        <style.MetabaseIframe>
          {URLs?.dashboard && <iframe id="IframeChromeReportDashboard" title="metabase" src={URLs?.dashboard} onLoad={() => setIsLoading(false)} />}
        </style.MetabaseIframe>
      </ComponentLoader>
      <ComponentIframeSwitch />
    </style.Container>
  );
}
