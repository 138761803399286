import styled from 'styled-components';

export const Content = styled.main``;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: end;
  gap: 20px;

  .euiButton--primary.euiButton--fill,
  .euiButton--primary.euiButton--fill:focus,
  .euiButton--primary.euiButton--fill:hover {
    background: #ee2f51;
    border: #ee2f51;
    text-decoration: none;
  }

  .euiButton__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
  }

  .euiButtonEmpty__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: -0.08px;
    color: #30a8ff;
  }
`;

export const Title = styled.h2`
  margin-bottom: 30px;
`;

export const Text = styled.p`
  max-width: 240px;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 15px;
`;
