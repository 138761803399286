/* eslint-disable no-console */
import { ConstantColors } from 'utilities';
import { HelperStorage } from 'helpers';
import type { StepAndroidProps } from 'pages/onboarding/page-onboarding.contracts';
import { EuiButtonEmpty } from '@elastic/eui';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useEffect, useMemo } from 'react';
import { useQuery } from 'hooks/use-query';
import * as style from '../page-onboarding.style';

export function StepAndroid({ openModalConfig }: StepAndroidProps) {
  const { t } = useTranslation();
  const query = useQuery();
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const linkToGoogleUrl = () => {
    const tenantId = HelperStorage.fetch('tenantId');
    const adminId = HelperStorage.fetch('adminId');
    servicePulsusWeb
      .signUp(`${process.env.REACT_APP_PULSUS_WEB_TEST}/android_enterprise/register`, tenantId, adminId)
      .then((items) => {
        console.log(items.url);
        HelperStorage.save('urlSignup', items.url);
        HelperStorage.save('android_configurate', true);
        window.parent.location.href = items.url;
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  const language = query.get('language');
  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <style.StepContainer>
      <style.StepLeft />
      <style.StepRight>
        <div className="content">
          <h2>{t('pages.onboarding.title_phrase')}</h2>
          <p>
            {t('pages.onboarding.phrase_first')}
            <b>{t('pages.onboarding.phrase_first_subtitle')}</b>
          </p>
          <p>
            {t('pages.onboarding.phrase_second')} <b>{t('pages.onboarding.phrase_second_link')}</b> {t('pages.onboarding.phrase_second_last')}
          </p>
          <p>
            {t('pages.onboarding.phrase_three')}
            <a href="https://accounts.google.com/SignUp" target="_blank" rel="noreferrer">
              {t('pages.onboarding.phrase_four')}
            </a>
          </p>
          <style.StepButton style={{ background: ConstantColors.DJBOUTI }} size="s" fill onClick={() => linkToGoogleUrl()}>
            {t('pages.onboarding.phrase_five')}
          </style.StepButton>
          <EuiButtonEmpty onClick={() => openModalConfig()}>{t('pages.onboarding.phrase_six')}</EuiButtonEmpty>
        </div>
      </style.StepRight>
    </style.StepContainer>
  );
}
