/* eslint-disable react/jsx-one-expression-per-line */
import { ConstantColors } from 'utilities';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import { useEffect, useMemo, useState } from 'react';
import WorkOutlinedIcon from '@mui/icons-material/WorkOutlined';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { useTranslation } from 'react-i18next';
import { AdvancedFlag } from 'components';
import { mdiAndroid } from '@mdi/js';
import * as style from './support.style';
import type { SupportProps } from '../page-android-enterprise.contracts';
import type { GetSupportMessagesProps } from './support.contracts';
import { ConstantResource } from '../../../modules/core/utilities';
import { ComponentResourceChecker } from '../../../modules/core/components';

export function Support({ saveConfig, isEditable }: SupportProps) {
  const { t } = useTranslation();

  const [radioIdShortFM, setRadioIdShortFM] = useState('defaultShortFM');
  const [radioIdLongFM, setRadioIdLongFM] = useState('defaultLongFM');
  const [radioIdShortWP, setRadioIdShortWP] = useState('defaultShortWP');
  const [radioIdLongWP, setRadioIdLongWP] = useState('defaultLongWP');
  const [radioIdShortDeleteWP, setRadioIdShortDeleteWP] = useState('defaultShortDeleteWP');
  const [textShortFM, setTextShortFM] = useState('');
  const [textLongFM, setTextLongFM] = useState('');
  const [textShortWP, setTextShortWP] = useState('');
  const [textLongWP, setTextLongWP] = useState('');
  const [textShortDeleteWP, setTextShortDeleteWP] = useState('');
  const [messages, setMessages] = useState<GetSupportMessagesProps | null>();

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const radiosShortFM = [
    {
      id: 'defaultShortFM',
      label: t('components.component_support_message.inline_buttons_label.default_radio'),
    },
    {
      id: 'personalizedShortFM',
      label: t('components.component_support_message.inline_buttons_label.custom_radio'),
    },
  ];

  const radiosLongFM = [
    {
      id: 'defaultLongFM',
      label: t('components.component_support_message.inline_buttons_label.default_radio'),
    },
    {
      id: 'personalizedLongFM',
      label: t('components.component_support_message.inline_buttons_label.custom_radio'),
    },
  ];

  const radiosShortWP = [
    {
      id: 'defaultShortWP',
      label: t('components.component_support_message.inline_buttons_label.default_radio'),
    },
    {
      id: 'personalizedShortWP',
      label: t('components.component_support_message.inline_buttons_label.custom_radio'),
    },
  ];

  const radiosLongWP = [
    {
      id: 'defaultLongWP',
      label: t('components.component_support_message.inline_buttons_label.default_radio'),
    },
    {
      id: 'personalizedLongWP',
      label: t('components.component_support_message.inline_buttons_label.custom_radio'),
    },
  ];

  const radiosShortDeleteWP = [
    {
      id: 'defaultShortDeleteWP',
      label: t('components.component_support_message.inline_buttons_label.default_radio'),
    },
    {
      id: 'personalizedShortDeleteWP',
      label: t('components.component_support_message.inline_buttons_label.custom_radio'),
    },
  ];

  const onChangeShortTextFM = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextShortFM(e.target.value);
  };

  const onChangeLongTextFM = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextLongFM(e.target.value);
  };

  const onChangeShortTextWP = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextShortWP(e.target.value);
  };

  const onChangeLongTextWP = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextLongWP(e.target.value);
  };

  const onChangeShortTextDeleteWP = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setTextShortDeleteWP(e.target.value);
  };

  const onChangeRadioShortFM = (optionId: string) => {
    setRadioIdShortFM(optionId);
    if (optionId === 'defaultShortFM') {
      setTextShortFM(messages?.fullyManaged?.shortSupportMessage?.textDefault || '');
    }
  };

  const onChangeRadioLongFM = (optionId: string) => {
    setRadioIdLongFM(optionId);
    if (optionId === 'defaultLongFM') {
      setTextLongFM(messages?.fullyManaged?.longSupportMessage?.textDefault || '');
    }
  };

  const onChangeRadioShortWP = (optionId: string) => {
    setRadioIdShortWP(optionId);
    if (optionId === 'defaultShortWP') {
      setTextShortWP(messages?.workProfile?.shortSupportMessage?.textDefault || '');
    }
  };

  const onChangeRadioLongWP = (optionId: string) => {
    setRadioIdLongWP(optionId);
    if (optionId === 'defaultLongWP') {
      setTextLongWP(messages?.workProfile?.longSupportMessage?.textDefault || '');
    }
  };

  const onChangeRadioShortDeleteWP = (optionId: string) => {
    setRadioIdShortDeleteWP(optionId);
    if (optionId === 'defaultShortDeleteWP') {
      setTextShortDeleteWP(messages?.workProfile?.wipeSupportMessage?.textDefault || '');
    }
  };

  const saveSupportConfig = () => {
    const body = {
      fullyManaged: {
        shortSupportMessage: {
          useDefault: radioIdShortFM === 'defaultShortFM',
          text: textShortFM,
        },
        longSupportMessage: {
          useDefault: radioIdLongFM === 'defaultLongFM',
          text: textLongFM,
        },
      },
      workProfile: {
        shortSupportMessage: {
          useDefault: radioIdShortWP === 'defaultShortWP',
          text: textShortWP,
        },
        longSupportMessage: {
          useDefault: radioIdLongWP === 'defaultLongWP',
          text: textLongWP,
        },
        wipeSupportMessage: {
          useDefault: radioIdShortDeleteWP === 'defaultShortDeleteWP',
          text: textShortDeleteWP,
        },
      },
    };
    servicePulsusWeb.setSupportMessages(body);
  };

  async function getSupportConfig() {
    const data: GetSupportMessagesProps = await servicePulsusWeb.getSupportMessages();

    if (data && Object.keys(data).length > 0) {
      const fmShortDefault = !data.fullyManaged?.shortSupportMessage.useDefault ? 'personalizedShortFM' : 'defaultShortFM';
      const fmShortText =
        fmShortDefault === 'defaultShortFM' ? data.fullyManaged.shortSupportMessage.textDefault : data.fullyManaged?.shortSupportMessage.text;
      const fmLongDefault = !data.fullyManaged?.longSupportMessage.useDefault ? 'personalizedLongFM' : 'defaultLongFM';
      const fmLongText =
        fmLongDefault === 'defaultLongFM' ? data.fullyManaged?.longSupportMessage.textDefault : data.fullyManaged?.longSupportMessage.text;
      setRadioIdShortFM(fmShortDefault);
      setTextShortFM(fmShortText);
      setRadioIdLongFM(fmLongDefault);
      setTextLongFM(fmLongText);

      const wpShortDefault = !data.workProfile?.shortSupportMessage.useDefault ? 'personalizedShortWP' : 'defaultShortWP';
      const wpShortText =
        wpShortDefault === 'defaultShortWP' ? data.workProfile?.shortSupportMessage.textDefault : data.workProfile?.shortSupportMessage.text;
      const wpLongDefault = !data.workProfile?.longSupportMessage.useDefault ? 'personalizedLongWP' : 'defaultLongWP';
      const wpLongText =
        wpLongDefault === 'defaultLongWP' ? data.workProfile?.longSupportMessage.textDefault : data.workProfile?.longSupportMessage.text;
      const wpWipeDefault = !data.workProfile?.wipeSupportMessage.useDefault ? 'personalizedShortDeleteWP' : 'defaultShortDeleteWP';
      const wpWipeText =
        wpWipeDefault === 'defaultShortDeleteWP' ? data.workProfile?.wipeSupportMessage.textDefault : data.workProfile?.wipeSupportMessage.text;
      setRadioIdShortWP(wpShortDefault);
      setTextShortWP(wpShortText);
      setRadioIdLongWP(wpLongDefault);
      setTextLongWP(wpLongText);
      setRadioIdShortDeleteWP(wpWipeDefault);
      setTextShortDeleteWP(wpWipeText);
      setMessages(data);
    }
  }

  useEffect(() => {
    getSupportConfig();
  }, []);

  useEffect(() => {
    if (saveConfig) {
      saveSupportConfig();
    }
  }, [saveConfig]);

  return (
    <ComponentResourceChecker identifier={ConstantResource.ANDROID_ENTERPRISE.CUSTOM_MESSAGE}>
      <style.Content data-testid="support-box-container">
        <style.Line />
        <style.Top data-testid="support-box-header">
          <h3>{t('components.component_support_message.text.title')}</h3>
          <p>{t('components.component_support_message.text.header_description')}</p>
        </style.Top>
        <AdvancedFlag
          flags={[
            { managementType: 'FM', icon: mdiAndroid, version: '7.0', OS: 'Android' },
            { managementType: 'WP', icon: mdiAndroid, version: '7.0', OS: 'Android' },
            { managementType: 'FM + WP', icon: mdiAndroid, version: '7.0', OS: 'Android' },
          ]}
        />
        <style.ColumnsContainer data-testid="support-box-content">
          <style.Column>
            <div className="icon-container">
              <div className="icon">
                <PhoneAndroidIcon fontSize="small" style={{ color: ConstantColors.AUSTRIA }} />
              </div>
              <p>Fully Managed Device</p>
            </div>
            <p>{t('components.component_support_message.text.fully_description')}</p>
            <style.MessageContainer>
              <style.RadioGroup
                data-testid="support-fm-short-message-radio"
                options={radiosShortFM}
                disabled={!isEditable}
                idSelected={radioIdShortFM}
                onChange={(id) => onChangeRadioShortFM(id)}
                name="radio Short FM"
              />
              <h5>{t('components.component_support_message.text.fully_short_message_label')}</h5>
              <style.TextArea
                data-testid="support-fm-short-message-textarea"
                placeholder={t('components.component_support_message.text.fully_short_message_placeholder')}
                aria-label="Mensagem curta fully managed device"
                value={textShortFM}
                onChange={(e) => onChangeShortTextFM(e)}
                resize="vertical"
                compressed
                disabled={radioIdShortFM === 'defaultShortFM' || !isEditable}
                maxLength={200}
              />
              <p>{t('components.component_support_message.text.message_max_length')}</p>
            </style.MessageContainer>
            <style.MessageContainer isLong>
              <style.RadioGroup
                data-testid="support-fm-long-message-radio"
                options={radiosLongFM}
                idSelected={radioIdLongFM}
                disabled={!isEditable}
                onChange={(id) => onChangeRadioLongFM(id)}
                name="radio Long FM"
              />
              <h5>{t('components.component_support_message.text.fully_long_message_label')}</h5>
              <style.TextArea
                data-testid="support-fm-long-message-textarea"
                placeholder={t('components.component_support_message.text.fully_long_message_placeholder')}
                aria-label="Mensagem curta fully managed device"
                value={textLongFM}
                onChange={(e) => onChangeLongTextFM(e)}
                resize="vertical"
                disabled={radioIdLongFM === 'defaultLongFM' || !isEditable}
              />
            </style.MessageContainer>
          </style.Column>
          <style.Column>
            <div className="icon-container">
              <WorkOutlinedIcon style={{ color: ConstantColors.FRANCE }} />
              <p>Work Profile</p>
            </div>
            <p>{t('components.component_support_message.text.work_profile_description')}</p>
            <style.MessageContainer>
              <style.RadioGroup
                data-testid="support-wp-short-message-radio"
                options={radiosShortWP}
                idSelected={radioIdShortWP}
                disabled={!isEditable}
                onChange={(id) => onChangeRadioShortWP(id)}
                name="radio Short WP"
              />
              <h5>{t('components.component_support_message.text.work_profile_short_message_label')}</h5>
              <style.TextArea
                data-testid="support-wp-short-message-textarea"
                placeholder={t('components.component_support_message.text.work_profile_short_message_placeholder')}
                aria-label="Mensagem curta Work Profile"
                value={textShortWP}
                onChange={(e) => onChangeShortTextWP(e)}
                resize="vertical"
                compressed
                disabled={radioIdShortWP === 'defaultShortWP' || !isEditable}
                maxLength={200}
              />
              <p>{t('components.component_support_message.text.message_max_length')}</p>
            </style.MessageContainer>
            <style.MessageContainer isLong>
              <style.RadioGroup
                data-testid="support-wp-long-message-radio"
                options={radiosLongWP}
                idSelected={radioIdLongWP}
                disabled={!isEditable}
                onChange={(id) => onChangeRadioLongWP(id)}
                name="radio Long WP"
              />
              <h5>{t('components.component_support_message.text.work_profile_long_message_label')}</h5>
              <style.TextArea
                data-testid="support-wp-long-message-textarea"
                placeholder={t('components.component_support_message.text.work_profile_long_message_placeholder')}
                aria-label="Mensagem curta Work Profile"
                value={textLongWP}
                onChange={(e) => onChangeLongTextWP(e)}
                resize="vertical"
                disabled={radioIdLongWP === 'defaultLongWP' || !isEditable}
              />
            </style.MessageContainer>
            <style.MessageContainer>
              <style.RadioGroup
                data-testid="support-wp-short-deleted-message-radio"
                options={radiosShortDeleteWP}
                idSelected={radioIdShortDeleteWP}
                disabled={!isEditable}
                onChange={(id) => onChangeRadioShortDeleteWP(id)}
                name="radio Short Delete WP"
              />
              <AdvancedFlag flags={[{ managementType: 'WP', icon: mdiAndroid, version: '9.0', OS: 'Android' }]} />
              <h5>{t('components.component_support_message.text.work_profile_wipe_message_label')}</h5>
              <style.TextArea
                data-testid="support-wp-short-deleted-message-textarea"
                placeholder={t('components.component_support_message.text.work_profile_wipe_message_placeholder')}
                aria-label="Mensagem curta Work Profile"
                value={textShortDeleteWP}
                onChange={(e) => onChangeShortTextDeleteWP(e)}
                resize="vertical"
                compressed
                disabled={radioIdShortDeleteWP === 'defaultShortDeleteWP' || !isEditable}
                maxLength={200}
              />
              <p>{t('components.component_support_message.text.message_max_length')}</p>
            </style.MessageContainer>
          </style.Column>
        </style.ColumnsContainer>
      </style.Content>
    </ComponentResourceChecker>
  );
}
