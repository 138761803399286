import styled from 'styled-components';
import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import { Tabs } from '@mui/material';
import { ConstantColors } from 'utilities';

export const Left = styled.div`
  width: 45%;
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.main};
`;

export const Right = styled.div`
  width: 55%;

  .euiTitle {
    font-weight: 700 !important;
  }

  #chromeOS-integration-tutorial {
    .euiTitle {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #343741;
    }
    .euiTextColor {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #343741;
    }
    .euiStep__content {
      padding: 0px 0px 12px 30px;
    }
    .link {
      color: #30a8ff !important;
      font-weight: 700;
      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }
    .euiStepNumber {
      font-size: 16px;
      background-color: ${({ theme }) => theme.main};
    }
  }
`;

export const Actions = styled.div`
  position: absolute;
  margin: 40px;
  bottom: 0;
  right: 0;
  margin: 40px;

  display: flex;
  gap: 20px;
`;

export const ContinueButton = styled(EuiButton)`
  width: 200px;
  background-color: ${({ theme }) => theme.main} !important;
  border: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
`;

export const CancelButton = styled(EuiButtonEmpty)`
  font-weight: 700;
  font-size: 14px;
  line-height: 35px;
  color: #30a8ff;
`;

export const EnrollContainer = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const ModalTitle = styled.p`
  font-weight: 700;
  font-size: 25.7391px;
  line-height: 28px;
  text-align: center;
  color: ${({ theme }) => theme.main};
`;

export const Content = styled.div`
  display: flex;

  .euiTitle {
    font-weight: 700 !important;
  }
`;

export const CustomTabs = styled(Tabs)`
  border-bottom: thin solid ${ConstantColors.BRUNEI};
  .MuiTab-textColorPrimary {
    text-transform: none !important;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    color: #343741;
    font-family: inherit;
  }
  .Mui-selected {
    color: ${({ theme }) => theme.main} !important;
  }

  .MuiTabs-indicator {
    background: ${({ theme }) => theme.main} !important;
  }
`;

export const EnrollLeft = styled.div`
  width: 52%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  #chromeOS-enroll-tutorial {
    .euiTitle {
      display: flex;
      align-items: center;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: #343741;
    }
    .euiTextColor {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #343741;
    }
    .euiStep__content {
      padding: 0px 0px 12px 30px;
    }
    .bold {
      color: #343741 !important;
      font-weight: 700;
      text-decoration: none !important;
      cursor: text;
      :hover {
        text-decoration: underline;
      }
    }
    .link {
      color: #30a8ff !important;
      font-weight: 700;
      font-size: 14px;

      cursor: pointer;
      :hover {
        text-decoration: underline;
      }
    }

    .euiStepNumber {
      font-size: 16px;
      background-color: ${({ theme }) => theme.main};
    }
  }
`;

export const EnrollRight = styled.div`
  width: 48%;
  display: flex;
  justify-content: end;
`;

export const BannerBackground = styled.div`
  background: ${({ theme }) => theme.main};
  width: 394px;
  border-radius: 30px 30px 0px 0px;
  height: 410px;
  overflow: hidden !important;
`;

export const Banner = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: flex-end;
  @keyframes RightSlideIn {
    0% {
      transform: translateX(400px);
    }
    100% {
      transform: translateX(0px);
    }
  }

  p {
    position: absolute;
    right: 55px;
    bottom: 255px;
    display: flex;
    align-items: center;
    width: 280px;
    height: 35px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: #181818;
  }

  .bannerSVG,
  p {
    animation-name: RightSlideIn;
    animation-duration: 0.5s;
    animation-timing-function: linear;
  }
`;

export const Breadcrumb = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 40px 40px 0px;

  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  gap: 10px;
  .euiTextColor--default {
    color: #69707d !important;
  }
`;

export const LinkButton = styled(EuiButtonEmpty)`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #69707d;
  span {
    padding: 0 !important;
  }
`;

export const ZeroTouchWarningBox = styled.div`
  background: #fff9e8;
  padding: 7px;
  margin-bottom: 15px;

  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  .euiTextColor {
    color: #8a6a0a !important;
  }
  .bold {
    font-weight: 700;
  }
`;
