import { EuiModal } from '@elastic/eui';
import styled from 'styled-components';

export const Modal = styled(EuiModal)`
  /* margin-top: 50px; */
  margin-bottom: 23vh; //with iframer
  .euiModal__flex {
    max-height: fit-content;
    height: 560px;
    width: 851px;
  }

  .euiStep__content {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding: 10px 32px 25px;
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
`;
export const BodyModal = styled.main`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

export const AnimationContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-left: auto;
  margin-right: auto;

  @media (max-height: 900px) {
    width: 565px;
  }
`;

export const Title = styled.h1`
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 40px;
  color: ${({ theme }) => theme.main};
  margin-bottom: 18px;
`;

export const Text = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 10.217px;
  line-height: 12px;
  text-align: center;
  color: #67758d;
  margin-bottom: 18px;
`;

export const FooterModal = styled.footer`
  display: flex;
  width: 554px;
  justify-content: space-between;
  align-items: center;
`;
