type GraphDarkProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconGraphDark({ width = 18, height = 18, color = '#000', test_id = 'icons__graph-dark' }: GraphDarkProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16 0H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2ZM6 14H4V7h2v7Zm4 0H8V4h2v10Zm4 0h-2v-4h2v4Z" fill={color} />
    </svg>
  );
}
