type IconProps = {
  size?: number | string;
  isSquare?: boolean;
};

export function AppStoreIcon({ size, isSquare }: IconProps) {
  return (
    <svg style={{ zoom: size || 1 }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="30" height="30" rx={isSquare ? '5' : '15'} fill="#ECECEC" />
      <path
        d="M15.011 14.4805L9.51367 20.9158C9.71363 21.5963 10.3489 22.0737 11.059 22.0737C11.3681 22.0737 11.6387 21.9964 11.8705 21.8419L18.6323 17.9797L15.011 14.4805Z"
        fill="#EA4335"
      />
      <path
        d="M21.4892 13.5752L18.591 11.9136L15.5 14.966L18.6297 17.98L21.5277 16.3571C22.0301 16.0866 22.3779 15.5457 22.3779 14.966C22.3391 14.3864 21.9916 13.8455 21.4892 13.5752Z"
        fill="#FBBC04"
      />
      <path
        d="M14.9977 15.4676L18.5938 11.913L11.9092 8.12633C11.6774 7.9718 11.3681 7.89453 11.059 7.89453C10.3251 7.89453 9.66821 8.39691 9.51367 9.05382L14.9977 15.4676Z"
        fill="#34A853"
      />
      <path
        d="M9.5131 9.0542C9.47461 9.16996 9.47461 9.32477 9.47461 9.47903V20.4914C9.47461 20.6457 9.47461 20.7617 9.51338 20.9162L15.5028 14.9658L9.5131 9.0542Z"
        fill="#4285F4"
      />
    </svg>
  );
}
