import { GenericMap } from 'components';
import SignalsMapLayers from 'pages/pulsus-insights-signals/signals-map-layers';
import * as style from '../../pages/pulsus-insights-signals/page-insights-signals.style';
import type { ComponentSignalsProps } from './component-pulsus-insights-signals.contracts';

export function ComponentPulsusInsightsSignalsContent({ mapHeight, paddingLeft }: ComponentSignalsProps) {
  return (
    <style.Container data-testid="page__insights-signal" style={{ paddingLeft: paddingLeft || '9px' }}>
      <GenericMap attributionControl={false} mapHeight={mapHeight} minZoom={5} maxZoom={16} zooms={[5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]}>
        <SignalsMapLayers />
      </GenericMap>
    </style.Container>
  );
}
