import React from 'react';

export function IconEmployeeUsers({ fill = '#989898', width = '8', height = '8' }) {
  return (
    <svg width={width} height={height} viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 4C5.105 4 6 3.105 6 2C6 0.895 5.105 0 4 0C2.895 0 2 0.895 2 2C2 3.105 2.895 4 4 4ZM4 5C2.665 5 0 5.67 0 7V8H8V7C8 5.67 5.335 5 4 5Z"
        fill={fill}
      />
    </svg>
  );
}
