export type ThemeProps = {
  changeTheme: (color: string) => void;
  changeDashboardTheme: (theme: 'light' | 'dark') => void;
};

export const AVAILABLE_OPTIONAL_THEMES = {
  PULSUS: '#F15A22',
  OCEAN: '#004691',
  DEEP_OCEAN: '#001A45',
  SPACE: '#525A69',
  ROYAL_PURPLE: '#609',
  MATERIAL_BLUE: '#0D47A1',
  MATERIAL_BROWN: '#3E2723',
  MATERIAL_GRAY: '#212121',
  MATERIAL_GREEN: '#1B5E20',
  MATERIAL_RED: '#B71C1C',
} as const;

export type AvailableOptionalThemes = keyof typeof AVAILABLE_OPTIONAL_THEMES;
