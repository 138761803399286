import styled, { css } from 'styled-components';
import type { MenuTabStyleProps } from './component-menu-tab.contracts';

export const Container = styled.div<MenuTabStyleProps>`
  display: flex;
  width: 86px;
  height: 86px;
  ${({ isSelected }) => css`
    background: ${isSelected ? '#ebebeb' : '#ffffff'};
  `}
  border: 1px solid #c8c8c8;
  border-radius: 6px;
  cursor: pointer;
  padding: 20px;

  @keyframes slideup {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-3px);
    }
  }
  @keyframes slidedown {
    0% {
      transform: translateY(-3px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  :hover {
    transform: translateY(-3px);
    animation-duration: 0.15s;
    animation-name: slideup;
    animation-timing-function: linear;
    .text {
      text-decoration: underline;
    }
  }
  transform: translateY(0px);
  animation-duration: 0.15s;
  animation-name: slidedown;
  animation-timing-function: linear;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  width: 100%;
  gap: 4px;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 11px;
  line-height: 16px;
  color: #525252;
`;

export const IconBox = styled.div``;
