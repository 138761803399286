import { EuiOverlayMask } from '@elastic/eui';
import * as style from './modal.style';
import type { ModalProps } from './modal.contracts';

export function ComponentModal({ onClose, children, width, height, radius }: ModalProps) {
  const handleOnClose = () => {
    onClose();
  };

  return (
    <EuiOverlayMask>
      <style.Modal
        onClose={handleOnClose}
        style={{ width: `${width || '500px'}`, height: `${height || '492px'}`, borderRadius: `${radius || '24px'}` }}
      >
        {children}
      </style.Modal>
    </EuiOverlayMask>
  );
}
