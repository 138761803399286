import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  padding: 24px;
  transition: all ease 0.2s;
  background: ${({ theme }) => theme.dashboardTheme.paper};
  border-radius: 4px;
  display: flex;
  gap: 24px;

  &.first-row {
    max-height: 300px;
  }
`;
