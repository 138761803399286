import styled, { css } from 'styled-components';
import { EuiButton, EuiRadioGroup, EuiTabs } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Container = styled.div`
  display: block;
  padding: 20px 50px 0 40px;
  width: 100%;
  max-width: 1200px;
`;

export const BodyTop = styled.div`
  background-color: #ffff;
  width: 100%;
  .euiSwitch.euiSwitch--compressed .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb,
  .euiSwitch.euiSwitch--mini .euiSwitch__button[aria-checked='true'] .euiSwitch__thumb {
    border-color: transparent;
  }
`;

export const HeadContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const TabsContainer = styled.nav`
  width: 100%;
  margin-top: 30px;
  display: flex;

  .certificates-list-popover {
    margin-right: 0;
    margin-left: auto;
  }

  .euiFormControlLayout {
    margin-right: 0px;
    margin-left: auto;
  }

  .euiFieldSearch {
    border-radius: 6px;
  }
`;

export const TabsSelector = styled(EuiTabs)`
  button.euiTab {
    background-color: transparent !important;

    span.euiTab__content {
      font-style: normal !important;
      font-weight: 500 !important;
      font-size: 14px !important;
      line-height: 24px !important;
    }
  }

  button.euiTab.euiTab-isSelected {
    color: ${({ theme }) => theme.main} !important;
    box-shadow: inset 0 -2px 0 ${({ theme }) => theme.main};

    span.euiTab__content {
      color: ${({ theme }) => theme.main} !important;
    }
  }

  span.euiTab__content {
    color: ${ConstantColors.COLOMBIA} !important;
  }
`;

export const LeftContainer = styled.div`
  padding-top: 10px;

  .euiCallOutHeader__title {
    font-style: normal;
    font-weight: 500;
    font-size: 12.8125px;
    line-height: 21px;
  }
`;

export const Title = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 40px;

  h1 {
    font-weight: 500;
    font-size: 40px;
    color: ${({ theme }) => theme.main};
    line-height: 21px;
  }
`;

export const SecondaryText = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 15.1636px;
  line-height: 18px;
  max-width: 750px;
  min-width: 50%;
  color: #67758d;
  margin-bottom: 20px;
`;

export const AlertText = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12.8125px;
  line-height: 21px;
  color: #8a6a0a;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AlertLink = styled.a`
  border: 1px solid ${ConstantColors.ENGLAND};
  cursor: pointer;
`;

export const LockSetting = styled.section`
  padding: 24px 18px;
  background: #f9f9f9;
  border-radius: 11px;
  width: 600px;

  span {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 15px;
  }

  h4 {
    font-weight: 700;
    font-size: 14px;
    line-height: 30px;
    color: #1a1c21;
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #67758d;
  }
`;

export const LockRadios = styled(EuiRadioGroup)`
  ${({ theme }) => css`
    display: flex;
    gap: 5px;
    .euiRadioGroup__item {
      margin: 0;
    }

    .euiRadio .euiRadio__input:checked + .euiRadio__circle {
      border-color: ${theme.main};
      background-color: ${theme.main};
    }

    .euiRadio .euiRadio__input:checked[disabled] + .euiRadio__circle {
      border-color: #98a2b3;
      background-color: #98a2b3;
    }

    &:focus {
      outline-color: ${theme.main};
    }
  `}
`;

export const RightContainer = styled.div`
  .buttonGeofence.true {
    @keyframes shake {
      10%,
      90% {
        -webkit-transform: translate3d(-1px, 0, 0);
        transform: translate3d(-1px, 0, 0);
        filter: brightness(0.9);
        transition: 0.5s;
      }
      20%,
      80% {
        -webkit-transform: translate3d(2px, 0, 0);
        transform: translate3d(2px, 0, 0);
      }
      30%,
      50%,
      70% {
        -webkit-transform: translate3d(-4px, 0, 0);
        transform: translate3d(-4px, 0, 0);
      }
      40%,
      60% {
        -webkit-transform: translate3d(4px, 0, 0);
        transform: translate3d(4px, 0, 0);
      }
    }
    -webkit-animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    animation: shake 0.8s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
`;

export const CsvUploadButton = styled(EuiButton)`
  color: ${ConstantColors.AUSTRIA};
  background-color: #30a8ff;
  height: 35px;
  border: none;
  width: 210px;

  &:hover {
    background-color: #30baff !important;
  }

  &:focus {
    background-color: #30baff !important;
  }
`;

export const Body = styled.div`
  margin-top: 50px;

  .euiPagination {
    justify-content: flex-end;
    margin-top: 15px;
  }

  .euiTableCellContent__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 118.02%;
    color: #67758d;
  }
`;

export const RadiusContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const RadiusDot = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  border: 1px solid white;
  background-color: #30baff;
`;

export const RadiusFlag = styled.p`
  min-width: 40px;
  max-height: 30px;
  border-radius: 5px;
  color: white;
  background-color: #30baff;
  padding: 5px 2px;
  text-align: center;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
`;

export const CoordinatesColumn = styled.div`
  font-style: italic;
  font-weight: 700;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;

  p {
    font-size: 10px !important;
  }
  span {
    font-weight: normal;
  }
`;

export const ActionsColumn = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  align-item: center;
`;

export const SquareNumberActions = styled.div`
  height: 20px;
  min-width: 20px;
  background-color: #e0e5ee;
  border-radius: 3px;
  align-items: center;
  padding: 2px 6px;
`;

export const DescriptionText = styled.p`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  color: #4a4a4a;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const FencesName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #444444;
`;
