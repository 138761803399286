import React from 'react';

type IconAirplaneModeInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconAirplaneModeInCicle({ width, height, elipse_fill, icon_fill }: IconAirplaneModeInCicleProps) {
  return (
    <svg width={width || 44} height={height || 45} viewBox="0 0 44 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <ellipse cx="22.365" cy="22.5" rx="21.6783" ry="22.5" fill={elipse_fill || '#30A8FF'} />
      <path
        d="M31.518 26V24L23.3285 19V13.5C23.3285 12.67 22.6829 12 21.8833 12C21.0836 12 20.438 12.67 20.438 13.5V19L12.2485 24V26L20.438 23.5V29L18.0293 30.5V32L21.8833 31L25.7372 32V30.5L23.3285 29V23.5L31.518 26Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
