import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const ModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;

  margin: 35px 25px;
  text-align: center;
  color: ${ConstantColors.SPAIN};
`;

export const ModalHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ModalTitle = styled.h2`
  font-weight: 500;
  font-size: 1rem;
  line-height: 15px;
  text-align: center;
  color: ${({ theme }) => theme.main};
`;

export const ModalMain = styled.main`
  display: flex;
  justify-content: space-evenly;
  gap: 15px;
`;

export const ModalBoxLink = styled.a`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  gap: 11px;

  width: 110px;
  height: 110px;

  border-radius: 15px;
  border: 1px solid ${ConstantColors.ENGLAND};
  cursor: pointer;
  color: ${ConstantColors.SPAIN};
  font-weight: 600;
  font-size: 12px;

  h4 {
    font-size: 12px;
    font-weight: 600;
  }
`;

export const ModalText = styled.p`
  width: 100px;
  margin-top: 18px;
  margin-left: 10px;
  text-align: left;
  line-height: normal;
  font-size: 11px;
  color: ${ConstantColors.FINLAND};
`;
