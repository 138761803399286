import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EuiListGroup, EuiSideNav } from '@elastic/eui';
import { ConstantColors } from 'utilities';
import { useTranslation } from 'react-i18next';
import { NavList } from './nav-list/nav-list';
import * as style from './component-application-main.style';

export function ComponentApplicationMain({ children }) {
  const { t } = useTranslation();

  const [loading_sizes, set_loading_sizes] = useState(true);
  const [nav_bar_size, set_nav_bar_size] = useState(20);
  const [content_size, set_content_size] = useState(80);

  const handleResize = (event) => {
    const nav_bar_width = (245 / event.target.innerWidth) * 100;
    const content_width = 100 - nav_bar_width;

    set_nav_bar_size(nav_bar_width);
    set_content_size(content_width);
    set_loading_sizes(false);
  };

  useEffect(() => {
    const nav_bar_width = (245 / window.innerWidth) * 100;
    const content_width = 100 - nav_bar_width;

    set_nav_bar_size(nav_bar_width);
    set_content_size(content_width);
    set_loading_sizes(false);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <style.Container>
      {!loading_sizes && (
        <style.Boxed>
          <style.ResizableContainer data-testid="component-application-main">
            {(EuiResizablePanel, EuiResizableButton) => (
              <>
                <EuiResizablePanel style={{ minWidth: 215 }} id="nav_bar" mode="collapsible" initialSize={nav_bar_size} minSize="245px">
                  <EuiListGroup flush>
                    <style.ResizableContainerPanel>
                      <EuiSideNav isOpenOnMobile items={NavList(t)} />
                    </style.ResizableContainerPanel>
                  </EuiListGroup>
                </EuiResizablePanel>
                <EuiResizableButton
                  style={{
                    borderLeft: `1px solid ${ConstantColors.PORTUGAL}`,
                    borderRight: `1px solid ${ConstantColors.PORTUGAL}`,
                    background: ConstantColors.BRAZIL,
                  }}
                />
                <EuiResizablePanel id="content" mode="main" initialSize={content_size} minSize="245px">
                  <style.ResizableContainerPanel>
                    <style.ContentContainer>{children}</style.ContentContainer>
                  </style.ResizableContainerPanel>
                </EuiResizablePanel>
              </>
            )}
          </style.ResizableContainer>
        </style.Boxed>
      )}
    </style.Container>
  );
}

ComponentApplicationMain.propTypes = {
  children: PropTypes.node,
};

ComponentApplicationMain.defaultProps = {
  children: '',
};
