import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';

import { InputLabel } from '@mui/material';

import { Visibility, VisibilityOff } from '@mui/icons-material';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  height: 90vh;

  .searchWifi .MuiInputBase-root:before {
    border-bottom: 1px solid rgb(206, 199, 209);
  }

  searchWifi {
    margin-top: -18px;
  }
`;

export const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  h4 {
    font-weight: 400;
  }
`;

export const Input = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const Switchers = styled.div`
  display: flex;
  justify-content: space-between;

  & button {
    font-weight: bold;
    align-self: flex-end;
  }
`;

export const WifiList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 15vh;
  overflow: auto;
  margin-top: -18px;
  scrollbar-width: thin;
`;

export const WifiItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #cec7d1;
`;

export const WifitItemOption = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

export const MessageListEmpty = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  height: 16vh;
  color: grey;
`;

export const LabelStyled = styled(InputLabel)`
  font-size: 10px;
  color: #282c31 !important;
`;

export const Title = styled.h4`
  color: #000000;
`;

export const labelSsid = styled.span`
  font-style: 12px;
  font-weight: 400;
  color: #282c31;

  span {
    color: #f44336;
  }
`;

export const AddTitle = styled.span`
  font-style: 14px;
`;

export const VisibilitySt = styled(Visibility)`
  color: #000000;
`;

export const VisibilityStOff = styled(VisibilityOff)``;

export const SearchIconSt = styled(SearchIcon)`
  color: #000000;
`;

export const WifiItemName = styled.span`
  color: #000000;
  font-style: 13px;
`;
