import React from 'react';

type IconScreenshotInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconScreenshotInCicle({ width, height, elipse_fill, icon_fill }: IconScreenshotInCicleProps) {
  return (
    <svg width={width || 45} height={height || 45} viewBox="0 0 45 45" fill="white" xmlns="http://www.w3.org/2000/svg">
      <circle cx="22.5" cy="22.5" r="22.5" fill={elipse_fill || '#C92F2F'} />
      <path
        d="M26.4286 14.0082L18.5714 14C17.7071 14 17 14.7364 17 15.6364V30.3636C17 31.2636 17.7071 32 18.5714 32H26.4286C27.2929 32 28 31.2636 28 30.3636V15.6364C28 14.7364 27.2929 14.0082 26.4286 14.0082ZM26.4286 30.3636H18.5714V29.5455H26.4286V30.3636ZM26.4286 27.9091H18.5714V18.0909H26.4286V27.9091ZM26.4286 16.4545H18.5714V15.6364H26.4286V16.4545ZM20.5357 20.1364H22.5V18.9091H19.3571V22.1818H20.5357V20.1364ZM22.5 27.0909H25.6429V23.8182H24.4643V25.8636H22.5V27.0909Z"
        fill={icon_fill || 'white'}
      />
    </svg>
  );
}
