import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import type { StoreProps } from 'stores/stores.contracts';
import _ from 'lodash';
import * as style from './certificates-cards-settings.style';
import { CertificatesCardsGroup } from './certificates-cards-group';

export const CertificatesCardsSettings = () => {
  const dispatch = useDispatch();
  const policy = useSelector((store: StoreProps) => store?.android_settings?.policy);
  const [initial_policy, set_initial_policy] = useState(false);
  const [credentials_config_disabled, set_credentials_config_disabled] = useState(false);

  function handleChange(value: boolean, type: string) {
    switch (type) {
      case 'credentials_config':
        set_credentials_config_disabled(value);
        dispatch({ type: 'SET_POLICY_CHANGES', payload: { credentialsConfigDisabled: value } });
        break;

      default:
        break;
    }
  }

  useEffect(() => {
    if (policy && Object.keys(policy).length > 0 && !_.isEqual(policy, initial_policy)) {
      set_credentials_config_disabled(policy.credentialsConfigDisabled);
      set_initial_policy(policy);
    }
  }, [policy]);

  return (
    <>
      <style.Container>
        <style.Header>
          <style.Title>Certificados</style.Title>
        </style.Header>
      </style.Container>
      <style.Container>
        <CertificatesCardsGroup
          credentials_config_disabled={credentials_config_disabled}
          onChange={(value: boolean, type: string) => handleChange(value, type)}
        />
      </style.Container>
    </>
  );
};
