import styled from 'styled-components';

export const Container = styled.div`
  min-width: 300px;
  display: flex;
  flex-direction: column;
`;

export const InfoWindowLine = styled.div`
  display: flex;
  margin-bottom: 10px;
  font-size: 14px;
  padding-left: 20px;

  p,
  span {
    margin-bottom: 3px;
    font-size: 13px;
  }

  .info-title {
    font-weight: 500;
    margin-right: 10px;
    font-size: 11px;
    text-transform: uppercase;
    min-width: 75px;
    text-align: right;
  }

  .secondary-info {
    color: #707070;
  }

  .model-info {
    display: flex;
    align-items: center;

    svg {
      margin-bottom: 5px;
      margin-right: 3px;
    }
  }

  span {
    margin-right: 5px;
  }
`;
