/* eslint-disable react/jsx-one-expression-per-line */
import { DialogComponent } from 'components';
import type { SendEmailsModalComponentProps } from './SendEmailsModalComponent.contracts';
import * as S from './SendEmailsModalComponent.styles';
import type { EuiComboBoxOptionOption } from '@elastic/eui';
import { EuiButton, EuiButtonEmpty, EuiComboBox } from '@elastic/eui';
import { useTypedTranslation } from 'modules/core/hooks';
import { useGeofenceContext } from 'modules/management/contexts/GeofenceContext';
import type { AdminEmail } from 'modules/management/contexts/GeofenceContext/contracts';
import { useEffect, useState } from 'react';
import { ServicePulsusValquiria } from 'services/ServicePulsusValquiria';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';

export const SendEmailsModalComponent = ({ isOpen, close }: SendEmailsModalComponentProps) => {
  const [selectedEmails, setSelectedEmails] = useState<EuiComboBoxOptionOption<AdminEmail>[]>([]);
  const [emailOptions, setEmailOptions] = useState<AdminEmail[]>([]);
  const { fields, setFields, loading } = useGeofenceContext();
  const [toast, setToast] = useState<Toast[]>([]);
  const { t } = useTypedTranslation<'geofence.modal_edit.step2'>('geofence.modal_edit.step2');

  const changeAdminEmails = (emails) => {
    setFields({
      ...fields,
      adminEmails: emails,
    });
  };

  const validateEmail = (emailForm: string): boolean => {
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!regex.test(emailForm) && emailForm !== '') {
      return false;
    }
    return true;
  };

  const onCreateOption = (searchValue: string, flattenedOptions: EuiComboBoxOptionOption<AdminEmail>[] = []) => {
    const normalizedSearchValue = searchValue.trim().toLowerCase();

    if (!normalizedSearchValue) {
      return;
    }

    if (!validateEmail(normalizedSearchValue)) {
      return;
    }

    const newOption: AdminEmail = {
      label: searchValue,
    };

    if (flattenedOptions.findIndex((option) => option.label.trim().toLowerCase() === normalizedSearchValue) === -1) {
      setEmailOptions([...emailOptions, newOption]);
    }

    setSelectedEmails([...selectedEmails, newOption]);
  };

  const getAdminEmails = () => {
    ServicePulsusValquiria.getAdminEmails()
      .then((joinedEmails) => {
        const emails: AdminEmail[] = joinedEmails.includes(',')
          ? joinedEmails.split(',').map((email: string) => ({
              label: email.trim(),
            }))
          : [{ label: joinedEmails.trim() }];

        setEmailOptions(emails);
      })
      .catch(() => {
        setToast([
          {
            title: t('toast.title'),
            color: 'danger',
            iconType: 'faceSad',
            id: '2',
            text: t('toast.text'),
          },
        ]);
      });
  };

  const closeModal = () => {
    changeAdminEmails(selectedEmails);
    close();
  };

  useEffect(() => {
    if (fields.adminEmails) {
      getAdminEmails();
      setSelectedEmails(fields.adminEmails);
    }
  }, [fields]);

  const renderModalsFooter = () => {
    return (
      <S.ModalsFooter>
        <EuiButtonEmpty onClick={close}>{t('close')}</EuiButtonEmpty>
        <EuiButton fill isLoading={loading} onClick={closeModal}>
          {t('save')}
        </EuiButton>
      </S.ModalsFooter>
    );
  };

  return (
    <DialogComponent
      width="550px"
      open={isOpen}
      footer={renderModalsFooter()}
      closeOnClickOutside
      onClose={() => close()}
      containerStyles={{ alignItems: 'flex-start !important', marginTop: '80px' }}
    >
      <S.Content>
        <S.Text>{t('modalTitle')}</S.Text>
        <EuiComboBox
          fullWidth
          placeholder="Add emails"
          options={emailOptions}
          delimiter=","
          selectedOptions={selectedEmails}
          onChange={(emails) => setSelectedEmails(emails)}
          onCreateOption={onCreateOption}
        />
      </S.Content>
      <ComponentToast toasts={toast} toastLifeTimeMs={5000} side="right" dismissToast={() => setToast([])} />
    </DialogComponent>
  );
};
