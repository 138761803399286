import React from 'react';
import { EuiSwitch } from '@elastic/eui';
import { random } from 'lodash';
import * as style from './quick-settings-card.style';
import type { QuickSettingsCardProps } from './security/page-security-settings.contracts';
import { Flags } from './flags';

export const QuickSettingsCard = (props: QuickSettingsCardProps) => {
  const { title, label, active, flags, onChange, children, noSwitch, isContentOnly, bottomContent, noBottomPadding } = props;

  const renderCardContent = () => (
    <style.Card key={random()} noBottomPadding={noBottomPadding} title={title} textAlign="left">
      <style.CardContent>
        {!noSwitch && (
          <style.SwitchContainer>
            <EuiSwitch label={label} checked={active} onChange={() => onChange()} />
          </style.SwitchContainer>
        )}
        <style.CardDescription>{children}</style.CardDescription>
        <Flags flags={flags} />
        {bottomContent && <style.BottomBox>{bottomContent}</style.BottomBox>}
      </style.CardContent>
    </style.Card>
  );

  return isContentOnly ? <>{renderCardContent()}</> : <style.CardContainer className="item">{renderCardContent()}</style.CardContainer>;
};
