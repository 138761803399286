import React from 'react';
import PropTypes from 'prop-types';
import * as style from './nav-item-title-secondary.style';

export function NavItemTitleSecondary(props) {
  const { children } = props;

  return <style.TitleSecondary {...props}>{children}</style.TitleSecondary>;
}

NavItemTitleSecondary.propTypes = {
  children: PropTypes.node,
};

NavItemTitleSecondary.defaultProps = {
  children: '',
};
