import React from 'react';

type IconTheteringInCicleProps = {
  width?: number;
  height?: number;
  elipse_fill?: string;
  icon_fill?: string;
};
export function IconTrashInCicle({ width, height, elipse_fill, icon_fill }: IconTheteringInCicleProps) {
  return (
    <svg width={width || 30} height={height || 30} viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
      <circle cx="15" cy="15" r="15" fill={elipse_fill || '#F3F6FB'} />
      <path
        d="M11.5714 18.8889C11.5714 19.5 12.0857 20 12.7143 20H17.2857C17.9143 20 18.4286 19.5 18.4286 18.8889V12.2222H11.5714V18.8889ZM12.7143 13.3333H17.2857V18.8889H12.7143V13.3333ZM17 10.5556L16.4286 10H13.5714L13 10.5556H11V11.6667H19V10.5556H17Z"
        fill={icon_fill || '#64748F'}
      />
    </svg>
  );
}
