import type { ReactElement } from 'react';
import { useEffect, useMemo, useRef } from 'react';
import { IconPulsusLogo } from 'icons';
import { FenceConfigurationComponent } from 'modules/management/components/Geofence';
import { useTranslation } from 'react-i18next';
import { EuiCallOut, EuiIcon } from '@elastic/eui';
import * as S from './GeofenceConfigurationPage.styles';
import { GeofenceTableComponent } from './GeofenceTable/GeofenceTableComponent';

export const GeofenceConfigurationPage = (): ReactElement => {
  const { t } = useTranslation('translation', { keyPrefix: 'geofence' });

  const memoTable = useMemo(() => <GeofenceTableComponent />, []);

  const ref = useRef<HTMLDivElement>(null);

  let observerHasBeenCreated = false;

  useEffect(() => {
    const observeElement = ref.current;
    if (observeElement && !observerHasBeenCreated) {
      new ResizeObserver((entries: ResizeObserverEntry[]) => {
        entries.forEach((item) => {
          const postMessageHeight = {
            from: 'geofence',
            event: 'RESIZE',
            height: item.contentBoxSize[0].blockSize < 700 ? 750 : item.contentBoxSize[0].blockSize,
          };

          window.parent.postMessage(JSON.stringify(postMessageHeight), process.env.REACT_APP_RUBY_LINK!);
        });
      }).observe(observeElement);
      observerHasBeenCreated = true;
    }
  }, [ref.current]);

  return (
    <S.Container ref={ref}>
      <S.HeaderContainer>
        <S.LeftContainer>
          <S.Title>
            <IconPulsusLogo width={200} height={35} color="#525252" />

            <h1>GEOFENCE</h1>
          </S.Title>
        </S.LeftContainer>
      </S.HeaderContainer>
      <S.Content>
        <EuiCallOut color="warning" iconType="help" style={{ maxWidth: '750px' }}>
          <S.AlertText>
            <EuiIcon type="help" />
            <span>
              {t('embeddedAlert.primaryText')} <b>{t('embeddedAlert.bold')}</b> {t('embeddedAlert.secondText')}
            </span>
          </S.AlertText>
        </EuiCallOut>
        <FenceConfigurationComponent />
        {memoTable}
      </S.Content>
    </S.Container>
  );
};
