import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const CsvUploadButton = styled(EuiButton)`
  color: ${ConstantColors.AUSTRIA};
  background-color: #30a8ff;
  height: 35px;
  border: none;
  width: 210px;

  &:hover {
    background-color: #30baff !important;
  }

  &:focus {
    background-color: #30baff !important;
  }
`;
