import styled from 'styled-components';

import { EuiButton, EuiHeader } from '@elastic/eui';
import { Link } from 'react-router-dom';

export const Header = styled(EuiHeader)`
  background: ${({ theme }) => theme.main};
  display: flex !important;
  justify-content: center !important;
  padding: 0 !important;
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1440px;
  background-color: transparent;
`;

export const TenantInformation = styled.div`
  display: grid;
  grid-template-columns: minmax(135px, 225px) auto;
  grid-template-rows: 100%;
  align-items: center;
  margin-right: 20px;
`;

export const TenantLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 225px;
`;

export const TenantName = styled.span`
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.08px;
  height: 25px;
  margin-left: 20px;
  overflow: hidden;
  color: ${({ theme }) => theme.contrast};
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 535px;
  min-width: 305px;
  height: 100%;
`;

export const EnrollButton = styled(EuiButton)`
  width: 180px;
  height: 30px;
  background: #5e5e5e !important;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  color: white !important;
`;

export const DialogContainer = styled.div`
  height: 100%;
`;
