export class DeviceHistory {
  public id: number;
  public latitude: number;
  public longitude: number;
  public lastLocation: string;
  public source: 'fused' | 'gps' | 'network' | 'unknown' | 'title';
  public precision: number;

  constructor(data: DeviceHistory.Server) {
    this.id = data.id;
    this.latitude = data.latitude;
    this.longitude = data.longitude;
    this.lastLocation = data.last_location;
    this.source = data.provider;
    this.precision = data.accuracy;
  }
}

export namespace DeviceHistory {
  export type Server = {
    id: number;
    latitude: number;
    longitude: number;
    last_location: string;
    provider: 'fused' | 'gps' | 'network' | 'unknown' | 'title';
    accuracy: number;
  };
}
