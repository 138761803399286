import { useMemo, type ReactElement, useEffect, useState } from 'react';
import * as S from './LessInstalledAppsComponent.styles';
import { DashboardCardComponent } from '../../DashboardCard/DashboardCardComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import type { SimpleStatistics } from 'modules/operational/pages/dashboard/DashboardPage.contracts';
import type { Toast } from 'modules/core/components';
import { ComponentToast } from 'modules/core/components';

export const LessInstalledAppsComponent = (): ReactElement => {
  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const servicePulsusHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);

  const [lessIntalledApps, setLessIntalledApps] = useState<SimpleStatistics[]>([]);
  const [toast, setToast] = useState<Toast[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const getLessInstalledApps = async () => {
    try {
      const lessInstalledApps = await servicePulsusHagalaz.getDashboardLessInstalledApps();
      setLessIntalledApps(lessInstalledApps);
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getLessInstalledApps();
  }, []);

  return (
    <DashboardCardComponent isLoading={isLoading} title={t('dashboards.lessInstalled.title')}>
      {!isLoading &&
        (lessIntalledApps.length === 0 ? (
          <p>{t('dashboards.lessInstalled.empty')}</p>
        ) : (
          lessIntalledApps.map((item) => {
            return (
              <S.Bar key={item.label + item.ratio}>
                <S.Name>{item.label}</S.Name>
                <S.Percentage>{Math.round(item.ratio)}%</S.Percentage>
              </S.Bar>
            );
          })
        ))}
      <ComponentToast dismissToast={() => setToast([])} toasts={toast} />
    </DashboardCardComponent>
  );
};
