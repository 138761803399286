import styled from 'styled-components';
import { ConstantColors } from 'utilities';
import { EuiButton } from '@elastic/eui';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const AnimationContainer = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 765px;
  height: 100%;
  margin-top: 49px;
  margin-left: auto;
  margin-right: auto;

  @media (max-height: 900px) {
    width: 565px;
  }
`;

export const Animation = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 600px;

  @media (max-height: 900px) {
    height: 400px;
  }
`;

export const Title = styled.h2`
  margin-top: 24px;
  width: 100%;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -1.08px;
  text-align: center;
  color: ${({ theme }) => theme.main};

  @media (max-height: 900px) {
    margin-top: 14px;
  }
`;

export const Description = styled.p`
  margin-top: 16px;
  margin-bottom: 30px;
  width: 100%;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.08px;
  text-align: center;
  color: ${ConstantColors.GREECE};

  @media (max-height: 900px) {
    margin-top: 6px;
    margin-bottom: 15px;
  }
`;

export const Button = styled(EuiButton)`
  width: fit-content;
  height: 40px;

  & .euiButton__text {
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: -0.08px;
  }

  @media (max-height: 900px) {
    height: 30px;

    & .euiButton__text {
      font-size: 12px;
    }
  }
`;
