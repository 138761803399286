import { useTranslation } from 'react-i18next';
import type { FormEvent } from 'react';
import { useMemo, useState } from 'react';
import { EuiFieldPassword, EuiModal } from '@elastic/eui';
import { HelperAuthentication } from 'helpers';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import type { ModalPasswordConfirmProps } from './ComponentModalPasswordConfirm.contracts';
import * as style from './ComponentModalPasswordConfirm.style';

export function ModalPasswordConfirm({ setOpen, handleShowPin, kind }: ModalPasswordConfirmProps) {
  const { t } = useTranslation('translation', { keyPrefix: 'sim_management' });

  const [value, setValue] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const fetchPassword = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      setLoading(true);

      const email = HelperAuthentication.decodingEmail();
      await servicePulsusWeb.login(email, value);

      handleShowPin();
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = (inputValue: string): void => {
    setError(false);
    setValue(inputValue);
  };

  return (
    <EuiModal onClose={() => setOpen(false)}>
      <style.WrapperContent onSubmit={(e) => fetchPassword(e)}>
        <div className="information">
          <h1>{t('password_confirmation.title')}</h1>
          <p>{kind === 'pin' ? t('password_confirmation.subtitle_pin') : t('password_confirmation.subtitle_puk')}</p>
        </div>
        <div>
          <style.FormRowPassword error={t('password_confirmation.error_message')} isInvalid={error} label="Senha">
            <EuiFieldPassword
              placeholder={t('password_confirmation.placeholder')}
              type="dual"
              value={value}
              onChange={(e) => handleChangePassword(e.target.value)}
              fullWidth
              isInvalid={error}
            />
          </style.FormRowPassword>
        </div>
        <style.ButtonGroup>
          <style.StyledButtonEmpty disabled={loading} color="text" onClick={() => setOpen(false)}>
            {t('password_confirmation.button_cancel')}
          </style.StyledButtonEmpty>
          <style.StyledButton isLoading={loading} type="submit">
            {t('password_confirmation.button_confirm')}
          </style.StyledButton>
        </style.ButtonGroup>
      </style.WrapperContent>
    </EuiModal>
  );
}
