import image_pulsus_device_enroll from 'assets/images/image-pulsus-device-enroll.svg';

export function DeviceDraw(props: { width: string | number; height: string | number }) {
  const { width, height } = props;
  return (
    <img
      width={width}
      height={height}
      data-testid="image_pulsus_device_enroll"
      src={image_pulsus_device_enroll}
      alt="demonstaçõe de enroll de dispositivo"
    />
  );
}
