import { useState, useEffect, useMemo } from 'react';
import image_pulsus_add_certificate from 'assets/images/image-pulsus-add-certificate.svg';
import { ModalFinishedCertificate } from 'pages/certificate/modal-certificate-finished/modal-finished-certificate';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import { ComponentApplicationContainer } from 'modules/core/components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import * as style from './page-certificate.style';
import { ComponentCertificatesList } from './certificate-list/component-certificates-list';
import { CertificateTypeModal } from './modal-certificate-type/certificate-type-modal';
import { CertificateConfigModal } from './certificate-config-modal/certificate-config-modal';
import { CertificateLoginModal } from './certificate-entrust-login-modal/certificate-entrust-login-modal';
import { CertificateIdentityModal } from './certificate-identity-modal/certificate-identity-modal';
import { CertificateVariablesModal } from './certificate-variables-modal/certificate-variables-modal';
import { ConstantResource } from '../../modules/core/utilities';

export function PageCertificate() {
  const [is_open_type_modal, set_is_open_type_modal] = useState(false);
  const [is_open_config_modal, set_is_open_config_modal] = useState(false);
  const [is_open_identity_modal, set_is_open_identity_modal] = useState(false);
  const [is_open_login_modal, set_is_open_login_modal] = useState(false);
  const [is_open_finished_modal, set_is_open_finished_modal] = useState(false);
  const [is_open_variables_modal, set_is_open_variables_modal] = useState(false);
  const [kind, set_kind] = useState('authority');
  const [file, set_file] = useState([]);
  const [has_error, set_has_error] = useState(false);
  const [message_error, set_message_error] = useState('');
  const [certificate_id, set_certificate_id] = useState('');
  const [certificate_name, set_certificate_name] = useState('');
  const { t } = useTranslation();
  const query = useQuery();
  const [authorityEnabled, setAuthorityEnabled] = useState(false);
  const [identityEnabled, setIdentityEnabled] = useState(false);
  const [groupPolicyFlags, setGroupPolicyFlags] = useState(false);

  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  useEffect(() => {
    servicePulsusWeb
      .getGroupPolicyFlags()
      .then((response) => {
        if (response.certificates) {
          setGroupPolicyFlags(true);
          CheckBundles();
        }
      })
      .catch((e) => {
        throw new Error(e);
      });
  }, []);

  const CheckBundles = () => {
    servicePulsusWeb.getResources().then(({ data }: { data: string[] }) => {
      setAuthorityEnabled(data.includes(ConstantResource.CERTIFICATES.AUTHORITY));
      setIdentityEnabled(data.includes(ConstantResource.CERTIFICATES.IDENTITY));
    });
  };

  const language = query.get('language');

  useEffect(() => {
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  const handleOpenTypeModal = () => {
    set_is_open_type_modal(true);
    set_is_open_config_modal(false);
  };

  const handleOpenModal = () => {
    if (kind === 'authority') {
      set_is_open_config_modal(true);
    } else {
      set_is_open_login_modal(true);
    }

    set_kind('authority');
    set_is_open_type_modal(false);
  };

  const handleOpenFinishedModal = () => {
    set_is_open_type_modal(false);
    set_is_open_config_modal(false);
    set_is_open_finished_modal(true);
    set_file([]);
  };

  const handleCloseTypeModal = () => {
    set_is_open_type_modal(false);
  };

  const handleCloseConfigModal = () => {
    set_is_open_config_modal(false);
    set_is_open_login_modal(false);
    set_has_error(false);
  };

  const handleCloseIdentityModal = () => {
    set_is_open_identity_modal(false);
  };
  const handleOpenIdentityModal = () => {
    set_is_open_identity_modal(true);
  };

  // TODO disabled because never was used
  // const handleCloseLoginModal = () => {
  //   set_is_open_login_modal(false);
  // };

  const handleCloseVariablesModal = () => {
    set_is_open_variables_modal(false);
  };

  const handleCloseFinishedModal = () => {
    set_is_open_finished_modal(false);
    set_has_error(false);
  };

  const openVariablesModal = (certificateId: string | null, certificateName: string) => {
    set_is_open_variables_modal(true);
    certificateId ? set_certificate_id(certificateId) : set_certificate_id('');
    set_certificate_name(certificateName);
  };

  const handleCreatedCertificate = () => {
    servicePulsusWeb
      .newCertificate(file?.[0], kind)
      .then(() => {
        handleOpenFinishedModal();
      })
      .catch((error) => {
        set_has_error(true);
        if (error.response.status === 500) {
          set_message_error('O certificado enviado é inválido, por favor faça o upload de um certificado diferente.');
        } else {
          set_message_error('Erro ao enviar o certificado.');
        }
      });
    set_file([]);
  };

  return (
    <ComponentApplicationContainer>
      {groupPolicyFlags && (
        <style.Container>
          <style.LeftContainer>
            <header>
              <style.TitleThird>{t('pages.certificate_list.title')}</style.TitleThird>
              <style.TextPrimary>
                {t('pages.certificate_list.subtitle')}
                {/* TODO disabled because this variable never was setted <b>{group_policy_name && ` ${group_policy_name}.`}</b> */}
              </style.TextPrimary>
            </header>
            <ComponentCertificatesList
              isOpenFinishedModal={is_open_finished_modal}
              openVariablesModal={openVariablesModal}
              authorityEnabled={authorityEnabled}
              identityEnabled={identityEnabled}
            />
          </style.LeftContainer>
          <style.RightContainer>
            <style.ImagePulsusAddCertificate src={image_pulsus_add_certificate} alt="um homem usando celulares" />
            <style.TitleSecondary>{t('pages.certificate_list.add_certificate_title')}</style.TitleSecondary>
            <style.TextSecondary>{t('pages.certificate_list.add_certificate_subtitle')}</style.TextSecondary>
            <style.ButtonsContainer>
              <style.Button fill iconType="plusInCircle" onClick={() => handleOpenTypeModal()}>
                {t('pages.certificate_list.add_certificate_button')}
              </style.Button>
            </style.ButtonsContainer>
          </style.RightContainer>
          {is_open_type_modal && (
            <CertificateTypeModal
              onClose={handleCloseTypeModal}
              openConfigModal={handleOpenModal}
              setKind={set_kind}
              authorityEnabled={authorityEnabled}
              identityEnabled={identityEnabled}
            />
          )}

          {is_open_config_modal && (
            <CertificateConfigModal
              onClose={handleCloseConfigModal}
              handleCreatedCertificate={handleCreatedCertificate}
              setFile={set_file}
              file={file}
              hasError={has_error}
              setHasError={set_has_error}
              messageError={message_error}
              certificateName=""
            />
          )}
          {is_open_identity_modal && <CertificateIdentityModal openFinishedModal={handleOpenFinishedModal} onClose={handleCloseIdentityModal} />}
          {is_open_login_modal && (
            <CertificateLoginModal
              onClose={handleCloseConfigModal}
              handleOpenIdentityModal={handleOpenIdentityModal}
              handleCreatedCertificate={handleCreatedCertificate}
              setFile={set_file}
              file={file}
              hasError={has_error}
              setHasError={set_has_error}
              messageError={message_error}
              certificateName=""
            />
          )}
          {is_open_finished_modal && <ModalFinishedCertificate onClose={handleCloseFinishedModal} />}
          {is_open_variables_modal && (
            <CertificateVariablesModal
              certificateId={certificate_id}
              certificateName={certificate_name}
              openFinishedModal={handleOpenFinishedModal}
              onClose={handleCloseVariablesModal}
            />
          )}
        </style.Container>
      )}
    </ComponentApplicationContainer>
  );
}
