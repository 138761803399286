/* eslint-disable react/jsx-one-expression-per-line */
import type { EuiStepProps, EuiTabbedContentTab } from '@elastic/eui';
import { EuiCodeBlock, EuiSteps } from '@elastic/eui';
import type { TFunction } from 'react-i18next';

const AFWSteps = (t: TFunction<'translation', undefined>): Omit<EuiStepProps, 'step'>[] => [
  {
    title: t('fully_managed_wp.afw.first_step.title'),
    children: <p>{t('fully_managed_wp.afw.first_step.subtitle')}</p>,
  },
  {
    title: t('fully_managed_wp.afw.second_step.title'),
    children: (
      <>
        <p>
          {t('fully_managed_wp.afw.second_step.subtitle')}
          <b>{t('fully_managed_wp.afw.second_step.bold_text')}</b>
          {t('fully_managed_wp.afw.second_step.subtitle_secondary')}
        </p>
        <h2 className="theme-color">afw#setup</h2>
        <p>{t('fully_managed_wp.afw.second_step.subtitle_third')}</p>
      </>
    ),
  },
  {
    title: t('fully_managed_wp.afw.third_step.title'),
    children: (
      <p>
        {t('fully_managed_wp.afw.third_step.subtitle')}
        <b>{t('fully_managed_wp.afw.third_step.bold_text')}</b>
      </p>
    ),
  },
];

const QRCodeSteps = (t: TFunction<'translation', undefined>): Omit<EuiStepProps, 'step'>[] => [
  {
    title: t('fully_managed_wp.zero_touch.title'),
    children: (
      <p>
        {t('fully_managed_wp.zero_touch.subtitle')}
        <b>{t('fully_managed_wp.zero_touch.bold_text')}</b>
        {t('fully_managed_wp.zero_touch.subtitle_secondary')}
        <b>{t('fully_managed_wp.zero_touch.bold_text_secondary')}</b>
      </p>
    ),
  },
  {
    title: t('default_steps.qr_code.second_step.title'),
    children: <p>{t('default_steps.qr_code.second_step.subtitle')}</p>,
  },
  {
    title: t('default_steps.qr_code.third_step.title'),
    children: <p>{t('default_steps.qr_code.third_step.subtitle')}</p>,
  },
];

const zeroTouchSteps = (code: string, t: TFunction<'translation', undefined>): Omit<EuiStepProps, 'step'>[] => [
  {
    title: t('default_steps.zero_touch.first_step.title'),
    children: (
      <>
        <p>
          {t('default_steps.zero_touch.first_step.subtitle')}
          <a className="link-like" href="https://partner.android.com/zerotouch" target="_blank" rel="noreferrer">
            {t('default_steps.zero_touch.first_step.bold_text')}
          </a>
          {t('default_steps.zero_touch.first_step.subtitle_secondary')}
        </p>
        <EuiCodeBlock paddingSize="s" language="json" fontSize="s" overflowHeight={180} isCopyable>
          {code}
        </EuiCodeBlock>
      </>
    ),
  },
  {
    title: t('default_steps.zero_touch.second_step.title'),
    children: (
      <p>
        {t('default_steps.zero_touch.second_step.subtitle')}
        <b>{t('default_steps.zero_touch.second_step.bold_text')}</b>
        {t('default_steps.zero_touch.second_step.subtitle_secondary')}
      </p>
    ),
  },
  {
    title: t('default_steps.zero_touch.third_step.title'),
    children: (
      <p>
        {t('default_steps.zero_touch.third_step.subtitle')}
        <b>{t('default_steps.zero_touch.third_step.bold_text')}</b>
        {t('default_steps.zero_touch.third_step.subtitle_secondary')}
      </p>
    ),
  },
];

export const tabs = (QRcode: string, code: string, translation: TFunction<'translation', undefined>): EuiTabbedContentTab[] => [
  {
    id: 'afw',
    name: 'AFW',
    content: <EuiSteps style={{ height: 480, overflow: 'scroll' }} steps={AFWSteps(translation)} />,
  },
  {
    id: 'qr-code',
    name: 'QR Code',
    content: <EuiSteps style={{ height: 480, overflow: 'scroll' }} steps={QRCodeSteps(translation)} />,
  },
  {
    id: 'zero-touch',
    name: 'Zero Touch',
    content: <EuiSteps style={{ height: 480, overflow: 'scroll' }} steps={zeroTouchSteps(code, translation)} />,
  },
];
