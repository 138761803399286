export class DataUsageIndicator {
  public value: number;
  public dailyAverage: number;
  public percentage?: number;

  constructor(data: DataUsageIndicator.Server) {
    this.value = data.value;
    this.dailyAverage = data.dailyAverage;
    this.percentage = data.percentage;
  }
}

export namespace DataUsageIndicator {
  export type Server = {
    value: number;
    dailyAverage: number;
    percentage?: number;
  };
}
