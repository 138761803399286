import styled from 'styled-components';

export const ModalsFooter = styled.div``;

export const Content = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Text = styled.p`
  font-weight: 700;
  font-size: 19px;
  line-height: 28px;
  text-align: center;
  color: #5e5e5e;
`;

export const Img = styled.img`
  width: 500px;
  height: 297px;
  margin: -60px auto 50px auto;
`;

export const ReregisterIcons = styled.img`
  width: 63px;
  height: 74px;
  position: absolute;
  top: 110px;
  left: 150px;
`;
