import { EuiCard } from '@elastic/eui';
import styled from 'styled-components';

export const CardContainer = styled.div`
  max-width: 483px;
`;

export const Card = styled(EuiCard)`
  display: flex;
  border-radius: 9px;
  padding: 25px !important;
  border: none !important;

  .euiTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
`;

export const CardContent = styled.div``;

export const SwitchContainer = styled.div`
  margin-top: 15px;
  font-size: 14px;

  .euiSwitch__body {
    background: ${({ theme }) => theme.main};
  }

  .euiSwitch__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

export const CardDescription = styled.p`
  margin-top: 15px;
  font-size: 13px;
  line-height: 15.73px;
`;
