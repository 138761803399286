import type { Geofence } from 'modules/management/entities';
import type { GeofenceFieldsProps, ReducerActions, ChangedFences } from './contracts';
import { GeofenceActionType } from './contracts';

export const setFieldsState = (fields: GeofenceFieldsProps): ReducerActions => ({
  payload: fields,
  type: GeofenceActionType.UPDATE_FIELDS,
});

export const setGeofenceAreas = (geofences: Geofence[]): ReducerActions => ({
  payload: geofences,
  type: GeofenceActionType.UPDATE_GEOFENCE_AREAS,
});

export const setChangedFences = (changedFences: ChangedFences): ReducerActions => ({
  payload: changedFences,
  type: GeofenceActionType.UPDATE_CHANGED_FENCES,
});
