/* eslint-disable react/jsx-one-expression-per-line */
import { ComponentBreadcrumb, DialogComponent } from 'components';
import { IconPulsusLogo } from 'icons';
import { ConstantColors, ConstantRoutes } from 'utilities';
import AEZeroTouch from 'assets/images/AE-zero-touch.jpg';
import AEBanner from 'assets/images/AE-banner.jpg';
import { useState } from 'react';
import { DirectZeroTouch } from 'embedded/embedded-ae-enroll/direct-zero-touch/embedded-direct-zero-touch';
import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import type { AutocompleteOptionProps } from 'components/autocomplete/component-autocomplete.contracts';
import { EnrollModalManager } from 'embedded/embedded-ae-enroll/select-enrollment-type/embedded-enroll-type-selector';
import { ComponentApplicationContainer } from 'modules/core/components';
import * as style from './page-android-enterprise.style';
import { SettingsManagement } from './general-settings/general-settings-management';

export function PageAndroidEnterprise() {
  const [isAndroidAtached, setIsAndroidAtached] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [enrollGroup, setEnrollGroup] = useState<AutocompleteOptionProps | null>({ id: '', dropdownDisplay: '' });
  const [renderedModalIndex, setRenderedModalIndex] = useState(0);

  function onChangeEnrollGroup(group: AutocompleteOptionProps | null) {
    setEnrollGroup(group);
  }

  const modalFooter = (
    <style.ModalFooter style={renderedModalIndex === 1 ? { justifyContent: 'start', width: '100%' } : {}}>
      <EuiButtonEmpty
        onClick={() => {
          setEnrollGroup({ id: '', dropdownDisplay: '' });
          if (renderedModalIndex === 1) {
            setRenderedModalIndex(0);
          } else {
            setModalOpen(false);
          }
        }}
      >
        Cancelar
      </EuiButtonEmpty>
      <EuiButton
        style={renderedModalIndex === 1 ? { display: 'none' } : {}}
        disabled={!enrollGroup || !enrollGroup.id}
        onClick={() => {
          setRenderedModalIndex(1);
        }}
        className="confirm"
        type="button"
      >
        Avançar
      </EuiButton>
    </style.ModalFooter>
  );

  return (
    <ComponentApplicationContainer>
      <ComponentBreadcrumb items={ConstantRoutes.ANDROID_ENTERPRISE.breadcrumbs} />
      <style.Content>
        <style.TopContainer>
          <style.LeftContainer>
            <style.About>
              <IconPulsusLogo width={135} height={25} color={ConstantColors.COLOMBIA} test_id="header-logo-icon" />
              <h1 className="theme-color">Android Enterprise</h1>
              <p>
                Encontre aqui todas as funcionalidades e recursos que o programa do <b>Google, Android Enterprise</b> , recomenda no gerenciamento de
                dispositivos corporativos a nivel mundial, agregando mais segurança e praticidade aos seus usuários e dispositivos.
              </p>
            </style.About>
            <style.AccInfo>
              {isAndroidAtached ? (
                <>
                  <div>
                    <p className="britgher-color">NOME DA CONTA</p>
                    <h2>Multilaser S.A</h2>
                  </div>
                  <div>
                    <p>
                      <b>ENTERPRISE ID:</b> <span className="britgher-color">838747hd</span>
                    </p>
                    <p>
                      <b>EMAIL:</b> <span className="britgher-color">wesley.vieira.teste@gmail.com</span>
                    </p>
                    <p>
                      <b>ATIVADO EM:</b> <span className="britgher-color">09 de junho de 2021, 14:05h</span>
                    </p>
                  </div>
                </>
              ) : (
                <div>
                  <h3 className="theme-color">Vincular conta Android Enterprise</h3>
                  <div className="atach-texts">
                    <p className="britgher-color">Você não possui nenhuma conta vinculada;</p>
                    <p>
                      <b>deseja vincular uma conta agora?</b>
                    </p>
                  </div>
                </div>
              )}
              <style.ButtonDetachAcc
                onClick={() => setIsAndroidAtached(!isAndroidAtached)}
                fill
                iconType={isAndroidAtached ? 'minusInCircle' : 'plusInCircle'}
              >
                {isAndroidAtached ? 'Desvincular' : 'Vincular'} conta Android Enterprise
              </style.ButtonDetachAcc>
            </style.AccInfo>
            <style.ZeroTouch>
              <div>
                <IconPulsusLogo width={100} height={20} color={ConstantColors.COLOMBIA} test_id="header-logo-icon" />
                <h2 className="theme-color">Zero Touch Enrollment</h2>
                <p>
                  Aqui você pode fazer uma ativação em massa dos seus dispositivos de maneira mais fácil com o programa da Google, Zero Touch.
                  <b>Experimente agora.</b>
                </p>
                <button type="button" className="theme-color config" onClick={() => setModalOpen(true)}>
                  Configurar
                </button>
              </div>
              <img src={AEZeroTouch} alt="android zero touch" />
            </style.ZeroTouch>
          </style.LeftContainer>
          <img src={AEBanner} alt="android enterprise recommended" />
        </style.TopContainer>
        <SettingsManagement />
      </style.Content>
      <DialogComponent open={modalOpen} maxWidth="1200px" onClose={() => setModalOpen(false)} footer={modalFooter}>
        {renderedModalIndex === 0 && <DirectZeroTouch groupSelected={enrollGroup} onChangeGroup={(group) => onChangeEnrollGroup(group)} />}
        {renderedModalIndex === 1 && <EnrollModalManager groupSelected={enrollGroup} />}
      </DialogComponent>
    </ComponentApplicationContainer>
  );
}
