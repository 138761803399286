export class DataUsageTableApps {
  public totalItems: number;
  public apps: AppsList[];

  constructor(data: DataUsageTableApps.Server) {
    this.totalItems = data.total_items;
    this.apps = data.page_items.map((item) => ({
      name: item.app_name,
      appValue: item.average_total_data,
    }));
  }
}

export namespace DataUsageTableApps {
  export type Server = {
    total_items: number;
    page_items: {
      app_name: string;
      average_total_data: number;
    }[];
  };
}

export type AppsList = {
  name: string;
  appValue: number;
};
