/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState, useEffect } from 'react';
import { EuiSwitch } from '@elastic/eui';
import { IconWorkProfile } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import type { PasswordPolicyContentProps, WifiToggleProps } from './page-security-settings.contracts';
import * as style from './wifi-toggle-policy.style';
import { CustomSlider } from './custom-slider';
import { SelectBox } from './security-select-box';
import { PopulateAndroidSettingsOptions } from './select-options-values';

export const WifiSettings = (props: WifiToggleProps) => {
  const { active, onClick } = props;
  const [activeTab, setActiveTab] = useState<string>('0');
  const [initialPasswordPolicies, setInitialPasswordPolicies] = useState({});
  const { t } = useTranslation();
  const options = PopulateAndroidSettingsOptions();
  const passwordPolicies = useSelector((store: any) => store?.android_settings?.policy?.passwordPolicies);
  const dispatch = useDispatch();

  const initialValues = {
    passwordQuality: false,
    passwordType: undefined,
    minSize: 0,
    changePeriod: 0,
    passwordNewRegistration: 0,
    blockDevice: false,
    inactivityTime: 0,
    eraseDataAfter: 0,
    strongPassword: false,
    requestPasswordTime: 'REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED',
  };
  const [tabContentValues, setContentValues] = useState<PasswordPolicyContentProps>(initialValues);
  const [workTabContentValues, setWorkContentValues] = useState<PasswordPolicyContentProps>(initialValues);

  const passwordQualityHidden =
    (activeTab === '0' &&
      (tabContentValues.passwordType === 'COMPLEXITY_LOW' ||
        tabContentValues.passwordType === 'COMPLEXITY_MEDIUM' ||
        tabContentValues.passwordType === 'COMPLEXITY_HIGH')) ||
    (activeTab === '1' &&
      (workTabContentValues.passwordType === 'COMPLEXITY_LOW' ||
        workTabContentValues.passwordType === 'COMPLEXITY_MEDIUM' ||
        workTabContentValues.passwordType === 'COMPLEXITY_HIGH'));

  const firstDisable =
    !active || (activeTab === '0' && !tabContentValues.passwordQuality) || (activeTab === '1' && !workTabContentValues.passwordQuality);
  const secondDisable = !active || (activeTab === '0' && !tabContentValues.blockDevice) || (activeTab === '1' && !workTabContentValues.blockDevice);
  const thirdDisable =
    !active || (activeTab === '0' && !tabContentValues.strongPassword) || (activeTab === '1' && !workTabContentValues.strongPassword);

  function changeTab(value: string) {
    setActiveTab(value);
  }

  function tabHandleChange(value: any, key: string) {
    const aux: any = {};
    aux[key] = value;
    if (activeTab === '0') {
      setContentValues((prevState) => ({
        ...prevState,
        ...aux,
      }));
    } else {
      setWorkContentValues((prevState) => ({
        ...prevState,
        ...aux,
      }));
    }
  }

  function overwriteData(data: any, scope: string | undefined) {
    if (scope === 'SCOPE_PROFILE') {
      setWorkContentValues(data);
    } else if (scope === 'SCOPE_DEVICE') {
      setContentValues(data);
    }
  }

  function translateData(data: any) {
    const body: any = [];
    Object.entries(data).forEach(([key, value]: [any, any]) => {
      const auxObj = {
        passwordQuality: value.passwordType,
        passwordMinimumLength: value.minSize,
        passwordHistoryLength: value.passwordNewRegistration,
        passwordExpirationTimeout: `${Number(value.changePeriod) * 86400}s`,
        maxInactivity: value.inactivityTime,
        maximumFailedPasswordsForWipe: value.eraseDataAfter,
        requirePasswordUnlock: value.requestPasswordTime,
        passwordScope: key === 'device' ? 'SCOPE_DEVICE' : 'SCOPE_PROFILE',
      };
      if (Object.keys(auxObj).length > 0) {
        body.push(auxObj);
      }
    });
    return body;
  }

  function sendStoreChanges() {
    const data = translateData({
      device: { ...tabContentValues, passwordScope: 'SCOPE_DEVICE' },
      profile: { ...workTabContentValues, passwordScope: 'SCOPE_PROFILE' },
    });
    dispatch({
      type: 'SET_POLICY_CHANGES',
      payload: { passwordPolicies: data },
    });
  }

  async function populateData(data: any) {
    data?.forEach((item: any) => {
      const auxObj = {
        passwordQuality:
          item.passwordQuality !== 'PASSWORD_QUALITY_UNSPECIFIED' ||
          (item.passwordMinimumLength && item.passwordMinimumLength !== 0) ||
          (item.passwordExpirationTimeout && Number(item.passwordExpirationTimeout.split('s')[0]) !== 0) ||
          (item.passwordHistoryLength && item.passwordHistoryLength !== 0),
        passwordType: item.passwordQuality || 'PASSWORD_QUALITY_UNSPECIFIED',
        minSize: item.passwordMinimumLength || 0,
        changePeriod: item.passwordExpirationTimeout ? Number(item.passwordExpirationTimeout.split('s')[0]) / 86400 : 0,
        blockDevice:
          (item.maximumFailedPasswordsForWipe && item.maximumFailedPasswordsForWipe !== 0) || (item.maxInactivity && item.maxInactivity !== 0),
        inactivityTime: item.maxInactivity || 0,
        eraseDataAfter: item.maximumFailedPasswordsForWipe || 0,
        passwordNewRegistration: item.passwordHistoryLength || 0,
        strongPassword:
          (item.passwordScope === 'SCOPE_DEVICE' &&
            (item.requirePasswordUnlock !== 'REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED' || tabContentValues.strongPassword)) ||
          (item.passwordScope === 'SCOPE_PROFILE' &&
            (item.requirePasswordUnlock !== 'REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED' || tabContentValues.strongPassword)),
        requestPasswordTime: item.requirePasswordUnlock || 'REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED',
      };
      overwriteData(auxObj, item.passwordScope);
    });
  }

  useEffect(() => {
    if (passwordPolicies && Object.keys(passwordPolicies).length > 0 && !_.isEqual(passwordPolicies, initialPasswordPolicies)) {
      populateData(passwordPolicies);
      setInitialPasswordPolicies(passwordPolicies);
    }
  }, [passwordPolicies]);

  useEffect(() => {
    sendStoreChanges();
  }, [tabContentValues, workTabContentValues]);

  return (
    <style.WifiCard title="" textAlign="left">
      <style.CardItem>
        <style.WifiTitle>{t('pages.pulsus_android_settings.text.password_card_title')}</style.WifiTitle>
        <div style={{ display: 'flex' }}>
          <style.Tabs hidden={!active}>
            <style.Tab isSelected={activeTab === '0'} onClick={() => changeTab('0')}>
              {t('pages.pulsus_android_settings.text.password_device_title')}
            </style.Tab>
            <style.Tab className="workProfile" isSelected={activeTab === '1'} onClick={() => changeTab('1')}>
              <style.TabContent>
                <IconWorkProfile />
                {t('pages.pulsus_android_settings.text.password_profile_title')}
              </style.TabContent>
            </style.Tab>
          </style.Tabs>
        </div>
        <style.WifiToggle workProfile={activeTab === '1'}>
          <EuiSwitch
            label={
              activeTab === '0'
                ? t('pages.pulsus_android_settings.labels.password_device_policy_active')
                : t('pages.pulsus_android_settings.labels.password_profile_policy_active')
            }
            checked={active}
            onChange={() => onClick()}
          />
        </style.WifiToggle>
        <style.WifiDescription>{t('pages.pulsus_android_settings.text.password_main_description')}</style.WifiDescription>
      </style.CardItem>
      <style.CardItem hidden={!active}>
        <style.WifiToggle workProfile={activeTab === '1'}>
          <EuiSwitch
            label={t('pages.pulsus_android_settings.labels.password_quality_switch')}
            checked={activeTab === '0' ? tabContentValues.passwordQuality : workTabContentValues.passwordQuality}
            onChange={() => {
              const auxValue = activeTab === '0' ? tabContentValues.passwordQuality : workTabContentValues.passwordQuality;
              tabHandleChange(!auxValue, 'passwordQuality');
            }}
          />
        </style.WifiToggle>
        <style.ElementsGroup>
          <style.Element style={{ width: 120 }}>
            <SelectBox
              options={options.passwordQualityWorkProfile}
              value={activeTab === '0' ? tabContentValues.passwordType : workTabContentValues.passwordType}
              onChange={(e) => {
                tabHandleChange(e, 'passwordType');
              }}
              label=""
              disabled={firstDisable}
            />
          </style.Element>
          <style.Element style={{ width: 150 }} hidden={passwordQualityHidden}>
            <CustomSlider
              min={0}
              max={16}
              value={activeTab === '0' ? tabContentValues.minSize : workTabContentValues.minSize}
              disabled={firstDisable}
              onChange={(e) => {
                tabHandleChange(e, 'minSize');
              }}
            >
              <style.ElementContentText>
                {t('pages.pulsus_android_settings.labels.password_min_length_prefix')}
                <style.ContentTextCustomColor workProfile={activeTab === '1'}>
                  {` ${activeTab === '0' ? tabContentValues.minSize : workTabContentValues.minSize} `}
                </style.ContentTextCustomColor>
                {t('pages.pulsus_android_settings.labels.password_min_length_sufix')}
              </style.ElementContentText>
            </CustomSlider>
          </style.Element>
          <style.Element style={{ width: 235 }}>
            <CustomSlider
              min={0}
              max={365}
              step={5}
              value={activeTab === '0' ? tabContentValues.changePeriod : workTabContentValues.changePeriod}
              disabled={firstDisable}
              onChange={(e) => tabHandleChange(e, 'changePeriod')}
            >
              <style.ElementContentText>
                {t('pages.pulsus_android_settings.labels.password_change_period_prefix')}
                <style.ContentTextCustomColor workProfile={activeTab === '1'}>
                  {` ${activeTab === '0' ? tabContentValues.changePeriod : workTabContentValues.changePeriod} `}
                </style.ContentTextCustomColor>
                {t('pages.pulsus_android_settings.labels.password_change_period_sufix')}
              </style.ElementContentText>
            </CustomSlider>
          </style.Element>
          <style.Element style={{ width: 355 }}>
            <CustomSlider
              min={0}
              max={50}
              value={activeTab === '0' ? tabContentValues.passwordNewRegistration : workTabContentValues.passwordNewRegistration}
              disabled={firstDisable}
              onChange={(e) => tabHandleChange(e, 'passwordNewRegistration')}
            >
              <style.ElementContentText>
                {t('pages.pulsus_android_settings.labels.password_history_length_prefix')}
                <style.ContentTextCustomColor workProfile={activeTab === '1'}>
                  {` ${activeTab === '0' ? tabContentValues.passwordNewRegistration : workTabContentValues.passwordNewRegistration} `}
                </style.ContentTextCustomColor>
                {t('pages.pulsus_android_settings.labels.password_history_length_sufix')}
              </style.ElementContentText>
            </CustomSlider>
          </style.Element>
        </style.ElementsGroup>
      </style.CardItem>
      <style.CardItem hidden={!active}>
        <style.ElementsGroup customGap="0 73px">
          <style.Element>
            <style.WifiToggle workProfile={activeTab === '1'}>
              <EuiSwitch
                label={t('pages.pulsus_android_settings.labels.device_block')}
                checked={activeTab === '0' ? tabContentValues.blockDevice : workTabContentValues.blockDevice}
                onChange={() => {
                  const auxValue = activeTab === '0' ? tabContentValues.blockDevice : workTabContentValues.blockDevice;
                  tabHandleChange(!auxValue, 'blockDevice');
                }}
              />
            </style.WifiToggle>
            <div style={{ marginTop: 15 }}>
              <CustomSlider
                min={0}
                max={5}
                value={activeTab === '0' ? tabContentValues.inactivityTime : workTabContentValues.inactivityTime}
                disabled={secondDisable}
                onChange={(e) => tabHandleChange(e, 'inactivityTime')}
              >
                <style.ElementContentText>
                  {t('pages.pulsus_android_settings.labels.device_max_inactivity_time_prefix')}
                  <style.ContentTextCustomColor workProfile={activeTab === '1'}>
                    {` ${activeTab === '0' ? tabContentValues.inactivityTime : workTabContentValues.inactivityTime} `}
                  </style.ContentTextCustomColor>
                  {t('pages.pulsus_android_settings.labels.device_max_inactivity_time_sufix')}
                </style.ElementContentText>
              </CustomSlider>
            </div>
          </style.Element>
          <style.Element style={{ width: 620 }}>
            <div style={{ marginTop: 15 }}>
              <CustomSlider
                min={0}
                max={10}
                ticks={options.eraseDataOptions}
                fullWidth
                value={activeTab === '0' ? tabContentValues.eraseDataAfter : workTabContentValues.eraseDataAfter}
                disabled={secondDisable}
                onChange={(e) => tabHandleChange(e, 'eraseDataAfter')}
              >
                <style.ElementContentText>
                  {t('pages.pulsus_android_settings.labels.password_max_failed_for_wipe_prefix')}
                  <style.ContentTextCustomColor workProfile={activeTab === '1'}>
                    {` ${activeTab === '0' ? tabContentValues.eraseDataAfter : workTabContentValues.eraseDataAfter} `}
                  </style.ContentTextCustomColor>
                  {t('pages.pulsus_android_settings.labels.password_max_failed_for_wipe_sufix')}
                </style.ElementContentText>
              </CustomSlider>
              <style.WifiWarningAlert>
                <style.WarningTitle>
                  <style.WarningIcon type="help" />
                  <style.WarningMessage>{t('pages.pulsus_android_settings.text.password_max_failed_for_wipe_description')}</style.WarningMessage>
                </style.WarningTitle>
              </style.WifiWarningAlert>
            </div>
          </style.Element>
        </style.ElementsGroup>
      </style.CardItem>
      <style.CardItem hidden={!active}>
        <style.Element>
          <style.WifiToggle workProfile={activeTab === '1'}>
            <EuiSwitch
              label={t('pages.pulsus_android_settings.labels.request_strong_password')}
              checked={activeTab === '0' ? tabContentValues.strongPassword : workTabContentValues.strongPassword}
              onChange={() => {
                const auxValue = activeTab === '0' ? tabContentValues.strongPassword : workTabContentValues.strongPassword;
                tabHandleChange(!auxValue, 'strongPassword');
              }}
            />
          </style.WifiToggle>
          <div style={{ marginTop: 15 }}>
            <style.GroupButton
              legend=""
              workProfile={activeTab === '1'}
              isDisabled={thirdDisable}
              options={options.passwordRequired}
              idSelected={activeTab === '0' ? tabContentValues.requestPasswordTime : workTabContentValues.requestPasswordTime}
              onChange={(e) => tabHandleChange(e, 'requestPasswordTime')}
            />
          </div>
        </style.Element>
      </style.CardItem>
    </style.WifiCard>
  );
};
