import androidEnterprise from 'assets/images/onboarding-modal-android.png';
import settings from 'assets/images/onboarding-modal-settings.jpg';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useEffect } from 'react';
import { useQuery } from 'hooks/use-query';
import * as style from './modals.style';
import type { ModalConfigureProps } from '../page-onboarding.contracts';

export function ModalConfigLater({ setStep, closeModal }: ModalConfigureProps) {
  const { t } = useTranslation();
  const query = useQuery();

  const nextStep = () => {
    setStep('ios1');
    closeModal();
  };

  useEffect(() => {
    const language = query.get('language');
    if (language) {
      i18next.changeLanguage(language);
    }
  }, []);

  return (
    <style.ModalConfig onClose={closeModal}>
      <section>
        <h2>{t('pages.onboarding.modal_integration_first')}</h2>

        <p>{t('pages.onboarding.modal_integration_second')}</p>
        <img src={settings} alt="aba enterprise" />
      </section>
      <img className="android-img" src={androidEnterprise} alt="android recommended" />
      <style.StepButton onClick={nextStep}>{t('pages.onboarding.modal_integration_link')}</style.StepButton>
    </style.ModalConfig>
  );
}
