import type { EuiRangeTick } from '@elastic/eui/src/components/form/range/range_ticks';
import { useTranslation } from 'react-i18next';
import type * as Types from './page-security-settings.contracts';

export function PopulateAndroidSettingsOptions() {
  const { t } = useTranslation();

  const passwordQuality: Types.SelectOption[] = [
    { value: '', text: t('pages.pulsus_android_settings.options.password_type_select') },
    { value: 'NUMERIC', text: t('pages.pulsus_android_settings.options.password_quality_device_numeric') },
    { value: 'ALPHANUMERIC', text: t('pages.pulsus_android_settings.options.password_quality_device_alphanumeric') },
    { value: 'BIOMETRIC_WEAK', text: t('pages.pulsus_android_settings.options.password_quality_device_biometric_weak') },
    { value: 'NUMERIC_COMPLEX', text: t('pages.pulsus_android_settings.options.password_quality_device_numeric_complex') },
  ];

  const passwordQualityWorkProfile: Types.SelectOption[] = [
    { value: '', text: t('pages.pulsus_android_settings.options.password_type_select') },
    { value: 'COMPLEXITY_LOW', text: t('pages.pulsus_android_settings.options.password_quality_profile_complexity_low') },
    { value: 'COMPLEXITY_MEDIUM', text: t('pages.pulsus_android_settings.options.password_quality_profile_complexity_medium') },
    { value: 'COMPLEXITY_HIGH', text: t('pages.pulsus_android_settings.options.password_quality_profile_numeric_high') },
    { value: 'NUMERIC', text: t('pages.pulsus_android_settings.options.password_quality_profile_numeric') },
    { value: 'ALPHANUMERIC', text: t('pages.pulsus_android_settings.options.password_quality_profile_alphanumeric') },
    { value: 'BIOMETRIC_WEAK', text: t('pages.pulsus_android_settings.options.password_quality_profile_biometric_weak') },
    { value: 'NUMERIC_COMPLEX', text: t('pages.pulsus_android_settings.options.password_quality_profile_numeric_complex') },
  ];

  const passwordRequired: { id: string; label: string }[] = [
    {
      id: 'REQUIRE_PASSWORD_UNLOCK_UNSPECIFIED',
      label: t('pages.pulsus_android_settings.options.password_require_off'),
    },
    {
      id: 'USE_DEFAULT_DEVICE_TIMEOUT',
      label: t('pages.pulsus_android_settings.options.password_require_default'),
    },
    {
      id: 'REQUIRE_EVERY_DAY',
      label: t('pages.pulsus_android_settings.options.password_require_every_day'),
    },
  ];

  const eraseDataOptions: EuiRangeTick[] = [
    { label: t('pages.pulsus_android_settings.options.password_max_failed_for_wipe_not_active'), value: 0 },
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
    { label: '6', value: 6 },
    { label: '7', value: 7 },
    { label: '8', value: 8 },
    { label: '9', value: 9 },
    { label: '10', value: 10 },
  ];

  return {
    passwordQuality,
    passwordQualityWorkProfile,
    passwordRequired,
    eraseDataOptions,
  };
}
