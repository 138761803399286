import type { WrapperComponentProps } from './WrapperComponent.contracts';
import * as S from './WrapperComponent.styles';
import { ConstantColors } from 'utilities';

const defaultProps = {
  hasVerticalPadding: true,
};

export const WrapperComponent = ({ children, color, hasVerticalPadding, hasFooter }: WrapperComponentProps) => {
  return (
    <S.Container hasFooter={hasFooter} hasVerticalPadding={hasVerticalPadding} color={color || ConstantColors.AUSTRIA}>
      {children}
    </S.Container>
  );
};

WrapperComponent.defaultProps = defaultProps;
