import Icon from '@mdi/react';
import { useTranslation } from 'react-i18next';
import * as style from './component-management-card.style';
import type { ManagementCardProps } from './component-management-card.contracts';

export function ManagementCard(props: ManagementCardProps) {
  const { title, description, icon, active, onClick } = props;
  const { t } = useTranslation();
  return (
    <style.Container>
      <style.Left>
        <style.IconBox>
          <style.CustomIconButtom onClick={() => onClick()} className={active ? 'active' : 'blocked'}>
            <Icon path={icon} color="white" size={1} />
          </style.CustomIconButtom>
        </style.IconBox>
        <style.Status>{active ? t('components.component_management_card.active') : t('components.component_management_card.blocked')}</style.Status>
      </style.Left>
      <style.Right>
        <style.Title>{title}</style.Title>
        <style.Description>{description}</style.Description>
      </style.Right>
    </style.Container>
  );
}
