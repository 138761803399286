import { EuiBadge, EuiBetaBadge, EuiFormRow } from '@elastic/eui';
import type { FlattenSimpleInterpolation } from 'styled-components';
import styled, { css } from 'styled-components';
import { ConstantColors } from 'utilities';
import type { WrapperButtonIconProps, StyledBadgeProps } from './ComponentTableSim.contracts';

export const TableContainer = styled.div`
  span {
    font-size: 12px;
    font-weight: 500;
    color: ${ConstantColors.ITALY};
  }

  .euiTableCellContent {
    padding: 5.5px 8px;
  }
`;

export const StyledBetaBadge = styled(EuiBetaBadge)`
  color: ${ConstantColors.CHILE} !important;
  text-decoration: underline;
  font-weight: 700 !important;
  font-size: 10px !important;
  padding: 0 10px;
  min-width: 70px;
`;

export const TextCell = styled.p`
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: inherit;
  line-height: 15px;
  color: #67758d;
`;

export const StyledBadge = styled(EuiBadge)<StyledBadgeProps>`
  span {
    color: #fff !important;
  }
  text-transform: uppercase;
  text-align: center;
  height: 19px;
  width: 100%;
  max-width: 150px;
  font-weight: 500;
  ${({ status }) => getBadgeBackground(status)};
`;

export const BadgeAdornment = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 70px;

  span {
    font-weight: 700;
    color: #343741;
    min-width: 70px;
  }

  svg {
    fill: #979797;
    width: 13px;
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    transform: scaleX(-1);
  }
`;

export const WrapperButtonIcon = styled.div<WrapperButtonIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 29px;
  height: 29px;

  margin: 0 4px;
  border-radius: 50%;
  background-color: ${({ disabled }) => (disabled ? '#EBECEE' : '#f3f6fb')};
  cursor: pointer;
  transition: 0.3s;

  &:hover {
    background-color: ${ConstantColors.BRUNEI};
    transition: 0.3s;
  }

  ${({ disabled }) => isDisabled(disabled)}
`;

export const FormRowPassword = styled(EuiFormRow)`
  max-width: 100% !important;
  margin-bottom: 48px;
`;

const getBadgeBackground = (type: string): FlattenSimpleInterpolation | undefined => {
  const objectStyle = {
    LOCK: css`
      background: #34b56f !important;
    `,
    UNLOCK: css`
      background: #34b56f !important;
    `,
    PENDING: css`
      background: ${ConstantColors.ANGOLA} !important;
    `,
    DELETED: css`
      background: #ff1100 !important;
    `,
  }[type];

  return objectStyle;
};

const isDisabled = (disabled: boolean | undefined): FlattenSimpleInterpolation => {
  return disabled
    ? css`
        opacity: 0.3;

        &:hover {
          background-color: ${ConstantColors.IRAQ};
          cursor: inherit;
        }
      `
    : css``;
};
