import 'translations';
import { RootRoutesComponent } from 'modules/core/routes';
import * as style from './ComponentApplication.style';
import { ThemeLocalProvider } from 'modules/core/contexts/theme';

export function Application() {
  return (
    <ThemeLocalProvider>
      <style.GlobalStyle />
      <RootRoutesComponent />
    </ThemeLocalProvider>
  );
}
