import { createContext, useContext, useState } from 'react';
import type { DataUsageContextProps, DataUsageFilterProps, DataUsageProviderProps } from './DataUsageProvider.contracts';
import moment from 'moment';
import { useQuery } from 'hooks/use-query';

export const DataUsageContext = createContext<DataUsageContextProps>({} as DataUsageContextProps);
export const useDataUsageContext = (): DataUsageContextProps => useContext(DataUsageContext);

export const DataUsageProvider = ({ children }: DataUsageProviderProps) => {
  const query = useQuery();
  const initialState: DataUsageFilterProps = {
    groups: [],
    startDate: moment().utcOffset(0).subtract(30, 'days').format('YYYY-MM-DD'),
    endDate: moment().utcOffset(0).format('YYYY-MM-DD'),
    deviceId: query.get('device_id') || undefined,
  };

  const [filter, setFilter] = useState<DataUsageFilterProps>(initialState);

  const applyFilter = (newFilter: Partial<DataUsageFilterProps>) => {
    setFilter((prevFilter) => ({
      ...prevFilter,
      ...newFilter,
    }));
  };

  return <DataUsageContext.Provider value={{ filter, applyFilter }}>{children}</DataUsageContext.Provider>;
};
