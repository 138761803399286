import type { SliderSettings } from 'components/generic-slider/component-generic-slider';
import { ComponentSlider } from 'components/generic-slider/component-generic-slider';
import { NewsCard } from '../news-card/news-card';
import * as style from './news-slider.style';
import { news } from '../mock';
import type { HistoryTranslate } from '../page-home.contracts';

const settings: SliderSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  speed: 1000,
  slidesPerRow: 3,
  slidesToShow: 1,
  touchThreshold: 100,
};

export function NewsSlider({ t, history }: HistoryTranslate) {
  return (
    <style.Wrapper data-testid="page__home-news-slider">
      <ComponentSlider settings={settings}>
        {news.map((item) => {
          return (
            <NewsCard
              key={item.link}
              is_beta={item.is_beta}
              is_new_feature={item.is_new_feature}
              image={item.img}
              date={item.date}
              title={item.title}
              text={item.text}
              link={item.link}
              history={history}
              t={t}
            />
          );
        })}
      </ComponentSlider>
    </style.Wrapper>
  );
}
