/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import type { ConditionalSlotComponentProps } from './ConditionalSlotComponent.contracts';

const defaultProps = {
  fallback: <></>,
};

export const ConditionalSlotComponent = ({ renderIf, fallback, children }: ConditionalSlotComponentProps): ReactElement => {
  return <>{renderIf ? children : fallback}</>;
};

ConditionalSlotComponent.defaultProps = defaultProps;
