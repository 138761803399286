export const ConstantResource = {
  INSIGHTS: {
    PAGE: 'insights',
    BATTERY: 'insights.battery',
    SIGNALS: 'insights.signals',
  } as const,
  DEVICES: {
    ADVANCED_SEARCH: 'devices.new.advanced_search',
    REMOTE_VIEW: 'devices.device_actions.remote_view',
    QUARANTINE_MODE: 'devices.device_actions.quarantine_mode',
    BULK_GET_PHONE_NUMBER: 'devices.device_actions.capture_phone_number',
  } as const,
  ANDROID_ENTERPRISE: {
    CUSTOM_MESSAGE: 'android_enterprise.custom_message',
    PLAY_INTEGRITY: 'android_enterprise.play_integrity',
  } as const,
  POLICIES: {
    OFF_LIMIT: 'android_policies.policy_tabs.fm_wp_work_profile_off_limit',
    BLOCK_LIST: 'android_policies.policy_tabs.fm_wp_app_allow_block_list',
  } as const,
  DASHBOARD: {
    INVENTORY: 'dashboard.inventory',
    ALERTS: 'dashboard.alerts',
    BATTERY_HEALTH: 'dashboard.battery_health',
    STATS_APPS: 'dashboard.stats_apps',
    STATS_BATTERY: 'dashboard.stats_battery',
    STATS_MEMORY: 'dashboard.stats_memory',
    STATS_STORAGE: 'dashboard.stats_storage',
    STATS_OS_MODELS: 'dashboard.stats_os_models',
  } as const,
  CERTIFICATES: {
    AUTHORITY: 'groups.group_policies.certificates.authority',
    IDENTITY: 'groups.group_policies.certificates.identity',
  } as const,
} as const;
