import { useEffect, useMemo, useState } from 'react';
import { ComponentButton, ComponentDateRange, ComponentLoader, ComponentTabs } from 'modules/core/components';
import type { TabID, Tabs } from 'modules/core/components/ComponentTabs/ComponentTabs.contracts';
import * as style from './ComponentIframeSwitch.style';
import type { Filters, FiltersChangeTypes, IframesInfoBody, IframeTablesUrls, Options } from './ComponentIframeSwitch.contracts';
import { ComponentComboBox } from 'components';
import { useTranslation } from 'react-i18next';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { useQuery } from 'hooks/use-query';
import { ConstantLanguages } from 'utilities';
import i18next from 'i18next';
import moment from 'moment';
import type { PickerType } from 'modules/core/components/ComponentDateRange/ComponentDateRange.contracts';
import type { ComboBoxOption } from 'components/combo-box/component-combo-box.contracts';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';

export function ComponentIframeSwitch() {
  const query = useQuery();
  const [language] = useState<string>(query.get('language') || ConstantLanguages.PT_BR);
  const { t } = useTranslation('translation', { keyPrefix: 'chromeOSReport' });
  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const [selectedTab, setSelectedTab] = useState<TabID>('general');

  const HagalazServices = useMemo(() => new ServicePulsusHagalaz(), []);

  const [filters, setFilters] = useState<Filters>({
    groupId: [],
    initialDate: moment().subtract(7, 'days'),
    endDate: moment(),
  });

  const [groupsOptions, setGroupsOptions] = useState<Options[]>([]);

  const [iframesInfo, setIframesInfo] = useState<IframesInfoBody>({
    inventory: {
      loading: true,
      url: '',
      label: t('tabsMenu.inventory'),
    },
    use: {
      loading: true,
      url: '',
      label: t('tabsMenu.use'),
    },
    location: {
      loading: true,
      url: '',
      label: t('tabsMenu.location'),
    },
  });

  const tabs: Tabs = [
    {
      id: 'general',
      disabled: false,
      name: t('tabsMenu.general'),
    },
    {
      id: 'inventory',
      disabled: false,
      name: t('tabsMenu.inventory'),
    },
    {
      id: 'use',
      disabled: false,
      name: t('tabsMenu.use'),
    },
    {
      id: 'location',
      disabled: false,
      name: t('tabsMenu.location'),
    },
  ];

  function changeFilters(type: FiltersChangeTypes, value: PickerType | ComboBoxOption[]) {
    setFilters((prevState) => ({ ...prevState, [type]: value }));
  }

  async function getIframesURLs() {
    HagalazServices.getChromeOSMetabaseTables(
      moment(filters.initialDate).format('YYYY-MM-DD'),
      moment(filters.endDate).format('YYYY-MM-DD'),
      filters.groupId
    ).then((urls: IframeTablesUrls) => {
      setIframesInfo((prevState) => ({
        ...prevState,
        inventory: { ...prevState.inventory, url: urls.inventory },
        use: { ...prevState.use, url: urls.usage },
        location: { ...prevState.location, url: urls.locations },
      }));
    });
  }

  function changeSelectedTab(id: TabID) {
    const scrollLocation = document.location.toString().split('#')[0];
    document.location = `${scrollLocation}#TabElements`;
    if (selectedTab !== 'general') {
      setIframesInfo((prevState) => ({
        ...prevState,
        inventory: { ...prevState.inventory, loading: selectedTab !== 'inventory' },
        use: { ...prevState.use, loading: selectedTab !== 'use' },
        location: { ...prevState.location, loading: selectedTab !== 'location' },
      }));
    }
    setSelectedTab(id);
  }

  function filterDatabaseTables() {
    getIframesURLs();
  }

  async function getFilterGroups() {
    const auxGroups = await serviceLoki.getChromeReportGroups();
    auxGroups.forEach((item) => groupsOptions.push({ id: `${item.id}`, label: item.name }));
    setGroupsOptions([...groupsOptions]);
  }

  useEffect(() => {
    getFilterGroups();
    getIframesURLs();
    i18next.changeLanguage(language);
  }, []);

  return (
    <>
      <style.TablesHeader>
        <ComponentTabs tabs={tabs} selectedTab={selectedTab} onClick={(id: TabID) => changeSelectedTab(id)} />
        <style.HeaderFilters>
          <ComponentComboBox
            className="reportDeviceGroups"
            label={`${t('buttons.labels.groups')}:`}
            placeholder={t('buttons.placeholder.groups')}
            onChange={(e) => changeFilters('groupId', e)}
            fullWidth
            options={groupsOptions}
            selectedOptions={filters.groupId}
          />
          <ComponentDateRange
            label={`${t('buttons.labels.date')}:`}
            className="reportDeviceDateRange"
            language={language}
            initialDate={filters.initialDate}
            endDate={filters.endDate}
            onChangeInitialDate={(e) => changeFilters('initialDate', e)}
            onChangeEndDate={(e) => changeFilters('endDate', e)}
          />
          <ComponentButton onClick={() => filterDatabaseTables()} label={t('buttons.labels.search')} />
        </style.HeaderFilters>
      </style.TablesHeader>
      <style.Tabs id="TabElements">
        {Object.keys(iframesInfo).map((key) => (
          <>
            {['general', key].includes(selectedTab as string) && (
              <style.Tab>
                {selectedTab === 'general' && <style.TabTitle>{iframesInfo[key].label}</style.TabTitle>}
                <ComponentLoader isLoading={iframesInfo[key].loading}>
                  <iframe
                    id={`${key}IframeChromeReport`}
                    src={iframesInfo[key].url}
                    title={`${key}Iframe`}
                    onLoad={() => {
                      setIframesInfo((prevState) => ({ ...prevState, [key]: { ...prevState[key], loading: false } }));
                    }}
                  />
                </ComponentLoader>
              </style.Tab>
            )}
          </>
        ))}
      </style.Tabs>
    </>
  );
}
