import { ComponentPulsusInsightsSignalsContent } from 'components/pulsus-insights-batteries-signals/component-pulsus-insights-signals-content';
import * as style from './embedded-pulsus-signals.style';

export function EmbeddedPulsusSignals() {
  return (
    <style.Container>
      <ComponentPulsusInsightsSignalsContent mapHeight="100vh" paddingLeft="0px" />
    </style.Container>
  );
}
