import styled from 'styled-components';
import { EuiForm, EuiFormRow, EuiButton, EuiCard, EuiText } from '@elastic/eui';
import { ConstantColors } from 'utilities';

export const Card = styled(EuiCard)`
  width: 500px !important;
  flex-grow: 0 !important;
  padding: 85px 50px ${({ success }) => (success ? '30px' : '85px')} 50px !important;

  @media (max-height: 1000px) {
    width: 480px !important;
    padding: 75px 40px ${({ success }) => (success ? '30px' : '75px')} 40px !important;
  }

  & .euiCard__children {
    margin: 0 !important;
  }
`;

export const Form = styled(EuiForm)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormTitle = styled.h1`
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 40px;
  margin-bottom: 10px;
  color: ${ConstantColors.ARGENTINA};

  @media (max-height: 1000px) {
    margin-bottom: 5px;
  }
`;

export const FormDescription = styled.p`
  width: 100%;
  text-align: left;
  font-style: normal;
  font-weight: 500;
  font-size: 15.1636px;
  line-height: 18px;
  margin-bottom: 65px;
  color: ${ConstantColors.GREECE};
`;

export const FormRow = styled(EuiFormRow)`
  margin-bottom: 30px;
`;

export const FormRecoverPasswordButton = styled(EuiButton)`
  margin-bottom: 25px;
  min-height: 40px !important;
`;

export const FormBackLoginLink = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  font-size: 14px;
  line-height: 21px;
`;

export const FormSuccessContainer = styled.div`
  display: ${({ success }) => (success ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  height: 105px;
  text-align: left;
  margin-top: 30px;
  background: ${ConstantColors.ZAMBIA};
`;

export const FormSuccessTitleContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const FormSuccessTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  color: ${ConstantColors.URUGUAY};
`;

export const FormSuccessText = styled(EuiText)`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
`;
