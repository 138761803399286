export type EventRegisterType = 'INSIDE' | 'OUTSIDE';

export type BlockDeviceServerStatus = 'Bloqueado' | 'Desbloqueado' | '-';

export class Register {
  public deviceId: number;
  public event: EventRegisterType;
  public groupName: string;
  public blockDevice: boolean | '-';
  public registeredAt: Date | null;
  public userName: string;
  public fence: string;

  constructor(data: Register.Server) {
    this.deviceId = data.device_id;
    this.event = data.event;
    this.groupName = data.group_name;
    this.userName = data.user_name;
    this.blockDevice = this.formatBlockDevice(data.block_device);
    this.fence = data.area_name;
    this.registeredAt = data?.registered_at ? new Date(data.registered_at) : null;
  }

  formatBlockDevice(status: BlockDeviceServerStatus) {
    if (status === 'Bloqueado') return true;
    if (status === 'Desbloqueado') return false;

    return '-';
  }
}

export namespace Register {
  export type Server = {
    area_name: string;
    block_device: BlockDeviceServerStatus;
    device_id: number;
    event: EventRegisterType;
    group_name: string;
    registered_at: string;
    user_name: string;
  };
}
