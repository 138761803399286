import type { RouteObject } from 'react-router-dom';
import { PageAppsList, PageDevices } from '../pages';
import DashboardPage from '../pages/dashboard/DashboardPage';
import { Suspense, lazy } from 'react';
import { LoadingFallbackComponent } from 'modules/core/components/LoadingFallback/LoadingFallbackComponent';
import { MapsProvider } from '../contexts/maps';
import { RouteGuardComponent } from 'modules/core/components/RouteGuard/RouteGuardComponent';
import { UserProvider } from 'modules/core/contexts/user';

const MapsPage = lazy(async () => import('modules/operational/pages/maps/MapsPage'));

export const OperationalDefaultRoutes: RouteObject[] = [
  {
    path: 'applications',
    element: <PageAppsList />,
  },
  {
    path: 'device-management',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <RouteGuardComponent>
          <UserProvider>
            <PageDevices />,
          </UserProvider>
        </RouteGuardComponent>
      </Suspense>
    ),
  },
  {
    path: 'dashboard',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <DashboardPage />
      </Suspense>
    ),
  },
  {
    path: 'maps',
    element: (
      <Suspense fallback={<LoadingFallbackComponent />}>
        <MapsProvider>
          <MapsPage />
        </MapsProvider>
      </Suspense>
    ),
  },
];
