type DollarProps = {
  width?: number;
  height?: number;
  color?: string;
  test_id?: string;
};

export function IconDollar({ width = 12, height = 21, color = '#DE5B25', test_id = 'icons__dollar' }: DollarProps) {
  return (
    <svg data-testid={test_id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.46 9.217c-2.676-.689-3.537-1.4-3.537-2.509 0-1.271 1.191-2.158 3.183-2.158 2.098 0 2.876.992 2.947 2.45h2.605c-.082-2.007-1.32-3.85-3.784-4.445V0H4.338v2.52C2.05 3.01.212 4.48.212 6.732c0 2.695 2.252 4.036 5.54 4.818 2.947.7 3.537 1.727 3.537 2.812 0 .805-.578 2.088-3.183 2.088-2.428 0-3.383-1.073-3.513-2.45H0c.141 2.555 2.075 3.99 4.338 4.468V21h3.536v-2.508C10.173 18.06 12 16.742 12 14.35c0-3.313-2.864-4.445-5.54-5.133Z"
        fill={color}
      />
    </svg>
  );
}
