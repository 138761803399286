/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useQuery } from 'hooks/use-query';
import i18next from 'i18next';
import { useNavigate } from 'react-router-dom';
import { EuiOverlayMask } from '@elastic/eui';
import { ConstantRoutes, ConstantLanguages } from 'utilities';
import { useTranslation } from 'react-i18next';
import { ChartFilterType } from 'pages/pulsus-insights-batteries/chart-filter-type';
import { ChartFilterDays } from 'pages/pulsus-insights-batteries/chart-filter-days';
import { ChartFilterRangeTime } from 'pages/pulsus-insights-batteries/chart-filter-range-time';
import { ComponentGroupsSelect } from 'components/groups-select/components-groups-select';
import type { ComponentsBatteriesFilterModalProps } from 'components/pulsus-insights-batteries-content/component-pulsus-insights-batteries.contracts';
import * as style from './components-batteries-filter-modal.style';

export function ComponentsBatteriesFilterModal({
  route,
  onClose,
  selected_group_ids,
  getFilterSelect,
  chartType,
  handleChartTypeChange,
  chartDays,
  handleChartDaysChange,
  range,
  handleRangeChange,
  loading,
  message,
  error,
  groups,
  set_groups,
  selection,
  set_selection,
}: ComponentsBatteriesFilterModalProps) {
  const history = useNavigate();
  const { t } = useTranslation();
  const urlChartRoute = route || ConstantRoutes.PULSUS_INSIGHTS_BATTERIES.path;
  const [can_proceed, set_can_proceed] = useState(false);

  const query = useQuery();

  const language = query.get('language') || ConstantLanguages.PT_BR;

  useEffect(() => {
    i18next.changeLanguage(language);
  }, []);

  const handleRedirect = () => {
    onClose();

    history({
      pathname: urlChartRoute,
      search: `?groups=${selection?.map((group: any) => group?.id).toString()}`,
    });

    if (selected_group_ids.length) {
      getFilterSelect();
    }
  };

  const handleOnClose = () => {
    onClose();
  };

  useEffect(() => {
    set_can_proceed(selection?.length !== 0);
  }, [selection]);

  return (
    <EuiOverlayMask data-testid="component_filter_modal">
      <style.Modal onClose={handleOnClose}>
        <style.Content>
          <style.Title data-testid="CBFM__title">{t('pages.pulsus_insights_batteries.modal.title')}</style.Title>
          <style.Subtitle data-testid="CBFM__title">{t('pages.pulsus_insights_batteries.modal.subtitle')}</style.Subtitle>
          <style.SearchGroupsContainer>
            <ComponentGroupsSelect groups={groups} set_groups={set_groups} loading={loading} message={message} set_selection={set_selection} />
          </style.SearchGroupsContainer>
          <style.ChartFilterContainer>
            <div className="type_container">
              <ChartFilterType chartType={chartType} handleChartTypeChange={handleChartTypeChange} />
            </div>
            <div className="days_container">
              <ChartFilterDays chartDays={chartDays} handleChartDaysChange={handleChartDaysChange} />
            </div>
            <div className="range_container">
              <ChartFilterRangeTime range={range} handleRangeChange={handleRangeChange} />
            </div>
          </style.ChartFilterContainer>
          <style.Footer>
            {selection?.length > 10 && (
              <style.Error title={`${t('pages.pulsus_insights_batteries.modal.error_title')}`} color="warning" iconType="alert">
                <p>{t('pages.pulsus_insights_batteries.modal.error_text')}</p>
              </style.Error>
            )}
            {error && (
              <style.Error title={error} color="warning" iconType="alert">
                <p>{error}</p>
              </style.Error>
            )}
            <style.Button
              data-testid="CBFM__button-add-selected-groups"
              fill
              disabled={!can_proceed || selection?.length > 10}
              onClick={handleRedirect}
            >
              {t('pages.pulsus_insights_batteries.modal.button')}
              <span>{` (${selection?.length})`}</span>
            </style.Button>
          </style.Footer>
        </style.Content>
      </style.Modal>
    </EuiOverlayMask>
  );
}
