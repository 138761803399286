import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  padding: 40px 80px;
`;

export const LeftBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
  width: 40%;
`;
export const RightBox = styled.div`
  width: 60%;
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

export const InfoBox = styled.div``;

export const Label = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;

export const Value = styled.div`
  font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  color: #343741;
`;

export const PanelBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const PanelTitle = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;

export const PanelContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 600px;
  gap: 10px;
  .item {
    width: 23%;
  }
`;

export const MapBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const MapTitle = styled.p`
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #1a1c21;
`;

export const MapDescription = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  .euiTextColor--default {
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #343741;

    &.bold {
      font-weight: 700;
    }
  }
`;

export const MapStatus = styled.div`
  .euiTextColor--default {
    font-weight: 300;
    font-size: 14px;
    line-height: 21px;
    color: #343741;

    &.bold {
      font-weight: 700;
    }
  }
`;
