import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import * as style from './modal-admin.style';

export function ModalAdmin() {
  const { t } = useTranslation();

  return (
    <style.ModalContainer data-testid="modal-admin">
      <style.ModalSection>
        <h3>{t('components.component_admin_modal.superadmin_section.superadmin_title')}</h3>
        <nav>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.client_add')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.tenant_list')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.watch_tower')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.highlights')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.consolidated_invoices')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.server_LDAP')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.superadmin_section.scheduled_tasks')}</span>
          </Link>
        </nav>
      </style.ModalSection>
      <style.SeparationLine />

      <style.ModalSection>
        <h3>{t('components.component_admin_modal.admin_section.admin_title')}</h3>
        <nav>
          <Link to="/">
            <span>{t('components.component_admin_modal.admin_section.administrators')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.admin_section.authentication')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.admin_section.audit_logs')}</span>
          </Link>
          <Link to="/">
            <span>{t('components.component_admin_modal.admin_section.themes')}</span>
          </Link>
        </nav>
      </style.ModalSection>
      <style.SeparationLine />

      <style.ModalSection>
        <h3>{t('components.component_admin_modal.admin_revenues.revenues_title')}</h3>
        <nav>
          <Link to="/">
            <span>{t('components.component_admin_modal.admin_revenues.active_devices_history')}</span>
          </Link>
        </nav>
      </style.ModalSection>
    </style.ModalContainer>
  );
}
