import type { Administrator } from 'modules/core/entities/Administrator/Administrator.entity';
import type { Tenant } from 'modules/core/entities/Tenant/Tenant.entity';
import type { ReactNode } from 'react';

export type UserProviderProps = {
  children: ReactNode;
};

export type UserContextProps = {
  administrator: Administrator;
  tenant: Tenant;
  featureFlags: string[];
  updateLogo: (newLogoUrl: string) => void;
};

export enum ActionType {
  UPDATE_ADMINISTRATOR_INFOS = 'UPDATE_ADMINISTRATOR_INFOS',
  UPDATE_TENANT_INFOS = 'UPDATE_TENANT_INFOS',
  UPDATE_LOGO = 'UPDATE_LOGO',
  UPDATE_FEATURE_FLAGS = 'UPDATE_FEATURE_FLAGS',
}

export type InitialState = {
  administrator: Administrator;
  tenant: Tenant;
  featureFlags: string[];
};

export type ChangeAdministratorInfos = {
  type: ActionType.UPDATE_ADMINISTRATOR_INFOS;
  payload: Administrator;
};

export type ChangeLogo = {
  type: ActionType.UPDATE_LOGO;
  payload: string;
};

export type ChangeTenantInfos = {
  type: ActionType.UPDATE_TENANT_INFOS;
  payload: Tenant;
};

export type UpdateFeatureFlags = {
  type: ActionType.UPDATE_FEATURE_FLAGS;
  payload: string[];
};

export type Action = ChangeAdministratorInfos | ChangeTenantInfos | ChangeLogo | UpdateFeatureFlags;
