import { ComponentBreadcrumb, ComponentToast, DialogComponent } from 'components';
import { ColoredChromeOSIcon } from 'icons';
import { HelperAuthentication } from 'helpers';
import { Tabs, Tab, IconButton } from '@mui/material';
import Icon from '@mdi/react';
import {
  mdiAccountCheck,
  mdiAccountSupervisorCircle,
  mdiBug,
  mdiCached,
  mdiCellphoneLock,
  mdiCellphoneRemove,
  mdiCircle,
  mdiDelete,
  mdiLockOpen,
  mdiMessage,
  mdiPencil,
} from '@mdi/js';
import { useEffect, useMemo, useState } from 'react';
import { EuiTextColor } from '@elastic/eui';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/pt-br';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import type { Toast } from 'components/toast/component-toast.contracts';
import { ServicePulsusTyr } from 'services/ServicePulsusTyr';
import type { JWTPostMessage } from 'modules/management/embbeded/SimManagement/EmbbededSimManagement.contracts';
import { useTranslation } from 'react-i18next';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import { ChromeBreadcrumbs } from '../chromeBreadcrumbs';
import * as style from './page-chromeOS-device.style';
import type { TabPanelProps, DeviceInfos, DeviceInformationInfo, State, EmbeddedDataProps } from './page-chromeOS-device.contracts';
import { InformationTab, ApplicationsTab } from './page-chromeOS-device-tabs/index';
import { FormatDevicePopup } from './popups/popup-format-device';
import { UnlinkDevicePopup } from './popups/popup-unlink-device';

export function PageChromeOSDevice({ embeddedData, embeddedOnEvent }: EmbeddedDataProps) {
  const location = useLocation().state! as unknown as State;
  const id = embeddedData?.deviceId || location?.id;
  const hist = useNavigate();
  const [status] = useState(true);
  const [identifier, setIdentifier] = useState('');
  const [expandOpen, setExpandOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [modalOpen, setModalOpen] = useState(false);
  const [unlinkModalOpen, setUnlinkModalOpen] = useState(false);
  const [loginEmail, setLoginEmail] = useState('');
  const [password, setPassword] = useState('');
  const [toast, setToast] = useState<Toast[]>([]);
  const [device, setDevice] = useState<DeviceInfos | null>({});
  const [deviceInfo, setDeviceInfo] = useState<DeviceInformationInfo>({});

  const TyrService = useMemo(() => new ServicePulsusTyr(), []);

  const { t } = useTranslation();
  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const footer = (
    <style.Footer>
      <style.LinkButton
        onClick={() => {
          setUnlinkModalOpen(false);
          setPassword('');
        }}
      >
        {t('pages.device_management.footer_cancel_button')}
      </style.LinkButton>
      <style.Button
        onClick={() => {
          setUnlinkModalOpen(false);
          setPassword('');
          deprovisionDevice();
        }}
      >
        {t('pages.device_management.footer_remove_button')}
      </style.Button>
    </style.Footer>
  );

  function TabPanel(props: TabPanelProps) {
    const { children, value, index } = props;

    return (
      <div hidden={value !== index} id={`Tab_id_${index}`}>
        {value === index && <>{children}</>}
      </div>
    );
  }

  function getFormatDate(date: Date | null) {
    let formatDate = date ? moment(date).format('LL') : null;
    formatDate = formatDate && moment(date).format('D').length < 2 ? `0${formatDate}` : formatDate;
    return formatDate;
  }

  async function getInfos() {
    const data = await serviceLoki.getDeviceDetailsById(id);
    if (data && Object.keys(data).length > 0) {
      const lastUpdate = getFormatDate(data.updated_at);

      const deviceInfos: DeviceInfos = {
        mac: data.mac_address || '-',
        serialNumber: data.serial_number || '-',
        cpuModel: data.cpu_info?.name || '-',
        SO: data.platform || '-',
        OSVersion: data.os_version || '-',
        model: data.model || '-',
        groupName: data.group?.name || '-',
        lastUpdate: lastUpdate || '-',
        plataformVersion: data.agent_specific_version || '-',
        managementMode: data.management_mode || '-',
        lastSync: data.last_contact_at ? moment(data.last_contact_at).fromNow() : '-',
      };

      const inscriptionDate = getFormatDate(data.activated_at);
      const rebootDate = getFormatDate(data.last_contact_at);

      const informationTabInfos = {
        inscriptionDate: inscriptionDate || '-',
        rebootDate: rebootDate || '-',
        user: data.user?.first_name || '-',
        id,
      };

      setIdentifier(data.identifier);
      setDevice((prev) => {
        return { ...prev, ...deviceInfos };
      });
      setDeviceInfo((prev) => {
        return { ...prev, ...informationTabInfos };
      });
    }
  }

  function getLoginTokenEmail() {
    const token = localStorage.getItem('@jwt_token');
    const decodedJwt = HelperAuthentication.decodeJwtFrom(token) as JWTPostMessage;
    setLoginEmail(decodedJwt.email);
  }

  function getLinkedToken() {
    const storage = localStorage.getItem('chromeOS_linked_account');
    if (storage) {
      const token = JSON.parse(storage)?.access_token;
      return token;
    }
    setToast([
      {
        title: t('pages.device_management.error_not_auth'),
        color: 'danger',
        iconType: 'faceSad',
        id: '3',
        text: t('pages.device_management.error_not_auth_description'),
      },
    ]);
    return false;
  }

  async function checkCredentials() {
    const validLogin = await servicePulsusWeb.checkLoginInformations(loginEmail, password);
    if (validLogin) {
      return true;
    }
    requestMessage(false, t('pages.device_management.error_incorrect_password'), t('pages.device_management.error_incorrect_password_description'));
    return false;
  }

  function requestMessage(success: boolean, title: string, text: string) {
    setToast([
      {
        title,
        color: success ? 'success' : 'danger',
        iconType: success ? 'faceHappy' : 'faceSad',
        id: '1',
        text,
      },
    ]);
  }

  async function sendCommandToDevice(command: string) {
    const validLogin = await checkCredentials();
    if (validLogin) {
      const token = embeddedData?.token || getLinkedToken();
      if (token) {
        const req = await TyrService.runCommand(identifier, command, embeddedData?.customerId);
        if (req) {
          requestMessage(
            true,
            t('pages.device_management.success_executed_command'),
            t('pages.device_management.success_executed_command_description')
          );
        } else {
          requestMessage(false, t('pages.device_management.error_executed_command'), t('pages.device_management.error_executed_command_description'));
        }
      }
    }
  }

  async function deprovisionDevice() {
    const validLogin = await checkCredentials();
    if (validLogin) {
      const token = embeddedData?.token || getLinkedToken();
      if (token) {
        const req = await TyrService.deprovisionDevice(identifier, embeddedData?.customerId);
        if (req) {
          requestMessage(
            true,
            t('pages.device_management.success_deprovisioned_device'),
            t('pages.device_management.success_deprovisioned_device_description')
          );
        } else {
          requestMessage(
            false,
            t('pages.device_management.error_deprovisioned_device'),
            t('pages.device_management.error_deprovisioned_device_description')
          );
        }
      }
    }
  }

  useEffect(() => {
    if (id) {
      getInfos();
      getLoginTokenEmail();
    } else if (!id && !embeddedOnEvent) {
      hist('/device-management');
    }
  }, [embeddedData]);

  return (
    <>
      <style.Container>
        <style.BreadcrumbBox>
          {!embeddedOnEvent && <ComponentBreadcrumb items={ChromeBreadcrumbs.PULSUS_CHROME_OS_DEVICE.breadcrumbs} />}
          <style.IconGroup style={embeddedOnEvent ? { width: '100%', justifyContent: 'flex-end' } : {}}>
            <section>
              <IconButton className="disabled" title={t('pages.device_management.advanced_identify')}>
                <Icon path={mdiAccountSupervisorCircle} size={1} color="black" />
              </IconButton>
              <IconButton className="disabled" title={t('pages.device_management.advanced_log')}>
                <Icon path={mdiBug} size={1} color="black" />
              </IconButton>
              <IconButton className="disabled" title={t('pages.device_management.advanced_update')}>
                <Icon path={mdiCached} size={1} color="black" />
              </IconButton>
              <IconButton className="disabled" title={t('pages.device_management.advanced_message')}>
                <Icon path={mdiMessage} size={1} color="black" />
              </IconButton>
              <IconButton className="disabled" title={t('pages.device_management.advanced_re_register')}>
                <Icon path={mdiAccountCheck} size={1} color="black" />
              </IconButton>
              <IconButton className="disabled" title={t('pages.device_management.advanced_edit')}>
                <Icon path={mdiPencil} size={1} color="black" />
              </IconButton>
            </section>
            <section>
              <IconButton className="disabled" title={t('pages.device_management.advanced_block')}>
                <Icon path={mdiCellphoneLock} size={1} color="black" />
              </IconButton>
              <IconButton className="disabled" title={t('pages.device_management.advanced_clear_password')}>
                <Icon path={mdiLockOpen} size={1} color="black" />
              </IconButton>
              <IconButton title={t('pages.device_management.advanced_data_clear')} onClick={() => setModalOpen(true)}>
                <Icon path={mdiCellphoneRemove} size={1} color="black" />
              </IconButton>
              <IconButton title={t('pages.device_management.advanced_remove')} onClick={() => setUnlinkModalOpen(true)}>
                <Icon path={mdiDelete} size={1} color="black" />
              </IconButton>
            </section>
          </style.IconGroup>
        </style.BreadcrumbBox>
        <style.Content>
          <style.HeaderInfos>
            <style.HeaderIdentifier>
              <ColoredChromeOSIcon />
              ID-
              {id}
            </style.HeaderIdentifier>
            <style.HeaderStatus>
              <Icon path={mdiCircle} title="status" size="10px" color={status ? '#3DDB85' : 'red'} />
              <p>{status ? t('pages.device_management_item.item_chrome_active') : t('pages.device_management_item.item_chrome_disable')}</p>
            </style.HeaderStatus>
            <style.LastSync>
              <EuiTextColor>{`${t('pages.device_management_item.item_chrome_last_sync')} `}</EuiTextColor>
              <EuiTextColor color="secondary">{device?.lastSync}</EuiTextColor>
            </style.LastSync>
          </style.HeaderInfos>
          <style.BodyInfos>
            <style.FixedContent>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_mac_address')}</style.Label>
                <style.Value>{device?.mac}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_serie_number')}</style.Label>
                <style.Value>{device?.serialNumber}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_cpu_model')}</style.Label>
                <style.Value>{device?.cpuModel}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_so')}</style.Label>
                <style.Value>{device?.SO}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_version')}</style.Label>
                <style.Value>{device?.OSVersion}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_model')}</style.Label>
                <style.Value>{device?.model}</style.Value>
              </style.Box>
            </style.FixedContent>
            <style.CustomContent className={!expandOpen ? 'compressed' : 'expand'}>
              <style.Box className={!expandOpen ? 'visible maxWidth' : 'visible'}>
                <style.Label>{t('pages.device_management_item.item_chrome_group')}</style.Label>
                <style.Value>{device?.groupName}</style.Value>
              </style.Box>
              {!expandOpen && (
                <style.Box className={!expandOpen ? 'visible' : 'hidden'}>
                  <style.ExpandButton iconType="plusInCircle" fill onClick={() => setExpandOpen(true)}>
                    {t('pages.device_management_item.item_chrome_configs')}
                  </style.ExpandButton>
                </style.Box>
              )}
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_configs_status')}</style.Label>
                <style.Value>{t('pages.device_management_item.item_chrome_active')}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_configs_update_os')}</style.Label>
                <style.Value>{device?.lastUpdate}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_configs_version')}</style.Label>
                <style.Value>{device?.plataformVersion}</style.Value>
              </style.Box>
              <style.Box className="maxWidth">
                <style.Label>{t('pages.device_management_item.item_chrome_configs_expiration')}</style.Label>
                <style.Value>{t('pages.device_management_item.item_chrome_configs_expiration_date')}</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>MEID/IMEI</style.Label>
                <style.Value>-</style.Value>
              </style.Box>
              <style.Box className="maxWidth">
                <style.Label>{t('pages.device_management_item.item_chrome_configs_version_firmware')}</style.Label>
                <style.Value>GOOGLE_GARG_1122993.0</style.Value>
              </style.Box>
              <style.Box>
                <style.Label>{t('pages.device_management_item.item_chrome_configs_mode')}</style.Label>
                <style.Value>{t('pages.device_management_item.item_chrome_configs_cloud')}</style.Value>
              </style.Box>
              {expandOpen && (
                <style.Box>
                  <style.ExpandButton iconType="minusInCircle" fill onClick={() => setExpandOpen(!expandOpen)}>
                    {t('pages.device_management_item.item_chrome_configs_close')}
                  </style.ExpandButton>
                </style.Box>
              )}
            </style.CustomContent>
          </style.BodyInfos>
          <style.TabsHeader>
            <Tabs value={activeTab} onChange={(e, value) => setActiveTab(value)}>
              <Tab label={t('pages.device_management_item.item_chrome_configs_info')} value={0} />
              <Tab label={t('pages.device_management_item.item_chrome_configs_apps')} value={1} />
            </Tabs>
          </style.TabsHeader>
        </style.Content>
      </style.Container>
      <style.TabsBox>
        <style.TabsContent>
          <TabPanel index={0} value={activeTab}>
            <InformationTab deviceInfo={deviceInfo} />
          </TabPanel>
          <TabPanel index={1} value={activeTab}>
            <ApplicationsTab />
          </TabPanel>
        </style.TabsContent>
      </style.TabsBox>
      <DialogComponent
        maxHeight="570px"
        height="570px"
        maxWidth="950px"
        width="950px"
        closeOnClickOutside
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <FormatDevicePopup
          onClose={() => setModalOpen(false)}
          password={password}
          onChange={(e) => setPassword(e)}
          formatDevice={(command) => sendCommandToDevice(command)}
        />
      </DialogComponent>
      <DialogComponent
        height="570px"
        maxHeight="570px"
        maxWidth="950px"
        width="950px"
        footer={footer}
        closeOnClickOutside
        open={unlinkModalOpen}
        onClose={() => setUnlinkModalOpen(false)}
      >
        <style.UnlinkModalContent>
          <UnlinkDevicePopup password={password} onChange={(e: string) => setPassword(e)} />
        </style.UnlinkModalContent>
      </DialogComponent>
      <ComponentToast toasts={toast} toastLifeTimeMs={5000} side="right" dismissToast={() => setToast([])} />
    </>
  );
}
