import type { EuiBasicTableColumn } from '@elastic/eui';
import { EuiEmptyPrompt, EuiInMemoryTable, EuiSwitch } from '@elastic/eui';
import { mdiBluetooth, mdiDeleteOutline, mdiWifi } from '@mdi/js';
import Icon from '@mdi/react';
import { IconButton } from '@mui/material';
import { ComponentPasswordField, ComponentSelectField, ComponentTextField, DialogComponent, ManagementCard, WifiManagement } from 'components';
import type { ActiveOptions } from 'components/wifi-management/component-wifi-management.contracts';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import type { NetworkBody, PolicyBody, StoreProps } from 'stores/stores.contracts';
import { useTranslation } from 'react-i18next';
import * as cardStyle from '../network-cards.style';
import type { WifiItemContent, WifiManagementProps } from './wifi-management-card.contracts';
import * as componentStyle from './wifi-management-card.style';

const style = { ...cardStyle, ...componentStyle };

export function WifiManagementCard({ emmitToast }: WifiManagementProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const store: PolicyBody | undefined = useSelector((stores: StoreProps) => stores?.chromeOS?.configuration?.policy);
  const [modalOpen, setModalOpen] = useState(false);
  const [networkForm, setNetworkForm] = useState<NetworkBody>({
    name: '',
    ssid: '',
    security: 'None',
    passphrase: '',
    hidden: false,
    autoConnect: false,
    active: true,
    device_wide: true,
  });
  const [bluetooth, setBluetooth] = useState(false);
  const [wifiStatus, setWifiStatus] = useState<ActiveOptions>('neutral');
  const [list, setList] = useState<WifiItemContent[]>([]);

  function emmitChange(bluetoothValue: boolean, networks: WifiItemContent[], mode: ActiveOptions) {
    if (store) {
      const aux: PolicyBody = JSON.parse(JSON.stringify(store));
      aux.network.networks = networks;
      aux.network.mode = mode;
      aux.device_policies.allowBluetooth = bluetoothValue;
      dispatch({ type: 'CHROMEOS_CHANGE_CONFIGURATION_POLICY', payload: aux });
    }
  }

  function changeStatus(item: WifiItemContent) {
    const index = _.findIndex(list, (o) => o.ssid === item.ssid);
    if (index >= 0) {
      const aux: WifiItemContent[] = JSON.parse(JSON.stringify(list));
      aux[index].active = !item.active;
      emmitChange(bluetooth, aux, wifiStatus);
    }
  }

  function deleteItem(item: WifiItemContent) {
    const index = _.findIndex(list, (o) => o.ssid === item.ssid);
    if (index >= 0) {
      const aux: WifiItemContent[] = JSON.parse(JSON.stringify(list));
      aux[index].delete = true;
      emmitChange(bluetooth, aux, wifiStatus);
    }
  }

  function addItem() {
    if (networkForm.ssid && _.findIndex(list, (o) => o.ssid === networkForm.ssid) < 0) {
      networkForm.name = networkForm.ssid;
      list.push(networkForm);
      emmitChange(bluetooth, list, wifiStatus);
      closeModal();
    } else if (!networkForm.ssid || !networkForm.passphrase || !networkForm.security) {
      emmitToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('components.component_wifi_management_card.error_message'),
          text: t('components.component_wifi_management_card.error_message_empty_fields'),
        },
      ]);
    } else {
      emmitToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('components.component_wifi_management_card.error_message'),
          text: t('components.component_wifi_management_card.error_message_empty_fields'),
        },
      ]);
    }
  }

  function closeModal() {
    setModalOpen(false);
    setNetworkForm({ name: '', ssid: '', security: 'None', passphrase: '', hidden: false, autoConnect: false, active: true, device_wide: true });
  }

  function changeFormText(key: string, value: string) {
    setNetworkForm((prev) => {
      const aux = JSON.parse(JSON.stringify(prev));
      aux[key] = value;
      if (key === 'security') {
        aux.passphrase = '';
      }
      return { ...prev, ...aux };
    });
  }

  function changeFormBoolean(key: string) {
    setNetworkForm((prev) => {
      const aux = JSON.parse(JSON.stringify(prev));
      aux[key] = !aux[key];
      return { ...prev, ...aux };
    });
  }

  const cols: EuiBasicTableColumn<WifiItemContent>[] = [
    {
      field: 'SSID',
      name: t('components.component_wifi_management_card.table_collumn_network_name'),
      width: '140px',
      align: 'center',
      render: (name: string, item: WifiItemContent) => {
        return (
          <style.TableNameCollumn>
            <Icon path={mdiWifi} size="13px" color="#64748F" />
            <style.TableContentText>{item.ssid}</style.TableContentText>
          </style.TableNameCollumn>
        );
      },
    },
    {
      field: 'security',
      name: t('components.component_wifi_management_card.table_collumn_network_security'),
      width: '65px',
      align: 'center',
      render: (name: string, item: WifiItemContent) => {
        return <style.TableContentText className="bold">{item.security}</style.TableContentText>;
      },
    },
    {
      field: 'status',
      name: t('components.component_wifi_management_card.table_collumn_network_status'),
      width: '125px',
      align: 'center',
      render: (name: string, item: WifiItemContent) => {
        let statusLabel = t('components.component_wifi_management_card.table_collumn_network_status_title');
        if (item.active) {
          statusLabel += ` ${t('components.component_wifi_management_card.table_collumn_network_status_allow')}`;
        } else {
          statusLabel += ` ${t('components.component_wifi_management_card.table_collumn_network_status_block')}`;
        }
        return (
          <style.TableStatusBox>
            <EuiSwitch compressed label={statusLabel} checked={item.active} onChange={() => changeStatus(item)} />
          </style.TableStatusBox>
        );
      },
    },
    {
      field: 'delete',
      name: t('components.component_wifi_management_card.table_collumn_network_delete'),
      width: '45px',
      align: 'center',
      render: (name: string, item: WifiItemContent) => {
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <IconButton title={t('components.component_wifi_management_card.table_collumn_network_erase_data')} onClick={() => deleteItem(item)}>
              <Icon path={mdiDeleteOutline} size="20px" color="#64748F" />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const [pagination] = useState({
    initialPageSize: 6,
    hidePerPageOptions: true,
  });

  const message = (
    <EuiEmptyPrompt
      title={<h3>{t('components.component_wifi_management_card.table_message_empty')}</h3>}
      titleSize="xs"
      body={t('components.component_wifi_management_card.table_message_empty_description')}
    />
  );

  const securityOptions = [
    { value: '', text: t('components.component_wifi_management_card.security_options_none') },
    { value: 'wep', text: t('components.component_wifi_management_card.security_options_none') },
    { value: 'wpa-wpa2', text: t('components.component_wifi_management_card.security_options_none') },
    { value: 'wpa-wpa2-enterprise', text: t('components.component_wifi_management_card.security_options_wpa_enterprise') },
    { value: 'dynamic-wep', text: t('components.component_wifi_management_card.security_options_dynammic') },
  ];

  function populateFields() {
    if (store) {
      setList([..._.filter(store.network.networks, (o) => !o.delete)]);
      setBluetooth(store.device_policies.allowBluetooth);
      setWifiStatus(store.network.mode);
    }
  }

  useEffect(() => {
    populateFields();
  }, [store]);

  return (
    <style.Card>
      <style.CardBody>
        <ManagementCard
          title={t('components.component_wifi_management_card.bluetooth_title')}
          description={t('components.component_wifi_management_card.bluetooth_description')}
          active={store?.device_policies?.allowBluetooth}
          icon={mdiBluetooth}
          onClick={() => {
            emmitChange(!bluetooth, list, wifiStatus);
          }}
        />
        <style.StatusBox>
          <WifiManagement
            title={t('components.component_wifi_management_card.wifi_title')}
            description={t('components.component_wifi_management_card.wifi_description')}
            active={wifiStatus}
            icon={mdiWifi}
            onClick={(e) => emmitChange(bluetooth, list, e)}
          />
        </style.StatusBox>
        <style.AddButton iconType="plusInCircle" fill onClick={() => setModalOpen(true)}>
          {t('components.component_wifi_management_card.add_wifi')}
        </style.AddButton>
      </style.CardBody>
      <EuiInMemoryTable itemId="SSID" columns={cols} pagination={pagination} message={message} items={list} />
      <DialogComponent open={modalOpen} width="678px" closeOnClickOutside onClose={() => closeModal()}>
        <style.DialogContent>
          <style.DialogTitle>{t('components.component_wifi_management_card.card_title')}</style.DialogTitle>
          <style.Form>
            <style.Body>
              <style.Line>
                <style.Field className="name">
                  <ComponentTextField
                    tootip={t('components.component_wifi_management_card.card_ssid_tooltip')}
                    label={t('components.component_wifi_management_card.card_ssid_label')}
                    value={networkForm.ssid}
                    onChange={(e) => changeFormText('ssid', e)}
                  />
                </style.Field>
                <style.Field className="security">
                  <ComponentSelectField
                    label={t('components.component_wifi_management_card.card_security_type_label')}
                    value={networkForm.security}
                    onChange={(e) => changeFormText('security', e)}
                    options={securityOptions}
                  />
                </style.Field>
              </style.Line>
              <style.Line>
                <style.Field>
                  <ComponentPasswordField
                    disabled={networkForm.security === 'None'}
                    label={t('components.component_wifi_management_card.card_password_label')}
                    value={networkForm.passphrase || ''}
                    onChange={(e) => changeFormText('passphrase', e)}
                  />
                </style.Field>
              </style.Line>
              <style.Line>
                <style.CustomSwitch
                  label={t('components.component_wifi_management_card.card_switch_hidden')}
                  checked={networkForm.hidden}
                  onChange={() => changeFormBoolean('hidden')}
                />
                <style.CustomSwitch
                  label={t('components.component_wifi_management_card.card_switch_auto_connect')}
                  checked={networkForm.autoConnect}
                  onChange={() => changeFormBoolean('autoConnect')}
                />
              </style.Line>
              <style.Actions>
                <style.DialogButton onClick={() => addItem()}>
                  {t('components.component_wifi_management_card.card_add_network_button')}
                </style.DialogButton>
              </style.Actions>
            </style.Body>
          </style.Form>
        </style.DialogContent>
      </DialogComponent>
    </style.Card>
  );
}
