import { EuiButton, EuiFieldNumber, EuiFieldText, EuiFormRow } from '@elastic/eui';
import styled from 'styled-components';

export const EditDeviceContainer = styled.div`
  min-height: 100%;
`;

export const DeviceInfo = styled.div`
  width: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: -8px 0 -8px 0;
`;

export const EditDeviceItem = styled.div`
  width: 100%;
  min-height: 88px;
  display: flex;
  align-items: center;
  justify-content: start;
  margin: -8px 0 -8px 0;
`;

export const AndroidIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: #3ddb85;
  height: 20px;
  width: 20px;
  margin-right: 13px;
`;

export const AndroidIcon = styled.img`
  height: 5px;
`;
export const ItemId = styled.h2`
  height: 8px;
  font-size: 20px;
  font-weight: 700;
  line-height: 8px;
  margin-right: 13px;
`;

export const ItemStatus = styled.h3`
  font-size: 11px;
  line-height: 10px;
  font-weight: 400;
  margin-right: 8px;
`;
export const EuiFormRowStyled = styled(EuiFormRow)`
  max-width: 600px;
  width: 100%;

  .euiFormControlLayout {
    max-width: 600px;
    width: 100%;
  }

  .euiComboBox {
    max-width: 600px;
    width: 100%;
  }

  .euiComboBox .euiComboBox__inputWrap {
    max-width: 600px;
    border-radius: 6px;
  }
`;

export const EuiFieldTextStyled = styled(EuiFieldText)`
  max-width: 600px;
  width: 100%;
`;
export const EuiFieldNumberStyled = styled(EuiFieldNumber)`
  max-width: 600px;
  width: 100%;
`;
export const ButtonsContainer = styled.div`
  width: 100%;
  max-width: 600px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const Button = styled(EuiButton)`
  min-width: 110px;
  margin-left: 10px;
  min-height: 39px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;

export const PlaceHolder = styled.div`
  position: fixed;
  height: 677px;
  width: 483px;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RequiredText = styled.p`
  margin-top: 15px;
  font-weight: 500;
`;
