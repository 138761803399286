import styled from 'styled-components';

export const GroupListContainer = styled.div`
  max-height: 416px;
  overflow: auto;
`;

export const GroupList = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: column;
  max-height: 416px;
  overflow: auto;
`;

export const GroupItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  gap: 8px;
`;

export const GroupName = styled.span`
  font-size: 14px;
  font-weight: 400;
  color: #98a2b3;
  max-width: calc(100% - 48px);
`;

export const Action = styled.button`
  all: unset;
  width: 37px;
  height: 22px;
  border-radius: 3px;
  background: #e0e5ee;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupsViewList = styled.div`
  width: 60%;
  height: 416px;
  padding: 28px 24px;
  background: #f5f5f5;
  border-radius: 13px;
  margin-top: 66px;
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const GroupListTitle = styled.p`
  color: #1a1c21;
  font-size: 15px;
  font-weight: 700;
`;
