export class ChromeReportGroup {
  status: number;

  id: number;

  work_profile_policy_name: null | string;

  group_policy_id: null | number;

  name: string;

  pin: string;

  tags: string[];

  constructor(
    status: number,
    id: number,
    work_profile_policy_name: null | string,
    group_policy_id: null | number,
    name: string,
    pin: string,
    tags: string[]
  ) {
    this.status = status;
    this.id = id;
    this.work_profile_policy_name = work_profile_policy_name;
    this.group_policy_id = group_policy_id;
    this.name = name;
    this.pin = pin;
    this.tags = tags;
  }
}
