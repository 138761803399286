import type { PostMessageOptions, ThemeOptions } from './HeaderComponent.contracts';
import { mdiDevices, mdiApps, mdiTune } from '@mdi/js';
import Icon from '@mdi/react';
import * as S from './HeaderComponent.styles';
import { IconCellComponent } from '../IconCell/IconCellComponent';
import { useTypedTranslation } from 'modules/core/hooks';
import { useEffect, useMemo, useState } from 'react';
import { useThemeContext } from 'modules/core/contexts/theme';
import type { Toast } from 'modules/core/components';
import { ComponentToast, ThemeToggleComponent } from 'modules/core/components';
import { ServicePulsusHagalaz } from 'services/ServicePulsusHagalaz/ServicePulsusHagalaz';
import type { DashboardStatus } from 'modules/operational/entities/DashboardStatus/DashboardStatus.entity';

export const HeaderComponent = () => {
  const { t } = useTypedTranslation<'dashboard'>('dashboard');

  const { changeDashboardTheme } = useThemeContext();

  const [dashboardTheme, setDashboardTheme] = useState<ThemeOptions>();
  const [status, setStatus] = useState<DashboardStatus>(null!);
  const [toast, setToast] = useState<Toast[]>([]);

  const dashboardThemeLS = localStorage.getItem('@dashboard_theme') as ThemeOptions;
  const serviceHagalaz = useMemo(() => new ServicePulsusHagalaz(), []);

  const sendMessage = (message: PostMessageOptions) => {
    window.parent.postMessage(message, process.env.REACT_APP_RUBY_LINK!);
  };

  const changeTheme = (theme: ThemeOptions) => {
    changeDashboardTheme(theme);
    sendMessage({ payloadType: 'CHANGE_THEME', body: theme });
  };

  const getStatus = async () => {
    try {
      const result = await serviceHagalaz.getDashboardStatus();
      setStatus(result);
    } catch {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          title: t('request.failed.title'),
          text: t('request.failed.text'),
          id: '1',
        },
      ]);
    }
  };

  useEffect(() => {
    if (dashboardTheme) {
      changeTheme(dashboardTheme);
    }
  }, [dashboardTheme]);

  useEffect(() => {
    if (dashboardThemeLS) {
      setDashboardTheme(dashboardThemeLS);
    }

    getStatus();
  }, []);

  return (
    <>
      <S.Container>
        <div>
          <section>
            <IconCellComponent icon={mdiDevices} value={status?.totalDevices || 0} text={t('header.devices')} />
            <IconCellComponent value={status?.activeDevices || 0} text={t('header.actives')} />
            <IconCellComponent value={status?.created || 0} text={t('header.new')} />
            <IconCellComponent value={status?.wiped || 0} text={t('header.removed')} />
            {!!status?.unenrolled && <IconCellComponent value={status?.unenrolled || 0} text={t('header.unenrolled')} />}
          </section>

          <section>
            <IconCellComponent downloadedIcon="circlesInCircles" value={status?.groups || 0} text={t('header.groups')} />
          </section>

          <section>
            <IconCellComponent icon={mdiApps} value={status?.totalApps || 0} text={t('header.apps')} />
            <IconCellComponent value={status?.active || 0} text={t('header.actives')} />
            <IconCellComponent value={status?.blocked || 0} text={t('header.blocked')} />
          </section>
        </div>
        <div>
          <ThemeToggleComponent selected={dashboardTheme || 'light'} onClick={(theme) => setDashboardTheme(theme)} />
          <S.SettingsButton onClick={() => sendMessage({ payloadType: 'OPEN_PANEL', body: 'settings' })}>
            <Icon path={mdiTune} size={1} color="#949494" />
          </S.SettingsButton>
        </div>
      </S.Container>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </>
  );
};
