import { useEffect, useState } from 'react';
import { EuiButton, EuiButtonIcon, EuiEmptyPrompt, EuiFieldNumber, EuiFieldText, EuiInMemoryTable, EuiRadio } from '@elastic/eui';
import _ from 'lodash';
import * as style from './general-card-group.style';
import { QuickSettingsCard } from '../quick-settings-card';
import { GetCardFlagsAndroid } from '../flags-label-android';
import { Flags } from '../flags';
import type { FullyManagedCardGroupProps, AppManagementItemProps } from './general-settings.contracts';

export function GeneralCardGroup(props: FullyManagedCardGroupProps) {
  const {
    cameraDisabled,
    screenCaptureDisabled,
    maxDaysWithWorkOff,
    personalPlayStoreManagement,
    onChangeBoolean,
    onChangeNumber,
    handleChangePlayStoreManagement,
  } = props;
  const [identifier, setIdentifier] = useState('');
  const [selection, setSelection] = useState([]);
  const [message] = useState(
    <EuiEmptyPrompt title={<h3>Desabilitado</h3>} titleSize="xs" body="Habilite o gerenciamento de aplicarivos para desbloquear configurações" />
  );
  const [pagination] = useState({
    initialPageSize: 5,
    pageSizeOptions: [3, 5, 10],
  });

  const [list, setList] = useState<AppManagementItemProps[]>([]);

  const options = GetCardFlagsAndroid();

  const handleChange = (value: number | number[]) => {
    onChangeNumber(value as number, 'maxDaysWithWorkOffValue');
  };

  const selectionValue = {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectable: (row: any) => row.id,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onSelectionChange: (selectionAux: any) => {
      setSelection(selectionAux);
    },
  };

  function addApp() {
    const applicationList = JSON.parse(JSON.stringify(personalPlayStoreManagement.personalApplications));
    if (_.indexOf(applicationList, identifier) < 0) {
      applicationList.push(identifier);
      handleChangePlayStoreManagement(applicationList, 'apps');
    }
  }

  function deleteApp(item: AppManagementItemProps) {
    const applicationList = JSON.parse(JSON.stringify(personalPlayStoreManagement.personalApplications));
    const index = _.indexOf(applicationList, item.id);
    applicationList.splice(index, index);
    handleChangePlayStoreManagement(applicationList, 'apps');
  }

  const cols = [
    {
      field: 'identifier',
      name: 'Identifier',
      width: '176px',
    },
    {
      field: 'permission',
      name: 'Permissão',
      width: '60px',
      render: () => {
        if (personalPlayStoreManagement.personalPlayStoreMode === 'BLOCKLIST') {
          return <div className="blockFlagCol">BLOCK</div>;
        }
        return <div className="allowFlagCol">ALLOW</div>;
      },
    },
    {
      field: 'delete',
      name: 'Excluir',
      width: '39px',
      render: (name: string, item: AppManagementItemProps) => {
        return (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <EuiButtonIcon onClick={() => deleteApp(item)} color="text" iconType="trash" />
          </div>
        );
      },
    },
  ];

  function removeSelected() {
    const applicationList = JSON.parse(JSON.stringify(personalPlayStoreManagement.personalApplications));
    selection.forEach((item: AppManagementItemProps) => {
      const index = _.indexOf(applicationList, item.id);
      applicationList.splice(index, index + 1);
    });
    handleChangePlayStoreManagement(applicationList, 'apps');
  }

  useEffect(() => {
    const auxList: AppManagementItemProps[] = [];
    personalPlayStoreManagement?.personalApplications?.forEach((item) => {
      auxList.push({ id: item, identifier: item });
    });
    setList(auxList);
  }, [personalPlayStoreManagement.personalApplications]);

  return (
    <style.Container className="fullyManaged">
      <style.Content>
        <QuickSettingsCard
          title="Permitir o uso de câmera no perfil pessoal"
          label="Câmera desabilitada no perfil pessoal"
          active={cameraDisabled}
          onChange={() => onChangeBoolean(!cameraDisabled, 'deviceCamera')}
          flags={options.deviceCameraFlags}
        >
          <style.CardDescription>
            Esta funcionalidade permite que você escolha se o usuário vai ter a possibilidade de usar a câmera no dispositivo gerenciado.
          </style.CardDescription>
        </QuickSettingsCard>
        <QuickSettingsCard
          title="Periodo de desligamento do perfil de trabalho"
          label="Gerenciar tempo que o perfil de trabalho pode ficar desligado"
          active={maxDaysWithWorkOff.active}
          onChange={() => {
            onChangeBoolean(!maxDaysWithWorkOff.active, 'maxDaysWithWorkOffActive');
          }}
          noBottomPadding
        />
        <style.SliderBox>
          <div style={{ width: '70%' }}>
            <style.FullySlider
              disabled={!maxDaysWithWorkOff.active}
              min={3}
              max={30}
              value={maxDaysWithWorkOff.value}
              onChange={(event, value) => handleChange(value)}
            />
          </div>
          <div style={{ width: '20%' }}>
            <EuiFieldNumber
              disabled={!maxDaysWithWorkOff.active}
              compressed
              min={3}
              max={30}
              value={maxDaysWithWorkOff.value}
              onChange={(e) => handleChange(Number(e.target.value))}
            />
          </div>
        </style.SliderBox>
        <style.SliderBoxWarning>
          <style.CardDescription>Período mínimo é</style.CardDescription>
          <span>3</span>
          <style.CardDescription>dias</style.CardDescription>
        </style.SliderBoxWarning>
        <style.SliderBox>
          <style.CardDescription>
            Esta funcionalidade permite que você escolha o periodo que o perfil de trabalho pode ficar desligado no device do colaborador.
          </style.CardDescription>
        </style.SliderBox>
        <style.FlagBox>
          <Flags flags={options.workProfilePeriodFlags} />
        </style.FlagBox>
        <QuickSettingsCard
          title="Permitir a Captura de tela no Perfil pessoal"
          label="Captura de tela permitido"
          active={screenCaptureDisabled}
          onChange={() => onChangeBoolean(!screenCaptureDisabled, 'screenshot')}
          flags={options.screenshotFlags}
        >
          <style.CardDescription>
            Esta funcionalidade permite que você escolha se o usuário vai ter a possibilidade de capturar a tela no dispositivo gerenciado.
          </style.CardDescription>
        </QuickSettingsCard>
      </style.Content>
      <style.Content>
        <QuickSettingsCard
          title="Gerenciar apps permitidos na Play Store pessoal"
          label="Gerenciamento da Play Store pessoal habilitado"
          active={personalPlayStoreManagement.active}
          onChange={() => handleChangePlayStoreManagement(!personalPlayStoreManagement.active, 'active')}
          flags={options.personalPlayStoreManagementFlags}
        >
          <style.CardDescription>
            Esta funcionalidade permite determinar que aplicativos podem ou não ser instalados na parte pessoal do dispositivo.
          </style.CardDescription>
        </QuickSettingsCard>
        <style.BottomContent>
          <style.ContentBox>
            <style.BoxItem>
              <EuiRadio
                style={{ width: 25 }}
                id="blockAppsList"
                disabled={!personalPlayStoreManagement.active}
                checked={personalPlayStoreManagement.personalPlayStoreMode === 'BLOCKLIST'}
                onChange={() => handleChangePlayStoreManagement('BLOCKLIST', 'mode')}
              />
              <p className="title">Block List</p>
              <p className="description">Permite a instalação de qualquer aplicativo da Play Store exceto apps definidos abaixo.</p>
            </style.BoxItem>
            <style.BoxItem>
              <EuiRadio
                style={{ width: 25 }}
                id="allowAppsList"
                disabled={!personalPlayStoreManagement.active}
                checked={personalPlayStoreManagement.personalPlayStoreMode === 'ALLOWLIST'}
                onChange={() => handleChangePlayStoreManagement('ALLOWLIST', 'mode')}
              />
              <p className="title">Allow List</p>
              <p className="description">Bloqueia a instalação de qualquer aplicativo que não esteja explicitamente definido na lista abaixo.</p>
            </style.BoxItem>
            <div className="identifierField">
              <style.Label>Digite o idenfifier do app</style.Label>
              <div className="textField">
                <EuiFieldText
                  disabled={!personalPlayStoreManagement.active}
                  compressed
                  value={identifier}
                  onChange={(e) => setIdentifier(e.target.value)}
                />
                <EuiButton
                  disabled={!personalPlayStoreManagement.active}
                  className="addButton"
                  size="s"
                  onClick={() => {
                    addApp();
                  }}
                >
                  Adicionar
                </EuiButton>
              </div>
            </div>
            <div>
              {selection.length > 0 && (
                <EuiButton
                  disabled={!personalPlayStoreManagement.active}
                  className="removeButton"
                  color="danger"
                  size="s"
                  onClick={() => {
                    removeSelected();
                  }}
                >
                  Remover Selecionados
                </EuiButton>
              )}
              <style.Label>Só será possivel instalar os aplicativos abaixo:</style.Label>
              <EuiInMemoryTable
                itemId="id"
                items={personalPlayStoreManagement.personalApplications ? list : []}
                isSelectable
                selection={selectionValue}
                columns={cols}
                pagination={pagination}
                message={message}
              />
            </div>
          </style.ContentBox>
        </style.BottomContent>
      </style.Content>
    </style.Container>
  );
}
