import { EuiButton, EuiButtonEmpty } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Container = styled.main`
  background-color: ${ConstantColors.AUSTRIA};
  min-height: 100vh;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`;

export const StepContainer = styled.section`
  padding: 30px 50px 0px 150px;
  display: flex;
  column-gap: 100px;
  width: 100%;
  max-width: 1400px;
  min-width: 1100px;
`;

export const StepLeft = styled.section`
  height: 100%;
  max-width: 500px;
  width: 37%;
`;

export const StepRight = styled.section`
  width: 50%;

  h2 {
    color: ${ConstantColors.ARGENTINA};
    margin-bottom: 20px;
    font-size: 21px;
  }

  p {
    font-size: 14px;
    max-width: 650px;
    margin-bottom: 15px;
    line-height: 18px;
  }

  .ios-text {
    max-width: 440px;
  }

  .content {
    margin-top: 35px;
  }

  .android-recommended {
    max-width: fit-content;
    position: relative;
    margin-top: 50px;

    svg {
      position: absolute;
      margin: auto;
      inset: 0;
      transform: scale(1.2);
    }
  }

  .enroll-right-section {
    a {
      line-height: 20px;
    }
  }

  .enroll-os {
    width: 295px;
    height: 167px;
    margin-bottom: 15px;

    display: flex;
    justify-content: center;
    align-items: center;

    background: ${ConstantColors.AUSTRIA};
    box-shadow: 0px 3.35302px 3.35302px rgba(0, 0, 0, 0.25);
    border-radius: 16.8453px;
  }

  .enroll-text {
    font-size: 12px;
    margin-top: 10px;
  }
`;

export const StepTitle = styled.div`
  display: flex;
  align-items: left;
  margin-top: 40px;
  font-weight: 700;
  font-size: 25.7391px;
  line-height: 28px;
  width: 450px;
  flex-direction: column;
  span {
    font-size: 12px;
    font-weight: 400;
  }
`;

export const StepButton = styled(EuiButton)`
  height: 47px;
  border: none;
  padding: 20px 17px;
  font-weight: 500;
  margin: 15px 0;
  min-width: 284px;
  max-width: 300px;
`;

export const StepButtonEmpty = styled(EuiButtonEmpty)`
  height: 35px;
  border: none;
  font-weight: 500;
  margin: 5px 0;
  font-size: 14px;
`;
