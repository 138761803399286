import { AuthComponent, ComponentApplicationHeader, ComponentApplicationMain } from 'components';
import { useQuery } from 'hooks/use-query';
import React from 'react';
import { ResourceContextProvider } from 'modules/core/contexts';
import * as styles from './ComponentApplicationContainer.style';
import type { ApplicationContainerProps } from './ComponentApplicationContainer.contracts';

export function ComponentApplicationContainer({ children, test_id, embedded }: ApplicationContainerProps) {
  const query = useQuery();
  const contentOnly = query.get('content_only') === 'true' || embedded;

  return (
    <AuthComponent>
      <ResourceContextProvider>
        <styles.Container data-testid={test_id}>
          {contentOnly ? (
            <React.Fragment>{children}</React.Fragment>
          ) : (
            <React.Fragment>
              <ComponentApplicationHeader />
              <ComponentApplicationMain>{children}</ComponentApplicationMain>
            </React.Fragment>
          )}
        </styles.Container>
      </ResourceContextProvider>
    </AuthComponent>
  );
}
