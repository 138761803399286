export class Certificate {
  constructor(id, group_policy_id, name, kind, administrator_id, enabled, expired, applications, data) {
    this.id = id;
    this.group_policy_id = group_policy_id;
    this.name = name;
    this.kind = kind;
    this.administrator_id = administrator_id;
    this.enabled = enabled;
    this.expired = expired;
    this.applications = applications;
    this.data = data;
  }
}
