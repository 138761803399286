import styled from 'styled-components';

export const Container = styled.div`
  padding: 0 50px 20px 50px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

export const BreadcrumbBox = styled.div`
  ul {
    padding-bottom: 0;
    padding-left: 0;
  }
`;

export const MetabaseIframe = styled.div`
  overflow: hidden;
  iframe {
    width: 100%;
    height: 800px;
  }
`;
