import type { ReactElement } from 'react';
import type { NavigationTabsComponentProps } from './NavigationTabsComponent.contracts';
import { EuiTab, htmlIdGenerator } from '@elastic/eui';
import * as S from './NavigationTabsComponent.styles';

export const NavigationTabsComponent = ({ tabs, selectedTab, onClick }: NavigationTabsComponentProps): ReactElement => {
  return (
    <S.TabsContainer>
      {tabs.map((tab) => {
        return (
          <EuiTab isSelected={tab.id === selectedTab} disabled={tab.disabled} onClick={() => onClick(tab.id)} key={htmlIdGenerator()()}>
            {tab.name}
          </EuiTab>
        );
      })}
    </S.TabsContainer>
  );
};
