import { useTranslation } from 'react-i18next';
import * as style from '../tabs.style';
import { UrlManagementCard } from './cards';
import type { NavigationTabProps } from './navigation-tab.contracts';

export function ChromeNavigationTab({ emmitToast }: NavigationTabProps) {
  const { t } = useTranslation();
  return (
    <style.Content>
      <style.Title>{t('pages.pulsus_chrome_os_configuration.navegation')}</style.Title>
      <style.Body>
        <style.Side>
          <UrlManagementCard emmitToast={emmitToast} />
        </style.Side>
        <style.Side />
      </style.Body>
    </style.Content>
  );
}
