import React from 'react';
import { EuiSwitch } from '@elastic/eui';
import * as style from './quick-certificates-settings.style';
import type { QuickSettingsCardProps } from './certificates-cards-settings.contracts';

export const QuickCertificatesSettings = (props: QuickSettingsCardProps) => {
  const { title, label, active, onChange, children, noSwitch } = props;
  return (
    <style.CardContainer className="item">
      <style.Card title={title} textAlign="left">
        <style.CardContent>
          {!noSwitch && (
            <style.SwitchContainer>
              <EuiSwitch
                label={label}
                checked={active}
                onChange={() => {
                  onChange();
                }}
              />
            </style.SwitchContainer>
          )}
          <style.CardDescription>{children}</style.CardDescription>
        </style.CardContent>
      </style.Card>
    </style.CardContainer>
  );
};
