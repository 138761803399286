import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const TitleSecondary = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.32px;
  cursor: pointer;
  margin-top: 18px;
  color: ${ConstantColors.GERMANY};
`;
