import styled, { css } from 'styled-components';
import { EuiModal, EuiButton, EuiFieldSearch } from '@elastic/eui';
import { ConstantColors } from 'utilities';
import type { PermissionsListProps } from '../page-certificate.contracts';

export const Modal = styled(EuiModal)`
  position: relative;

  .euiModal__flex {
    width: 792px;
    max-height: fit-content;
  }
`;

export const Variables = styled.div`
  height: 41px;
  display: flex;
  align-items: center;

  &:hover {
    background-color: rgba(0, 109, 228, 0.1);
  }

  span {
    margin-left: 5px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  padding: 25px 70px 32px;

  .euiSwitch {
    margin-bottom: 16px;
  }

  .euiSwitch__label {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: ${ConstantColors.COLOMBIA};
  }

  .euiSwitch__button[aria-checked='false'] .euiSwitch__body {
    background-color: ${({ theme }) => theme.contrast} !important;
  }

  .euiSwitch__body {
    background-color: ${({ theme }) => theme.main} !important;
  }
`;

export const Title = styled.h2`
  font-style: normal;
  font-weight: bold;
  line-height: 40px;
  font-size: 24px;
  color: ${({ theme }) => theme.main};
  margin-bottom: 40px;
`;

export const Subtitle = styled.h6`
  font-weight: 700;
  font-size: 16px;
  margin-bottom: 20px;
  color: ${ConstantColors.HAITI};
  margin-bottom: 40px;
`;

export const PermissionsContainer = styled.section`
  width: 100%;
  min-height: 300px;
  max-height: 300px;
  padding: 0 30px;
  overflow: auto;

  .euiButton--primary.euiButton--fill {
    background-color: ${ConstantColors.CROATIA};
    border-color: ${ConstantColors.CROATIA};
    color: ${ConstantColors.CHILE};

    &:hover {
      background-color: ${ConstantColors.CROATIA};
      border-color: ${ConstantColors.CROATIA};
      color: ${ConstantColors.CHILE};
    }

    &:focus {
      background-color: ${ConstantColors.CROATIA};
      border-color: ${ConstantColors.CROATIA};
      color: ${ConstantColors.CHILE};
    }
  }
`;

export const FieldSearch = styled(EuiFieldSearch)``;

export const PermissionsList = styled.section<PermissionsListProps>`
  ${({ isDisabled }) => css`
    margin-top: 20px;
    border-top: 1px solid ${ConstantColors.PORTUGAL};
    opacity: ${isDisabled ? 0.5 : 1};
    pointer-events: ${isDisabled ? 'none' : 'all'};
  `}
`;

export const Permission = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 41px;
  border-bottom: 1px solid ${ConstantColors.PORTUGAL};
  padding: 0 10px;

  div {
    display: flex;
  }

  p {
    margin-left: 30px;
  }

  .euiIcon {
    cursor: pointer;
  }
`;

export const SubmitContainer = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
`;

export const Button = styled(EuiButton)`
  min-width: 202px;
  min-height: 39px;
  margin-left: 20px;
  background-color: ${({ disabled, theme }) => (disabled ? 'rgba(171, 180, 196, 0.1)' : theme.main)} !important;
  color: ${({ disabled, theme }) => (disabled ? '#ABB4C4' : theme.contrast)} !important;
  border-color: ${({ disabled, theme }) => (disabled ? 'transparent' : theme.main)} !important;
`;
