import { EuiButtonEmpty, EuiSwitch } from '@elastic/eui';
import styled from 'styled-components';
import EditIcon from '@mui/icons-material/Edit';

export const Body = styled.div`
  margin-top: 30px;
  .euiPagination {
    justify-content: flex-end;
    margin-top: 15px;
  }

  .euiTableCellContent__text {
    font-weight: 500;
    font-size: 12px;
    line-height: 118.02%;
    color: #67758d;
  }

  .euiCheckbox .euiCheckbox__input:checked + .euiCheckbox__square {
    background-color: ${({ theme }) => theme.main};
    border-color: ${({ theme }) => theme.main};
  }

  tbody {
    tr {
      #tooltipFenceConfig {
        :onmouseout {
          display: block;
        }
      }
      #editIcon {
        opacity: 0;
        padding-bottom: 5px;
      }
      :hover {
        #editIcon {
          opacity: 1;
          cursor: pointer;
          transition: 0.5s;
          margin-left: -10px;
        }
      }
    }
  }

  #blockIcon {
    color: #ffff;
    border-radius: 50%;
    padding: 4px;
  }

  #insideIcon {
    transform: rotate(180deg);
    color: #ffff;
    border-radius: 50%;
    padding: 4px;
  }

  #outsideIcon {
    color: #ffff;
    border-radius: 50%;
    padding: 4px;
  }
`;

export const BodyHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
  justify-content: space-between;
`;

export const FencesSelectionNumbers = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 118.02%;
  color: #67758d;
  margin-right: 15px;
  width: 400px;
  display: flex;
  align-items: center;
`;

export const UnselectMultipleFencesButton = styled(EuiButtonEmpty)`
  font-weight: 700;
  font-size: 12px;
  line-height: 35px;
  color: #30a8ff;
  align-items: center;
  text-decoration: underline;
  .euiButtonEmpty__text {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const ChangeMultipleFencesButton = styled(EuiButtonEmpty)`
  font-weight: 700;
  font-size: 12px;
  line-height: 35px;
  color: #30a8ff;
  align-items: center;

  .euiButtonEmpty__text {
    display: flex;
    gap: 10px;
    align-items: center;
  }
`;

export const RadiusFlag = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #4a4a4a;
`;

export const CoordinatesColumn = styled.div`
  font-style: italic;
  font-weight: 700;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-direction: column;

  p {
    font-size: 10px !important;
  }
  span {
    font-weight: normal;
  }
`;

export const Text = styled.p`
  max-width: 30ch;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  color: #4a4a4a;
`;

export const ActionsColumn = styled.div`
  font-weight: 500;
  font-size: 12px;
  color: #000000;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SquareNumberActions = styled.div`
  height: 20px;
  min-width: 20px;
  background-color: #e0e5ee;
  border-radius: 3px;
  align-items: center;
  padding: 2px 6px;
`;

export const DescriptionText = styled.p`
  font-style: italic;
  font-weight: 300;
  font-size: 12px;
  line-height: 21px;
  color: #4a4a4a;
  max-width: 30ch;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const FencesNameContainer = styled.div`
  display: flex;
  align-items: baseline;
  gap: 10px;
  font-style: normal;

  :hover {
    cursor: pointer;
  }
`;

export const FencesName = styled.p`
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 21px;
  color: #444444;
  font-style: normal;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
`;

export const PopoverAsTooltip = styled.div`
  padding: 8px;
  box-shadow: 0px 1px 5px 0px #0000001a;
  background: #404040;
  border-radius: 6px;
  color: #ffffff;
  max-width: 217px;
  p {
    font-size: 14px;
  }
`;

export const TooltipButton = styled.button`
  all: unset;
  cursor: pointer;
  color: #30a8ff;

  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledSwitch = styled(EuiSwitch)`
  .euiSwitch__body {
    background: rgba(152, 162, 179, 0.2) !important;
  }
`;

export const IconWrapper = styled.div`
  background: #30a8ff;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconGroup = styled.div`
  display: flex;
  gap: 4px;
`;

export const StyledEditIcon = styled(EditIcon)`
  color: #5c5c5c;
  font-size: 20px;
`;
