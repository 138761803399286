import { MapContainer, ScaleControl, TileLayer, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { useEffect, useState } from 'react';
import type { genericMapProps } from './generic-map.contracts';

export function GenericMap({
  zooms,
  centerCoords,
  children,
  hideScale,
  hideZoomControl,
  initialZoom = 5,
  mapHeight = 'calc(100vh - 150px)',
  mapWidth = '100%',
  baseLayerUrl = 'https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}@2x.png',
  scalePosition = 'bottomleft',
  zoomPosition = 'bottomleft',
  attributionControl = false,
  ...props
}: genericMapProps) {
  const [coordsLocal, setCoordsLocal] = useState<[number, number]>([-23.536131806116856, -46.64912031617174]);

  useEffect(() => {
    if (!centerCoords) {
      window.navigator.geolocation.getCurrentPosition(
        (success) => {
          setCoordsLocal([success.coords.latitude, success.coords.longitude]);
        },
        (error) => {
          // eslint-disable-next-line no-console
          console.log(error);
        }
      );
    } else {
      setCoordsLocal(centerCoords);
    }
  }, []);

  return (
    <MapContainer
      attributionControl={attributionControl}
      zooms={zooms}
      zoom={initialZoom}
      center={coordsLocal}
      zoomControl={false}
      style={{ width: mapWidth, height: mapHeight, overflow: 'hidden' }}
      data-testid="component__generic-map"
      {...props}
    >
      <>
        <TileLayer url={baseLayerUrl} />
        {!hideScale && <ScaleControl position={scalePosition} imperial={false} />}
        {!hideZoomControl && <ZoomControl position={zoomPosition} />}

        {children}
      </>
    </MapContainer>
  );
}
