import { EuiCard } from '@elastic/eui';
import styled, { css } from 'styled-components';

const CardContainer = styled.div``;

const Card = styled(EuiCard)`
  display: flex;
  border-radius: 9px;
  ${(props: { noBottomPadding?: boolean }) => {
    if (props?.noBottomPadding) {
      return css`
        padding: 25px 25px 0px 25px !important;
      `;
    }
    return css`
      padding: 25px !important;
    `;
  }}
  border: none !important;
  width: 100%;

  .euiTitle {
    font-size: 16px;
    font-weight: 500;
    line-height: 40px;
  }
`;

const CardContent = styled.div``;

const SwitchContainer = styled.div`
  margin-top: 15px;
  font-size: 14px;

  .euiSwitch__body {
    background: ${({ theme }) => theme.main};
  }

  .euiSwitch__label {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }
`;

const CardDescription = styled.p`
  margin-top: 15px;
  font-size: 13px;
  line-height: 15.73px;
`;

const CardFlags = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
`;

const Flag = styled.div`
  display: flex;
  align-items: center;
  border-radius: 12px;
  height: 18px;
  padding: 0 6px;
  font-size: 12px !important;
  font-weight: 700;
  line-height: 18px;
  color: #343741;
  background: #e0e5ee;
`;

const BottomBox = styled.div`
  margin-top: 15px;
`;

export { CardContainer, Card, CardContent, SwitchContainer, CardDescription, CardFlags, Flag, BottomBox };
