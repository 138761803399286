import { EuiButton } from '@elastic/eui';
import styled from 'styled-components';

export const Container = styled.div``;

export const HeaderContainer = styled.div`
  padding: 0 50px 20px 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const HeaderTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #343434;
`;

export const HeaderDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #67758d;
`;

export const MenuBox = styled.div`
  padding-top: 30px;
  display: flex;
  gap: 20px;
`;

export const FlagsBox = styled.div`
  display: flex;
  gap: 20px;
`;

export const BodyContainer = styled.div`
  background-color: #f9f9f9;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 55px 65px;
`;

export const Content = styled.div`
  display: flex;
`;

export const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const LeftTitle = styled.p`
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  color: #343434;
`;

export const LeftDescription = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 18px;
  color: #67758d;
  max-width: 520px;
  padding-bottom: 30px;
`;

export const Right = styled.div`
  width: 50%;
`;

export const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 450px;
  gap: 30px;
`;

export const SettingsBox = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  gap: 55px;
  padding: 55px 55px;
  border-radius: 6px;
`;

export const SaveBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  .euiButton:hover,
  .euiButton:focus {
    color: white;
    background: #30a8ff;
    border-radius: 4px;
    border: none;
  }
`;

export const SaveButton = styled(EuiButton)`
  color: white;
  border: none;
  background: #30a8ff;
  border-radius: 4px;
`;
export const Side = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 520px;
  height: 100%;
  gap: 20px;
  width: 100%;
  &.half {
    width: 50%;
  }
`;
