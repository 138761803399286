import type { FormEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ModalAddCsvContracts } from './ComponentModalAddCsv.contracts';
import * as S from './ComponentModalAddCsv.style';
import { EuiIconTip } from '@elastic/eui';

export function ModalAddCsv({ addList, setOpen, loading }: ModalAddCsvContracts) {
  const { t } = useTranslation('translation', { keyPrefix: 'sim_management' });
  const [file, setFile] = useState<FileList | null>(null);
  const [error, setError] = useState<string>('');

  const handleCancel = () => {
    setFile(null);
    setOpen(false);
  };

  const handleAddFile = (fileReceived: FileList | null): void => {
    const reg = /\/csv$/;

    if (fileReceived?.length === 0) {
      setError('');
      setFile(null);
    } else if (fileReceived && reg.test(String(fileReceived?.item(0)?.type))) {
      setFile(fileReceived);
    } else {
      setError(t('upload_file.error_message_format'));
    }
  };

  const handleSubmitFile = (e: FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    if (!file) {
      setError(t('upload_file.error_message_empty'));
    } else {
      addList(e, file);
    }
  };

  return (
    <S.Modal onClose={() => handleCancel()} maxWidth="850px">
      <S.WrapperContent onSubmit={(e) => handleSubmitFile(e)}>
        <S.Content>
          <S.Title>{t('upload_file.title')}</S.Title>
          <S.Text>{t('upload_file.subtitle')}</S.Text>
          <S.BodyModal>
            <S.FilePicker
              initialPromptText={t('upload_file.placeholder')}
              onChange={(files) => handleAddFile(files)}
              aria-label={t('geofences_modal_label')}
              fullWidth
              display="large"
              accept=".csv"
              required
              isInvalid={!!file && !!error}
            />
          </S.BodyModal>
        </S.Content>
        <S.FooterModal>
          <S.SampleButton>
            <EuiIconTip size="m" type="download" color="#30A8ff" />
            <S.ButtonLink href="/sample.csv" download>
              {t('page_description.download_sample')}
            </S.ButtonLink>
            <EuiIconTip size="m" type="alert" color="warning" content={t('page_description.tooltip')} />
          </S.SampleButton>
          {!!error && (
            <S.Error title="Erro" color="warning" iconType="alert">
              <p>{error}</p>
            </S.Error>
          )}
          <div>
            <S.ButtonCancel onClick={() => handleCancel()}>{t('upload_file.button_cancel')}</S.ButtonCancel>
            <S.Button fill isLoading={loading} disabled={!!error || !file} type="submit">
              {t('upload_file.button_confirm')}
            </S.Button>
          </div>
        </S.FooterModal>
      </S.WrapperContent>
    </S.Modal>
  );
}
