import { useTranslation } from 'react-i18next';

import * as style from './page-pulsus-insights-batteries.style';
import type { ChartFilterRangeTimeProps } from './pulsus-insights-batteries.contracts';

export function ChartFilterRangeTime({ range, handleRangeChange, disabled }: ChartFilterRangeTimeProps) {
  const { t } = useTranslation();

  return (
    <>
      <style.ChartLabel>{t('pages.pulsus_insights_batteries.chart_filter_range.label')}</style.ChartLabel>
      <style.EuiDualRangeHours
        disabled={disabled}
        value={range}
        min={0}
        max={23}
        step={1}
        onChange={handleRangeChange}
        showInput
        aria-label="Start and end time"
        ticks={[
          { label: '20kb', value: 20 },
          { label: '100kb', value: 100 },
        ]}
        data-testid="chart_dual_range"
      />
    </>
  );
}
