import styled from 'styled-components';

export const CardFlags = styled.section`
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  gap: 10px;
`;

export const Flag = styled.div`
  display: flex;
  align-items: center;
  gap: 7px;
  max-height: 22px;
  padding: 0px 13px 0px 13px;
  border-radius: 12px;
  font-size: 10px !important;
  font-weight: 700;
  line-height: 18px;
  color: #343741;
  background: #e0e5ee;

  &:hover {
    background: #cbced4;
  }

  span {
    min-width: 21px;
  }

  svg {
    margin-bottom: 2px;
  }
`;
