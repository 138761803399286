import type { ReactElement } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { ComponentUploadModalProps } from './UploadCsvModalComponent.contracts';
import * as S from './UploadCsvModalComponent.styles';

export function UploadCsvModalComponent({ onClose, changeStep, uploadFile, sampleHref, emitChange }: ComponentUploadModalProps): ReactElement {
  const [isCsvFormat, setIsCsvFormat] = useState<boolean>(true);
  const [messageError, setMessageError] = useState<string>('');

  const [file, setFile] = useState([]);

  const { t } = useTranslation('translation', { keyPrefix: 'geofence.uploadModal' });

  const handleOnClose = () => {
    setFile([]);
    onClose();
    setMessageError('');
  };

  const onChange = (value: any) => {
    setFile(value.length > 0 ? value : {});

    setIsCsvFormat(value.length > 0 && value[0].name.substr(-4).includes('.csv'));

    if (value.length === 0) setMessageError('');
  };

  const handleUploadFile = () => {
    uploadFile(file);
    emitChange && emitChange();
    handleOnClose();
  };

  return (
    <>
      <S.Content>
        <S.Title>{t('geofences_modal_title')}</S.Title>
        <S.Text>{t('geofences_modal_text')}</S.Text>
        <S.BodyModal>
          <S.FilePicker
            initialPromptText={t('geofences_modal_label')}
            onChange={onChange}
            aria-label={t('geofences_modal_label')}
            fullWidth
            display="large"
            accept=".csv"
            required
          />
        </S.BodyModal>
      </S.Content>
      <S.FooterModal>
        <div>
          <S.ButtonLink iconType="download" href={sampleHref}>
            {t('geofences_modal_model')}
          </S.ButtonLink>

          {messageError.length > 0 && (
            <S.Error title="Erro" color="warning" iconType="alert">
              <p>{messageError}</p>
            </S.Error>
          )}

          <S.ButtonLink onClick={changeStep}>{t('geofences_modal_cancel')}</S.ButtonLink>
        </div>
        <div>
          <S.Button
            fill
            onClick={handleUploadFile}
            disabled={file.length === 0 || file.length === undefined || messageError.length > 0 || !isCsvFormat}
          >
            {t('geofences_modal_confirm')}
          </S.Button>
        </div>
      </S.FooterModal>
    </>
  );
}
