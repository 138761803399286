import { EuiModal } from '@elastic/eui';
import type { FormEvent } from 'react';
import { useMemo, useState } from 'react';
import { ComponentToast } from 'components';
import { useTranslation } from 'react-i18next';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';
import type { ModalDeleteSimContracts } from './ComponentModalDeleteSim.contracts';
import * as style from './ComponentModalDeleteSim.style';

export function ModalDeleteSIM({ handleDeletePin, setModal, sim }: ModalDeleteSimContracts): React.ReactElement {
  const { t } = useTranslation('translation', { keyPrefix: 'sim_management' });

  const [loading, setLoading] = useState<boolean>(false);
  const [toastType, setToastType] = useState<'error' | 'success' | undefined>();

  const serviceLoki = useMemo(() => new ServicePulsusLoki(), []);

  const fetchDeleteLogin = async (e: FormEvent<HTMLFormElement>): Promise<void> => {
    e.preventDefault();
    try {
      setLoading(true);
      await serviceLoki.deleteSim(sim, sim.chip_id);
      handleDeletePin();
    } catch (err) {
      setToastType('error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <EuiModal onClose={() => setModal(null)}>
      <style.WrapperContent onSubmit={(e) => fetchDeleteLogin(e)}>
        <div className="information">
          <h1>{t('delete_sim.title')}</h1>
          <p>{t('delete_sim.subtitle')}</p>
        </div>
        <style.ButtonGroup>
          <style.StyledButtonEmpty type="button" disabled={loading} color="text" onClick={() => setModal(null)}>
            {t('delete_sim.button_cancel')}
          </style.StyledButtonEmpty>
          <style.StyledButton isLoading={loading} type="submit">
            {t('delete_sim.button_confirm')}
          </style.StyledButton>
        </style.ButtonGroup>
      </style.WrapperContent>
      <ComponentToast dismissToast={() => setToastType(undefined)} toastType={toastType} />
    </EuiModal>
  );
}
