import { useState, type ReactElement, useEffect } from 'react';
import { EuiButton, EuiPopover } from '@elastic/eui';
import moment from 'moment';
import type { MomentInput } from 'moment-timezone';
import TZ from 'moment-timezone';
import { useTheme } from 'styled-components';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { ptBR, LocalizationProvider, DateCalendar } from '@mui/x-date-pickers';

import Slider from '@mui/material/Slider';

import * as S from './MapsCalendarComponent.styles';

import { useMapsContext } from '../../../contexts/maps';
import { useTypedTranslation } from 'modules/core/hooks';
import { NavigationTabsComponent } from 'modules/core/components/NavigationTabs/NavigationTabsComponent';
import { NavigationTabPanelComponent } from 'modules/core/components/NavigationTabPanel/NavigationTabPanelComponent';
import { useUserContext } from 'modules/core/contexts/user';

export const MapsCalendarComponent = (): ReactElement => {
  const [selectedTab, setSelectedTab] = useState(0);

  const { administrator } = useUserContext();
  const [radioIdSelected, setRadioIdSelected] = useState('3h');
  const [sliderValue, setSliderValue] = useState('0:00');
  const [sliderValueInMinutes, setSliderValueInMinutes] = useState(0);
  const [calendarValue, setCalendarValue] = useState<moment.Moment>(moment().utcOffset(administrator?.offset!));

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const { setFilter } = useMapsContext();
  const { t } = useTypedTranslation<'maps.calendar'>('maps.calendar');

  const theme = useTheme();

  const closePopover = () => setIsPopoverOpen(false);
  const onButtonClick = () => setIsPopoverOpen((isOpen) => !isOpen);

  const calendarCustomStyles = {
    '.Mui-selected ': {
      backgroundColor: `${theme.main} !important`,
      borderRadius: '2px',
    },
    '.MuiPickersDay-today': {
      borderRadius: '2px',
    },
    '.MuiPickersCalendarHeader-root': {
      paddingLeft: '0',
    },
    width: '258px',
    height: '320px',
  };

  const sliderCustomStyles = {
    width: '85%',
    '.MuiSlider-thumb': {
      backgroundColor: '#fff',
      border: '1px solid #98A2B34D',
    },
    '.MuiSlider-rail': {
      backgroundColor: '#98A2B34D',
    },
    '.MuiSlider-track': {
      backgroundColor: theme.main,
      border: `1px solid ${theme.main}`,
    },
    '.MuiSlider-thumb:hover, .MuiSlider-thumb.Mui-focusVisible': {
      boxShadow: 'none',
    },
  };

  const tabs = [
    {
      id: 0,
      name: t('tabOne.title'),
    },
    {
      id: 1,
      name: t('tabTwo.title'),
    },
  ];

  const normalizeHour = () => {
    const [hour, minute] = sliderValue.split(':');

    const hourFormated = calendarValue
      .utcOffset(administrator?.offset!)
      .set('hour', Number(hour))
      .set('minutes', Number(minute))
      .format('L LT');

    return hourFormated;
  };

  const popoverButton = (
    <EuiButton iconType="calendar" iconSide="left" onClick={onButtonClick} color="text">
      {normalizeHour()}
    </EuiButton>
  );

  const radios = [
    {
      id: '30m',
    },
    {
      id: '1h',
    },
    {
      id: '3h',
    },
    {
      id: '6h',
    },
    {
      id: '12h',
    },
    {
      id: '24h',
    },
  ];

  const renderRadioSection: ReactElement = (
    <S.RadioSection title={t('RadioSelectionTitle')} isCollapsible initialIsOpen={false} arrowDisplay="left">
      <S.RadioSubtitle>{t('RadioSelectionSubtitle')}</S.RadioSubtitle>

      <S.WrapRadio>
        <S.Trace />
        <S.Radios options={radios} idSelected={radioIdSelected} onChange={(id) => onChangeRadio(id)} name="radio group" />
      </S.WrapRadio>

      <S.Labels>
        <span>30m</span>
        <span>1h</span>
        <span>3h</span>
        <span>6h</span>
        <span>12h</span>
        <span>24h</span>
      </S.Labels>
    </S.RadioSection>
  );

  const onChangeRadio = (optionId: string) => {
    setRadioIdSelected(optionId);
  };

  useEffect(() => {
    setDefaultValuesSlider();
  }, []);

  const handleSelectDate = () => {
    const [hour, minute] = sliderValue.split(':');
    const formatedDate = calendarValue.hour(Number(hour)).minute(Number(minute)).second(0);

    setFilter({
      dateFilter: formatedDate.utcOffset(0).format('YYYY-MM-DDTHH:mm:ss'),
      hourRange: radioIdSelected.slice(0, -1),
      currentSearchDate: false,
    });
    setIsPopoverOpen(false);
  };

  const setDefaultValuesSlider = () => {
    const dateSlider = TZ.utc(moment() as MomentInput)
      .tz(administrator.timezone)
      .format('HH:mm');

    setSliderValueInMinutes(Number(dateSlider.split(':')[0]) * 60 + Number(dateSlider.split(':')[1]));
    setSliderValue(dateSlider);
  };

  const handleChangeTab = (id: number) => {
    setSelectedTab(id);
    setDefaultValuesSlider();
  };

  const handleChangeCalendar = (dateCal: moment.Moment) => {
    setCalendarValue(dateCal.utcOffset(administrator?.offset!));
  };

  const handleChangeSlider = (newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      const hours = Math.floor(newValue / 60);
      const minutes = newValue % 60;

      if (minutes < 10) {
        setSliderValue(`${hours}:0${minutes}`);

        return;
      }

      setSliderValue(`${hours}:${minutes}`);
    }
  };

  return (
    <EuiPopover button={popoverButton} isOpen={isPopoverOpen} closePopover={closePopover}>
      <S.Container>
        <S.TabColumns>
          <NavigationTabsComponent tabs={tabs} selectedTab={selectedTab} onClick={(id: number) => handleChangeTab(id)} />
        </S.TabColumns>
        <S.WrapperCalendar>
          <S.Body>
            <NavigationTabPanelComponent index={0} value={selectedTab}>
              <S.DateBtn iconType="calendar" iconSide="left" color="text">
                {moment()
                  .utcOffset(administrator?.offset!)
                  .format('L LT')}
              </S.DateBtn>
              {renderRadioSection}
            </NavigationTabPanelComponent>
            <NavigationTabPanelComponent index={1} value={selectedTab}>
              <LocalizationProvider dateAdapter={AdapterMoment} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                <DateCalendar
                  sx={calendarCustomStyles}
                  value={calendarValue}
                  onChange={(calendaVal: moment.Moment) => handleChangeCalendar(calendaVal)}
                />
              </LocalizationProvider>
              <S.LabelSlider>{t('tabTwo.timeLabel')}</S.LabelSlider>
              <S.SliderContainer>
                <Slider
                  min={0}
                  max={1439}
                  onChange={(_, newValue) => handleChangeSlider(newValue)}
                  defaultValue={sliderValueInMinutes}
                  sx={sliderCustomStyles}
                />
                <S.HourSlider>{sliderValue}</S.HourSlider>
              </S.SliderContainer>
              {renderRadioSection}
            </NavigationTabPanelComponent>
          </S.Body>
        </S.WrapperCalendar>
        <S.SelectionBtn onClick={() => handleSelectDate()}>{t('selectBtn')}</S.SelectionBtn>
      </S.Container>
    </EuiPopover>
  );
};
