import type { ChangeEvent, ReactElement } from 'react';
import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { EuiButtonIcon, EuiFieldSearch } from '@elastic/eui';
import { mdiAndroid, mdiApple } from '@mdi/js';
import Icon from '@mdi/react';
import { useTheme } from 'styled-components';
import { CircularProgress } from '@mui/material';

import * as S from './MapsDevicesListComponent.styles';
import { useMapsContext } from 'modules/operational/contexts/maps';
import { useTypedTranslation } from 'modules/core/hooks';
import { ConstantColors } from 'utilities';
import type { Toast } from 'modules/core/components';
import { ComponentToast, TextEllipsisComponent } from 'modules/core/components';
import { ConditionalSlotComponent } from 'modules/core/components/ConditionalSlot/ConditionalSlotComponent';
import type { DeviceMapList } from 'modules/operational/entities/DeviceMapList/DeviceMapList.entity';
import { IconComponent } from 'modules/core/components/Icons/IconComponent';
import { ServicePulsusLoki } from 'services/ServicePulsusLoki';

export const MapsDevicesListComponent = (): ReactElement => {
  const [devicesList, setDevicesList] = useState<DeviceMapList[]>([]);
  const [selectedDevices, setSelectedDevices] = useState<Record<number, DeviceMapList>>({});

  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({ currentPage: 1, total: 0 });
  const [isTyping, setIsTyping] = useState(false);
  const [searchStr, setSearchStr] = useState('');

  const [toast, setToast] = useState<Toast[]>([]);

  const inputRef = useRef<EuiFieldSearch>();
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const { setFilter } = useMapsContext();
  const theme = useTheme();

  const { t } = useTypedTranslation<'maps.devicesList'>('maps.devicesList');

  const groupService = useMemo(() => new ServicePulsusLoki(), []);
  const isSelectedListEmpty = useMemo(() => Object.keys(selectedDevices)?.length <= 0, [selectedDevices]);

  const itemsPerPage = 100;

  const getDevices = async (page: number) => {
    try {
      const searchString = inputRef.current?.state.value;

      const { devices, total } = await groupService.getDeviceMapsList(page, searchString);

      setPagination({ currentPage: page, total: Math.ceil(total / itemsPerPage) });

      setDevicesList([...devicesList, ...devices]);
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('error.title'),
          text: t('error.text'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const onChangeSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setIsTyping(true);
    setSearchStr(e.target.value);

    if (typingTimeoutRef.current) {
      clearTimeout(typingTimeoutRef.current);
    }

    typingTimeoutRef.current = setTimeout(() => {
      setIsTyping(false);
      typingTimeoutRef.current = null;
    }, 500);
  };

  const handleSearchByInput = async (searchString: string) => {
    try {
      if (searchString.length > 0) {
        setLoading(true);

        const { devices, total } = await groupService.getDeviceMapsList(1, searchString);

        setPagination({ currentPage: 1, total: Math.ceil(total / itemsPerPage) });

        setDevicesList([...devices]);
      } else {
        setDevicesList([]);
        setPagination({ currentPage: 1, total: 0 });
      }
    } catch (err) {
      setToast([
        {
          color: 'danger',
          iconType: 'faceSad',
          id: '1',
          title: t('error.title'),
          text: t('error.text'),
        },
      ]);
    } finally {
      setLoading(false);
    }
  };

  const getIconByType = useCallback((type: string) => {
    const icons = {
      android: <Icon path={mdiAndroid} title="Android" color="#3DDB85" size={1} />,
      ios: <Icon path={mdiApple} title="IOS" color={ConstantColors.ITALY} size={0.9} />,
      chromeos: <IconComponent icon="coloredChromeOS" />,
    };

    return icons[type] || <S.EmptyDiv />;
  }, []);

  const handleDeleteSelectedDevice = (id: number) => {
    const newList = selectedDevices;
    delete newList[id];

    setSelectedDevices({ ...newList });
  };

  const handleGetMoreDevices = async () => {
    if (pagination.total !== 1 && pagination.currentPage < pagination.total) {
      setLoading(true);
      getDevices(pagination.currentPage + 1);
    }
  };

  const handleSelectDevice = (device: DeviceMapList) => {
    setSelectedDevices((oldState) => ({ ...oldState, [device.id]: device }));
  };

  const onItemsRendered = async (visibleStopIndex: number) => {
    const isLoadTriggered = visibleStopIndex === devicesList.length - 1;

    if (isLoadTriggered) {
      await handleGetMoreDevices();
    }
  };

  const MemoizedList = useMemo(
    () => (
      <S.DevicesList
        height={160}
        itemCount={devicesList.length}
        itemSize={49}
        width="100%"
        onItemsRendered={async ({ visibleStopIndex }) => onItemsRendered(visibleStopIndex)}
      >
        {({ index, style }) => (
          <S.DeviceItem key={devicesList[index].id} style={style} onClick={() => handleSelectDevice(devicesList[index])}>
            {getIconByType(devicesList[index].platform)}
            <S.StyledBadge label={devicesList[index].id.toString()} />
            <TextEllipsisComponent>
              <S.DeviceName>{devicesList[index]?.user?.fullName || '-'}</S.DeviceName>
            </TextEllipsisComponent>
          </S.DeviceItem>
        )}
      </S.DevicesList>
    ),
    [devicesList]
  );

  const submitFilter = () => {
    setFilter({
      deviceIds: Object.values(selectedDevices).map((item) => item.id),
      groupIds: undefined,
      isAllGroupsSelected: undefined,
    });
  };

  useEffect(() => {
    const handleSearch = async () => {
      if (!isTyping) {
        await handleSearchByInput(searchStr);
      }
    };
    handleSearch();
  }, [isTyping]);

  return (
    <S.Container>
      <EuiFieldSearch placeholder={t('placeholder')} ref={inputRef as any} fullWidth onChange={(e) => onChangeSearch(e)} />
      <S.ListsContainer>
        {MemoizedList}

        <ConditionalSlotComponent renderIf={loading} fallback={<S.CenteredLoader />}>
          <S.CenteredLoader>
            <CircularProgress style={{ color: theme.main }} size={15} />
          </S.CenteredLoader>
        </ConditionalSlotComponent>
        <ConditionalSlotComponent renderIf={!isSelectedListEmpty}>
          <S.SelectedDevicesList>
            {Object.keys(selectedDevices).map((device) => (
              <S.SelectedDeviceItem key={selectedDevices[device].id}>
                <S.WrapperMainInfos>
                  {getIconByType(selectedDevices[device].platform)}
                  <S.StyledBadge label={selectedDevices[device].id.toString()} />
                  <TextEllipsisComponent>
                    <S.DeviceName>{selectedDevices[device].name || '-'}</S.DeviceName>
                  </TextEllipsisComponent>
                </S.WrapperMainInfos>
                <S.Close>
                  <EuiButtonIcon
                    aria-label="remove"
                    iconType="cross"
                    color="danger"
                    onClick={() => handleDeleteSelectedDevice(selectedDevices[device].id)}
                  />
                </S.Close>
              </S.SelectedDeviceItem>
            ))}
          </S.SelectedDevicesList>
        </ConditionalSlotComponent>
      </S.ListsContainer>
      <S.StyledButton disabled={isSelectedListEmpty} onClick={submitFilter}>
        {t('locate')}
      </S.StyledButton>
      <ComponentToast toasts={toast} dismissToast={() => setToast([])} />
    </S.Container>
  );
};
