/* eslint-disable react/jsx-no-useless-fragment */
import type { ReactElement } from 'react';
import { useMemo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { EuiFieldSearch, EuiToolTip } from '@elastic/eui';
import { useTypedTranslation } from 'modules/core/hooks';
import type { SelectedGroupListComponentProps } from './SelectedGroupListComponent.contracts';
import * as S from './SelectedGroupListComponent.styles';
import { TextEllipsisComponent } from 'modules/core/components';

export const SelectedGroupListComponent = ({ tags, setTags }: SelectedGroupListComponentProps): ReactElement => {
  const [inputValue, setInputValue] = useState<string>('');

  const { t } = useTypedTranslation<'devices.add_group_modal.selected_group_list'>('devices.add_group_modal.selected_group_list');

  const removeFilteredGroup = (groupId: number, tagId: string) => {
    const newArrTags = [...tags];

    const tagIndex = tags.findIndex((tag) => tag.id === tagId);
    const groupIndex = tags[tagIndex].groups.findIndex((group) => group.id === groupId);

    newArrTags[tagIndex].groups[groupIndex] = { ...tags[tagIndex].groups[groupIndex], selected: false };
    const newTags = [...newArrTags];

    setTags(newTags);
  };

  const removeGroup = (groupIndex: number, tagIndex: number) => {
    const newTags = [...tags];

    newTags[tagIndex].groups[groupIndex] = {
      ...newTags[tagIndex].groups[groupIndex],
      selected: false,
    };

    setTags(newTags);
  };

  const filterGroups = () => {
    return tags.map((tag) => ({
      ...tag,
      groups: tag.groups.filter((group) => group.name?.toLowerCase().includes(inputValue?.toLowerCase()) && group.selected === true),
    }));
  };

  const filteredGroups = useMemo(() => {
    return filterGroups();
  }, [inputValue, tags]);

  const renderedItems = {};

  const renderGroupsOrFilteredGroups = () => {
    if (inputValue.length > 0) {
      return filteredGroups.map((tag) => {
        return tag.groups.map((group) => {
          const hasAlreadyRendered = !!renderedItems[group.id];
          renderedItems[group.id] = group.id;

          return (
            <>
              {group.selected && !hasAlreadyRendered ? (
                <S.GroupItem>
                  <S.GroupName>
                    <EuiToolTip content={group.name}>
                      <TextEllipsisComponent style={{ height: 18 }}>{group.name}</TextEllipsisComponent>
                    </EuiToolTip>
                  </S.GroupName>
                  <S.Action onClick={() => removeFilteredGroup(group.id, tag.id)}>
                    <CloseIcon style={{ width: 14, height: 14 }} />
                  </S.Action>
                </S.GroupItem>
              ) : (
                <></>
              )}
            </>
          );
        });
      });
    }

    return tags.map((tag, tagIndex) => {
      return tag.groups.map((group, groupIndex) => {
        const hasAlreadyRendered = !!renderedItems[group.id];
        renderedItems[group.id] = group.id;

        return (
          <>
            {group.selected && !hasAlreadyRendered ? (
              <S.GroupItem>
                <S.GroupName>
                  <EuiToolTip content={group.name}>
                    <TextEllipsisComponent style={{ height: 18 }}>{group.name}</TextEllipsisComponent>
                  </EuiToolTip>
                </S.GroupName>
                <S.Action onClick={() => removeGroup(groupIndex, tagIndex)}>
                  <CloseIcon style={{ width: 14, height: 14 }} />
                </S.Action>
              </S.GroupItem>
            ) : (
              <></>
            )}
          </>
        );
      });
    });
  };

  return (
    <S.GroupsViewList>
      <S.GroupListTitle>{t('title')}</S.GroupListTitle>
      <EuiFieldSearch placeholder={t('inputPlaceholder')} value={inputValue} onChange={(e) => setInputValue(e.target.value)} />
      <S.GroupListContainer>{renderGroupsOrFilteredGroups()}</S.GroupListContainer>
    </S.GroupsViewList>
  );
};
