import { EuiButton, EuiButtonEmpty, EuiDualRange, EuiFilterButton, EuiFilterGroup, EuiSwitch } from '@elastic/eui';
import styled from 'styled-components';
import { ConstantColors } from 'utilities';

export const Content = styled.main`
  padding: 13px 20px 30px 25px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  input,
  select {
    border-radius: 6px;
  }
`;

export const Top = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
`;

export const Group = styled.div`
  margin-bottom: 20px;

  .so-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  #switch-date {
    margin-left: 160px;
  }
`;

export const Card = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 25px 10px;
  background-color: ${ConstantColors.AUSTRIA};
  border-radius: 6px;
  width: fit-content;
  position: relative;

  max-width: 830px;
  min-width: 280px;

  label {
    white-space: nowrap;
  }

  .group-select {
    max-width: 250px;
  }

  .disclaimer {
    position: absolute;
    bottom: 20px;
    left: 30px;
    color: ${ConstantColors.ANDORRA};
  }

  &.status {
    max-width: 280px;
    flex-wrap: wrap;
    column-gap: 20px;
    row-gap: 12px;
    padding: 15px 20px;

    .euiCheckbox {
      min-width: 55px;

      &:nth-of-type(5) {
        margin-right: 18px;
      }
    }
  }

  &.so {
    display: grid;
    grid-template-columns: 10px 100px 125px auto;
    min-width: 825px;
    gap: 15px;

    padding: 11px 20px;

    svg {
      min-width: 15px;
    }
  }

  &.ranges {
    min-width: fit-content;
    padding: 45px 20px;
    gap: 20px;

    section:first-child {
      .euiSwitch {
        margin-left: 2px;
      }
    }
  }

  .identifier {
    border-right: 2px solid #e4e6f3;
    padding-right: 10px;
    margin-right: -10px;
    width: 390px;

    .euiFormControlLayout {
      width: 180px;
    }
  }

  .date-picker {
    width: 410px;
    #selectDateType {
      width: 155px;
    }

    .euiDatePickerRange {
      width: 240px;
      border-radius: 8px;
    }

    &__hour {
      .euiDatePickerRange {
        width: 320px;
        border-radius: 8px;
      }
    }
  }

  &.mid-first-line {
    min-width: fit-content;
    padding: 25px 13px 13px 13px;
    white-space: nowrap;

    .euiSelect {
      width: 180px;
    }
  }

  .has-label {
    position: relative;
    font-size: 10px;
    font-weight: 700;
    color: ${ConstantColors.JAPAN};
    width: fit-content;

    .date-error {
      position: absolute;
      bottom: -17px;
      left: 3px;
      color: ${ConstantColors.ANDORRA};
    }

    p {
      position: absolute;
      top: -20px;
      left: 5px;
      font-size: 10px;
    }
  }

  section {
    display: flex;
    gap: 10px;
  }

  .so-management {
    div {
      display: flex;
      gap: 15px;
    }

    p {
      margin-bottom: 10px;
      font-size: 10px;
      font-weight: 700;
    }
  }
`;

export const CenterButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const Switch = styled(EuiSwitch)`
  margin: 0 0 10px 10px;

  .euiSwitch__body {
    background-color: ${({ theme }) => theme.main};
  }

  span {
    font-weight: 500;
  }
`;

export const ButtonAddGroups = styled(EuiButtonEmpty)`
  font-size: 12px;
`;

export const DualRange = styled(EuiDualRange)`
  width: 340px;
  .euiRangeLevel {
    &--danger {
      background-color: ${ConstantColors.ANDORRA};
    }
    &--warning {
      background-color: ${ConstantColors.ANGOLA};
    }
    &--success {
      background-color: #00bfb3;
    }
  }
`;

export const Mid = styled.section`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 15px;
`;

export const MidRight = styled.section`
  .first-line {
    display: flex;
    gap: 5px;
    max-width: 830px;
  }
`;

export const Bottom = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  max-width: 1220px;

  .chip-check {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-left: 10px;
    height: 40px;
    align-items: center;
  }

  .storage-wrapper {
    display: flex;
    gap: 15px;

    .euiFormControlLayout {
      width: 130px;
    }
  }

  section {
    display: flex;
    flex-direction: column;
  }

  label {
    white-space: nowrap;
  }
`;

export const SearchContainer = styled.div`
  gap: 10px;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const ClearButton = styled(EuiButtonEmpty)``;

export const SearchButton = styled(EuiButton)`
  width: 165px;
  margin-top: 10px;
`;

export const StorageUnitGroup = styled(EuiFilterGroup)`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: none;
`;

export const StorageUnitButton = styled(EuiFilterButton)`
  width: 36px;

  :not(:first-of-type) {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  :first-of-type {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  &.euiButtonEmpty--text:focus,
  &.euiFilterButton-hasActiveFilters {
    background-color: ${({ theme }) => theme.main};
    color: #fff;
  }

  span {
    text-decoration: none !important;
  }
`;
