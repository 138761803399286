import { EuiButton } from '@elastic/eui';
import styled, { css } from 'styled-components';
import type { ConfigProps } from '../page-android-enterprise.contracts';

export const SaveConfig = styled.section`
  display: flex;
  align-items: center;
  column-gap: 30px;
  margin: 35px 0 35px 50px;
`;

export const SaveButton = styled(EuiButton)``;

export const ConfigContainer = styled.section<ConfigProps>`
  ${({ isEditable }) => css`
    opacity: ${isEditable ? 1 : 0.4};
    cursor: ${isEditable ? '' : 'not-allowed'};
  `}

  max-width: 97%;
`;
