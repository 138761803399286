import type { FlagProps } from './component-flag.contracts';
import * as style from './component-flag.style';

export function FilterFlag(props: FlagProps) {
  const { title, isSelected, onClick } = props;
  return (
    <style.Container isSelected={isSelected} onClick={() => onClick()}>
      <style.Title>{title}</style.Title>
    </style.Container>
  );
}
