// TODO remove this comment and the consoles
/* eslint-disable no-console */
import io from 'socket.io-client';
import SimplePeer from 'simple-peer';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HelperAuthentication } from 'helpers';
import { ServicePulsusWeb } from 'services/ServicePulsusWeb';
import * as style from './RemoteViewModalComponent.styles';
import type { RemoteViewModalProps } from './RemoteViewModalComponent.contracts';

export function RemoteViewModalComponent({ onClose, deviceId }: RemoteViewModalProps) {
  const [video, setVideo] = useState<MediaStream>(new MediaStream());
  const [peer, setPeer] = useState<SimplePeer.Instance>(newPeerConnection());
  const [iosPeer, setIosPeer] = useState<RTCPeerConnection>(new RTCPeerConnection());
  const [peerConnected, setPeerConnected] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);

  const { t } = useTranslation('translation', { keyPrefix: 'devices' });
  const servicePulsusWeb = useMemo(() => new ServicePulsusWeb(), []);

  const signalingServer = process.env.REACT_APP_WEBRTC_SIGNALING_SERVER || '';

  const socket = io(signalingServer);

  useEffect(() => {
    socket.on('connect', () => {
      if (!socketConnected) {
        setSocketConnected(true);
        socket.emit('create room', { roomName: getRoomName(), iceServers: getIceServers() });
        servicePulsusWeb.pushToDevice(getRoomName(), deviceId);
        setPeer(newPeerConnection());
      }
    });

    socket.on('device is connected', () => {
      socket.emit('request offer', getRoomName());
    });

    socket.on('instance disconnect', () => {
      console.log('instance disconnect');
    });

    socket.on('connect_error', (error) => {
      console.log({ error });
    });

    socket.on('receive offer', (offer) => {
      peer?.signal(JSON.parse(offer.replace(/\r\n/g, '\\r\\n')));
    });

    socket.on('receive offer ios', async (data) => {
      setIosPeer(
        new RTCPeerConnection({
          bundlePolicy: 'max-compat',
          iceTransportPolicy: 'all',
          iceServers: getIceServers(),
          iceCandidatePoolSize: 10,
        })
      );
      console.log('SOCKET RECEIVED OFFER IOS EVENT FROM DEVICE');
      initIOSPeerEvents();
      await iosPeer?.setRemoteDescription(new RTCSessionDescription(data.offer));
      const answer = await iosPeer?.createAnswer();
      await iosPeer?.setLocalDescription(answer);
      socket.emit('answer', { answer });
    });

    socket.on('receive offer candidate', (candidate) => {
      peer?.signal(JSON.parse(candidate));
    });

    socket.on('receive offer candidate ios', (candidate) => {
      console.log('SOCKET RECEIVED OFFER CANDIDATE IOS EVENT FROM DEVICE');
      console.log(candidate);
      const receivedCandidate = new RTCIceCandidate(candidate);
      console.log(receivedCandidate);
      iosPeer.addIceCandidate(receivedCandidate);
    });
  }, []);

  const initIOSPeerEvents = () => {
    iosPeer.onicecandidate = (event) => {
      console.log('Ice candidate received on PEER: ', event);
      if (event.candidate) {
        socket.emit('new ice candidate', { iceCandidate: event.candidate });
      }
    };

    iosPeer.ontrack = (event) => {
      console.log('ONTRACK RECEIVED');
      video.addTrack(event.track);
    };

    iosPeer.onconnectionstatechange = (event) => {
      if (iosPeer.connectionState === 'connected') {
        console.log('Peers connected');
        setPeerConnected(true);
      } else if (iosPeer.connectionState === 'disconnected') {
        console.log('disconnected');
        setPeerConnected(false);
        iosPeer.close();
      } else {
        console.log(iosPeer.connectionState);
        console.log(event, 'not treated state');
      }
    };
  };

  function newPeerConnection() {
    let newPeer: SimplePeer.Instance | undefined;
    if (!newPeer) {
      newPeer = new SimplePeer({
        trickle: false,
        initiator: false,
        channelName: getRoomName(),
        config: {
          iceTransportPolicy: 'all',
          iceServers: getIceServers(),

          optional: [{ googDscp: true }],
        },
        offerOptions: {
          iceRestart: true,
        },
      } as any);
    }

    newPeer.on('connect', () => {
      setPeerConnected(true);
    });

    newPeer.on('error', (err) => {
      console.log('peer error', err);
    });

    newPeer.on('close', () => {
      console.log('peer close');
    });

    newPeer.on('signal', (data) => {
      if (data.type === 'answer') {
        socket.emit('answer', JSON.stringify(data));
      } else {
        socket.emit('answer candidate', JSON.stringify(data));
      }
    });

    newPeer.on('stream', (stream) => {
      setVideo(stream);
    });

    return newPeer;
  }

  const handleOnClose = () => {
    closeConnectionPeer();
    onClose();
    socket.disconnect();
  };

  function normalizeName(room: string) {
    const noLetterAndNumber = /[^\w\s]/gi;
    const accentuation = /[\u0300-\u036f]/g;
    return room.normalize('NFD').replace(accentuation, '').replace(noLetterAndNumber, '');
  }

  function getRoomName() {
    const room = `pulsus-${HelperAuthentication.decodedSchemaName()}-${deviceId}`;
    return normalizeName(room);
  }

  function getIceServers(): Array<{ urls: string; username?: string; credential?: string }> {
    return [
      { urls: 'stun:stun.l.google.com:19302' },
      { urls: process.env.REACT_APP_TURN_URLS!, username: process.env.REACT_APP_TURN_USERNAME, credential: process.env.REACT_APP_TURN_CREDENTIAL! },
    ];
  }

  const elementVideo = document.getElementById('remote-view-video') as HTMLMediaElement;

  if (elementVideo && video) {
    elementVideo.srcObject = video;
  }

  const closeConnectionPeer = () => {
    if (peer) {
      peer.destroy();
      iosPeer?.close();
      setPeerConnected(false);
    }
  };

  return (
    <style.Modal onClose={handleOnClose}>
      <style.Container>
        <style.DeviceLabel>{`${t('remote_view.device_span')}: ${deviceId}`}</style.DeviceLabel>
        <style.LauncherPreview className="launcher-preview">
          <style.Device className="marvel-device remote-view nexus5 portrait grid">
            <style.Screen>
              {video ? (
                <style.Video id="remote-view-video" className="remote-video-control" autoPlay />
              ) : (
                <style.LoadingWrapper className="loading-wrapper">
                  <style.Phrase>{t('remote_view.connection_loading')}</style.Phrase>
                  <style.Spinner />
                </style.LoadingWrapper>
              )}
            </style.Screen>
          </style.Device>
        </style.LauncherPreview>
        <style.DeviceLabelAlert>{`* ${t('remote_view.device_remote_alert')}`}</style.DeviceLabelAlert>
      </style.Container>
    </style.Modal>
  );
}
