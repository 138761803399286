import type { EuiTabbedContentTab } from '@elastic/eui';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldPassword,
  EuiFieldText,
  EuiFlexGroup,
  EuiFlexItem,
  EuiFormRow,
  EuiSelect,
  EuiTabbedContent,
} from '@elastic/eui';
import { useEffect, useState } from 'react';
import { DeviceDraw, ZeroTouchDraw } from 'icons';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as S from './fully-managed.style';
import { tabs } from './tabs-fully-managed';
import type { EnrollComponentData } from '../modal-ae-enroll.contracts';
import { QRCodeCanvas } from 'qrcode.react';

export function FullyManaged({ data }: EnrollComponentData) {
  const { t, i18n } = useTranslation('translation', { keyPrefix: 'pages.enroll' });

  const [selectedTab, setSelectedTab] = useState<EuiTabbedContentTab>(tabs(data?.jsonString, t)[0]);
  const [qrCodeOptionSelected, setQrCodeOptionSelected] = useState<'default' | 'advanced'>('default');
  const [ssid, setSsid] = useState('');
  const [wifiPassword, setWifiPassword] = useState('');

  const securityOptions = [
    { value: 'WEP', text: 'WEP' },
    { value: 'WPA', text: 'WPA/WPA2' },
  ];

  const radiosQrCode = [
    {
      id: 'default',
      label: t('fully_managed.qr_code.options.default'),
    },
    {
      id: 'advanced',
      label: t('fully_managed.qr_code.options.advanced'),
    },
  ];

  const languageOptions = [
    { value: '', text: t('fully_managed.qr_code.form.default_language') },
    { value: 'pt_br', text: t('fully_managed.qr_code.form.portuguese') },
    { value: 'en_us', text: t('fully_managed.qr_code.form.english') },
    { value: 'es_es', text: t('fully_managed.qr_code.form.spanish') },
  ];

  const [securityType, setSecurityType] = useState(securityOptions[0].value);
  const [language, setLanguage] = useState(languageOptions[0].value);
  const [qrCode, setQrCode] = useState({});

  const defaultQrCode = {
    'android.app.extra.PROVISIONING_DEVICE_ADMIN_COMPONENT_NAME': 'mobi.pulsus/.messaging.DeviceAdminReceiver',
    'android.app.extra.PROVISIONING_LEAVE_ALL_SYSTEM_APPS_ENABLED': true,
    'android.app.extra.PROVISIONING_DEVICE_ADMIN_SIGNATURE_CHECKSUM': 'eHyGaE2ZkPyKwagi4vyACg4sfy3x0e2PCn5IDOaX9gw',
    'android.app.extra.PROVISIONING_DEVICE_ADMIN_PACKAGE_DOWNLOAD_LOCATION': data?.packageDownloadLocation,
    'android.app.extra.PROVISIONING_ADMIN_EXTRAS_BUNDLE': {
      pin: data?.groupPin,
    },
    'android.app.extra.PROVISIONING_SKIP_ENCRYPTION': true,
  };

  const onTabClick = (tab: EuiTabbedContentTab) => {
    setSelectedTab(tab);
  };

  function typeWrite() {
    const elemento = document.querySelector('.imageInputTextFully');
    const texto = 'afw#pulsus.mobi';
    if (elemento) {
      const textoArray = texto.split('');
      elemento.innerHTML = '';
      textoArray.forEach((letra, i) => {
        setTimeout(() => {
          elemento.innerHTML += letra;
        }, 100 * i);
      });
    }
  }

  useEffect(() => {
    const loop = setInterval(() => {
      typeWrite();
    }, 3500);
    return function clear() {
      clearInterval(loop);
    };
  }, []);

  const handleQrCodeRender = () => {
    if (qrCodeOptionSelected === 'default') return renderQrCode(JSON.stringify(defaultQrCode));
    if (Object.keys(qrCode).length !== 0) return renderQrCode(JSON.stringify(qrCode));
    if (qrCodeOptionSelected === 'advanced') return renderQrCodeForm();
  };

  const downloadQrCode = () => {
    const canvas = document.querySelector('canvas.qr-code') as HTMLCanvasElement;

    if (!canvas) {
      return;
    }

    const imageURL = canvas.toDataURL('image/png');

    const downloadLink = document.createElement('a');
    downloadLink.href = imageURL;
    downloadLink.download = 'qr-code.png';

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const renderQrCode = (value: string) => {
    return (
      <>
        {qrCodeOptionSelected === 'advanced' && (
          <EuiButtonEmpty onClick={() => setQrCode({})}>{t('fully_managed.qr_code.generate_new')}</EuiButtonEmpty>
        )}
        <S.QrCodeContainer>
          <QRCodeCanvas className="qr-code" size={300} value={value} />
          <EuiButtonEmpty onClick={() => downloadQrCode()} iconType="download">
            {t('fully_managed.qr_code.download')}
          </EuiButtonEmpty>
        </S.QrCodeContainer>
      </>
    );
  };

  const generateNewQrCode = () => {
    const qrCodeNewSettings = {
      ...(ssid && { 'android.app.extra.PROVISIONING_WIFI_SSID': ssid }),
      ...(wifiPassword && { 'android.app.extra.PROVISIONING_WIFI_PASSWORD': wifiPassword }),
      ...(securityType && { 'android.app.extra.PROVISIONING_WIFI_SECURITY_TYPE': securityType }),
      ...(language && { 'android.app.extra.PROVISIONING_LOCALE': language }),
    };

    setQrCode({ ...defaultQrCode, ...qrCodeNewSettings });
  };

  console.log(JSON.stringify(qrCode));

  const renderQrCodeForm = () => {
    return (
      <S.QrCodeForm>
        <h3>{t('fully_managed.qr_code.form.title')}</h3>
        <p>{t('fully_managed.qr_code.form.text')}</p>
        <EuiFlexGroup>
          <EuiFlexItem grow={3}>
            <EuiFormRow label="SSID">
              <EuiFieldText
                placeholder={t('fully_managed.qr_code.form.ssid_placeholder')}
                value={ssid}
                onChange={(event) => setSsid(event.target.value)}
              />
            </EuiFormRow>
          </EuiFlexItem>
          <EuiFlexItem grow={2}>
            <EuiFormRow label={t('fully_managed.qr_code.form.security')}>
              <EuiSelect
                options={securityOptions}
                value={securityType}
                onChange={(e) => setSecurityType(e.target.value)}
                aria-label="security types"
              />
            </EuiFormRow>
          </EuiFlexItem>
        </EuiFlexGroup>
        <EuiFormRow label={t('fully_managed.qr_code.form.password')}>
          <EuiFieldPassword type="dual" value={wifiPassword} onChange={(e) => setWifiPassword(e.target.value)} aria-label="wifi password" fullWidth />
        </EuiFormRow>
        <EuiFormRow label={t('fully_managed.qr_code.form.language')}>
          <EuiSelect options={languageOptions} value={language} onChange={(e) => setLanguage(e.target.value)} aria-label="language" />
        </EuiFormRow>
        <EuiButton onClick={() => generateNewQrCode()} fill fullWidth>
          {t('fully_managed.qr_code.form.button')}
        </EuiButton>
      </S.QrCodeForm>
    );
  };

  return (
    <S.Container>
      <S.Top>
        <h2>{t('fully_managed.title')}</h2>
      </S.Top>
      <S.OptionsContainer>
        <S.TabsContainer>
          <EuiTabbedContent tabs={tabs(data?.jsonString, t)} onTabClick={(tab) => onTabClick(tab)} />
        </S.TabsContainer>
        <S.OptionsRight>
          {selectedTab.id === 'afw' && (
            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
              <div className="background">
                <S.ImageBox className="animation">
                  <DeviceDraw width={336} height={390} />
                  <S.ImageInput className="imageInputTextFully" />
                </S.ImageBox>
              </div>
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Fully Managed Device</p>
                <p className="secondary">/</p>
                <p className="primary">AFW</p>
              </S.LinkHistory>
            </div>
          )}
          {selectedTab.id === 'zero-touch' && (
            <S.ZeroTouchBox>
              <div style={{ height: '80%', display: 'flex', alignItems: 'center' }}>
                <ZeroTouchDraw />
              </div>
              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Fully Managed Device</p>
                <p className="secondary">/</p>
                <p className="primary">Zero Touch</p>
              </S.LinkHistory>
            </S.ZeroTouchBox>
          )}
          {selectedTab.id === 'qr-code' && (
            <S.RightBox>
              <section>
                <S.QrCodeRadios
                  data-testid="qr-code-options"
                  options={radiosQrCode}
                  idSelected={qrCodeOptionSelected}
                  onChange={(id) => setQrCodeOptionSelected(id as 'default' | 'advanced')}
                  name="qr-code radio group"
                />
                {handleQrCodeRender()}
              </section>

              <S.LinkHistory>
                <Link className="secondary" to={`/enroll?language=${i18n.language}`}>
                  Enroll
                </Link>

                <p className="secondary">/</p>
                <p className="secondary">Fully Managed Device</p>
                <p className="secondary">/</p>
                <p className="primary">QR Code</p>
              </S.LinkHistory>
            </S.RightBox>
          )}
        </S.OptionsRight>
      </S.OptionsContainer>
    </S.Container>
  );
}
